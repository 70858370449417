import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import React, { useState } from "react";
import Date from "../../../FormControl/Date/Date";
// Component
import Select from "./../../../FormControl/Select/Select";
import Text from "./../../../FormControl/Text/Text";
// SCSS
import styles from "./Add.module.scss";
// Images
import CalendarIcon from "../../../../Assets/images/calendar.svg";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddOrder = ({
  showModal,
  pageTitle,
  submitStatus,
  productsList,
  handleCancelClick,
  handleSubmitClick,
}) => {
  //STATE FOR DATA
  const [stockOrderSelected, setStockOrderSelected] = useState({
    productId: productsList[0]?.id,
    variationId: productsList[0]?.product_variations[0].id,
    stockQuantity: 1,
    stockDeliveryDate: moment().format("MM-DD-YYYY"),
    productDetail: productsList[0],
    varationDetail: productsList[0]?.product_variations[0],
  });
  //VARIATION LIST STATE OF SELECTED PRODUCT
  const [variationsList, setVariationsList] = useState(
    productsList[0]?.product_variations
  );

  //FUNCTION FOR SET VARIATION DATA OF SELECTED PRODUCT
  const setProductVarationData = async (id) => {
    let productData = productsList.filter((product) => product.id === id);
    setVariationsList(productData[0].product_variations);
    setStockOrderSelected({
      ...stockOrderSelected,
      productId: productData[0].id,
      variationId: productData[0].product_variations[0].id,
      productDetail: productData[0],
      varationDetail: productData[0].product_variations[0],
    });
  };

  //FUNCTION FOR SET SELECTED VARIATION DATA
  const setVarationData = async (id) => {
    let variationsData = variationsList.filter(
      (variations) => variations.id === id
    );

    setStockOrderSelected({
      ...stockOrderSelected,
      variationId: variationsData[0].id,
      varationDetail: variationsData[0],
    });
  };

  //FUNCTION FOR SET ALL THING TO DFAULT
  const setDefaultValue = async () => {
    setStockOrderSelected({
      ...stockOrderSelected,
      productId: productsList[0]?.id,
      variationId: productsList[0]?.product_variations[0].id,
      stockQuantity: 1,
      stockDeliveryDate: moment().format("MM-DD-YYYY"),
      productDetail: productsList[0],
      varationDetail: productsList[0]?.product_variations[0],
    });
    setVariationsList(productsList[0].product_variations);
  };

  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={(e) => {
          e.preventDefault();
          setDefaultValue();
          handleCancelClick();
        }}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <Select
                  label="Product"
                  parentClass="text-left"
                  value={stockOrderSelected.productId}
                  required={false}
                  submitStatus={submitStatus}
                  onChange={(data) => {
                    setProductVarationData(data);
                  }}
                  options={productsList}
                  disabled={false}
                />
              </div>
              <div className="col-md-12 col-xs-12">
                <Date
                  type="date"
                  label="Order completion date"
                  value={stockOrderSelected.stockDeliveryDate}
                  required={true}
                  submitStatus={submitStatus}
                  dateFormat="MM-DD-YYYY"
                  onChange={(data) =>
                    setStockOrderSelected({
                      ...stockOrderSelected,
                      stockDeliveryDate: data,
                    })
                  }
                  disabled={false}
                  checkPassDate={true}
                  fieldIcon={CalendarIcon}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <Select
                  label="Variation"
                  parentClass="text-left"
                  value={stockOrderSelected.variationId}
                  required={false}
                  submitStatus={submitStatus}
                  onChange={(data) => setVarationData(data)}
                  options={variationsList}
                  disabled={false}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <Text
                  type="number"
                  label="Quantity"
                  parentClass="text-left"
                  value={stockOrderSelected.stockQuantity}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => {
                    const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                    if (regex.test(data.toString())) {
                      setStockOrderSelected({
                        ...stockOrderSelected,
                        stockQuantity: data,
                      });
                    } else {
                      setStockOrderSelected({
                        ...stockOrderSelected,
                        stockQuantity: "",
                      });
                    }
                  }}
                  min="0"
                />
              </div>
            </div>
            <button
              data-dismiss="modal"
              onClick={(e) => {
                handleSubmitClick(e, stockOrderSelected);
                setDefaultValue();
              }}
              className="btn gray-btn d-inline-block mt-5 mb-3 mr-2"
            >
              Save
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default AddOrder;
