import React, { useContext, useEffect, useState } from "react";
import moment, { unix } from "moment";
import "moment-timezone";
import ReactTable from "../Table/Table";
import ManageUpchargeDatesModal from "./../../Components/Modal/UpchargeDate";
//icon
import EditIcon from "./../../Assets/images/edit.svg";
import DeleteIcon from "./../../Assets/images/delete.svg";
import AddIcon from "../../Assets/images/add.svg";
import styles from "./index.module.scss";
import ActivatedIcon from "../../Assets/images/activated.svg";
//modal
import DeleteConfirmation from "./../../Components/Modal/DeleteConfirmation/DeleteConfirmation";
import StatusConfirmation from "./../../Components/Modal/StatusConfirmation/StatusConfirmation";

// Context
import { AppContext } from "./../../Context/AppContext";
import { getAccessByModuleName } from "./../../Routes/Routes";
import { MODULES } from "./../../Helpers/Constants";
// Services
// import SettingsApi from "./../../Services/Settings/Settings";
// import UtilsApi from "./../../Services/Utils/Utils";
import { updateStatus } from "./../../api/utils";
import {
  getUpchargeDatesList,
  upchargeDatesUpdate,
  upchargeDatesAdd,
  upchargeDatesDeleteById,
} from "./../../api/settings";
import { convertUtcTimestampToPstDate } from "Hooks/commanUtility";
import { DateTime } from "luxon";
import {
  PST_ZONE,
  UTC_ZONE,
  START_TIME_OF_DATE,
  END_TIME_OF_DATE,
} from "Pages/Order/Order/utility/util";

const UpchargeDates = () => {
  //Api Calling Response Data
  const [data, setData] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [UpchargeDatesDetails, setUpchargeDatesDetails] = React.useState({});
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [
    manageUpchargeDatesTitle,
    setManageUpchargeDatesPageTitle,
  ] = React.useState("");
  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [access, setAccess] = useState({});
  const [deleteUpchargeDateId, setDeleteUpchargeDateId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const settingsService = new SettingsApi();

  //status update
  const [statusUpChargeDateId, setStatusUpChargeDateId] = useState(false);
  const [statusOfUpchargeDate, setStatusOfUpchargeDate] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);
  // const utilsService = new UtilsApi();

  // Add / Edit
  const [title, setTitle] = React.useState(false);
  const [amount, setAmount] = React.useState(false);
  const [apllies_on, setAplliesOn] = React.useState(false);
  const [apllies_untill, setAplliesUntill] = React.useState(false);
  const [local, setLocal] = React.useState(false);
  const [non_local, setNonLocal] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [createdAt, setCreatedAt] = React.useState(false);
  const [updatedAt, setUpdatedAt] = React.useState(false);
  const [upcharges, setUpcharges] = useState({
    title: "",
    amount: "",
    apllies_on: "",
    apllies_untill: "",
    local: "",
    non_local: "",
    status: "",
    createdAt: "",
    updatedAt: "",
  });
  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  useEffect(() => {
    // onload function
    // console.log("1.0 moment.unix() => ", moment.utc().unix())
    // setAplliesOn(moment.utc().unix());
    // setAplliesUntill(moment.utc().unix());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.CONFIG_STORE_SETTING);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      window.location.href = "/dashboard";
    }
  }

  const handleAddUpchargeDates = async (
    event,
    type = "add",
    UpchargesInfo = {}
  ) => {
    event.preventDefault();
    setUpchargeDatesDetails(UpchargesInfo);
    setTitle("");
    setAmount("");
    setAplliesOn("");
    setAplliesUntill("");
    setLocal("");
    setNonLocal("");
    setStatus("");
    setCreatedAt("");
    setUpdatedAt("");
    setUpcharges("");
    setShowAddModal(true);
    setManageUpchargeDatesPageTitle("Upcharge Dates");
  };

  // Upcharge dates list
  const handleGetUpchargeDatesList = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    // setLoading(true);
    const reqBody = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
    };

    // setIsLoading(true);
    let storeSettingAccess = getAccessByModuleName(
      modules,
      MODULES.CONFIG_STORE_SETTING
    );

    if (storeSettingAccess && storeSettingAccess.permissions.read_access) {
      // const response = await settingsService.getUpchargeDatesList(reqBody);
      const response = await getUpchargeDatesList(reqBody);

      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else showToastMessage(undefined, response.data.message, "error", false);
    }
    // setIsLoading(false);
    // setLoading(false);
  };

  const handleManageUpchargeDatesClick = async (
    event,
    type = "add",
    upchargeDates = {}
  ) => {
    event.preventDefault();
    setUpchargeDatesDetails(upchargeDates);
    setShowAddModal(true);
    setManageUpchargeDatesPageTitle("Create Upcharge Dates");

    if (type === "edit") {
      setManageUpchargeDatesPageTitle("Edit Upcharge Dates");

      setTitle(upchargeDates.title);
      setAmount(upchargeDates.amount);
      setAplliesOn(upchargeDates.apllies_on);
      setAplliesUntill(upchargeDates.apllies_untill);
      setLocal(upchargeDates.local);
      setNonLocal(upchargeDates.non_local);
      setStatus(upchargeDates.status);
      setCreatedAt(upchargeDates.createdAt);
      setUpdatedAt(upchargeDates.updatedAt);
      setUpcharges(upchargeDates.upcharges);
    }
  };

  const handleManageUpchargeDates = async (event) => {
    // debugger
    // console.log("1.0 erorro => ", title , amount , apllies_on , apllies_untill , status, !local, !non_local);
    event.preventDefault();
    setSubmitStatus(true);
    if (
      title &&
      amount &&
      apllies_on &&
      apllies_untill &&
      (status === 0 || status === 1)
    ) {
      // let todayDate = moment().startOf("day").unix();
      if (local === false && non_local === false) {
        // console.log("1.0 erorro andar  => ", local, non_local);
        showToastMessage(
          undefined,
          "Please select at least one option 'Local' or 'Non Local' ",
          "error",
          false
        );
        return;
      }
      //   if (
      //     (apllies_on < todayDate && apllies_untill < todayDate) ||
      //     apllies_on > apllies_untill
      //   ) {
      //     showToastMessage(
      //       undefined,
      //       "Please enter applies until date greater then applies on date and both should be future date.",
      //       "error",
      //       false
      //     );
      //     return;
      //   }

      // console.log("1.0 apllies_on => ", apllies_on);
      // console.log("1.0 apllies_untill => ", apllies_untill);
      //Process

      //  1.0 apllies_on =>  1629590400
      //  1.0 apllies_untill =>  1629590399

      //  1.0 apllies_on after =>  1629570600
      //  1.0 apllies_untill after =>  1629656999

      let appOnDate = moment.unix(apllies_on).format("YYYY-MM-DD");
      //   let appTillDate = moment.unix(apllies_untill).format("DD-MM-YYYY");
      let appTillDate = moment.unix(apllies_untill).format("YYYY-MM-DD");
      //   body.end_date = moment(appTillDate).endOf("day").unix();

      // console.log(
      //   "1.0 apllies_on after => ",
      //   moment(appOnDate).startOf("day").unix()
      // );
      // console.log(
      //   "1.0 apllies_untill after => ",
      //   moment(appTillDate).endOf("day").unix()
      // );

      let testFromDate = DateTime.fromFormat(
        `${appOnDate} ${START_TIME_OF_DATE}`,
        "yyyy-LL-dd hh:mm a",
        {
          zone: UTC_ZONE,
        }
      )
        .setZone(PST_ZONE)
        .toSeconds();

      let testEndDate = DateTime.fromFormat(
        `${appTillDate} ${END_TIME_OF_DATE}`,
        "yyyy-LL-dd hh:mm a",
        {
          zone: UTC_ZONE,
        }
      )
        .setZone(PST_ZONE)
        .toSeconds();

      let reqBody = {
        title,
        amount,
        apllies_on: testFromDate,
        apllies_untill: testEndDate,
        // apllies_on: apllies_on,
        // apllies_untill: apllies_untill,
        local,
        non_local,
        status,
      };
      // console.log("appOnDate=>",testFromDate);
      // console.log("appTillDate=>",testEndDate);
      // console.log("reqBody=>",reqBody);
      // return false;
      let response;
      if (UpchargeDatesDetails.id) {
        reqBody.id = UpchargeDatesDetails.id;
        // response = await settingsService.upchargeDatesUpdate(reqBody);
        response = await upchargeDatesUpdate(reqBody);
      } else response = await upchargeDatesAdd(reqBody);
      // console.log(response);
      if (response && response.status === 200) {
        // debugger
        // console.log("hadslfk");
        showToastMessage(undefined, response.data.message, "success", false);
        setShowAddModal(false);
        setSubmitStatus(false);
        handleGetUpchargeDatesList(defaultFilter);

        // Clear all value
        setUpchargeDatesDetails({});
        setTitle(null);
        setAmount(null);
        setAplliesOn(null);
        setAplliesUntill(null);
        setLocal(null);
        setNonLocal(null);
        setStatus(null);
        setCreatedAt(null);
        setUpdatedAt(null);
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }

      // setIsLoading(false);
    }
  };

  const handleDeleteUpchargeDatesClick = async (event, id) => {
    event.preventDefault();
    setDeleteUpchargeDateId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteUpchargeDate = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // const response = await settingsService.upchargeDatesDeleteById(
    //   deleteUpchargeDateId
    // );
    const response = await upchargeDatesDeleteById(deleteUpchargeDateId);

    if (response && response.status === 200) {
      setDeleteUpchargeDateId(null);
      setShowDeleteModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      handleGetUpchargeDatesList(defaultFilter);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  // status update
  const handleStatusUpchargeClick = async (event, id, status) => {
    event.preventDefault();

    setStatusUpChargeDateId(id);
    setStatusOfUpchargeDate(status);
    setShowStatusModal(true);
  };

  const handleUpchargeDateStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfUpchargeDate === 1 ? 0 : 1,
      id: statusUpChargeDateId,
      table: "store_settings_upcharge_dates",
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setStatusUpChargeDateId(null);
      setStatusOfUpchargeDate(null);
      setShowStatusModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      handleGetUpchargeDatesList(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        isVisible: true,
        Cell: ({ value, row, column }) => {
          return `${row.original.title}`;
        },
      },
      {
        Header: "Upcharge Amount",
        accessor: "amount",
        isVisible: true,
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          return `$${row.original.amount}`;
        },
      },
      {
        Header: "Applies On",
        accessor: "apllies_on",
        isVisible: true,
        Cell: ({ value }) =>
          // convertUtcTimestampToPstDate(value, "ccc LLL dd, yyyy"),
          // moment.unix(value).format("ddd MMM DD, YYYY")
          DateTime.fromSeconds(value)
            .setZone(UTC_ZONE)
            .toFormat("ccc LLL dd, yyyy"),
      },
      {
        Header: "Applies Until",
        accessor: "apllies_untill",
        isVisible: true,
        Cell: ({ value }) =>
          // convertUtcTimestampToPstDate(value, "ccc LLL dd, yyyy"),
          // moment.unix(value).format("ddd MMM DD, YYYY")
          DateTime.fromSeconds(value)
            .setZone(UTC_ZONE)
            .toFormat("ccc LLL dd, yyyy")
      },
      {
        Header: "Local",
        accessor: "local",
        isVisible: true,
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          return `${row.original.local === true ? 1 : 0}`;
        },
      },
      {
        Header: "Non-Local",
        accessor: "non_local",
        isVisible: true,
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          return `${row.original.non_local === true ? 1 : 0}`;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        isVisible: true,
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.status}>
              {row.original.status === 1 ? (
                <div className={styles.Active}>Active</div>
              ) : (
                <div className={styles.Inactive}>Inactive </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          let accessCol = getAccessByModuleName(
            modules,
            MODULES.CONFIG_STORE_SETTING
          );
          return (
            <div>
              {accessCol && accessCol.permissions.write_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={EditIcon}
                  alt="Edit"
                  onClick={(e) =>
                    handleManageUpchargeDatesClick(e, "edit", row.original)
                  }
                />
              )}
              {accessCol && accessCol.permissions.delete_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={DeleteIcon}
                  alt="Delete"
                  onClick={(e) => handleDeleteUpchargeDatesClick(e, value)}
                />
              )}
              {accessCol && accessCol.permissions.write_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={ActivatedIcon}
                  alt="Active"
                  onClick={(e) =>
                    handleStatusUpchargeClick(e, value, row.original.status)
                  }
                />
              )}
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <div className="d-md-flex align-items-md-center mt-4 mb-5">
        <h3 className="bold mr-3">
          Upcharge Date
          {access.write_access && (
            <span
              className={`cursor-pointer ml-3 ${styles.searchIconWrapper}`}
              onClick={handleAddUpchargeDates}
            >
              <img className={styles.searchIcon} src={AddIcon} alt="" />
            </span>
          )}
        </h3>
      </div>
      <div>
        <ReactTable
          columns={columns}
          data={data}
          fetchData={handleGetUpchargeDatesList}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
          displayColumnVisibility={false}
        />
      </div>
      <div>
        <DeleteConfirmation
          showModal={showDeleteModal}
          title="Delete Upcharge Dates"
          message="Are you sure want to delete this Upcharge dates?"
          handleCancelClick={(event) => {
            event.preventDefault();
            setShowDeleteModal(false);
            setDeleteUpchargeDateId(null);
          }}
          handleSubmitClick={handleDeleteUpchargeDate}
        />
        <ManageUpchargeDatesModal
          showModal={showAddModal}
          pageTitle={manageUpchargeDatesTitle}
          submitStatus={submitStatus}
          title={title ? title : ""}
          amount={amount ? amount : ""}
          apllies_on={apllies_on ? apllies_on : ""}
          apllies_untill={apllies_untill ? apllies_untill : ""}
          local={local}
          non_local={non_local}
          status={status}
          createdAt={createdAt ? createdAt : ""}
          updatedAt={updatedAt ? updatedAt : ""}
          handleCancelClick={(e) => {
            setShowAddModal(false);
            setSubmitStatus(null);
          }}
          handleSubmitClick={handleManageUpchargeDates}
          onChange={(type, data) => {
            if (type === "title") setTitle(data);
            if (type === "amount") setAmount(data);
            if (type === "apllies_on") setAplliesOn(data);
            if (type === "apllies_untill") setAplliesUntill(data);
            if (type === "local") setLocal(data);
            if (type === "non_local") setNonLocal(data);
            if (type === "status") setStatus(data);
            if (type === "createdAt") setCreatedAt(data);
            if (type === "updatedAt") setUpdatedAt(data);
          }}
        />

        <StatusConfirmation
          showModal={showStatusModal}
          title={"Upcharge Dates"}
          message={"upcharge dates?"}
          buttonLabel={statusOfUpchargeDate ? "Inactivate" : "Activate"}
          statusFlag={statusOfUpchargeDate}
          customFlag={false}
          handleCancelClick={(event) => {
            event.preventDefault();
            setShowStatusModal(false);
            setStatusUpChargeDateId(null);
            setStatusOfUpchargeDate(null);
          }}
          handleSubmitClick={handleUpchargeDateStatus}
        />
      </div>
    </React.Fragment>
  );
};
export default UpchargeDates;
