import { callPostApi } from "./api";

export async function getAddressTypeList() {
      //     return this.interceptors.POST("address-types/list");
      try {
            const response = await callPostApi({ url: "address-types/list" });
            return response;
      } catch (error) {
            throw error;
      }
}

