import React, { useContext, useState } from "react";
import moment from "moment";
import { withRouter } from "react-router";
// Components
import ReactTable from "../../../Components/Table/Table";
// Context
import { AppContext } from "../../../Context/AppContext";

// Services

//scss
import styles from "./index.module.scss";
import { getCustomerPetalRewardsList } from "api/customer";
import { isBeforetwelveandsix } from "Helpers/date";

const PetalRewards = (props) => {
  const { showToastMessage } = useContext(AppContext);

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      customer_id: props.match.params.id,
    };
    if (postData.customer_id) {
      const response = await getCustomerPetalRewardsList(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count.length / pageSize));
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  let threeMark = (
    <span className={` ${styles.ProducatBadge} ${styles.ProducatBadgeRed}`}>
      !!!
    </span>
  );

  let oneMark = (
    <span className={` ${styles.ProducatBadge} ${styles.ProducatBadgeGreen}`}>
      !
    </span>
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Order",
        accessor: "order_number",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Products",
        accessor: "image",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          let time = isBeforetwelveandsix(row.original.delivery_date_time);
          return (
            <div className={` ${styles.ProducatImageWapper}`}>
              <img
                src={
                  row.original.order_products[0].variation_image === "" ||
                  row.original.order_products[0].variation_image === "null" ||
                  row.original.order_products[0].variation_image === null
                    ? ""
                    : `${row.original.order_products[0].variation_image}`
                }
                alt=""
              />
              {time.beforTwelve && threeMark}
              {!time.beforTwelve && time.beforSix && oneMark}
            </div>
          );
        },
      },
      {
        Header: "Product Name",
        accessor: "product_name",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return row.original.order_products[0].product_name;
        },
      },
      {
        Header: "Amount Spent",
        accessor: "petal_rewards_point_amount",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return `$${row.original.total.toFixed(0)}`;
        },
      },
      {
        Header: "Rewards Points",
        accessor: "earned_petal_rewards_point",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          if(row.original.order_yotpo_detail && row.original.order_yotpo_detail.yotpo_loyalty_earning_given){
            return (
              `${row.original.earned_petal_rewards_point.toFixed(0)}`
           );
          }else{
            return (
              0
           );
          }
        },
      },
      // {
      //   Header: "Amount In Wallet",
      //   accessor: "amount_wallet",
      //   isVisible: true,
      //   disableFilters: true,
      //   disableSortBy: true,
      //   Cell: ({ value, row, column }) => {
      //     return `$${row.original.amount_wallet.toFixed(2)}`;
      //   },
      // },
    ],
    []
  );

  return (
    <React.Fragment>
      <ReactTable
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        noDataText={"There is no data to display"}
        defaultFilter={defaultFilter}
        displayGlobalSearch={false}
        displayColumnVisibility={false}
      />
    </React.Fragment>
  );
};

export default withRouter(PetalRewards);
