import React from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// SCSS
import styles from "./index.module.scss";

// Component
import Text from "./../../FormControl/Text/Text";
import Select from "../../FormControl/Select/Select";
import RadioBoxLabel from "../../FormControl/RadioBoxLabel/RadioBoxLabel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ZipCodeModel = ({
  showModal,
  pageTitle,
  submitStatus,
  zip_code,
  status,
  is_deliverable,
  onChange,
  handleCancelClick,
  handleSubmitClick,
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <Text
                  type="number"
                  label="Zip code"
                  value={zip_code}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("zip_code", data)}
                  id="zip_code"
                />
              </div>
			  	{ pageTitle == "Edit Zipcode" && <div className="col-md-12 col-xs-12">
					<Select
						label="Status"
						parentClass='text-left'
						value={status}
						required={true}
						submitStatus={submitStatus}
						onChange={(data) => onChange('status', data)}
						options={[{ id: 0, title: 'In Active' }, { id: 1, title: 'Active' }]}
						disabled={false}
					/>
				</div>}
				{/* <div className={`${styles.bannerFormRadio}`}>
					<RadioBoxLabel
						type="radio"
						label="YES"
						value={is_deliverable}
						className={styles.redioButton}
						onChange={(data) => onChange('is_deliverable', data)}
						// selectedValue={is_deliverable}
						// aria-disabled="true"
					/>
					<RadioBoxLabel
						label="NO"
						type="radio"
						value={is_deliverable}
						// selectedValue={is_deliverable}
						onChange={(data) => onChange('is_deliverable', data)}
					/>
				</div> */}
            </div>
            <a
              href="/"
              data-dismiss="modal"
              onClick={handleSubmitClick}
              className="btn gray-btn d-inline-block mt-4 mb-4 mr-2"
            >
              Save
            </a>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ZipCodeModel;
