import Interceptors from "../Interceptors/Interceptors";

class CmsPages {
  constructor() {
    this.interceptors = new Interceptors();
  }

  async getCmsPagesList(data) {
    return this.interceptors.POST("page", data);
  }

  async getCmsPagesDetail(pageId) {
    return this.interceptors.GET("page/" + pageId);
  }

  async createCmsPages(postData) {
    return this.interceptors.POST("page/add", postData);
  }

  async updateCmsPages(postData,pageId) {
    return this.interceptors.PUT("page/"+pageId, postData);
  }

  async deleteCmsPage(id) {
    return this.interceptors.DELETE("page/" + id);
  }

}

export default CmsPages;
