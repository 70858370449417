import React from "react";
//scss
import styles from "./index.module.scss";

const DesignerStatusSummary = (props) => {  

  let gridValue = (props.isToday) ? 'col-lg-2 col-md-2' : 'col-lg-3 col-md-3';

  return (
    <div className={styles.DesignerStatusCard}>
    <div className={`row`}>
      <div
        className={`${styles.DesignerStatusItem} col-12 col-lg-3 col-md-3 `}
      >
        <div className={`${styles.DesignerStatusDetail}`}>
          {props.totalUnmade}
        </div>
        <div className={`${styles.DesignerStatusLabel}`}>
          Total Unmade
        </div>
      </div>
      <div
        className={`${styles.DesignerStatusItem} col-12 ${gridValue} `}
      >
        <div className={`${styles.DesignerStatusDetail}`}>
          {props.unassignedOrders}
        </div>
        <div className={`${styles.DesignerStatusLabel}`}>
          Unassigned Orders
        </div>
      </div>
      <div
        className={`${styles.DesignerStatusItem} col-12 ${gridValue} `}
      >
        <div className={`${styles.DesignerStatusDetail}`}>
          {props.assignedOrders}
        </div>
        <div className={`${styles.DesignerStatusLabel}`}>
          Assigned Orders
        </div>
      </div>
      <div
        className={`${styles.DesignerStatusItem} col-12 ${gridValue} `}
      >
        <div className={`${styles.DesignerStatusDetail}`}>
          {props.unprocessedOrders}
        </div>
        <div className={`${styles.DesignerStatusLabel}`}>
          Unprocessed Orders
        </div>
      </div>
      {
        props.isToday &&
        <div
          className={`${styles.DesignerStatusItem} col-12 col-lg-3 col-md-3 cursor-pointer `}
          onClick={props.openDesignerPopup}
        >
          <div className={`${styles.DesignerStatusDetail}`}>
            {props.designersClockedIn}
          </div>
          <div className={`${styles.DesignerStatusLabel}`}>
            Designers Clocked In
          </div>
        </div>
      }      
    </div>
      </div>
  );
};

export default DesignerStatusSummary;
