// Packages
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import "moment-timezone";

// Context
import { AppContext } from "./../../Context/AppContext";

// Components
import Header from "./../../Components/Header/Header";
import Notification from "./../../Components/Modal/Notification/Notification";
import AddChangeRequest from "./../../Components/Modal/ChangeRequest/Add/ChangeRequest";

// Services
// import Clock from "./../../Services/Clock/Clock";
import { applyChangeRequest, clockInOut, continueBreak, todaysDetails } from "api/clock";

const TimeClock = () => {
  const [clockDetails, setClockDetails] = useState({});
  const [changeRequestClock, setChangeRequestClock] = useState({});
  const [showEarlyBreakModal, setEarlyBreakModal] = useState(false);
  const [showChangeRequestModal, setChangeRequestModal] = useState(false);
  const [earlyBreakMessage, setEarlyBreakMessage] = useState("");
  const [time, setTime] = useState("");
  const [reason, setReason] = useState("");
  const [changeRequestClockDetails, setChangeRequestClockDetails] = useState(
    ""
  );
//   const clockService = new Clock();

  const { showToastMessage, setIsLoading, timezone } = useContext(AppContext);

  const handleGetClockDetails = async () => {
    setIsLoading(true);
    // const response = await clockService.todaysDetails();
    const response = await todaysDetails();

    if (response && response.status === 200) {
      setClockDetails(response.data.data);
    } else if (response && response.status === 404) {
    } else {
      showToastMessage(undefined, response?.data?.message, "error", false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    handleGetClockDetails();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClockInOut = async (event, type) => {
    event.preventDefault();

    let reqBody = { type };
    setIsLoading(true);
    // const response = await clockService.clockInOut(reqBody);
    const response = await clockInOut(reqBody);

    if (response && response.status === 200) {
      if (response.data.data.is_early_break) {
        setEarlyBreakModal(true);
        setEarlyBreakMessage(
          `You're trying to clock-in back from lunch break that's less than ${response.data.data.total_break_duration} minutes. Remain on your lunch break for just ${response.data.data.remaining_break_duration} more minutes.`
        );
      }
      await handleGetClockDetails();
    } else showToastMessage(undefined, response?.data?.message, "error", false);

    setIsLoading(false);
  };

  const handleContinueBreak = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    // const response = await clockService.continueBreak();
    const response = await continueBreak();

    if (response && response.status === 200) {
      setEarlyBreakModal(false);
      await handleGetClockDetails();
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const handleChangeRequest = async (event) => {
    event.preventDefault();

    let reqBody = {
      reason: reason,
      type: changeRequestClockDetails.clock_in ? "IN" : "OUT",
      clock_times_id: changeRequestClockDetails.id,
      user_id: clockDetails.user_id,
    };

    let changeDay;
    if (changeRequestClockDetails.clock_in) {
      changeDay = moment
        .unix(changeRequestClockDetails.clock_in)
        .tz(timezone)
        .format("YYYY-MM-DD");
    } else {
      changeDay = moment
        .unix(changeRequestClockDetails.clock_out)
        .tz(timezone)
        .format("YYYY-MM-DD");
    }

    let changeTime = moment(time).startOf("minute").format("HH:mm");
    reqBody.time = moment
      .tz(changeDay + " " + changeTime, timezone)
      .utc()
      .unix("X");

    setIsLoading(true);
    // const response = await clockService.applyChangeRequest(reqBody);
    const response = await applyChangeRequest(reqBody);

    if (response && response.status === 200) {
      setChangeRequestModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      setTime("");
      setReason("");

      await handleGetClockDetails();
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Header title="Clock In (Clock Out)" />

      <div id="MainContent" className="main-content">
        <div className="current-date text-right">
          {timezone && moment().tz(timezone).format("ddd MMM DD, YYYY")}
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              {((clockDetails && !clockDetails.clock_time) ||
                (clockDetails &&
                  clockDetails.clock_time &&
                  clockDetails.clock_time[clockDetails.clock_time.length - 1] &&
                  clockDetails.clock_time[clockDetails.clock_time.length - 1][
                    "clock_in"
                  ] === 0)) && (
                <div
                  className="clockin-out d-flex justify-content-center align-items-center mx-auto position-relative"
                  data-toggle="modal"
                  data-target="#notification"
                  onClick={(event) => handleClockInOut(event, "0")}
                >
                  {" "}
                  Clock in{" "}
                </div>
              )}
              {clockDetails &&
                clockDetails.clock_time &&
                clockDetails.clock_time[clockDetails.clock_time.length - 1] &&
                clockDetails.clock_time[clockDetails.clock_time.length - 1][
                  "clock_out"
                ] === 0 && (
                  <div
                    className="clockin-out d-flex justify-content-center align-items-center mx-auto position-relative"
                    data-toggle="modal"
                    data-target="#notification"
                    onClick={(event) => handleClockInOut(event, "1")}
                  >
                    {" "}
                    Clock out{" "}
                  </div>
                )}

              {clockDetails &&
                clockDetails.clock_time &&
                clockDetails.clock_time.length > 0 &&
                clockDetails.clock_time.map((clockTimesObj, index) => {
                  return (
                    <div className="row mb-5" key={index}>
                      <div className="col-3 col-md-4 in-out-label pt-2">
                        {clockTimesObj.clock_in ? "In" : "Out"} -
                      </div>
                      <div
                        className={`col-4 pt-2 ${
                          clockTimesObj.clock_in ? "in-time" : "out-time"
                        }`}
                      >
                        {timezone && clockTimesObj && clockTimesObj.clock_in > 0
                          ? moment
                              .unix(clockTimesObj.clock_in)
                              .tz(timezone)
                              .format("hh:mm A")
                          : timezone &&
                            clockTimesObj &&
                            clockTimesObj.clock_out > 0
                          ? moment
                              .unix(clockTimesObj.clock_out)
                              .tz(timezone)
                              .format("hh:mm A")
                          : ""}
                      </div>

                      <div className="col-5 col-md-4">
                        {((clockTimesObj.clock_in > 0 &&
                          clockTimesObj.changed_request_in_status === 1) ||
                          (clockTimesObj.clock_out > 0 &&
                            clockTimesObj.changed_request_out_status ===
                              1)) && (
                          <a
                            href="/"
                            onClick={(event) => event.preventDefault()}
                            className="light-grey-btn d-block text-center"
                          >
                            Pending
                          </a>
                        )}
                        {((clockTimesObj.clock_in > 0 &&
                          clockTimesObj.changed_request_in_status === 2) ||
                          (clockTimesObj.clock_out > 0 &&
                            clockTimesObj.changed_request_out_status ===
                              2)) && (
                          <a
                            href="/"
                            onClick={(event) => event.preventDefault()}
                            className="light-grey-btn d-block text-center"
                          >
                            Approved
                          </a>
                        )}
                        {((clockTimesObj.clock_in > 0 &&
                          clockTimesObj.changed_request_in_status === 3) ||
                          (clockTimesObj.clock_out > 0 &&
                            clockTimesObj.changed_request_out_status ===
                              3)) && (
                          <a
                            href="/"
                            onClick={(event) => event.preventDefault()}
                            className="light-grey-btn d-block text-center"
                          >
                            Rejected
                          </a>
                        )}
                        {
                          // 0 => No, 1 => Pending, 2 => Accepted, 3 => Rejected
                          ((clockTimesObj.clock_in > 0 &&
                            clockTimesObj.changed_request_in_status === 0) ||
                            (clockTimesObj.clock_out > 0 &&
                              clockTimesObj.changed_request_out_status ===
                                0)) && (
                            <a
                              href="/"
                              className="light-grey-btn d-block text-center"
                              data-toggle="modal"
                              data-target="#requestChange"
                              onClick={(event) => {
                                event.preventDefault();
                                setChangeRequestModal(true);
                                setChangeRequestClockDetails(clockTimesObj);
                                setChangeRequestClock(clockTimesObj);
                              }}
                            >
                              Request Change
                            </a>
                          )
                        }
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <Notification
        showModal={showEarlyBreakModal}
        message={earlyBreakMessage}
        handleCancelClick={(event) => {
          event.preventDefault();
          setEarlyBreakModal(false);
          handleGetClockDetails();
        }}
        handleSubmitClick={handleContinueBreak}
      />

      <AddChangeRequest
        showModal={showChangeRequestModal}
        clockDetails={changeRequestClock}
        handleCancelClick={(event) => {
          event.preventDefault();
          setChangeRequestModal(false);
          handleGetClockDetails();
          setTime("");
          setReason("");
        }}
        reason={reason}
        setReason={(data) => setReason(data)}
        time={time}
        setTime={(data) => setTime(data)}
        handleSubmitClick={handleChangeRequest}
      />
    </React.Fragment>
  );
};

export default TimeClock;
