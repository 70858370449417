import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import moment from "moment";

import CardContent from "@material-ui/core/CardContent";

// Components
import Header from "../../../Components/Header/Header";
import Goto from "../../../Components/Cms/Goto";
import PrimaryInformation from "../../../Components/Blogs/PrimaryInformation";
import SimilarBlogs from "../../../Components/Blogs/SimilarBlogs";
import ProductCategory from "../../../Components/Products/ProductCategory";
import Banner from "../../../Components/Cms/Banner";
import Seo from "../../../Components/Cms/Seo";
import OGInfo from "../../../Components/Cms/OgInfo";
import Twitterinfo from "../../../Components/Cms/TwitterInfo";
// import Editer from "../../../Components/Cms/Schema";

//scss
import classes from "./index.module.scss";

// Context
import { getAccessByModuleName } from "../../../Routes/Routes";
import { AppContext } from "../../../Context/AppContext";
import { MODULES } from "../../../Helpers/Constants";
import { blogDataCall } from "../../../Hooks/cms/blogDataCall";
import { useBlog } from "../../../Context/Cms-blog/useBlog";

// Services
import BlogsApi from "../../../Services/Blogs/Blogs";
import {
  getBlogDetail,
  createBlog,
  updateBlog,
  getCategoryLookup,
  getAllBlogsList,
} from "../../../api/blogs";

import Text from "../../../Components/FormControl/Text/Text";
import { BlogForm } from "Hooks/cms/blogFormFormik";

const AddBlog = (props) => {
  const gotoList = [
    { id: "primary-information", title: "Primary Information" },
    { id: "banner-section", title: "Banner Style" },
    { id: "seo-section", title: "SEO" },
  ];

  const { showToastMessage, modules, setIsLoading } = useContext(AppContext);

  const [access, setAccess] = useState({});

  const [updateBlogId, setUpdateBlogId] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [blogList, setBlogList] = useState([]);

  const {
    blogState,
    setPageForView,
    setBlogAllData,
    clearBlogState,
    setBlogPrimaryData,
    checkBlogPrimaryData,
    setCategoryData,
    deleteCategoryData,
    setBlogBannerData,
    addNewBanner,
    removeBanner,
    setBannerData,
    checkBannerData,
    setSeoData,
    checkSeoData,
    setOgInfoData,
    checkOgInfoData,
    setTwitterInfoData,
    checkTwitterInfoData,
    setSchemaData,
    // checkSchemaData,
    setSimilarBlogData,
  } = useBlog();
  
  const blogData = blogState.setBlogAllData ? blogState.setBlogAllData : {};
  let blogInitalValues = {
    title: blogData.title ? blogData.title : "",
    slug: blogData.slug ? blogData.slug : "",
    detail: blogData.detail ? blogData.detail : "",
    description: blogData.description ? blogData.description : "",
    keywords: blogData.keywords ? blogData.keywords : "",
    author_name: blogData.author_name ? blogData.author_name : "",
    read_time: blogData.read_time ? blogData.read_time : "",
    blog_date: blogData.blog_date
    ? moment(blogData.blog_date).format("MM-DD-YYYY")
    : "",
    url_category_slug: blogData.url_category_slug ? blogData.url_category_slug : "",
  };
  const submit = (values) => {};
  const cbSubmit = (values) => {
    setBlogPrimaryData(values);
  };
  
  const { formik: BlogFormik } = BlogForm(
    submit,
    blogInitalValues,
    cbSubmit,
    checkBlogPrimaryData
    );
  const blogsService = new BlogsApi();

  useEffect(() => {
    clearBlogState();
    const { updateId, view } = props.match.params;
    setUpdateBlogId(updateId);
    getAllCategoryList();
    getAllBlogList();
    setPageForView(0);
    if (view !== undefined && view != null) {
      setPageForView(1);
    }
  }, []);

  useEffect(() => {
    if (updateBlogId) {
      handleGetBlogDetail(updateBlogId);
    }
  }, [updateBlogId]);

  const getAllCategoryList = async () => {
    setIsLoading(true);
    // const response = await blogsService.getCategoryLookup();
    const response = await getCategoryLookup();
    if (response && response.status === 200) {
      let categoryDataArr = [];
      response.data.data?.forEach((d, i) => {
        let tempArr = {
          categoryId: d.id,
          title: d.title,
          category_hierarchy_id: d.id,
        };
        categoryDataArr.push(tempArr);
      });
      setCategoryList(categoryDataArr);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const getAllBlogList = async () => {
    setIsLoading(true);
    // const response = await blogsService.getAllBlogsList();
    const response = await getAllBlogsList();
    if (response && response.status === 200) {
      setBlogList(response.data.data.rows);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handleGetBlogDetail = async (updateId) => {
    setIsLoading(true);
    // const response = await blogsService.getBlogDetail(updateId);
    const response = await getBlogDetail(updateId);
    if (response && response.status === 200) {
      if (response.data.data) {
        let tempResponseData = { ...response.data.data };

        setBlogAllData(tempResponseData);

        tempResponseData.cms_blog_mapping_categories?.forEach((d, i) => {
          let tempArr = {
            categoryId: d.category_id,
            title: d.cms_blog_category.title,
            category_hierarchy_id: d.category_id,
          };
          setCategoryData(tempArr);
        });

        tempResponseData.banners?.forEach((d, i) => {
          tempResponseData.banners[i]["module"] = "blogs";
          setBlogBannerData(tempResponseData.banners[i]);
        });

        setSeoData(tempResponseData.seo);
        setOgInfoData(tempResponseData.seo);
        setTwitterInfoData(tempResponseData.seo);
        setSchemaData(tempResponseData.seo);
        setBlogPrimaryData(tempResponseData);

        tempResponseData.similar_blogs?.forEach((d, i) => {
          let tempArr = {
            similar_blog_id: d.similar_blog_id,
            title: d.cms_blog.title,
            banners: d.cms_blog?.banners,
          };
          setSimilarBlogData(tempArr);
        });
      }
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handleSubmitClick = async (event) => {
    event.preventDefault();

    const primaryInfoSubmit = document.querySelector(
      ".primary-info-submit-button"
    );
    if (primaryInfoSubmit) {
      primaryInfoSubmit.click();
    }

    let showBannerError = false;
    blogState.banners.forEach((bannerData, index) => {
      const bannerSubmit = document.getElementById(
        `banner-submit-button-${index}`
      );
      if (bannerSubmit) {
        bannerSubmit.click();
      }
      if (!bannerData.image) {
        showBannerError = true;
      }
    });

    // const seoSubmit = document.querySelector(".seo-submit-button");
    // if (seoSubmit) {
    //   seoSubmit.click();
    // }

    // const ogInfoSubmit = document.querySelector(".og-info-submit-button");
    // if (ogInfoSubmit) {
    //   ogInfoSubmit.click();
    // }

    // const twitterSubmit = document.querySelector(".twitter-submit-button");
    // if (twitterSubmit) {
    //   twitterSubmit.click();
    // }

    // const schemaSubmit = document.querySelector(".schema-submit-button");
    // if (schemaSubmit) {
    //   schemaSubmit.click();
    // }

    // console.log('blogState ===> ', blogState);
    if (blogState.blogError) {
      showToastMessage(
        undefined,
        `Please enter required blog details`,
        "error",
        false
      );
    } else if (blogState.banners.length > 0 && showBannerError) {
      showToastMessage(
        undefined,
        `Please enter required banner details`,
        "error",
        false
      );

      // }else if (blogState.seo === null) {
      //   showToastMessage(
      //     undefined,
      //     `Please enter required seo details`,
      //     "error",
      //     false
      //   );
      // } else if (blogState.seoError) {
      //   showToastMessage(
      //     undefined,
      //     `Please enter required seo details`,
      //     "error",
      //     false
      //   );
      // } else if (blogState.ogInfoError) {
      //   showToastMessage(
      //     undefined,
      //     `Please enter required og info details`,
      //     "error",
      //     false
      //   );
      // } else if (blogState.twitterError) {
      //   showToastMessage(
      //     undefined,
      //     `Please enter required twitter info details`,
      //     "error",
      //     false
      //   );
      // } else if (blogState.schemaError) {
      //   showToastMessage(
      //     undefined,
      //     `Please enter required schema details`,
      //     "error",
      //     false
      //   );
    } else {
      const reqBody = blogDataCall(blogState);
      // console.log("handleSubmitClick ===> ", blogState, reqBody);
      // return false;
      setIsLoading(true);
      if (updateBlogId !== "" && updateBlogId !== undefined) {
        reqBody.id = +updateBlogId;
        // let response = await blogsService.updateBlog(reqBody);
        let response = await updateBlog(reqBody);
        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          clearBlogState();
          props.history.push("/cms/blog-management");
        } else {
          showToastMessage(undefined, response.data.message, "error", false);
        }
        setIsLoading(false);
      } else {
        // let response = await blogsService.createBlog(reqBody);
        let response = await createBlog(reqBody);
        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          clearBlogState();
          props.history.push("/cms/blog-management");
        } else {
          showToastMessage(undefined, response.data.message, "error", false);
        }
        setIsLoading(false);
      }
    }
  };

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  // Access Contorol functionm call
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.BLOGS);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.write_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  return (
    <React.Fragment>
      <Header title="Blog Page" />
      <div className="row">
        <div id="MainContent" className="col-8">
          <div className={`${classes.cardBody}`}>
            <CardContent>
              <PrimaryInformation
                data={blogState.setBlogAllData}
                setBlogPrimaryData={setBlogPrimaryData}
                checkBlogPrimaryData={checkBlogPrimaryData}
              />
              <Banner
                bannerModule="blogs"
                bannersData={blogState.banners}
                addNewBanner={addNewBanner}
                removeBanner={removeBanner}
                setBannerData={setBannerData}
                checkBannerData={checkBannerData}
                uploadImageType="BLOGS_BANNER"
              />
              <SimilarBlogs
                similarBlogData={blogState.similarBlogData}
                allBlogList={blogList}
                updateBlogId={updateBlogId}
              />
              <Seo
                data={blogState.seo}
                setSeoData={setSeoData}
                checkSeoData={checkSeoData}
              />
              <OGInfo
                data={blogState.seo}
                setOgInfoData={setOgInfoData}
                checkOgInfoData={checkOgInfoData}
              />
              <Twitterinfo
                data={blogState.seo}
                setTwitterInfoData={setTwitterInfoData}
                checkTwitterInfoData={checkTwitterInfoData}
              />
              {/* <Editer 
                data={blogState.seo}
                setSchemaData={setSchemaData}
                checkSchemaData={checkSchemaData}
              /> */}
            </CardContent>
          </div>
        </div>
        <div className="col-4">
          <Goto
            handleSubmitClick={handleSubmitClick}
            notShowSaveButton={blogState.setPageForView}
            gotoList={gotoList}
          />
          <div style={{margin:"28px"}}>
            <Text
              type="text"
              label="Category URL Slug"
              value={BlogFormik.values.url_category_slug} 
              onChange={(data) => 
                BlogFormik.setValues((v) => {
                  return { ...v, url_category_slug: data };
                })
              }
              touched={true}
              error={BlogFormik.errors.url_category_slug}
              id="url_category_slug"
            />
          </div>
          <ProductCategory
            categoryList={categoryList}
            setPageForView={blogState.setPageForView}
            selectedCategoryList={blogState.categories}
            setCategoryHierarchyData={setCategoryData}
            deleteCategoryHierarchyData={deleteCategoryData}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(AddBlog);
