import { CouponAction } from "./couponAction";

const clrStateCoupon = {
  coupon_code: "",
  coupon_type: "Percentage",
  coupon_value: "",
  // coupon_apply_to: "Specific Collection",
  coupon_apply_to: "Specific Product",
  coupon_redeem_for_mobile:false,
  coupons_specific_collections: [],
  usage_limit_one_per_user: false,
  usage_limit_number_of_time: null,
  active_start_date: "",
  active_end_date: "",
  section_info: {
    category_section: false,
    product_section: false,
    order_section: false,
  },
}

function CouponReducer(state, action) {
  switch (action.type) {

    case CouponAction.SET_COUPON_CODE_NAME:
      return {
        ...state,
        coupon_code: action.payload,
      };

    case CouponAction.SET_COUPON_CODE_TYPE:
      return {
        ...state,
        coupon_type: action.payload,
      };

    case CouponAction.SET_COUPON_CODE_VALUE:
      return {
        ...state,
        coupon_value: action.payload,
      };

    case CouponAction.SET_COUPON_APPLY_TO:
      return {
        ...state,
        coupon_apply_to: action.payload,
      };

    case CouponAction.SET_COUPON_REDEEM_FOR_MOBILE:
      return {
        ...state,
        coupon_redeem_for_mobile: action.payload,
      };

    case CouponAction.SET_USAGE_LIMIT_PER_USER:
      return {
        ...state,
        usage_limit_one_per_user: action.payload,
      };

    case CouponAction.SET_START_DATE:
      return {
        ...state,
        active_start_date: action.payload,
      };

    case CouponAction.SET_END_DATE:
      return {
        ...state,
        active_end_date: action.payload,
      };

    case CouponAction.SET_SELECTED_DATA:
      let selectedData = state.coupons_specific_collections ? [...state.coupons_specific_collections] : [];
      selectedData.push(action.payload);
      return {
        ...state,
        coupons_specific_collections: selectedData,
      };

    case CouponAction.DELETE_SIMILAR_BLOG_DATA:
      let selectedDeleteData = [...state.coupons_specific_collections];
      selectedDeleteData.splice(action.payload, 1);
      return {
        ...state,
        coupons_specific_collections: selectedDeleteData,
      };

    case CouponAction.DISABLE_SECTION_ON_SELECT:
      return {
        ...state,
        section_info: action.payload,
      };

    case CouponAction.EMPTY_SELECTED_DATA:

      return {
        ...state,
        coupons_specific_collections: [],
      };

    case CouponAction.CLEAR_COUPON_DATA:
      return clrStateCoupon;

    case CouponAction.SET_COUPON_USE_COUNT:
      return {
        ...state,
        usage_limit_number_of_time: action.payload,
      };

    default:
      return state;
  }
}

export default CouponReducer;
