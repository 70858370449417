import React from 'react';

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// SCSS
import styles from './PayrollDate.module.scss';

// Components
import Date from './../../FormControl/Date/Date';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PayrollDate = ({ showModal, onChange, payrollDateId, payrollStartDate, payrollEndDate, submitStatus, handleCancelClick, handleSubmitClick, message }) => {
    return (
        <React.Fragment>
            <Dialog
                open={showModal}
                TransitionComponent={Transition}
                onClose={handleCancelClick}
                classes={{
                    paper: styles.modalDialog
                }}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogContent
                    className={styles.modalContent}
                >
                    <div className={`text-center ${styles.modalBody}`}>
                        {
                            payrollDateId &&
                            <h5 className={`my-3 text-center ${styles.modalTitle}`}>Edit Monthly Payroll Date</h5>
                        }
                        {
                            !payrollDateId &&
                            <h5 className={`my-3 text-center ${styles.modalTitle}`}>Add Monthly Payroll Date</h5>
                        }
                        <div className={styles.formWrapper}>
                            <div className="row">
                                <div className="col-sm-6 col-xs-12">
                                    <Date
                                        label="Payroll start date"
                                        value={payrollStartDate}
                                        dateFormat="ddd MMM-DD-YYYY"
                                        type="date"
                                        onChange={(value) => onChange('payrollStartDate', value)}
                                        checkPassDate="true"
                                    />
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    <Date
                                        label="Payroll end date"
                                        value={payrollEndDate}
                                        dateFormat="ddd MMM-DD-YYYY"
                                        type="date"
                                        onChange={(value) => onChange('payrollEndDate', value)}
                                        checkPassDate="true"
                                    />
                                </div>
                            </div>
                        </div>
                        <a href="/" data-dismiss="modal" onClick={handleSubmitClick} className="btn gray-btn d-inline-block mb-3 mr-2"> Save </a>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default PayrollDate;
