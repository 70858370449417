import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import EditIcon from "../../../Assets/images/edit.svg";
import Header from "../../../Components/Header/Header";
import ManageUserModal from "../../../Components/Modal/Customer/Add/Add";
import OrderhistoryCard from "../../../Components/OrderhistoryCard/OrderhistoryCard";
import SwitchBox from "../../../Components/FormControl/SwitchBox/SwitchBox";
import { AppContext } from "../../../Context/AppContext";
import { DEFAULT_COUNTRY_CODE, MODULES, PAYMENT_METHODS } from "../../../Helpers/Constants";
import { getAccessByModuleName } from "../../../Routes/Routes";
import CustomersApi from "../../../Services/Customers/Customers";
import styles from "./index.module.scss";
import { formatUsPhone,checkValidEmailId } from "../../../Hooks/commanUtility";
import PetalRewards from "./petalRewards";
import PetalRewardsForm from "./petalRewardsForm";
import LoadingScreen from "Components/Loading/LoadingScreen";
import PaymentCardApi from "Services/PaymentCard/PaymentCard";
import PaymentHistoryCard from "Components/PaymentHistoryCard/PaymentHistoryCard";
import PaymentCardEditModal from "Components/Modal/PaymentCard/PaymentCardEditModal";
import PayabliPaymentCardEditModal from "Components/Modal/PayabliPaymentCardEdit/payabliPaymentCardEdit";

const CustomerDetail = (props) => {
  const { showToastMessage, modules } = useContext(AppContext);
  const [userDetails, setUserDetails] = useState(null);
  const [paymentCardDetails, setPaymentCardDetails] = useState(null);
  const [modalPaymentData, setmodalPaymentData] = useState(null);

  // const [userdetailsGet, setUserdetailsGet] = useState(false);

  // Add / Edit
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone1, setPhone1] = useState(null);
  const [countryCode1, setCountryCode1] = useState(DEFAULT_COUNTRY_CODE);
  const [phone2, setPhone2] = useState(null);
  const [countryCode2, setCountryCode2] = useState(DEFAULT_COUNTRY_CODE);
  // const [address, setAddress] = useState(null);
  // const [city, setCity] = useState(null);
  // const [state, setState] = useState(null);
  // const [country, setCountry] = useState(null);
  // const [zipCode, setZipCode] = useState(null);
  const [uniqueAboutCustomer, setUniqueAboutCustomer] = useState(null);
  const [isHouseAccount, setIsHouseAccount] = useState(0);
  const [isBusinessAccount, setIsBusinessAccount] = useState(0) ;

  const [showAddModal, setShowAddModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState({isOpen:false, payment_method:""});

  const [submitStatus, setSubmitStatus] = useState(false);
  const [manageUserPageTitle, setManageUserPageTitle] = useState("");

  const [access, setAccess] = useState({});
  const [newOrderAccess, setNewOrderAccess] = useState({});

  const [submitClick,setSubmitClick] = useState(false);


  const customersService = new CustomersApi();
  const paymentService = new PaymentCardApi();


  useEffect(async () => {
    let accountAccess = getAccessByModuleName(modules, MODULES.ACCOUNT_LOOKUP);
    if (accountAccess && accountAccess.permissions.write_access) {
      await handleGetUserData();
    }
  }, [props.match.params.id,submitClick]);

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);
  useEffect(()=>{
    handleGetPaymentCard();
  },[])
  const handleGetPaymentCard = async()=>{
    const getPaymentBody={
      id:props.match.params.id
    }
     await paymentService.getPaymentCardlist(getPaymentBody).then((data)=>{
      if (data && data.status === 200) {
        setPaymentCardDetails(data.data.data.rows);
      } else {
        showToastMessage(undefined, data.data.data.message, "error", false);
      }
     }).catch((error)=>{
        showToastMessage(undefined, error.data.message, "error", false);
     })
  }

  const handleGetUserData = async () => {
    const { id } = props.match.params;
    if (!id) return;

    const postData = {
      id,
    };
    await customersService
      .getCustomerWithOrderDetails(postData)
      .then((data) => {
        if (data && data.status === 200) {
          setUserDetails(data.data.data);
        } else {
          showToastMessage(undefined, data.data.message, "error", false);
        }
        setSubmitClick(false)
        // setLoading(false);
        // setUserdetailsGet(true);
      })
      .catch((err) => {
        showToastMessage(undefined, err.data.message, "error", false);
        // setUserdetailsGet(true);
      });
  };

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.ACCOUNT_LOOKUP);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.write_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
    let newOrderAccess = getAccessByModuleName(modules, MODULES.NEW_ORDER);
    if (newOrderAccess) setNewOrderAccess(newOrderAccess.permissions);
  }

  const handleManageHeaderClick = async (event, link) => {
    event.preventDefault();

    window.location = link;
  };

  const handleSeeAllClick = async (event, link) => {
    event.preventDefault();

    window.location = link;
  };

  const handleManageUserClick = async (event) => {
    event.preventDefault();

    setShowAddModal(true);
    setManageUserPageTitle("Edit Personal Information");
    setFirstName(userDetails.customer?.first_name);
    setLastName(userDetails.customer?.last_name);
    setEmail(userDetails.customer?.email);
    setPhone1(userDetails.customer?.phone);
    setCountryCode1(userDetails.customer?.country_code);
    setPhone2(userDetails.customer?.alt_phone);
    setCountryCode2(userDetails.customer?.alt_country_code);
    // setAddress(
    //   userDetails.customer && userDetails.customer.customer_cards.length
    //     ? userDetails.customer.customer_cards[0].address_line_1
    //     : ""
    // );
    // setCountry(
    //   userDetails.customer && userDetails.customer.customer_cards.length
    //     ? userDetails.customer.customer_cards[0].country
    //     : ""
    // );
    // setState(
    //   userDetails.customer && userDetails.customer.customer_cards.length
    //     ? userDetails.customer.customer_cards[0].state
    //     : ""
    // );
    // setCity(
    //   userDetails.customer && userDetails.customer.customer_cards.length
    //     ? userDetails.customer.customer_cards[0].city
    //     : ""
    // );
    // setZipCode(
    //   userDetails.customer && userDetails.customer.customer_cards.length
    //     ? userDetails.customer.customer_cards[0].zip_code
    //     : ""
    // );
    setUniqueAboutCustomer(userDetails.customer.unique_about_customer);
    setIsHouseAccount(userDetails.customer.is_house_account);
    setIsBusinessAccount(userDetails.customer.is_business_account)
  };

  const handleManageUser = async (event) => {
    event.preventDefault();
    setSubmitStatus(true);

    // console.log('handleManageUser ===>', firstName, lastName, email, phone1, phone2, address, city, country, state, zipCode, uniqueAboutCustomer);
    if (
      firstName?.trim() &&
      lastName?.trim() &&
      email?.trim() &&
      phone1?.trim()
      // address &&
      // city &&
      // country &&
      // state &&
      // zipCode &&
      // uniqueAboutCustomer?.trim()
    ) {
      if(!checkValidEmailId(email)){
        showToastMessage(
          undefined,
          "Please enter a valid email id",
          "error",
          false
        );
        return false;
      }

      let customer = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        unique_about_customer: uniqueAboutCustomer,
        is_house_account: isHouseAccount,
        is_business_account: isBusinessAccount,
        phone: phone1,
        country_code: countryCode1,
        is_business_account: isBusinessAccount,
      };

      // if (phone2 && phone2 !== null) {
      customer["alt_phone"] = phone2;
      customer["alt_country_code"] = countryCode2;
      // }
      let reqBody = {
        customer: customer,
        // customer_cards: {
        //   address_line_1: address,
        //   country: country,
        //   state: state,
        //   city: city,
        //   zip_code: zipCode,
        // },
      };
      let response;
      if (userDetails.customer.id) {
        reqBody["id"] = userDetails.customer.id;
        response = await customersService.updateCustomer(reqBody);
      }
      // console.log('reqBody ===>', reqBody, response);

      if (response && response.status === 200) {
        showToastMessage(undefined, response.data.message, "success", false);
        setShowAddModal(false);
        setSubmitStatus(false);
        handleGetUserData();

        // Clear all value
        // setUserDetails({});
        setFirstName(null);
        setLastName(null);
        setEmail(null);
        setPhone1(null);
        setCountryCode1("+1");
        setPhone2(null);
        setCountryCode2("+1");
        // setAddress(null);
        // setCountry(null);
        // setState(null);
        // setCity(null);
        // setZipCode(null);
        setUniqueAboutCustomer(null);
        setIsHouseAccount(0);
        setIsBusinessAccount(0);
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    } else {
      showToastMessage(
        undefined,
        "Please fill required information",
        "error",
        false
      );
    }
  };

  if (!userDetails) return <LoadingScreen />;

  // console.log('userDetails ===>', userDetails);
  return (
    <React.Fragment>
      <Header
        title={`Account Lookup ${
          userDetails.customer
            ? "- " +
              userDetails.customer.first_name +
              " " +
              userDetails.customer.last_name
            : ""
        }`}
        hasAddButton={newOrderAccess.write_access}
        handleAddClick={(e) =>
          handleManageHeaderClick(
            e,
            `/admin/orders/new-order/${userDetails.customer.id}`
          )
        }
      />

      {access.read_access && (
        <div id="MainContent" className="main-content">
          <div className="container-fluid">
            <h3 className="bold mt-5 mb-3 d-flex align-items-center">
              <span>Personal Information</span>
              <span
                onClick={handleManageUserClick}
                className={`cursor-pointer ml-1 mb-1`}
                
                
              >
                <img className={styles.icon} src={EditIcon} alt="" />
              </span>
            </h3>
            <div className="row">
              <div className={`col-12 col-lg-3 mb-4`}>
                <div className={`${styles.PersonalInformationLabel}`}>
                  First Name
                </div>
                <div className={`${styles.PersonalInformationDetail}`}>
                  {userDetails.customer &&
                  userDetails.customer.first_name !== null
                    ? userDetails.customer.first_name
                    : "-"}
                </div>
              </div>
              <div className={`col-12 col-lg-3 mb-4`}>
                <div className={`${styles.PersonalInformationLabel}`}>
                  Last Name
                </div>
                <div className={`${styles.PersonalInformationDetail}`}>
                  {userDetails.customer &&
                  userDetails.customer.last_name !== null
                    ? userDetails.customer.last_name
                    : "-"}
                </div>
              </div>
              <div className={`col-12 col-lg-6 mb-4`}>
                <div className={`${styles.PersonalInformationLabel}`}>
                  Email
                </div>
                <div className={`${styles.PersonalInformationDetail}`}>
                  {userDetails.customer && userDetails.customer.email !== null
                    ? userDetails.customer.email
                    : "-"}
                </div>
              </div>
              <div className={`col-12 col-lg-3 mb-4`}>
                <div className={`${styles.PersonalInformationLabel}`}>
                  Phone 1
                </div>
                <div className={`${styles.PersonalInformationDetail}`}>
                  {userDetails.customer && userDetails.customer?.phone
                    ? userDetails.customer?.country_code +
                      " " +
                      formatUsPhone(userDetails.customer.phone)
                    : "-"}
                </div>
              </div>
              <div className={`col-12 col-lg-3 mb-4`}>
                <div className={`${styles.PersonalInformationLabel}`}>
                  Phone 2
                </div>
                <div className={`${styles.PersonalInformationDetail}`}>
                  {userDetails.customer && userDetails.customer?.alt_phone
                    ? userDetails.customer?.alt_country_code +
                      " " +
                      formatUsPhone(userDetails.customer.alt_phone)
                    : "-"}
                </div>
              </div>
              {/* <div className={`col-12 col-lg-6 mb-4`}>
                <div className={`${styles.PersonalInformationLabel}`}>
                  Address
                </div>
                <div className={`${styles.PersonalInformationDetail}`}>
                  {userDetails.customer &&
                  userDetails.customer.customer_cards.length
                    ? userDetails.customer.customer_cards[0].zip_code
                      ? `${userDetails.customer.customer_cards[0].customer_address} - ${userDetails.customer.customer_cards[0].zip_code}`
                      : `${userDetails.customer.customer_cards[0].customer_address}`
                    : "-"}
                </div>
              </div> */}
              <div className={`col-12 col-lg-3 mb-4`}>
                <div className={`${styles.PersonalInformationLabel}`}>
                  House Account
                </div>
                <div className={`${styles.PersonalInformationDetail}`}>
                  <SwitchBox
                    value={
                      userDetails.customer
                        ? userDetails.customer.is_house_account
                        : 0
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div className={`col-12 col-lg-3 mb-4`}>
                <div className={`${styles.PersonalInformationLabel}`}>
                  Business Account
                </div>
                <div className={`${styles.PersonalInformationDetail}`}>
                  <SwitchBox
                    value={
                      userDetails.customer
                        ? userDetails.customer.is_business_account
                        : 0
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div className={`col-12 col-lg-9 mb-8`}>
                <div className={`${styles.PersonalInformationLabel}`}>
                  What's unique about this customer?
                </div>
                <div className={`${styles.PersonalInformationDetail}`}>
                  {userDetails.customer
                    ? userDetails.customer.unique_about_customer
                    : "-"}
                </div>
              </div>
            </div>
            <h3 className="bold mt-5 mb-4">Summary</h3>
            {/* <div className="row"> */}
            <div className={`${styles.OrderSummaryCard}`}>
              <div className="row">
                <div
                  className={`${styles.OrderSummaryItem} col-12 col-lg-3 col-sm-12 col-xs-12`}
                >
                  <div className={`${styles.OrderSummaryDetail}`}>
                    {userDetails.total_orders}
                  </div>
                  <div className={`${styles.OrderSummaryLabel}`}>
                    Total Orders
                  </div>
                </div>
                <div
                  className={`${styles.OrderSummaryItem} col-12 col-lg-3 col-sm-12 col-xs-12`}
                >
                  <div className={`${styles.OrderSummaryDetail}`}>
                    $
                    {userDetails.avg_order_value
                      ? userDetails.avg_order_value.toFixed(2)
                      : 0}
                  </div>
                  <div className={`${styles.OrderSummaryLabel}`}>
                    AVG Order Value
                  </div>
                </div>
                <div
                  className={`${styles.OrderSummaryItem} col-12 col-lg-3 col-sm-12 col-xs-12`}
                >
                  <div className={`${styles.OrderSummaryDetail}`}>
                    {userDetails.refunded_orders}
                  </div>
                  <div className={`${styles.OrderSummaryLabel}`}>
                    Refunded Orders
                  </div>
                </div>
                <div
                  className={`${styles.OrderSummaryItem} col-12 col-lg-3 col-sm-12 col-xs-12`}
                >
                  <div className={`${styles.OrderSummaryDetail}`}>
                    {userDetails.replaced_orders}
                  </div>
                  <div className={`${styles.OrderSummaryLabel}`}>
                    Replaced Orders
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
            <h3 className="bold mt-5 mb-2">
              Order History
              <span
                className={`cursor-pointer ${styles.SeeAllLink}`}
                onClick={(e) =>
                  handleSeeAllClick(
                    e,
                    `/admin/orders/list/${userDetails.customer.id}/${
                      userDetails.customer
                        ? userDetails.customer.first_name +
                          " " +
                          userDetails.customer.last_name
                        : ""
                    }`
                  )
                }
              >
                See All
              </span>
            </h3>
            <div className="row">
              {userDetails.customer_orders &&
              userDetails.customer_orders.length > 0
                ? userDetails.customer_orders.map((data, index) => {
                    let card_data = {
                      orderId: data.order_number,
                      id: data.id,
                      price: data.total,
                      orderStatus: data.order_status,
                      // date: data.createdAt,
                      date: data.delivery_date_time,
                      refundAmount:
                        data.refund && data.refund !== null ? data.refund : 0,
                    };

                    let recipients_data = [];
                    data.order_recipients.map((recipient) => {
                      recipients_data.push(recipient.first_name);
                      return recipients_data;
                    });
                    card_data.name = recipients_data.join(", ");

                    return (
                      <div
                        className={`col-12 col-lg-3 col-md-3 col-sm-12 col-xs-12 pt-3 pb-3 ${styles.OrderHistoryWrapper}`}
                      >
                        <OrderhistoryCard key={index} data={card_data} />
                      </div>
                    );
                  })
                : ""}
            </div>
            <h3 className="bold mt-5 mb-2">
              Payment Methods
            </h3>
            <div className="row">
              {paymentCardDetails &&
              paymentCardDetails.length > 0
                ? paymentCardDetails.map((data, index) => {
                    return (
                      <div
                        className={`col-12 col-lg-3 col-md-3 col-sm-12 col-xs-12 pt-3 pb-3 ${styles.OrderHistoryWrapper}`}
                      >
                        <PaymentHistoryCard key={index} data={data} EditClick={()=>{
                          //PAYABLI
                          setPaymentModal({isOpen:true,payment_method:data?.payment_method});
                          setmodalPaymentData(data);
                        }}/>
                      </div>
                    );
                  })
                :<p className="col-12 pt-4" style={{fontSize:"18px",lineHeight:"24px",fontWeight:"500",textAlign:"center"}}>No Payment Method Found</p>}
            </div>
            <h3 className="bold mt-5 mb-2">Petal Rewards</h3>
            {userDetails && (
              <PetalRewardsForm
                userDetails={userDetails}
                onSubmitClick={(data)=>setSubmitClick(data)}
                // customerId={userDetails.customer && userDetails.customer.id ? userDetails.customer.id : 0}
                // customerName={userDetails.customer && userDetails.customer.first_name ? userDetails.customer.first_name : "-"}
                // petal_amount={userDetails.customer && userDetails.customer.petal_rewards.length>0 && userDetails.customer.petal_rewards[0].total_amounts ? userDetails.customer.petal_rewards[0].total_amounts : 0}
                // petal_point={userDetails.customer && userDetails.customer.petal_rewards.length>0 && userDetails.customer.petal_rewards[0].total_points ? userDetails.customer.petal_rewards[0].total_points : 0}
              />
            )}
            <PetalRewards userDetails={userDetails} />
            <h3 className="bold mt-5 mb-2">&nbsp;</h3>
          </div>
        </div>
      )}

      {
        //PAYABLI
        paymentModal.isOpen ? (
          paymentModal.payment_method === PAYMENT_METHODS.PAYABLI ? (
            <PayabliPaymentCardEditModal 
              showModal={paymentModal}
              data={modalPaymentData}
              setPaymentModal={setPaymentModal}
              pageTitle={"Payabli Payment Method"}
              handleCancelClick={()=>{
                setPaymentModal({isOpen:false,payment_method:""});
              }}
              entryPoint={
                modalPaymentData?.customer_store?.
                  store?.payabli_paypoint ?? "" 
              }
            />
          ):
          <PaymentCardEditModal 
            showModal={paymentModal}
            data={modalPaymentData}
            setPaymentModal={setPaymentModal}
            pageTitle={"Edit Payment Method"}
            handleCancelClick={()=>{
              setPaymentModal({isOpen:false,payment_method:""});
            }}
        />
        ):null
      }
     

      {access.write_access && (
        <ManageUserModal
          showModal={showAddModal}
          pageTitle={manageUserPageTitle}
          firstName={firstName}
          lastName={lastName}
          email={email}
          phone1={phone1}
          countryCode1={countryCode1}
          phone2={phone2}
          countryCode2={countryCode2}
          // address={address}
          // city={city}
          // state={state}
          // country={country}
          // zipCode={zipCode}
          uniqueAboutCustomer={uniqueAboutCustomer}
          isHouseAccount={isHouseAccount}
          isBusinessAccount={isBusinessAccount}
          submitStatus={submitStatus}
          handleCancelClick={(e) => {
            setShowAddModal(false);
            // Clear all value
            setFirstName(null);
            setLastName(null);
            setEmail(null);
            setPhone1(null);
            setCountryCode1("+1");
            setPhone2(null);
            setCountryCode2("+1");
            // setAddress(null);
            // setCity(null);
            // setState(null);
            // setCountry(null);
            // setZipCode(null);
            setUniqueAboutCustomer(null);
            setIsHouseAccount(0);
            setIsBusinessAccount(0);
          }}
          handleSubmitClick={handleManageUser}
          onChange={(type, data) => {
            if (type === "firstName") setFirstName(data);
            if (type === "lastName") setLastName(data);
            if (type === "email") setEmail(data);
            if (type === "phone1") setPhone1(data);
            if (type === "countryCode1") setCountryCode1(data);
            if (type === "phone2") setPhone2(data);
            if (type === "countryCode2") setCountryCode2(data);
            // if (type === "address") setAddress(data);
            // if (type === "city") setCity(data);
            // if (type === "state") setState(data);
            // if (type === "country") setCountry(data);
            // if (type === "zipCode") setZipCode(data);
            if (type === "uniqueAboutCustomer") setUniqueAboutCustomer(data);
            if (type === "isHouseAccount") setIsHouseAccount(data);
            if (type === "isBusinessAccount") setIsBusinessAccount(data);
          }}
          onBlur={(type, data) => {
            if (type === "firstName") setFirstName(data?.trim());
            if (type === "lastName") setLastName(data);
            if (type === "email") setEmail(data);
            if (type === "phone1") setPhone1(data);
            if (type === "countryCode1") setCountryCode1(data);
            if (type === "phone2") setPhone2(data);
            if (type === "countryCode2") setCountryCode2(data);
            // if (type === "address") setAddress(data);
            // if (type === "city") setCity(data);
            // if (type === "state") setState(data);
            // if (type === "country") setCountry(data);
            // if (type === "zipCode") setZipCode(data);
            if (type === "uniqueAboutCustomer") setUniqueAboutCustomer(data);
            if (type === "isHouseAccount") setIsHouseAccount(data);
            if (type === "isBusinessAccount") setIsBusinessAccount(data);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default withRouter(CustomerDetail);
