import TextArea from "Components/FormControl/TextArea/TextArea";
import { useFormikContext } from "formik";
import React from "react";
import { useOrderAttributeProvider } from "../context/order";

const DesignerInstruction = () => {
  const formik = useFormikContext();
  const { active } = useOrderAttributeProvider();
  return (
    <div>
      <h3 className="bold mt-5">Designer Instructions (optional)</h3>
      <TextArea
        label="Designer Instructions"
        rows={3}
        className="w-100"
        style={{ height: "130px" }}
        onChangeWithEvent={formik.handleChange}
        id="delivery_information.designer_instruction"
        onBlur={formik.handleBlur}
        error={
          formik.touched?.delivery_information?.designer_instruction &&
          Boolean(formik.errors?.delivery_information?.designer_instruction)
        }
        value={formik.values.delivery_information.designer_instruction}
        disabled={active === 0 ? false : true}
      />
    </div>
  );
};

export default DesignerInstruction;
