/*
this table container is use for client side filter, sorting and pagination 
In this table we can not call api on filter, sorting and pagination
also we can set dynamic last column filter
*/
import React, { Fragment } from 'react';
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from 'react-table';
import Pagination from "@material-ui/lab/Pagination";
import { Button, Checkbox, Input, MenuItem, Popover } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
// import { Filter, DefaultColumnFilter } from './filters';
import classnames from "classnames";
import "./Table.scss";
import HourglassEmptySharpIcon from "@material-ui/icons/HourglassEmptySharp";

import CarbonSettings from "./../../Assets/images/icons/carbon_settings-adjust.svg";
import "./Table.scss";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";
const DefaultColumnFilter = ({
    column: {
      filterValue,
      setFilter,
      Header,
      preFilteredRows: { length },
    },
    visibleColumns,
}) => {
    const filterColumn = visibleColumns.find((x) => x.Header === Header);

    return (
        <Input
        value={filterValue || ""}
        startAdornment={<SearchIcon />}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={
            filterColumn && filterColumn.placeholder
              ? filterColumn.placeholder
              : ` ${Header}`
          }
      />
    );
  };
   const Filter = ({ column }) => {
    return (
      <div style={{ marginTop: 5 }}>
        {column.canFilter && column.render('Filter')}
      </div>
    );
  };
const TableContainer = ({ columns, data, noDataText,loading,disableLastColumnFilter=false }) => {
      const defaultColumn = React.useMemo(
          () => ({
            Filter: DefaultColumnFilter,
          }),
          []
        );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
} = useTable(
    {
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
    );
    
  return (
    <React.Fragment>
      <div
        className={`ReactTable -striped -highlight  pb-4 pb-xl-5`}
      >
      <table {...getTableProps()} className="rt-table table">
            <thead className="rt-thead -header">
            {headerGroups.map((headerGroup, headerGroupIndex) => (
                <tr
                  key={headerGroupIndex}
                  {...headerGroup.getHeaderGroupProps()}
                  className="rt-tr"
                >
                  {headerGroup.headers.map((column, key) => (
                    <th
                      key={key}
                      className={classnames("rt-th rt-resizable-header", {
                        "-cursor-pointer":
                          column.render("canSort"),
                        "-sort-asc": column.isSorted && !column.isSortedDesc,
                        "-sort-desc": column.isSorted && column.isSortedDesc,
                      })}
                      style={{
                        minWidth: column.colWidth ? column.colWidth : 100,
                        pointerEvents:"auto"
                      }}
                    >
                      <div
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="rt-resizable-header-content"
                      >
                        <span>{column.render("Header")}</span>
                      </div>

                      {disableLastColumnFilter && headerGroup.headers.length - 1 ===
                      key ? null : column.canFilter ? (
                        <div className={`rt-filter-wapper`}>
                          {column.render("Filter")}
                        </div>
                      ) : <div className={`rt-filter-wapper`}>
                          {column.render("Filter")}
                          </div>}
                        
                      {/* ) : null} */}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="rt-tbody">
              { loading ? (
                <tr>
                  <td colSpan={visibleColumns.length} className="text-center">
                    <div className="wrapper">
                      <HourglassEmptySharpIcon />
                      <p className="pl-2">Loading...</p>
                    </div>
                  </td>
                </tr>
              ) : page.length === 0 ? (
                <tr>
                  <td className="text-center" colSpan={visibleColumns.length}>
                    <span className="no-data-message">{noDataText}</span>
                  </td>
                </tr>
              ) : (
                page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      key={i}
                      {...row.getRowProps()}
                      className={classnames(
                        "rt-tr",
                        { " -odd": i % 2 === 0 },
                        { " -even": i % 2 === 1 }
                      )}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()} className="rt-td">
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <div className="pagination-bottom mt-3 d-flex justify-content-end">
          <Pagination
            className="custom-pagination"
            count={pageCount}
            page={pageIndex + 1}
            onChange={(e, value) => {
              gotoPage(value - 1);
            }}
          />
        </div>
        </div>
    </React.Fragment>
  );
};

export default TableContainer;
