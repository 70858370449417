export const locationState = {
  primary_info: {
    name: "",
    type: "",
    parent_id: null,
    main_parent_id: null,
    service_offered: "",
    need_opening_hour: "",
    deliver_throughout_description: "",
    your_local_florist_description: "",
    sequence_number: 0,
    slug: "",
    blurb: "",
    description: "",
    status: 1
  },
  cms_location_categories: [],
  banners: [
  ],
  seo: {
    id: 0,
    parent_id: 0,
    module: "locations",
    is_robot: false,
    is_no_follow: false,
    is_no_index: false,
    image_alt_text: "",
    page_title: "",
    meta_title: "",
    meta_description: "",
    canonical_url: "",
    og_title: "",
    og_description: "",
    og_image_url: "",
    og_image_alt: "",
    og_url: "",
    og_image_type: "",
    og_site_name: "",
    og_image_height: 0,
    og_image_width: 0,
    twitter_title: "",
    twitter_image_url: "",
    twitter_description: "",
    twitter_site: "",
    twitter_creator: "",
    schema: "",
  },
};