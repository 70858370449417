class ListenerManager {
    constructor() {
      this.listeners = new Set();
      this.globalListener = this.handleEvent.bind(this);
    }
  
    handleEvent(event) {
      for (const listener of this.listeners) {
        listener(event);
      }
    }
  
    addListener(listener) {
      this.listeners.add(listener);
      if (this.listeners.size === 1) {
        window.addEventListener("message", this.globalListener);
      }
    }
  
    removeListener(listener) {
      this.listeners.delete(listener);
      if (this.listeners.size === 0) {
        window.removeEventListener("message", this.globalListener);
      }
    }
  }
  
  const listenerManager = new ListenerManager();
  let globalConfig = {};
  
  export default class PayabliComponent {
    constructor(payabliConfig) {
      let objThis = this;
      this.payabliConfig = payabliConfig;
      this.temporaryTokenFromApi = "";
      this.payabliIframe = document.createElement("iframe");
      this.payabliButton = document.createElement("button");
      this.payabliIframeContainer = document.createElement("div");
      this.payabliIframe.style.opacity = "0";
      this.payabliIframe.style.width = "100%";
      this.payabliIframe.style.height = 0;
      this.payabliPayMethodSelected = null;
      this.payabliMainContainer = document.getElementById(
        payabliConfig.rootContainer
      );
      this.randomId = "";
      listenerManager.addListener(this.handleMessage.bind(this));
      this.initUI(payabliConfig);
    }
  
    initUI(payabliConfig) {
      this.randomId = "_" + Math.random().toString(36).substr(2, 9);
      globalConfig[this.randomId] = payabliConfig;
      try {
        //config validations
        if (typeof payabliConfig === "undefined") {
          throw new Error(
            "Payabli component configuration is not defined. Try something like this:\n\nvar payabliConfig = {}"
          );
        }
        if (typeof payabliConfig.rootContainer === "undefined") {
          throw new Error(
            "Payabli component 'rootContainer' configuration is not defined. Try something like this:\n\nvar payabliConfig = {\n rootContainer: 'pay-component' \n}"
          );
        }
        if (
          payabliConfig.type !== "expressCheckout" &&
          (typeof payabliConfig.defaultOpen === "undefined" ||
            (payabliConfig.defaultOpen !== "card" &&
              payabliConfig.defaultOpen !== "ach" &&
              payabliConfig.defaultOpen !== "device"))
        ) {
          throw new Error(
            "Payabli component 'defaultOpen' configuration is not defined or is incorrect. Try something like this:\n\nvar payabliConfig = {\n defaultOpen: 'card' \n}"
          );
        }
        if (
          payabliConfig.defaultOpen === "card" &&
          typeof payabliConfig.card !== "undefined" &&
          payabliConfig.card.enabled === false
        ) {
          throw new Error(
            "Payabli component 'defaultOpen' is 'card' and 'card' is disable. Try something like this:\n\nvar payabliConfig = {\n defaultOpen: 'card',\n card: { enabled: true } \n}"
          );
        }
        if (
          payabliConfig.defaultOpen === "ach" &&
          typeof payabliConfig.ach !== "undefined" &&
          payabliConfig.ach.enabled === false
        ) {
          throw new Error(
            "Payabli component 'defaultOpen' is 'ach' and 'ach' is disable. Try something like this:\n\nvar payabliConfig = {\n defaultOpen: 'ach',\n ach: { enabled: true } \n}"
          );
        }
        if (
          payabliConfig.defaultOpen === "device" &&
          ((typeof payabliConfig.device !== "undefined" &&
            payabliConfig.device.enabled === false) ||
            typeof payabliConfig.device === "undefined")
        ) {
          throw new Error(
            "Payabli component 'defaultOpen' is 'device' and 'device' is disable. Try something like this:\n\nvar payabliConfig = {\n defaultOpen: 'device',\n device: { enabled: true } \n}"
          );
        }
        if (
          payabliConfig.type !== "expressCheckout" &&
          (typeof payabliConfig.card === "undefined" ||
            typeof payabliConfig.ach === "undefined")
        ) {
          throw new Error(
            "Payabli component 'card' or 'ach' configuration is not defined."
          );
        }
  
        if (payabliConfig.type === "expressCheckout") {
          if (!payabliConfig.expressCheckout) {
            throw new Error(
              "Payabli component 'expressCheckout' configuration is not defined."
            );
          }
          // applePay
          if (payabliConfig?.expressCheckout?.applePay?.buttonType) {
            const buttonType =
              payabliConfig?.expressCheckout?.applePay?.buttonType;
            if (["set-up", "subscribe"].includes(buttonType)) {
              throw new Error("Invalid button type in Apple Pay configuration");
            }
          }
          validateExpressCheckout(payabliConfig.expressCheckout);
        }
        if (
          typeof payabliConfig.card !== "undefined" &&
          typeof payabliConfig.ach !== "undefined" &&
          payabliConfig.card.enabled === false &&
          payabliConfig.ach.enabled === false
        ) {
          throw new Error("Payabli component 'card' or 'ach' should be enable.");
        }
        if (
          typeof payabliConfig.type !== "undefined" &&
          !["methodembedded", "expresscheckout"].includes(
            payabliConfig.type.toLowerCase()
          ) &&
          typeof payabliConfig.buttonLabelInModal === "undefined"
        ) {
          throw new Error(
            "Payabli component 'buttonLabelInModal' configuration is not defined. Try something like this:\n\nvar payabliConfig = {\n buttonLabelInModal: 'Pay' \n}"
          );
        }
        if (typeof payabliConfig.token === "undefined") {
          throw new Error(
            "Payabli component 'token' configuration is not defined. Try something like this:\n\nvar payabliConfig = {\n token: 'xyz' \n}"
          );
        }
  
        if (typeof payabliConfig.type === "undefined") {
          throw new Error(
            "Payabli component 'type' configuration is not defined. Try something like this:\n\nvar payabliConfig = {\n type: 'methodEmbedded' \n}"
          );
        }
  
        if (
          payabliConfig.type.toLowerCase() === "vterminal" &&
          (typeof payabliConfig.oneTimePayment === "undefined" ||
            typeof payabliConfig.recurringPayment === "undefined")
        ) {
          throw new Error(
            "Payabli component 'oneTimePayment' or 'recurringPayment' configuration is not defined. Try something like this:\n\nvar payabliConfig = {\n oneTimePayment: true,\n recurringPayment: false, \n}"
          );
        }
  
        if (
          payabliConfig.type.toLowerCase() === "vterminal" &&
          typeof payabliConfig.oneTimePayment !== "undefined" &&
          typeof payabliConfig.recurringPayment !== "undefined" &&
          payabliConfig.oneTimePayment === false &&
          payabliConfig.recurringPayment === false
        ) {
          throw new Error(
            "Payabli component 'oneTimePayment' and 'recurringPayment' configurations are 'false', you need at least one of them set to 'true'. Try something like this:\n\nvar payabliConfig = {\n oneTimePayment: true,\n recurringPayment: false, \n}"
          );
        }
  
        if (
          payabliConfig.card &&
          payabliConfig.card.inputs &&
          payabliConfig.card.inputs.cardZipcode &&
          payabliConfig.card.inputs.cardZipcode.country
        ) {
          let payabliConfigZipcodeCountry =
            payabliConfig.card.inputs.cardZipcode.country;
          if (!Array.isArray(payabliConfigZipcodeCountry)) {
            throw new Error(
              "Payabli component 'cardZipcode.country' configuration should be an Array of Strings. Try something like this:\ncountry: ['ca', 'us']"
            );
          }
  
          if (Array.isArray(payabliConfigZipcodeCountry)) {
            payabliConfigZipcodeCountry.forEach(function (item) {
              if (typeof item !== "string") {
                throw new Error(
                  "Payabli component 'cardZipcode.country' configuration should be an Array of Strings. Try something like this:\ncountry: ['ca', 'us']"
                );
              }
            });
          }
        }
  
        const {
          defaultOpen,
          card = {},
          ach = {},
          type,
          lineItems,
          entryPoint,
        } = payabliConfig;
  
        if (
          type.toLowerCase() === "vterminal" &&
          lineItems &&
          typeof lineItems === "object"
        ) {
          const requiredFields = [
            "name",
            "type",
            "label",
            "value",
            "description",
            "quantity",
            "showDescription",
          ];
  
          for (const item in lineItems) {
            if (
              requiredFields.some(
                (field) => typeof lineItems[item][field] === "undefined"
              )
            ) {
              throw new Error(
                "Payabli component 'lineItems' configuration is not correct. Try something like this:\n\n" +
                  "var payabliConfig = {\n lineItems: [{\n" +
                  "   name: 'name',\n" +
                  "   type: 'customer',\n" +
                  "   label: 'label',\n" +
                  "   value: '20.00',\n" +
                  "   description: '',\n" +
                  "   quantity: '',\n" +
                  "   showDescription: 'true'\n" +
                  " }] \n}"
              );
            }
          }
        }
  
        const cardTypes = [
          "amex",
          "discover",
          "visa",
          "mastercard",
          "diners",
          "jcb",
        ];
  
        if (
          defaultOpen === "card" &&
          cardTypes.every((type) => card[type] === false)
        ) {
          throw new Error(
            "Payabli component. All cards are disabled, you must activate at least one card."
          );
        }
  
        if (defaultOpen === "ach" && !ach.checking && !ach.savings) {
          throw new Error(
            "Payabli component. All account types are disabled, you must activate at least one account type."
          );
        }
  
        if (
          ["vterminal", "expresscheckout"].includes(type.toLowerCase()) &&
          !entryPoint
        ) {
          throw new Error(
            "Payabli component 'entryPoint' configuration is not defined or empty. Try something like this:\n\n" +
              "var payabliConfig = {\n entryPoint: 'entrypoint' \n}"
          );
        }
  
        // Set default enabled values for card and ach
        if (
          defaultOpen === "card" &&
          (card.enabled === undefined || card.enabled === null)
        ) {
          card.enabled = true;
        }
  
        if (
          defaultOpen === "ach" &&
          (ach.enabled === undefined || ach.enabled === null)
        ) {
          ach.enabled = true;
        }
  
        // Set default values for card types
        cardTypes.forEach((type) => {
          if (card[type] === undefined) {
            card[type] = true;
          }
        });
  
        // Set default values for ach types
        const achTypes = ["checking", "savings"];
        achTypes.forEach((type) => {
          if (ach[type] === undefined) {
            ach[type] = true;
          }
        });
  
        payabliConfig.randomId = this.randomId;
        let payabliConfigJson = JSON.stringify(payabliConfig);
        let payabliEncoded = window.btoa(payabliConfigJson);
  
        if (!this.payabliMainContainer) {
          throw new Error("Payabli component 'rootContainer' not found.");
        }
  
        //get JS hostname
        var thisScriptTag = document.querySelector('script[src*="component.js"]');
        //var thisScriptTag = scripts[scripts.length - 1];
        var jsversion = "2.0.1";
        var urlParser = document.createElement("a");
        urlParser.href = thisScriptTag.getAttribute("src");
        var hostjs = urlParser.protocol + "//" + urlParser.host + "/" + jsversion;
        if (Object.keys(thisScriptTag.dataset).includes("test")) {
          hostjs = urlParser.protocol + "//" + urlParser.host;
        }
  
        let payabliHead = document.getElementsByTagName("HEAD")[0];
        let payabliLink = document.createElement("link");
        switch (payabliConfig.type.toLowerCase()) {
          case "methodlightbox":
            this.payabliIframe.setAttribute(
              "src",
              hostjs + "/addpaymethod/" + payabliEncoded
            );
            this.payabliIframe.setAttribute("scrolling", "no");
            this.payabliIframeContainer.id =
              "payabliComponentsIframeContainerMethod";
            this.payabliIframeContainer.onclick = function () {
              this.closeModal();
            };
            this.payabliIframeContainer.style.opacity = "1";
            if (payabliConfig.hasOwnProperty("hideComponent")) {
              if (payabliConfig.hideComponent === true) {
                this.payabliIframeContainer.style.display = "none";
              }
            }
            //Adding css file
            payabliLink.rel = "stylesheet";
            payabliLink.type = "text/css";
            payabliLink.href = hostjs + "/lightbox.css";
            payabliHead.appendChild(payabliLink);
            break;
          case "methodembedded":
            this.payabliIframe.setAttribute(
              "src",
              hostjs + "/addpaymethodembeded/" + payabliEncoded
            );
            this.payabliIframe.setAttribute("scrolling", "no");
            this.payabliIframe.setAttribute("frameborder", "0");
            this.payabliIframe.setAttribute("allowtransparency", "true");
            this.payabliIframeContainer.id =
              "payabliComponentsIframeContainerMethodEmbedded";
            this.payabliIframeContainer.style.opacity = "1";
            if (payabliConfig.hasOwnProperty("hideComponent")) {
              if (payabliConfig.hideComponent === true) {
                this.payabliIframeContainer.style.display = "none";
              }
            }
            break;
          case "expresscheckout":
            this.payabliIframe.setAttribute(
              "src",
              hostjs + "/expresscheckout/" + payabliEncoded
            );
            this.payabliIframe.setAttribute("scrolling", "no");
            this.payabliIframe.setAttribute("allow", "payment");
            this.payabliIframe.setAttribute("frameborder", "0");
            this.payabliIframe.setAttribute("allowtransparency", "true");
            this.payabliIframeContainer.id =
              "payabliComponentsIframeContainerExpressCheckout";
            this.payabliIframeContainer.style.opacity = "1";
            if (payabliConfig.hasOwnProperty("hideComponent")) {
              if (payabliConfig.hideComponent === true) {
                this.payabliIframeContainer.style.display = "none";
              }
            }
            break;
          case "vterminal":
            this.payabliIframe.setAttribute(
              "src",
              hostjs + "/vterminal/" + payabliEncoded
            );
            this.payabliIframe.setAttribute("scrolling", "yes");
            this.payabliIframeContainer.id =
              "payabliComponentsIframeContainerEterminal";
            this.payabliIframeContainer.onclick = function () {
              this.closeModal();
            };
            this.payabliIframeContainer.style.opacity = "1";
            if (payabliConfig.hasOwnProperty("hideComponent")) {
              if (payabliConfig.hideComponent === false) {
                this.payabliIframeContainer.style.display = "flex";
              }
            }
            //Adding css file
            payabliLink.rel = "stylesheet";
            payabliLink.type = "text/css";
            payabliLink.href = hostjs + "/components.css";
            payabliHead.appendChild(payabliLink);
            break;
          default:
            throw new Error(
              "Payabli component 'type' has an incorrect value. Try something like this:\n\nvar payabliConfig = {\n type: 'methodlightbox' \n}\n\nOR\n\nvar payabliConfig = {\n type: 'methodembedded' \n}\n\nOR\n\nvar payabliConfig = {\n type: 'vterminal' \n}"
            );
        }
  
        if (this.payabliMainContainer) {
          while (this.payabliMainContainer.firstChild) {
            this.payabliMainContainer.removeChild(
              this.payabliMainContainer.firstChild
            );
          }
  
          this.payabliMainContainer.appendChild(this.payabliIframeContainer);
          this.payabliIframeContainer.appendChild(this.payabliIframe);
        }
      } catch (error) {
        console.error(error);
        this.payabliMainContainer.innerHTML = error;
      }
    }
  
    handleMessage(event) {
      let payabliConfig = globalConfig[this.randomId];
      if (
        event.data === "close-payabli-modal" &&
        this.payabliConfig.type.toLowerCase() === "methodlightbox"
      ) {
        this.closeModal();
      }
      if (
        event.data === "close-payabli-modal-vt" &&
        this.payabliConfig.type.toLowerCase() === "vterminal"
      ) {
        this.closeModal();
      }
  
      if (
        event.data.event ===
        "callback-payabli-function-done" + payabliConfig.randomId
      ) {
        if (typeof payabliConfig.functionCallBackSuccess !== "undefined") {
          payabliConfig.functionCallBackSuccess(event.data.data);
        }
      }
      if (
        event.data.event ===
        "callback-payabli-function-ready" + payabliConfig.randomId
      ) {
        if (typeof payabliConfig.functionCallBackReady !== "undefined") {
          payabliConfig.functionCallBackReady(event.data.data);
        }
      }
      if (
        event.data.event ===
        "callback-payabli-function-error" + payabliConfig.randomId
      ) {
        if (typeof payabliConfig.functionCallBackError !== "undefined") {
          payabliConfig.functionCallBackError(event.data.data);
        }
      }
  
      if (
        event.data.event ===
        "callback-payabli-function-mounted-component" + payabliConfig.randomId
      ) {
        this.payabliIframe.style.opacity = "1";
      }
  
      if (
        event.data.event ===
        "callback-payabli-function-resize" + payabliConfig.randomId
      ) {
        if (
          event.data.data &&
          Number.isInteger(event.data.data) &&
          payabliConfig.type.toLowerCase() !== "vterminal"
        ) {
          this.payabliIframe.style.height = event.data.data.toString() + "px";
        }
      }
      if (
        event.data.event ===
        "callback-payabli-function-pay-method-selected" + payabliConfig.randomId
      ) {
        if (event.data.data) {
          this.payabliPayMethodSelected = event.data.data;
        }
      }
  
      if (
        event.data.event ===
        "callback-payabli-function-set-token" + payabliConfig.randomId
      ) {
        if (event.data.data) {
          this.temporaryTokenFromApi = event.data.data;
        }
      }
  
      // Apple Pay events
  
      if (
        event.data.event ===
        `callback-payabli-function-init-domain${payabliConfig.randomId}`
      ) {
        this.payabliIframe.contentWindow.postMessage(
          {
            event: "callback-payabli-function-set-domain" + this.randomId,
            data: String(window.location.hostname),
          },
          "*"
        );
      }
      if (
        event.data.event ===
        "callback-payabli-function-expresscheckout-success" +
          payabliConfig.randomId
      ) {
        if (
          typeof payabliConfig.functionCallBackSuccess !== "undefined" &&
          event.data.data
        ) {
          payabliConfig.functionCallBackSuccess(event.data.data);
        }
      }
      if (
        event.data.event ===
        "callback-payabli-function-expresscheckout-ready" + payabliConfig.randomId
      ) {
        if (
          typeof payabliConfig.functionCallBackReady !== "undefined" &&
          event.data.data
        ) {
          payabliConfig.functionCallBackReady(event.data.data);
        }
      }
      if (
        event.data.event ===
        "callback-payabli-function-expresscheckout-cancel" +
          payabliConfig.randomId
      ) {
        if (
          typeof payabliConfig.functionCallBackCancel !== "undefined" &&
          event.data.data
        ) {
          payabliConfig.functionCallBackCancel(event.data.data);
        }
      }
      if (
        event.data.event ===
        "callback-payabli-function-expresscheckout-error" + payabliConfig.randomId
      ) {
        if (
          typeof payabliConfig.functionCallBackError !== "undefined" &&
          event.data.data
        ) {
          payabliConfig.functionCallBackError(event.data.data);
        }
      }
    }
  
    showModal() {
      this.payabliIframeContainer.style.display = "flex";
      this.payabliIframeContainer.style.opacity = "1";
    }
  
    closeModal() {
      this.payabliIframeContainer.style.opacity = "0";
      this.payabliIframeContainer.style.display = "none";
    }
  
    payabliExec(action, parameter) {
      let data = {};
      if (parameter) {
        data = parameter;
      }
  
      if (action === "method") {
        this.payabliIframe.contentWindow.postMessage(
          {
            event: "callback-payabli-function-exec" + this.randomId,
            data: data,
          },
          "*"
        );
      } else if (action === "pay") {
        this.payabliIframe.contentWindow.postMessage(
          {
            event: "callback-payabli-function-pay" + this.randomId,
            data: data,
          },
          "*"
        );
      } else if (action === "reinit") {
        this.payabliIframe.contentWindow.postMessage(
          {
            event: "callback-payabli-function-reinit" + this.randomId,
            data: data,
          },
          "*"
        );
      } else if (action === "auth") {
        this.payabliIframe.contentWindow.postMessage(
          {
            event: "callback-payabli-function-auth" + this.randomId,
            data: data,
          },
          "*"
        );
      }
    }
  
    updateConfig(newConfig) {
      listenerManager.removeListener(this.handleMessage.bind(this));
      this.initUI(newConfig);
      this.payabliExec("reinit");
    }
  }
  
  function validateExpressCheckout(expressCheckout) {
    const requiredFields = [
      { key: "amount", type: "number" },
      { key: "fee", type: "number" },
      { key: "currency", type: "string" },
      { key: "supportedNetworks", type: "object" },
      { key: "applePay", type: "object" },
    ];
  
    const applePayRequiredFields = [{ key: "enabled", type: "boolean" }];
  
    const optionalFields = {
      layout: {
        columns: "number",
      },
      appearance: {
        buttonHeight: "number",
        buttonBorderRadius: "number",
        padding: {
          x: "number",
          y: "number",
        },
      },
      applePay: {
        buttonStyle: "string",
        buttonType: "string",
        language: "string",
      },
    };
  
    function validateObject(obj, fields) {
      Object.entries(fields).forEach(([key, typeOrNestedFields]) => {
        if (typeof typeOrNestedFields === "string") {
          // Simple type check
          if (key in obj && typeof obj[key] !== typeOrNestedFields) {
            throw new Error(`Field ${key} must be of type ${typeOrNestedFields}`);
          }
        } else {
          // Nested object validation
          if (key in obj) {
            validateObject(obj[key], typeOrNestedFields);
          }
        }
      });
    }
  
    // Validate required fields
    requiredFields.forEach((field) => {
      const { key, type } = field;
  
      // Check if the required field is missing
      if (!(key in expressCheckout)) {
        throw new Error(`Missing required field: ${key}`);
      }
  
      // Check if the field is of the correct type
      if (typeof expressCheckout[key] !== type) {
        throw new Error(`Field ${key} must be of type ${type}`);
      }
    });
  
    // Validate required fields within applePay
    validateObject(expressCheckout.applePay, applePayRequiredFields);
  
    // Validate optional fields
    validateObject(expressCheckout, optionalFields);
  
    // Additional validations
    if (!Array.isArray(expressCheckout.supportedNetworks)) {
      throw new Error(`Field supportedNetworks must be an array`);
    }
  }