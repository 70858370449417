/* eslint-disable no-console */
import axios from "axios";
import Cookies from "universal-cookie";
import jwt from "jsonwebtoken";
import * as Sentry from '@sentry/react'
const cookies = new Cookies();
const accessToken = cookies.get("accessToken");
const API_URL = process.env.REACT_APP_API_URL;
let key = "!T7X!gsfE9Q&U!gk5V#kwJfYZSYHGB";
let payload = {
  iss: "ffv2ApiAccess",
};
// let acc = jwt.sign(payload, key, { expiresIn: 60 });

export async function callApi({ url, method, body, headers }) {
  let selectedStore = localStorage.getItem("selectedStore");
  if(typeof(localStorage.getItem("pagewiseallstorecheckbox"))!='undefined' && localStorage.getItem("pagewiseallstorecheckbox")!=null && localStorage.getItem("pagewiseallstorecheckbox")==0){
    selectedStore = parseInt(0);
  }
  let acc = jwt.sign(payload, key, { expiresIn: 60 });
  let authHeader = accessToken ? { authorization: accessToken } : {};
  authHeader["x-token"] = acc;
  try {
    const result = await axios({
      url: API_URL + url,
      method: method,
      data: body,
      timeout: 120000,
      headers: { ...authHeader, ...headers, selectedstoreid:selectedStore, "requestfrom":"admin"},
    });
    return result.data;
  } catch (error) {
    // throw error;
    console.log(error);
    console.log("Post data", JSON.stringify(body));
    Sentry.captureMessage(
      `URL:${url},  message:${
        error?.response?.data?.message
      }, GENERATE_TIME:${new Date().toLocaleString()}`
    );
    if (error && error.response) {
      return error.response;
    }
  }
}

export async function callPostApi({ url, body, headers }) {
  let selectedStore = localStorage.getItem("selectedStore");
  if(typeof(localStorage.getItem("pagewiseallstorecheckbox"))!='undefined' && localStorage.getItem("pagewiseallstorecheckbox")!=null && localStorage.getItem("pagewiseallstorecheckbox")==0){
    selectedStore = parseInt(0);
  }
  let acc = jwt.sign(payload, key, { expiresIn: 60 });
  let authHeader = accessToken ? { authorization: accessToken } : {};
  authHeader["x-token"] = acc;
  try {
    const result = await axios({
      url: API_URL + url,
      method: "POST",
      data: body,
      timeout: 120000,
      headers: { ...authHeader, ...headers, selectedstoreid:selectedStore, "requestfrom":"admin"},
    });
    return result;
  } catch (error) {
    // throw error;
    console.log(error);
    console.log("Post data", JSON.stringify(body));
    Sentry.captureMessage(
      `URL:${url},  message:${
        error?.response?.data?.message
      }, GENERATE_TIME:${new Date().toLocaleString()}`
    );
    if (error && error.response) {
      return error.response;
    }
  }
}

export async function callPostApiv2(url, body, headers, notoken) {
  let selectedStore = localStorage.getItem("selectedStore");
  if(typeof(localStorage.getItem("pagewiseallstorecheckbox"))!='undefined' && localStorage.getItem("pagewiseallstorecheckbox")!=null && localStorage.getItem("pagewiseallstorecheckbox")==0){
    selectedStore = parseInt(0);
  }
  let acc = jwt.sign(payload, key, { expiresIn: 60 });
  let authHeader = accessToken ? { authorization: accessToken } : {};
  authHeader["x-token"] = acc;
  if (notoken) {
    authHeader.authorization = "";
  }
  try {
    const result = await axios({
      url: API_URL + url,
      method: "POST",
      data: body,
      timeout: 120000,
      headers: { ...authHeader, ...headers, selectedstoreid:selectedStore, "requestfrom":"admin"},
    });
    return result;
  } catch (error) {
    console.log(error);
    console.log("Post data", JSON.stringify(body));
    Sentry.captureMessage(
      `URL:${url},  message:${
        error?.response?.data?.message
      }, GENERATE_TIME:${new Date().toLocaleString()}`
    );
    throw error;
  }
}

export async function callGetApi({ url, body, headers }) {
  let selectedStore = localStorage.getItem("selectedStore");
  if(typeof(localStorage.getItem("pagewiseallstorecheckbox"))!='undefined' && localStorage.getItem("pagewiseallstorecheckbox")!=null && localStorage.getItem("pagewiseallstorecheckbox")==0){
    selectedStore = parseInt(0);
  }
  let acc = jwt.sign(payload, key, { expiresIn: 60 });
  let authHeader = accessToken ? { authorization: accessToken } : {};
  authHeader["x-token"] = acc;
  try {
    const result = await axios({
      url: API_URL + url,
      method: "GET",
      data: body,
      timeout: 120000,
      headers: { ...authHeader, ...headers, selectedstoreid:selectedStore, "requestfrom":"admin"},
    });
    return result;
  } catch (error) {
    console.log(error);
    console.log("Post data", JSON.stringify(body));
    Sentry.captureMessage(
      `URL:${url},  message:${
        error?.response?.data?.message
      }, GENERATE_TIME:${new Date().toLocaleString()}`
    );
    // throw error;
    if (error && error.response) {
      return error.response;
    }
  }
}

export async function callGetApiv2(url, body, headers) {
  let selectedStore = localStorage.getItem("selectedStore");
  if(typeof(localStorage.getItem("pagewiseallstorecheckbox"))!='undefined' && localStorage.getItem("pagewiseallstorecheckbox")!=null && localStorage.getItem("pagewiseallstorecheckbox")==0){
    selectedStore = parseInt(0);
  }
  let acc = jwt.sign(payload, key, { expiresIn: 60 });
  let authHeader = accessToken ? { authorization: accessToken } : {};
  authHeader["x-token"] = acc;
  try {
    const result = await axios({
      url: API_URL + url,
      method: "GET",
      data: body,
      timeout: 120000,
      headers: { ...authHeader, ...headers, selectedstoreid:selectedStore, "requestfrom":"admin"},
    });
    return result;
  } catch (error) {
    console.log(error);
    console.log("Post data", JSON.stringify(body));
    Sentry.captureMessage(
      `URL:${url},  message:${
        error?.response?.data?.message
      }, GENERATE_TIME:${new Date().toLocaleString()}`
    );
    throw error;
  }
}

export async function callPutApi({ url, body, headers }) {
  let selectedStore = localStorage.getItem("selectedStore");
  if(typeof(localStorage.getItem("pagewiseallstorecheckbox"))!='undefined' && localStorage.getItem("pagewiseallstorecheckbox")!=null && localStorage.getItem("pagewiseallstorecheckbox")==0){
    selectedStore = parseInt(0);
  }
  let acc = jwt.sign(payload, key, { expiresIn: 60 });
  let authHeader = accessToken ? { authorization: accessToken } : {};
  authHeader["x-token"] = acc;
  try {
    const result = await axios({
      url: API_URL + url,
      method: "PUT",
      data: body,
      timeout: 120000,
      headers: { ...authHeader, ...headers, selectedstoreid:selectedStore, "requestfrom":"admin"},
    });
    return result;
  } catch (error) {
    // throw error;
    console.log(error);
    console.log("Post data", JSON.stringify(body));
    Sentry.captureMessage(
      `URL:${url},  message:${
        error?.response?.data?.message
      }, GENERATE_TIME:${new Date().toLocaleString()}`
    );
    if (error && error.response) {
      return error.response;
    }
  }
}

export async function callPutApiv2(url, body, headers) {
  let selectedStore = localStorage.getItem("selectedStore");
  if(typeof(localStorage.getItem("pagewiseallstorecheckbox"))!='undefined' && localStorage.getItem("pagewiseallstorecheckbox")!=null && localStorage.getItem("pagewiseallstorecheckbox")==0){
    selectedStore = parseInt(0);
  }
  let acc = jwt.sign(payload, key, { expiresIn: 60 });
  let authHeader = accessToken ? { authorization: accessToken } : {};
  authHeader["x-token"] = acc;
  try {
    const result = await axios({
      url: API_URL + url,
      method: "PUT",
      data: body,
      timeout: 120000,
      headers: { ...authHeader, ...headers, selectedstoreid:selectedStore, "requestfrom":"admin"},
    });
    return result;
  } catch (error) {
    console.log(error);
    console.log("Post data", JSON.stringify(body));
    Sentry.captureMessage(
      `URL:${url},  message:${
        error?.response?.data?.message
      }, GENERATE_TIME:${new Date().toLocaleString()}`
    );
    throw error;
  }
}

export async function callDeleteApi({ url, body, headers }) {
  let selectedStore = localStorage.getItem("selectedStore");
  if(typeof(localStorage.getItem("pagewiseallstorecheckbox"))!='undefined' && localStorage.getItem("pagewiseallstorecheckbox")!=null && localStorage.getItem("pagewiseallstorecheckbox")==0){
    selectedStore = parseInt(0);
  }
  let acc = jwt.sign(payload, key, { expiresIn: 60 });
  let authHeader = accessToken ? { authorization: accessToken } : {};
  authHeader["x-token"] = acc;
  try {
    const result = await axios({
      url: API_URL + url,
      method: "DELETE",
      data: body,
      timeout: 120000,
      headers: { ...authHeader, ...headers, selectedstoreid:selectedStore, "requestfrom":"admin"},
    });
    return result;
  } catch (error) {
    console.log(error);
    console.log("Post data", JSON.stringify(body));
    Sentry.captureMessage(
      `URL:${url},  message:${
        error?.response?.data?.message
      }, GENERATE_TIME:${new Date().toLocaleString()}`
    );
    // throw error;
    if (error && error.response) {
      return error.response;
    }
  }
}

export async function callDeleteApiv2(url, body, headers) {
  let selectedStore = localStorage.getItem("selectedStore");
  if(typeof(localStorage.getItem("pagewiseallstorecheckbox"))!='undefined' && localStorage.getItem("pagewiseallstorecheckbox")!=null && localStorage.getItem("pagewiseallstorecheckbox")==0){
    selectedStore = parseInt(0);
  }
  let acc = jwt.sign(payload, key, { expiresIn: 60 });
  let authHeader = accessToken ? { authorization: accessToken } : {};
  authHeader["x-token"] = acc;
  try {
    const result = await axios({
      url: API_URL + url,
      method: "DELETE",
      data: body,
      timeout: 120000,
      headers: { ...authHeader, ...headers, selectedstoreid:selectedStore, "requestfrom":"admin"},
    });
    return result;
  } catch (error) {
    console.log(error);
    console.log("Post data", JSON.stringify(body));
    Sentry.captureMessage(
      `URL:${url},  message:${
        error?.response?.data?.message
      }, GENERATE_TIME:${new Date().toLocaleString()}`
    );
    return error.response
  }
}
