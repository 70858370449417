import { callPostApi, callPutApi, callGetApi } from "./api";

export async function getChangeRequest(data) {
      // return this.interceptors.POST('change-request/admin', data);
      try {
            const response = await callPostApi({ url: "change-request/admin", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateChangeRequestStatus(data) {
      // return this.interceptors.PUT('change-request/update-status', data);
      try {
            const response = await callPutApi({ url: "change-request/update-status", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getChangeRequestDetails(id) {
      // return this.interceptors.GET('change-request/' + id);
      try {
            const response = await callGetApi({ url: "change-request/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}
