import React, { useState, useContext, useEffect } from "react";
import { MODULES } from "../../Helpers/Constants";
import { Collapse } from "reactstrap";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";
import { useLocation } from "react-router-dom";

//components
import Select from "../../Components/FormControl/Select/NewSelect"

// Context
import { AppContext } from "./../../Context/AppContext";

// Import SCSS
import styles from "./Sidebar.module.scss";

// Utils
import allRoutes from "../../Routes/Routes";

// Images
import AdminLog from "../../Assets/images/admin-logo.svg";
import { withRouter } from "react-router";
import { useOrder } from "Context/OrderContext/useOrder";

const Sidebar = (props) => {
  let [routes, setRoutes] = useState([]);
  const [storeList,setStoreList] = useState([]);
  const [selectedStore,setSelectedStore] = useState()
  const location = useLocation();

  const { modules } = useContext(AppContext);

  useEffect(() => {
    
    let routes = [];
    allRoutes.forEach((route) => {
      let children = [];
      route.children.forEach((child) => {
        /* CHECK MODULE ACCESS FOR EACH */
        let moduleAccess = modules.find(
          (module) => module.name === child.moduleName
        );
        if (moduleAccess) {
          if (
            child.moduleName === MODULES.NEW_ORDER ||
            child.moduleName === MODULES.EDIT_ORDER ||
            child.moduleName === MODULES.HOME_PAGE
          ) {
            if (moduleAccess.permissions.write_access) {
              children.push(child);
            }
          } else {
            if (moduleAccess.permissions.read_access) {
              children.push(child);
            }
          }
        } else {
          if (child.displayName === "Dashboard") {
            children.push(child);
          }
        }

        if(child.accessPermission){
          children.push(child);
        }
      });

      let activeRoute = route.children.filter((childRoute) => {
        return childRoute.path === location.pathname;
      });

      // PARENT ONLY HAS ACCESS IF ANY CHILD HAS
      if (children.length > 0) {
        routes.push({ isOpen: activeRoute.length > 0, ...route, children });
      }
    });
    setRoutes(routes);
  }, [modules]);
  
  useEffect(()=>{
    let storelist = localStorage.getItem("assignStore");
      setStoreList(JSON.parse(storelist))
      setSelectedStore(localStorage.getItem("selectedStore"))
  },[])
  function toggleCollapse(route, index) {
    let newRoutes = [...routes];
    newRoutes[index].isOpen = !newRoutes[index].isOpen;
    setRoutes(newRoutes);
  }

  const handleNavigation = (event, route) => {
    if (event) event.preventDefault();

    props.history.push(route);
  };
  const { resetState } = useOrder();

  const storeHandler = async(id)=>{
  if(localStorage.getItem("selectedStore")!=id){
    localStorage.setItem("selectedStore",id);
    window.location.reload();
  }
  }
  useEffect(() => {
    const data =  localStorage.getItem('selectedStore'); 
    setSelectedStore(JSON.parse(data));
  }, [])
  return (
    <div className={styles.leftPanel} id="leftPanel">
      <div className={styles.logo}>
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <img src={AdminLog} alt="Admin Logo" />
        </a>
        <div className="pt-3">
          <Select
            label= "Select Store"
            value={selectedStore}
            onChange={(data) => {storeHandler(data.id);}}
            options={storeList}
            usedFor="Filter"
          />
        </div>
      </div>

      <CustomScroll>
        <ul
          className={`nav flex-column flex-nowrap overflow-hidden ${styles.leftNavigation}`}
        >
          {routes.map((route, index) => (
            <li className={styles.navItem} key={route.key}>
              <a
                className={`collapsed text-truncate nav-link ${styles.navLink}`}
                href="javascript"
                onClick={(e) => {
                  e.preventDefault();
                  toggleCollapse(route, index);
                }}
              >
                <span>{route.displayName}</span>
              </a>
              <Collapse isOpen={route.isOpen}>
                <ul className="flex-column pl-2 nav">
                  {route.children.map((child) => {
                    return (
                      child.showOnSideBar && (
                        <li className={styles.navItem} key={child.displayName}>
                          <a
                            href="/"
                            className={`nav-link py-0 ${styles.navLink} ${
                              child.path === location.pathname
                                ? styles.active
                                : ""
                            }`}
                            onClick={(event) => {
                              if (child.path === "/admin/orders/new-order") {
                                resetState();
                              }
                              handleNavigation(event, child.path);
                            }}
                          >
                            <span>{child.displayName}</span>
                          </a>
                        </li>
                      )
                    );
                  })}
                </ul>
              </Collapse>
            </li>
          ))}
        </ul>
      </CustomScroll>
    </div>
  );
};

export default withRouter(Sidebar);