import Interceptors from "../Interceptors/Interceptors";
class Recipes {
  constructor() {
    this.Interceptors = new Interceptors();
  }
  async getRecipeList(postData) {
    return this.Interceptors.POST("product-recipe/list", postData);
  }

  async deleteRecipe(id) {
    return this.Interceptors.DELETE("product-recipe/" + id);
  }
  async getRecipeRawMaterials(postData) {
    return this.Interceptors.POST("product-recipe/raw-materials/list",postData);
  }
  async getRecipeType(postData) {
    return this.Interceptors.POST("product-recipe/types/list", postData);
  }
  async getRecipeUnit(postData) {
    return this.Interceptors.POST("product-recipe/units/list", postData);
  }

  async addRecipe(postData) {
    return this.Interceptors.POST("product-recipe", postData);
  }
  async updateRecipe(postData,id) {
    return this.Interceptors.PUT("product-recipe/"+ id,postData);
  }
  async getRecipeDetail(id) {
    return this.Interceptors.GET("product-recipe/"+ id);
  }
}
export default Recipes;
