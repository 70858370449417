export const OrderActions = {
  ADD_NEW_RECIPENT: "ADD_NEW_RECIPENT",
  ADDON_ADD: "ADDON_ADD",
  PRODUCT_ADD: "PRODUCT_ADD",
  SAVE_DELIVERY_ADDRESS: "SAVE_DELIVERY_ADDRESS",
  SET_ACTIVE_INDEX: "SET_ACTIVE_INDEX",
  CALCULATE: "CALCULATE",
  GET_OCCATION_LIST: "GET_OCCATION_LIST",
  REMOVE_RECIPENT: "REMOVE_RECIPENT",
  UPDATE_COMMON_RECIPIENT_FPOM: "UPDATE_COMMON_RECIPIENT_FORM",
  UPDATE_RECIPIENT_FPOM: "UPDATE_RECIPIENT_FPOM",
  UPDATE_GREETING_CARD_FORM: "UPDATE_GREETING_CARD_FORM",
  UPDATE_CUSTOMER_DATA: "UPDATE_CUSTOMER_DATA",
  UPDATE_DESIGNER_INSTRUCTION: "UPDATE_DESIGNER_INSTRUCTION",
  UPDATE_DRIVER_INSTRUCTION: "UPDATE_DRIVER_INSTRUCTION",
  UPDATE_DATE_TIME_INSTRUCTION: "UPDATE_DATE_TIME_INSTRUCTION",
  REMOVE_ADDON_ITEM: "REMOVE_ADDON_ITEM",
  REMOVE_PRODUCT_ITEM: "REMOVE_PRODUCT_ITEM",
  SET_CARD_DETAIL: "SET_CARD_DETAIL",
  UPDATE_INTERNAL_NOTES: "UPDATE_INTERNAL_NOTES",
  GET_ORDER_DETAILS: "GET_ORDER_DETAILS",
  CLR_DATA_TEST: "CLR_DATA_TEST",
  ADD_PRODUCT_QTY: "ADD_PRODUCT_QTY",
  ADD_ADDON_QTY: "ADD_ADDON_QTY",
  REMOVE_ADDON_QTY: "REMOVE_ADDON_QTY",
  REMOVE_PRODUCT_QTY: "REMOVE_PRODUCT_QTY",
  TAX_SET: "TAX_SET",
  DISSCOUNT_ID: "DISSCOUNT_ID",
  SET_ADD_FUND: "SET_ADD_FUND",
  SET_REFUND: "SET_REFUND",
  SET_PATEL_REWARDS: "SET_PATEL_REWARDS",
  SET_BLOCKOUT_DATE: "SET_BLOCKOUT_DATE",
  SET_UP_CHGARGE: "SET_UP_CHGARGE",
  DELETE_RECIPENT_ID: "DELETE_RECIPENT_ID",
  DELETE_PRODUCT_ID: "DELETE_PRODUCT_ID",
  DELETE_ADDON_ID: "DELETE_ADDON_ID",
  OVERRIDE_ORDER_NOTE: "OVERRIDE_ORDER_NOTE",
  INCRESS_PRODUCT_QTY: "INCRESS_PRODUCT_QTY",
  DECRESS_PRODUCT_QTY: "DECRESS_PRODUCT_QTY",
  INCRESS_ADDON_QTY: "INCRESS_ADDON_QTY",
  DECRESS_ADDON_QTY: "DECRESS_ADDON_QTY",
  SET_CARD_DATA_LIST: "SET_CARD_DATA_LIST",
  SET_VERIFIED: "SET_VERIFIED",
  SET_CUSTOMER_DETAILS: "SET_CUSTOMER_DETAILS",
  SET_CARD_DEFALT: "SET_CARD_DEFALT",
  SET_DISSCOUNT_DETAILS: "SET_DISSCOUNT_DETAILS",
  UPDATE_BILLING_ADDRESS: "UPDATE_BILLING_ADDRESS",
  CLEAR_CUSTOMER_DATA: "CLEAR_CUSTOMER_DATA",
  CLEAR_DELIVERY_DATA: "CLEAR_DELIVERY_DATA",
  SET_FERQUENCY_LIST: "SET_FERQUENCY_LIST",
  CHECK_ADDFUND_REFUND: "CHECK_ADDFUND_REFUND",
  SET_CUSTOMER_DETAILS_INFO: "SET_CUSTOMER_DETAILS_INFO",
  CLRINTERNALNOTE: "CLRINTERNALNOTE",
  CLR_DATA_TEST_TIME: "CLR_DATA_TEST_TIME",
  CHECK_CUSTOMER_INFORMATION: "CHECK_CUSTOMER_INFORMATION",
  CHECK_RECIPENT_INFORMATION: "CHECK_RECIPENT_INFORMATION",
  CHECK_COMMAN_RECIPENT_INFORMATION: "CHECK_COMMAN_RECIPENT_INFORMATION",
  CHECK_DELIVERY_INFORMATION: "CHECK_DELIVERY_INFORMATION",
  CAHECK_CARD_COMPLETION_STATUS: "CAHECK_CARD_COMPLETION_STATUS",
  SET_PAYMENT_TYPE: "SET_PAYMENT_TYPE",
  SET_CARD_SIGNATURE: "SET_CARD_SIGNATURE",
  SET_HLOIDAY_CUT_OFF: "SET_HLOIDAY_CUT_OFF",
  SET_PRODUCT_CATAGORY_EDIT: "SET_PRODUCT_CATAGORY_EDIT",
  UPDATE_PRODUCT_CATAGORY: "UPDATE_PRODUCT_CATAGORY",
  BILLING_ADDRESS_CHECK: "BILLING_ADDRESS_CHECK",
  CLEAR_ADDON: "CLEAR_ADDON",
  RESET: "RESET",
  DUPLICATE_ORDER: "DUPLICATE_ORDER",
  REPLACE_ORDER: "REPLACE_ORDER",
  SET_CARD: "SET_CARD",
  SET_EDIT_MODE: "SET_EDIT_MODE",
  CHANGE_ORDER_TYPE: "CHANGE_ORDER_TYPE",
  SET_ORDER_INTIAL_CONFIG: "SET_ORDER_INTIAL_CONFIG",
  CLEAR_DELIVERY_DATE_TIME: "CLEAR_DELIVERY_DATE_TIME",
};
