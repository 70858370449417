import { callPostApi, callDeleteApi, callPutApi, callGetApi } from "./api";


export async function userLogin(data) {
      // return this.interceptors.POST('users/login', data);
      try {
            const response = await callPostApi({ url: "users/login", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function userLogout() {
      // return this.interceptors.GET('users/logout');
      try {
            const response = await callGetApi({ url: "users/logout" });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function userForgotPassword(data) {
      // return this.interceptors.POST('users/forgot-password', data);
      try {
            const response = await callPostApi({ url: "users/forgot-password", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getUserLookup(data="false") {
      // return this.interceptors.GET('users/lookup');
      try {
            const response = await callGetApi({ url: `users/lookup/${data}` });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function userResetPassword(data) {
      // return this.interceptors.POST('users/reset-password', data);
      try {
            const response = await callPostApi({ url: "users/reset-password", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getProfile() {
      // return this.interceptors.GET('users/profile');
      try {
            const response = await callGetApi({ url: "users/profile" });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getUserList(data) {
      // return this.interceptors.POST('users/admin', data);
      try {
            const response = await callPostApi({ url: "users/admin", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateProfile(data) {
      // return this.interceptors.PUT('users', data);
      try {
            const response = await callPutApi({ url: "users", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function uploadProfile(data) {
      // return this.interceptors.PUT_FILE('users/upload-profile', data);
      try {
            const response = await callPutApi({ url: "users/upload-profile", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function changePassword(data) {
      // return this.interceptors.POST('users/change-password', data);
      try {
            const response = await callPostApi({ url: "users/change-password", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getCountries() {
      // return this.interceptors.GET('utils/countries');
      try {
            const response = await callGetApi({ url: "utils/countries" });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getStates(id) {
      // return this.interceptors.GET('utils/states/' + id);
      try {
            const response = await callGetApi({ url: "utils/states/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getRoles() {
      // return this.interceptors.POST('roles/list');
      try {
            const response = await callPostApi({ url: "roles/list" });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function add(data) {
      // return this.interceptors.POST('users', data);
      try {
            const response = await callPostApi({ url: "users", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function update(data) {
      return this.interceptors.PUT('users', data);
}

export async function updateUser(data) {
      // return this.interceptors.PUT('users/edit', data);
      try {
            const response = await callPutApi({ url: "users/edit", body: data });
            return response;
      } catch (error) {
            throw error;

      }
}

export async function deleteUser(id) {
      // return this.interceptors.DELETE('users/' + id);
      try {
            const response = await callDeleteApi({ url: "users/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function verifyUsers(data) {
      // return this.interceptors.POST('users/verify', data);
      try {
            const response = await callPostApi({ url: "users/verify", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getTimezones() {
      // return this.interceptors.GET('utils/timezone');
      try {
            const response = await callGetApi({ url: "utils/timezone" });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getDesigners(data) {
      // return this.interceptors.POST('users/designer-lookup', data);
      try {
            const response = await callPostApi({ url: "users/designer-lookup", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function logoutAllUsers() {
      try {
            const response = await callGetApi({ url: "users/logoutAllUsers" });
            return response;
      } catch (error) {
            throw error;
      }
}
