import RadioBox from "Components/FormControl/RadioBox/RadioBox";
import { MODULES } from "Helpers/Constants";
import { setAllStore } from "Helpers/setAllStore";
import { DateTime } from "luxon";
import moment from "moment";
import React, { useContext, useState } from "react";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router";
import { getAccessByModuleName } from "Routes/Routes";
import { getProductOrderDetail } from "../../../../api/report";
import ViewIcon from "../../../../Assets/images/error-outline.svg";
import ProductOrderFilter from "../../../../Components/ProductOrderFilter/ProductOrderFilter";
import ReactTable from "../../../../Components/Table/Table";
import { AppContext } from "../../../../Context/AppContext";
import { formatUsPhone } from "../../../../Hooks/commanUtility";
import ExportIcon from "./../../../../Assets/images/export.svg";
// Components
import Header from "./../../../../Components/Header/Header";
import styles from "./index.module.scss";
const ProductsOrderLookUp = (props) => {
  const { showToastMessage, modules } = useContext(AppContext);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [productType, setProductType] = React.useState("");
  const [exportData, setExportData] = React.useState([]);
  const [exportHeader, setExportHeader] = React.useState([]);
  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });
  const filterOption = [
    { id: "weekly", title: "Weekly" },
    { id: "monthly", title: "Monthly" },
    { id: "yearly", title: "Yearly" },
    { id: "custom", title: "Custom" },
  ];
  const [type, setType] = useState("delivery_date");
  let accessExport = getAccessByModuleName(modules, MODULES.PRODUCT_ORDER_REPORT);

  const [fromDate, setFromDate] = useState(
    moment().startOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [toDate, setToDate] = useState(
    moment().endOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [selectedPeriod, setSelectedPeriod] = React.useState("weekly");
  const [selectedPeriodLabel, setSelectedPeriodLabel] = React.useState(
    "By Week"
  );

  const [firstTimePageLoad, setFirstTimePageLoad] = React.useState(false);
  
  const [checkAllStore,setCheckAllStore] = useState(true);

  const product_id = props.match.params;
  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
  }) => {

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);

    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    if (accessExport && accessExport.permissions.write_access)
      handleExportProductOrders(fetchData);
    setLoading(true);
    const postData = {
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
      product_id: product_id.id,
      isExport: false,
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      filters,
      sortBy,
      date_type: type,
    };

    if (!firstTimePageLoad) {
      const response = await getProductOrderDetail(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count.length / pageSize));
      } else if (response.status === 403) {
        showToastMessage(undefined, response.data.message, "error", false);
        props.history.push("/profile");
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    } else {
      setFirstTimePageLoad(false);
    }
    setLoading(false);

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  const handleExportProductOrders = async (event) => {
    if (!product_id.id) return;

    let postData = {
      currentPage: event.pageIndex,
      itemsPerPage: event.pageSize,
      globalSearch: event.globalFilter,
      product_id: product_id.id,
      type: productType === "ALL" ? "" : productType,
      isExport: true,
      date_type: type,
      start_date: moment(fromDate).format("YYYY-MM-DD"),
      end_date: moment(toDate).format("YYYY-MM-DD"),
    };

    const response = await getProductOrderDetail(postData);

    if (response && response.status === 200) {
      setExportHeader(response.data.data.header);
      setExportData(response.data.data.data);
    } else if (response.status === 403) {
      showToastMessage(undefined, response.data.message, "error", false);
      props.history.push("/profile");
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const handleClearSearch = async (event) => {
    event.preventDefault();

    setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
    setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
    setSelectedPeriod("weekly");

    fetchData({
      ...defaultFilter,
      fromDateDefault: moment().startOf("isoWeek").format("ddd MMM DD, YYYY"),
      toDateDefault: moment().endOf("isoWeek").format("ddd MMM DD, YYYY"),
    });
    handleExportProductOrders(event);
  };

  const handleSelectedPeriod = async (id) => {
    if (id === "weekly") {
      setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Week");
    } else if (id === "monthly") {
      setFromDate(moment().startOf("month").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("month").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Month");
    } else if (id === "yearly") {
      setFromDate(moment().startOf("year").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("year").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Year");
    }
  };

  const handleFromDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setToDate(moment(data).add(7, "days").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "monthly") {
      setToDate(moment(data).add(1, "months").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "yearly") {
      setToDate(moment(data).add(1, "years").utc().format("ddd MMM DD, YYYY"));
    }
  };

  const handleToDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setFromDate(
        moment(data).subtract(7, "days").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "monthly") {
      setFromDate(
        moment(data).subtract(1, "months").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "yearly") {
      setFromDate(
        moment(data).subtract(1, "years").utc().format("ddd MMM DD, YYYY")
      );
    }
  };

  let viewOrderAccess = getAccessByModuleName(modules, MODULES.VIEW_ORDER);
  const columns = React.useMemo(
    () => [
      {
        Header: "Order N0",
        accessor: "order_number",
        isVisible: true,
        placeholder: "Search by Order No",
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        isVisible: true,
        Cell: ({ value, row, column }) => {
          return <div>{row.original.order_transaction.customer_name}</div>;
        },
      },
      {
        Header: "Recipient Name",
        accessor: "recipient_name",
        isVisible: true,
        Cell: ({ value, row, column }) => {
          return <div>{row.original.order_recipients[0].recipient_name}</div>;
        },
      },
      {
        Header: "Customer Phone",
        accessor: "customer_phone",
        isVisible: true,
        Cell: ({ value, row, column }) => {
          return `${
            row.original.order_transaction.customer_phone
              ? row.original.order_transaction.customer_phone_country_code + " "+ formatUsPhone(row.original.order_transaction.customer_phone)
              : ""
          }`;
        },
      },
      {
        Header: "Recipient Phone",
        accessor: "recipient_phone",
        isVisible: true,
        Cell: ({ value, row, column }) => {
          return `${
            row.original.order_recipients[0].recipient_phone
              ? row.original.order_recipients[0].phone_country_code +" "+ formatUsPhone(row.original.order_recipients[0].recipient_phone)
              : ""
          }`;
        },
      },
      {
        Header: "Delivery Date",
        accessor: "delivery_date",
        isVisible: true,
        Cell: ({ value, row, column }) => {
          return `${
            row.original.delivery_date && row.original.delivery_date !== null
              ? DateTime.fromISO(row.original.delivery_date)
                  .setZone("America/Los_Angeles")
                  .toFormat("ccc LLL dd, yyyy")
              : ""
          }`;
        },
      },
      {
        Header: "Action",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {/* <img
                className={`cursor-pointer mr-2 view-icon`}
                src={ViewIcon}
                alt="View Order"
              /> */}
              {viewOrderAccess && viewOrderAccess.permissions.read_access ? (
                <img
                  className={`cursor-pointer mr-2 view-icon`}
                  src={ViewIcon}
                  alt="View Order"
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push(`/admin/orders/view/${row.original.id}`);
                  }}
                />
              ) : (
                <span style={{ width: 34, height: 34, display: "block" }} />
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Header title="Products Order Lookup" />

      <div id="MainContent" className="main-content">
        <div className="row">
          <div className="col-12 d-flex align-items-center">
            <span
              className="mb-0 mt-2 pb-0 d-inline-bock mr-3"
              style={{ lineHeight: 1 }}
            >
              Filter By
            </span>
            <RadioBox
              selectedValue={type}
              onChange={() => {
                if (setType) {
                  setType("order_date");
                }
              }}
              label="Order date"
              value="order_date"
            />
            <RadioBox
              selectedValue={type}
              onChange={() => {
                if (setType) {
                  setType("delivery_date");
                }
              }}
              label="Delivery date"
              value="delivery_date"
            />
          </div>

          <div className="col-md-11">
            <ProductOrderFilter
              selectedPeriodLabel={selectedPeriodLabel}
              selectedPeriod={selectedPeriod}
              setSelectedPeriod={setSelectedPeriod}
              handleSelectedPeriod={handleSelectedPeriod}
              fromDate={fromDate}
              setFromDate={setFromDate}
              handleFromDate={handleFromDate}
              toDate={toDate}
              setToDate={setToDate}
              handleToDate={handleToDate}
              fetchData={fetchData}
              handleClearSearch={handleClearSearch}
              defaultFilter={defaultFilter}
              filterOption={filterOption}
              AllStoreCheckbox={true}
              defaultSetCheckAllStore={checkAllStore}
              checkAllStore={(data)=>{setCheckAllStore(data)}}
            />
          </div>
          {accessExport && accessExport.permissions.write_access && (
            <div className="col-md-1">
              <CSVLink
                style={{ float: "right", "margin-top": "25px" }}
                data={exportData}
                headers={exportHeader}
                filename={"ProductsOrderedReport.csv"}
                className={`link cursor-pointer ml-3 ${styles.searchIconWrapper}`}
              >
                <img className={styles.searchIcon} src={ExportIcon} alt="" />
              </CSVLink>
            </div>
          )}
        </div>
        <ReactTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
          displayColumnVisibility={true}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(ProductsOrderLookUp);
