// Packages
import React, { useContext } from 'react';
import moment from 'moment';
import 'moment-timezone';

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// SCSS
import styles from './AcceptReject.module.scss';

// Component
import Text from "./../../../FormControl/Text/Text";

// Context
import { AppContext } from "./../../../../Context/AppContext";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AcceptReject = ({ showModal, handleCancelClick, handleSubmitClick, comment, setComment, submitStatus, changeRequestDetailsObj, acceptRejectChangeRequestStatus }) => {
    const { timezone } = useContext(AppContext);

    return (
        <React.Fragment>
            <Dialog
                open={showModal}
                TransitionComponent={Transition}
                onClose={handleCancelClick}
                classes={{
                    paper: styles.modalDialog
                }}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogContent
                    className={`${styles.modalContent} px-0 py-0`}
                >
                    <div className={`text-center ${styles.modalBody}`}>
                        <div className="d-flex justify-content-between my-3">
                            <h5 className={`m-0 text-center ${styles.modalTitle}`}>
                                {
                                    acceptRejectChangeRequestStatus === 2 &&
                                    <span>Accept Request</span>
                                }
                                {
                                    acceptRejectChangeRequestStatus === 3 &&
                                    <span>Reject Request</span>
                                }
                            </h5>
                        </div>

                        <div className="table-responsive mt-4">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td className={`text-left no-border px-0 ${styles.title}`}> Original Time </td>
                                        <td className="text-left no-border px-0">
                                            {
                                                timezone &&
                                                    changeRequestDetailsObj && changeRequestDetailsObj.clock_time && changeRequestDetailsObj.type === "IN" ? changeRequestDetailsObj && changeRequestDetailsObj.clock_time && changeRequestDetailsObj.clock_time.clock_in ? moment.unix(changeRequestDetailsObj.clock_time.clock_in).tz(timezone).format('hh:mm A') + ', Clock In' : '-' : changeRequestDetailsObj && changeRequestDetailsObj.clock_time && changeRequestDetailsObj.clock_time.clock_out ? moment.unix(changeRequestDetailsObj.clock_time.clock_out).tz(timezone).format('hh:mm A') + ', Clock Out' : '-'
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={`text-left no-border px-0 ${styles.title}`}> New Time </td>
                                        <td className="text-left no-border px-0">
                                            {
                                                changeRequestDetailsObj && timezone &&
                                                moment.unix(changeRequestDetailsObj.time).tz(timezone).format('hh:mm A')
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="form-group bmd-form-group mb-5 pt-0">
                            <Text
                                type="text"
                                label="Comment"
                                value={comment}
                                required={true}
                                submitStatus={submitStatus}
                                onChange={(comment) => setComment(comment)}
                            />
                        </div>

                        <a href="/" data-dismiss="modal" onClick={handleSubmitClick} className="btn gray-btn d-inline-block mb-3 mr-2"> Submit </a>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment >
    );
}

export default AcceptReject;
