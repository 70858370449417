import React, { useState, useEffect, useContext } from "react";
// import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import moment from "moment";
import Date from "../../../Components/FormControl/Date/Date";
import CalendarIcon from "../../../Assets/images/calendar.svg";
import SearchIcon from "../../../Assets/images/search.svg";
import CloseIcon from "../../../Assets/images/close.svg";

// Scss
import styles from "./index.module.scss";

// Context
import { AppContext } from "./../../../Context/AppContext";
import { getAccessByModuleName } from "./../../../Routes/Routes";
import { MODULES } from "./../../../Helpers/Constants";

// Components
import Header from "./../../../Components/Header/Header";
import ReactTable from "../../../Components/Table/Table";

// Services
import { getAuditTrailReportList } from "../../../api/auditTrail";

// Images
import AddIcon from "./../../../Assets/images/add.svg";
import { DateTime } from "luxon";
import { PST_ZONE } from "Pages/Order/Order/utility/util";
import CheckBox from "Components/FormControl/CheckBox/CheckBox";
import { setAllStore } from "Helpers/setAllStore";

const AuditTrailPages = (props) => {
  // const history = useHistory();

  const { showToastMessage, modules } = useContext(AppContext);

  const [access, setAccess] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [defaultFilter, setDefaultFilter] = useState({
    currentPage: 0,
    itemsPerPage: 10,
    sortBy: [],
    filters: [],
  });

  const [fromDate, setFromDate] = useState(moment().format("ddd MMM DD, YYYY"));
  const [toDate, setToDate] = useState(moment().format("ddd MMM DD, YYYY"));



  useEffect(getModuleAccess, [modules]);
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.AUDIT_TRAIL_LOG_REPORT);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/users");
    }
  }

  const handleClearSearch = async (event) => {
    event.preventDefault();
    setFromDate(moment().format("ddd MMM DD, YYYY"));
    setToDate(moment().format("ddd MMM DD, YYYY"));
    fetchData({
      ...defaultFilter,
      fromDateDefault: moment().format("YYYY-MM-DD"),
      toDateDefault: moment().format("YYYY-MM-DD"),
    });
  };

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
  }) => {


    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
    };
    // const response = await CmsPagesService.getCmsPagesList(postData);
    const response = await getAuditTrailReportList(postData);

    if (response && response.status === 200) {
      setData(response.data.data.rows);
      // let lengthCount = response.data.data.count
      setPageCount(Math.ceil(response.data.data.count / 10));
    } else if (response.status === 403) {
      showToastMessage(undefined, response.data.message, "error", false);
      props.history.push("/profile");
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);

  };

  const handleAddAuditTrailAddClick = async (event, id) => {
    event.preventDefault();

    props.history.push(`/reports/audit-trail/detail/${id}`);
  };

  let pageAccess = getAccessByModuleName(modules, MODULES.AUDIT_TRAIL_LOG_REPORT);
  const columns = React.useMemo(
    () => [
      {
        Header: "Action",
        accessor: "action",
        isVisible: true,
        placeholder: "Search by Action",
        Cell: ({ value, row, column }) => {
          return (
            <h4>
              {row.original.action}
            </h4>
          );
        },
      },
      {
        Header: "Module",
        accessor: "table_name",
        isVisible: true,
        placeholder: "Search by Module",
        Cell: ({ value, row, column }) => {
          return (
            <h4>
              {row.original.table_name}
            </h4>
          );
        },
      },
      {
        Header: "Identification",
        accessor: "parent_id",
        isVisible: true,
        placeholder: "Search by Identification",
        isCustomFilter:true,
        isCustomFilterLength:1,
        Cell: ({ value, row, column }) => {
          return (
            <h4>
              {(row.original.table_name === 'orders' || 
                row.original.table_name === 'stock_order' || 
                row.original.table_name === "custom_product_recipe" || 
                row.original.table_name === "inventory_waste_sheets" ||
                row.original.table_name === "inventory_request_resources") ? 
                '#'+row.original.parent_id : 
                row.original.parent_id+' - '+row.original.displayName}
            </h4>
          );
        },
      },
      {
        Header: "User",
        accessor: "user_name",
        isVisible: true,
        placeholder: "Search by User Name",
      },
      {
        Header: "Date Time",
        accessor: "createdAt",
        isVisible: true,
        placeholder: "Date",
        Cell: ({ value, row, column }) => {
          return `${DateTime.fromISO(row.original.createdAt)
            .setZone(PST_ZONE)
            .toFormat("ccc LLL dd, yyyy, hh:mm a")}`;
        },
      },
      {
        Header: "Action",
        accessor: "id",
        isVisible: (pageAccess && pageAccess.permissions.read_access) ? true : false,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div className={`${styles.iconWrapper}`}>
              {pageAccess && pageAccess.permissions.read_access && (
                <img
                  className={`cursor-pointer ml-3 ${styles.icon}`}
                  src={AddIcon}
                  alt="Detail"
                  onClick={(e) =>
                    handleAddAuditTrailAddClick(e, row.original.id)
                  }
                />
              )}
            </div>
          );
        },
      },
    ],
    [data]
  );

  return (
    <React.Fragment>
      <Header
        title="Audit Trail"
        // hasAddButton={1}
        // handleAddClick={handleAddCmsPageClick}
      />
      <div id="MainContent" className="main-content" />
      <div className="row mb-5">
        <div className="col-sm-4 col-xs-12">
          <Date
            label="Filter From"
            value={fromDate}
            dateFormat="ddd MMM DD, YYYY"
            type="date"
            fieldIcon={CalendarIcon}
            onChange={(fromDate) => {
              setFromDate(moment(fromDate).format("ddd MMM DD, YYYY"));
            }}
          />
        </div>
        <div className="col-sm-4 col-xs-12">
          <Date
            label="Filter To"
            value={toDate}
            dateFormat="ddd MMM DD, YYYY"
            type="date"
            fieldIcon={CalendarIcon}
            onChange={(toDate) => {
              setToDate(moment(toDate).format("ddd MMM DD, YYYY"));
            }}
          />
        </div>
        <div className="col-sm-2 col-xs-12">
          <div className="td-actions pt-4">
            <span
              onClick={(e) => fetchData(defaultFilter)}
              className={`link cursor-pointer ${styles.searchIconWrapper}`}
            >
              <img className={styles.searchIcon} src={SearchIcon} alt="" />
            </span>
            <span
              onClick={(e) => handleClearSearch(e)}
              className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
            >
              <img className={styles.clearIcon} src={CloseIcon} alt="" />
            </span>
          </div>
        </div>
      </div>

      <div>
        <ReactTable
          columns={columns}
          data={data}
          expandedRows={true}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(AuditTrailPages);
