import { DateTime } from "luxon";
import moment from "moment";
export function EditOrderState(orderState, editOrderState) {
  let orderStateNew = { ...orderState };
  let mainstate = { ...editOrderState };
  let activeRecipentIndex = 0;

  let recipents = orderStateNew.order_recipients.map((recipentData) => {
    let added_product = [];
    let addedAddons = [];
    recipentData.order_products.forEach((productdata) => {
      let data = {
        id: productdata.product_id,
        sqlid: productdata.id ?? "",
        order_id: productdata.order_id ?? 0,
        order_recipients_id: productdata.order_recipients_id ?? 0,
        product_id: productdata.product_id ?? 0,
        product_type: productdata.product_type === false ? 0 : 1,
        qty: productdata.qty ?? 0,
        unit_price: productdata.unit_price ?? 0,
        total_price: productdata.total_price ?? 0,
        promocode: productdata.promocode ?? "",
        discount_price: productdata.discount_price ?? 0,
        commission_price: productdata.commission_price ?? 0,
        total_commission_price: productdata.total_commission_price ?? 0,
        product_name: productdata.product_name ?? "",
        variation_id: productdata.variation_id ?? 0,
        variation_image: productdata.variation_image ?? "",
        variation_name: productdata.variation_name ?? "",
        title: productdata.product_name ?? "",
        selectedVariant: {
          image: productdata.variation_image ?? "",
          price: productdata.unit_price ?? 0,
          commission: productdata.commission_price ?? 0,
          title: productdata.variation_name ?? "",
          id: productdata.variation_id ?? 0,
          productid: productdata.product_id ?? 0,
        },
      };
      if (productdata.product_type === false) {
        added_product.push(data);
      } else {
        addedAddons.push(data);
      }
    });
    return {
      form: {
        recipients_id: recipentData.recipients_id ?? 0,
        id: recipentData.id ?? 0,
        whats_the_occasion: recipentData.whats_the_occasion ?? "",
        whats_the_occasion_id: recipentData.whats_the_occasion_id ?? 0,
        first_name: recipentData.first_name ?? "",
        last_name: recipentData.last_name ?? "",
        greetings_on_card: recipentData.greetings_on_card ?? "",
        card_signature: recipentData.card_signature ?? "",
        video_url: recipentData.video_url ?? "",
        video_message: recipentData.video_message ?? "",
        video_message_pass_code: recipentData.video_message_pass_code ?? "",
        completed: true,
      },
      // isVerifiedRecipient: 0,

      added_product: added_product,
      addedAddons: addedAddons,
      flower_total: orderStateNew.flower_total_amount ?? 0,
      tax: orderStateNew.tax ?? 0,
      delivery_charge: orderStateNew.delivery_charges ?? 0,
      subtotal: orderStateNew.sub_total ?? 0,
      total: orderStateNew.total ?? 0,
      deleted_recipient_product_id: [],
      internal_notes: orderStateNew.order_internal_notes?.map(
        (internalNotesdata) => {
          return {
            author: internalNotesdata?.user?.first_name ?? "",
            ans: internalNotesdata.notes ?? "",
            time: moment(internalNotesdata.createdAt)
              .format("ddd MMM DD,YYYY, h:mm a")
              .toString(),
          };
        }
      ),
    };
  });

  let formInfo = orderStateNew.order_recipients.map((Formdata) => {
    return {
      order_id: Formdata.order_id,
      address_type: Formdata.address_type,
      address_type_id: Formdata.address_type_id,
      attn: Formdata.attn,
      address_line_1: Formdata.address_line_1,
      address_line_2: Formdata.address_line_2,
      phone: Formdata.phone,
      alt_phone: Formdata.alt_phone,
      city: Formdata.city,
      state: Formdata.state,
      zipcode: Formdata.zipcode,
      country: "usa",
      is_local: orderStateNew.is_local_order ?? 0,
      latitude: "",
      longitude: "",
      completed: true,
    };
  });
  let delivered_time = DateTime.fromISO(orderStateNew.delivery_date_time, {
    zone: "UTC",
  })
    .setZone("America/Los_Angeles")
    .toFormat("hh:mm a");
  // debugger;
  let order = {
    id: orderStateNew.id ?? 0,
    csr_id: orderStateNew.csr_id ?? 0,
    order_type: orderStateNew.order_type === false ? 0 : 1,
    source: orderStateNew.source ?? "",
    designer_id: orderStateNew.designer_id ?? 0,
    actual_delivered_time: orderStateNew.actual_delivered_time ?? "",
    recipient_signature_or_picture:
      orderStateNew.recipient_signature_or_picture ?? "",
    driver_instruction: orderStateNew.driver_instruction ?? "",
    delivery_date: DateTime.fromISO(orderStateNew.delivery_date_time)
      .setZone("America/Los_Angeles")
      .toFormat("yyyy-LL-dd"),
    // delivery_date: orderStateNew.delivery_date,
    delivery_type: orderStateNew.delivery_type ?? 0,
    delivery_type_id: orderStateNew.delivery_type_id ?? 0,
    delivered_time_id: orderStateNew.delivered_time_id ?? 0,
    delivered_time: delivered_time ?? "",
    custom_delivery_time: orderStateNew.custom_delivery_time ?? "",
    custom_delivery_time_before_after:
      orderStateNew.custom_delivery_time_before_after ?? "",
    custom_delivery_time_amount: orderStateNew.custom_delivery_time_amount,
    shopcode: orderStateNew.shopcode ?? "",
    external_shop_name: orderStateNew.external_shop_name ?? "",
    external_phone_number: orderStateNew.external_phone_number ?? "",
    external_person_name: orderStateNew.external_person_name ?? "",
    external_order_number: orderStateNew.external_order_number ?? "",
    designer_instruction: orderStateNew.designer_instruction ?? "",
    total_qty: orderStateNew.total_qty ?? 0,
    sub_total: orderStateNew.sub_total ?? 0,
    promocode_type: orderStateNew.promocode_type ?? "",
    promocode_id: orderStateNew.promocode_id ?? 0,
    promocode: orderStateNew.promocode ?? "",
    created_by: orderStateNew.created_by ?? 0,
    discount_percentage: orderStateNew.discount_percentage ?? 0,
    discount_price: orderStateNew.discount_price ?? 0,
    upcharge_id: orderStateNew.upcharge_id ?? 0,
    upcharge_amount: orderStateNew.upcharge_amount ?? 0,
    delivery_charges: orderStateNew.delivery_charges ?? 0,
    custom_delivery_time_ampm: orderStateNew.custom_delivery_time_ampm ?? "",
    custom_reason_for_discount: orderStateNew.custom_reason_for_discount ?? "",
    earned_petal_rewards_point: orderStateNew.earned_petal_rewards_point ?? 0,
    flower_total_amount: orderStateNew.flower_total_amount ?? 0,
    gift_card_amount: orderStateNew.gift_card_amount ?? 0,
    gift_card_id: orderStateNew.gift_card_id ?? 0,
    gift_card_detail: orderStateNew.gift_card_detail ?? "",
    internal_notes: orderStateNew.internal_notes ?? "",
    is_fraud_order: orderStateNew.is_fraud_order ?? 0,
    is_local_order: orderStateNew.is_local_order ?? 0,
    order_status: orderStateNew.order_status ?? "",
    petal_rewards_earn_amount: orderStateNew.petal_rewards_earn_amount ?? 0,
    petal_rewards_id: orderStateNew.petal_rewards_id ?? 0,
    petal_rewards_point_amount: orderStateNew.petal_rewards_point_amount ?? 0,
    petal_rewards_point_applied: orderStateNew.petal_rewards_point_applied ?? 0,
    tax: orderStateNew.tax ?? 0,
    total: orderStateNew.total ?? 0,
    mainTotal: orderStateNew.total ?? 0,
    tax_id: orderStateNew.tax_id ?? "",
    total_commission_amount: orderStateNew.total_commission_amount ?? 0,
    delivery_charges_percentage: orderStateNew.delivery_charges_percentage ?? 0,
    modified_by: orderStateNew.modified_by ?? 0,
    deleted_recipient_id: [],
    upcharge_percentage: orderStateNew.upcharge_percentage ?? 0,
    refund: 0,
    total_fund: orderStateNew.total_fund_amount ?? 0,
    total_refund: orderStateNew.total_refund_amount ?? 0,
    is_subscribe_order: orderStateNew.is_subscribe_order ?? false,
    frequency: +orderStateNew.subscription_order?.frequency ?? 0,
    parent_order_id: orderStateNew.parent_order_id ?? 0,
    add_fund: 0,
    delivery_date_time:
      DateTime.fromISO(orderStateNew.delivery_date_time)
        .setZone("utc")
        .toFormat("yyyy-LL-dd HH:mm") ?? "",
    delivery_time_type: orderStateNew.delivery_time_type ?? "",
  };

  let order_transaction = {
    id: orderStateNew.order_transaction.id ?? 0,
    customer_address_id: 0,
    customer_card_id: orderStateNew.order_transaction.customer_card_id ?? 0,
    total_qty: 0,
    sub_total: 0,
    promocode: "",
    promocode_id: 0,
    discount_price: 0,
    delivery_charges: 0,
    upcharge_amount: 0,
    tax: 0,
    tax_id: "",
    flower_total_amount: 0,
    petal_rewards_id: 0,
    petal_rewards_point_amount: 0,
    petal_rewards_point_applied: 0,
    total: 0,
    total_commission_amount: 0,
    gift_card_amount: 0,
    gift_card_detail: "",
    gift_card_id: "",
    order_from: 0,
    customer_id: orderStateNew.order_transaction.customer_id ?? 0,
    promocode_type: "",
    earned_petal_rewards_point: 0,
    subscription_discount: orderStateNew.subscription_discount ?? 0,
  };
  let customer_info = {
    customer_first_name:
      orderStateNew.order_transaction.customer_first_name ?? "",
    customer_last_name:
      orderStateNew.order_transaction.customer_last_name ?? "",
    customer_billing_address:
      orderStateNew.order_transaction.customer_billing_address ?? "",
    customer_billing_city:
      orderStateNew.order_transaction.customer_billing_city ?? "",
    customer_billing_state:
      orderStateNew.order_transaction.customer_billing_state ?? "",
    customer_billing_country: "usa",
    customer_billing_zipcode:
      orderStateNew.order_transaction.customer_billing_zipcode ?? "",
    customer_email: orderStateNew.order_transaction.customer_email ?? "",
    customer_phone: orderStateNew.order_transaction.customer_phone ?? "",
    customer_alt_phone:
      orderStateNew.order_transaction.customer_alt_phone ?? "",
    is_business_account: orderStateNew.order_transaction.is_business_account ?? 0,  
    is_default: 0,
    completed: true,
  };
  let delivery_type_id = orderStateNew.delivery_type_id ?? 0;
  let not_available_at_home_key = orderStateNew.not_available_at_home_key ?? "";
  let not_available_at_home_option =
    orderStateNew.not_available_at_home_option ?? "";
  let is_local_available = orderStateNew.is_local_order ?? false;
  let order_card_list =
    orderStateNew.order_transaction.order_payment_transactions?.map(
      (cardData) => {
        return {
          id: cardData.id ?? 0,
          transaction_id: cardData.transaction_id ?? "",
          transaction_type: cardData.transaction_type ?? "credit_card",
          card_id: cardData.card_id ?? 0,
          card_last_4_digits: cardData.card_last_4_digits ?? "",
          transaction_status: cardData.transaction_status ?? "",
          total_amount: cardData.total_amount ?? 0,
        };
      }
    );
  let order_payment_transaction =
    orderStateNew.order_transaction.order_payment_transactions?.map(
      (cardData) => {
        return {
          id: cardData.id ?? 0,
          transaction_id: cardData.transaction_id ?? "",
          transaction_type: cardData.transaction_type ?? "credit_card",
          card_id: cardData.card_id ?? 0,
          card_last_4_digits: cardData.card_last_4_digits ?? "",
          transaction_status: cardData.transaction_status ?? "",
          total_amount: cardData.total_amount ?? 0,
          card_type:
            cardData.card_type === null ? "" : cardData.card_type ?? "",
        };
      }
    );

  let order_validations = orderStateNew.order_validations.map((data) => {
    return {
      id: data.id ?? 0,
      order_id: data.order_id ?? 0,
      validation_type: data.validation_type ?? "",
      validation_message: data.validation_message ?? "",
      validation_status: data.validation_status ?? 0,
      updated_by: data.updated_by ?? 0,
    };
  });

  let billing_data = {
    customer_billing_first_name:
      orderStateNew.order_transaction.customer_billing_first_name ?? "",
    customer_billing_last_name:
      orderStateNew.order_transaction.customer_billing_last_name ?? "",
    customer_billing_address:
      orderStateNew.order_transaction.customer_billing_address_1 ?? "",
    customer_billing_city:
      orderStateNew.order_transaction.customer_billing_city ?? "",
    customer_billing_state:
      orderStateNew.order_transaction.customer_billing_state ?? "",
    customer_billing_country: "United States",
    customer_billing_zipcode:
      orderStateNew.order_transaction.customer_billing_zipcode ?? "",
    completed: true,
  };

  return {
    ...mainstate,
    editMode: true,
    custom_order: orderStateNew.is_custom_order,
    order_type_identifier: orderStateNew.order_type_identifier,
    activeRecipentIndex: activeRecipentIndex,
    billing_data: billing_data,
    formInfo: formInfo[0],
    order: order,
    order_transaction: order_transaction,
    order_payment_transaction: order_payment_transaction,
    customer_info: customer_info,
    delivery_type_id: delivery_type_id,
    not_available_at_home_key: not_available_at_home_key,
    not_available_at_home_option: not_available_at_home_option,
    is_local_available: is_local_available,
    order_card_list: order_card_list,
    recipents: recipents,
    order_validations: order_validations,
  };
}

export function duplicate(orderState, editOrderState, state) {
  let orderStateNew = { ...orderState };
  let mainstate = { ...editOrderState };
  let activeRecipentIndex = 0;

  //Card Details
  let order_card_list =
    orderStateNew.order_transaction.order_payment_transactions?.map(
      (cardData) => {
        return {
          id: cardData.id ?? 0,
          transaction_id: cardData.transaction_id ?? "",
          transaction_type: cardData.transaction_type ?? "credit_card",
          card_id: cardData.card_id ?? 0,
          card_last_4_digits: cardData.card_last_4_digits ?? "",
          transaction_status: cardData.transaction_status ?? "",
          total_amount: cardData.total_amount ?? 0,
        };
      }
    );

  //Setting Recipients
  let recipents = orderStateNew.order_recipients.map((recipentData) => {
    let added_product = [];
    let addedAddons = [];
    recipentData.order_products.forEach((productdata) => {
      let data = {
        sqlid: productdata.id ?? "",
        order_id: productdata.order_id ?? 0,
        order_recipients_id: productdata.order_recipients_id ?? 0,
        product_id: productdata?.product_id ?? 0,
        id: productdata?.product_id ?? 0,
        product_type: productdata.product_type === false ? 0 : 1,
        qty: productdata.qty ?? 0,
        unit_price: productdata.unit_price ?? 0,
        total_price: productdata.total_price ?? 0,
        promocode: productdata.promocode ?? "",
        discount_price: productdata.discount_price ?? 0,
        commission_price: productdata.commission_price ?? 0,
        total_commission_price: productdata.total_commission_price ?? 0,
        product_name: productdata.product_name ?? "",
        variation_id: productdata.variation_id ?? 0,
        variation_image: productdata.variation_image ?? "",
        variation_name: productdata.variation_name ?? "",
        title: productdata.product_name ?? "",
        selectedVariant: {
          image: productdata.variation_image ?? "",
          price: productdata.unit_price ?? 0,
          commission: productdata.commission_price ?? 0,
          title: productdata.variation_name ?? "",
          id: productdata.variation_id ?? 0,
          productid: productdata.product_id ?? 0,
        },
      };
      if (productdata.product_type === false) {
        added_product.push(data);
      } else {
        addedAddons.push(data);
      }
    });
    return {
      form: {
        recipients_id: recipentData.recipients_id ?? 0,
        id: recipentData.id ?? 0,
        whats_the_occasion: recipentData.whats_the_occasion ?? "",
        whats_the_occasion_id: recipentData.whats_the_occasion_id ?? 0,
        first_name: recipentData.first_name ?? "",
        last_name: recipentData.last_name ?? "",
        greetings_on_card: recipentData.greetings_on_card ?? "",
        card_signature: recipentData.card_signature ?? "",
        video_url: recipentData.video_url ?? "",
        video_message: recipentData.video_message ?? "",
        video_message_pass_code: recipentData.video_message_pass_code ?? "",
        completed: true,
      },
      // isVerifiedRecipient:

      added_product: added_product,
      addedAddons: addedAddons,
      flower_total: orderStateNew.flower_total_amount ?? 0,
      tax: orderStateNew.tax ?? 0,
      delivery_charge: orderStateNew.delivery_charges ?? 0,
      subtotal: orderStateNew.sub_total ?? 0,
      total: orderStateNew.total ?? 0,
      deleted_recipient_product_id: [],
      internal_notes: "",
    };
  });

  //Setting delivery information
  let formInfo = orderStateNew.order_recipients.map((Formdata) => {
    return {
      order_id: Formdata.order_id,
      address_type: Formdata.address_type,
      address_type_id: Formdata.address_type_id,
      attn: Formdata.attn,
      address_line_1: Formdata.address_line_1,
      address_line_2: Formdata.address_line_2,
      phone: Formdata.phone,
      alt_phone: Formdata.alt_phone,
      city: Formdata.city,
      state: Formdata.state,
      zipcode: Formdata.zipcode,
      country: "usa",
      is_local: orderStateNew.is_local_order ?? 0,
      latitude: "",
      longitude: "",
    };
  });

  //Customer Information
  let customer_info = {
    customer_id: orderStateNew.order_transaction.customer_id,
    customer_first_name:
      orderStateNew.order_transaction.customer_first_name ?? "",
    customer_last_name:
      orderStateNew.order_transaction.customer_last_name ?? "",
    customer_billing_address:
      orderStateNew.order_transaction.customer_billing_address ?? "",
    customer_billing_city:
      orderStateNew.order_transaction.customer_billing_city ?? "",
    customer_billing_state:
      orderStateNew.order_transaction.customer_billing_state ?? "",
    customer_billing_country: "usa",
    customer_billing_zipcode:
      orderStateNew.order_transaction.customer_billing_zipcode ?? "",
    customer_email: orderStateNew.order_transaction.customer_email ?? "",
    is_business_account: orderStateNew.order_transaction.is_business_account ?? 0, 
    customer_phone: orderStateNew.order_transaction.customer_phone ?? "",
    customer_alt_phone:
      orderStateNew.order_transaction.customer_alt_phone ?? "",
    is_default: 0,
  };

  let delivery_type_id = orderStateNew.delivery_type_id ?? 0;

  let not_available_at_home_key = orderStateNew.not_available_at_home_key ?? "";

  let not_available_at_home_option =
    orderStateNew.not_available_at_home_option ?? "";

  let is_local_available = orderStateNew.is_local_order ?? false;

  //Setting Billing data
  let billing_data = {
    customer_billing_first_name:
      orderStateNew.order_transaction.customer_billing_first_name ?? "",
    customer_billing_last_name:
      orderStateNew.order_transaction.customer_billing_last_name ?? "",
    customer_billing_address:
      orderStateNew.order_transaction.customer_billing_address_1 ?? "",
    customer_billing_city:
      orderStateNew.order_transaction.customer_billing_city ?? "",
    customer_billing_state:
      orderStateNew.order_transaction.customer_billing_state ?? "",
    customer_billing_country: "United States",
    customer_billing_zipcode:
      orderStateNew.order_transaction.customer_billing_zipcode ?? "",
  };

  return {
    ...mainstate,
    order: {
      ...mainstate.order,
      internal_notes: `${state} order for order number ${orderStateNew.order_number}`,
    },
    editMode: false,
    custom_order: orderStateNew.is_custom_order,
    activeRecipentIndex: activeRecipentIndex,
    billing_data: billing_data,
    formInfo: formInfo[0],
    customer_info: customer_info,
    delivery_type_id: delivery_type_id,
    not_available_at_home_key: not_available_at_home_key,
    not_available_at_home_option: not_available_at_home_option,
    is_local_available: is_local_available,
    recipents: recipents,
    order_type_identifier: 2,
    parent_order_id: orderStateNew.id,
    order_card_list: order_card_list,
    order_number: orderStateNew.order_number,
  };
}
