import React, { useState } from "react";
import moment from "moment";
import { getAccessByModuleName } from "./../../../Routes/Routes";
import Header from "../../../Components/Header/Header";
import ReactTable from "../../../Components/Table/Table";
// Context
import { MODULES, STATUS_FILTER } from "./../../../Helpers/Constants";
import { AppContext } from "../../../Context/AppContext";
import { useHistory } from "react-router-dom";
//icon
import EditIcon from "./../../../Assets/images/edit.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";
import ActivatedIcon from "./../../../Assets/images/activated.svg";
//scss
import styles from "./index.module.scss";
//services
// import BlockOutDatesApi from "../../../Services/BlockOutDates/BlockOutDates";
// import UtilsApi from "../../../Services/Utils/Utils";
import { updateStatus } from "../../../api/utils";
import {
  deleteBlockOutDates,
  getBlockOutDatesList,
} from "../../../api/blockoutDates";
//modals
import DeleteConfirmation from "../../../Components/Modal/DeleteConfirmation/DeleteConfirmation";
import StatusConfirmation from "../../../Components/Modal/StatusConfirmation/StatusConfirmation";
import { convertUtcTimestampToPstDate } from "Hooks/commanUtility";

const BlockoutDates = (props) => {
  // const blockOutDatesService = new BlockOutDatesApi();
  // const utilsService = new UtilsApi();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState({
    isDelete: false,
    id: null,
  });

  const [statusBlockoutId, setStatusBlockoutId] = useState(false);
  const [statusOfBlockout, setStatusOfBlockout] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const { showToastMessage, modules, setIsLoading } = React.useContext(
    AppContext
  );
  //Api Response Hooks
  const [data, setData] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [access, setAccess] = React.useState({});
  const statusList = STATUS_FILTER;
  const [defaultFilter, setDefaultFilter] = React.useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });
  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);

    let updatedFilters = [];

    for (let i = 0; i < filters.length; i++) {
      updatedFilters[i] = Object.assign({}, filters[i]);
    }

    let findIndexOfStartDate = updatedFilters.findIndex(
      (item) => item.id === "start_blocking_on"
    );
    let findIndexOfEndDate = updatedFilters.findIndex(
      (item) => item.id === "stop_repeating_on"
    );
    if (findIndexOfStartDate >= 0) {
      updatedFilters[findIndexOfStartDate].value = moment(
        updatedFilters[findIndexOfStartDate].value,
        "ddd MMM DD, YYYY"
      ).unix();
    }
    if (findIndexOfEndDate >= 0) {
      updatedFilters[findIndexOfEndDate].value = moment(
        updatedFilters[findIndexOfEndDate].value,
        "ddd MMM DD, YYYY"
      ).unix();
    }

    updatedFilters.forEach((item, index) => {
      item.key = item.id;
      delete item.id;
    });
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters: updatedFilters,
      sortBy,
    };

    // let response = await blockOutDatesService.getBlockOutDatesList(postData);
    let response = await getBlockOutDatesList(postData);
    if (response && response.status === 200) {
      setData(response.data.data.rows);
      setPageCount(Math.ceil(response.data.data.count / pageSize));
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);
  };

  const handleStatusBlockoutClick = async (event, id, status) => {
    event.preventDefault();

    setStatusBlockoutId(id);
    setStatusOfBlockout(status);
    setShowStatusModal(true);
  };

  const handleBlockoutStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfBlockout === 1 ? 0 : 1,
      id: statusBlockoutId,
      table: "blockout_dates",
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setStatusBlockoutId(null);
      setStatusOfBlockout(null);
      setShowStatusModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  React.useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.BLOCKOUT_DATES);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/users");
    }
  }

  let blockAccess = getAccessByModuleName(modules, MODULES.BLOCKOUT_DATES);
  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        isVisible: true,
        // Cell: ({ value, row, column }) => {
        //   return `${row.original.title}`;
        // },
      },
      {
        Header: "Start Date",
        accessor: "start_blocking_on",
        isVisible: true,
        // isDateFilter: true,
        // Cell: ({ value }) => moment.unix(value).format("ddd MMM DD, YYYY"),
        Cell: ({ value }) => convertUtcTimestampToPstDate(value,"ccc LLL dd, yyyy"),
      },
      {
        Header: "Stop Repeat At",
        accessor: "stop_repeating_on",
        isVisible: true,
        // isDateFilter: true,
        // Cell: ({ value }) => moment.unix(value).format("ddd MMM DD, YYYY"),
        Cell: ({ value }) => convertUtcTimestampToPstDate(value,"ccc LLL dd, yyyy"),
      },
      {
        Header: "Status",
        accessor: "status",
        isVisible: true,
        placeholder: "Status",
        isSelectFilter: true,
        selectFilterOption: statusList,
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.status}>
              {row.original.status === 1 ? (
                <div className={styles.Active}>Active</div>
              ) : (
                <div className={styles.Inactive}>Inactive </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Local",
        accessor: "services_block_local_delivery",
        isVisible: true,
        disableFilters: true,
        Cell: ({ value }) => {
          return value ? (
            <div className={styles.blocked}>{"Blocked"}</div>
          ) : (
            <div className={styles.allowed}>{"Allowed"}</div>
          );
        },
      },
      {
        Header: "Non-Local",
        accessor: "services_block_non_local_delivery",
        isVisible: true,
        disableFilters: true,
        Cell: ({ value }) => {
          return value ? (
            <div className={styles.blocked}>{"Blocked"}</div>
          ) : (
            <div className={styles.allowed}>{"Allowed"}</div>
          );
        },
      },
      {
        Header: "Pickup",
        accessor: "services_block_pickup_order",
        isVisible: true,
        disableFilters: true,
        Cell: ({ value }) => {
          return value ? (
            <div className={styles.blocked}>{"Blocked"}</div>
          ) : (
            <div className={styles.allowed}>{"Allowed"}</div>
          );
        },
      },
      {
        Header: "Delivery",
        accessor: "services_block_delivery_order",
        isVisible: true,
        disableFilters: true,
        Cell: ({ value }) => {
          return value ? (
            <div className={styles.blocked}>{"Blocked"}</div>
          ) : (
            <div className={styles.allowed}>{"Allowed"}</div>
          );
        },
      },
      {
        Header: "On Demand",
        accessor: "services_block_on_demand_delivery",
        isVisible: true,
        disableFilters: true,
        Cell: ({ value }) => {
          return value ? (
            <div className={styles.blocked}>{"Blocked"}</div>
          ) : (
            <div className={styles.allowed}>{"Allowed"}</div>
          );
        },
      },
      {
        Header: "Will Call",
        accessor: "services_block_will_call",
        isVisible: false,
        disableFilters: true,
        Cell: ({ value }) => {
          return value ? (
            <div className={styles.blocked}>{"Blocked"}</div>
          ) : (
            <div className={styles.allowed}>{"Allowed"}</div>
          );
        },
      },
      {
        Header: "",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {blockAccess && blockAccess.permissions.write_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={EditIcon}
                  alt="Edit"
                  onClick={(e) => handleBlockOutUpdate(value)}
                />
              )}
              {blockAccess && blockAccess.permissions.delete_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={DeleteIcon}
                  alt="Delete"
                  onClick={() =>
                    setShowDeleteModal({ isDelete: true, id: value })
                  }
                />
              )}
              {blockAccess && blockAccess.permissions.write_access && (
                <img
                  className={`${styles.searchIcon}`}
                  src={ActivatedIcon}
                  alt=""
                  onClick={(e) =>
                    handleStatusBlockoutClick(e, value, row.original.status)
                  }
                />
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const deleteCutOff = async () => {
    // const response = await blockOutDatesService.deleteBlockOutDates(
    //   showDeleteModal.id
    // );
    const response = await deleteBlockOutDates(showDeleteModal.id);

    if (response && response.status === 200) {
      setShowDeleteModal({ isDelete: false, id: null });
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    }
  };

  const handleBlockoutAdd = () => {
    history.push("/settings/blockout/add");
  };

  const handleBlockOutUpdate = (id) => {
    history.push("/settings/blockout/add", { id });
  };

  return (
    <div>
      <Header
        title="Blockout Dates"
        hasAddButton={access.write_access}
        handleAddClick={handleBlockoutAdd}
      />
      <div className="d-md-flex align-items-md-center mt-4 mb-3">
        <h3 className="bold mr-3">Delivery Blockout Dates</h3>
      </div>
      <div>
        <ReactTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
          displayColumnVisibility={false}
        />
      </div>

      <DeleteConfirmation
        showModal={showDeleteModal.isDelete}
        title="Delete Date"
        message="Are you sure want to delete this block out?"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteModal({ isDelete: false, id: null });
          // setDeleteNotesId(null);
        }}
        handleSubmitClick={deleteCutOff}
      />

      <StatusConfirmation
        showModal={showStatusModal}
        title={"Blockout Dates"}
        message={"blockout dates?"}
        buttonLabel={statusOfBlockout ? "Inactivate" : "Activate"}
        statusFlag={statusOfBlockout}
        customFlag={false}
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowStatusModal(false);
          setStatusBlockoutId(null);
          setStatusOfBlockout(null);
        }}
        handleSubmitClick={handleBlockoutStatus}
      />
    </div>
  );
};
export default BlockoutDates;
