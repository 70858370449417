import React, { useState } from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// SCSS
import styles from "./index.module.scss";
// Component
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HolidayCutoffCategory = ({
  showModal,
  pageTitle,
  handleCancelClick,
  handleSubmitClick,
  categoryList,
  duplicateCategory,
}) => {
  const [selectedCategory, setSelectedCategories] = useState([]);

  return (
    <React.Fragment>
      <form className={`row`}>
        <Dialog
          open={showModal}
          TransitionComponent={Transition}
          onClose={() => {
            if (handleCancelClick) {
              handleCancelClick();
            }
          }}
          classes={{
            paper: styles.modalDialog,
          }}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogContent className={`${styles.modalContent} px-0 py-0`}>
            <div className={`text-center ${styles.modalBody}`}>
              <h5 className={`my-3 text-center ${styles.modalTitle}`}>
                {pageTitle}
              </h5>
            </div>
            <div className={`row`}>
              <div className={`col-md-10 ml-5`}>
                <Autocomplete
                  id="categorySearching"
                  className="auto-select"
                  freeSolo
                  options={categoryList.map((option) => option.title)}
                  renderInput={(params) => (
                    <TextField {...params} label="Search Category" />
                  )}
                  onInputChange={(event, newValue) => {
                    event.preventDefault();
                    if (newValue !== undefined) {
                      let filterData = categoryList?.filter((data) => {
                        if (data.title === newValue) {
                          return data;
                        }
                      });
                      if (
                        filterData !== undefined &&
                        filterData !== null &&
                        filterData.length > 0
                      ) {
                        setSelectedCategories(filterData);
                      }
                    }
                  }}
                />
                {duplicateCategory === 1 ? (
                  <p className={styles.paragraphBackground}>
                    This category is already added
                  </p>
                ) : (
                  ""
                )}
              </div>

              <div className={`col-md-12 text-center`}>
                <button
                  type="submit"
                  onClick={() => handleSubmitClick(selectedCategory)}
                  className="btn gray-btn d-inline-block mb-3 mr-2"
                >
                  Save
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </form>
    </React.Fragment>
  );
};

export default HolidayCutoffCategory;
