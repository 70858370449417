import { callPostApi, callDeleteApi, callPutApi, callGetApi } from "./api";

export async function getBlogsList(data) {
      // return this.interceptors.POST("cms-blog/list", data);
      try {
            const response = await callPostApi({ url: "cms-blog/list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getBlogDetail(blogId) {
      // return this.interceptors.GET("cms-blog/" + blogId);
      try {
            const response = await callGetApi({ url: "cms-blog/" + blogId });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function createBlog(postData) {
      // return this.interceptors.POST("cms-blog", postData);
      try {
            const response = await callPostApi({ url: "cms-blog", body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateBlog(postData) {
      // return this.interceptors.PUT("cms-blog", postData);
      try {
            const response = await callPutApi({ url: "cms-blog", body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function deleteBlog(id) {
      // return this.interceptors.DELETE("cms-blog/" + id);
      try {
            const response = await callDeleteApi({ url: "cms-blog/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getCategoryLookup() {
      // return this.interceptors.GET("cms-blog/category/lookup");
      try {
            const response = await callGetApi({ url: "cms-blog/category/lookup" });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getAllBlogsList() {
      // return this.interceptors.POST("cms-blog/blog-name-list");
      try {
            const response = await callPostApi({ url: "cms-blog/blog-name-list" });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getBlogCategoryList(postData) {
      // return this.interceptors.POST("cms-blog/category/list", postData);
      try {
            const response = await callPostApi({ url: "cms-blog/category/list", body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getBlogCategoryAdd(postData) {
      // return this.interceptors.POST("cms-blog/category", postData);
      try {
            const response = await callPostApi({ url: "cms-blog/category", body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getBlogCategoryUpdate(postData) {
      // return this.interceptors.PUT("cms-blog/category", postData);
      try {
            const response = await callPutApi({ url: "cms-blog/category/", body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function deleteBlogCategory(id) {
      // return this.interceptors.DELETE("cms-blog/category/" + id);
      try {
            const response = await callDeleteApi({ url: "cms-blog/category/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}


