import React, { useEffect, useState } from 'react';

// SCSS
import styles from './index.module.scss';

function VideoMessage(props) {

    const [iframe, setIframe] = useState("");

  useEffect(() => {
    if (
      props.URL &&
      props.URL.includes("vimeo")
    ) {
      fetch(
        `https://vimeo.com/api/oembed.json?url=${props.URL}&width=400&height=360`
      )
        .then((d) => d.json())
        .then((r) => {
          setIframe(r.html);
        });
    }
  }, []);



    return (
        <div className={styles.VideoMessageWrapper}>
            <div className={styles.container}>
                { props.URL ? 
                    <div className={styles.videoContainer}>
                        {props.URL && !iframe && (
                        <video
                            controls
                            src={props.URL}
                        ></video>
                        )}
                        {iframe && <div dangerouslySetInnerHTML={{ __html: iframe }}></div>}
                    </div>
                : ""
                }
                <div className={styles.textSection}>{props.message}</div>
            </div>
        </div>
    )
}

export default VideoMessage;