import React, { useContext, useEffect, useState } from "react";
import Text from "../../Components/FormControl/Text/Text";
// import Header from "../../Components/Header/Header";
// Context
import { AppContext } from "../../Context/AppContext";
import { getAccessByModuleName } from "../../Routes/Routes";
import { MODULES } from "../../Helpers/Constants";
// Services
// import SettingsApi from "../../Services/Settings/Settings";
import { getByConfigurationKey, update } from "../../api/settings";

const DeliveryCharge = () => {
  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);
  const [deliveryChargeNonLocal, setDeliveryChargeNonLocal] = useState(null);
  const [taxRateChargeNonLocal, setTaxRateChargeNonLocal] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [access, setAccess] = useState({});
  // const settingsService = new SettingsApi();

  useEffect(() => {
    handleDeliveryChargeSetting();
    fetchTaxRateSetting();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  const onChange = (type, data) => {
    if (type === "deliveryChargeNonLocal") setDeliveryChargeNonLocal(data);
    if (type === "taxRateNonLocal") setTaxRateChargeNonLocal(data);
  };

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.CONFIG_STORE_SETTING);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      window.location.href = "/dashboard";
    }
  }

  const handleDeliveryChargeSetting = async () => {
    //     let response = await settingsService.getByConfigurationKey(
    //       "deliveryChargeNonLocal"
    //     );
    let response = await getByConfigurationKey("deliveryChargeNonLocal");

    if (response && response.status === 200) {
      setDeliveryChargeNonLocal(response.data.data.value.charge);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const handleSubmitClick = async (event) => {
    if (deliveryChargeNonLocal && deliveryChargeNonLocal <= 0) {
      showToastMessage(
        undefined,
        "Please enter valid positive number greater than 0",
        "error",
        false
      );
      event.preventDefault();
    } else {
      event.preventDefault();
      setSubmitStatus(true);
      if (deliveryChargeNonLocal) {
        setIsLoading(true);
        let obj = { charge: deliveryChargeNonLocal };
        let requestBody = {
          key: "deliveryChargeNonLocal",
          value: JSON.stringify(obj),
        };

        //   let response = await settingsService.update(requestBody);
        let response = await update(requestBody);

        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          setSubmitStatus(true);
          handleDeliveryChargeSetting();
        } else {
          showToastMessage(undefined, response.data.message, "error", false);
        }
        setIsLoading(false);
      }
    }
  };

  // Fetch Tax Rate Setting data
  const fetchTaxRateSetting = async () => {
    let response = await getByConfigurationKey("tax_rate_non_local");
    if (response && response.status === 200) {
      setTaxRateChargeNonLocal(response.data.data.value);
      console.log('response.data.data.value: ', response.data.data.value);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  }

  // Handle Tax Rate update Setting data
  const handleTaxRateSetting = async (event) => {
    event.preventDefault();
    if (!taxRateChargeNonLocal || isNaN(taxRateChargeNonLocal) ||
        (taxRateChargeNonLocal && taxRateChargeNonLocal <= 0)) {
      showToastMessage(
        undefined,
        "Please enter valid positive number greater than 0",
        "error",
        false
      );
      return;
    }
    setSubmitStatus(true);
    if (taxRateChargeNonLocal) {
      setIsLoading(true);
    
      let requestBody = {
        key: "tax_rate_non_local",
        value: taxRateChargeNonLocal,
      };
      let response = await update(requestBody);
      if (response && response.status === 200) {
        showToastMessage(undefined, response.data.message, "success", false);
        setSubmitStatus(true);
        fetchTaxRateSetting();
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
      setIsLoading(false);
    }
  }

  return (
    <React.Fragment>
      {/* <Header title="Delivery Charge" /> */}
      <div className="row mt-5">
        <div className="col-md-4">
          <Text
            type="text"
            label="Non-Local Delivery charge"
            value={deliveryChargeNonLocal}
            required={true}
            submitStatus={submitStatus}
            id="non_local_delivery_charge"
            onChange={(data) => onChange("deliveryChargeNonLocal", data)}
          />
        </div>
        <div className="col-md-2">
          {access.write_access && (
            <a
              href="/"
              data-dismiss="modal"
              onClick={handleSubmitClick}
              className="btn gray-btn d-inline-block mb-3 mr-2"
            >
              {" "}
              Save{" "}
            </a>
          )}
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-4">
          <Text
            type="text"
            label="Non-Local Tax Rate"
            value={taxRateChargeNonLocal}
            required={true}
            submitStatus={submitStatus}
            id="non_local_tax_rate"
            onChange={(data) => onChange("taxRateNonLocal", data)}
          />
        </div>
        <div className="col-md-2">
          {access.write_access && (
            <a
              href="/"
              data-dismiss="modal"
              onClick={handleTaxRateSetting}
              className="btn gray-btn d-inline-block mb-3 mr-2"
            >
              {" "}
              Save{" "}
            </a>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default DeliveryCharge;
