import Header from "Components/Header/Header";
import { AppContext } from "Context/AppContext";
import { MODULES } from "Helpers/Constants";
import { getAccessByModuleName } from "Routes/Routes";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import AddIcon from "../../../../Assets/images/add.svg";
import styles from "../index.module.scss";
import DeleteIcon from "./../../../../Assets/images/delete.svg";
import { useFormik } from "formik";
import Select from "../../../../Components/FormControl/Select/Select";
import Text from "../../../../Components/FormControl/Text/Text";
import {
  addNewWasteSheet,
  getRowMaterialsList,
  getWasteSheetDetails,
  updateWasteSheet,
  viewWasteSheetDetail,
} from "api/inventoryProduct";
import Confirmation from "Components/Modal/Confirmation/Confirmation";

const AddEditWasteSheet = (props) => {
  const { modules, showToastMessage, setIsLoading } = useContext(AppContext);
  const [access, setAccess] = useState({});
  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.WASTE_SHEET);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/profile");
    }
  }
  const [showErrors, setShowErrors] = useState(false);
  const [rawMaterial, setMaterials] = useState([]);
  const [wasteSheetId, setWasteSheetId] = useState(); //only for update case
  const [updateData, setUpdateData] = useState({ isData: false, data: "" });
  const [data, setData] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [viewWasteSheet, setViewWasteSheet] = useState(false);
    /**
   * Toggle the visibility of the confirmation modal.
   *
   * @param {boolean} showConfirmation - The current visibility state of the confirmation modal.
   * @param {function} setShowConfirmation - The function to update the visibility state of the confirmation modal.
   */
    const [showConfirmation, setShowConfirmation] = useState(false);

  //It used globly to set initial value when needed
  const initialValue = [
    {
      id: "",
      inventory_product_id: "",
      raw_matrial_type: "",
      raw_matrial_unit: "",
      waste_quantity: 0,
      raw_matrial_id: "",
      rawMaterial: "",
      available_quantity: 0,
    },
  ];

  const formik = useFormik({
    initialValues: {
      id: "",
      inventory_waste_sheet_ingredients: initialValue,
    },
    onSubmit: (values) => {
      onSubmitHandler(values);
    },
    validate: (values) => {
      values.inventory_waste_sheet_ingredients.forEach((da) => {
        if (!updateData.isData) {
          if (!da.waste_quantity) {
            setShowErrors(true);
          } else if (da.waste_quantity && !da.inventory_product_id) {
            setShowErrors(true);
          } else {
            setShowErrors(false);
          }
        } else {
          if (da.inventory_product_id && !da.waste_quantity) {
            setShowErrors(true);
          } else if (da.waste_quantity && !da.inventory_product_id) {
            setShowErrors(true);
          } else {
            if(values.inventory_waste_sheet_ingredients.length > 1) {
              if (!da.waste_quantity) {
                setShowErrors(true);
              } else if (da.waste_quantity && !da.inventory_product_id) {
                setShowErrors(true);
              }
            } else {
              setShowErrors(false);
            }
          }
        }
      });
    },
  });

  //update values by key names
  const setValues = async (index, value) => {
    await formik.setValues({ ...formik.values, [index]: value });
  };

  //Use only in view case
  useEffect(() => {
    (async () => {
      const { viewId } = props?.match?.params;
      if (viewId && viewId > 0) {
        await viewWasteSheetDetails(viewId);
        setViewWasteSheet(true);
      } else {
        setViewWasteSheet(false);
      }
    })();
  }, []);

  //Get all Row material list store wise
  const rowMaterialListHandler = async (storeId) => {
    try {
      setIsLoading(true);
      const response = await getRowMaterialsList(storeId);
      let temp = [];
      if (response && response.status === 200) {
        setData(response.data.data);
        response.data.data.map((da, idx) => {
          temp.push({
            id: da?.product_recipe_raw_material?.id,
            title: da.product_recipe_raw_material.title,
          });
        });
        setMaterials(temp);
        temp = [];
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showToastMessage(undefined, error.message, "error", false);
    }
  };

  useEffect(() => {
    (async () => {
      const storeId = localStorage.getItem("selectedStore");
      await rowMaterialListHandler(storeId);
    })();
  }, []);

  //use for update case for get update id
  useEffect(() => {
    (async () => {
      const { wasteId } = props?.match?.params;
      if (wasteId !== undefined && wasteId !== "") {
        setWasteSheetId(wasteId);
        setUpdateData({ isData: true });
      }
    })();
  }, []);

  //Only use in update case
  const wasteSheetDetailsHandler = async (id) => {
    if (updateData.isData) {
      setIsLoading(true);
      try {
        const response = await getWasteSheetDetails(id);
        if (response && response.status === 200) {
          setUpdateData({ isData: true, data: response.data.data });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          props.history.push("/inventory-management/waste-sheet");
          showToastMessage(undefined, response.data.message, "error", false);
        }
      } catch (error) {
        setIsLoading(false);
        props.history.push("/inventory-management/waste-sheet");
        showToastMessage(undefined, error.message, "error", false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (wasteSheetId && wasteSheetId > 0) {
        await wasteSheetDetailsHandler(wasteSheetId);
      }
    })();
  }, [wasteSheetId]);

  useEffect(async () => {
    if (updateData.isData) {
      let editData = updateData.data;
      // await rowMaterialListHandler(editData?.store_id);
      let updatedData = editData?.inventory_waste_sheet_ingredients?.map(
        (da) => {
          return {
            id: da?.id,
            inventory_product_id: da?.inventory_product_id,
            raw_matrial_type: da?.raw_matrial_type,
            raw_matrial_unit: da?.raw_matrial_unit,
            waste_quantity: da?.waste_quantity,
            raw_matrial_id:
              da?.inventory_product?.product_recipe_raw_material?.id,
            available_quantity: da?.inventory_product?.quantity,
            rawMaterial:
              da?.inventory_product.product_recipe_raw_material?.title,
          };
        }
      );
      setPrevData(updatedData);
      await setValues("id", editData?.id);
    } else {
      setPrevData([]);
    }
  }, [updateData.data]);

  const hanldeChangeRawMaterial = (key, value, position) => {
    if (key === "raw_matrial") {
      const selectedData = data.find(
        (da) => da?.product_recipe_raw_material?.id === value
      );
      const newData = {
        inventory_product_id: selectedData?.id,
        raw_matrial_unit:
          selectedData?.product_recipe_raw_material?.product_recipe_unit
            ?.title ?? "",
        raw_matrial_type:
          selectedData?.product_recipe_raw_material?.product_recipe_type
            ?.title ?? "",
        waste_quantity: 0,
        raw_matrial_id: selectedData?.product_recipe_raw_material?.id,
        available_quantity: selectedData?.quantity ?? 0,
      };

      formik.values.inventory_waste_sheet_ingredients[position] = newData;
      const newAddedWasteSheet = [
        ...formik.values.inventory_waste_sheet_ingredients,
      ];
      setValues("inventory_waste_sheet_ingredients", newAddedWasteSheet);
    } else if (key === "waste_quantity") {
      let prevData = formik.values.inventory_waste_sheet_ingredients;
      prevData[position].waste_quantity = value;
      const updatedData = [...formik.values.inventory_waste_sheet_ingredients];
      setValues("inventory_waste_sheet_ingredients", updatedData);
    }
  };

  //This function will handdle add new row
  const addNewRow = () => {
    if (viewWasteSheet) {
      return;
    }
    let data = formik.values.inventory_waste_sheet_ingredients;
    data.push(initialValue[0]);
    formik.setValues((value) => {
      return {
        ...value,
        inventory_waste_sheet_ingredients: [...data],
      };
    });
  };

  //delete waste sheet row run time from row list data
  const deleteRow = (values, action) => {
    if (viewWasteSheet) {
      return;
    }
    if (action === "newRow") {
      formik.setValues((value) => {
        return {
          ...value,
          inventory_waste_sheet_ingredients:
            value.inventory_waste_sheet_ingredients.filter((s) => s !== values),
        };
      });
    } else {
      const updatedData = prevData.filter((s) => s !== values);
      setPrevData(updatedData);
    }
  };

  //use to view datails only
  const viewWasteSheetDetails = async (id) => {
    setIsLoading(true);
    try {
      const response = await viewWasteSheetDetail(id);
      if (response && response.status === 200) {
        let viewData = response.data.data?.map((da) => {
          return {
            id: da?.id,
            inventory_product_id: da?.inventory_product_id,
            raw_matrial_type: da?.raw_matrial_type,
            raw_matrial_unit: da?.raw_matrial_unit,
            waste_quantity: da?.waste_quantity,
            raw_matrial_id:
              da?.inventory_product?.product_recipe_raw_material?.id,
            available_quantity: da?.inventory_product?.quantity,
            rawMaterial:
              da?.inventory_product.product_recipe_raw_material?.title,
          };
        });
        setPrevData(viewData);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        showToastMessage(undefined, response.data.message, "error", false);
      }
    } catch (error) {
      setIsLoading(false);
      showToastMessage(undefined, error.message, "error", false);
    }
  };

  //Final submit handler in both case add and update
  const onSubmitHandler = async (value) => {
    if (showErrors) {
      setShowConfirmation(false);
      showToastMessage(
        undefined,
        "Please add all waste sheet ingredients data.",
        "error",
        false
      );
      return;
    }
    let validationFlag = false;
    value?.inventory_waste_sheet_ingredients.forEach((val, idx) => {
      if (!val.inventory_product_id) {
        validationFlag = true;
      }
      if (!val.waste_quantity) {
        validationFlag = true;
      }
    });

    try {
      if (!updateData.isData) {
        if (validationFlag) {
          setShowConfirmation(false);
          showToastMessage(
            undefined,
            "Please add all ingredients data",
            "error",
            false
          );
          return;
        }
        setIsLoading(true);
        const response = await addNewWasteSheet(value);
        if (response && response.status === 200) {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "success", false);
        } else {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "error", false);
        }
      } else {
        let response;
        if(value?.inventory_waste_sheet_ingredients.length > 0) {
          if (validationFlag) {
            setShowConfirmation(false);
            if(value?.inventory_waste_sheet_ingredients.length > 1){
              showToastMessage(
                undefined,
                "Please add all ingredients data",
                "error",
                false
              );
              return;
            } else {
              setIsLoading(true);
              const postData = {
                id: value?.id,
                inventory_waste_sheet_ingredients: prevData,
              };
              response = await updateWasteSheet(value.id, postData);
            }            
          } else {
            setIsLoading(true);
            const newData = value?.inventory_waste_sheet_ingredients;
            const postData = {
              id: value?.id,
              inventory_waste_sheet_ingredients: prevData?.concat(newData),
            };
            response = await updateWasteSheet(value.id, postData);
          }
        } else {
          const postData = {
            id: value?.id,
            inventory_waste_sheet_ingredients: prevData,
          };
          response = await updateWasteSheet(value.id, postData);
        }
        if (response && response.status === 200) {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "success", false);
        } else {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "error", false);
        }
      }
      setShowConfirmation(false);
      props.history.push("/inventory-management/waste-sheet");
    } catch (error) {
      setIsLoading(false);
      setShowConfirmation(false);
      showToastMessage(undefined, error.message, "error", false);
    }
  };

  return (
    <>
      <Header
        title={
          updateData.isData
            ? "Update Waste Sheet"
            : viewWasteSheet
            ? "View Waste Sheet"
            : "Add New Waste"
        }
      />
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <div className="row">
            <table className="rt-table table border-bottom">
              <thead className="rt-thead -header">
                <tr className="rt-tr">
                  <th className="rt-th rt-resizable-header">Raw Material</th>
                  <th className="rt-th rt-resizable-header">Unit</th>
                  <th className="rt-th rt-resizable-header">Qty</th>
                  <th className="rt-th rt-resizable-header">Waste Qty</th>
                  {!viewWasteSheet && (
                    <th className="rt-th rt-resizable-header">Action</th>
                  )}
                </tr>
              </thead>
              <tbody className="rt-tbody">
                {!viewWasteSheet &&
                  formik.values.inventory_waste_sheet_ingredients.map(
                    (value, idx) => {
                      return (
                        <tr>
                          <td className="text-center col-md-4 col-xs-12 p-0 pl-2" style={{ width: "50%" }}>
                            <div className="">
                              <Select
                                placeholder={"Select raw material"}
                                parentClass={"text-left"}
                                options={rawMaterial}
                                value={value?.raw_matrial_id}
                                onChange={(data) =>
                                  hanldeChangeRawMaterial(
                                    "raw_matrial",
                                    data,
                                    idx
                                  )
                                }
                                key={value?.raw_matrial_id}
                                disableUnderline={false}
                                disabled={viewWasteSheet}
                              />
                            </div>
                          </td>
                          <td className="p-0 pl-2">
                            <Text
                              value={value.raw_matrial_unit}
                              required={true}
                              id={"matrial_unit"}
                              disabled={true}
                            />
                          </td>
                          <td className="p-0 pl-2">
                            <Text
                              value={value?.available_quantity}
                              required={true}
                              id={"available_quantity"}
                              disabled={true} 
                            />
                          </td>
                          <td className="p-0 pl-2">
                            <Text
                              value={value?.waste_quantity}
                              required={true}
                              id={"waste_quantity"}
                              onChange={(data) => {
                                let regex = /^\d*\.?\d*$/;
                                if (regex.test(data)) {
                                  hanldeChangeRawMaterial(
                                    "waste_quantity",
                                    data,
                                    idx
                                  );
                                }
                              }}
                              disabled={viewWasteSheet}
                            />
                          </td>
                          <td className="d-flex justify-content-start">
                            {idx === 0 ? (
                              <>
                                {formik.values.inventory_waste_sheet_ingredients.length && formik.values.inventory_waste_sheet_ingredients.length > 1 && (
                                  <div onClick={() => deleteRow(value, "newRow")}>
                                    <span>
                                      <img
                                        className={`cursor-pointer ${styles.icon}`}
                                        src={DeleteIcon}
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                )}
                                <div onClick={addNewRow}>
                                  <span
                                    className={`link cursor-pointer ${styles.iconWrapper} ml-3`}
                                  >
                                    <img
                                      className={styles.icon}
                                      src={AddIcon}
                                      alt=""
                                    />
                                  </span>
                                </div>
                              </>
                            ) : (
                              <div onClick={() => deleteRow(value, "newRow")}>
                                <span>
                                  <img
                                    className={`cursor-pointer ${styles.icon}`}
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </span>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
              <tbody className="rt-tbody">
                {updateData.isData || viewWasteSheet
                  ? prevData?.map((value, idx) => {
                      return (
                        <tr style={{ backgroundColor: "#fbfbfb" }}>
                          <td className="text-center col-md-4 col-xs-12 p-0 pl-2" style={{ width: "50%" }}>
                            <Text
                              value={value?.rawMaterial}
                              required={true}
                              id={"available_quantity"}
                              disabled={
                                viewWasteSheet
                                  ? viewWasteSheet
                                  : updateData.isData
                              }
                            />
                          </td>
                          <td className="p-0 pl-2">
                            <Text
                              value={value.raw_matrial_unit}
                              required={true}
                              id={"matrial_unit"}
                              disabled={
                                viewWasteSheet
                                  ? viewWasteSheet
                                  : updateData.isData
                              }
                            />
                          </td>
                          <td className="p-0 pl-2">
                            <Text
                              value={value?.available_quantity}
                              required={true}
                              id={"available_quantity"}
                              disabled={
                                viewWasteSheet
                                  ? viewWasteSheet
                                  : updateData.isData
                              }
                            />
                          </td>
                          <td
                            className={viewWasteSheet ? "p-0 pl-4" : "p-0 pl-2"}
                          >
                            <Text
                              value={value?.waste_quantity}
                              required={true}
                              id={"waste_quantity"}
                              disabled={
                                viewWasteSheet
                                  ? viewWasteSheet
                                  : updateData.isData
                              }
                            />
                          </td>
                          <td className="d-flex justify-content-start">
                            {!viewWasteSheet && (
                              <div onClick={() => deleteRow(value, "oldRow")}>
                                <span>
                                  <img
                                    className={`cursor-pointer ${styles.icon}`}
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </span>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
          </div>
          <div class={`text-center`}>
            {!viewWasteSheet && (
              <button
                type="button"
                className="btn gray-btn d-inline-block mb-3 mr-2"
                onClick={() => setShowConfirmation(true)}
              >
                {"Submit"}
              </button>
            )}
          </div>
        </form>
      </div>
      <Confirmation
        showModal={showConfirmation}
        title={updateData.isData ? "Update Waste Sheet" : "Add New Waste"}
        message={`Are you sure you want to submit?`}
        btn1_title="Submit"
        btn2_title="Cancel"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowConfirmation(false);
        }}
        handleSubmitClick={formik.handleSubmit}
      />
    </>
  );
};

export default withRouter(AddEditWasteSheet);
