import { callGetApiv2, callPostApi, callPostApiv2, callPutApiv2 } from "./api";


export async function getCustomerList(data) {
  return callPostApiv2("customers/customer-lookup", data);
}

export async function getCustomerWithOrderDetails(data) {
  return callPostApiv2("customers/customer-with-order-details", data);
}

export async function updateCustomer(data) {
  return callPostApiv2("customers/edit", data);
}

export async function getCustomerPetalRewardsList(data) {
  return callPostApiv2("customers/customer-petal-rewards-lookup", data);
}


export async function getCustomerDefaultBillingAddressBook(data) {
  return callPostApiv2("customers/default-billing-address-book", data);
}
export async function getCustomerdata(data) {
  return callPostApiv2("customers/customer-with-order-details", data);
}
export async function getCustomerRecipients(data) {
  return callPostApiv2("customers/customer-address-book", data);
}
export async function getCustomerCard(data) {
  return callPostApiv2("customers/card-admin", data);
}
export async function addCardTocustomer(data) {
  return callPostApiv2("customers/card/addcardbyadmin", data);
}
export async function updateCustomerPointBalance(data) {
  try {
    const response = await callPostApi({ url: "customers/update_point_balance", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function customerLookupExport(){
  return callGetApiv2("customers/customer-lookup/export");
}
export async function createCustomer(data){
  try {
    const response = await callPostApi({ url: "customers/add_customer_by_admin", body: data });
    return response;
  }catch (error) {
    throw error;
  }
}

