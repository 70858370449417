export const DeliveryMatrixAction = {
  SET_DELIVERY_MATRIX_NAME: "SET_DELIVERY_MATRIX_NAME",
  SET_DELIVERY_MATRIX_ZIPCODE: "SET_DELIVERY_MATRIX_ZIPCODE",
  SET_DELIVERY_MATRIX_ADDRESS_TYPE: "SET_DELIVERY_MATRIX_ADDRESS_TYPE",
  SET_DELIVERY_MATRIX_DAYS: "SET_DELIVERY_MATRIX_DAYS",
  SET_DELIVERY_MATRIX_START_DATE: "SET_DELIVERY_MATRIX_START_DATE",
  SET_DELIVERY_MATRIX_END_DATE: "SET_DELIVERY_MATRIX_END_DATE",
  SET_DELIVERY_TIME_DATA: "SET_DELIVERY_TIME_DATA",
  DELETE_DELIVERY_TIME_DATA: "DELETE_DELIVERY_TIME_DATA",
  EDIT_DELIVERY_TIME_DATA: "EDIT_DELIVERY_TIME_DATA",
  CLEAR_DELIVERY_MATRIX_DATA: "CLEAR_DELIVERY_MATRIX_DATA",
  DELETE_ALL_DELIVERY_TIME_DATA: "DELETE_ALL_DELIVERY_TIME_DATA",
};
