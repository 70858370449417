import React, { useReducer } from "react";
import CategoryReducer from "./categoryReducer";
import { categoryState } from "./categoryState";

export const CategoryContext = React.createContext({
  categoryState: categoryState,
  dispatch: () => null,
});

const CategoryProviderCurd = (props) => {
  const [state, dispatch] = useReducer(CategoryReducer, categoryState);
  return (
    <CategoryContext.Provider value={{ categoryState: state, dispatch }}>
      {props.children}
    </CategoryContext.Provider>
  );
};

export default CategoryProviderCurd;
