import Interceptors, { NewInterceptors } from "../Interceptors/Interceptors";

class Products {
  constructor() {
    this.interceptors = new Interceptors();
    this.notTokenInterception = new NewInterceptors();
  }

  async getProducts(data) {
    return this.notTokenInterception.POST("products", data);
  }

  async getProductsNormal() {
    return this.interceptors.POST("products/normal-list");
  }

  async getProductsWithSequence(data) {
    return this.interceptors.POST("products/product-sequence", data);
  }

  async setProductsWithSequence(data) {
    return this.interceptors.POST("products/set-product-sequence", data);
  }

  async getProductDetail(productId) {
    return this.interceptors.GET("products/" + productId);
  }

  async getMenus() {
    return this.interceptors.GET("menus?menuFor=admin");
  }

  async createProduct(postData) {
    return this.interceptors.POST("products/add", postData);
  }

  async deleteProduct(id) {
    return this.interceptors.DELETE("products/" + id);
  }

  async updateProduct(id, postData) {
    return this.interceptors.PUT("products/" + id, postData);
  }

  async getSimilarProductDetail(productId) {
    return this.interceptors.GET("products/similar/" + productId);
  }
  async updatesequnceProduct(post_data) {
    return this.interceptors.POST("products/update-sequence", post_data);
  }
}

export default Products;
