import React, { useReducer } from "react";
import { ProductInitialState } from "./ProductInitialState";
import { productReducer } from "./ProductReducer";

export const ProductContext = React.createContext({
  productState: ProductInitialState,
  dispatch: () => null,
});

const ProductProvider = (props) => {
  const [state, dispatch] = useReducer(productReducer, ProductInitialState);

  // useEffect(() => {
  //     dispatch({ type: PRODUCT_ACTIONS.SET_PRODUCT_LIST, payload: ProductList });
  // }, []);

  return (
    <ProductContext.Provider value={{ productState: state, dispatch }}>
      {props.children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
