import { callDeleteApi, callGetApi } from "./api";

export async function list() {
      // return this.interceptors.GET('notification');
      try {
            const response = await callGetApi({ url: "notification" });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function deleteNotification(id) {
      // return this.interceptors.DELETE('notification/delete/' + id);
      try {
            const response = await callDeleteApi({ url: "notification/delete/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getCount() {
      // return this.interceptors.GET('notification/count');
      try {
            const response = await callGetApi({ url: "notification/count" });
            return response;
      } catch (error) {
            throw error;
      }
}
