import { useContext } from "react";
import { CustomerContext } from "./customerContext";
import * as CustomerActions from "./customerActions";

export function useCustomer() {
  const { customerState, dispatch } = useContext(CustomerContext);

  const setPaymentMethodNonce = (values) => {
    dispatch({
      type: CustomerActions.customerActions.CREATE_PAYMENT_NONCE,
      payload: values,
    });
  };

  return {
    customerState,
    setPaymentMethodNonce,
  };
}
