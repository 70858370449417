import { ProductActions } from "./productAction";

const clrStateProduct = {
  activeProductVariantIndex: 0,
  product_primary_info: {
    type: null,
    inventory_remaining: 0,
    title: "",
    sequence_number: 0,
    status: "",
    sku_id: "",
    keywords: "",
    customer_note: "",
    lead_time: "",
    non_local_lead_time: "",
    product_images: "",
    description: "",
    care_description: "",
    declaimer: "",
    did_you_know: "",
    after_care: "",
    delivery_info: "",
  },
  productPrimaryError: false,
  productNavigationError: false,
  product_navigation_info: {
    slug: "",
    blurb: "",
  },
  productVariationError: false,
  product_variations: [
    {
      title: "",
      sku: "",
      commission: "",
      price: "",
      sale_price: "",
      inventory: "",
      image: "",
      status: "",
      is_default_selected: "",
      is_sold_out: "",
      placeholder_background: "",
      placeholder_text: "",
      checklist: "",
    },
  ],
  product_flag_info_value: {
    is_eligible_for_commission: "",
    is_limited_availability: "",
    is_sold_out: "",
    is_exclude_from_search: "",
    temporary_unavailable: "",
  },
  is_local_only: "",
  seo: {
    is_robot: false,
    is_no_follow: false,
    is_no_index: false,
    image_alt_text: "",
    page_title: "",
    meta_title: "",
    meta_description: "",
    canonical_url: "",
    og_title: "",
    og_description: "",
    og_image_url: "",
    og_url: "",
    og_image_alt: " ",
    og_image_type: "",
    og_image_width: 0,
    og_image_height: 0,
    og_site_name: "",
    twitter_title: "",
    twitter_description: "",
    twitter_image_url: "",
    twitter_site: "",
    twitter_creator: "",
  },
  product_notes: [
    {
      date: "",
      user_id: "",
      note: "",
      user_name: "",
    },
  ],
  activeProductNoteIndex: 0,
  setProductAllData: "",
  similar_products: [],
  activeIndexSimilarProduct: 0,
  product_images: [],
  setEditVariantProductIndex: "",
  banners: [],
};

const newRec = {
  id: 0,
  module: "products",
  position: "LEFT",
  banner_text: "",
  button_label: "",
  button_url: "",
  description: "",
  image: "",
  is_supported_image: 1,
  show_oval: 0,
};

const clrStateProductVariant = {
  product_variations: {}
}

function ProductReducer(state, action) {
  switch (action.type) {
    case ProductActions.SET_ACTIVE_INDEX:
      return {
        ...state,
        activeProductVariantIndex: action.payload,
      };
    case ProductActions.SET_PRIMARY_INFO:
      let primaryDataSet = { ...state.product_primary_info, ...action.payload };

      return {
        ...state,
        product_primary_info: primaryDataSet,
      };

    case ProductActions.CHECK_PRIMARY_INFO:
      return {
        ...state,
        productPrimaryError: action.payload,
      };

    case ProductActions.SET_NAVIGATION_INFO:
      let navigationDataSet = {
        ...state.product_navigation_info,
        ...action.payload,
      };

      return {
        ...state,
        product_navigation_info: navigationDataSet,
      };

    case ProductActions.CHECK_NAVIGATION_INFO:
      return {
        ...state,
        productNavigationError: action.payload,
      };

    case ProductActions.SET_VARIANT_INFO:
      let variantDataSet = state.product_variations
        ? [...state.product_variations]
        : [];
      if (state.setEditVariantProductIndex === "") {
        variantDataSet[state.activeProductVariantIndex] = {
          ...variantDataSet[state.activeProductVariantIndex],
          ...action.payload,
        };
        // variantDataSet.push(action.payload);

      } else {
        variantDataSet[state.setEditVariantProductIndex] = {
          ...action.payload,
        };
      }
      return {
        ...state,
        product_variations: variantDataSet,
      };

    case ProductActions.CHECK_VARIANT_INFO:
      return {
        ...state,
        productVariationError: action.payload,
      };

    case ProductActions.DELETE_PRODUCT_VARIANT:
      let productVariant = [...state.product_variations];
      productVariant.splice(action.payload, 1);
      return {
        ...state,
        product_variations: productVariant,
      };

    case ProductActions.SET_PRODUCT_FLAG_INFO:
      let flagDataSet = { ...state.product_flag_info_value, ...action.payload };

      return {
        ...state,
        product_flag_info_value: flagDataSet,
      };

    case ProductActions.SET_IS_LOCAL_VALUE:
      return {
        ...state,
        is_local_only: action.payload,
      };

    case ProductActions.SET_SEO_DATA:
      let seoDataSet = { ...state.seo, ...action.payload };

      return {
        ...state,
        seo: seoDataSet,
      };

    case ProductActions.SET_PRODUCT_NOTE_INFO:
      let noteDataSet = state.product_notes ? [...state.product_notes] : [];
      noteDataSet[state.activeProductNoteIndex] = {
        ...noteDataSet[state.activeProductNoteIndex],
        ...action.payload,
      };
      return {
        ...state,
        product_notes: noteDataSet,
      };

    case ProductActions.DELETE_PRODUCT_NOTE:
      let productNote = [...state.product_notes];
      productNote.splice(action.payload, 1);
      return {
        ...state,
        product_notes: productNote,
      };

    case ProductActions.SET_ACTIVE_INDEX_NOTE:
      return {
        ...state,
        activeProductNoteIndex: action.payload,
      };

    case ProductActions.CLR_DATA_PRODUCT:
      return clrStateProduct;

    case ProductActions.SET_ALL_PRODUCT_DATA:
      return {
        ...state,
        setProductAllData: action.payload,
      };
    case ProductActions.CHECK_SEO_DATA:
      return {
        ...state,
        seoError: action.payload,
      };
    case ProductActions.UPDATE_OG_INFO_DATA:
      let ogInfoData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: ogInfoData,
      };
    case ProductActions.CHECK_OG_INFO_DATA:
      return {
        ...state,
        ogInfoError: action.payload,
      };
    case ProductActions.UPDATE_TWITTER_INFO_DATA:
      let twitterInfoData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: twitterInfoData,
      };
    case ProductActions.CHECK_TWITTER_INFO_DATA:
      return {
        ...state,
        twitterInfoError: action.payload,
      };
    case ProductActions.UPDATE_SCHEMA_DATA:
      let schemaData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: schemaData,
      };
    case ProductActions.CHECK_SCHEMA_DATA:
      return {
        ...state,
        schemaError: action.payload,
      };

    case ProductActions.SET_SIMILAR_PRODUCT_INDEX:
      return {
        ...state,
        activeIndexSimilarProduct: action.payload,
      };

    case ProductActions.SET_SIMILAR_PRODUCT:
      let similarProductDataSet = state.similar_products
        ? [...state.similar_products]
        : [];
      similarProductDataSet[state.activeIndexSimilarProduct] = {
        ...similarProductDataSet[state.activeIndexSimilarProduct],
        ...action.payload,
      };
      return {
        ...state,
        similar_products: similarProductDataSet,
      };

    case ProductActions.DELETE_SIMILAR_PRODUCT:
      let similarProduct = [...state.similar_products];
      similarProduct.splice(action.payload, 1);
      return {
        ...state,
        similar_products: similarProduct,
      };

    case ProductActions.SET_PRODUCT_IMAGE_DATA:
      let imageDataSet = state.product_images ? [...state.product_images] : [];
      imageDataSet.push(action.payload);
      return {
        ...state,
        product_images: imageDataSet,
      };

    case ProductActions.UPDATE_PRODUCT_IMAGE_DATA:
      let imageDataSetUpdate = action.payload ? [...action.payload] : [];
      // imageDataSet.push(action.payload);
      return {
        ...state,
        product_images: imageDataSetUpdate,
      };

    case ProductActions.SET_EDIT_VARIANT_PRODUCT_INDEX:
      return {
        ...state,
        setEditVariantProductIndex: action.payload,
      };

    case ProductActions.SET_USER_DATA_INFO:
      let userDataSet = state.user_data ? [...state.user_data] : [];
      userDataSet.push(action.payload);
      return {
        ...state,
        user_data: userDataSet,
      };

    case ProductActions.SET_PAGE_FOR_VIEW:
      return {
        ...state,
        setPageForView: action.payload,
      };

    case ProductActions.SET_CATEGORY_HIERARCHY_DATA:
      let categoryDataSet = state.product_categories
        ? [...state.product_categories]
        : [];
      categoryDataSet.push(action.payload);
      return {
        ...state,
        product_categories: categoryDataSet,
      };

    case ProductActions.DELETE_CATEGORY_HIERARCHY_DATA:
      let categoryData = [...state.product_categories];
      categoryData.splice(action.payload, 1);
      return {
        ...state,
        product_categories: categoryData,
      };

    case ProductActions.DELETE_PRODUCT_IMAGE_DATA:
      let productImageData = [...state.product_images];
      productImageData.splice(action.payload, 1);
      return {
        ...state,
        product_images: productImageData,
      };

    case ProductActions.SET_DELETE_PRODUCT_IMAGE_DATA:
      let productImageDataDelete = state.delete_product_image
        ? [...state.delete_product_image]
        : [];

      let productImageDataNew = [...state.product_images];

      productImageDataDelete.push(productImageDataNew[action.payload]);
      return {
        ...state,
        delete_product_image: productImageDataDelete,
      };

    case ProductActions.ADD_NEW_BANNER:
      let aBanner = state.banners ? [...state.banners] : [];
      aBanner.push(newRec);
      return {
        ...state,
        banners: aBanner,
      };

    case ProductActions.REMOVE_BANNER:
      let rBanner = [...state.banners];
      rBanner.splice(action.payload, 1);
      return {
        ...state,
        banners: rBanner,
      };

    case ProductActions.UPDATE_BANNER_DATA:
      let bannersData = [...state.banners];
      bannersData[action.payload.index] = {
        ...action.payload.values,
      };
      return {
        ...state,
        banners: bannersData,
      };

    case ProductActions.CHECK_BANNER_DATA:
      return {
        ...state,
        bannerError: action.payload,
      };

    case ProductActions.SET_PRODUCT_BANNER_DATA:
      let bannerDataSet = state.banners ? [...state.banners] : [];
      bannerDataSet.push(action.payload);
      return {
        ...state,
        banners: bannerDataSet,
      };

    case ProductActions.ADD_SIMILAR_PRODUCT_DATA:
      let similarProductData = [...action.payload];

      return {
        ...state,
        similar_products: similarProductData,
      };

    case ProductActions.DELETE_SIMILAR_PRODUCT_DATA:
      let DeletedSimilarProduct = [...action.payload];
      return {
        ...state,
        similar_products: DeletedSimilarProduct,
      };

    case ProductActions.CHECK_PRODUCT_NOTE_DATA:
      return {
        ...state,
        productNoteError: action.payload,
      };

    case ProductActions.SET_SIMILAR_PRODUCTPRODUCT_ARRAY:
      let selectedArraySet = [...action.payload];
      return {
        ...state,
        similar_products: selectedArraySet,
      };

    case ProductActions.CLR_PRODUCT_VARIANT_DATA:
      return clrStateProductVariant;

    default:
      return state;
  }
}

export default ProductReducer;
