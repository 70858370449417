import React, { useState, useEffect, useContext } from "react";

// Scss
import styles from "./index.module.scss";

// Context
import { AppContext } from "./../../../Context/AppContext";
import { getAccessByModuleName } from "./../../../Routes/Routes";
import { MODULES, STATUS_FILTER } from "./../../../Helpers/Constants";

// Components
import Header from "./../../../Components/Header/Header";
import ReactTable from "../../../Components/Table/Table";
import DeleteConfirmation from "./../../../Components/Modal/DeleteConfirmation/DeleteConfirmation";
import BlogCategoryModal from "../../../Components/Modal/Blogs/AddBlogCategory";
import StatusConfirmation from "../../../Components/Modal/StatusConfirmation/StatusConfirmation";

// Services
// import BlogsApi from "../../../Services/Blogs/Blogs";
// import UtilsApi from "../../../Services/Utils/Utils";
import { updateStatus } from "../../../api/utils";
import {
  getBlogCategoryList,
  getBlogCategoryAdd,
  getBlogCategoryUpdate,
  deleteBlogCategory,
} from "../../../api/blogs";

// Images
import EditIcon from "./../../../Assets/images/edit.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";
import ActivatedIcon from "./../../../Assets/images/activated.svg";

const BlogCategory = (props) => {
  // const blogsService = new BlogsApi();
  // const utilsService = new UtilsApi();

  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);

  const [access, setAccess] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [defaultFilter, setDefaultFilter] = useState({
    currentPage: 0,
    itemsPerPage: 10,
    sortBy: [],
    filters: [],
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const [defaultNameCategory, setDefaultNameCategory] = useState("");
  const [defaultSlugCategory, setDefaultSlugCategory] = useState("");
  const [defaultSeoData, setDefaultSeoData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteBlogCategoryId, setDeleteBlogCategoryId] = useState();
  const [statusBlogCategoryId, setStatusBlogCategoryId] = useState(false);
  const [statusOfBlog, setStatusOfBlog] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [submitStatus, setSubmitStatus] = useState(false);
  const statusList = STATUS_FILTER;

  let seoData = {
    id: null,
    module: "blogs_category",
    is_robot: false,
    is_no_follow: false,
    is_no_index: false,
    image_alt_text: "",
    page_title: "",
    meta_title: "",
    meta_description: "",
    canonical_url: "",
    og_title: "",
    og_description: "",
    og_image_url: "",
    og_url: "",
    og_image_alt: " ",
    og_image_type: "",
    og_image_width: 0,
    og_image_height: 0,
    og_site_name: "",
    twitter_title: "",
    twitter_description: "",
    twitter_image_url: "",
    twitter_site: "",
    twitter_creator: "",
  };

  useEffect(getModuleAccess, [modules]);
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.BLOGS_CATEGORY);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/users");
    }
  }

  const handleManageBlogCategoryClick = async (event, id, data) => {
    event.preventDefault();
    setUpdateId("");
    setDefaultNameCategory("");
    setDefaultSlugCategory("");
    setDefaultSeoData([]);
    if (id) {
      setUpdateId(id);
      setDefaultNameCategory(data.title);
      setDefaultSlugCategory(data.slug);
      if (data.seo !== null && data.seo !== undefined) {
        setDefaultSeoData(data.seo);
      } else {
        setDefaultSeoData(seoData);
      }
    }
    setShowAddModal(true);
  };

  const handelDeleteClick = async (event, id) => {
    event.preventDefault();

    setDeleteBlogCategoryId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteBlog = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // const response = await blogsService.deleteBlogCategory(
    //   deleteBlogCategoryId
    // );
    const response = await deleteBlogCategory(deleteBlogCategoryId);
    if (response && response.status === 200) {
      setDeleteBlogCategoryId(null);
      setShowDeleteModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      setShowDeleteModal(false);
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  const handleStatusBlogClick = async (event, id, status) => {
    event.preventDefault();

    setStatusBlogCategoryId(id);
    setStatusOfBlog(status);
    setShowStatusModal(true);
  };

  const handleBlogStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfBlog === 1 ? 0 : 1,
      id: statusBlogCategoryId,
      table: "cms_blog_categories",
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setStatusBlogCategoryId(null);
      setStatusOfBlog(null);
      setShowStatusModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  const handleManageBlogCategoryAddClick = async (
    categoryName,
    categorySlug,
    seoData
  ) => {
    let blogCategoryApiCall = true;
    let validationMessage = "Please add required data";
    if (
      categoryName === "" ||
      categoryName === undefined ||
      categoryName === null ||
      categoryName?.trim() === ""
    ) {
      blogCategoryApiCall = false;
      validationMessage = "Please enter title";
    } else if (
      categorySlug === "" ||
      categorySlug === undefined ||
      categorySlug === null ||
      categorySlug?.trim() === ""
    ) {
      blogCategoryApiCall = false;
      validationMessage = "Please enter slug";
    }

    seoData.module = "blogs_category";

    if (blogCategoryApiCall) {
      let response;
      if (updateId !== "" && updateId !== undefined) {
        seoData.parent_id = updateId;
        let postData = {
          title: categoryName,
          slug: categorySlug,
          id: updateId,
          seo: seoData,
        };
        // response = await blogsService.getBlogCategoryUpdate(postData);
        response = await getBlogCategoryUpdate(postData);
      } else {
        let postData = {
          title: categoryName,
          slug: categorySlug,
          seo: seoData,
        };
        // response = await blogsService.getBlogCategoryAdd(postData);
        response = await getBlogCategoryAdd(postData);
      }

      if (response && response.status === 200) {
        showToastMessage(undefined, response.data.message, "success", false);
        fetchData(defaultFilter);
        setDefaultNameCategory("");
        setDefaultSlugCategory("");
        setDefaultSeoData([]);
        setShowAddModal(false);
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
        fetchData(defaultFilter);
      }
      setLoading(false);
    } else {
      showToastMessage(undefined, validationMessage, "error", false);
    }
  };

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
    };
    // const response = await blogsService.getBlogCategoryList(postData);
    const response = await getBlogCategoryList(postData);

    if (response && response.status === 200) {
      setData(response.data.data.rows);
      setPageCount(Math.ceil(response.data.data.count / pageSize));
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);
  };

  let blogsCategoryAccess = getAccessByModuleName(
    modules,
    MODULES.BLOGS_CATEGORY
  );
  const columns = React.useMemo(
    () => [
      {
        Header: "Category Name",
        accessor: "title",
        isVisible: true,
        placeholder: "Search by Category Name",
      },
      {
        Header: "Category Slug",
        accessor: "slug",
        isVisible: true,
        placeholder: "Search by Category Slug",
      },
      {
        Header: "Status",
        accessor: "status",
        isVisible: true,
        placeholder: "Status",
        isSelectFilter: true,
        selectFilterOption: statusList,
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.status}>
              {row.original.status === 1 ? (
                <div className={styles.Active}>Active</div>
              ) : (
                <div className={styles.Inactive}>Inactive </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {blogsCategoryAccess &&
                blogsCategoryAccess.permissions.write_access && (
                  <img
                    className={`cursor-pointer ml-3`}
                    src={ActivatedIcon}
                    alt="Active"
                    onClick={(e) =>
                      handleStatusBlogClick(e, value, row.original.status)
                    }
                  />
                )}

              {blogsCategoryAccess &&
                blogsCategoryAccess.permissions.delete_access && (
                  <img
                    className={`cursor-pointer ml-3`}
                    src={DeleteIcon}
                    alt="Delete"
                    onClick={(e) => handelDeleteClick(e, value)}
                  />
                )}

              {blogsCategoryAccess &&
                blogsCategoryAccess.permissions.write_access && (
                  <img
                    className={`cursor-pointer ml-3`}
                    src={EditIcon}
                    alt="Edit"
                    onClick={(e) =>
                      handleManageBlogCategoryClick(e, value, row.original)
                    }
                  />
                )}
            </div>
          );
        },
      },
    ],
    [data]
  );

  return (
    <React.Fragment>
      <Header
        title="Blog Category"
        hasAddButton={access.write_access}
        handleAddClick={handleManageBlogCategoryClick}
      />
      <div id="MainContent" className="main-content" />
      <div>
        <ReactTable
          columns={columns}
          data={data}
          expandedRows={true}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
        />
      </div>

      {showAddModal && (
        <BlogCategoryModal
          showModal={showAddModal}
          pageTitle="Add Blogs Category"
          defaultValue={defaultNameCategory}
          defaultValueSlug={defaultSlugCategory}
          defaultSeoData={defaultSeoData}
          handleCancelClick={(e) => {
            setShowAddModal(false);
          }}
          submitStatus={submitStatus}
          handleSubmitClick={handleManageBlogCategoryAddClick}
        />
      )}
      <DeleteConfirmation
        showModal={showDeleteModal}
        title="Delete Blog Category"
        message="Are you sure want to delete this blog category?"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteModal(false);
          setDeleteBlogCategoryId(null);
        }}
        handleSubmitClick={handleDeleteBlog}
      />

      <StatusConfirmation
        showModal={showStatusModal}
        title={"Blog Category"}
        message={"blog category?"}
        buttonLabel={statusOfBlog ? "Inactivate" : "Activate"}
        statusFlag={statusOfBlog}
        customFlag={false}
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowStatusModal(false);
          setStatusBlogCategoryId(null);
          setStatusOfBlog(null);
        }}
        handleSubmitClick={handleBlogStatus}
      />
    </React.Fragment>
  );
};

export default BlogCategory;
