import { callPostApi} from "./api";
export async function getKeywordList(data) {
  try {
    const response = await callPostApi({ url: "most-keyword-search", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getKeywordExportList(data) {
  try {
    const response = await callPostApi({ url: "most-keyword-search/exportList", body: data});
    return response;
  } catch (error) {
    throw error;
  }
}