import { useFormik } from "formik";

export function FeaturedProductForm(
  submit, 
  initialVal, 
  cb,
  checkFeaturedProductData
) {
  const validation = (values) => {
    // console.log('FeaturedProductForm validation');
    let errors = {};
    let featuredProductError = false;
    // if (!values.name) {
    //   errors.name = "Name required";
    //   featuredProductError = true;
    // }

    // if (!values.sequence_number) {
    //   errors.sequence_number = "Sequence number required";
    //   featuredProductError = true;
    // }

    // console.log('featuredProductError ===>', errors, featuredProductError);
    checkFeaturedProductData(featuredProductError);
    if (cb) {
      cb(values);
    }
    return errors;
  };

  const initialValues = {
    id: 0,
    parent_id: 0,
    module: 'home-page',
    name: "",
    sequence_number: 1,
    products: [],
  };

  const formik = useFormik({
    initialValues: initialVal || initialValues,
    onSubmit: submit,
    validate: validation,
    enableReinitialize: true,
  });
  return { formik };
}
