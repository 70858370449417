import { Dialog, makeStyles } from "@material-ui/core";
import card from "Assets/images/card.jpg";
import React, { useState } from "react";

const useStyles = makeStyles({
  card: {
    "& textarea": {
      textAlign: "center",
    },
  },
});

const GreetingCard = ({ value, signature, video_url }) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  return (
    <div className="my-5">
      {video_url && (
        <a
          className="d-inline-block mb-3"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setShow(true);
          }}
        >
          {"Show video message"}
        </a>
      )}
      <Dialog open={show} onClick={() => setShow(false)}>
        <video
          controls
          // src={}
        ></video>
      </Dialog>
      <div
        className={classes.card}
        style={{ height: 400, position: "relative", width: "max-content" }}
      >
        <img style={{ height: "100%" }} src={card} alt="Card" />
        <div
          style={{
            position: "absolute",
            top: "30%",
            overflowWrap: "break-word",
            height: "230px",
            width: "48%",
            textAlign: "center",
            left: "51.5%",
            transform: "translateX(-50%)",
          }}
        >
          {value}
        </div>
        <span
          style={{
            fontWeight: "bold",
            position: "absolute",
            bottom: "17%",
            height: "50px",
            padding: "0 1rem",
            display: "inline-block",
            textAlign: "center",
            whiteSpace: "pre-line",
            wordBreak: "break-all",
            width: "48%",
            lineHeight: 1,
            left: "51.5%",
            transform: "translateX(-50%)",
          }}
        >
          {signature}
        </span>
      </div>
    </div>
  );
};

export default GreetingCard;
