import React from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// SCSS
import styles from "./Add.module.scss";

// Component
import Text from "./../../../FormControl/Text/Text";
import Select from "./../../../FormControl/Select/Select";
import { formatUsPhone } from "./../../../../Hooks/commanUtility";
import { DEFAULT_COUNTRY_CODE_NAME, USER_TYPES } from "Helpers/Constants";
import MultiSelect from 'react-select';
import PhoneNumber from "Components/FormControl/PhoneNumber/PhoneNumber";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddChangeRequest = ({
  showModal,
  pageTitle,
  submitStatus,
  rolesList,
  username,
  firstName,
  lastName,
  password,
  email,
  role,
  mobile,
  countryCode,
  active,
  userType,
  productivity_low,
  productivity_high,
  onChange,
  handleCancelClick,
  handleSubmitClick,
  storelist,
  userAssignStore,
}) => {

  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <Text
                  type="text"
                  label="First Name"
                  value={firstName}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("firstName", data)}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <Text
                  type="text"
                  label="Last Name"
                  value={lastName}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("lastName", data)}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <Text
                  type="text"
                  label="Username"
                  value={username}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("username", data)}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <Text
                  type="password"
                  label="Password"
                  value={password}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("password", data)}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                {/* <Text
                  type="tel"
                  label="Mobile"
                  value={formatUsPhone(mobile)}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => {
                    if (data.length <= 14) {
                      onChange("mobile", data);
                    }
                  }}
                  // onChange={(data) => onChange('mobile', data)}
                /> */}
               
                <PhoneNumber
                  countryCode={DEFAULT_COUNTRY_CODE_NAME}
                  specialLabel={"Mobile"}
                  initValue={countryCode + mobile}
                  fieldName={`Mobile`}
                  onChange={(phone, countryData, event, formatedValue) => {
                    let countryCode = "+" + countryData.dialCode;
                    let finalMobileNum = formatedValue
                      ? formatedValue?.split(countryCode)[1]?.trim()
                      : "";
                    onChange("countyCode", countryCode);
                    onChange("mobile", finalMobileNum);
                  }}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <Text
                  type="email"
                  label="Email"
                  value={email}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("email", data)}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <Select
                  label="Role"
                  parentClass="text-left"
                  value={role}
                  required={false}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("role", data)}
                  options={rolesList}
                  disabled={false}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <Select
                  label="Status"
                  parentClass="text-left"
                  value={active}
                  required={false}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("active", data)}
                  options={[
                    { id: 0, title: "In Active" },
                    { id: 1, title: "Active" },
                  ]}
                  disabled={false}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="row">
                  <div className="col-sm-6 col-xs-12">
                    <Text
                      type="number"
                      label="Productivity Low"
                      value={productivity_low}
                      required={true}
                      submitStatus={submitStatus}
                      onChange={(data) => onChange("productivity_low", data)}
                    />
                  </div>
                  <div className="col-sm-6 col-xs-12">
                    <Text
                      type="number"
                      label="Productivity High"
                      value={productivity_high}
                      required={true}
                      submitStatus={submitStatus}
                      onChange={(data) => onChange("productivity_high", data)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <Select
                  label="User Type"
                  parentClass="text-left"
                  value={userType}
                  required={false}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("userType", data)}
                  options={USER_TYPES}
                  disabled={false}
                />
              </div>

              <div className="col-md-6 col-xs-12 mb-4">
                <div className={`form-group bmd-form-group is-filled text-left`} >
                  <label className={`bmd-label-floating ${styles.selectLabel}`}> Select Store</label>
                  <MultiSelect
                      options={storelist.map((elem)=>{return {value:elem.id,label:elem.title}})}
                      onChange={(data)=>onChange("storelist",userType==="designer"?[data].map((obj) => {return obj.value }):data.map((obj) => {return obj.value }))}
                      isMulti={userType==="designer" ? false:true}
                      defaultValue={userAssignStore.map((elem)=>{return {value:elem.id,label:elem.title}})}
                      placeholder="Select Store"
                      label="Select Store"
                      styles={{ control: (styles, state) => ({
                        ...styles,
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        borderBottomWidth: state.isFocused ? 2 : 1,
                        borderRadius: 0,
                        background: "transparent",
                        boxShadow: "none",
                        fontSize: 20,
                      }),
                      singleValue: (styles) => ({ ...styles, color: "#212721" }),
                      valueContainer: (styles) => ({
                        ...styles,
                        paddingLeft: 0,
                        color: "#212721",
                      }
                      )
                    }}
                      isClearable={false}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#CB998D",
                          primary25: "rgba(203,153,141,.15)",
                        }
                      })}
                    />
                </div>
              </div>

            </div>
            <a
              href="/"
              data-dismiss="modal"
              onClick={handleSubmitClick}
              className="btn gray-btn d-inline-block mb-3 mr-2"
            >
              {" "}
              Save{" "}
            </a>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default AddChangeRequest;
