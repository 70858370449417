import React, { useReducer } from "react";
import homePageReducer from "./homePageReducer";
import { homePageState } from "./homePageState";

export const HomePageContext = React.createContext({
  homePageState: homePageState,
  dispatch: () => null,
});

const HomePageProvider = (props) => {
  const [state, dispatch] = useReducer(homePageReducer, homePageState);
  return (
    <HomePageContext.Provider value={{ homePageState: state, dispatch }}>
      {props.children}
    </HomePageContext.Provider>
  );
};

export default HomePageProvider;
