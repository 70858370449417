import React from "react";
import { Doughnut } from "react-chartjs-2";
import Styles from "./index.module.scss";
const PieChart = ({ chartData }) => {
  const progressBarData = [
    {
      id: 1,
      title: "Paypal",
      price: "$395.15",
      color: "#CB998D",
      pct: 80,
    },
    {
      id: 2,
      title: "ApplePay",
      price: "$395.15",
      color: "#A47F74",
      pct: 70,
    },
    {
      id: 3,
      title: "Gift ",
      price: "$395.15",
      color: "#7A7A7A",
      pct: 40,
    },
    {
      id: 4,
      title: "House",
      price: "$395.15",
      color: "#C2C2C2",
      pct: 20,
    },
    {
      id: 5,
      title: "Credit",
      price: "$395.15",
      pct: 50,
    },
  ];

  let OrderData = [];
  let OrderDataTitles = [];
  chartData.revenueBreakDownOrderData.map((p, i) => (

    OrderData.push(p.per),
    OrderDataTitles.push(p.title)
  ));

  const revenueBreakDownOrderData = {
    labels: OrderDataTitles,
    datasets: [
      {
        label: "# of Votes",
        data: OrderData,
        backgroundColor: ["#938497", "#CB998D", "#E4E0E5", "#c6c6c6"],
        borderColor: ["#CB998D"],
        borderWidth: 0.1,
        hoverOffset: 4,
        // borderRadius:
      },
    ],
  };

  let CountData = [];
  let CountDataTitle = [];
  chartData.revenueBreakDownOrderCount.map((p, i) => (

    CountData.push(p.per),
    CountDataTitle.push(p.title)
  ));
  const revenueBreakDownOrderCountData = {
    labels: CountDataTitle,
    datasets: [
      {
        label: "# of Votes",
        data: CountData,
        backgroundColor: ["#E4E0E5", "#CB998D", '#938497'],
        borderColor: ["#CB998D"],
        borderWidth: 0.1,
        hoverOffset: 4,
      },
    ],
  };

  const options1 = {
    cutoutPercentage: 80,
    responsive: false,
    tooltips: {
      enabled: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };


  return (
    <div >
      <div class="row">
        <div className={`col`}>
          <Doughnut
            data={revenueBreakDownOrderData}

            height={110}
            width={110}
            style={{ marginLeft: "50px" }}
          />
          <div className={`d-flex mt-4 ${Styles.PieChartList}`}>
            <ul style={{ listStyle: "disc" }}>
              {chartData?.revenueBreakDownOrderData.map((i) => (
                <li>
                  {i.title} - <span>${i.status}</span>
                </li>
              ))}

            </ul>
          </div>
        </div>
        <div className={`col`}>
          <Doughnut
            data={revenueBreakDownOrderCountData}

            height={110}
            width={110}
            style={{ marginLeft: "50px" }}
          />
          <div className={`d-flex mt-4 ${Styles.PieChartList}`}>
            <ul>
              {chartData?.revenueBreakDownOrderCount.map((i) => (
                <li>
                  {i.title} - <span>${i.status}</span>
                </li>
              ))}

            </ul>
          </div>
        </div>
      </div>
      <div class="col">
        {/* {progressBarData.map((i) => (
          <ProgressBar
            heading={i.title}
            price={i.price}
            color={i.color}
            pct={i.pct}
          />
        ))} */}
      </div>
    </div>
  );
};
export default PieChart;
