import { useContext } from "react";
import { ProductContext } from "./ProductContext";
import * as PRODUCT_ACTIONS from "./ProductAction";

export function useProduct() {
  const { productState, dispatch } = useContext(ProductContext);

  const setMenuList = (payload) => {
    dispatch({ type: PRODUCT_ACTIONS.SET_MENU_LIST, payload });
  };

  const setProductList = (payload) => {
    dispatch({ type: PRODUCT_ACTIONS.SET_PRODUCT_LIST, payload });
  };

  const setSelectedProduct = (item) => {
    dispatch({ type: PRODUCT_ACTIONS.SELECTED_PRODUCT, payload: item });
  };

  const clearSelectedProduct = () => {
    dispatch({ type: PRODUCT_ACTIONS.CLEAR_SELECTED_PRODUCT });
  };

  const setCurrentAdd = (payload) => {
    dispatch({ type: PRODUCT_ACTIONS.SET_CURRENT_ADD, payload });
  };

  const clearCurrentAdd = () => {
    dispatch({ type: PRODUCT_ACTIONS.CLEAR_CURRENT_ADD });
  };

  const addProduct = (payload) => {
    dispatch({ type: PRODUCT_ACTIONS.PRODUCT_ADD, payload });
  };

  const deleteProduct = (payload) => {
    dispatch({ type: PRODUCT_ACTIONS.DELETE_PRODUCT_SELECTED, payload });
  };
  const selectProductVariation = (payload) => {
    dispatch({ type: PRODUCT_ACTIONS.PRODUCT_VARIANT_SELECTED, payload });
  };
  const clearProductVariation = () => {
    dispatch({ type: PRODUCT_ACTIONS.CLR_VARIANT_SELECTED });
  };
  const addProductCms = (payload) => {
    dispatch({ type: PRODUCT_ACTIONS.SELECTED_PRODUCT_DATA_CMS, payload });
  };
  return {
    productState,
    addProductCms,
    selectProductVariation,
    clearProductVariation,
    setMenuList,
    setProductList,
    setSelectedProduct,
    clearSelectedProduct,
    setCurrentAdd,
    clearCurrentAdd,
    addProduct,
    deleteProduct,
  };
}
