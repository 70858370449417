import { getProductsWithSequence, setProductsWithSequence } from "api/product";
import React, { useContext, useEffect, useState } from "react";
import Nestable from "react-nestable";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import "../../../../node_modules/react-nestable/dist/styles/index.css";
// Components
import Header from "../../../Components/Header/Header";
import LoadingScreen from "../../../Components/Loading/LoadingScreen";
// Context
import { AppContext } from "../../../Context/AppContext";
import { MODULES } from "../../../Helpers/Constants";
import { getAccessByModuleName } from "../../../Routes/Routes";
import Frame from "./../../../Assets/images/Frame 697@2x.svg";
//scss
import styles from "./index.module.scss";

const Sequence = (props) => {
  const history = useHistory();
  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [access, setAccess] = useState({});
  const [loading, setLoading] = useState(true);
  const [defaultFilter, setDefaultFilter] = useState({
    category_hierarchy_id: props.match.params.id,
  });
  // const productService = new ProductManagementApi();
  const updateData = async () => {
    // console.log("postdata", data);
    setLoading(true);
    const postData = {
      data: data,
    };
    const response = await setProductsWithSequence(postData);
    if (response && response.status === 200) {
      showToastMessage(
        undefined,
        "Sequence has been updated successfully.",
        "success",
        false
      );
      fetchData(defaultFilter);
      // history.push("/product-catalog/category-management");
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(defaultFilter);
  }, []);

  const fetchData = async ({ category_hierarchy_id }) => {
    setLoading(true);
    const postData = {
      category_hierarchy_id: props.match.params.id,
    };
    const response = await getProductsWithSequence(postData);
    if (response && response.status === 200) {
      setData(response.data.data.rows);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);
  };

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  // Access Contorol functionm call
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.CATEGORY);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.write_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }
  const renderItem = ({ collapseIcon, depth, handler, index, item }) => (
    <div>
      <div className={styles.GridTable}>
        <div
          className={
            "d-flex align-items-center justify-cotent-between cursor-pointer ml-3"
          }
        >
          {collapseIcon}
          <img
            style={{ width: "25px", height: "25px" }}
            src={Frame}
            alt="Move"
          />
        </div>
        <div className={styles.ProducatImageWapper}>
          {/* <img src={item.product.product_images[0].image} alt={item.title} /> */}
          {item.product.product_images.length !== 0 ? (
            <img
              src={
                item.product.product_images.length === 0 ||
                item.product.product_images[0].image === "" ||
                item.product.product_images[0].image === "null" ||
                item.product.product_images[0].image === null
                  ? ""
                  : `${item.product.product_images[0].image}`
              }
              alt={item.title}
            />
          ) : (
            <div className={styles.emptyImage}>
              <span>Image</span>
            </div>
          )}
        </div>
        <div className={styles.Name}>{item.product.title}</div>
      </div>
    </div>
  );
  const handleProductSequence = async (event) => {
    setData(event.items);
  };
  // console.log(data);
  const SequenceData = () =>
    data && data.length > 0 ? (
      <div>
        <div className="row mb-4">
          <div className={`col-md-6`}>
            <h3 style={{ marginLeft: "0%" }} className="bold mt-5">
              {data[0].category_hierarchy.category.title}
            </h3>
          </div>
          <div className={`col-md-6`}>
            <span
              onClick={() => {
                updateData();
              }}
              style={{
                fontSize: "14px",
                padding: "7px 15px",
                marginLeft: "76%",
              }}
              className="btn gray-btn d-inline-block mb-2 mr-2 mt-5 cursor-pointer text-right"
            >
              Save Sequence
            </span>
          </div>
        </div>
        <div className={styles.GridTableHeader}>
          <div className={styles.MoveButton}></div>
          <div className={styles.header}>Image</div>
          <div className={styles.header}>Product Name</div>
        </div>
        <Nestable
          className={`${styles.NestedGroup} nestedSequence`}
          items={data}
          renderItem={renderItem}
          maxDepth={1}
          onChange={handleProductSequence}
        />
      </div>
    ) : (
      <div>No data found</div>
    );
  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <React.Fragment>
      <Header title="Product Sequence" />
      <div id="MainContent" className="main-content" />
      <div>
        <SequenceData />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Sequence);
