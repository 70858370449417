import { useContext } from "react";
import { CategoryContext } from "./CategoryContext";
import { CategoryAction } from "./categoryAction";

export function useCategory() {
  const { categoryState, dispatch } = useContext(CategoryContext);
 
  const addNewBanner = (values) => {
    dispatch({ type: CategoryAction.ADD_NEW_BANNER });
  };
  const removeBanner = (index) => {
    dispatch({ type: CategoryAction.REMOVE_BANNER, payload: index });
  };
  const setBannerData = (values) => {
    dispatch({ type: CategoryAction.UPDATE_BANNER_DATA, payload: values });
  };
  const checkBannerData = (payload) => {
    dispatch({ type: CategoryAction.CHECK_BANNER_DATA, payload });
  };
  const setSeoData = (values) => {
    dispatch({ type: CategoryAction.SET_SEO_DATA, payload: values });
  };
  const checkSeoData = (payload) => {
    dispatch({ type: CategoryAction.CHECK_SEO_DATA, payload });
  };
  const setOgInfoData = (values) => {
    dispatch({ type: CategoryAction.UPDATE_OG_INFO_DATA, payload: values });
  };
  const checkOgInfoData = (payload) => {
    dispatch({ type: CategoryAction.CHECK_OG_INFO_DATA, payload });
  };
  const setTwitterInfoData = (values) => {
    dispatch({
      type: CategoryAction.UPDATE_TWITTER_INFO_DATA,
      payload: values,
    });
  };
  const checkTwitterInfoData = (payload) => {
    dispatch({ type: CategoryAction.CHECK_TWITTER_INFO_DATA, payload });
  };
  const setSchemaData = (values) => {
    dispatch({ type: CategoryAction.UPDATE_SCHEMA_DATA, payload: values });
  };
  const checkSchemaData = (payload) => {
    dispatch({ type: CategoryAction.CHECK_SCHEMA_DATA, payload });
  };
  const setCategoryPrimaryData = (payload) => {
    dispatch({ type: CategoryAction.SET_CATEGORY_PRIMARY_DATA, payload });
  };
  const checkCategoryPrimaryData = (payload) => {
    dispatch({ type: CategoryAction.CHECK_CATEGORY_PRIMARY_DATA, payload });
  };
  const setCategoryNavigationData = (values) => {
    dispatch({ type: CategoryAction.SET_CATEGORY_NAVIGATION_INFO, payload: values });
  };
  const checkCategoryNavigationData = (values) => {
    dispatch({ type: CategoryAction.CHECK_CATEGORY_NAVIGATION_INFO, payload: values });
  };
  const setCategoryAllData = (values) => {
    dispatch({ type: CategoryAction.SET_ALL_CATEGORY_DATA, payload: values });
  };
  const clearCategoryState = (values) => {
    dispatch({ type: CategoryAction.CLEAR_CATEGORY_DATA });
  };
  const setCategoryBannerData = (payload) => {
    dispatch({ type: CategoryAction.SET_CATEGORY_BANNER_DATA, payload });
  };
  const setCategoryHierarchyId = (payload) => {
    dispatch({ type: CategoryAction.SET_CATEGORY_HIERARCHY_ID, payload });
  };
  const setCategoryHierarchyParentId = (payload) => {
    dispatch({ type: CategoryAction.SET_CATEGORY_HIERARCHY_PARENT_ID, payload });
  };
  const setCategoryListData = (payload) => {
    dispatch({ type: CategoryAction.SET_CATEGORY_LIST_DATA, payload });
  };
  const setCategoryFaqData = (payload) => {
    dispatch({type: CategoryAction.SET_CATEGORY_FAQ_DATA, payload});
  }
  const removeFaq = (index) => {
    dispatch({ type: CategoryAction.REMOVE_FAQ, payload: index });
  };
  const setFaqData = (values) => {
    dispatch({ type: CategoryAction.UPDATE_FAQ_DATA, payload: values });
  };

  return {
    categoryState,
    addNewBanner,
    removeBanner,
    setBannerData,
    checkBannerData,
    setSeoData,
    checkSeoData,
    setOgInfoData,
    checkOgInfoData,
    setTwitterInfoData,
    checkTwitterInfoData,
    setSchemaData,
    checkSchemaData,
    setCategoryPrimaryData,
    checkCategoryPrimaryData,
    setCategoryNavigationData,
    checkCategoryNavigationData,
    clearCategoryState,
    setCategoryAllData,
    setCategoryBannerData,
    setCategoryHierarchyId,
    setCategoryHierarchyParentId,
    setCategoryListData,
    setCategoryFaqData,
    removeFaq,
    setFaqData
  };
}
