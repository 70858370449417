import React, { useReducer } from "react";
import CustomerReducer from "./customerReducer";
import { customerInitalState } from "./customerState";

export const CustomerContext = React.createContext({
  customerState: customerInitalState,
  dispatch: () => null,
});

const CustomerProvider = (props) => {
  const [state, dispatch] = useReducer(CustomerReducer, customerInitalState);

  return (
    <CustomerContext.Provider value={{ customerState: state, dispatch }}>
      {props.children}
    </CustomerContext.Provider>
  );
};

export default CustomerProvider;
