import moment from "moment";
import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router";
//ICONS
import EditIcon from "./../../../Assets/images/edit.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";
import ActivatedIcon from "./../../../Assets/images/activated.svg";
import Header from "../../../Components/Header/Header";
import PlusIcon from "./../../../Assets/images/plus_1.svg";
import CloseIcon from "./../../../Assets/images/minus_1.svg";
import AddIcon from "./../../../Assets/images/add.svg";
// CONTEXT
import { AppContext } from "../../../Context/AppContext";
import { useHistory } from "react-router-dom";
import { MODULES } from "./../../../Helpers/Constants";
import { getAccessByModuleName } from "./../../../Routes/Routes";
//API
import { getCmsLocationsList, deleteCmsLocations } from "api/cmsLocation";
import { updateStatus } from "../../../api/utils";
//CSS
import styles from "./index.module.scss";
import "react-nestable/dist/styles/index.css";
//COMPONENT
import DeleteConfirmation from "../../../Components/Modal/DeleteConfirmation/DeleteConfirmation";
import StatusConfirmation from "../../../Components/Modal/StatusConfirmation/StatusConfirmation";
import Nestable from "react-nestable";

const LocationManagement = (props) => {
  const history = useHistory();
  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);

  const [data, setData] = React.useState([]);
  // const [loading, setLoading] = React.useState(false);
  // const [pageCount, setPageCount] = React.useState(1);
  const [access, setAccess] = useState({});
  const [statusLocationId, setStatusLocationId] = useState(false);
  const [statusOfLocation, setStatusOfLocation] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLocationId, setDeleteLocationId] = useState();

  useEffect(getModuleAccess, [modules]);
  useEffect(() => {
    fetchData(defaultFilter);
  }, []);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.LOCATIONS);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/users");
    }
  }

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setIsLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
    };

    const response = await getCmsLocationsList(postData);
    if (response && response.status === 200) {
      setData(response.data.data.rows);
      // setPageCount(Math.ceil(response.data.data.count / pageSize));
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handleAddLocationsClick = async (event, type = "add", id) => {
    event.preventDefault();
    if (type === "add") {
      //add new location
      history.push("/cms/locations/add");
    } else if (type === "parent") {
      //add location under location
      history.push(`/cms/locations/add-hierarchy/${id}`);
    } else {
      //edit location
      props.history.push(`/cms/locations/edit/${id}`);
    }
  };

  const handleStatusLocationClick = async (event, id, status) => {
    event.preventDefault();

    setStatusLocationId(id);
    setStatusOfLocation(status);
    setShowStatusModal(true);
  };

  const handleLocationStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfLocation === 1 ? 0 : 1,
      id: statusLocationId,
      table: "cms_locations",
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setStatusLocationId(null);
      setStatusOfLocation(null);
      setShowStatusModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  const handleDeleteLocationClick = async (event, id, categoryHierarchyId) => {
    event.preventDefault();
    setDeleteLocationId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteLocation = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const response = await deleteCmsLocations(deleteLocationId);

    if (response && response.status === 200) {
      setDeleteLocationId(null);
      setShowDeleteModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const renderItem = ({ collapseIcon, depth, handler, index, item }) => (
    <div className={styles.GridTable}>
      <div
        className={
          "d-flex align-items-center justify-cotent-between cursor-pointer ml-3"
        }
      >
        {collapseIcon}
      </div>
      <div>{item.name}</div>
      <div className={styles.status}>
        {item.status === 1 ? (
          <div className={styles.Active}>Active</div>
        ) : (
          <div className={styles.Inactive}>Inactive </div>
        )}
      </div>

      <div className={styles.CreatedRow}>
        {moment(item.createdAt).format("ddd MMM DD, YYYY")}
      </div>
      <div className={styles.UpdateRow}>
        {moment(item.updatedAt).format("ddd MMM DD, YYYY")}
      </div>
      <div>
        {access && access.write_access && (
          <span
            className={`link cursor-pointer ${styles.iconWrapper}`}
            onClick={(e) => {
              e.preventDefault();
              handleAddLocationsClick(e, "parent", item.id);
            }}
          >
            <img className={`cursor-pointer`} src={AddIcon} alt="Add" />
          </span>
        )}
        {access && access.write_access && (
          <img
            className={`cursor-pointer ml-2`}
            src={EditIcon}
            alt="Edit"
            onClick={(e) => {
              e.preventDefault();
              handleAddLocationsClick(e, "edit", item.id);
            }}
          />
        )}
        {access && access.delete_access && (
          <img
            className={`cursor-pointer ml-2`}
            src={DeleteIcon}
            alt="Delete"
            onClick={(e) => handleDeleteLocationClick(e, item.id)}
          />
        )}
        {access && access.write_access && (
          <img
            className={`cursor-pointer ml-3`}
            src={ActivatedIcon}
            alt="Active"
            onClick={(e) => handleStatusLocationClick(e, item.id, item.status)}
          />
        )}
      </div>
    </div>
  );

  const handlerStub = () => <span />;
  return (
    <React.Fragment>
      <Header
        title="CMS - Locations Page"
        hasAddButton={access.write_access}
        handleAddClick={handleAddLocationsClick}
      />
      <div id="MainContent" className="main-content" />

      <div className={styles.GridTable}>
        <div className={styles.MoveButton}></div>
        <div>Name</div>
        <div>Status</div>
        <div>Created At</div>
        <div>Updated At</div>
        <div className={styles.Actions}></div>
      </div>
      {data.length !== 0 ? (
        <Nestable
          renderCollapseIcon={(props) => (
            <img
              className="cursor-pointer mr-2"
              style={{ width: "25px", height: "25px" }}
              src={props.isCollapsed ? PlusIcon : CloseIcon}
              alt="Collapse"
            />
          )}
          handler={handlerStub}
          className={styles.NestedGroup}
          items={data}
          renderItem={renderItem}
        />
      ) : (
        "No data found"
      )}

      {access.write_access && (
        <StatusConfirmation
          showModal={showStatusModal}
          title={"Locations"}
          message={"locations?"}
          buttonLabel={statusOfLocation ? "Inactivate" : "Activate"}
          statusFlag={statusOfLocation}
          customFlag={false}
          handleCancelClick={(event) => {
            event.preventDefault();
            setShowStatusModal(false);
            statusLocationId(null);
            statusOfLocation(null);
          }}
          handleSubmitClick={handleLocationStatus}
        />
      )}

      <DeleteConfirmation
        showModal={showDeleteModal}
        title="Delete Locations"
        message="Are you sure want to delete this locations?"
        buttonLabel="Delete Locations"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteModal(false);
          setDeleteLocationId(null);
        }}
        handleSubmitClick={handleDeleteLocation}
      />
    </React.Fragment>
  );
};

export default withRouter(LocationManagement);
