import { useFormikContext } from "formik";
import { useOrderAttributeProvider } from "../context/order";
import { decrement, deleteProds, increment } from "../utility/util";

export function useProductManage() {
  const formik = useFormikContext();
  const { active, module } = useOrderAttributeProvider();

  const deleteProduct = (i, id) => {
    let recipients = [...formik.values.recipients];
    let currentR = recipients[active];
    let deleted_product_ids = currentR.deleted_product_ids;
    if (module === "edit-order") {
      if (currentR.products[i]?.sql_id) {
        deleted_product_ids.push(currentR.products[i]?.sql_id);
      }
    }

    currentR.products = deleteProds(currentR.products, i);
    formik.setValues((v) => {
      return {
        ...v,
        recipients,
      };
    });
  };

  const increaseProductQty = (i) => {
    let recipients = [...formik.values.recipients];
    recipients[active].products[i] = increment(recipients[active].products[i]);
    formik.setValues((v) => {
      return {
        ...v,
        recipients,
      };
    });
  };

  const decreaseProductQty = (i) => {
    let recipients = [...formik.values.recipients];
    recipients[active].products[i] = decrement(recipients[active].products[i]);
    formik.setValues((v) => {
      return {
        ...v,
        recipients,
      };
    });
  };

  const deleteAddon = (i) => {
    let recipients = [...formik.values.recipients];
    let currentR = recipients[active];
    // currentR.addons = deleteProds(currentR.addons, i);
    let deleted_product_ids = currentR.deleted_product_ids;
    if (module === "edit-order") {
      if (currentR.addons[i]?.sql_id) {
        deleted_product_ids.push(currentR.addons[i]?.sql_id);
      }
      
    }
    currentR.addons = deleteProds(currentR.addons, i);
    formik.setValues((v) => {
      return {
        ...v,
        recipients,
      };
    });
  };

  const increaseAddonQty = (i) => {
    let recipients = [...formik.values.recipients];
    recipients[active].addons[i] = increment(recipients[active].addons[i]);
    formik.setValues((v) => {
      return {
        ...v,
        recipients,
      };
    });
  };

  const decreaseAddonQty = (i) => {
    let recipients = [...formik.values.recipients];
    recipients[active].addons[i] = decrement(recipients[active].addons[i]);
    formik.setValues((v) => {
      return {
        ...v,
        recipients,
      };
    });
  };


  const deleteAllProduct = () => {

    let recipients = [...formik.values.recipients];

    recipients.forEach((rec) => {
      let currentR = rec;
      let deleted_product_ids = currentR.deleted_product_ids;
      if (module === "edit-order") {

        currentR.products?.forEach((prod) => {
          if (prod?.sql_id) {
            deleted_product_ids.push(prod?.sql_id);
          }
        });

      }
    })


  };

  return {
    deleteProduct,
    increaseProductQty,
    decreaseProductQty,
    decreaseAddonQty,
    increaseAddonQty,
    deleteAddon,
    deleteAllProduct
  };
}
