// Packages
import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router";

// Components
import Header from "./../../../Components/Header/Header";
import ClockDetail from "./../../../Components/Modal/ClockDetail/ClockDetail";

// Services
// import Clock from "./../../../Services/Clock/Clock";
import { getUserHistory, details } from "./../../../api/clock";

// Context
import { AppContext } from "./../../../Context/AppContext";

// Images
import EditIcon from "./../../../Assets/images/edit.svg";

const TimeClockHistory = (props) => {
  const [clockDetailsList, setClockDetailsList] = useState([]);
  const [clockDetails, setClockDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { showToastMessage, setIsLoading } = useContext(AppContext);
  // const clockService = new Clock();

  const handleGetClockDetailsList = async () => {
    setIsLoading(true);
    // const response = await clockService.getUserHistory();
    const response = await getUserHistory();

    if (response && response.status === 200)
      setClockDetailsList(response.data.data);
    else showToastMessage(undefined, response?.data?.message, "error", false);

    setIsLoading(false);
  };

  useEffect(() => {
    handleGetClockDetailsList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetClockDetails = async (event, id) => {
    event.preventDefault();

    setIsLoading(true);
    // const response = await clockService.details(id);
    const response = await details(id);

    if (response && response.status === 200) {
      setClockDetails(response.data.data);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setShowModal(true);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Header title="Time History" />

      <div id="MainContent" className="main-content">
        {clockDetailsList.map((clockDetails, index) => {
          return (
            clockDetails.time_clock &&
            clockDetails.time_clock.length > 0 && (
              <div key={index}>
                <div className="current-date text-right">
                  {clockDetails.date}
                </div>
                <div className="table-responsive my-5">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th className="text-center">Hours</th>
                        <th className="text-center">OT (1.5)</th>
                        <th className="text-center">OT (2)</th>
                        <th className="text-center">Total Hours</th>
                        <th className="text-right" />
                      </tr>
                    </thead>
                    <tbody>
                      {clockDetails.time_clock.map((timeClock, index) => {
                        return (
                          <tr
                            key={index}
                            onClick={(event) =>
                              handleGetClockDetails(event, timeClock.id)
                            }
                            className="cursor-pointer"
                          >
                            <td>{timeClock.date}</td>
                            <td className="text-center">
                              {timeClock.regular_hours
                                ? timeClock.regular_hours
                                : "-"}
                            </td>
                            <td className="text-center">
                              {timeClock.ot1 ? timeClock.ot1 : "-"}
                            </td>
                            <td className="text-center">
                              {timeClock.ot2 ? timeClock.ot2 : "-"}
                            </td>
                            <td className="text-center">
                              {timeClock.duration ? timeClock.duration : "-"}
                            </td>
                            <td className="td-actions text-right">
                              <div>
                                <a
                                  className="link d-block"
                                  href="/"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    props.history.push(
                                      `/time-clock/${timeClock.id}`
                                    );
                                  }}
                                >
                                  <img src={EditIcon} alt="" />
                                </a>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                      <tr className="total-row">
                        <td>Total Weekly Hours</td>
                        <td className="text-center">
                          {clockDetails.total_regular_hours
                            ? clockDetails.total_regular_hours
                            : "-"}
                        </td>
                        <td className="text-center">
                          {clockDetails.total_ot1
                            ? clockDetails.total_ot1
                            : "-"}
                        </td>
                        <td className="text-center">
                          {clockDetails.total_ot2
                            ? clockDetails.total_ot2
                            : "-"}
                        </td>
                        <td className="text-center">
                          {clockDetails.total_duration
                            ? clockDetails.total_duration
                            : "-"}
                        </td>
                        <td className="td-actions text-right" />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )
          );
        })}
      </div>

      <ClockDetail
        showModal={showModal}
        clockDetails={clockDetails}
        handleCloseModal={(event) => {
          event.preventDefault();
          setShowModal(false);
        }}
        isEditable={false}
      />
    </React.Fragment>
  );
};

export default withRouter(TimeClockHistory);
