import { callPostApi, callDeleteApi, callPutApi, callGetApi } from "./api";

export async function get() {
      // return this.interceptors.GET('configuration');
      try {
            const response = await callGetApi({ url: "configuration" });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function update(data) {
      // return this.interceptors.PUT('configuration', data);
      try {
            const response = await callPutApi({ url: "configuration", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

// export async function delete (id) {
//       return this.interceptors.PUT('configuration/' + id);
// }

export async function getByConfigurationKey(key) {
      // return this.interceptors.GET('configuration/key/' + key);
      try {
            const response = await callGetApi({ url: "configuration/key/" + key });
            return response;
      } catch (error) {
            throw error;
      }
}


export async function getExceptions() {
      // return this.interceptors.GET('exception-master');
      try {
            const response = await callGetApi({ url: "exception-master" });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function addExceptions(data) {
      // return this.interceptors.POST('exception-master', data);
      try {
            const response = await callPostApi({ url: "exception-master", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateExceptions(data) {
      // return this.interceptors.PUT('exception-master', data);
      try {
            const response = await callPutApi({ url: "exception-master", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function deleteExceptions(id) {
      // return this.interceptors.DELETE('exception-master/' + id);
      try {
            const response = await callDeleteApi({ url: "exception-master/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getPayrollDates() {
      // return this.interceptors.GET('payroll-date');
      try {
            const response = await callGetApi({ url: "payroll-date" });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function addPayrollDates(data) {
      // return this.interceptors.POST('payroll-date', data);
      try {
            const response = await callPostApi({ url: "payroll-date", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updatePayrollDates(data) {
      // return this.interceptors.PUT('payroll-date', data);
      try {
            const response = await callPutApi({ url: "payroll-date", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function handleDeletePayrollDateData(id) {
      // return this.interceptors.DELETE('payroll-date/' + id);
      try {
            const response = await callDeleteApi({ url: "payroll-date/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}

// Email Notification setting
export async function getEmailNotificationList(data) {
      // return this.interceptors.POST('store-settings/email-notification/list', data);
      try {
            const response = await callPostApi({ url: "store-settings/email-notification/list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function emailNotificationAdd(data) {
      // return this.interceptors.POST('store-settings/email-notification/', data);
      try {
            const response = await callPostApi({ url: "store-settings/email-notification/", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function emailNotificationUpdate(data) {
      // return this.interceptors.PUT('store-settings/email-notification/', data);
      try {
            const response = await callPutApi({ url: "store-settings/email-notification/", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function emailNotificationGetById(id) {
      // return this.interceptors.GET('store-settings/email-notification/' + id);
      try {
            const response = await callGetApi({ url: "store-settings/email-notification/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function emailNotificationDeleteById(id) {
      // return this.interceptors.DELETE('store-settings/email-notification/' + id);
      try {
            const response = await callDeleteApi({ url: "store-settings/email-notification/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}

// Rollover time setting
export async function getRolloverTimeInfo() {
      // return this.interceptors.GET('store-settings/rollover-time/');
      try {
            const response = await callGetApi({ url: "store-settings/rollover-time/" });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function rolloverTimeUpdate(data) {
      // return this.interceptors.PUT('store-settings/rollover-time/', data);
      try {
            const response = await callPutApi({ url: "store-settings/rollover-time/", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

// Upcharge Dates setting
export async function getUpchargeDatesList(data) {
      // return this.interceptors.POST('store-settings/upcharge-date/list', data);
      try {
            const response = await callPostApi({ url: "store-settings/upcharge-date/list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function upchargeDatesAdd(data) {
      // return this.interceptors.POST('store-settings/upcharge-date/', data);
      try {
            const response = await callPostApi({ url: "store-settings/upcharge-date/", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function upchargeDatesUpdate(data) {
      // return this.interceptors.PUT('store-settings/upcharge-date/', data);
      try {
            const response = await callPutApi({ url: "store-settings/upcharge-date/", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function upchargeDatesGetById(id) {
      // return this.interceptors.GET('store-settings/upcharge-date/' + id);
      try {
            const response = await callGetApi({ url: "store-settings/upcharge-date/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function upchargeDatesDeleteById(id) {
      // return this.interceptors.DELETE('store-settings/upcharge-date/' + id);
      try {
            const response = await callDeleteApi({ url: "store-settings/upcharge-date/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getFolderGroups(data) {
      // console.log("1.0.0 folder list => ", data);
      // return this.interceptors.GET('store-settings/folder/list', data);
      try {
            const response = await callGetApi({ url: "store-settings/folder/list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getFolderNormalList() {
      // return this.interceptors.GET('store-settings/folder/normal-list');
      try {
            const response = await callGetApi({ url: "store-settings/folder/normal-list" });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function groupZipcodeAdd(data) {
      // return this.interceptors.POST('store-settings/folder/', data);
      try {
            const response = await callPostApi({ url: "store-settings/folder/", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function groupZipcodeUpdate(data) {
      // return this.interceptors.PUT('store-settings/folder/', data);
      try {
            const response = await callPutApi({ url: "store-settings/folder/", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function groupZipcodeGetById(id) {
      // return this.interceptors.GET('store-settings/folder/' + id);
      try {
            const response = await callGetApi({ url: "store-settings/folder/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function groupZipcodeDeleteById(id) {
      // return this.interceptors.DELETE('store-settings/folder/' + id);
      try {
            const response = await callDeleteApi({ url: "store-settings/folder/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function zipcodeListByGroup(data, id) {
      // console.log("1.0.0 zipcode list => ", data);
      // return this.interceptors.POST('store-settings/zipcode/list/group/' + id, data);
      try {
            const response = await callPostApi({ url: "store-settings/zipcode/list/group/" + id, body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function zipcodeAdd(data) {
      // return this.interceptors.POST('store-settings/zipcode/', data);
      try {
            const response = await callPostApi({ url: "store-settings/zipcode/", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function zipcodeUpdate(data) {
      // return this.interceptors.PUT('store-settings/zipcode/', data);
      try {
            const response = await callPutApi({ url: "store-settings/zipcode/", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function zipcodeGetById(id) {
      // return this.interceptors.GET('store-settings/zipcode/' + id);
      try {
            const response = await callGetApi({ url: "store-settings/zipcode/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function zipcodeDeleteById(id) {
      // return this.interceptors.DELETE('store-settings/zipcode/' + id);
      try {
            const response = await callDeleteApi({ url: "store-settings/zipcode/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}