export const productState = {
      activeProductVariantIndex: 0,
      product_primary_info: {
            type: null,
            inventory_remaining: 0,
            title: "",
            sequence_number: 0,
            status: "",
            sku_id: "",
            keywords: "",
            customer_note: "",
            lead_time: "",
            non_local_lead_time: "",
            product_images: [],
            description: "",
            care_description: "",
            declaimer: "",
            did_you_know: "",
            after_care: "",
            delivery_info: "",
      },
      productPrimaryError: false,
      productNavigationError: false,
      product_navigation_info: {
            slug: "",
            blurb: "",
      },
      productVariationError: false,
      product_variations:
            [{
                  title: "",
                  sku: "",
                  commission: 0,
                  price: 0,
                  sale_price: 0,
                  inventory: 100,
                  image: "",
                  status: 0,
                  is_default_selected: false,
                  is_sold_out: false,
                  placeholder_background: "",
                  placeholder_text: "",
                  checklist: "",
            },],
      product_flag_info_value: {
            is_eligible_for_commission: false,
            is_limited_availability: false,
            is_sold_out: false,
            is_exclude_from_search: false,
            temporary_unavailable: false,
      },
      is_local_only: false,
      seo: {
            id: 0,
            parent_id: 0,
            module: "product",
            is_robot: false,
            is_no_follow: false,
            is_no_index: false,
            image_alt_text: "",
            page_title: "",
            meta_title: "",
            meta_description: "",
            canonical_url: "",
            og_title: "",
            og_description: "",
            og_image_url: "",
            og_url: "",
            og_image_alt: " ",
            og_image_type: "",
            og_image_width: 0,
            og_image_height: 0,
            og_site_name: "",
            twitter_title: "",
            twitter_description: "",
            twitter_image_url: "",
            twitter_site: "",
            twitter_creator: ""
      },
      product_notes: [],
      activeProductNoteIndex: 0,
      setProductAllData: "",
      similar_products: [],
      activeIndexSimilarProduct: 0,
      product_images: [],
      setEditVariantProductIndex: '',
      user_data: [],
      setPageForView: 0,
      product_categories: [],
      delete_product_image: [],
      banners: [
            {
                  id: 0,
                  module: "products",
                  position: "LEFT",
                  banner_text: "",
                  button_label: "",
                  button_url: "",
                  description: "",
                  image: "",
                  is_supported_image: 1,
                  show_oval: 0
            }
      ],
      productNoteError: true,
      seoError: false,
      ogInfoError: false,
      twitterError: false,
      schemaError: false,
      bannerError: false,
};
