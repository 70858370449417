export function getLabel(a) {
  let attnLabel = "Apartment Name";
  let addressLine2Label = "Address Line 2";

  switch (a.toLowerCase()) {
    case "apartment":
      attnLabel = "Apartment name";
      addressLine2Label = "Unit number";
      break;
    case "business":
      attnLabel = "Business name";
      addressLine2Label = "Floor/suite number";
      break;
    case "house":
      attnLabel = "Attention";
      addressLine2Label = "Address line 2";
      break;
    case "funeral home":
      attnLabel = "Funeral home name";
      addressLine2Label = "Address line 2";
      break;
    case "church":
      attnLabel = "Church name";
      addressLine2Label = "Address line 2";
      break;
    case "hospital":
      attnLabel = "Hospital name";
      addressLine2Label = "Room number";
      break;
    case "restaurant":
      attnLabel = "Restaurant name";
      addressLine2Label = "Address line 2";
      break;
    case "hotel":
      attnLabel = "Hotel name";
      addressLine2Label = "Room number";
      break;
    case "university":
      attnLabel = "Room number";
      addressLine2Label = "Dorm info";
      break;
    case "other school":
      attnLabel = "School name";
      addressLine2Label = "Room number";
      break;
    default:
      attnLabel = "Apartment name";
      addressLine2Label = "Address line 2";
      break;
  }
  return { attnLabel, addressLine2Label };
}
