import React from "react";
import moment from "moment";

// Context
// import { AppContext } from "./../../Context/AppContext";
import { BlogForm } from "../../Hooks/cms/blogFormFormik";

// SCSS
import styles from "./index.module.scss";

// COMPONENTS
import Text from "../../Components/FormControl/Text/Text";
import Date from "../../Components/FormControl/Date/Date";
import WYSIWIGEditor from "../../Components/WYSIWIGEditor/WYSIWIGEditor";
import TextArea from "../FormControl/TextArea/TextArea";
import { useBlog } from "Context/Cms-blog/useBlog";

function PrimaryInformation(props) {
  // const { showToastMessage, setIsLoading } = useContext(AppContext);

  const blogData = props.data ? props.data : {};
  let blogInitalValues = {
    title: blogData.title ? blogData.title : "",
    slug: blogData.slug ? blogData.slug : "",
    detail: blogData.detail ? blogData.detail : "",
    description: blogData.description ? blogData.description : "",
    keywords: blogData.keywords ? blogData.keywords : "",
    author_name: blogData.author_name ? blogData.author_name : "",
    read_time: blogData.read_time ? blogData.read_time : "",
    blog_date: blogData.blog_date
      ? moment(blogData.blog_date).format("MM-DD-YYYY")
      : "",
    url_category_slug: blogData.url_category_slug ? blogData.url_category_slug : "",
  };

  const {blogState} = useBlog();
  const submit = (values) => {};
  const cbSubmit = (values) => {
    console.log("values1",{...values,url_category_slug:blogState.primary_info.url_category_slug});
    props.setBlogPrimaryData({...values,url_category_slug:blogState.primary_info.url_category_slug});
  };

  const { formik: BlogFormik } = BlogForm(
    submit,
    blogInitalValues,
    cbSubmit,
    props.checkBlogPrimaryData
  );
    return (
    <form onSubmit={(e) => BlogFormik.handleSubmit(e)} className={`row`}>
      <div className={`${styles.productsWrapper}`} id="primary-information">
        <div className={`mt-2 md-4`}>
          <div className={`row`}>
            <div className={`col-md-6 col-xs-12`}>
              <Text
                type="text"
                label="Blog Title"
                value={BlogFormik.values.title}
                onChange={(data) =>
                  BlogFormik.setValues((v) => {
                    return { ...v, title: data };
                  })
                }
                onBlur={BlogFormik.handleBlur}
                touched={BlogFormik.touched.title}
                error={BlogFormik.errors.title}
                id="title"
              />
            </div>
            <div className={`col-md-6 col-xs-12`}>
              <Text
                type="text"
                label="Slug"
                value={BlogFormik.values.slug}
                onChange={(data) =>
                  BlogFormik.setValues((v) => {
                    return { ...v, slug: data };
                  })
                }
                onBlur={BlogFormik.handleBlur}
                touched={BlogFormik.touched.slug}
                error={BlogFormik.errors.slug}
                id="slug"
              />
            </div>
            <div className={`col-md-6 col-xs-12`}>
              <Text
                type="text"
                label="Keywords"
                value={BlogFormik.values.keywords}
                onChange={(data) =>
                  BlogFormik.setValues((v) => {
                    return { ...v, keywords: data };
                  })
                }
                id="keywords"
              />
            </div>
            <div className={`col-md-6 col-xs-12`}>
              <Text
                type="text"
                label="Author"
                value={BlogFormik.values.author_name}
                onChange={(data) =>
                  BlogFormik.setValues((v) => {
                    return { ...v, author_name: data };
                  })
                }
                id="author_name"
              />
            </div>
            <div className={`col-md-6 col-xs-12`}>
              <Text
                type="number"
                label="Read Time"
                value={BlogFormik.values.read_time}
                onChange={(data) =>
                  BlogFormik.setValues((v) => {
                    return { ...v, read_time: data ? +data : null };
                  })
                }
                id="read_time"
              />
            </div>
            <div className={`col-md-6 col-xs-12`}>
              <Date
                type="date"
                label="Blog Date"
                value={BlogFormik.values.blog_date}
                dateFormat="MM-DD-YYYY"
                onChange={(data) =>
                  BlogFormik.setValues((v) => {
                    return { ...v, blog_date: data ? data : null };
                  })
                }
                id="blog_date"
              />
            </div>
          </div>

          <div className={`row`}>
            <div className={`col-md-12 col-xs-12`}>
              <TextArea
                label="Description"
                rows={3}
                submitStatus={props.submitStatus}
                value={BlogFormik.values.detail}
                required={false}
                onChange={(data) =>
                  BlogFormik.setValues((v) => {
                    return { ...v, detail: data };
                  })
                }
                error={BlogFormik.errors.detail}
                touched={BlogFormik.touched.detail}
                disabled={false}
                id="description"
              />
            </div>
          </div>
          <br></br>
          <div className={`row`}>
            <label
              className="bmd-label-floating"
              style={{ marginLeft: "15px" }}
            >
              Blog Content
            </label>
            <div className={`col-md-12 col-xs-12`}>
              <WYSIWIGEditor
                data={BlogFormik.values.description}
                onChange={(data) => {
                  // console.log("WYSIWIGEditor", data);
                  BlogFormik.setValues((v) => {
                    return { ...v, description: data };
                  });
                }}
              />
            </div>
            {BlogFormik.errors.description ? (
              <p
                class="invalid-feedback d-block"
                style={{ marginLeft: "15px" }}
              >
                {BlogFormik.errors.description}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <button
        className="d-none primary-info-submit-button"
        type="submit"
      ></button>
    </form>
  );
}

export default PrimaryInformation;
