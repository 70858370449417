import { useContext } from "react";
import { cmsPageContext } from "./cmsPageContext";
import { CmsPageActions } from "./cmsPageActions";

export function useCmsPage() {
  const { cmsPageState, dispatch } = useContext(cmsPageContext);
  const clearCmsPageState = () => {
    dispatch({ type: CmsPageActions.CLEAR_DATA_PAGE });
  };
   
  const setSeoData = (values) => {
    dispatch({ type: CmsPageActions.UPDATE_SEO_DATA, payload: values });
  };
  const checkSeoData = (payload) => {
    dispatch({ type: CmsPageActions.CHECK_SEO_DATA, payload });
  };
  const setOgInfoData = (values) => {
    dispatch({ type: CmsPageActions.UPDATE_OG_INFO_DATA, payload: values });
  };
  const checkOgInfoData = (payload) => {
    dispatch({ type: CmsPageActions.CHECK_OG_INFO_DATA, payload });
  };
  const setTwitterInfoData = (values) => {
    dispatch({ type: CmsPageActions.UPDATE_TWITTER_INFO_DATA, payload: values});
  };
  const checkTwitterInfoData = (payload) => {
    dispatch({ type: CmsPageActions.CHECK_TWITTER_INFO_DATA, payload });
  };
  const setSchemaData = (values) => {
    dispatch({ type: CmsPageActions.UPDATE_SCHEMA_DATA, payload: values });
  };
  const checkSchemaData = (payload) => {
    dispatch({ type: CmsPageActions.CHECK_SCHEMA_DATA, payload });
  };
  const setPageName = (payload) => {
    dispatch({ type: CmsPageActions.SET_PAGE_NAME, payload });
  };
  const setPageSlugName = (payload) => {
    dispatch({ type: CmsPageActions.SET_PAGE_SLUG_NAME, payload });
  };
  const setPageDescription = (payload) => {
    dispatch({ type: CmsPageActions.SET_PAGE_DESCRIPTION, payload });
  };

  return {
    cmsPageState,
    clearCmsPageState,
    setSeoData,
    checkSeoData,
    setOgInfoData,
    checkOgInfoData,
    setTwitterInfoData,
    checkTwitterInfoData,
    setSchemaData,
    checkSchemaData,
    setPageName,
    setPageSlugName,
    setPageDescription
  };
}