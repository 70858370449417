import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";

const AutocompleteInput = ({
  label,
  type,
  value,
  submitStatus,
  required,
  id,
  disabled,
  onChangeParent,
  onChangeParentManual,
  listData,
  onBlur,
}) => {
  const [open, setOpen] = React.useState(false);
  const [defualtValue, setDefualtValue] = React.useState(value ? value : "");

  return (
    <Autocomplete
      freeSolo
      id="free-solo-2-demo"
      className="auto-select"
      // className="form-control"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, newValue) => {
        event.preventDefault();
        onChangeParent(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        onChangeParentManual(newInputValue);
        if (newInputValue && newInputValue.length > 2) {
          setOpen(true);
        } else {
          setOpen(false);
        }
        onChangeParent(newInputValue);
      }}
      disableClearable
      value={defualtValue}
      disabled={disabled}
      getOptionSelected={(option) => option.value === defualtValue}
      options={listData}
      getOptionLabel={(options) => {
        return options || (options !== undefined && options !== "")
          ? options.title !== undefined
            ? options.title ?? ""
            : options
          : "";
      }}
      renderInput={(params) => (
        <TextField
          id={id}
          {...params}
          label={label}
          margin="normal"
          onBlur={onBlur}
        />
      )}
    />
  );
};

export default AutocompleteInput;
