import {
  deleteCategory,
  deleteCategoryHierarchy,
  getCategoryList,
  updateCategoryHierarchy,
} from "api/category";
import { updateStatus } from "api/utils";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Nestable from "react-nestable";
import "react-nestable/dist/styles/index.css";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import Header from "../../../Components/Header/Header";
// import classNames from "classnames";
import LoadingScreen from "../../../Components/Loading/LoadingScreen";
// Components
import DeleteConfirmation from "../../../Components/Modal/Category/DeleteConfirmation";
import StatusConfirmation from "../../../Components/Modal/Category/StatusConfirmation";
import { AppContext } from "../../../Context/AppContext";
import ActivatedIcon from "./../../../Assets/images/activated.svg";
// import { Link } from "react-router-dom";
import AddIcon from "./../../../Assets/images/add.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";
import EditIcon from "./../../../Assets/images/edit.svg";
// import ReactTable from "../../../Components/Table/Table";
import Frame from "./../../../Assets/images/Frame 697@2x.svg";
import CloseIcon from "./../../../Assets/images/minus_1.svg";
import PlusIcon from "./../../../Assets/images/plus_1.svg";
import ViewProductList from "./../../../Assets/images/View_product_list_1.svg";
import { MODULES } from "./../../../Helpers/Constants";
//scss
// Context
import { getAccessByModuleName } from "./../../../Routes/Routes";
import styles from "./index.module.scss";

const CategoryManagement = (props) => {
  const history = useHistory();
  // const categoryService = new CategoryApi();
  // const utilsService = new UtilsApi();

  const { showToastMessage, modules, setIsLoading } = useContext(AppContext);
  // Add / Edit
  const [access, setAccess] = useState({});

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [pageCount, setPageCount] = React.useState(1);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState();
  const [deleteCategoryHierarchyId, setDeleteCategoryHierarchyId] = useState();
  const [statusCategoryId, setStatusCategoryId] = useState(false);
  const [statusOfCategory, setStatusOfCategory] = useState();
  const [statusCategoryHierarchyId, setStatusCategoryHierarchyId] =
    useState(false);
  const [statusOfCategoryHierarchy, setStatusOfCategoryHierarchy] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 50,
    sortBy: [
      {
        id: "createdAt",
        asc: "asc",
      },
    ],
    filters: [],
    globalFilter: "",
  });

  useEffect(getModuleAccess, [modules]);
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.CATEGORY);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  useEffect(() => {
    fetchData(defaultFilter);
  }, []);

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
    };

    let categoryAccess = getAccessByModuleName(modules, MODULES.CATEGORY);
    if (categoryAccess && categoryAccess.permissions.read_access) {
      // const response = await categoryService.getCategoryList(postData);
      const response = await getCategoryList(postData);
      if (response && response.status === 200) {
        let categoryData = response.data.data.rows;

        let finalCatData = await handleNestedCategoryData(categoryData);
        setData(finalCatData);

        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }
    setLoading(false);
  };

  const handleNestedCategoryData = async (catData) => {
    if (catData.length > 0) {
      catData.forEach((data, index) => {
        catData[index]["categoryId"] = data.id;
        catData[index]["id"] = data.category_hierarchies[0].id;
        if (catData[index].children.length > 0) {
          handleNestedCategoryData(catData[index].children);
        }
      });
    }
    return catData;
  };

  const handleManageCategoryClick = (
    event,
    type = "add",
    categoryHierarchyId = ""
  ) => {
    if (categoryHierarchyId !== "") {
      history.push(
        `/product-catalog/category-management/add-category-hierarchy/${categoryHierarchyId}`
      );
    } else {
      history.push("/product-catalog/category-management/add-category");
    }
  };

  const handleManageCategoryEditClick = (
    event,
    categoryId,
    categoryHierarchyId
  ) => {
    history.push(
      `/product-catalog/category-management/edit-category/${categoryId}/${categoryHierarchyId}`
    );
  };

  const handleManageProductSequence = (id) => {
    // console.log("id", id);
    history.push("/productcatalog/category-management/sequence/" + id);
  };

  const handleDeleteCategoryClick = async (event, id, categoryHierarchyId) => {
    event.preventDefault();
    setDeleteCategoryId(id);
    setDeleteCategoryHierarchyId(categoryHierarchyId);
    setShowDeleteModal(true);
  };

  //API FOR CATEGORY DELETE
  const handleDeleteCategory = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // const response = await categoryService.deleteCategory(deleteCategoryId);
    const response = await deleteCategory(deleteCategoryId);

    if (response && response.status === 200) {
      setDeleteCategoryId(null);
      setDeleteCategoryHierarchyId(null);
      setShowDeleteModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  //API FOR CATEGORY DELETE
  const handleDeleteCategoryHierarchy = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // const response = await categoryService.deleteCategoryHierarchy(
    //   deleteCategoryHierarchyId
    // );
    const response = await deleteCategoryHierarchy(deleteCategoryHierarchyId);

    if (response && response.status === 200) {
      setDeleteCategoryId(null);
      setDeleteCategoryHierarchyId(null);
      setShowDeleteModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const handleStatusCategoryClick = async (
    event,
    id,
    status,
    hierarchyId,
    hierarchyStatus
  ) => {
    event.preventDefault();

    setStatusCategoryId(id);
    setStatusOfCategory(status);
    setStatusCategoryHierarchyId(hierarchyId);
    setStatusOfCategoryHierarchy(hierarchyStatus);
    setShowStatusModal(true);
  };

  //API FOR CHANGE CATEGORY STATUS
  const handleCategoryStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfCategory === 1 ? 0 : 1,
      id: statusCategoryId,
      table: "categories",
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setStatusCategoryId(null);
      setStatusOfCategory(null);
      setShowStatusModal(false);
      setStatusCategoryHierarchyId(null);
      setStatusOfCategoryHierarchy(null);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  //API FOR CHANGE CATEGORY HIERARCHY STATUS
  const handleCategoryHierarchyStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfCategoryHierarchy === 1 ? 0 : 1,
      id: statusCategoryHierarchyId,
      table: "category_hierarchies",
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setStatusCategoryId(null);
      setStatusOfCategory(null);
      setStatusCategoryHierarchyId(null);
      setStatusOfCategoryHierarchy(null);
      setShowStatusModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  //API FOR CHANGE CATEGORY HIERARCHY
  const handleManageCategoryHierarchy = async (
    categoryHierarchyId,
    parentHierarchyId,
    itemData
  ) => {
    setIsLoading(true);

    let postData = {
      parent_hierarchy_id: parentHierarchyId,
    };

    // const response = await categoryService.updateCategoryHierarchy(
    //   categoryHierarchyId,
    //   postData
    // );
    const response = await updateCategoryHierarchy(
      categoryHierarchyId,
      postData
    );
    if (response && response.status === 200) {
      setData(itemData);
      showToastMessage(undefined, response.data.message, "success", false);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  // const items = [
  //   { id: 0, text: "Andy", children: [{ id: 4, text: "Andy1" }] },
  //   {
  //     id: 1,
  //     text: "Harry",
  //     children: [{ id: 2, text: "David" }],
  //   },
  //   { id: 3, text: "Lisa" },
  // ];

  const renderItem = ({ collapseIcon, depth, handler, index, item }) => (
    <div className={styles.GridTable}>
      <div
        className={
          "d-flex align-items-center justify-cotent-between cursor-pointer ml-3"
        }
      >
        {collapseIcon}
        {access && access.write_access && (
          <img
            style={{ width: "25px", height: "25px" }}
            src={Frame}
            alt="Move"
          />
        )}
      </div>
      {item.category_image !== "" ? (
        <div className={styles.ProducatImageWapper}>
          <img src={item.category_image} alt={item.title} />
        </div>
      ) : (
        <div className={styles.emptyImage}>
          <span>Image</span>
        </div>
      )}
      <div className={styles.Name}>{item.title}</div>
      <div className={styles.status}>
        {item.status === 1 ? (
          <div className={styles.Active}>Active</div>
        ) : (
          <div className={styles.Inactive}>Inactive </div>
        )}
      </div>
      <div className={styles.status}>
        {item.category_hierarchies[0].status === 1 ? (
          <div className={styles.Active}>Active</div>
        ) : (
          <div className={styles.Inactive}>Inactive </div>
        )}
      </div>
      <div className={styles.CreatedRow}>
        {moment(item.createdAt).format("ddd MMM DD, YYYY")}
      </div>
      <div className={styles.UpdateRow}>
        {moment(item.updatedAt).format("ddd MMM DD, YYYY")}
      </div>
      <div className={"d-flex align-items-center justify-content-between"}>
        {access && access.write_access && (
          <div>
            <span className={`link cursor-pointer ${styles.iconWrapper}`}>
              <img
                className={styles.icon}
                src={AddIcon}
                alt="Add"
                onClick={(e) => {
                  e.preventDefault();
                  handleManageCategoryClick(
                    e,
                    "Add",
                    item.category_hierarchies[0].id
                  );
                }}
              />
            </span>
          </div>
        )}
        {access && access.write_access && item.slug !== 'subscription-category' && item.slug !== 'custom-category' && (
          <img
            className={`cursor-pointer ml-2`}
            src={ActivatedIcon}
            alt="Active"
            onClick={(e) => {
              handleStatusCategoryClick(
                e,
                item.categoryId,
                item.status,
                item.category_hierarchies[0].id,
                item.category_hierarchies[0].status
              );
            }}
          />
        )}
        {access && access.delete_access && item.slug !== 'subscription-category' && item.slug !== 'custom-category' && (
          <img
            className={`cursor-pointer ml-2`}
            src={DeleteIcon}
            alt="Delete"
            onClick={(e) =>
              handleDeleteCategoryClick(
                e,
                item.categoryId,
                item.category_hierarchies[0].id
              )
            }
          />
        )}
        {access && access.write_access && (
          <img
            className={`cursor-pointer ml-2`}
            src={EditIcon}
            alt="Edit"
            onClick={(e) => {
              e.preventDefault();
              handleManageCategoryEditClick(
                e,
                item.categoryId,
                item.category_hierarchies[0].id
              );
            }}
          />
        )}
        {access && access.write_access && (
          <span className={`link cursor-pointer `}>
            <img
              className={`cursor-pointer`}
              src={ViewProductList}
              style={{
                height: "38px",
              }}
              alt="Add"
              onClick={(e) => {
                e.preventDefault();
                handleManageProductSequence(item.category_hierarchies[0].id);
              }}
            />
          </span>
        )}
      </div>
    </div>
  );
  // console.log(data);
  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <React.Fragment>
      <Header
        title="Category Management"
        hasAddButton={access.write_access}
        handleAddClick={handleManageCategoryClick}
      />
      <div id="MainContent" className="main-content" />
      <div className={styles.GridTable}>
        <div className={styles.MoveButton}></div>
        <div className={styles.header}>Image</div>
        <div className={styles.header}>Name</div>
        <div className={styles.header}>Category Status</div>
        <div className={styles.header}>Hierarchy Status</div>
        <div className={styles.header}>Created At</div>
        <div className={styles.header}>Updated At</div>
        <div className={styles.Actions}></div>
      </div>
      {data.length !== 0 ? (
        <Nestable
          renderCollapseIcon={(props) => (
            <img
              className="cursor-pointer mr-2"
              style={{ width: "25px", height: "25px" }}
              src={props.isCollapsed ? PlusIcon : CloseIcon}
              alt="Collapse"
            />
          )}
          className={styles.NestedGroup}
          items={data}
          renderItem={renderItem}
          onChange={(args) => {
            let countArr = args.targetPath.length;
            let itemData = args.items;
            let parentHierarchyId = null;
            let categoryHierarchyId = args.dragItem.id;
            let parentHierarchyData = args.items;

            if (countArr > 1) {
              for (let index = 0; index < countArr; index++) {
                if (
                  parentHierarchyData[args.targetPath[index]]?.id !==
                  categoryHierarchyId
                ) {
                  parentHierarchyId =
                    parentHierarchyData[args.targetPath[index]]?.id;
                  parentHierarchyData =
                    parentHierarchyData[args.targetPath[index]]?.children;
                }
              }
            }

            handleManageCategoryHierarchy(
              categoryHierarchyId,
              parentHierarchyId,
              itemData
            );
          }}
        />
      ) : (
        "No data found"
      )}

      <StatusConfirmation
        showModal={showStatusModal}
        // title={statusOfCategory ? "InActive Category" : "Active Category"}
        title="Activate/Inactivate Category or Category Hierarchy "
        // message={
        //   statusOfCategory
        //     ? "Are you sure want to inactive this category?"
        //     : "Are you sure want to active this category?"
        // }
        message="Are you sure want to activate/inactivate this category or category hierarchy?"
        buttonLabel={
          statusOfCategory ? "Inactivate category" : "Activate category"
        }
        buttonLabel1={
          statusOfCategoryHierarchy
            ? "Inactivate category hierarchy"
            : "Activate category hierarchy"
        }
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowStatusModal(false);
          setStatusCategoryId(null);
          setStatusOfCategory(null);
          setStatusCategoryHierarchyId(null);
          setStatusOfCategoryHierarchy(null);
        }}
        handleSubmitClick={handleCategoryStatus}
        handleSubmitClick1={handleCategoryHierarchyStatus}
      />

      <DeleteConfirmation
        showModal={showDeleteModal}
        title="Delete Category Or Category Hierarchy"
        message="Are you sure want to delete this category or category hierarchy?"
        buttonLabel="Delete Category"
        buttonLabel1="Delete Category Hierarchy"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteModal(false);
          setDeleteCategoryId(null);
          setDeleteCategoryHierarchyId(null);
        }}
        handleSubmitClick={handleDeleteCategory}
        handleSubmitClick1={handleDeleteCategoryHierarchy}
      />
    </React.Fragment>
  );
};

export default withRouter(CategoryManagement);
