import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import ReactDiffViewer from "react-diff-viewer";
import stringify from "json-stable-stringify";

import CardContent from "@material-ui/core/CardContent";

// Components
import Header from "../../../Components/Header/Header";

//scss
import classes from "./index.module.scss";

// Context
import { getAccessByModuleName } from "../../../Routes/Routes";
import { AppContext } from "../../../Context/AppContext";
import { MODULES } from "../../../Helpers/Constants";

// services
import { getAuditTrailReportDetail } from "../../../api/auditTrail";

const AddPages = (props) => {
  const { showToastMessage, modules } = useContext(AppContext);

  // const [access, setAccess] = useState({});
  const [oldValueData, setOldValueData] = useState("");
  const [newValueData, setNewValueData] = useState("");

  useEffect(() => {
    const { reportId } = props.match.params;
    getReportDetail(reportId);
  }, []);

  const getReportDetail = async (id) => {
    const response = await getAuditTrailReportDetail(id);

    if (response && response.status === 200) {
      let tempResponseDataUser = { ...response.data.data };
      if (
        tempResponseDataUser.old_value &&
        tempResponseDataUser.old_value !== ""
      ) {
        let oldDataParse = JSON.parse(tempResponseDataUser.old_value);
        let oldDataSerialize = JSON.parse(stringify(oldDataParse));
        let formatDataOld = await jsonFormat(oldDataSerialize);
        setOldValueData(formatDataOld.props.children);
      }

      if (
        tempResponseDataUser.new_value &&
        tempResponseDataUser.new_value !== ""
      ) {
        let newDataParse = JSON.parse(tempResponseDataUser.new_value);
        let newDataSerialize = JSON.parse(stringify(newDataParse));
        let formatDataNew = await jsonFormat(newDataSerialize);
        setNewValueData(formatDataNew.props.children);
      }
    } else showToastMessage(undefined, response.data.message, "error", false);
  };

  const jsonFormat = async (data) => {
    return <pre>{JSON.stringify(data, null, 2)}</pre>;
  };

  //   const oldCode = `
  // const a = 10
  // const b = 10
  // const c = () => console.log('foo')

  // if(a > 10) {
  //   console.log('bar')
  // }

  // console.log('done')
  // `;
  //   const newCode = `
  // const a = 10
  // const boo = 10

  // if(a === 10) {
  //   console.log('bar')
  // }
  // `;
  useEffect(getModuleAccess, [modules]);
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.AUDIT_TRAIL_LOG_REPORT);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/users");
    }
  }
  let pageAccess = getAccessByModuleName(modules, MODULES.AUDIT_TRAIL_LOG_REPORT);
  

  return (
    <React.Fragment>
      <Header title="Audit Trail Detail" />
      <div className="row">
        {/* <div id=""> */}

        <div className={`${classes.cardBody}`}>
          <CardContent>
            <ReactDiffViewer
              oldValue={oldValueData}
              newValue={newValueData}
              // oldValue={oldCode}
              // newValue={newCode}
              splitView={true}
              useDarkTheme={false}
              rightTitle={"New Data"}
              leftTitle={"Old Data"}
              hideLineNumbers={false}
              linesOffset={0}
              disableWordDiff={true}
              showDiffOnly={false}
            />
          </CardContent>
        </div>
        {/* </div> */}
      </div>
    </React.Fragment>
  );
};
export default withRouter(AddPages);
