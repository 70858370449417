import Interceptors from './../Interceptors/Interceptors';

class Users {
    constructor() {
        this.interceptors = new Interceptors();
    }

    async login(data) {
        return this.interceptors.POST('users/login', data);
    }

    async logout() {
        return this.interceptors.GET('users/logout');
    }

    async forgotPassword(data) {
        return this.interceptors.POST('users/forgot-password', data);
    }

    async getUserLookup() {
        return this.interceptors.GET('users/lookup');
    }

    async resetPassword(data) {
        return this.interceptors.POST('users/reset-password', data);
    }

    async getProfile() {
        return this.interceptors.GET('users/profile');
    }

    async getUserList(data) {
        return this.interceptors.POST('users/admin', data);
    }

    async updateProfile(data) {
        return this.interceptors.PUT('users', data);
    }

    async uploadProfile(data) {
        return this.interceptors.PUT_FILE('users/upload-profile', data);
    }

    async changePassword(data) {
        return this.interceptors.POST('users/change-password', data);
    }

    async getCountries() {
        return this.interceptors.GET('utils/countries');
    }

    async getStates(id) {
        return this.interceptors.GET('utils/states/' + id);
    }

    async getRoles() {
        return this.interceptors.POST('roles/list');
    }

    async add(data) {
        return this.interceptors.POST('users', data);
    }

    async update(data) {
        return this.interceptors.PUT('users', data);
    }

    async updateUser(data) {
        return this.interceptors.PUT('users/edit', data);
    }

    async delete(id) {
        return this.interceptors.DELETE('users/' + id);
    }

    async verifyUsers(data) {
        return this.interceptors.POST('users/verify', data);
    }

    async getTimezones() {
        return this.interceptors.GET('utils/timezone');
    }

    async getDesigners(data) {
        return this.interceptors.POST('users/designer-lookup', data);
    }
}

export default Users;