import { useFormik } from "formik";

export function FeaturedCategoryForm(
  submit, 
  initialVal, 
  cb,
  checkFeaturedCategoryData
) {
  const validation = (values) => {
    // console.log('FeaturedCategoryForm validation');
    let errors = {};
    let featuredCategoryError = false;

    if (!values.name) {
      errors.name = "Name required";
      featuredCategoryError = true;
    }

    // if (!values.sequence_number) {
    //   errors.sequence_number = "Sequence number required";
    //   featuredCategoryError = true;
    // }

    // if (!values.button_label) {
    //   errors.button_label = "Button label required";
    //   featuredCategoryError = true;
    // }

    // if (!values.button_url) {
    //   errors.button_url = "Button url required";
    //   featuredCategoryError = true;
    // }

    // console.log('featuredCategoryError ===>', errors, featuredCategoryError);
    checkFeaturedCategoryData(featuredCategoryError);
    if (cb) {
      cb(values);
    }
    return errors;
  };

  const initialValues = {
    id: 0,
    parent_id: 0,
    module: 'home-page',
    name: "",
    sequence_number: 1,
    button_label: "",
    button_url: "",
    description: "",
    children: [],
    products: [],
  };

  const formik = useFormik({
    initialValues: initialVal || initialValues,
    onSubmit: submit,
    validate: validation,
    enableReinitialize: true,
  });
  return { formik };
}
