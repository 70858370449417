
import Interceptors from '../Interceptors/Interceptors';

class HomePage {
    constructor() {
        this.interceptors = new Interceptors();
    }

    async getHomePage() {
        return this.interceptors.GET(`home-page`);
    }

    async updateHomePage(data) {
        return this.interceptors.PUT(`home-page/update`, data);
    }
}

export default HomePage;