import React, { useContext, useState } from "react";
import { withRouter } from 'react-router';
// Components
import Text from "../../../Components/FormControl/Text/TextInput";
import Select from "./../../../Components/FormControl/Select/Select"
// import RadioBox from "../../../Components/FormControl/RadioBox/RadioBox";
// Context
import { AppContext } from "../../../Context/AppContext";

// Services
import CustomersApi from "../../../Services/Customers/Customers";
import { useEffect } from "react";
import { updateCustomerPointBalance } from "api/customer";

//scss
// import styles from "./index.module.scss";

const PetalRewardsForm = (props) => {
  const { showToastMessage, setIsLoading } = useContext(AppContext);
  const [submitStatus, setSubmitStatus] = useState(false);
 
  const [action,setAction] = useState(1);
  const [point,setPoint] = useState("");
  const [pointValue,setPointValue] = useState(point);
  const [error,setError] = useState("")

  useEffect(()=>{
    //if admin select add points so pointValue should be positive otherwise pointValue should be negative
    if(action){
     setPointValue(point)
    }else{
      setPointValue(-point)
    }
  },[action,point])

  const handleManagePetalRewards = async (event) => {
    event.preventDefault();
    setSubmitStatus(true);

      if (!point && point==="" || !(parseInt(point)>0)) {
        event.preventDefault();
        setError("Points should be greater than 0")
      }else if(point % 1 != 0){
        setError("Points should be in integer format")
      }else if(!action && point > props?.userDetails?.customer.petal_rewards[0]?.total_points){
        setError("Subtracting more than the total points is not allowed")
      }else {
        setError("")

        let reqBody = {
          customer_email: props?.userDetails?.customer?.email,
          point_adjustment_amount: parseInt(pointValue)
        };

        setIsLoading(true)
        const response = await updateCustomerPointBalance(reqBody);
        if (response && response.status === 200) {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "success", false);
          await props.onSubmitClick(true)
          setAction(1)
        } else {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "error", false);
        } 
        setPoint(" ")

      }
  };



  return (
    <React.Fragment>
      <div className="row mt-4 mb-4">
        <div className="col-md-2 col-xs-12">
          <Text
              type="text"
              label="Customer"
              value={props?.userDetails?.customer?.first_name}
              disabled={true}
          />
        </div>
        <div className="col-md-2 col-xs-12">
          <Text
              type="text"
              label="Total Points"
              value={props?.userDetails?.customer.petal_rewards[0]?.total_points && props?.userDetails?.customer.petal_rewards[0]?.total_points > 0 ? props?.userDetails?.customer.petal_rewards[0]?.total_points : "0"}
              disabled={true}
          />
        </div>
        <div className="col-md-2 col-xs-12">
          <Select
              label="Action"
              parentClass="text-left"
              value={action}
              required={false}
              submitStatus={submitStatus}
              onChange={(data) => setAction(data)}
              options={[
                { id: 1, title: "Add Points" },
                { id: 0, title: "Subtract Points" },
              ]}
              disabled={props?.userDetails?.customer?.is_walkin_customer ? true : false}
            />
        </div>
        <div className="col-md-2 col-xs-12">
          <Text
              type="number"
              label= {action ? "Add Points" : "Subtract Points"}
              value={point}
              required={true}
              submitStatus={submitStatus}
              onChange={(data) =>setPoint(data)}
              touched={true}
              error={error}
              disabled={props?.userDetails?.customer?.is_walkin_customer ? true : false}
          />
        </div>
        

        <div className="col-md-2 col-xs-12">          
          {/* <div className="row">
            <div className={`col-md-12 col-xs-12`}>
              <label class="bmd-label-floating">Points Notifiaction</label>
            </div>
            <div className={`col-md-12 col-xs-12`}>
              <RadioBox
                label="Yes"
                labelClass={styles.petalRewardsFormRadioLabel}
                value={1}
                selectedValue={isPointsNotifiaction}
                required={true}
                onChange={(data) => {setIsPointsNotifiaction(+data)}}
              />
              <RadioBox
                label="No"
                labelClass={styles.petalRewardsFormRadioLabel}
                value={0}
                selectedValue={isPointsNotifiaction}
                required={true}
                onChange={(data) => {setIsPointsNotifiaction(+data)}}
              />
            </div>
          </div> */}
        </div>
        {!props?.userDetails?.customer?.is_walkin_customer && 
          <div className="col-md-2 col-xs-12">
            <a 
              href="#" 
              data-dismiss="modal" 
              onClick={handleManagePetalRewards}
              className="btn gray-btn d-inline-block mb-3 mr-2">
              Save
            </a>
          </div>
        }
      </div>
    </React.Fragment>
  );
};

export default withRouter(PetalRewardsForm);
