import { Drawer, InputBase, NativeSelect } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "../../Assets/images/closeDrawer.svg";
import DropdownIcon from "../../Assets/images/dropdown.svg";
import { useOrder } from "../../Context/OrderContext/useOrder";
import { useProduct } from "../../Context/Product/useProduct";
// Services
// import ProductsApi from "../../Services/Products/Products";
import { getMenusNew, getProductDetail } from "../../api/product";
// Context
import { AppContext } from "./../../Context/AppContext";
import ProductCard from "./ProductCard";
// SCSS
import styles from "./ProductsDrawer.module.scss";
import ProductVariant from "./ProductVariant";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Link from "@material-ui/core/Link";
// import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";
import NewModal from "../NewModal/NewModal";

function ProductsDrawer(props) {
  const scrollProduct = React.useRef();
  const { addProductRecipent, orderState, celarDiliveryData } = useOrder();
  const { showToastMessage } = useContext(AppContext);
  const [showMenuDrawer, setShowMenuDrawer] = useState(false);
  const [showMenuDrawerID, setShowMenuDrawerID] = useState(0);
  const [indrawer, setIndrawer] = useState(false);
  const [setinsideLink, seSetinsideLink] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menusList, setMenusList] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [menusChildList, setMenusChildList] = useState([]);
  const [productVariation, setProductVariation] = useState([]);
  // const productsService = new ProductsApi();
  const [bacremb, setBacremb] = useState([]);
  const [catImage, setCatimage] = useState("");
  const [selectedArray, setSelectedArray] = useState(
    orderState.recipents[orderState.activeRecipentIndex].added_product
  );
  // const [offset, setOffset] = useState(0);
  const [detailOffset, setDetailOffset] = useState(0);

  const {
    productState,
    setSelectedProduct,
    clearSelectedProduct,
    clearCurrentAdd,
  } = useProduct();

  useEffect(() => {
    (async () => {
      await handleGetMenus();
      await props.handleGetProducts();
    })();
  }, []);
  const handleSelected = async (data) => {
    let product_variations = data?.product_variations;
    if (data) {
      product_variations.forEach((variationdata, index) => {
        let selectedIndex = orderState.recipents[
          orderState.activeRecipentIndex
        ].added_product.findIndex(
          (productdata) => productdata.selectedVariant.id === variationdata.id
        );
        if (selectedIndex !== -1) {
          product_variations[index]["isSelected"] = true;
        } else {
          product_variations[index]["isSelected"] = false;
        }
      });
      product_variations.forEach((variationselecteddata, index) => {
        let selectedVarianttep = selectedArray.findIndex(
          (variationtempdata) =>
            variationtempdata.selectedVariant.id === variationselecteddata.id
        );
        if (selectedVarianttep !== -1) {
          product_variations[index]["isSelected"] = true;
        } else {
          product_variations[index]["isSelected"] = false;
        }
      });
      console.log("product_variations", product_variations);
      setProductVariation(product_variations);
    }
  };
  const handleGetMenus = async () => {
    // const response = await productsService.getMenus();
    let postData = {
      menuFor: "admin",
      position: "TOP",
    };
    const response = await getMenusNew(postData);

    if (response && response.status === 200) {
      setMenusList(response.data.data);
    } else showToastMessage(undefined, response.data.message, "error", false);
  };

  const handleGetMenusChild = async (id) => {
    let menusChildData = menusList.filter((menu) => menu.id === id);
    setMenusChildList(
      menusChildData.length ? menusChildData[0]["children"] : []
    );
    setSelectedMenu(menusChildData.length ? menusChildData[0] : "");
    if (menusChildData.length && menusChildData[0]["children"].length) {
      if (showMenuDrawerID === id) {
        setShowMenuDrawerID(0);
        setShowMenuDrawer(!showMenuDrawer);
      } else {
        setShowMenuDrawerID(id);
        setShowMenuDrawer(true);
      }
    } else {
      setShowMenuDrawerID(id);
      setShowMenuDrawer(false);
      seSetinsideLink(false);
      setIndrawer(false);
    }
  };
  const handleProductClick = async (event, item) => {
    handlePopupClose();
    // let targetEl = event.currentTarget;
    // setAnchorEl(targetEl);

    // const response = await productsService.getProductDetail(item.id);
    const response = await getProductDetail(item.id);
    if (response && response.status === 200) {
      setSelectedProduct(response.data.data);
      handleSelected(response.data.data);
      // setDetailOffset(targetEl.offsetTop + targetEl.offsetHeight);
    } else showToastMessage(undefined, response.data.message, "error", false);
  };
  const handlePopupClose = () => {
    setAnchorEl(null);
    setDetailOffset(0);
    clearSelectedProduct();
    clearCurrentAdd();
    setAnchorEl(null);
  };

  // const hndleMenuclose = () => {
  //   setAnchorEl(null);
  //   setDetailOffset(0);
  //   setAnchorEl(null);
  // };
  // let settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  const addProductAction = () => {
    let leadtime = 0;
    productState.selectedProductArray.forEach((productData) => {
      if (productData.lead_time > leadtime) {
        leadtime = productData.lead_time;
      }
    });
    if (leadtime > 0) {
      celarDiliveryData(leadtime);
    }
    addProductRecipent(productState.selectedProductArray);
    clearCurrentAdd();
    props.onClose();
    props.openAddProductDrawer(false);
  };
  const handlePriceList = (e) => {
    props.setPrice({
      value: e.target.value,
      label: e.target.value === "aec" ? "Low to Hign" : "High to Low",
    });
    props.handleProductSort(e.target.value);
  };
  const handleManageMenuProduct = async (urlSlug, data) => {
    props.handleManageMenuProduct(urlSlug);
    setBacremb(data);
  };
  const handleAddProduct = async (e, item) => {
    let selectedProduct = { ...item };
    props.addProductCms(selectedProduct);
  };

  const variantDrawerStyle = {
    marginTop: detailOffset,
  };
  useEffect(() => {
    if (indrawer && !setinsideLink) {
      setShowMenuDrawer(true);
    }
    if (!indrawer && setinsideLink) {
      setShowMenuDrawer(true);
    }
    if (!indrawer && !setinsideLink) {
      setShowMenuDrawer(false);
    }
    if (indrawer && setinsideLink) {
      // setShowMenuDrawer(false);
    }
  }, [indrawer, setinsideLink]);

  const RenderItem = ({ item, index }) => {
    let isselected = false;
    if (props.isVarianNeeded) {
      isselected = !!productState?.setCurrentProductToAdd?.find(
        (c) => c.id === item.id
      );
    } else {
      isselected = item.isSelected;
    }
    return (
      <div
        className={`${styles.colSection} col-md-4 col-lg-3 col-xl-2 d-flex flex-column position-relative`}
        key={item.id}
        aria-describedby={item.id}
        onClick={(e) => {
          if (!props.isVarianNeeded) {
            handleAddProduct(e, item);
          } else {
            if (item.is_out_of_stock) {
              showToastMessage(
                undefined,
                `Product ${item.title} is currently out of stock`,
                "error",
                false
              );
            } else if (item.is_sold_out) {
              showToastMessage(
                undefined,
                `Product ${item.title} is currently Sold out`,
                "error",
                false
              );
            } else if (item.inventory_remaining === 0) {
              showToastMessage(
                undefined,
                `Product ${item.title} is currently out of stock`,
                "error",
                false
              );
            } else if (
              item.lead_time > 0 &&
              orderState.order.delivery_date !== ""
            ) {
              let a = moment(orderState.order.delivery_date);
              let b = moment().format("YYYY-MM-DD");
              let diffDays = a.diff(b, "days");
              if (diffDays < item.lead_time) {
                showToastMessage(
                  undefined,
                  `Product ${item.title} is currently not avalabile on selected date`,
                  "error",
                  false
                );
              }
            } else {
              handleProductClick(e, item);
            }
          }
        }}
      >
        <ProductCard
          item={{ ...item, isSelected: isselected }}
          key={index}
          isVarianNeeded={props.isVarianNeeded}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <Drawer
        anchor={"bottom"}
        open={props.open}
        onClose={props.onClose}
        classes={{ paper: styles.drawerWrapper }}
      >
        <div className={styles.drawerInner} id="productDrawerInner">
          <div
            className={`ml-auto cursor-pointer ${styles.closeIconWrapper}`}
            onClick={handlePopupClose}
          >
            <img
              src={CloseIcon}
              className={styles.closeIcon}
              onClick={props.onClose}
              alt=""
            />
          </div>
          <div className={styles.header}>
            <div className={styles.title}>{"Select Products"}</div>
            <div
              className={`${styles.top} `}
              // onMouseLeave={() => {
              //   setTimeout(() => {
              //     setIndrawer(false);
              //     console.log("Call from onMouseLeave menu drawer");
              //   }, 100);

              //   // setShowMenuDrawer(false);
              // }}
            >
              <div
                className={`${styles.link} cursor-pointer`}
                onClick={() => {
                  props.handleEligeble();
                  setBacremb([]);
                }}
                onMouseEnter={() => {
                  setShowMenuDrawer(false);
                }}
              >
                {"Commission Eligible"}
              </div>
              {menusList.map((item, index) => (
                <div
                  className={`${styles.link} ${
                    showMenuDrawerID === item.id && styles.activeLink
                  } cursor-pointer`}
                  key={index}
                  // onMouseEnter={() => {
                  //   handleGetMenusChild(item.id);
                  //   seSetinsideLink(true);
                  //   console.log("Call from onMouseEnter menu title");
                  //   // setTimeout(() => {
                  //   //   seSetinsideLink(true);
                  //   // }, 100);
                  // }}
                  onMouseLeave={() => {
                    // setTimeout(() => {
                    seSetinsideLink(false);
                    console.log("Call from onMouseLeave menu title");
                    // }, 100);
                  }}
                  onClick={() => {
                    seSetinsideLink(true);
                    handleGetMenusChild(item.id);
                    let productLink = item.slug.split("/");
                    let bacrambdata = [];
                    bacrambdata.push({
                      title: item.title,
                      slug: item.slug,
                    });
                    handleManageMenuProduct(productLink, bacrambdata);
                  }}
                >
                  {item.title}
                  {item.children.length ? (
                    <img
                      src={DropdownIcon}
                      className={styles.dropDownArrow}
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                </div>
              ))}
              {/* <ClickAwayListener onClickAway={hndleMenuclose}> */}

              {/* </ClickAwayListener> */}
            </div>
            <Drawer
              id="Menu"
              // anchor={"top"}
              // onClick={() => setShowMenuDrawer(!showMenuDrawer)}
              variant={"persistent"}
              open={showMenuDrawer}
              style={{}}
              className={`menuDrawerStyle`}
              // onMouseLeave={() => {
              //   setTimeout(() => {
              //     setIndrawer(false);
              //     console.log("Call from onMouseLeave menu drawer");
              //   }, 100);

              //   // setShowMenuDrawer(false);
              // }}
              // onMouseEnter={() => {
              //   // setTimeout(() => {
              //   setIndrawer(true);
              //   console.log("Call from onMouseEnter menu drawer");
              //   // }, 200);
              // }}
              // onMouseOut={() => {
              //   // setShowMenuDrawer(false);
              // }}
              // onClose={() => {
              //   // setShowMenuDrawer(false);
              // }}
              classes={{ paper: styles.dropDownDrawer }}
              // onBlur={() => {
              //   // setShowMenuDrawer(false);
              // }}
              // BackdropProps={{ invisible: true }}
              // PaperProps={{ style: variantDrawerStyle }}
            >
              <div
                onMouseEnter={() => {
                  // setTimeout(() => {
                  setIndrawer(true);
                  console.log("Call from onMouseEnter menu drawer");
                  // }, 200);
                }}
                onMouseLeave={() => {
                  setTimeout(() => {
                    setIndrawer(false);
                    console.log("Call from onMouseLeave menu drawer");
                  }, 100);

                  // setShowMenuDrawer(false);
                }}
                className={`${styles.list} row`}
                id="list"
              >
                {menusChildList.map((childItem, childIndex) => {
                  return childItem.title.toLowerCase() === "colors" ? (
                    <div className={`${styles.colors} col-2`} key={childIndex}>
                      <div className={styles.listTitle}>{childItem.title}</div>
                      {childItem.children.length &&
                        childItem.children.map(
                          (subChildItem, subChildIndex) => {
                            let subChildTitle = subChildItem.title.toLowerCase();
                            let border = subChildTitle.includes("white");
                            let colorCode = "transparent";

                            if (subChildTitle.includes("white")) {
                              colorCode = "white";
                            } else if (subChildTitle.includes("pink")) {
                              colorCode = "pink";
                            } else if (subChildTitle.includes("yellow")) {
                              colorCode = "yellow";
                            } else if (subChildTitle.includes("purple")) {
                              colorCode = "purple";
                            } else if (subChildTitle.includes("red")) {
                              colorCode = "red";
                            } else if (subChildTitle.includes("blue")) {
                              colorCode = "blue";
                            }
                            return (
                              <div
                                className={styles.section}
                                key={subChildIndex}
                              >
                                <div
                                  className={`${styles.colorCode} ${
                                    border && "border"
                                  }`}
                                  style={{
                                    backgroundColor: `${colorCode}`,
                                  }}
                                />
                                <div
                                  className={styles.colorLink}
                                  key={subChildIndex}
                                  onMouseEnter={() => {
                                    setCatimage(
                                      subChildItem.category?.category_image ??
                                        catImage
                                    );
                                  }}
                                  onClick={() => {
                                    if (subChildItem.category === null) {
                                      // setShowMenuDrawer(false);
                                    } else {
                                      let productLink = subChildItem.category.slug.split(
                                        "/"
                                      );
                                      let bacrambdata = [];
                                      bacrambdata.push({
                                        title: selectedMenu.title,
                                        slug: selectedMenu.slug,
                                      });
                                      bacrambdata.push({
                                        title: subChildItem.title,
                                        slug: subChildItem.slug,
                                      });
                                      handleManageMenuProduct(
                                        productLink,
                                        bacrambdata
                                      );
                                      // setShowMenuDrawer(false);
                                    }
                                  }}
                                >
                                  {subChildItem.title}
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  ) : (
                    <div className={`${styles.productMenuChild} col-2`}>
                      <div className={styles.listTitle}>{childItem.title}</div>
                      {childItem.children.length &&
                        childItem.children.map(
                          (subChildItem, subChildIndex) => {
                            return (
                              <div
                                className={styles.productMenuChildLink}
                                key={subChildIndex}
                                onMouseEnter={() => {
                                  setCatimage(
                                    subChildItem.category?.category_image ??
                                      catImage
                                  );
                                }}
                                onClick={() => {
                                  if (subChildItem.category === null) {
                                    // setShowMenuDrawer(false);
                                  } else {
                                    let productLink = subChildItem.category.slug.split(
                                      "/"
                                    );
                                    let bacrambdata = [];
                                    bacrambdata.push({
                                      title: selectedMenu.title,
                                      slug: selectedMenu.slug,
                                    });
                                    bacrambdata.push({
                                      title: subChildItem.title,
                                      slug: subChildItem.slug,
                                    });
                                    handleManageMenuProduct(
                                      productLink,
                                      bacrambdata
                                    );
                                    // setShowMenuDrawer(false);
                                  }
                                }}
                              >
                                {subChildItem.title}
                              </div>
                            );
                          }
                        )}
                    </div>
                  );
                })}
                {selectedMenu ? (
                  <img
                    src={
                      catImage === ""
                        ? selectedMenu.category?.category_image
                        : catImage
                    }
                    className={`${styles.drawerImage} col`}
                    alt=""
                  />
                ) : (
                  ""
                )}
              </div>
            </Drawer>
          </div>
          <div className={`${styles.container} container-fluid`}>
            <div className={styles.topSection}>
              {bacremb?.length > 0 &&
                bacremb.map((data, index) => {
                  return (
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      separator={<NavigateNextIcon fontSize="small" />}
                      key={index}
                    >
                      <Link
                        aria-current={index < bacremb.length - 1 && "page"}
                        onClick={() => {
                          let productSlug = data.slug.split("/");
                          let bacrambdata = [];
                          for (let q = 0; q <= productSlug.length - 1; q++) {
                            bacrambdata.push(bacremb[q]);
                          }
                          handleManageMenuProduct(productSlug, bacrambdata);
                        }}
                        className={`cursor-pointer`}
                      >
                        <div className={styles.parent}>{data?.title ?? ""}</div>
                      </Link>
                      {bacremb.length > 0 &&
                        bacremb[1] !== undefined &&
                        index < bacremb.length - 1 && (
                          <NavigateNextIcon
                            fontSize="small"
                            className={`d-none`}
                          />
                        )}
                    </Breadcrumbs>
                  );
                })}
              {/* <div className={styles.parent}>{"Plants"}</div>

          <div className={styles.child}>{"Daisies"}</div> */}
              <div className={styles.search}>
                <InputBase
                  placeholder="Search Product..."
                  classes={{ root: styles.root }}
                  endAdornment={<div className={styles.adornedEnd} />}
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      props.handleProductSearch(e.target.value);
                    } else if (e.target.value === "") {
                      props.handleGetProducts();
                    }
                  }}
                />
              </div>
              <div className="d-flex align-items-center">
                <div className={styles.sortBy}>{"Sort By"}</div>
                <NativeSelect
                  value={props.price.value}
                  onChange={(e) => {
                    handlePriceList(e);
                  }}
                  placeholder="Price"
                  name="age"
                  inputProps={{ "aria-label": "Price" }}
                >
                  <option value={"aec"}>Low to High</option>
                  <option value={"dec"}>High to Low</option>
                </NativeSelect>
              </div>
            </div>
          </div>
          <div
            className={`container-fluid ${styles.listContainer}`}
            // style={{ overflow: "auto" }}
            ref={scrollProduct}
            // ref={(ref) => (this.scrollParentRef = ref)}
          >
            {/* <InfiniteScroll
              // pageStart={0}
              // loadMore={props.scrollTimeProductsCall}
              next={props.scrollTimeProductsCall}
              hasMore={props.data.length < props.count}
              loader={
                <div className="loader" key={0}>
                  {" "}
                </div>
              }
              scrollableTarget="productDrawerInner"
              dataLength={props.data.length}
              // useWindow={true}
              // getScrollParent={() => scrollProduct.current}
              // threshold={1000}
            > */}
            <div className={`${styles.rowSection} row m-md-0`}>
              {props.data.length > 0 ? (
                props.data.map((item, index) => (
                  <RenderItem item={item} index={index} />
                  // <div></div>
                ))
              ) : (
                <div className={`text-center col-12`}>
                  <h3>No data found</h3>
                </div>
              )}
              {props.data.length < props.count && (
                <div
                  className={`col-md-12 col-lg-12 col-xl-12 d-flex flex-column position-relative`}
                >
                  <p
                    onClick={() => {
                      props.scrollTimeProductsCall();
                    }}
                    style={{
                      color: "#7a7a7a",
                      fontSize: "18px",
                      cursor: "pointer",
                      textDecoration: "underline",
                      textAlign: "center",
                    }}
                  >
                    Load more..
                  </p>
                </div>
              )}
              {/* <ClickAwayListener onClickAway={handlePopupClose}> */}
              {/* <Drawer
                  anchor={"top"}
                  variant={"persistent"}
                  open={productState.selectedProduct !== null}
                  onClose={handlePopupClose}
                  classes={{ paper: styles.variantWrapper }}
                  BackdropProps={{ invisible: true }}
                  PaperProps={{ style: variantDrawerStyle }}
                >
                 
                </Drawer> */}
              <NewModal show={productState.selectedProduct !== null}>
                <div
                  className={styles.popoverContent}
                  style={{ height: "100%" }}
                >
                  <img
                    onClick={() => {
                      setSelectedProduct(null);
                    }}
                    src={CloseIcon}
                    alt={"Close Icon"}
                    style={{
                      position: "fixed",
                      top: "25px",
                      right: "25px",
                      cursor: "pointer",
                    }}
                  />
                  {productVariation?.slice(0, 5).map((item, index) => {
                    // console.log("Item", item);
                    return (
                      <ProductVariant
                        {...item}
                        key={index}
                        setProductVariation={setProductVariation}
                        selectedArray={selectedArray}
                        setSelectedArray={setSelectedArray}
                      />
                    );
                  })}
                </div>
              </NewModal>
              {/* </ClickAwayListener> */}
            </div>
            {/* </InfiniteScroll> */}
          </div>
        </div>
        <div className={`px-sm-1 py-3 ${styles.productAddBtnSection}`}>
          <button
            className="btn gray-btn"
            onClick={() => {
              if (!props.isVarianNeeded) {
                props.handleAddProductSubmitCms();
                props.openAddProductDrawer(false);
              } else {
                addProductAction();
              }
            }}
          >
            {" "}
            Add{" "}
          </button>
        </div>
      </Drawer>
    </React.Fragment>
  );
}

export default ProductsDrawer;

//  Old code
