import React, { useEffect, useReducer } from "react";
import orderReducer from "./orderReducer";
import { orderState } from "./orderState";

export const OrderContext = React.createContext({
  orderState: orderState,
  dispatch: () => null,
});

const OrderProvider = (props) => {
  const [state, dispatch] = useReducer(orderReducer, orderState);

  // useEffect(() => {
  //   console.log("Order contenxt", state.billing_data);
  // }, [state.billing_data]);

  return (
    <OrderContext.Provider value={{ orderState: state, dispatch }}>
      {props.children}
    </OrderContext.Provider>
  );
};

export default OrderProvider;
