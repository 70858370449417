import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import styles from "./OrderhistoryCard.module.scss";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

export default function OrderHistoryCard(props) {
  const priority =
    props.data.orderStatus === "cancel" || props.data.refundAmount > 0
      ? styles.red
      : props.data.price > 300
      ? styles.gold
      : styles.default;
  return (
    <Card className={` ${styles.OrderHistoryCard} ${priority}`}>
      <CardContent className={`${styles.content}`}>
        <div>
          <p className={`${styles.CustomerName}`}>{props.data.name}</p>
        </div>
        <div>
          <p className={`${styles.OrderDate}`}>
            {props.data.date && props.data.date !== null
              ? DateTime.fromISO(props.data.date)
                  .setZone("America/Los_Angeles")
                  .toFormat("LLL dd, yyyy")
              : "-"}
          </p>
        </div>
        <div className={`d-flex justify-content-between mt-4`}>
          <p className={`${styles.OrderId}`}>
            <Link to={`/admin/orders/view/${props.data.id}`}>
              #{props.data.orderId}
            </Link>
          </p>
          <p className={`${styles.OrderPrice}`}>
            ${props.data.price ? props.data.price.toFixed(2) : 0}
          </p>
        </div>
      </CardContent>
    </Card>
  );
}
