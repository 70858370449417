import { Button, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import React, { useState } from "react";
// SCSS
import styles from "./cancelNote.module.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OverrideEditOrderModal = ({
  showModal,
  pageTitle,
  handleCancelClick,
  handleSubmitClick,
  placeholder,
  hideProceedButton,
  message
}) => {
  // console.log("hideProceedButton=>",hideProceedButton)
  // const { orderState } = useOrder();
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="row">
              <div className="container">
                <p
                  style={{ color: "#f30000", fontSize: "30px", "line-height": "1.5" }}
                  className="mt-5 mb-3"
                >
                  {message}
                </p>
                {/* <p
                  style={{ color: "#f30000", fontSize: "30px" }}
                  className="mt-5 mb-3"
                >
                  {!hideProceedButton ? 'Do you want to still proceed ?.' : 'You can Not Edit This Order'}
                </p> */}
              </div>
              <div className="col-12 my-3">
                <div className="d-flex justify-content-center">
                  {!hideProceedButton && (
                    <span
                      data-dismiss="modal"
                      onClick={handleSubmitClick}
                      className="btn gray-btn d-inline-block mt-5 mb-5 mr-2 mt-5 cursor-pointer"
                    >
                      {" "}
                      Proceed{" "}
                    </span>
                  )}
                  <span
                    data-dismiss="modal"
                    onClick={handleCancelClick}
                    className="secondary-btn d-inline-block mt-5 mb-5 mr-2 mt-5 cursor-pointer"
                  >
                    {" "}
                    {!hideProceedButton ? 'No' : 'Ok'}{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default OverrideEditOrderModal;
