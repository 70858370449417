import React, { useEffect, useRef, useState, useContext } from "react";
import Header from "../../../Components/Header/Header";
import CardContent from "@material-ui/core/CardContent";
import Goto from "../../../Components/Cms/Goto";
import WYSIWIGEditor from "../../../Components/WYSIWIGEditor/WYSIWIGEditor";
import AddIcon from "../../../Assets/images/add.svg";
import classes from "./index.module.scss";
import ManageHolidaysCutoffModal from "../../../Components/Modal/HolidayCutOff";
import { useLocation } from "react-router-dom";
import { AppContext } from "./../../../Context/AppContext";
//services
// import HolidaysCutOffApi from "../../../Services/HolidaysCutoff/HolidaysCutoff";
// import CategoryApi from "../../../Services/Category/Category";
import moment from "moment";
import {
  getDetailsHoliday,
  addCutOff,
  updateCutOff,
} from "../../../api/holidaysCutoff";
import { getCategoryHierarchyList } from "../../../api/category";
//components
import Text from "../../../Components/FormControl/Text/TextInput";
import Date from "../../../Components/FormControl/Date/Date";
import CalendarIcon from "./../../../Assets/images/calendar.svg";
//hook
import { HolidayForm } from "../../../Hooks/cms/holidayCutOffFormik";
import { useHistory } from "react-router-dom";

//css
import styles from "./index.module.scss";

//IMAGES
import DeleteIcon from "../../../Assets/images/delete.svg";
import { DateTime } from "luxon";
import {
  END_TIME_OF_DATE_TWENTY_FOUR,
  PST_ZONE,
  START_TIME_OF_DATE_TWENTY_FOUR,
  UTC_ZONE,
} from "Pages/Order/Order/utility/util";
import { convertUtcTimestampToPstDate } from "Hooks/commanUtility";

const HolidaysCutoff = (props) => {
  const location = useLocation();
  const history = useHistory();
  // const holidaysCutOffservice = new HolidaysCutOffApi();
  // const categoryService = new CategoryApi();
  const formRef = useRef();
  let [initialValues, setInitValues] = useState({
    copy: "",
    start_date: "",
    end_date: "",
    product_label: "",
    headline: "",
    url: "",
    preview_text: "",
  });
  const [selectedCategory, setSelectedCategories] = useState([]);
  // const [categories, setCategories] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const { showToastMessage, setIsLoading } = useContext(AppContext);

  const gotoList = [
    { id: "primary-information", title: "Primary Information" },
    { id: "excluded-categories", title: "Excluded Categories" },
  ];
  // const [data, setData] = useState({})
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [isEdit, setEditStatus] = useState(false);
  const [submitStatus, setSubmitStatus] = React.useState(false);
  const [duplicateCategory, setDuplicateCategory] = useState(0);

  const handleAddHolidaysCutoffCategory = async (event) => {
    event.preventDefault();
    setShowAddModal(true);
  };

  const updateCategories = (value) => {
    setDuplicateCategory(0);
    let checkForDuplicate;
    if (value && value.length > 0) {
      if (selectedCategory.length > 0) {
        checkForDuplicate = selectedCategory?.filter((data) => {
          if (
            data.category_hierarchy_id === value[0]["category_hierarchy_id"]
          ) {
            return true;
          }
        });
      }

      if (checkForDuplicate && checkForDuplicate.length > 0) {
        setDuplicateCategory(1);
      } else {
        setShowAddModal(false);
        let selectedCategoryList = [...selectedCategory];
        selectedCategoryList.push(value[0]);
        setSelectedCategories(selectedCategoryList);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await handleGetCategory();
    })();
  }, []);

  useEffect(() => {
    if (location?.state?.id) {
      setEditStatus(true);
      getDetails();
    }
  }, [location, categoryList]);

  //get category details
  const handleGetCategory = async () => {
    setIsLoading(true);
    // const response = await categoryService.getCategoryHierarchyList();
    const response = await getCategoryHierarchyList();

    if (response && response.status === 200) {
      if (response.data.data && response.data.data.length) {
        setCategoryList(response.data.data);
      }
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const getDetails = async () => {
    // const response = await holidaysCutOffservice.getDetails(location.state.id);
    const response = await getDetailsHoliday(location.state.id);
    if (response.status === 200) {
      let pstDate = DateTime.fromSeconds(response.data.data.start_date)
        .setZone(UTC_ZONE)
        .toFormat("MM-dd-yyyy");
      // console.log("pstDate=>",pstDate);
      let timestamp = DateTime.fromFormat(`${pstDate}`, "LL-dd-yyyy", {
        zone: PST_ZONE,
      })
        .setZone(UTC_ZONE)
        .toSeconds();
      // console.log("timestamp=>",timestamp)
      // debugger
      setInitValues({
        copy: response.data.data.copy,
        start_date: timestamp,
        end_date: response.data.data.end_date,
        product_label: response.data.data.product_label,
        headline: response.data.data.headline,
        url: response.data.data.url,
        preview_text: response.data.data.preview_text,
      });

      let categoryData = [];
      response.data.data.holiday_cutoff_exclude_categories?.map((d, i) => {
        let catData = categoryList?.filter((data) => {
          if (data.category_hierarchy_id === d.category_hierarchy_id) {
            return true;
          }
        });
        if (catData && catData.length > 0) {
          categoryData.push(catData[0]);
        }
      });
      setSelectedCategories(categoryData);
    }
  };

  const submit = async (values) => {
    let body = { ...values };

    let selectedCategoryData = [];
    if (selectedCategory.length > 0) {
      selectedCategory?.map((d, i) => {
        let tempData = {
          category_hierarchy_id: d.category_hierarchy_id,
        };
        selectedCategoryData.push(tempData);
      });
    }
    body.cutoff_categories = selectedCategoryData;
    if (body.end_date < body.start_date) {
      showToastMessage(
        undefined,
        "You can not add start date bigger then end date ",
        "error",
        false
      );
    } else if (selectedCategoryData.length === 0) {
      showToastMessage(
        undefined,
        "Add minimum on category in exclude category ",
        "error",
        false
      );
    } else {
      // console.log("body=>", body);
      // body.start_date = moment.unix(body.start_date).format("DD-MM-YYYY");
      body.start_date = convertUtcTimestampToPstDate(
        body.start_date,
        "dd-LL-yyyy"
      );
      body.end_date = convertUtcTimestampToPstDate(body.end_date, "dd-LL-yyyy");
      // console.log("converted_body=>", body);
      // return false;
      if (isEdit) {
        setIsLoading(true);
        // let response = await holidaysCutOffservice.updateCutOff(
        //   location.state.id,
        //   body
        // );
        let response = await updateCutOff(location.state.id, body);
        if (response.status === 200) {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "success", false);
          history.push("/settings/holidays-cutoff");
        } else {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "error", false);
        }
      } else {
        setIsLoading(true);
        // let response = await holidaysCutOffservice.addCutOff(body);
        let response = await addCutOff(body);
        if (response.status === 200) {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "success", false);
          history.push("/settings/holidays-cutoff");
        } else {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "error", false);
        }
      }
    }
  };

  const deleteSelectedData = async (index) => {
    let selectedCategoryList = [...selectedCategory];
    selectedCategoryList.splice(index, 1);
    setSelectedCategories(selectedCategoryList);
  };

  const { formik: CutOffFormik } = HolidayForm(submit, initialValues);
  // console.log("CutOffFormik=>",CutOffFormik.values);
  return (
    <React.Fragment>
      <Header title="Holiday CutOff" />
      <form ref={formRef} onSubmit={(e) => CutOffFormik.handleSubmit(e)}>
        <div className="row">
          <div id="MainContent" className="col-8">
            <div className={`${classes.cardBody}`}>
              <CardContent>
                <div className="row" id="primary-information">
                  <div class={`col-md-6`}>
                    <Text
                      type="text"
                      label="Product Label"
                      id="product_label"
                      value={CutOffFormik.values.product_label}
                      required={true}
                      onChange={(data) =>
                        CutOffFormik.setValues((v) => {
                          return { ...v, product_label: data };
                        })
                      }
                      touched={CutOffFormik.touched.product_label}
                      error={CutOffFormik.errors.product_label}
                    />
                  </div>
                  <div class={`col-md-3`}>
                    <Date
                      label="Start Date"
                      // value={fromDate}
                      dateFormat="MM-DD-YYYY"
                      type="date"
                      fieldIcon={CalendarIcon}
                      onChange={(data) =>
                        CutOffFormik.setValues((v) => {
                          let pst = moment(data).format("MM-DD-YYYY");
                          let timestamp = DateTime.fromFormat(
                            `${pst} ${START_TIME_OF_DATE_TWENTY_FOUR}`,
                            "LL-dd-yyyy HH:mm",
                            {
                              zone: PST_ZONE,
                            }
                          )
                            .setZone(UTC_ZONE)
                            .toSeconds();

                          return {
                            ...v,
                            start_date: timestamp,
                          };
                        })
                      }
                      // value={
                      //   CutOffFormik.values.start_date !== ""
                      //     ? DateTime.fromSeconds(CutOffFormik.values.start_date)
                      //         .setZone(UTC_ZONE)
                      //         .toFormat("LL-dd-yyyy")
                      //     : ""
                      // }
                      value={
                        CutOffFormik.values.start_date !== ""
                          ? convertUtcTimestampToPstDate(
                              CutOffFormik.values.start_date,
                              "LL-dd-yyyy"
                            )
                          : ""
                      }
                      id="start_date"
                      touched={CutOffFormik.touched.start_date}
                      error={CutOffFormik.errors.start_date}
                      checkPassDate={true}
                    />
                  </div>
                  <div class={`col-md-3`}>
                    <Date
                      label="End Date"
                      // value={fromDate}
                      dateFormat="MM-DD-YYYY"
                      type="date"
                      // value={moment.unix(CutOffFormik.values.end_date).format("DD-MM-YYYY")}
                      value={
                        CutOffFormik.values.end_date !== ""
                          ? convertUtcTimestampToPstDate(
                              CutOffFormik.values.end_date,
                              "LL-dd-yyyy"
                            )
                          : ""
                      }
                      fieldIcon={CalendarIcon}
                      onChange={(data) =>
                        CutOffFormik.setValues((v) => {
                          let pst = moment(data).format("MM-DD-YYYY");
                          let timestamp = DateTime.fromFormat(
                            `${pst} ${END_TIME_OF_DATE_TWENTY_FOUR}`,
                            "LL-dd-yyyy HH:mm",
                            {
                              zone: PST_ZONE,
                            }
                          )
                            .setZone(UTC_ZONE)
                            .toSeconds();
                          return {
                            ...v,
                            end_date: timestamp,
                          };
                        })
                      }
                      id="end_date"
                      touched={CutOffFormik.touched.end_date}
                      error={CutOffFormik.errors.end_date}
                      checkPassDate={true}
                    />
                  </div>
                  <div class={`col-md-6`}>
                    <Text
                      type="text"
                      label="Headline"
                      value={CutOffFormik.values.headline}
                      required={true}
                      onChange={(data) =>
                        CutOffFormik.setValues((v) => {
                          return { ...v, headline: data };
                        })
                      }
                      id="headline"
                      touched={CutOffFormik.touched.headline}
                      error={CutOffFormik.errors.headline}
                    />
                  </div>
                  <div class={`col-md-6`}>
                    <Text
                      type="text"
                      label="Details Url"
                      value={CutOffFormik.values.url}
                      required={true}
                      onChange={(data) =>
                        CutOffFormik.setValues((v) => {
                          return { ...v, url: data };
                        })
                      }
                      id="url"
                      touched={CutOffFormik.touched.url}
                      error={CutOffFormik.errors.url}
                    />
                  </div>
                </div>
                <div className={`row`}>
                  <label
                    className="bmd-label-floating"
                    style={{ marginLeft: "15px" }}
                  >
                    Copy
                  </label>
                  <div className={`col-md-12 col-xs-12`}>
                    <WYSIWIGEditor
                      data={CutOffFormik.values.copy}
                      onChange={(data) =>
                        CutOffFormik.setValues((v) => {
                          return { ...v, copy: data };
                        })
                      }
                    />
                  </div>
                </div>
                <br></br>
                <div class={`col-md-12`}>
                  <Text
                    type="text"
                    label="Product Preview Text"
                    value={CutOffFormik.values.preview_text}
                    required={true}
                    onChange={(data) => {
                      if (data.length < 255) {
                        CutOffFormik.setValues((v) => {
                          return { ...v, preview_text: data };
                        });
                      } else {
                        return;
                      }
                    }}
                    id="preview_text"
                    touched={CutOffFormik.touched.preview_text}
                    error={CutOffFormik.errors.preview_text}
                  />
                </div>

                <div
                  className="d-md-flex align-items-md-center mt-4 mb-2"
                  id="excluded-categories"
                >
                  <h3 className="bold mr-3">
                    Excluded Categories
                    <span
                      className={`cursor-pointer ml-3 ${classes.searchIconWrapper}`}
                      onClick={handleAddHolidaysCutoffCategory}
                    >
                      <img
                        className={classes.searchIcon}
                        src={AddIcon}
                        alt=""
                      />
                    </span>
                  </h3>
                </div>

                <div className={`col-md-12`}>
                  <div className="table-responsive mt-2 mb-4">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="text-left">Category</th>
                          <th className="text-left"></th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {selectedCategory && selectedCategory.length > 0 ? (
                          selectedCategory?.map((d, i) => {
                            return (
                              <tr>
                                <td className={``}>{d.title}</td>
                                <td className={`link cursor-pointer`}>
                                  <img
                                    onClick={(e) => deleteSelectedData(i)}
                                    className={`${styles.searchIcon} mr-3`}
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className={``}>No Data</td>
                            <td className={` text-center`}>-</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </CardContent>
            </div>
          </div>
          <div className="col-4">
            <Goto gotoList={gotoList} />
          </div>
        </div>
      </form>
      <div>
        <ManageHolidaysCutoffModal
          showModal={showAddModal}
          pageTitle="Add Category"
          submitStatus={submitStatus}
          handleCancelClick={(e) => {
            setDuplicateCategory(0);
            setShowAddModal(false);
          }}
          categoryList={categoryList}
          duplicateCategory={duplicateCategory}
          handleSubmitClick={updateCategories}
        />
      </div>
    </React.Fragment>
  );
};
export default HolidaysCutoff;
