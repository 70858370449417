import React, { useReducer } from "react";
import blogReducer from "./blogReducer";
import { blogState } from "./blogState";

export const BlogContext = React.createContext({
  blogState: blogState,
  dispatch: () => null,
});

const BlogProvider = (props) => {
  const [state, dispatch] = useReducer(blogReducer, blogState);
  return (
    <BlogContext.Provider value={{ blogState: state, dispatch }}>
      {props.children}
    </BlogContext.Provider>
  );
};

export default BlogProvider;