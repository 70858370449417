import { getOrderList, getOrderListExport } from "api/order";
// import { useOrder } from 'Context/OrderContext/useOrder';
import { DateTime } from "luxon";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import EditIcon from "../../../Assets/images/edit.svg";
import ViewIcon from "../../../Assets/images/error-outline.svg";
import ExportIcon from "../../../Assets/images/export.svg";
import DefaultOrderFilter from "../../../Components/DefaultOrderFilter/DefaultOrderFilter";
import ReactTable from "../../../Components/Table/Table";
import { AppContext } from "../../../Context/AppContext";
import { MODULES, ORDER_STATUS_FILTER , ORDER_TYPE } from "../../../Helpers/Constants";
import { formatUsPhone } from "../../../Hooks/commanUtility";
// Context
import { getAccessByModuleName } from "../../../Routes/Routes";
// Components
import Header from "./../../../Components/Header/Header";
import styles from "./index.module.scss";
import { useUser } from "Context/UserContext/useUser";
import { useLastOrderEditPath } from "Hooks/useLastOrderEditPath";
import LoadingScreen from "Components/Loading/LoadingScreen";
import { setAllStore } from "Helpers/setAllStore";
const Orders = (props) => {
  const { showToastMessage, modules } = useContext(AppContext);
  const reference = React.useRef(null);
  const [userData, setUserData] = useState("");
  const { userState, reLogin } = useUser();
  const getUserData = async () => {
    if (userState?.userProfile?.id) {
      setUserData(userState?.userProfile?.user_type);
    } else {
      const user = await reLogin();
      setUserData(user?.user_type);
    }
  };
  useEffect(() => {
    getUserData();
  }, []);

  // const [statusList, setStatusList] = useState([
  //   { id: "", title: "All" },
  //   { id: "pending", title: "Pending" },
  //   { id: "processing", title: "Processing" },
  //   { id: "in_design", title: "In Design" },
  //   { id: "design_completed", title: "Design Completed" },
  //   { id: "out_for_delivery", title: "Out for Delivery" },
  //   { id: "delivered", title: "Delivered" },
  //   { id: "cancel", title: "Cancel" },
  //   { id: "failed", title: "Failed" },
  // ]);

  const statusList = ORDER_STATUS_FILTER;
  const orderTypeList = ORDER_TYPE;

  const tempArrDefault = {
    order_number: "",
    order_status: "",
    createdAt: "",
    order_type: "",
    delivery_time: "",
    order_taken_by: "",
    order_designed_by: "",
    designer_instruction: "",
    total: "",
    refund_amount: "",
    customer_billing_first_name: "",
    customer_billing_last_name: "",
    customer_billing_address: "",
    customer_billing_city: "",
    customer_billing_state: "",
    customer_billing_zipcode: "",
    customer_first_name: "",
    customer_last_name: "",
    customer_phone_number: "",
    customer_alt_phone_number: "",
    is_business_account: 0,
    customer_email: "",
    whats_the_occasion: "",
    recipient_first_name: "",
    recipient_last_name: "",
    attn: "",
    address_line_1: "",
    address_line_2: "",
    recipient_phone: "",
    recipient_alt_phone: "",
    city: "",
    state: "",
    zipcode: "",
    greetings_on_card: "",
    card_signature: "",
    is_burq_order:"",
  };

  const exportHeaders = [
    //order
    { label: "Order No", key: "order_number" },
    { label: "Status", key: "order_status" },
    { label: "Order Type", key: "is_burq_order" },
    { label: "Order Date", key: "createdAt" },
    { label: "Delivery Type", key: "order_type" },
    { label: "Delivery Date", key: "delivery_date_time" },
    { label: "Delivery Time", key: "delivery_time" },
    { label: "Order Taken By", key: "order_taken_by" },
    { label: "Designed By", key: "order_designed_by" },
    { label: "Designer Instructions", key: "designer_instruction" },
    { label: "Order Total", key: "flower_total_amount" },
    { label: "Complete Order Total", key: "total" },
    { label: "Refunded Amount", key: "refund_amount" },
    //billing data
    { label: "Billing First Name", key: "customer_billing_first_name" },
    { label: "Billing Last Name", key: "customer_billing_last_name" },
    { label: "Billing Address", key: "customer_billing_address" },
    { label: "Billing City", key: "customer_billing_city" },
    { label: "Billing State", key: "customer_billing_state" },
    { label: "Billing Zipcode", key: "customer_billing_zipcode" },
    { label: "Sender First Name", key: "customer_first_name" },
    { label: "Sender Last Name", key: "customer_last_name" },
    { label: "Customer Phone Number", key: "customer_phone_number" },
    { label: "Customer ALT Phone Number", key: "customer_alt_phone_number" },
    { label: "Customer email", key: "customer_email" },
    //recipent
    { label: "Occasion Type", key: "whats_the_occasion" },
    { label: "Recipient First Name", key: "recipient_first_name" },
    { label: "Recipient Last Name", key: "recipient_last_name" },
    { label: "Address Type", key: "address_type" },
    { label: "Address Type Name", key: "attn" },
    { label: "Recipient Address Line 1", key: "address_line_1" },
    { label: "Recipient Address Line 2", key: "address_line_2" },
    { label: "Recipient Phone", key: "recipient_phone" },
    { label: "Recipient ALT Phone", key: "recipient_alt_phone" },
    { label: "Recipient City", key: "city" },
    { label: "Recipient State", key: "state" },
    { label: "Recipient ZIP", key: "zipcode" },
    { label: "Greeting On The Card", key: "greetings_on_card" },
    { label: "Card Signature", key: "card_signature" },
    //recipent product data
    { label: "Product Type", key: "product_type" },
    { label: "Product Name", key: "product_name" },
    { label: "Product Variation Name", key: "variation_name" },
    { label: "Product Qty", key: "product_qty" },
    { label: "Product Total Amount", key: "product_total_amount" },
  ];
  // let exportData = [];
  const [exportData, setExportData] = useState([]);
  // Add / Edit
  const [access, setAccess] = useState({});
  // Default Values
  let defaultFromDate = moment()
    .subtract(6, "days")
    .startOf("day")
    .format("ddd MMM DD, YYYY");
  let deafultToDate = moment().endOf("day").format("ddd MMM DD, YYYY");
  let defaultFilterValue = {
    pageIndex: 0,
    pageSize: 10,
    sortBy: [
      {
        id: "order_number",
        desc: "desc",
      },
    ],
    filters: [{ id: "order_status", value: "" }],
    globalFilter: "",
  };
  let defaultType = "order_date";
  // Table Constants
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  // Date Range Filter Start
  const [type, setType] = useState(defaultType);
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(deafultToDate);
  // Date Range Filter End

  const [defaultFilter, setDefaultFilter] = useState(defaultFilterValue);
  const [firstTimePageLoad, setFirstTimePageLoad] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [isCustomSearch, setIsCustomSearch] = useState(false);
  const [columnStateSaveData, setColumnStateSaveData] = useState({});

  const [checkAllStore,setCheckAllStore] = useState(true);

  // const { resetState } = useOrder();
  useLastOrderEditPath();

  // Set Async Data
  const setAsyncData = (data) => {
    let asyncDataValues = JSON.stringify(data);
    localStorage.setItem("asyncDataValues", asyncDataValues);
  };
  // Get And Set Data Into State
  useEffect(() => {
    const filterData = JSON.parse(localStorage.getItem("asyncDataValues"));
    if (filterData) {
      const {
        currentPage,
        itemsPerPage,
        globalSearch,
        filters,
        sortBy,
        date_type,
        start_date,
        end_date,
      } = filterData;
      setDefaultFilter({
        filters: filters,
        pageIndex: currentPage,
        globalFilter: globalSearch,
        pageSize: itemsPerPage,
        sortBy: sortBy,
      });
      setType(date_type);
      setFromDate(moment(start_date).format("ddd MMM DD, YYYY"));
      setToDate(moment(end_date).format("ddd MMM DD, YYYY"));
    }
    setFirstRender(false);

    const columnStateData = JSON.parse(
      localStorage.getItem("columnDataStateSaveOrderLookUp")
    );
    setColumnStateSaveData(columnStateData);
  }, []);

  // Handle On Start Date Change Validation
  const handleFromDate = async (fromDate) => {
    setFromDate(fromDate);
  };
  // Handle On End Date Change Validation
  const handleToDate = async (toDate) => {
    setToDate(toDate);
  };
  // Date Validation
  const dateValidation = (fromDate, toDate) => {
    let todayDate = moment(new Date()).format("x");
    let startDate = moment(fromDate).format("x");
    let endDate = moment(toDate).format("x");
    if (startDate > endDate) {
      showToastMessage(
        undefined,
        "End date should be greater than start date",
        "error",
        false
      );
      return false;
    }
    let daysDiff = moment(fromDate).diff(toDate, "days");
    daysDiff = daysDiff < 0 ? daysDiff * -1 : daysDiff;
    if (
      userData &&
      userData !== "super_admin" &&
      userData !== "admin" &&
      startDate < todayDate &&
      daysDiff > 60
    ) {
      showToastMessage(
        undefined,
        "You can select maximum 60 days back from current date for start date",
        "error",
        false
      );
      return false;
    }
    return true;
  };

  // Fetch Records
  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
    latestData,
  }) => {

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);

    if (
      !dateValidation(
        fromDateDefault ? fromDateDefault : fromDate,
        toDateDefault ? toDateDefault : toDate
      )
    ) {
      return false;
    }
    pageIndex = latestData ? 0 : pageIndex;
    setLoading(true);
    let postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      date_type: type,
      start_date: fromDateDefault
        ? fromDateDefault
        : moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault
        ? toDateDefault
        : moment(toDate).format("YYYY-MM-DD"),
    };
    let orderAccess = getAccessByModuleName(modules, MODULES.ORDER_LOOKUP);
    if (orderAccess && orderAccess.permissions.read_access) {
      const response = await getOrderList(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count / pageSize));
        setTotalCount(response.data.data.count);
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }
    setLoading(false);
    setAsyncData(postData);
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setIsCustomSearch(false);

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  // Export Data
  const fetchDataExport = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
  }) => {
    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);
    if (
      !dateValidation(
        fromDateDefault ? fromDateDefault : fromDate,
        toDateDefault ? toDateDefault : toDate
      )
    ) {
      return false;
    }
    setLoading(true);
    const postData = {
      globalSearch: globalFilter,
      filters,
      sortBy,
      date_type: type,
      start_date: moment(fromDate).format("YYYY-MM-DD"),
      end_date: moment(toDate).format("YYYY-MM-DD"),
    };

    if (!firstTimePageLoad) {
      let orderAccess = getAccessByModuleName(modules, MODULES.ORDER_LOOKUP);
      if (orderAccess && orderAccess.permissions.read_access) {
        const response = await getOrderListExport(postData);
        if (response && response.status === 200) {
          // console.log(response.data.data.rows);
          let tempDataArr = [];
          response.data.data.rows.forEach((data, index) => {
            let orderStatusData = statusList.filter(
              (status) => status.id === data.order_status
            );

            //sum of refund amount
            let sumRefundAmount =
              data.order_transaction?.order_payment_transactions.reduce(
                function (sum, current) {
                  return sum + (current.type === "credit")
                    ? current.total_amount
                    : 0;
                },
                0
              );

            //order master data
            let tempArr = {
              order_number: data.order_number,
              order_status: orderStatusData[0]["title"],
              is_burq_order:(data.is_burq_order === 1 ? "On-Demand" : (data.is_burq_order === 0 && data.order_onfleet.onfleet_task_id !== null) ? "Onfleet" : "-"),
              createdAt: DateTime.fromISO(data.createdAt)
                .setZone("America/Los_Angeles")
                .toFormat("ccc LLL dd, yyyy"),
              order_type: data.order_type ? "PickUp" : "Delivery",
              delivery_date_time: DateTime.fromISO(data.delivery_date_time)
                .setZone("America/Los_Angeles")
                .toFormat("ccc LLL dd, yyyy"),
              delivery_time:
                data.delivery_time_type === "Anytime"
                  ? data.delivery_time_type
                  : DateTime.fromISO(data.delivery_date_time)
                      .setZone("America/Los_Angeles")
                      .toFormat("hh:mm a"),
              order_taken_by: data.csr_detail?.full_name ?? "",
              order_designed_by: data.designer_detail?.full_name ?? "",
              designer_instruction: data.designer_instruction,
              total: data.total,
              flower_total_amount: data.flower_total_amount,
              refund_amount: sumRefundAmount ?? 0,
              customer_billing_first_name:
                data.order_transaction?.customer_billing_first_name ?? "",
              customer_billing_last_name:
                data.order_transaction?.customer_billing_last_name ?? "",
              customer_billing_address:
                data.order_transaction?.customer_billing_address ?? "",
              customer_billing_city:
                data.order_transaction?.customer_billing_city ?? "",
              customer_billing_state:
                data.order_transaction?.customer_billing_state ?? "",
              customer_billing_zipcode:
                data.order_transaction?.customer_billing_zipcode ?? "",
              customer_first_name:
                data.order_transaction?.customer_first_name ?? "",
              customer_last_name:
                data.order_transaction?.customer_last_name ?? "",
              customer_phone_number: data.order_transaction?.customer_phone
                ? data.order_transaction?.customer_phone_country_code +
                  " " +
                  data.order_transaction?.customer_phone
                : "",
              customer_alt_phone_number: data.order_transaction
                ?.customer_alt_phone
                ? data.order_transaction?.customer_alt_phone_country_code +
                  " " +
                  data.order_transaction?.customer_alt_phone
                : "",
              customer_email: data.order_transaction?.customer_email ?? "",
            };

            data?.order_recipients.forEach((recipentData, recipentIndex) => {
              // tarnsaction data with billing data of recipent
              let tempArrRecipent = {
                whats_the_occasion: recipentData.whats_the_occasion,
                recipient_first_name: recipentData.first_name,
                recipient_last_name: recipentData.last_name,
                address_type: recipentData.building_type,
                attn: recipentData.attn,
                address_line_1: recipentData.address_line_1,
                address_line_2: recipentData.address_line_2,
                recipient_phone: recipentData.recipient_phone
                  ? recipentData?.phone_country_code +
                    " " +
                    recipentData.recipient_phone
                  : "",
                recipient_alt_phone: recipentData?.alt_phone
                  ? recipentData?.alt_phone_country_code +
                    " " +
                    recipentData.alt_phone
                  : "",
                city: recipentData.city,
                state: recipentData.state,
                zipcode: recipentData.zipcode,
                greetings_on_card: recipentData.greetings_on_card,
                card_signature: recipentData.card_signature,
              };

              //product data based on recipent
              recipentData?.order_products.forEach(
                (recipentProduct, recipentProductIndex) => {
                  let rempArrRecipentProduct = {
                    product_type: recipentProduct.product_type
                      ? "Addon"
                      : "Product",
                    product_name: recipentProduct.product_name,
                    variation_name: recipentProduct.variation_name.replace(
                      /\"/g,
                      ""
                    ),
                    product_qty: recipentProduct.qty,
                    product_total_amount: recipentProduct.total_price,
                  };
                  if (recipentProductIndex === 0) {
                    tempArr = {
                      ...tempArr,
                      ...tempArrRecipent,
                      ...rempArrRecipentProduct,
                    };
                  } else {
                    tempArr = {
                      ...tempArrDefault,
                      ...rempArrRecipentProduct,
                    };
                  }
                  tempDataArr.push(tempArr);
                }
              );
            });

            // tempDataArr.push(tempArr);
          });

          // console.log("tempDataArr=>", tempDataArr);
          setExportData(tempDataArr);
          // DownloadButtonMeeting(tempDataArr);
          // console.log(reference.current);
          reference.current?.link?.click();
        } else {
          showToastMessage(undefined, response.data.message, "error", false);
        }
      }
    } else {
      setFirstTimePageLoad(false);
    }
    setLoading(false);
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  //Search clear
  const handleClearSearch = async (event) => {
    event.preventDefault();
    // Condition According To Order Type For Start Date And End Date
    setType(defaultType);
    setFromDate(defaultFromDate);
    setToDate(deafultToDate);
    setIsCustomSearch(true);
    fetchData({
      ...defaultFilter,
      latestData: true,
      fromDateDefault: defaultFromDate,
      toDateDefault: deafultToDate,
    });
  };

  const selectedColumn = async (column) => {
    let columnData = JSON.stringify(column);
    // console.log("column=>",columnData);
    localStorage.setItem("columnDataStateSaveOrderLookUp", columnData);
  };
  // console.log("columnStateSaveData=>",columnStateSaveData);
  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.ORDER_LOOKUP);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }
  let accountAccess = getAccessByModuleName(modules, MODULES.ACCOUNT_LOOKUP);
  let viewOrderAccess = getAccessByModuleName(modules, MODULES.VIEW_ORDER);
  let editOrderAccess = getAccessByModuleName(modules, MODULES.EDIT_ORDER);
  // Datatable columns
  const columns = React.useMemo(
    () => [
      {
        Header: "Order No",
        accessor: "order_number",
        isVisible: columnStateSaveData?.order_number ?? true,
        placeholder: "Order No",
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        isVisible: columnStateSaveData?.customer_name ?? true,
        placeholder: "Customer Name",
        Cell: ({ value, row, column }) => {
          return row.original.order_transaction?.customer_id === 0 ||
            row.original.order_transaction?.customer_id === undefined ? (
            <span>{`${row.original.order_transaction.customer_name}`}</span>
          ) : accountAccess && accountAccess.permissions.write_access ? (
            <Link
              to={`/admin/customers/${row.original.order_transaction.customer_id}`}
            >
              <span className={`cursor-pointer`}>
                {`${row.original.order_transaction.customer_name}`}
              </span>
            </Link>
          ) : (
            <span>{`${row.original.order_transaction.customer_name}`}</span>
          );
        },
      },
      {
        Header: "Recipient Name",
        accessor: "recipient_name",
        isVisible: columnStateSaveData?.recipient_name ?? true,
        placeholder: "Recipient Name",
        Cell: ({ value, row, column }) => {
          return `${row.original.order_recipients[0].recipient_name}`;
        },
      },
      {
        Header: "Customer Phone",
        accessor: "customer_phone",
        isVisible: columnStateSaveData?.customer_phone ?? true,
        placeholder: "Customer Phone",
        Cell: ({ value, row, column }) => {
          return `${
            row.original.order_transaction.customer_phone
              ? row.original.order_transaction.customer_phone_country_code +
                " " +
                formatUsPhone(row.original.order_transaction.customer_phone)
              : ""
          }`;
        },
      },
      {
        Header: "Recipient Phone",
        accessor: "recipient_phone",
        isVisible: columnStateSaveData?.recipient_phone ?? true,
        placeholder: "Recipient Phone",
        Cell: ({ value, row, column }) => {
          return `${
            row.original.order_recipients[0].recipient_phone
              ? row.original.order_recipients[0].phone_country_code +
                " " +
                formatUsPhone(row.original.order_recipients[0].recipient_phone)
              : ""
          }`;
        },
      },
      {
        Header: "Delivery Date",
        accessor: "delivery_date_time",
        isVisible: columnStateSaveData?.delivery_date_time ?? true,
        placeholder: "Delivery Date",
        Cell: ({ value, row, column }) => {
          return `${
            row.original.delivery_date_time &&
            row.original.delivery_date_time !== null
              ? row.original.delivery_time_type === "Anytime"
                ? DateTime.fromISO(row.original.delivery_date_time)
                    .setZone("America/Los_Angeles")
                    .toFormat("ccc LLL dd, yyyy") +
                  " " +
                  row.original.delivery_time_type
                : DateTime.fromISO(row.original.delivery_date_time)
                    .setZone("America/Los_Angeles")
                    .toFormat("ccc LLL dd, yyyy, hh:mm a")
              : ""
          }`;
        },
        disableFilters: true,
      },
      {
        Header: "Order Date",
        accessor: "createdAt",
        isVisible: columnStateSaveData?.createdAt ?? true,
        placeholder: "Order Date",
        Cell: ({ value, row, column }) => {
          return `${
            row.original.createdAt && row.original.createdAt !== null
              ? DateTime.fromISO(row.original.createdAt)
                  .setZone("America/Los_Angeles")
                  .toFormat("ccc LLL dd, yyyy, hh:mm a")
              : ""
          }`;
        },
        disableFilters: true,
      },
      {
        Header: "Price",
        accessor: "total",
        placeholder: "Price",
        isVisible: columnStateSaveData?.total ?? false,
        Cell: ({ value, row, column }) => {
          return `$${row.original.total.toFixed(2)}`;
        },
      },
      {
        Header: "Customer Email",
        accessor: "customer_email",
        placeholder: "Customer Email",
        isVisible: columnStateSaveData?.customer_email ?? false,
        Cell: ({ value, row, column }) => {
          return `${row.original.order_transaction.customer_email}`;
        },
      },
      {
        Header: "Customer Address",
        accessor: "customer_address",
        placeholder: "Customer Address",
        isVisible: columnStateSaveData?.customer_address ?? false,
        Cell: ({ value, row, column }) => {
          return `${row.original.order_transaction.customer_address}`;
        },
      },
      {
        Header: "Recipient Address",
        accessor: "recipient_address",
        placeholder: "Recipient Address",
        isVisible: columnStateSaveData?.recipient_address ?? false,
        Cell: ({ value, row, column }) => {
          return `${row.original.order_recipients[0].recipient_address}`;
        },
      },
      {
        Header: "Order Type",
        accessor: "is_burq_order",
        isVisible: columnStateSaveData?.is_burq_order ?? true,
        placeholder: "Order Type",
        isSelectFilter: true,
        selectFilterOption: orderTypeList,
        colWidth: 160,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {row.original.is_burq_order === 1 ? "On-Demand" : (row.original.is_burq_order === 0 && row.original.order_onfleet.onfleet_task_id !== null) ? "Onfleet" : "-"}
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "order_status",
        isVisible: columnStateSaveData?.order_status ?? true,
        placeholder: "Status",
        isSelectFilter: true,
        selectFilterOption: statusList,
        colWidth: 160,
        Cell: ({ value, row, column }) => {
          let orderStatusData = statusList.filter(
            (status) => status.id === row.original.order_status
          );
          if(row.original.order_status === 'cancel' && row.original.is_burq_order === 1) {
            if(row.original.is_cancel_fail_by_burq === 1) {
              return "Driver Cancelled"
            } else if(row.original.is_order_cancel_on_burq_also === 0) {
              return (<span>Cancel <span style={{color:'red'}}> * </span> </span>)
            } else {
              return `${
                orderStatusData.length ? orderStatusData[0]["title"] : "-"
              }`;
            }
          }else {
            return `${
              orderStatusData.length ? orderStatusData[0]["title"] : "-"
            }`;
          }
        },
      },
      {
        Header: "Type",
        accessor: "source",
        placeholder: "Type",
        isVisible: columnStateSaveData?.source ?? false,
      },
      {
        Header: "External Order Number",
        accessor: "external_order_number",
        placeholder: "External Order Number",
        isVisible: columnStateSaveData?.external_order_number ?? false,
      },
      {
        Header: "Shop Code",
        accessor: "shopcode",
        placeholder: "Shop Code",
        isVisible: columnStateSaveData?.shopcode ?? false,
      },
      {
        Header: "Actions",
        accessor: "id",
        isVisible: columnStateSaveData?.id ?? true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          let orderStatusDataArryy = statusList.filter(
            (status) => status.id === row.original.order_status
          );
          return (
            <div>
              {viewOrderAccess && viewOrderAccess.permissions.read_access ? (
                <img
                  className={`cursor-pointer mr-2 view-icon`}
                  src={ViewIcon}
                  alt="View Order"
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push(`/admin/orders/view/${row.original.id}`);
                  }}
                />
              ) : (
                <span style={{ width: 34, height: 34, display: "block" }} />
              )}
              {editOrderAccess.permissions.write_access &&
              row?.original?.old_order_number === 0 ? (
                <img
                  className={`cursor-pointer`}
                  src={EditIcon}
                  alt="Edit Order"
                  onClick={(e) => {
                    e.preventDefault();
                    // resetState();
                    props.history.push(`/admin/orders/edit/${row.original.id}`);
                  }}
                />
              ) : (
                <span style={{ width: 34, height: 34, display: "block" }} />
              )}
            </div>
          );
        },
      },
    ],
    [columnStateSaveData]
  );

  if (firstRender) return <LoadingScreen />;
  return (
    <React.Fragment>
      <Header title={`Order Lookup (${totalCount})`} />   
      <div id="MainContent" className="main-content">
        {userData && userData === "super_admin" && (
          <div className="col-md-12">
            {exportData && exportData.length > 0 && (
              <CSVLink
                ref={reference}
                style={{
                  display: "none",
                  float: "right",
                  marginTop: "25px",
                }}
                data={exportData}
                headers={exportHeaders}
                filename={"orderLookup.csv"}
                className={`link csvLink cursor-pointer ml-3 ${styles.searchIconWrapper}`}
              >
                DownLoad
              </CSVLink>
            )}
            <span
              onClick={(e) => fetchDataExport(defaultFilter)}
              className={`link cursor-pointer ${styles.searchIconWrapper}`}
              style={{ float: "right", marginTop: "25px" }}
            >
              <img className={styles.searchIcon} src={ExportIcon} alt="" />
            </span>
          </div>
        )}

        <DefaultOrderFilter
          type={type}
          setType={setType}
          fromDate={fromDate}
          handleFromDate={handleFromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          handleToDate={handleToDate}
          // fetchData={fetchData}
          fetchData={(data) => {
            fetchData({
              ...defaultFilter,
              ...data,
              latestData: true,
            });
            setIsCustomSearch(true);
          }}
          handleClearSearch={handleClearSearch}
          defaultFilter={defaultFilter}
          defaultSetCheckAllStore={checkAllStore}
          checkAllStore={(data)=>{setCheckAllStore(data)}}
        />  
        <ReactTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
          displayColumnVisibility={true}
          isCustomSearch={isCustomSearch}
          selectedColumn={selectedColumn}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Orders);
