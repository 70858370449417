import React from 'react';
import moment from 'moment';
import { DateTime } from 'luxon';
// Components
import ReactTable from '../../../Components/Table/Table';
import CheckBox from '../../../Components/FormControl/CheckBox/CheckBox';
// Style
import styles from './index.module.scss';
// Images
import Check from './../../../Assets/images/Checks.svg';
import EditIcon from './../../../Assets/images/edit.svg';
// const UTC_ZONE = 'UTC';
// const PST_ZONE = 'America/Los_Angeles';
const Personal = (props) => {
  // Check Time OverDue
  const isTimeOverDue = (date, time) => {
    let currentDateTime = DateTime.now()
      .setZone('America/Los_Angeles')
      .toFormat('x');
    let givenDateTime = DateTime.fromFormat(
      date + ' ' + time,
      'yyyy-LL-dd HH:mm'
    )
    .toFormat('x');
    return currentDateTime > givenDateTime ? true : false;
  };
  // Table Columns
  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'ids',
        isVisible: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          return row.original.reminder_status === 1 ? (
            <div className={styles.completed}>
              <img src={Check} alt={''}/>{' '}
            </div>
          ) : (
            <div className={styles.pending}>
              <CheckBox
                value={row.original.reminder_status === 1 ? true : false}
                onChange={() => {
                  props.setChecked(row.original.id, props.defaultFilter)
                }}
                name="checkedB"
              />
            </div>
          );
        },
      },
      {
        Header: 'Reminder-Checklist',
        accessor: 'title',
        isVisible: true,
        Cell: ({ row, value }) => {
          return isTimeOverDue(row.original.reminder_date, row.original.time) &&
            row.original.reminder_status !== 1 ? (
            <div className={styles.pending}>{value}</div>
          ) : row.original.reminder_status === 1 ? (
            <div className={styles.completed}>{value}</div>
          ) : (
            <div>{value}</div>
          );
        },
      },
      {
        Header: 'Date',
        accessor: 'reminder_date',
        isVisible: true,
        isDateFilter: true,
        dateFormatValue: 'YYYY-MM-DD',
        Cell: ({ row, value }) => {
          value = moment(value).format('ddd MMM DD, YYYY');
          return isTimeOverDue(row.original.reminder_date, row.original.time) &&
            row.original.reminder_status !== 1 ? (
            <div className={styles.pending}>{value}</div>
          ) : row.original.reminder_status === 1 ? (
            <div className={styles.completed}>{value}</div>
          ) : (
            <div>{value}</div>
          );
        },
      },
      {
        Header: 'Due Time',
        accessor: 'time',
        isVisible: true,
        disableFilters: true,
        Cell: ({ row, value }) => {
          value = moment(value, 'HH:mm').format('hh:mm A');
          return isTimeOverDue(row.original.reminder_date, row.original.time) &&
            row.original.reminder_status !== 1 ? (
            <div className={styles.pending}>{value}</div>
          ) : row.original.reminder_status === 1 ? (
            <div className={styles.completed}>{value}</div>
          ) : (
            <div>{value}</div>
          );
        },
      },
      {
        Header: '',
        accessor: 'id',
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return row.original.reminder_status !== 1 ? (
            <div>
              <img
                className={`cursor-pointer ml-3`}
                src={EditIcon}
                alt="Edit"
                onClick={(e) =>
                  props.handleTodoReminderCode(e, 'edit', row.original)
                }
              />
            </div>
          ) : (
            <div></div>
          );
        },
      },
    ],
    [props.defaultFilter]
  );
  return (
    <React.Fragment>
      {/* Table For Personal */}
      <ReactTable
        columns={columns}
        data={props.data}
        fetchData={props.fetchData}
        loading={props.loading}
        pageCount={props.pageCount}
        noDataText={'There is no data to display'}
        defaultFilter={props.defaultFilter}
        displayGlobalSearch={false}
        displayColumnVisibility={false}
      />
    </React.Fragment>
  );
};
export default Personal;
