import moment from 'moment';
import React, { useContext, useState } from 'react';
import { getStockArrangementReport } from '../../../api/report';
import CalendarIcon from '../../../Assets/images/calendar.svg';
import Date from '../../../Components/FormControl/Date/Date';
import Header from '../../../Components/Header/Header';
import ReactTable from '../../../Components/Table/Table';
import { AppContext } from '../../../Context/AppContext';
import { MODULES } from './../../../Helpers/Constants';
import { getAccessByModuleName } from './../../../Routes/Routes';
import styles from './index.module.scss';
import CloseIcon from '../../../Assets/images/close.svg';
import SearchIcon from '../../../Assets/images/search.svg';
import CheckBox from 'Components/FormControl/CheckBox/CheckBox';
import { setAllStore } from 'Helpers/setAllStore';
const MostPorpularProduct = () => {
  const { showToastMessage, modules } = useContext(AppContext);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [defaultFilter, setDefaultFilter] = React.useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: '',
  });
  let defaultStartDate = moment()
    .subtract(7, 'days')
    .startOf('day')
    .format('ddd MMM DD, YYYY');
  let defaultEndDate = moment().endOf('day').format('ddd MMM DD, YYYY');
  const [fromDate, setFromDate] = useState(defaultStartDate);
  const [toDate, setToDate] = useState(defaultEndDate);

  // const [firstTimePageLoad, setFirstTimePageLoad] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);

  const [checkAllStore,setCheckAllStore] = useState(true);

  const handleClearSearch = async (event) => {
    event.preventDefault();
    setFromDate(defaultStartDate);
    setToDate(defaultEndDate);
    fetchData({
      ...defaultFilter,
      fromDateDefault: defaultStartDate,
      toDateDefault: defaultEndDate,
    });
  };

  // Date Validation
  const dateValidation = (fromDate, toDate) => {
    let startDate = moment(fromDate).format('x');
    let endDate = moment(toDate).format('x');
    if (startDate > endDate) {
      showToastMessage(
        undefined,
        'Start date should not be greater than from end date',
        'error',
        false
      );
      return false;
    }
    let daysDiff = moment(fromDate).diff(toDate, 'days');
    daysDiff = daysDiff < 0 ? daysDiff * -1 : daysDiff;
    if (daysDiff > 7) {
      showToastMessage(
        undefined,
        'Maximum 7 days is availabel',
        'error',
        false
      );
      return false;
    }
    return true;
  };

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
  }) => {

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);

    if (
      !dateValidation(
        fromDateDefault ? fromDateDefault : fromDate,
        toDateDefault ? toDateDefault : toDate
      )
    ) {
      return false;
    }
    let startDate = fromDateDefault ? fromDateDefault : fromDate;
    startDate = moment(startDate).format('YYYY-MM-DD');
    let endDate = toDateDefault ? toDateDefault : toDate;
    endDate = moment(endDate).format('YYYY-MM-DD');
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      start_date: startDate,
      end_date: endDate,
    };

    let accountAccess = getAccessByModuleName(
      modules,
      MODULES.STOCK_ARRANGEMENTS_REPORT
    );
    if (accountAccess && accountAccess.permissions.read_access) {
      const response = await getStockArrangementReport(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        // setPageCount(Math.ceil(response.data.data.count.length / pageSize));
        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else {
        showToastMessage(undefined, response?.data?.message, 'error', false);
      }
    } else {
      // setFirstTimePageLoad(false);
    }
    setLoading(false);

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  const columns = React.useMemo(() => [
    {
      Header: 'Products',
      accessor: 'product_id',
      isVisible: true,
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value, row, column }) => {
        let defaultIndex = 0;
        row.original.product.product_images.forEach((d, i) => {
          if (d.is_default_selected) defaultIndex = i;
        });
        return (
          <div className="row">
            <div className={`col-md-4 ${styles.ProducatImageWapper}`}>
              <img
                height="70"
                width="60"
                src={
                  row.original.product.product_images.length === 0 ||
                  row.original.product.product_images[defaultIndex].image ===
                    '' ||
                  row.original.product.product_images[defaultIndex].image ===
                    'null' ||
                  row.original.product.product_images[defaultIndex].image ===
                    null
                    ? ''
                    : `${row.original.product.product_images[defaultIndex].image}`
                }
                alt=""
              />
            </div>
          </div>
        );
      },
    },
    {
      Header: 'Products Name',
      accessor: 'title',
      disableSortBy: true,
      isVisible: true,
      Cell: ({ value, row, column }) => {
        return `${row.original.product ? row.original.product.title : ''}`;
      },
    },
    {
      Header: 'Variation',
      accessor: 'variation_title',
      isVisible: true,
      disableSortBy: true,
      Cell: ({ value, row, column }) => {
        return row.original.title;
      },
    },
    {
      Header: 'Quantity',
      accessor: 'no_of_orders',
      isVisible: true,
      disableFilters: true,
    },
  ]);

  return (
    <div>
      <Header title="Stock Arrangements Report" />
      <div className="row mb-5">
        <div className="col-sm-4 col-xs-12">
          <Date
            label="Start Date"
            value={fromDate}
            dateFormat="ddd MMM DD, YYYY"
            type="date"
            fieldIcon={CalendarIcon}
            onChange={(fromDate) => {
              setFromDate(moment(fromDate).format('ddd MMM DD, YYYY'));
              // handleFromDate(fromDate);
            }}
          />
        </div>
        <div className="col-sm-4 col-xs-12">
          <Date
            label="End Date"
            value={toDate}
            dateFormat="ddd MMM DD, YYYY"
            type="date"
            fieldIcon={CalendarIcon}
            onChange={(toDate) => {
              setToDate(moment(toDate).format('ddd MMM DD, YYYY'));
              // handleToDate(toDate);
            }}
          />
        </div>
        <div  className='col-sm-2 col-xs-12'>
          <CheckBox
              value={checkAllStore}
              label="All Stores"
              onChange={(data)=>{setCheckAllStore(data)}}
          />
        </div>
        <div className="col-sm-2 col-xs-12">
          <div className="td-actions pt-4">
            <span
              onClick={(e) => fetchData(defaultFilter)}
              className={`link cursor-pointer ${styles.searchIconWrapper}`}
            >
              <img className={styles.searchIcon} src={SearchIcon} alt="" />
            </span>
            <span
              onClick={(e) => handleClearSearch(e)}
              className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
            >
              <img className={styles.clearIcon} src={CloseIcon} alt="" />
            </span>
          </div>
        </div>
      </div>
      <div>
        <ReactTable
          columns={columns}
          data={data}
          loading={loading}
          fetchData={fetchData}
          pageCount={pageCount}
          defaultFilter={defaultFilter}
          noDataText={'There is no data to display'}
          displayGlobalSearch={false}
          displayColumnVisibility={false}
          displayLastColumnSorting={true}
        />
      </div>
    </div>
  );
};
export default MostPorpularProduct;
