import React from "react";
import classes from "./index.module.scss";
import AddBlackIcon from "./../../Assets/images/add-black.svg";

const MultipalImage = (props) => {
  const multipalImageRef = React.useRef();

  return (
    <div
      onClick={() => {
        multipalImageRef.current.click();
      }}
      className={classes.result}
    >
      <input
        type="file"
        multiple={props.multiple ? props.multiple : false}
        className="d-none"
        ref={multipalImageRef}
        accept={props.accept}
        onChange={async (e) => {
          const file = e.target.files;
          let imagedArr = [];
          for (let index = 0; index < file.length; index++) {
            imagedArr.push(file[index]);
          }
          props.handleUploadImage(imagedArr);
        }}
      />
      <img src={AddBlackIcon} alt="Add_Image"/>
    </div>
  );
};
export default MultipalImage;
