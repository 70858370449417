import Header from "Components/Header/Header";
import { AppContext } from "Context/AppContext";
import { MODULES, REQUEST_STATUS, REQUEST_TYPE_FILTER } from "Helpers/Constants";
import { getAccessByModuleName } from "Routes/Routes";
import React, { useContext, useEffect, useMemo, useState } from "react";
import ReactTable from "../../../Components/Table/Table";
import { DateTime } from "luxon";
import { withRouter } from "react-router";
import { getSentRequestList } from "api/inventoryProduct";
import EditIcon from "./../../../Assets/images/edit.svg";
import ViewIcon from "../../../Assets/images/error-outline.svg";

const SendResources = (props) => {
  const { modules, showToastMessage, setIsLoading } = useContext(AppContext);
  const [access, setAccess] = useState({});
  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.SEND_RESOURCES);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/profile");
    }
  }
 
  const statusList = REQUEST_STATUS;
  const typeList = REQUEST_TYPE_FILTER;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [
      {
        id: "id",
        desc: true,
      },
    ],
    filters: [],
    globalFilter: "",
    list_type: "send",
  });

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      list_type: "send",
    };
    const response = await getSentRequestList(postData);
    if (response && response.status === 200) {
      setData(response.data.data.rows);
      setPageCount(Math.ceil(response.data.data.count / pageSize));
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      const postData = {
        ...defaultFilter,
        list_type: "send",
      };
      await fetchData(postData);
    })();
  }, []);

  const handleManageResourceClick = (data, action="add") => {
    setIsLoading(true);
    if (action === "add") {
      props.history.push(`/inventory-management/send-resources/add-request`);
      setIsLoading(false);
    } else if (action === "edit") {
      props.history.push(`/inventory-management/send-resources/${data.id}`);
      setIsLoading(false);
    } else {
      props.history.push(
        `/inventory-management/send-resources/view/${data.id}`
      );
      setIsLoading(false);
    }
  };

  const columns = useMemo(() => [
    {
      Header: "Request No.",
      accessor: "request_number",
      isVisible: true,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Destination Store",
      accessor: "destination_store_id",
      placeholder: "Search By Destination Store Name",
      isVisible: true,
      Cell: ({ value, row, column }) => {
        return <span>{row.original?.destinationStore?.title}</span>;
      },
    },
    {
      Header: "Requested By",
      accessor: "request_created_by",
      placeholder: "Search Requested by Name",
      isVisible: true,
      Cell: ({ value, row, column }) => {
        return <span>{row.original?.requestCreatedBy?.full_name}</span>;
      },
    },
    {
      Header: "Request Date",
      accessor: "updatedAt",
      placeholder: "Search By Send Date",
      isVisible: true,
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value, row, column }) => {
        return (
          <span>
            {DateTime.fromISO(row.original.updatedAt)
              .setZone("America/Los_Angeles")
              .toFormat("ccc LLL dd, yyyy, hh:mm a")}
          </span>
        );
      },
    },
    {
      Header: "Status",
      accessor: "request_status",
      isVisible: true,
      disableSortBy: true,
      disableFilters: true,
      selectFilterOption: statusList,
      Cell: ({ value, row, column }) => {
        let requestStatus = statusList.filter(
          (status) => status.id === row.original.request_status
        );
        return `${requestStatus.length ? requestStatus[0]["title"] : "-"}`;
      },
    },
    {
      Header: "Type",
      accessor: "request_type",
      isVisible: true,
      disableSortBy: true,
      isSelectFilter: true,
      selectFilterOption: typeList,
      Cell: ({ value, row, column }) => {
        let requestType = typeList.filter(
          (status) => status.id === row.original.request_type
        );
        return `${requestType.length ? requestType[0]["title"] : "-"}`;
      },
    },
    {
      Header: "Action",
      accessor: "id",
      isVisible: true,
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value, row, column }) => {
        const checkFilledReqst =
          row.original.request_status === "partially_filled" ||
          row.original.request_status === "filled";
        return (
          <div className="d-flex justify-content-start">
            {access && access.read_access && (
              <img
                className={`cursor-pointer`}
                src={ViewIcon}
                alt="Edit"
                onClick={(e) => {
                  e.preventDefault();
                  handleManageResourceClick(row.original, "view");
                }}
              />
            )}
            {access && access.write_access && !checkFilledReqst && (
              <img
                className={`cursor-pointer ml-3`}
                src={EditIcon}
                alt="Edit"
                onClick={(e) => {
                  e.preventDefault();
                  handleManageResourceClick(row.original, "edit");
                }}
              />
            )}
          </div>
        );
      },
    },
  ]);

  return (
    <>
      <Header
        title="Send Resources"
        hasAddButton={access.write_access}
        handleAddClick={handleManageResourceClick}
      />
      <ReactTable
        columns={columns}
        data={data}
        expandedRows={true}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        noDataText={"There is no data to display"}
        defaultFilter={defaultFilter}
        displayGlobalSearch={false}
      />
    </>
  );
};

export default withRouter(SendResources);
