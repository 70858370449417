import React from "react";
import styles from "./RevenueBox.module.scss";

const RevenueBox = ({ text, stastic, displayIcon, percentage, currency }) => {
  return (
    <div className={styles.revenue_box} style={{ display: "flex" }}>
      <div className="justify-content-between align-items-center">
        <div className={styles.revenue_value}>
          {currency}
          {stastic}
          {percentage}{" "}
        </div>

        <div className={styles.revenue_title}>{text}</div>
      </div>

      <div>
        <img className="mr-0" src={displayIcon} alt="Static Data" />
      </div>
    </div>
  );
};

export default RevenueBox;
