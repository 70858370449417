import React, { useState, useContext, useEffect } from "react";
import Header from "./../../../Components/Header/Header";
import Select from "../../../Components/FormControl/Select/Select";

import { getAccessByModuleName } from "../../../Routes/Routes";
import { AppContext } from "../../../Context/AppContext";
import { MODULES } from "../../../Helpers/Constants";

import AddRecipeTable from "./add-reacipe-table";
// import { Formik, Form, Field } from "formik";
// import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";
// Api
import RecipeApi from "./../../../Services/Recipe/Recipe";
import ProductsApi from "../../../Services/Products/Products";
import { getProductsNormal } from "../../../api/product";
import {
  getRecipeRawMaterials,
  getRecipeType,
  getRecipeUnit,
  addRecipe,
  updateRecipe,
  getRecipeDetail,
} from "../../../api/recipe";

const AddRecipe = (props) => {
  // const history = useHistory();
  const [recipe, setRecipe] = useState([
    {
      quantity: "",
      ingredients_type_id: "",
      ingredients_raw_material_id: "",
      ingredients_unit_id: "",
      ingredients_unit:"-",
      ingredients_type:"-"
    },
  ]);

  const [submitStatus, setSubmitStatus] = useState(false);
  const { showToastMessage, modules, setIsLoading } = useContext(AppContext);
  const [productsList, setProductsList] = useState([]);
  const [variationsList, setVariationsList] = useState([]);
  const [rowMaterialList, setRowMaterialList] = useState([]);
  // const [typeList, setTypeList] = useState();
  // const [unitList, setUnitList] = useState();

  const [stockProductId, setStockProductId] = useState("");
  const [stockProductName, setStockProductName] = useState("");
  const [stockVariationId, setStockVariationId] = useState("");
  const [stockVariationName, setStockVariationName] = useState("");

  const [recipeIdUpdate, setRecipeIdUpdate] = useState(); //only for update case
  const [recipeDetailById, setRecipeDetailById] = useState(); //only for update case

  const [access, setAccess] = useState({});

  //use for update case for get update id
  useEffect(() => {
    (async () => {
      const { updateId } = props.match.params;
      if (updateId !== undefined && updateId !== "") {
        setRecipeIdUpdate(updateId);
      } else {
        await handleGetProducts(); //get product list
        await handleGetRowMaterial(); //get row material list
        // await handleGetType(); //get type list
        // await handleGetUnit(); // get unit list
      }
    })();
  }, []);

  //use in update case for get recipe detail
  useEffect(() => {
    (async () => {
      if (recipeIdUpdate) {
        await handleGetRecipeDetailById(recipeIdUpdate);
      }
    })();
  }, [recipeIdUpdate]);

  //use in update case for set Ingredient Data
  useEffect(() => {
    (async () => {
      if (recipeDetailById) {
        await setRecipeIngredientData();
        await handleGetProducts(); //get product list
        await handleGetRowMaterial(); //get row material list
        // await handleGetType(); //get type list
        // await handleGetUnit(); // get unit list
      }
    })();
  }, [recipeDetailById]);

  // use in both update & add
  // useEffect(() => {
  //   (async () => {
  //     await handleGetProducts(); //get product list
  //     await handleGetRowMaterial(); //get row material list
  //     await handleGetType(); //get type list
  //     await handleGetUnit(); // get unit list
  //   })();
  // }, []);

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  // Access Contorol functionm call
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.RECIPE);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.write_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  const productsService = new ProductsApi();
  const recipeService = new RecipeApi();

  //get recipe detail data for update case by recipe id
  const handleGetRecipeDetailById = async (updateId) => {
    setIsLoading(true);
    // const response = await recipeService.getRecipeDetail(updateId);
    const response = await getRecipeDetail(updateId);
    if (response && response.status === 200) {
      if (response.data.data) {
        let tempResponseData = { ...response.data.data };
        setRecipeDetailById(tempResponseData);
      }
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handleOnChange = async (type, data, position = "") => {
    if (type === "stockProductId" && stockProductId !== data) {
      //get selected product data for set it's variation in dropdown
      const selectedProductData = await findProductDetailFromArray(
        productsList,
        data
      );
      setStockProductId(data);
      setStockProductName(selectedProductData.title);
      setVariationsList(selectedProductData.product_variations);
      setStockVariationId(selectedProductData.product_variations[0].id);
      setStockVariationName(selectedProductData.product_variations[0].title);
    }
    if (type === "stockVariationId") {
      //get selected product Variation data for set it's variation in dropdown
      const selectedProductVariationData = await findProductDetailFromArray(
        variationsList,
        data
      );
      setStockVariationId(data);
      setStockVariationName(selectedProductVariationData.title);
    }

    if (type === "stockQuantity") {
      recipe[position]["quantity"] = data;
      let recipeList = [...recipe];
      setRecipe(recipeList);
    }
    
    if (type === "stockRowMaterial") {
      const selectedRawData = rowMaterialList.find((d) => d.id === data);
      recipe[position]["ingredients_raw_material_id"] = data;
      recipe[position]["ingredients_unit"] = selectedRawData.product_recipe_unit.title
      recipe[position]["ingredients_type"] = selectedRawData.product_recipe_type.title
      recipe[position]["ingredients_unit_id"] = selectedRawData.product_recipe_unit.id
      recipe[position]["ingredients_type_id"] = selectedRawData.product_recipe_type.id
      let recipeList = [...recipe];
      setRecipe(recipeList);
    }
    console.log("recipe=>", recipe);
  };

  //get selected product function by passing id
  const findProductDetailFromArray = async (productArray, productId) => {
    return productArray.find((element) => {
      return element.id === productId;
    });
  };

  //get product details
  const handleGetProducts = async () => {
    setIsLoading(true);
    // const response = await productsService.getProductsNormal();
    const response = await getProductsNormal();

    if (response && response.status === 200) {
      if (response.data.data && response.data.data.length) {
      const filteredProduct = response.data.data.filter((d)=>d.slug !== "custom-product" && d.slug !== "subscription-item")
        setProductsList(filteredProduct);

        if (recipeIdUpdate !== "" && recipeIdUpdate !== undefined) {
          //for update case get selected product
          if (recipeDetailById) {
            const selectedProductData = await findProductDetailFromArray(
              filteredProduct,
              recipeDetailById?.product_variation?.product?.id
            );

            setStockProductId(recipeDetailById.product_variation.product.id);
            setStockProductName(
              recipeDetailById.product_variation.product.title
            );
            setVariationsList(selectedProductData.product_variations);
            setStockVariationName(recipeDetailById.product_variation.title);
            setStockVariationId(recipeDetailById.product_variation.id);
          }
        } else {
          setStockProductId(filteredProduct[0].id);
          setStockProductName(filteredProduct[0].title);
          setVariationsList(filteredProduct[0].product_variations);
          setStockVariationId(filteredProduct[0].product_variations[0].id);
          setStockVariationName(
            filteredProduct[0].product_variations[0].title
          );
        }
      }
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  // set Ingredient Data for update case
  const setRecipeIngredientData = async () => {
    let ingredientData = recipeDetailById?.product_recipe_ingredients;

    let data = ingredientData?.map((val, idx) => {
      return {
        id: val.id,
        quantity: val.quantity,
        ingredients_type_id: val.ingredients_type_id,
        ingredients_raw_material_id: val.ingredients_raw_material_id,
        ingredients_unit_id: val.ingredients_unit_id,
        ingredients_unit: val.product_recipe_unit?.title ?? "-",
        ingredients_type: val.product_recipe_type?.title ?? "-",
      };
    });
    setRecipe(data);
  };

  //get row material data
  const handleGetRowMaterial = async () => {
    setIsLoading(true);
    const postData = {
      currentPage: 0,
      itemsPerPage: 1000,
    };

    // const response = await recipeService.getRecipeRawMaterials(postData);
    const response = await getRecipeRawMaterials(postData);
    if (response && response.status === 200) {
      if (response.data.data.rows && response.data.data.rows.length) {
        setRowMaterialList(response.data.data.rows);
      }
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  //get recipe type data
  // const handleGetType = async () => {
  //   setIsLoading(true);
  //   const postData = {
  //     currentPage: 0,
  //     itemsPerPage: 1000,
  //   };
  //   // const response = await recipeService.getRecipeType(postData);
  //   const response = await getRecipeType(postData);
  //   if (response && response.status === 200) {
  //     if (response.data.data.rows && response.data.data.rows.length) {
  //       setTypeList(response.data.data.rows);
  //     }
  //   } else {
  //     showToastMessage(undefined, response.data.message, "error", false);
  //   }
  //   setIsLoading(false);
  // };

  //get recipe unit data
  // const handleGetUnit = async () => {
  //   setIsLoading(true);
  //   const postData = {
  //     currentPage: 0,
  //     itemsPerPage: 1000,
  //   };
  //   // const response = await recipeService.getRecipeUnit(postData);
  //   const response = await getRecipeUnit(postData);
  //   if (response && response.status === 200) {
  //     if (response.data.data.rows && response.data.data.rows.length) {
  //       setUnitList(response.data.data.rows);
  //     }
  //   } else {
  //     showToastMessage(undefined, response.data.message, "error", false);
  //   }
  //   setIsLoading(false);
  // };

  // save button click
  const handleSubmitOnClick = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    let validationFlag = false;
    recipe.forEach((val, idx) => {
      if (!val.ingredients_raw_material_id) {
        validationFlag = true;
      }
      if (!val.ingredients_type_id) {
        validationFlag = true;
      }
      if (!val.ingredients_unit_id) {
        validationFlag = true;
      }
      if (!val.quantity) {
        validationFlag = true;
      }
      recipe[idx].status = 1;
    });

    if (validationFlag) {
      showToastMessage(
        undefined,
        "Please add all ingredients data",
        "error",
        false
      );
    } else if (!stockVariationId) {
      showToastMessage(
        undefined,
        "Please select product & variant",
        "error",
        false
      );
    } else {
      let reqBody = {
        recipe_name: stockProductName + " - " + stockVariationName,
        variation_id: stockVariationId,
        product_id:stockProductId,
        product_recipe_ingredients: recipe,
      };

      let response;
      if (recipeIdUpdate) {
        //for update case
        // response = await recipeService.updateRecipe(reqBody, recipeIdUpdate);
        response = await updateRecipe(reqBody, recipeIdUpdate);
      } else {
        // response = await recipeService.addRecipe(reqBody);
        response = await addRecipe(reqBody);
      }

      if (response && response.status === 200) {
        showToastMessage(undefined, response.data.message, "success", false);
        // history.push("recipe");
        props.history.push("/product-catalog/recipe");
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }

    setIsLoading(false);
  };

  const addNewRow = () => {
    let data = recipe;

    data.push({
      quantity: "",
      ingredients_type_id: "",
      ingredients_raw_material_id: "",
      ingredients_unit_id: "",
      ingredients_unit:"-",
      ingredients_type:"-"
    });

    setRecipe([...data]);
  };

  //delete recipe row run time from row data
  const deleteRow = (value) => {
    setRecipe(recipe.filter((s) => s !== value));
  };

  return (
    <React.Fragment>
      <Header title="Recipe" />
      <div id="MainContent" className="main-content">
        <form>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <Select
                label="Product"
                parentClass="text-left"
                value={stockProductId}
                required={false}
                submitStatus={submitStatus}
                onChange={(data) => handleOnChange("stockProductId", data)}
                options={productsList}
                disabled={false}
              />
            </div>
            <div className="col-md-4 col-xs-12">
              <Select
                label="Variation"
                parentClass="text-left"
                value={stockVariationId}
                required={false}
                submitStatus={submitStatus}
                onChange={(data) => handleOnChange("stockVariationId", data)}
                options={variationsList}
                disabled={false}
              />
            </div>
          </div>
          <div className="">
            <table className="rt-table table border-bottom">
              <thead className="rt-thead -header">
                <tr className="rt-tr">
                  <th className="rt-th rt-resizable-header">Raw Material</th>
                  <th className="rt-th rt-resizable-header">Type</th>
                  <th className="rt-th rt-resizable-header">Unit</th>
                  <th className="rt-th rt-resizable-header">Quantity</th>
                  <th className="rt-th rt-resizable-header">Action</th>
                </tr>
              </thead>
              <tbody className="rt-tbody">
                <AddRecipeTable
                  add={addNewRow}
                  delete={deleteRow.bind()}
                  recipe={recipe}
                  onChange={handleOnChange}
                  submitStatus={submitStatus}
                  rowMaterialList={rowMaterialList}
                />
              </tbody>
            </table>
            <div class="text-center">
              <span
                onClick={handleSubmitOnClick}
                className="btn gray-btn d-inline-block mb-3 mr-2"
              >
                Save
              </span>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
// export default AddRecipe;
export default withRouter(AddRecipe);
