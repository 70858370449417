import { CmsPageActions } from "./cmsPageActions";

const clearCmsPage = {
  title: '',
  slug: '',
  description: '',
  seo: {
    id: 0,
    parent_id: 0,
    module: "pages",
    is_robot: false,
    is_no_follow: false,
    is_no_index: false,
    image_alt_text: "",
    page_title: "",
    meta_title: "",
    meta_description: "",
    canonical_url: "",
    og_title: "",
    og_description: "",
    og_image_url: "",
    og_image_alt: "",
    og_url: "",
    og_image_type: "",
    og_site_name: "",
    og_image_height: 0,
    og_image_width: 0,
    twitter_title: "",
    twitter_image_url: "",
    twitter_description: "",
    twitter_site: "",
    twitter_creator: "",
    schema: "",
  },
};

function cmsPageReducer(state, action) {
  switch (action.type) {

    case CmsPageActions.UPDATE_SEO_DATA:
      let seoData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: seoData,
      };
    case CmsPageActions.CHECK_SEO_DATA:
      return {
        ...state,
        seoError: action.payload,
      };
    case CmsPageActions.UPDATE_OG_INFO_DATA:
      let ogInfoData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: ogInfoData,
      };
    case CmsPageActions.CHECK_OG_INFO_DATA:
      return {
        ...state,
        ogInfoError: action.payload,
      };
    case CmsPageActions.UPDATE_TWITTER_INFO_DATA:
      let twitterInfoData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: twitterInfoData,
      };
    case CmsPageActions.CHECK_TWITTER_INFO_DATA:
      return {
        ...state,
        twitterInfoError: action.payload,
      };
    case CmsPageActions.UPDATE_SCHEMA_DATA:
      let schemaData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: schemaData,
      };
    case CmsPageActions.CHECK_SCHEMA_DATA:
      return {
        ...state,
        schemaError: action.payload,
      };
    case CmsPageActions.CLEAR_DATA_PAGE:
      return clearCmsPage

    case CmsPageActions.SET_PAGE_NAME:
      return {
        ...state,
        title: action.payload,
      };
    case CmsPageActions.SET_PAGE_SLUG_NAME:
      return {
        ...state,
        slug: action.payload,
      };
    case CmsPageActions.SET_PAGE_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };

    default:
      return state;
  }
}

export default cmsPageReducer;