import { useFormik } from "formik";

export function ProductVariationInfoForm(
      submit,
      initialVal,
      cb,
      checkProductVariantData
) {
      const validation = (values) => {
            let errors = {};
            let productVariationError = false;
            if (!values.title) {
                  errors.title = "Title required";
                  productVariationError = true;
            } else {
                  productVariationError = false;
            }

            if (!values.price) {
                  errors.price = "Price required";
                  productVariationError = true;
            } else {
                  productVariationError = productVariationError ?? false;
            }

            if (!values.sale_price) {
                  errors.sale_price = "Sales Price required";
                  productVariationError = true;
            } else {
                  productVariationError = productVariationError ?? false;
            }

            // if (!values.status) {
            //       errors.status = "Status required";
            //       productVariationError = true;
            // } else {
            //       productVariationError = false;
            // }


            if (cb) {
                  cb(values);
            }
            checkProductVariantData(productVariationError);
            return errors;
      };

      const initialValues = {
            title: "",
            sku: "",
            commission: 0,
            price: "",
            sale_price: "",
            inventory: 1,
            image: "",
            status: "",
            is_default_selected: false,
            is_sold_out: false,
            placeholder_background: "",
            placeholder_text: "",
            checklist: "",
            important_note: "",
      };


      const formik = useFormik({
            initialValues: initialVal || initialValues,
            onSubmit: values => {
                  formik.resetForm();
            },
            // onCancel: (initialValues, { resetForm }) => resetForm(),
            // onReset: (initialValues, { resetForm }) => resetForm(),
            enableReinitialize: true,
            validate: validation,
      });

      return { formik };
}