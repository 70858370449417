import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";

import CardContent from "@material-ui/core/CardContent";

// Components
import Header from "../../../Components/Header/Header";
import PrimaryInformation from "./PrimaryInformation";
import Seo from "../../../Components/Cms/Seo";
import OGInfo from "../../../Components/Cms/OgInfo";
import Twitterinfo from "../../../Components/Cms/TwitterInfo";
// import Editer from "../../../Components/Cms/Schema";

//scss
import classes from "./index.module.scss";

// Context
import { getAccessByModuleName } from "../../../Routes/Routes";
import { AppContext } from "../../../Context/AppContext";
import { MODULES } from "../../../Helpers/Constants";

import { useCmsPage } from "../../../Context/Cms-page/useCmsPage";

// Services
import CmsPagesApi from "../../../Services/CmsPages/CmsPages";
import {
  getCmsPagesDetail,
  createCmsPages,
  updateCmsPages,
} from "../../../api/cmsPages";

const AddPages = (props) => {
  const { showToastMessage, modules, setIsLoading } = useContext(AppContext);

  const [access, setAccess] = useState({});
  const [updatePageId, setUpdatePageId] = useState("");

  const CmsPagesService = new CmsPagesApi();

  const {
    cmsPageState,
    clearCmsPageState,
    setSeoData,
    checkSeoData,
    setOgInfoData,
    checkOgInfoData,
    setTwitterInfoData,
    checkTwitterInfoData,
    setPageName,
    setPageSlugName,
    setPageDescription,
  } = useCmsPage();

  useEffect(() => {
    clearCmsPageState();
    const { updateId } = props.match.params;
    if (updateId && updateId !== undefined && updateId !== null) {
      setUpdatePageId(updateId);
      getPageData(updateId);
    }
  }, []);

  const getPageData = async (updateId) => {
    setIsLoading(true);
    // const response = await CmsPagesService.getCmsPagesDetail(updateId);
    const response = await getCmsPagesDetail(updateId);
    if (response && response.status === 200) {
      let tempResponseData = { ...response.data.data };
      // console.log("tempResponseData=>",tempResponseData.seo);
      setPageName(tempResponseData.title);
      setPageSlugName(tempResponseData.slug);
      setPageDescription(tempResponseData.description);
      setSeoData(tempResponseData.seo);
      setOgInfoData(tempResponseData.seo);
      setTwitterInfoData(tempResponseData.seo);
      // setSchemaData(tempResponseData.seo);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handleSubmitClick = async (event) => {
    event.preventDefault();
    // console.log("cmsPageState=>", cmsPageState);

    let validationFlag = true;
    let validationMessage = "Please add required data";
    if (!cmsPageState.title || cmsPageState.title?.trim() === "") {
      validationFlag = false;
      validationMessage = "Title is required";
    } else if (!cmsPageState.slug || cmsPageState.slug?.trim() === "") {
      validationFlag = false;
      validationMessage = "Slug is required";
    }

    if (validationFlag) {
      let response;
      // delete cmsPageState.seo.id;
      // delete cmsPageState.seo.parent_id;
      if (updatePageId !== "" && updatePageId !== undefined) {
        let postData = {
          title: cmsPageState.title,
          slug: cmsPageState.slug,
          description: cmsPageState.description,
          seo: cmsPageState.seo,
        };
        // response = await CmsPagesService.updateCmsPages(postData, updatePageId);
        response = await updateCmsPages(postData, updatePageId);
      } else {
        let postData = {
          title: cmsPageState.title,
          slug: cmsPageState.slug,
          description: cmsPageState.description,
          seo: cmsPageState.seo,
        };
        // response = await CmsPagesService.createCmsPages(postData);
        response = await createCmsPages(postData);
      }

      if (response && response.status === 200) {
        showToastMessage(undefined, response.data.message, "success", false);
        clearCmsPageState();
        props.history.push("/cms/cms-page");
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    } else {
      showToastMessage(undefined, validationMessage, "error", false);
    }
  };

  return (
    <React.Fragment>
      <Header title="Cms Page" />
      <div className="row">
        <div id="MainContent">
          <div className={`${classes.cardBody}`}>
            <CardContent>
              <PrimaryInformation data={""} />

              <Seo
                data={cmsPageState.seo}
                setSeoData={setSeoData}
                checkSeoData={checkSeoData}
              />
              <OGInfo
                data={cmsPageState.seo}
                setOgInfoData={setOgInfoData}
                checkOgInfoData={checkOgInfoData}
              />
              <Twitterinfo
                data={cmsPageState.seo}
                setTwitterInfoData={setTwitterInfoData}
                checkTwitterInfoData={checkTwitterInfoData}
              />
              <div className={"col-md-6"}>
                <button
                  onClick={handleSubmitClick}
                  className="btn gray-btn d-inline-block mb-1 mr-2"
                >
                  Save
                </button>
              </div>
            </CardContent>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(AddPages);
