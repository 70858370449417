import { Drawer } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import classes from "./styles.module.scss";
const TableSectionDrawer = ({ open, onClose, children }) => {
  return (
    <Drawer
      anchor="bottom"
      className={classes.Drawer}
      open={open}
      onClose={onClose}
    >
      <Close
        onClick={onClose}
        style={{
          position: "fixed",
          top: "10px",
          right: "10px",
          cursor: "pointer",
        }}
      />
      {children}
    </Drawer>
  );
};

export default TableSectionDrawer;
