import { callPostApi, callDeleteApi, callPutApi, callGetApi } from "./api";

export async function getCmsPagesList(data) {
      // return this.interceptors.POST("page", data);
      try {
            const response = await callPostApi({ url: "page", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getCmsPagesDetail(pageId) {
      // return this.interceptors.GET("page/" + pageId);
      try {
            const response = await callGetApi({ url: "page/" + pageId });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function createCmsPages(postData) {
      // return this.interceptors.POST("page/add", postData);
      try {
            const response = await callPostApi({ url: "page/add", body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateCmsPages(postData, pageId) {
      // return this.interceptors.PUT("page/" + pageId, postData);
      try {
            const response = await callPutApi({ url: "page/" + pageId, body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function deleteCmsPage(id) {
      // return this.interceptors.DELETE("page/" + id);
      try {
            const response = await callDeleteApi({ url: "page/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}


