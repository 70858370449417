import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import styles from "./index.module.scss";
import ReactTooltip from "react-tooltip";
import moment from "moment";
// tooltip finction
function renderDay(day, tooltipDay, msg) {
  const date = day.getDate();
  const month = day.getMonth();
  let tooltp = tooltipDay?.find((d) => d.date === date && d.month === month);
  console.log("tooltp", tooltp, tooltipDay, msg);
  const dateStyle = {
    // position: "",
    bottom: 0,
    right: 0,
    fontSize: "13px",
  };
  const cellStyle = {
    minHeight: "2.5rem",
    minWidth: "2.5rem",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  };
  return (
    <>
      {tooltp?.date === date && month === tooltp?.month ? (
        <>
          <div
            style={{ ...cellStyle, backgroundColor: "red" }}
            data-tip
            data-for={tooltp?.id}
          >
            <div style={{ ...dateStyle }}>{date}</div>
          </div>
          <ReactTooltip
            // className="tooltip"
            effect="solid"
            arrowColor="#000"
            id={tooltp?.id}
            place="top"
            backgroundColor="#000"
          >
            <p style={{ fontSize: "1rem", padding: "0.5rem 0" }}>Hello</p>
          </ReactTooltip>
        </>
      ) : (
        <div style={cellStyle}>
          <div style={dateStyle}>{date}</div>
        </div>
      )}
    </>
  );
}

//  Custom textfiled
export const MyTextFiled = (props) => {
  const [isFocused, setIsFocused] = React.useState(false);
  let { value, onFocus, onBlur } = props;
  let dateformate = new Date(value);
  let day =
    dateformate.getDate() < 9
      ? `0${dateformate.getDate()}`
      : dateformate.getDate();
  let month =
    dateformate.getMonth() < 9
      ? `0${1 + dateformate.getMonth()}`
      : `${1 + dateformate.getMonth()}`;
  // let monthvalidation = dateformate.getMonth() <= 9;
  let year = dateformate.getFullYear();

  let selectedDate = value !== "" ? `${month}-${day}-${year}` : "";
  console.log("selectedDate", moment(value), props);

  return (
    <div
      className={`form-group bmd-form-group ${isFocused ? "is-focused" : ""} ${
        value ? "is-filled" : ""
      } `}
    >
      <label className={`${styles.bmdLabelFloating} bmd-label-floating`}>
        Delivery date
      </label>
      <input
        type="text"
        onBlur={onBlur}
        onFocus={onFocus}
        value={selectedDate ?? ""}
        required={true}
        className="form-control"
      />
    </div>
  );
};

const DatePicker = ({
  onClick,
  disableDays,
  onSelect,
  selectedDate,
  ...props
}) => {
  let dDays = disableDays ? disableDays : [];
  // const [Days, setDays] = useState(selectedDate || undefined);
  let tooltipArray = [{ id: "abc", date: 13, month: 4, message: "Hello" }];
  const handleDayClick = (day, modifiers) => {
    // let selectedDays = da;

    if (modifiers.disabled) {
      // Day is disabled, do nothing
      return;
    }
    onSelect(day);
  };
  return (
    <div>
      <DayPickerInput
        component={(props) => <MyTextFiled {...props} />}
        classNames={`w-100 border border-dark round`}
        // renderDay={(date) => renderDay(date, tooltipArray)}
        onDayClick={handleDayClick}
        dayPickerProps={{
          //   disabledDays: [{ before: new Date() }, ...dDays],
          showOutsideDays: true,
          selectedDays: selectedDate,
          renderDay: (date) => {
            console.log("render day", date, tooltipArray);
            return renderDay(date, tooltipArray, "hello");
          },
          // onDayClick: handleDayClick,
        }}
      />
      <a href data-tip data-for="happyFace">
        d(`･∀･)b
      </a>
      <ReactTooltip
        id="happyFace"
        effect="solid"
        arrowColor="#000"
        place="top"
        backgroundColor="#000"
      >
        <span>Show happy face</span>
      </ReactTooltip>
    </div>
  );
};

export default DatePicker;
