import React, { useState, useEffect, useContext } from "react";

// SCSS
import styles from "./index.module.scss";

// Components
import Header from "./../../Components/Header/Header";
import Text from "./../../Components/FormControl/Text/Text";
import Select from "./../../Components/FormControl/Select/Select";
import ChangePassword from "./../../Components/Modal/ChangePassword/ChangePassword";
import { formatUsPhone } from "./../../Hooks/commanUtility";

// Services
import Users from "./../../Services/Users/Users";
import {
  getProfile,
  getCountries,
  getTimezones,
  getStates,
  updateProfile,
  uploadProfile,
  changePassword,
} from "api/user";

// Context
import { AppContext } from "./../../Context/AppContext";
// import { MODULES } from "./../../Helpers/Constants";
// import { getAccessByModuleName } from "./../../Routes/Routes";

// Images
import ProfilePicture from "./../../Assets/images/profile-pic.png";
// import PhoneInput from "react-phone-input-2";
import {
  DEFAULT_COUNTRY_CODE_NAME,
  DEFAULT_COUNTRY_CODE,
} from "Helpers/Constants";
import PhoneNumber from "Components/FormControl/PhoneNumber/PhoneNumber";

const Profile = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState(DEFAULT_COUNTRY_CODE);
  const [email, setEmail] = useState("");
  const [office, setOffice] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [timezone, setTimezone] = useState("");
  const [user, setUser] = useState({});
  const [countries, setCountries] = useState([]);
  const [statesList, setStateList] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [submitChangePasswordStatus, setSubmitChangePasswordStatus] = useState(
    false
  );
  const [showModal, setShowModal] = useState(false);
  // const [access, setAccess] = useState({});

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const usersService = new Users();

  const {
    showToastMessage,
    handleChangeTimezone,
    setIsLoading,
    modules,
  } = useContext(AppContext);

  /* Check Module Access */
  // useEffect(getModuleAccess, [modules]);

  // function getModuleAccess() {
  //     let access = getAccessByModuleName(modules, MODULES.USER_PROFILE);
  //     if (access) setAccess(access.permissions);
  //     if (access && !access.permissions.write_access) {
  //     showToastMessage(
  //         undefined,
  //         "You are not authorized to access.",
  //         "error",
  //         false
  //     );
  //     props.history.push(`/users`);
  //     }
  // }

  useEffect(() => {
    handleGetCountries();
    handleGetTimezones();
    handleGetProfileDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetProfileDetails = async () => {
    setIsLoading(true);
    // const response = await usersService.getProfile();
    const response = await getProfile();

    setIsLoading(false);
    if (response && response.status === 200) {
      setUser(response.data.data);
      response.data.data.first_name &&
        setFirstName(response.data.data.first_name);
      response.data.data.last_name && setLastName(response.data.data.last_name);
      response.data.data.email && setEmail(response.data.data.email);
      response.data.data.office && setOffice(response.data.data.office);
      response.data.data.mobile && setMobile(response.data.data.mobile);
      response.data.data.country_code &&
        setCountryCode(response.data.data.country_code);
      response.data.data.city && setCity(response.data.data.city);
      response.data.data.zip_code && setZipCode(response.data.data.zip_code);

      if (response.data.data.country_id) {
        setCountry(response.data.data.country_id);

        await handleGetState(response.data.data.country_id);

        response.data.data.state_id && setState(response.data.data.state_id);
      }

      if (response.data.data.default_timezone_id) {
        setTimezone(response.data.data.default_timezone_id);

        let selectedTimezone = timezoneList.find(
          (timezoneObj) =>
            timezoneObj.id === response.data.data.default_timezone_id
        );
        if (selectedTimezone && selectedTimezone.id && selectedTimezone.zone) {
          handleChangeTimezone(selectedTimezone.zone);
        }
      }
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const handleGetCountries = async () => {
    // const response = await usersService.getCountries();
    const response = await getCountries();

    if (response && response.status === 200) {
      setCountries(response.data.data);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const handleGetTimezones = async () => {
    // const response = await usersService.getTimezones();
    const response = await getTimezones();

    if (response && response.status === 200) {
      setTimezoneList(response.data.data);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const handleGetState = async (id) => {
    // const response = await usersService.getStates(id);
    const response = await getStates(id);

    if (response && response.status === 200) {
      setStateList(response.data.data);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const handleUpdateProfile = async (event) => {
    event.preventDefault();
    setSubmitStatus(true);

    if (
      firstName &&
      lastName &&
      mobile &&
      office &&
      city &&
      state &&
      country &&
      zipCode
    ) {
      let reqBody = {
        first_name: firstName,
        last_name: lastName,
        mobile: mobile,
        office: office,
        city: city,
        state_id: state,
        country_id: country,
        default_timezone_id: timezone,
        zip_code: zipCode,
        country_code: countryCode,
      };
      setIsLoading(true);
      //   const response = await usersService.updateProfile(reqBody);
      const response = await updateProfile(reqBody);
      setSubmitStatus(false);

      if (response && response.status === 200) {
        await handleGetProfileDetails();
        showToastMessage(undefined, response.data.message, "success", false);
      } else showToastMessage(undefined, response.data.message, "error", false);

      setIsLoading(false);
    }
  };

  const handleUploadProfile = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    // let reqBody = { media: event.target.files[0] };
    let imageFormdata = new FormData();
    imageFormdata.append("media", event.target.files[0]);

    // const response = await usersService.uploadProfile(reqBody);
    const response = await uploadProfile(imageFormdata);

    if (response && response.status === 200) {
      await handleGetProfileDetails();
      showToastMessage(undefined, response.data.message, "success", false);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();
    setSubmitChangePasswordStatus(true);

    if (currentPassword && newPassword && confirmPassword) {
      let reqBody = {
        current_password: currentPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      };
      setIsLoading(true);
      //   const response = await usersService.changePassword(reqBody);
      const response = await changePassword(reqBody);
      setSubmitChangePasswordStatus(false);

      if (response && response.status === 200) {
        showToastMessage(undefined, response.data.message, "success", false);
        setShowModal(false);
      } else showToastMessage(undefined, response.data.message, "error", false);

      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Header title="Edit Profile" />
      {/* {access.read_access && access.write_access && ( */}
      <div id="MainContent" className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 mt-md-5 mt-3">
              <div className="row">
                <div className="col-md-4">
                  <div
                    className={`position-relative ${styles.profilePictureWrapper}`}
                  >
                    <img
                      src={
                        user.profile_image ? user.profile_image : ProfilePicture
                      }
                      alt={user.username}
                    />
                    <span className={`position-absolute ${styles.editPicIcon}`}>
                      <i className="icon-edit"></i>
                      <input
                        type="file"
                        onChange={(event) => handleUploadProfile(event)}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <div className="d-block input-info mb-4">
                    <span className="d-block mb-1">Role</span>
                    {user.role && user.role.title ? user.role.title : "-"}
                  </div>
                  <div className="d-block input-info mb-4">
                    <span className="d-block mb-1">Username</span>
                    {user.username}
                  </div>
                </div>
                <div className="col-md-4 col-lg-4">
                  <a
                    href="/"
                    onClick={(event) => {
                      event.preventDefault();
                      setShowModal(true);
                    }}
                    className="btn btn-block gray-btn bold px-2 text-normal"
                  >
                    Change Password
                  </a>
                </div>
              </div>

              <h3 className="bold mt-5">Personal Information</h3>

              <div className="row">
                <div className="col-md-6">
                  <Text
                    type="text"
                    label="First Name"
                    value={firstName}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(firstName) => setFirstName(firstName)}
                  />
                </div>
                <div className="col-md-6">
                  <Text
                    type="text"
                    label="Last Name"
                    value={lastName}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(lastName) => setLastName(lastName)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  {/* <Text
                    type="tel"
                    label="Mobile"
                    // value={mobile}
                    value={formatUsPhone(mobile)}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(mobile) => {
                      if (mobile.length <= 10) {
                        setMobile(mobile);
                      }
                    }}
                  /> */}

                  <PhoneNumber
                    countryCode={DEFAULT_COUNTRY_CODE_NAME}
                    specialLabel={"Mobile"}
                    initValue={countryCode + mobile}
                    fieldName={`Mobile`}
                    onChange={(phone, countryData, event, formatedValue) => {
                      let countryCode = "+" + countryData.dialCode;
                      let finalMobileNum = formatedValue
                        ? formatedValue?.split(countryCode)[1]?.trim()
                        : "";
                      setCountryCode(countryCode);
                      setMobile(finalMobileNum);
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <Text
                    type="email"
                    label="Email"
                    disabled={true}
                    value={email}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(email) => this.setEmail(email)}
                  />
                </div>
              </div>

              <h3 className="bold mt-5 pt-2 mb-0">Address Information</h3>

              <Text
                type="text"
                label="Apt/Unit/Office#"
                value={office}
                required={true}
                submitStatus={submitStatus}
                onChange={(office) => setOffice(office)}
              />

              <div className="row">
                <div className="col-md-3 col-sm-6 col-xs-12">
                  <Select
                    label="Country"
                    value={country}
                    onChange={(data) => {
                      setCountry(data);
                      handleGetState(data);
                    }}
                    options={countries}
                    disabled={false}
                  />
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12">
                  <Select
                    label="State"
                    value={state}
                    onChange={(data) => setState(data)}
                    options={statesList}
                    disabled={false}
                  />
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12">
                  <Text
                    type="text"
                    label="City"
                    value={city}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(city) => setCity(city)}
                  />
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12">
                  <Text
                    type="text"
                    label="Zip code"
                    value={zipCode}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={(zipCode) => setZipCode(zipCode)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-xs-12">
                  <Select
                    label="Timezone"
                    value={timezone}
                    onChange={(data) => setTimezone(data)}
                    options={timezoneList}
                    disabled={false}
                  />
                </div>
              </div>

              {/* {access.write_access && ( */}
              <div className="d-block text-center mt-3 mb-5">
                <button
                  onClick={(e) => handleUpdateProfile(e)}
                  className="btn gray-btn bold"
                >
                  Save
                </button>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      {/* )} */}

      {/* {access.write_access && ( */}
      <ChangePassword
        showModal={showModal}
        currentPassword={currentPassword}
        newPassword={newPassword}
        confirmPassword={confirmPassword}
        submitStatus={submitChangePasswordStatus}
        onChangePassword={(type, value) => {
          if (type === "currentPassword") setCurrentPassword(value);
          if (type === "newPassword") setNewPassword(value);
          if (type === "confirmPassword") setConfirmPassword(value);
        }}
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowModal(false);
          setCurrentPassword("");
          setNewPassword("");
          setSubmitChangePasswordStatus(false);
          setConfirmPassword("");
        }}
        handleSubmitClick={handleChangePassword}
      />
      {/* )} */}
    </React.Fragment>
  );
};

export default Profile;
