import Interceptors from "../Interceptors/Interceptors";

class Customers {
  constructor() {
    this.interceptors = new Interceptors();
  }

  async getCustomerList(data) {
    return this.interceptors.POST("customers/customer-lookup", data);
  }

  async getCustomerWithOrderDetails(data) {
    return this.interceptors.POST(
      "customers/customer-with-order-details",
      data
    );
  }

  async updateCustomer(data) {
    return this.interceptors.POST("customers/edit", data);
  }

  async getCustomerPetalRewardsList(data) {
    return this.interceptors.POST("customers/customer-petal-rewards-lookup", data);
  }


  async getCustomerDefaultBillingAddressBook(data) {
    return this.interceptors.POST(
      "customers/default-billing-address-book",
      data
    );
  }
  async getCustomerdata(data) {
    return this.interceptors.POST(
      "customers/customer-with-order-details",
      data
    );
  }
  async getCustomerRecipients(data) {
    return this.interceptors.POST("customers/customer-address-book", data);
  }
  async getCustomerCard(data) {
    return this.interceptors.POST("customers/card-admin", data);
  }
  async addCardTocustomer(data) {
    return this.interceptors.POST("customers/card/addcardbyadmin", data);
  }
}

export default Customers;
