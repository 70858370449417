import { Close } from "@material-ui/icons";
import NewModal from "Components/NewModal/NewModal";
import { OrderConfigContext } from "Context/OrderConfigContext";
import { useFormikContext } from "formik";
import { DateTime } from "luxon";
import moment from "moment";
import React, { useContext } from "react";
import List from "../../../../Components/OrderComponents/TableSection/ProductComponent/List";
import Menu from "../../../../Components/OrderComponents/TableSection/ProductComponent/Menu";
import SortAndSearch from "../../../../Components/OrderComponents/TableSection/ProductComponent/SortAndSearch";
import VariationView from "../../../../Components/OrderComponents/TableSection/ProductComponent/VariationView";
import { useProductCall } from "../hooks/useProductCall";
import { PST_ZONE, UTC_ZONE } from "../utility/util";

const ProductContentNewContext = (props) => {
  const {
    menu,
    setFilter,
    setPage,
    render,
    setRender,
    setIsLoading,
    getProducts,
    setSort,
    page,
    totalCount,
    productsToAdd,
    productList,
    showToastMessage,
    setCurrentSelected,
    currentSelected,
    setProductsToAdd,
  } = useProductCall();

  const formik = useFormikContext();
  const config = useContext(OrderConfigContext);

  return (
    <div style={{ height: "100vh", backgroundColor: "#fff" }}>
      <div className="container-fluid">
        <h1 className="text-center">Select Product</h1>
        <Menu
          list={menu}
          applyFilter={(filter) => {
            setFilter(filter);
            setPage(0);
            setRender(render + 1);
          }}
        />
        <SortAndSearch
          search={async (value) => {
            setIsLoading(true);
            await getProducts(value);
            setIsLoading(false);
          }}
          applySort={(sort) => {
            setSort(sort);
            setPage(0);
            setRender(render + 1);
          }}
        />
        <List
          loadMore={async () => {
            setIsLoading(true);
            await getProducts("", page + 1);
            setPage(page + 1);
            setIsLoading(false);
          }}
          totalCount={totalCount}
          selectedProducts={productsToAdd}
          lists={productList}
          onCardClick={(c) => {
            // if (c.is_out_of_stock) {
            //   showToastMessage(
            //     undefined,
            //     `${c.title} is currently out of stock`,
            //     "error",
            //     false
            //   );
            //   return;
            // }
            // if (c.is_sold_out) {
            //   showToastMessage(
            //     undefined,
            //     `${c.title} is currently out of stock`,
            //     "error",
            //     false
            //   );
            //   return;
            // }
            if (
              moment(
                formik.values.delivery_information.delivery_date
              ).isValid() &&
              formik.values.delivery_information.order_type !== 1
            ) {
              // let selected_date = DateTime.fromFormat(
              //   formik.values.delivery_information.delivery_date,
              //   "yyyy-LL-dd",
              //   { zone: PST_ZONE }
              // ).setZone(UTC_ZONE);

              //Lead Time Check
              //open when you want to check lead time
              // let selected_date_moment = moment(formik.values.delivery_information.delivery_date)
              // if (c.lead_time > 0) {
              //   let current_date = moment();
              //   // debugger;
              //   // console.log("current_date",current_date);
              //   // console.log("selected_date_moment",selected_date_moment);
              //   let diff = selected_date_moment.diff(current_date, "days");
              //   // console.log(diff);
              //   if (diff < c.lead_time) {
              //     showToastMessage(
              //       undefined,
              //       `${c.title} is currently unavailable for order due to ${c.lead_time} days lead time.`,
              //       "error",
              //       false
              //     );
              //     return;
              //   }
              // }

              //Holiday Cutoff check
              //open when you want to check holiday cutoff
              // let holidays = config.holidayCutOff;
              // let applicableHoliday = holidays.find(
              //   (z) =>
              //     z.start_date <= selected_date.toSeconds() &&
              //     selected_date.toSeconds() < z.end_date
              // );
              // if (applicableHoliday) {
              //   let product_available = false;
              //   let product_category_hierarchy_ids = c.product_categories.map(
              //     (z) => z.category_hierarchy_id
              //   );
              //   applicableHoliday.holiday_cutoff_exclude_categories.forEach(
              //     (x) => {
              //       if (
              //         product_category_hierarchy_ids.includes(
              //           x.category_hierarchy_id
              //         )
              //       ) {
              //         product_available = true;
              //       }
              //     }
              //   );
              //   if (!product_available) {
              //     showToastMessage(
              //       undefined,
              //       `${c.title} is currently unavailable for order due to holiday period.`,
              //       "error",
              //       false
              //     );
              //     return;
              //   }
              // }
            }

            setCurrentSelected(c);
          }}
        />
      </div>
      <div
        className="py-3 text-center"
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          background: "#fff",
          zIndex: 20,
          boxShadow: "0 0 10px hsl(0deg 0% 76% / 50%)",
        }}
      >
        <button
          className="btn gray-btn"
          onClick={() => {
            props.onAdd(productsToAdd);
            props.onClose();
          }}
        >
          Add
        </button>
      </div>
      <NewModal show={!!currentSelected}>
        <Close
          onClick={() => {
            setCurrentSelected(null);
          }}
          style={{
            position: "fixed",
            top: 10,
            right: 10,
            fontSize: "2rem",
            cursor: "pointer",
          }}
        />
        <VariationView
          productSelected={productsToAdd}
          currentProduct={currentSelected}
          onVariationClick={(c) => {
            // if (c.is_sold_out) {
            //   showToastMessage(
            //     undefined,
            //     `${c.title} variation is currently sold out.`,
            //     "error",
            //     false
            //   );
            //   return;
            // }
            let added_products = [...productsToAdd];
            let alreadyAddedIndex = added_products.findIndex(
              (x) =>
                x.id === currentSelected.id && x.selectedVariant.id === c.id
            );
            let product = { ...currentSelected };
            product.qty = 1;
            product.total_price = c.sale_price;
            //unique key for each product recipent wise
            product.product_key = product.product_key || `prod_${product.id}_${c.id}_${new Date().getTime()}`; 
            product.selectedVariant = c;
            product.selectedVariant.price = c.sale_price;

            if (alreadyAddedIndex === -1) {
              added_products.push(product);
            } else {
              added_products.splice(alreadyAddedIndex, 1);
            }
            setProductsToAdd(added_products);
            setCurrentSelected(null);
          }}
        />
      </NewModal>
    </div>
  );
};

export default ProductContentNewContext;
