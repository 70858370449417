import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";

// Scss
import styles from "./index.module.scss";

// Context
import { AppContext } from "./../../../Context/AppContext";
import { getAccessByModuleName } from "./../../../Routes/Routes";
import { MODULES, STATUS_FILTER } from "./../../../Helpers/Constants";

// Components
import Header from "./../../../Components/Header/Header";
import ReactTable from "../../../Components/Table/Table";
import DeleteConfirmation from "./../../../Components/Modal/DeleteConfirmation/DeleteConfirmation";
import StatusConfirmation from "../../../Components/Modal/StatusConfirmation/StatusConfirmation";
import AddFaqModal from "../../../Components/Modal/Faqs/AddFaq";

// Services
import UtilsApi from "../../../Services/Utils/Utils";
import CmsFaqApi from "../../../Services/CmsFaqs/CmsFaqs";
import { updateStatus } from "../../../api/utils";
import {
  getFaqList,
  deleteFaq,
  createFaq,
  updateFaq,
} from "../../../api/cmsFaqs";

// Images
import EditIcon from "./../../../Assets/images/edit.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";
import ActivatedIcon from "./../../../Assets/images/activated.svg";

const CmsFaq = (props) => {
  const history = useHistory();

  const utilsService = new UtilsApi();
  const CmsFaqService = new CmsFaqApi();

  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);

  const [access, setAccess] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [defaultFilter, setDefaultFilter] = useState({
    currentPage: 0,
    itemsPerPage: 10,
    sortBy: [],
    filters: [],
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCmsFaqId, setDeleteCmsFaqId] = useState();
  const [statusCmsFaqId, setStatusCmsFaqId] = useState(false);
  const [statusOfCmsFaq, setStatusOfCmsFaq] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);

  const [faqQuestion, setFaqQuestion] = useState("");
  const [faqAnswer, setFaqAnswer] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const statusList = STATUS_FILTER;

  useEffect(getModuleAccess, [modules]);
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.FAQ);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/users");
    }
  }

  const handelDeleteClick = async (event, id) => {
    event.preventDefault();

    setDeleteCmsFaqId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteCmsFaq = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // const response = await CmsFaqService.deleteFaq(deleteCmsFaqId);
    const response = await deleteFaq(deleteCmsFaqId);
    if (response && response.status === 200) {
      setDeleteCmsFaqId(null);
      setShowDeleteModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  const handleCmsFaqClick = async (event, id, status) => {
    event.preventDefault();

    setStatusCmsFaqId(id);
    setStatusOfCmsFaq(status);
    setShowStatusModal(true);
  };

  const handleCmsFaqStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfCmsFaq === 1 ? 0 : 1,
      id: statusCmsFaqId,
      table: "cms_faqs",
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setShowStatusModal(false);
      setStatusCmsFaqId(null);
      setStatusOfCmsFaq(null);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
    };
    // const response = await CmsFaqService.getFaqList(postData);
    const response = await getFaqList(postData);

    if (response && response.status === 200) {
      setData(response.data.data.rows);
      setPageCount(Math.ceil(response.data.data.count / pageSize));
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);
  };

  const handleAddCmsFaqClick = async (event, id, data) => {
    event.preventDefault();
    event.preventDefault();
    setUpdateId("");
    setFaqQuestion("");
    setFaqAnswer("");
    if (id && id !== undefined && id !== "") {
      setUpdateId(id);
      setFaqQuestion(data.question);
      setFaqAnswer(data.description);
    }
    setShowAddModal(true);
  };

  const handleManageFaqAddClick = async (faqQuestions, faqAnswers) => {
    let faqApiCall = true;
    let validationMessage = "Please add required data";
    if (
      faqQuestions === "" ||
      faqQuestions === undefined ||
      faqQuestions === null ||
      faqQuestions?.trim() === ""
    ) {
      faqApiCall = false;
      validationMessage = "Please faq question";
    } else if (
      faqAnswers === "" ||
      faqAnswers === undefined ||
      faqAnswers === null ||
      faqAnswers?.trim() === ""
    ) {
      faqApiCall = false;
      validationMessage = "Please faq description";
    }

    if (faqApiCall) {
      let response;
      setLoading(true);
      if (updateId !== "" && updateId !== undefined) {
        let postData = {
          question: faqQuestions,
          description: faqAnswers,
        };
        // response = await CmsFaqService.updateFaq(postData, updateId);
        response = await updateFaq(postData, updateId);
      } else {
        let postData = {
          question: faqQuestions,
          description: faqAnswers,
        };
        // response = await CmsFaqService.createFaq(postData);
        response = await createFaq(postData);
      }
      if (response && response.status === 200) {
        showToastMessage(undefined, response.data.message, "success", false);
        fetchData(defaultFilter);
        setUpdateId("");
        setFaqQuestion("");
        setFaqAnswer("");
        setShowAddModal(false);
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
        fetchData(defaultFilter);
      }
      setLoading(false);
    } else {
      showToastMessage(undefined, validationMessage, "error", false);
    }
  };

  let faqAccess = getAccessByModuleName(modules, MODULES.FAQ);
  const columns = React.useMemo(
    () => [
      // {
      //   Header: "ID",
      //   accessor: "",
      //   isVisible: true,
      //   placeholder: "",
      //   disableFilters: true,
      // },
      {
        Header: "Questions",
        accessor: "question",
        isVisible: true,
        placeholder: "Search by Question",
        disableFilters: true,
      },
      {
        Header: "Status",
        accessor: "status",
        isVisible: true,
        placeholder: "Status",
        isSelectFilter: true,
        selectFilterOption: statusList,
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.status}>
              {row.original.status === 1 ? (
                <div className={styles.Active}>Active</div>
              ) : (
                <div className={styles.Inactive}>Inactive </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Created",
        accessor: "createdAt",
        isVisible: true,
        placeholder: "Date",
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          return `${moment(row.original.createdAt).format("ddd MMM DD, YYYY")}`;
        },
      },
      {
        Header: "Updated",
        accessor: "updatedAt",
        isVisible: true,
        placeholder: "Date",
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          return `${moment(row.original.createdAt).format("ddd MMM DD, YYYY")}`;
        },
      },
      {
        Header: "Action",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {faqAccess && faqAccess.permissions.write_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={EditIcon}
                  alt="Edit"
                  onClick={(e) => handleAddCmsFaqClick(e, value, row.original)}
                />
              )}
              {faqAccess && faqAccess.permissions.write_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={ActivatedIcon}
                  alt="Active"
                  onClick={(e) =>
                    handleCmsFaqClick(e, value, row.original.status)
                  }
                />
              )}

              {faqAccess && faqAccess.permissions.delete_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={DeleteIcon}
                  alt="Delete"
                  onClick={(e) => handelDeleteClick(e, value)}
                />
              )}
            </div>
          );
        },
      },
    ],
    [data]
  );

  return (
    <React.Fragment>
      <Header
        title="CMS Faq"
        hasAddButton={1}
        handleAddClick={handleAddCmsFaqClick}
      />
      <div id="MainContent" className="main-content" />
      <div>
        <ReactTable
          columns={columns}
          data={data}
          expandedRows={true}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
        />
      </div>

      <DeleteConfirmation
        showModal={showDeleteModal}
        title="Delete Faq"
        message="Are you sure want to delete this faq?"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteModal(false);
          setDeleteCmsFaqId(null);
        }}
        handleSubmitClick={handleDeleteCmsFaq}
      />

      <StatusConfirmation
        showModal={showStatusModal}
        title={"Faq"}
        message={"faq?"}
        buttonLabel={statusOfCmsFaq ? "Inactivate" : "Activate"}
        statusFlag={statusOfCmsFaq}
        customFlag={false}
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowStatusModal(false);
          setStatusCmsFaqId(null);
          setStatusOfCmsFaq(null);
        }}
        handleSubmitClick={handleCmsFaqStatus}
      />

      <AddFaqModal
        showModal={showAddModal}
        pageTitle="Add FAQs"
        defaultValue={faqQuestion}
        defaultValueSlug={faqAnswer}
        handleCancelClick={(e) => {
          setShowAddModal(false);
        }}
        submitStatus={submitStatus}
        handleSubmitClick={handleManageFaqAddClick}
      />
    </React.Fragment>
  );
};

export default withRouter(CmsFaq);
