import {
  getAddressTypes,
  getBlockOutListActive,
  getDeliveryTypes,
  getFrequnceyList,
  getHolidayListActive,
  getOccasions,
  getPetalrewardsConfig,
  getUpchargeListActive,
} from "api/order";
import {storeList} from "../api/stores";
import LoadingScreen from "Components/Loading/LoadingScreen";
import { createContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";
export const OrderConfigContext = createContext(null);
const OrderConfigProvider = (props) => {
  const [loading, setLoading] = useState(true);
  const [configs, setConfigs] = useState({
    occasionsList: [],
    addressTypesList: [],
    deliveryTypesList: [],
    petalConfig: null,
    frequencyList: [],
    holidayCutOff: [],
    upchargeList: [],
    blockoutList: [],
    storeList:[],
  });

  const handleGetPetalrewardConfig = async () => {
    try {
      const response = await getPetalrewardsConfig();
      const cookie = new Cookies();
      cookie.set("petalrewardconfig", response?.data?.data?.value);
      return response?.data?.data?.value;
    } catch (error) {
      throw error;
    }
  };

  const handleGetFrequency = async () => {
    try {
      const response = await getFrequnceyList();
      if (response.status === 200) {
        let freqData = [];
        for (let k in response.data.data) {
          let freDataobj = {
            name: response.data.data[k],
            id: +k,
          };
          freqData.push(freDataobj);
        }
        return freqData;
      }
    } catch (error) {
      throw error;
    }
  };

  const getConfigs = async () => {
    try {
      const response = await getAddressTypes();
      const occResp = await getOccasions();
      let PostData = {
        sortBy: [
          {
            id: "id",
            desc: false,
          },
        ],
      };
      let storelist = await storeList();
      const dellistReps = await getDeliveryTypes(PostData);
      let addressList = response.data.data.rows;
      let occasionList = occResp.data.data.rows;
      let deliveryList = dellistReps.data.data.rows;
      
      let petalConfig = await handleGetPetalrewardConfig();
      let frequencyList = await handleGetFrequency();
      let holidayCutOff = await getHolidayCutoffAll();
      const upchargeResp = await getUpchargeListActive();
      const blockResp = await getBlockOutListActive();
      let uplist = upchargeResp?.data?.data?.rows;
      let bllist = blockResp?.data?.data?.rows;
      setConfigs({
        addressTypesList: addressList,
        occasionsList: occasionList,
        deliveryTypesList: deliveryList,
        petalConfig,
        frequencyList,
        holidayCutOff,
        upchargeList: uplist,
        blockoutList: bllist,
        storeList: storelist.data.data.rows
      });
      setLoading(false);
    } catch (error) {
      console.log("Error getting config");
    }
  };

  const getHolidayCutoffAll = async () => {
    try {
      const response = await getHolidayListActive();
      return response.data.data.rows;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getConfigs();
  }, []);

  return (
    <OrderConfigContext.Provider value={configs}>
      {loading && <LoadingScreen />}
      {props.children}
    </OrderConfigContext.Provider>
  );
};
export default OrderConfigProvider;
