import {
  client,
  hostedFields,
} from "braintree-web";
export function getBraintreeClient(token) {
  return new Promise((resolve, reject) => {
    client.create(
      {
        authorization: token,
      },
      (err, clientInstance) => {
        if (err) {
          return reject(err);
        }
        return resolve(clientInstance);
      }
    );
  });
}

export function getHostedFieldInstance(
  client,
  fields,
  styles
) {
  return new Promise((resolve, reject) => {
    hostedFields.create(
      {
        client: client,
        fields: fields,
        styles: styles,
      },
      (err, hostedFieldInstance) => {
        if (err) {
          return reject(err);
        }
        return resolve(hostedFieldInstance);
      }
    );
  });
}
