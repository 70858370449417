// Packages
import React, { useEffect, useContext } from "react";
import { withRouter } from "react-router";

// SCSS
import styles from "./index.module.scss";

// Context
import { AppContext } from "./../../Context/AppContext";

// Services
import Users from "./../../Services/Users/Users";
import { verifyUsers } from "./../../api/user";

const ResetPassword = (props) => {
  const usersService = new Users();

  const { showToastMessage, logout } = useContext(AppContext);
  let token = new URLSearchParams(props.location.search).get("data");

  useEffect(() => {
    handleVerifyUser(token);
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleVerifyUser = async (token) => {
    if (token) {
      let reqBody = { token };

      //   const response = await usersService.verifyUsers(reqBody);
      const response = await verifyUsers(reqBody);

      if (response && response.status === 200) {
        showToastMessage(undefined, response.data.message, "success", false);
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }

      logout();

      props.history.replace("/login");
    }
  };

  return (
    <React.Fragment>
      <div
        className={`d-flex align-items-center justify-content-center h-100 ${styles.loginWrapper}`}
      >
        <h3>Account verification</h3>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ResetPassword);
