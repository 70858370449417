// Packages
import { withRouter } from "react-router";
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import "moment-timezone";

// Context
import { AppContext } from "./../../../Context/AppContext";

// Components
import Header from "./../../../Components/Header/Header";
import AddChangeRequest from "./../../../Components/Modal/ChangeRequest/Add/ChangeRequest";

// Services
// import Clock from "./../../../Services/Clock/Clock";
import { getClockDetailsById, applyChangeRequest } from "./../../../api/clock";

const TimeClockDetails = (props) => {
  const [clockDetails, setClockDetails] = useState({});
  const [changeRequestClock, setChangeRequestClock] = useState({});
  const [showChangeRequestModal, setChangeRequestModal] = useState(false);
  const [time, setTime] = useState("");
  const [reason, setReason] = useState("");
  const [changeRequestClockDetails, setChangeRequestClockDetails] = useState(
    ""
  );
  // const clockService = new Clock();

  const { showToastMessage, setIsLoading, timezone } = useContext(AppContext);

  useEffect(() => {
    handleGetClockDetails();
  }, [props.match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetClockDetails = async () => {
    const { id } = props.match.params;

    if (!id) return;

    setIsLoading(true);
    // const response = await clockService.getClockDetailsById(id);
    const response = await getClockDetailsById(id);
    if (response && response.status === 200)
      setClockDetails(response.data.data);
    else if (response && response.status === 404) {
    } else showToastMessage(undefined, response.data.message, "error", false);
    setIsLoading(false);
  };

  const handleChangeRequest = async (event) => {
    event.preventDefault();

    let reqBody = {
      reason: reason,
      type: changeRequestClockDetails.clock_in ? "IN" : "OUT",
      clock_times_id: changeRequestClockDetails.id,
      user_id: clockDetails.user_id,
    };

    let changeDay;
    if (changeRequestClockDetails.clock_in) {
      changeDay = moment
        .unix(changeRequestClockDetails.clock_in)
        .tz(timezone)
        .format("YYYY-MM-DD");
    } else {
      changeDay = moment
        .unix(changeRequestClockDetails.clock_out)
        .tz(timezone)
        .format("YYYY-MM-DD");
    }

    let changeTime = moment(time).startOf("minute").format("HH:mm");
    reqBody.time = moment
      .tz(changeDay + " " + changeTime, timezone)
      .utc()
      .unix("X");

    // const response = await clockService.applyChangeRequest(reqBody);
    const response = await applyChangeRequest(reqBody);

    if (response && response.status === 200) {
      setChangeRequestModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      await handleGetClockDetails();
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  return (
    <React.Fragment>
      <Header title="Clock In (Clock Out)" />

      <div id="MainContent" className="main-content">
        <div className="current-date pull-left">
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              props.history.push("/time-clock/history");
            }}
            className="gray-btn d-inline-block text-center"
          >
            {" "}
            Back{" "}
          </a>
        </div>
        <div className="current-date text-right">
          {timezone &&
            clockDetails &&
            clockDetails.clock_time &&
            clockDetails.clock_time.length > 0 &&
            moment
              .unix(clockDetails.clock_time[0].clock_in)
              .tz(timezone)
              .format("ddd MMM DD, YYYY")}
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              {clockDetails.clock_time &&
                clockDetails.clock_time.map((clockTimesObj, index) => {
                  return (
                    <div className="row mb-5" key={index}>
                      <div className="col-3 col-md-4 in-out-label pt-2">
                        {clockTimesObj.clock_in ? "In" : "Out"} -
                      </div>
                      <div
                        className={`col-4 pt-2 ${
                          clockTimesObj.clock_in ? "in-time" : "out-time"
                        }`}
                      >
                        {timezone && clockTimesObj && clockTimesObj.clock_in > 0
                          ? moment
                              .unix(clockTimesObj.clock_in)
                              .tz(timezone)
                              .format("hh:mm A")
                          : timezone &&
                            clockTimesObj &&
                            clockTimesObj.clock_out > 0
                          ? moment
                              .unix(clockTimesObj.clock_out)
                              .tz(timezone)
                              .format("hh:mm A")
                          : ""}
                      </div>

                      <div className="col-5 col-md-4">
                        {((clockTimesObj.clock_in > 0 &&
                          clockTimesObj.changed_request_in_status === 1) ||
                          (clockTimesObj.clock_out > 0 &&
                            clockTimesObj.changed_request_out_status ===
                              1)) && (
                          <a
                            href="/"
                            onClick={(event) => event.preventDefault()}
                            className="light-grey-btn d-block text-center"
                          >
                            Pending
                          </a>
                        )}
                        {((clockTimesObj.clock_in > 0 &&
                          clockTimesObj.changed_request_in_status === 2) ||
                          (clockTimesObj.clock_out > 0 &&
                            clockTimesObj.changed_request_out_status ===
                              2)) && (
                          <a
                            href="/"
                            onClick={(event) => event.preventDefault()}
                            className="light-grey-btn d-block text-center"
                          >
                            Approved
                          </a>
                        )}
                        {((clockTimesObj.clock_in > 0 &&
                          clockTimesObj.changed_request_in_status === 3) ||
                          (clockTimesObj.clock_out > 0 &&
                            clockTimesObj.changed_request_out_status ===
                              3)) && (
                          <a
                            href="/"
                            onClick={(event) => event.preventDefault()}
                            className="light-grey-btn d-block text-center"
                          >
                            Rejected
                          </a>
                        )}
                        {
                          // 0 => No, 1 => Pending, 2 => Accepted, 3 => Rejected
                          ((clockTimesObj.clock_in > 0 &&
                            clockTimesObj.changed_request_in_status === 0) ||
                            (clockTimesObj.clock_out > 0 &&
                              clockTimesObj.changed_request_out_status ===
                                0)) && (
                            <a
                              href="/"
                              className="light-grey-btn d-block text-center"
                              data-toggle="modal"
                              data-target="#requestChange"
                              onClick={(event) => {
                                event.preventDefault();
                                setChangeRequestModal(true);
                                setChangeRequestClockDetails(clockTimesObj);
                                setChangeRequestClock(clockTimesObj);
                              }}
                            >
                              Request Change
                            </a>
                          )
                        }
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <AddChangeRequest
        showModal={showChangeRequestModal}
        clockDetails={changeRequestClock}
        handleCancelClick={(event) => {
          event.preventDefault();
          setChangeRequestModal(false);
          handleGetClockDetails();
          setTime("");
          setReason("");
        }}
        reason={reason}
        setReason={(data) => setReason(data)}
        time={time}
        setTime={(data) => setTime(data)}
        handleSubmitClick={handleChangeRequest}
      />
    </React.Fragment>
  );
};

export default withRouter(TimeClockDetails);
