import React from "react";
import Text from "../FormControl/Text/Text";
import styles from "./index.module.scss";
import { formatUsPhone } from "../../Hooks/commanUtility";

const RecipentForm = (props) => {
  return (
    <div>
      <h4 className="small-heading bold mt-0 mb-4">What's the occasion?</h4>
      <div className={`row`}>
        <div className={`col-md-4 col-xs-12`}>
          <Text
            type="text"
            label="Occasion Type"
            value={
              props.recipientData.whats_the_occasion
                ? props.recipientData.whats_the_occasion
                : "-"
            }
            disabled={true}
          />
        </div>
      </div>
      <div className={`row`}>
        <div className={`col-md-8 col-xs-12`}>
          <div className={`row`}>
            <div className={`col-md-6 col-xs-12`}>
              <Text
                type="text"
                label="First Name"
                value={
                  props.recipientData.first_name
                    ? props.recipientData.first_name
                    : "-"
                }
                disabled={true}
              />
            </div>
            <div className={`col-md-6 col-xs-12`}>
              <Text
                type="text"
                label="Last Name"
                value={
                  props.recipientData.last_name
                    ? props.recipientData.last_name
                    : "-"
                }
                disabled={true}
              />
            </div>
            <div className={`col-md-6 col-xs-12`}>
              <Text
                type="text"
                label="Address Type"
                value={
                  props.recipientData.address_type
                    ? props.recipientData.address_type
                    : "-"
                }
                disabled={true}
              />
            </div>
            <div className={`col-md-6 col-xs-12`}>
              <Text
                type="text"
                label={props.recipientData.attnLabel}
                value={
                  props.recipientData.attn ? props.recipientData.attn : "-"
                }
                disabled={true}
              />
            </div>
            <div className={`col-md-12 col-xs-12`}>
              <Text
                type="text"
                label="Address Line 1"
                value={
                  props.recipientData.address_line_1
                    ? props.recipientData.address_line_1
                    : "-"
                }
                disabled={true}
              />
            </div>
            <div className={`col-md-4 col-xs-12`}>
              <Text
                type="text"
                label={props.recipientData.addressLine2Label}
                value={
                  props.recipientData.address_line_2
                    ? props.recipientData.address_line_2
                    : "-"
                }
                disabled={true}
              />
            </div>
            <div className={`col-md-4 col-xs-12`}>
              <Text
                type="tel"
                label="Phone"
                value={
                  props.recipientData.phone
                    ? props.recipientData.phone_country_code +
                      " " +
                      formatUsPhone(props.recipientData.phone)
                    : "-"
                }
                disabled={true}
              />
            </div>
            <div className={`col-md-4 col-xs-12`}>
              <Text
                type="tel"
                label="ALT Phone"
                value={
                  props.recipientData.alt_phone
                    ? props.recipientData.alt_phone_country_code +
                      " " +
                      formatUsPhone(props.recipientData.alt_phone)
                    : "-"
                }
                disabled={true}
              />
            </div>
            <div className={`col-md-4 col-xs-12`}>
              <Text
                type="text"
                label="City"
                value={
                  props.recipientData.city ? props.recipientData.city : "-"
                }
                disabled={true}
              />
            </div>
            <div className={`col-md-4 col-xs-12`}>
              <Text
                type="text"
                label="State"
                value={
                  props.recipientData.state ? props.recipientData.state : "-"
                }
                disabled={true}
              />
            </div>
            <div className={`col-md-4 col-xs-12`}>
              <Text
                type="text"
                label="Zipcode"
                value={
                  props.recipientData.zipcode
                    ? props.recipientData.zipcode
                    : "-"
                }
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className={`col-md-4 col-xs-12`}>
          <div className={styles.mapWrap}>
            <iframe
              width="100%"
              height="366"
              frameBorder="0"
              title="AddressMaps"
              scrolling="no"
              src={
                props.recipientData.address_line_1 ||
                props.recipientData.zipcode
                  ? `https://maps.google.com/maps?q=${props.recipientData.address_line_1} ${props.recipientData.zipcode}&output=embed`
                  : `https://maps.google.com/maps?q=Los%20Angeles+(My%20Business%20Name)&output=embed`
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipentForm;
