import React, { useEffect, useState, useContext } from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import RecipeSettingsApi from "../../../Services/RecipeSettings/RecipeSettings";
import { useFormik } from "formik";
import _ from "lodash";
// Scss
import styles from "./index.module.scss";
// Component
import Text from "./../../FormControl/Text/Text";
import Select from "./../../FormControl/Select/Select";
import { AppContext } from "./../../../Context/AppContext";
import ImageGallery from './ImageGallery'
import UtilsApi from "../../../Services/Utils/Utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const recipeSettingsService = new RecipeSettingsApi();
const utilsService = new UtilsApi();

const RecipeModel = ({
  showModal,
  pageTitle,
  submitStatus,
  type,
  rowMaterialName,
  quantity,
  isEdit,
  editData,
  onChange,
  handleCancelClick,
  handleSubmitClick,
  showStatus
}) => {
  const [defaultFilter] = React.useState({
    currentPage: 0,
    sortBy: [],
    filters: [],
  });
  const [showErrors, setShowErrors] = useState(false);
  // const [selectedType, selectType] = useState({});

  const { showToastMessage, setIsLoading} = useContext(AppContext);
  const formik = useFormik({
    initialValues:{
      title:"",
      type: "",
      image:"",
      unit:"",
      status: 0,
    },
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      handleSubmitClick(values);
      setShowErrors(false)
    },
    validate:(values) => {
      console.log("Validation ", values);
      const errors = {};
      values.name = values.title.trim();
      if (!values.title) {
        errors.title = "Please enter name";
      }
      if(!values.type) {
        errors.type = "Please select type";
      }
      if(!values.image)  {
        errors.image = "Please select image"
      }
      if(!values.unit){
        errors.unit = "Please select unit"
      }
      return errors;
    }
  })
  const setValues = async (index, value) => {
    await formik.setValues({ ...formik.values, [index]: value });
  };

  const [data, setData] = React.useState([]);
  const [unit, setUnitData] = useState([])

  //Get all recipe types
  const fetchTypes = async () => {
    const postData = {
      ...defaultFilter,
      itemsPerPage:200
    };
    const response = await recipeSettingsService.recipeTypeList(postData);
    if (response && response.status === 200) {
      return response.data.data.rows
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  //Get all recipe units
  const fetchUnit = async()=>{
    const postData = {
      ...defaultFilter,
      itemsPerPage:200
    };
    const response = await recipeSettingsService.unitList(postData);
    if(response && response.status === 200){
      return response.data.data.rows
    }else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  }

  //upload or update image when user select new image
  const [imageUrl, setImageUrl] = useState("")

  //handle image upload or update
  const handleUploadImage = async (event) => {
    const file = event.target.files[0];
    if(file){
      let imagedata = new FormData()
      imagedata.append("image",file);
      imagedata.append("type", "RAWMATERIALS");
      setIsLoading(true);
      const response = await utilsService.uploadImage(imagedata);
      if(response && response.status === 200){
        setIsLoading(false)
        setImageUrl(response.data.data[0].url)
      formik.setValues((value)=>{
        return{
          ...value,
          image:response.data.data[0].url
        }
      })
      }else{
        setIsLoading(false);
        setImageUrl("")
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }
  };
  const handleRemoveImage = (event, i) => {
    event.stopPropagation();
    formik.setValues((v) => {
      return {
        ...v,
        image:""
      };
    });
    setImageUrl("")
  };

  useEffect(async() => {
    setShowErrors(false)
    const units = await fetchUnit();
    setUnitData(units)
    const types = await fetchTypes();
    setData(types)
    if(isEdit){
      formik.setValues({
        ...editData, 
        type:editData.product_recipe_type.id,
        unit:editData.product_recipe_units_id,
        title:editData.title,
        image:editData.image,
        status:editData.status
      });
      setImageUrl(editData?.image)
    }
  },[]);
  

  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
        <form
            onSubmit={(e) => {
              setShowErrors(true);
              formik.handleSubmit(e);
            }}
          >
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <Select
                  label="Type"
                  placeHolder="Type"
                  parentClass="text-left"
                  // value={selectedType}
                  value={formik.values.type}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => {setValues("type", data);}}
                  options={data}
                  key={editData?.product_recipe_types_id}
                  disabled={false}
                />
                {showErrors && (
                  <p className={`${styles.error} text-left`}>
                    {formik.errors.type}
                  </p>
                )}
              </div>
              <div className="col-md-6 col-xs-12">
                <Select
                  label="Unit"
                  placeHolder="Unit"
                  parentClass="text-left"
                  // value={selectedType}
                  value={formik.values.unit }
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => {setValues("unit", data);}}
                  options={unit}
                  key={editData?.product_recipe_units_id}
                  disabled={false}
                />
                {showErrors && (
                  <p className={`${styles.error} text-left`}>
                    {formik.errors.unit}
                  </p>
                )}
              </div>
              <div className="col-md-12 col-xs-12">
                <Text
                  type="text"
                  value={formik.values.title}
                  label="Raw material name"
                  // value={rowMaterialName}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => {setValues("title", data);}}
                />
                {showErrors && (
                  <p className={`${styles.error} text-left`}>
                    {formik.errors.title}
                  </p>
                )}
              </div>
                {
                  showStatus && 
                  <div className="col-md-12 col-xs-12">
                    <Select
                      label="Status"
                      parentClass="text-left"
                      value={formik.values.status}
                      required={true}
                      submitStatus={submitStatus}
                      onChange={(data) => {setValues("status", data);}}
                      options={[
                        { id: 0, title: "In Active" },
                        { id: 1, title: "Active" },
                      ]}
                      disabled={false}
                    />
                    {showErrors && (
                      <p className={`${styles.error} text-left`}>
                        {formik.errors.status}
                      </p>
                    )}
                </div>
                }
              <div style={{paddingLeft:16}}>
                <ImageGallery
                  onclickHandler={handleUploadImage}
                  deleteHandler={handleRemoveImage}
                  image_url={imageUrl}
                  error={formik.errors.image}
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn gray-btn d-inline-block mt-4 mb-4 mr-2"
              >
                Save
            </button>
            {/* <div
              data-dismiss="modal"
              onClick={(e) =>
                handleSubmitClick(e, {
                  type: selectedType,
                  name: name,
                  inventory: inventory,
                  status: status,
                })
              }
              className="btn gray-btn d-inline-block mb-3 mr-2"
            >
              {" "}
              Save{" "}
            </div> */}
          </div>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default RecipeModel;
