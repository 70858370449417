import React, { useState, useEffect, useContext } from "react";
// import moment from "moment";
import CardContent from "@material-ui/core/CardContent";
import Header from "../../../Components/Header/Header";
import classes from "./index.module.scss";
import CreateCode from "./CreateCode";
import TypeAndValue from "./TypeAndValue";
import Summary from "./Summary";
import { withRouter } from "react-router";

import { useCoupon } from "../../../Context/CouponCurd/useCoupon";
import { postReqCoupon } from "../../../Hooks/coupon/couponDataAPiCall";

// Api
// import ProductsApi from "../../../Services/Products/Products";
// import CategoryApi from "../../../Services/Category/Category";
// import CouponApi from "../../../Services/Coupons/Coupons";
import { getProductsNormal } from "api/product";
import { getCategoryHierarchyList } from "api/category";
import { getCouponDetailById, createCoupon, updateCoupon } from "api/coupons";

import { getAccessByModuleName } from "../../../Routes/Routes";
import { AppContext } from "./../../../Context/AppContext";
import { MODULES } from "../../../Helpers/Constants";
// import { convertUtcTimestampToPstDate } from "Hooks/commanUtility";

const AddCoupun = (props) => {
  // const productsService = new ProductsApi();
  // const categoryService = new CategoryApi();
  // const couponService = new CouponApi();

  const [productsList, setProductsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [couponIdUpdate, setCouponIdUpdate] = useState("");
  const { showToastMessage, modules, setIsLoading } = useContext(AppContext);
  const [access, setAccess] = useState({});

  let productsListArr = [];
  let categoryListArr = [];

  // const summaryList = [{ title: "Not Published Yet" }];
  const [summaryList, setSummaryList] = useState([]);
  const [couponSummaryList, setCouponSummaryList] = useState([]);

  const {
    couponState,
    setCouponCodeName,
    setCouponCodeType,
    setCouponCodeValue,
    setCouponApplyTo,
    setCouponRedeemForMobile,
    setUsageLimitPerUser,
    setStartDate,
    setEndDate,
    setSelectedData,
    deleteSelectedData,
    disableSectionOnSelect,
    emptySelectedData,
    clearCouponState,
    setCouponUseCount,
  } = useCoupon();

  useEffect(() => {
    clearCouponState();
  }, []);

  useEffect(() => {
    (async () => {
      // await clearCouponState();
      await handleGetProducts();
      await handleGetCategory();

      const { couponId } = props.match.params;
      if (couponId && couponId !== undefined) {
        await setCouponIdUpdate(couponId);
        await handleGetCouponDetailById(couponId);
      }
    })();
  }, []);

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  // Access Contorol functionm call
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.COUPON);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.write_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  //get product details
  const handleGetProducts = async () => {
    setIsLoading(true);
    // const response = await productsService.getProductsNormal();
    const response = await getProductsNormal();

    if (response && response.status === 200) {
      if (response.data.data && response.data.data.length) {
        setProductsList(response.data.data);
        productsListArr = [...response.data.data];
      }
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  //get category details
  const handleGetCategory = async () => {
    setIsLoading(true);
    // const response = await categoryService.getCategoryHierarchyList();
    const response = await getCategoryHierarchyList();

    if (response && response.status === 200) {
      if (response.data.data && response.data.data.length) {
        setCategoryList(response.data.data);
        categoryListArr = [...response.data.data];
      }
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  //get coupon detail
  const handleGetCouponDetailById = async (id) => {
    setIsLoading(true);
    // const response = await couponService.getCouponDetailById(id);
    const response = await getCouponDetailById(id);

    if (response && response.status === 200) {
      if (response.data.data) {
        let tempResponseData = response.data.data;

        let tempSummaryData = [];
        let tempCouponSummaryListData = [];

        let numberOfUse =
          tempResponseData.usage_limit_number_count === null
            ? 0
            : tempResponseData.usage_limit_number_count;
        tempCouponSummaryListData.push({
          title: "This coupon used " + numberOfUse + " times",
        });

        setCouponCodeName(tempResponseData.coupon_code);
        setCouponCodeType(tempResponseData.coupon_type);
        setCouponCodeValue(tempResponseData.coupon_value);
        setCouponApplyTo(tempResponseData.coupon_apply_to);

        tempSummaryData.push({
          title:
            tempResponseData.coupon_code +
            " coupon for " +
            tempResponseData.coupon_apply_to,
        });
        if (tempResponseData.coupon_redeem_for_mobile === 1) {
          setCouponRedeemForMobile(true);
          tempSummaryData.push({ title: "Coupon only redeemable on mobile app orders" });
        } else {
          setCouponRedeemForMobile(false);
        }
        if (tempResponseData.usage_limit_one_per_user === 1) {
          setUsageLimitPerUser(true);
          tempSummaryData.push({ title: "Apply once per order" });
        } else {
          setUsageLimitPerUser(false);
          tempSummaryData.push({ title: "Apply on multiple order" });
        }
        if (tempResponseData.usage_limit_number_of_time !== null) {
          setCouponUseCount(
            tempResponseData.usage_limit_number_of_time.toString()
          );
        } else {
          setCouponUseCount(tempResponseData.usage_limit_number_of_time);
        }

        // setStartDate(
        //   // moment.unix(tempResponseData.active_start_date).format("ddd LL,hh:mm a")
        //   moment.unix(tempResponseData.active_start_date)
        //   // .tz("America/Los_Angeles")
        // );
        setStartDate(tempResponseData.active_start_date);
        if (tempResponseData.active_end_date !== null) {
          // setEndDate(
          //   // moment.unix(tempResponseData.active_end_date).format("ddd LL,hh:mm a")
          //   moment.unix(tempResponseData.active_end_date)
          //   // .tz("America/Los_Angeles")
          // );
          setEndDate(tempResponseData.active_end_date);
        } else {
          setEndDate("");
        }

        if (tempResponseData.coupon_apply_to === "Specific Product") {
          if (tempResponseData.coupons_specific_collections.length > 0) {
            tempResponseData.coupons_specific_collections.forEach((element) => {
              let filterData = productsListArr?.filter((data) => {
                if (data.id === element.product_id) {
                  return data;
                }
              });
              if (filterData !== undefined && filterData.length > 0) {
                setSelectedData(filterData[0]);
              }
            });
          }
        } else if (tempResponseData.coupon_apply_to === "Specific Collection") {
          if (tempResponseData.coupons_specific_collections.length > 0) {
            tempResponseData.coupons_specific_collections.forEach((element) => {
              let filterData = categoryListArr?.filter((data) => {
                if (
                  data.category_hierarchy_id === element.category_hierarchy_id
                ) {
                  return data;
                }
              });

              if (filterData !== undefined && filterData.length > 0) {
                setSelectedData(filterData[0]);
              }
            });
          }
        }

        tempSummaryData.push({ title: "Everyone can use this coupon code" });
        if (tempResponseData.status === 1) {
          tempSummaryData.push({ title: "Published" });
        } else {
          tempSummaryData.push({ title: "Not Published Yet" });
        }
        // summaryListData = tempSummaryData;
        setSummaryList(tempSummaryData);
        setCouponSummaryList(tempCouponSummaryListData);
      }
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handleSubmitClick = async (event) => {
    event.preventDefault();

    // let activeStartDateTimestamp = moment
    //   .tz(couponState.active_start_date, "America/New_York")
    //   .utc()
    //   .format("X");
    // let activeEndDateTimestamp = null;
    // if (couponState.active_end_date) {
    //   activeEndDateTimestamp = moment
    //     .tz(couponState.active_end_date, "America/New_York")
    //     .utc()
    //     .format("X");
    // }
    let activeStartDateTimestamp = couponState.active_start_date;
    let activeEndDateTimestamp = null;
    if (couponState.active_end_date) {
      activeEndDateTimestamp = couponState.active_end_date;
    }

    let couponApiCall = true;
    let validationMessage = "Please add required data";
    if (!couponState.coupon_code) {
      couponApiCall = false;
      validationMessage = "Please add coupon code";
    } else if (!couponState.coupon_value) {
      couponApiCall = false;
      validationMessage = "Please add coupon value";
    } else if (
      couponState.coupon_type === "Percentage" &&
      couponState.coupon_value > 100
    ) {
      couponApiCall = false;
      validationMessage = "You can not add more than 100% coupon value";
    } else if (
      couponState.coupon_apply_to !== "Order Total" &&
      couponState.coupons_specific_collections.length === 0
    ) {
      couponApiCall = false;
      validationMessage =
        "Please add category or product or select order total";
    } else if (!couponState.active_start_date) {
      couponApiCall = false;
      validationMessage = "Please add start date";
    } else if (
      activeEndDateTimestamp !== null &&
      activeStartDateTimestamp === activeEndDateTimestamp
    ) {
      couponApiCall = false;
      validationMessage = "You can not add start & end date same";
    } else if (
      activeEndDateTimestamp !== null &&
      activeStartDateTimestamp > activeEndDateTimestamp
    ) {
      couponApiCall = false;
      validationMessage = "You can not add start date bigger then end date";
    }

    // console.log("couponState=>>>", couponState);
    if (couponApiCall) {
      if (couponIdUpdate !== "" && couponIdUpdate !== undefined) {
        //update case
        const post_req = postReqCoupon(couponState);
        // console.log("post_req=>>>", post_req);
        // return false;
        // let response = await couponService.updateCoupon(
        //   post_req,
        //   couponIdUpdate
        // );
        let response = await updateCoupon(post_req, couponIdUpdate);
        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          clearCouponState();
          setIsLoading(false);
          props.history.push("/product-catalog/discount-coupons");
        } else {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "error", false);
        }
      } else {
        const post_req = postReqCoupon(couponState);
        // console.log("post_req=>>>", post_req);
        // let response = await couponService.createCoupon(post_req);
        let response = await createCoupon(post_req);
        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          clearCouponState();
          setIsLoading(false);
          props.history.push("/product-catalog/discount-coupons");
        } else {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "error", false);
        }
      }
    } else {
      setIsLoading(false);
      showToastMessage(undefined, validationMessage, "error", false);
    }
  };

  // console.log("couponState=>", couponState);
  // console.log("summaryList=>", summaryList);

  return (
    <React.Fragment>
      <Header title="Add Coupon" />
      <div className="row">
        <div id="MainContent" className="col-8">
          <div className={`${classes.cardBody}`}>
            <CardContent>
              <CreateCode
                setCouponCodeName={setCouponCodeName}
                couponCodeName={couponState.coupon_code}
              />
              <TypeAndValue
                productsList={productsList}
                categoryList={categoryList}
                setCouponCodeType={setCouponCodeType}
                couponState={couponState}
                // couponCodeTypeName={couponState.coupon_type}
                setCouponCodeValue={setCouponCodeValue}
                // couponCodeValue={couponState.coupon_value}
                setCouponApplyTo={setCouponApplyTo}
                setCouponRedeemForMobile={setCouponRedeemForMobile}
                // CouponApplyTo={couponState.coupon_apply_to}
                setUsageLimitPerUser={setUsageLimitPerUser}
                setCouponUseCount={setCouponUseCount}
                // usageLimitPerUser={couponState.usage_limit_one_per_user}
                setStartDate={setStartDate}
                startDate={couponState.active_start_date}
                setEndDate={setEndDate}
                endDate={couponState.active_end_date}
                setSelectedData={setSelectedData}
                // selectedData={couponState.coupons_specific_collections}
                deleteSelectedData={deleteSelectedData}
                disableSectionOnSelect={disableSectionOnSelect}
                emptySelectedData={emptySelectedData}
                handleSubmitClick={handleSubmitClick}
              />
            </CardContent>
          </div>
        </div>
        <div className="col-4">
          <Summary
            summaryList={summaryList}
            couponSummaryList={couponSummaryList}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(AddCoupun);
