import CheckBox from "Components/FormControl/CheckBox/CheckBox";
import Date from "Components/FormControl/Date/Date";
import { MODULES } from "Helpers/Constants";
import { setAllStore } from "Helpers/setAllStore";
import moment from "moment";
import React, { useContext, useState } from "react";
import { Line } from "react-chartjs-2";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router";
import { getAccessByModuleName } from "Routes/Routes";
import {
  getCsrProductivityData,
  getProductivityData,
} from "../../../api/report";
import CalendarIcon from "../../../Assets/images/calendar.svg";
import CloseIcon from "../../../Assets/images/close.svg";
import ExportIcon from "../../../Assets/images/export.svg";
import SearchIcon from "../../../Assets/images/search.svg";
import Select from "../../../Components/FormControl/Select/Select";
import ReactTable from "../../../Components/Table/Table";
import Header from "../../../Components/Header/Header";
import { AppContext } from "../../../Context/AppContext";
// import { MODULES } from "./../../../Helpers/Constants";
// Components
// import { getAccessByModuleName } from "./../../../Routes/Routes";
import styles from "./index.module.scss";

const OfficeProductivity = (props) => {
  const { showToastMessage, modules } = useContext(AppContext);
  // Add / Edit
  // const [access, setAccess] = useState({});
  let accessExport = getAccessByModuleName(
    modules,
    MODULES.OFFICE_PRODUCTIVITY_REPORT
  );

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const reference = React.useRef(null);

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  const [fromDate, setFromDate] = useState(
    moment().startOf("week").format("ddd MMM DD, YYYY")
  );
  const [toDate, setToDate] = useState(
    moment().endOf("week").format("ddd MMM DD, YYYY")
  );
  const [selectedPeriod, setSelectedPeriod] = React.useState("weekly");
  const [selectedPeriodLabel, setSelectedPeriodLabel] =
    React.useState("By Week");

  //   const [firstTimePageLoad, setFirstTimePageLoad] = React.useState(false);
  const [exportData, setExportData] = React.useState([]);
  const [exportHeader, setExportHeader] = React.useState([]);

  const [checkAllStore, setCheckAllStore] = useState(true);

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
    selectedPeriodDefault,
  }) => {
    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
    setAllStore(checkAllStore);

    // setChartData(null);
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    // if (accessExport && accessExport.permissions.write_access)
    //   handleExportProductivity(sortBy, filters, fromDateDefault, toDateDefault);
    setLoading(true);

    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
    };

    const response = await getCsrProductivityData(postData);
    if (response && response.status === 200) {
      const updatedResponse = [];
      response.data.data.rows.map((data) => {
        data = { ...data, ...data["clock_time_masters"] };
        delete data["clock_time_masters"];
        updatedResponse.push(data);
      });
      setData(updatedResponse ?? []);
      setPageCount(Math.ceil(response.data.data.count.length / pageSize));
    } else if (response.status === 403) {
      showToastMessage(undefined, response.data.message, "error", false);
      props.history.push("/profile");
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setLoading(false);

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if (checkAllStore) {
      setAllStore(false);
    }
  };

  const handleClearSearch = async (event) => {
    event.preventDefault();
    // setChartData(null);
    setFromDate(moment().startOf("week").format("ddd MMM DD, YYYY"));
    setToDate(moment().endOf("week").format("ddd MMM DD, YYYY"));
    setSelectedPeriod("weekly");
    fetchData({
      ...defaultFilter,
      fromDateDefault: moment().startOf("week").format("YYYY-MM-DD"),
      toDateDefault: moment().endOf("week").format("YYYY-MM-DD"),
      selectedPeriodDefault: "weekly",
    });
    // handleExportProductivity(
    //   event.pageIndex,
    //   event.pageSize,
    //   event.globalFilter
    // );
  };

  const handleExportProductivity = async (
    sortBy,
    filters,
    fromDateDefault,
    toDateDefault
  ) => {
    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
    setAllStore(checkAllStore);

    let postData = {
      isExport: true,
      filters,
      sortBy,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
    };

    const response = await getCsrProductivityData(postData);
    if (response && response.status === 200) {
      const updatedResponse = [];
      response.data.data.data.rows.map((data) => {
        data = { ...data, ...data["clock_time_masters"] };
        delete data["clock_time_masters"];
        updatedResponse.push(data);
      });

      setExportHeader(response.data.data.header);
      setExportData(updatedResponse);
      reference.current?.link?.click();
    } else if (response.status === 403) {
      showToastMessage(undefined, response.data.message, "error", false);
      props.history.push("/profile");
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if (checkAllStore) {
      setAllStore(false);
    }
  };

  const handleSelectedPeriod = async (id) => {
    if (id === "weekly") {
      setFromDate(moment().startOf("week").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("week").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Week");
    } else if (id === "monthly") {
      setFromDate(moment().startOf("month").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("month").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Month");
    } else if (id === "yearly") {
      setFromDate(moment().startOf("year").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("year").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Year");
    }
  };

  const handleFromDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setToDate(moment(data).add(7, "days").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "monthly") {
      setToDate(moment(data).add(1, "months").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "yearly") {
      setToDate(moment(data).add(1, "years").utc().format("ddd MMM DD, YYYY"));
    }
  };

  const handleToDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setFromDate(
        moment(data).subtract(7, "days").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "monthly") {
      setFromDate(
        moment(data).subtract(1, "months").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "yearly") {
      setFromDate(
        moment(data).subtract(1, "years").utc().format("ddd MMM DD, YYYY")
      );
    }
  };

  const columns = React.useMemo(() => [
    {
      Header: "Sales Representative",
      accessor: (data) => `${data.first_name} ${data.last_name}`,
      isVisible: true,
    },
    {
      Header: "Regular Hours",
      accessor: "totalRegularHours",
      isVisible: true,
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: "Overtime",
      accessor: "totalOt",
      isVisible: true,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Double Overtime",
      accessor: "totalDot",
      isVisible: true,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Total Hours",
      accessor: "totalHours",
      isVisible: true,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Create",
      accessor: "create",
      isVisible: true,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Update",
      accessor: "update",
      isVisible: true,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Create per hour",
      accessor: "create_per_hour",
      isVisible: true,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Update per hour",
      accessor: "update_per_hour",
      isVisible: true,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Average Order Value",
      accessor: "avg_order_value",
      isVisible: true,
      disableFilters: true,
      disableSortBy: true,
    },
  ]);

  return (
    <React.Fragment>
      <Header title="Office Productivity Report" />
      <div id="MainContent" className="main-content" />

      <div className={"row "}>
        <div className="col-sm-2 col-xs-12">
          <Select
            label={selectedPeriodLabel}
            value={selectedPeriod}
            onChange={(data) => {
              setSelectedPeriod(data);
              handleSelectedPeriod(data);
            }}
            options={[
              { id: "weekly", title: "Weekly" },
              // { id: "monthly", title: "Monthly" },
              // { id: "yearly", title: "Yearly" },
              { id: "custom", title: "Custom" },
            ]}
            disabled={false}
          />
        </div>
        <div className="col-sm-3 col-xs-12">
          <Date
            label="Filter From"
            value={fromDate}
            dateFormat="ddd MMM DD, YYYY"
            type="date"
            fieldIcon={CalendarIcon}
            onChange={(fromDate) => {
              setFromDate(moment(fromDate).format("ddd MMM DD, YYYY"));
              handleFromDate(fromDate);
            }}
          />
        </div>
        <div className="col-sm-3 col-xs-12">
          <Date
            label="Filter To"
            value={toDate}
            dateFormat="ddd MMM DD, YYYY"
            type="date"
            fieldIcon={CalendarIcon}
            onChange={(toDate) => {
              setToDate(moment(toDate).format("ddd MMM DD, YYYY"));
              handleToDate(toDate);
            }}
          />
        </div>
        <div className="col-sm-2 col-xs-12">
          <CheckBox
            value={checkAllStore}
            label="All Stores"
            onChange={(data) => {
              setCheckAllStore(data);
            }}
          />
        </div>
        <div className="col-sm-2 col-xs-12">
          <div className="td-actions pt-4">
            <span
              onClick={(e) => fetchData(defaultFilter)}
              className={`link cursor-pointer ${styles.searchIconWrapper}`}
            >
              <img className={styles.searchIcon} src={SearchIcon} alt="" />
            </span>
            <span
              onClick={(e) => handleClearSearch(e)}
              className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
            >
              <img className={styles.clearIcon} src={CloseIcon} alt="" />
            </span>

            {exportData && exportData.length > 0 && (
              <CSVLink
                ref={reference}
                style={{
                  display: "none",
                  float: "right",
                  marginTop: "25px",
                }}
                data={exportData}
                headers={exportHeader}
                filename={"OfficeProductivityReport.csv"}
                className={`link cursor-pointer ml-3 ${styles.searchIconWrapper}`}
              >
                DownLoad
              </CSVLink>
            )}
            {accessExport && accessExport.permissions.write_access && (
              <span
                onClick={(e) => handleExportProductivity(defaultFilter)}
                className={`link cursor-pointer ${styles.searchIconWrapper}`}
                style={{ float: "right" }}
              >
                <img
                  className={styles.searchIcon}
                  src={ExportIcon}
                  alt="Export Data"
                />
              </span>
            )}
          </div>
        </div>
      </div>
      {/* {selectedPeriod !== "custom" &&
        chartData &&
        chartData.datasets.length !== 0 && (
          <Line data={chartData} height={80} />
        )} */}
      <br></br>
      <br></br>
      <div>
        <ReactTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
          displayColumnVisibility={false}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(OfficeProductivity);
