import React from "react";
import CKEditor from "ckeditor4-react";

import { SchemaForm } from "../../Hooks/Seo/schemaFormFormik";

const Schema = (props) => {
  const schemaData = props.data ? props.data : {};

  const schemaInitalValues = {
    schema: schemaData.schema ? schemaData.schema : "",
  };

  const submit = (values) => {};
  const cbSubmit = (values) => {
    props.setSchemaData(values);
  };

  const { formik: schemaFormik } = SchemaForm(
    submit,
    schemaInitalValues,
    cbSubmit,
    props.checkSchemaData
  );

  return (
    <form onSubmit={(e) => schemaFormik.handleSubmit(e)} id={`schema-section`}>
      <div>
        <div className="form-group bmd-form-group is-filled text-left">
          <label className="bmd-label-floating">Schema</label>
          <div className={`mt-2 mb-4`}>
            <CKEditor
              data={schemaFormik.values.schema}
              onChange={(evt) => {
                schemaFormik.setValues((v) => {
                  return {
                    ...v,
                    schema: evt.editor.getData(),
                  };
                });
              }}
            />
            {schemaFormik.errors.schema ? (
              <p class="invalid-feedback d-block">
                {schemaFormik.errors.schema}
              </p>
            ) : (
              ""
            )}
            <button
              className="d-none schema-submit-button"
              type="submit"
            ></button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Schema;
