import React, { useContext, useEffect, useState } from "react";
import Select from "../../Components/FormControl/Select/Select";
import styles from "./index.module.scss";
// import Text from "../../Components/FormControl/Text/Text";
// import Header from "../../Components/Header/Header";
// Context
import { AppContext } from "../../Context/AppContext";
import { getAccessByModuleName } from "../../Routes/Routes";
import { MODULES } from "../../Helpers/Constants";
// Services
// import SettingsApi from "../../Services/Settings/Settings";
import { getRolloverTimeInfo, rolloverTimeUpdate } from "../../api/settings";
import { driverInstuctionFormatUTCtoPST,convertUtcToPstTime } from "Hooks/commanUtility";
import { DateTime } from "luxon";
import { PST_ZONE, UTC_ZONE } from "Pages/Order/Order/utility/util";
import moment from "moment";

const RolloverTime = () => {
  // const [value, setValue] = React.useState();
  const { showToastMessage, modules } = useContext(AppContext);
  const [rollOverData, setRollOverData] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [access, setAccess] = useState({});
  // const settingsService = new SettingsApi();

  useEffect(() => {
    handleRolloverTimeSetting();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.CONFIG_STORE_SETTING);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      window.location.href = "/dashboard";
    }
  }

  const handleManageRolloverTimeMer = async (id, mer) => {
    //console.log("1.0 mer => ", mer, id)
    let rollOverDataObj = await rollOverData.map((rdata) => {
      if (rdata.id === id) {
        rdata.meridiem = mer === "PM" ? "AM" : "PM";
      }
      return rdata;
    });
    setRollOverData(rollOverDataObj);
    handleUpdateRolloverTime(id);
  };

  const handleManageRolloverTimeHrs = async (id, hrs) => {
    //console.log("1.0 hrs => ", hrs, id)
    let rollOverDataObj = await rollOverData.map((rdata) => {
      if (rdata.id === id) {
        rdata.hrs = hrs;
      }
      return rdata;
    });
    setRollOverData(rollOverDataObj);
    handleUpdateRolloverTime(id);
  };

  const handleManageRolloverTimeMin = async (id, min) => {
    //console.log("1.0 min => ", min, id)
    let rollOverDataObj = await rollOverData.map((rdata) => {
      if (rdata.id === id) {
        rdata.min = min;
      }
      return rdata;
    });
    setRollOverData(rollOverDataObj);
    handleUpdateRolloverTime(id);
  };

  const handleRolloverTimeSetting = async () => {
    //     let response = await settingsService.getRolloverTimeInfo();
    let response = await getRolloverTimeInfo();

    if (response && response.status === 200) {
      let rollOver = response.data.data;
      let rollOverDataObj = await rollOver.map((rdata) => {
        let time = rdata.value;

        time = time.split(":");
        let ampm = rdata.value.split(" ");
        rdata["hrs"] = time[0];
        if (rdata["hrs"].length === 1) rdata["hrs"] = "0" + rdata["hrs"];
        rdata["min"] = time[1];
        // rdata["meridiem"] =
        //   rdata["hrs"] >= "05" && rdata["min"] > "00" && ampm[1] === "AM"
        //     ? "PM"
        //     : ampm[1];
        rdata["meridiem"] = ampm[1];
        let twentyFourFormat = moment(rdata["hrs"]+":"+rdata["min"], "HH:mm").format("hh:mm A");
        // console.log(
        //   "twentyFourFormat=>",
        //   twentyFourFormat,
        //   rdata.value,
        //   rdata.key
        // );

        let commonDateTimeConvert = DateTime.fromFormat(twentyFourFormat, "hh:mm a" ,{ zone: UTC_ZONE }).setZone(PST_ZONE)

        rdata["hrs"] = commonDateTimeConvert.toFormat("hh");
        rdata["min"] = commonDateTimeConvert.toFormat("mm");
        rdata["meridiem"] = commonDateTimeConvert.toFormat("a");
          // console.log("pstToUtcTime",pstToUtcTime);
        return rdata;
      });
      setRollOverData(rollOverDataObj);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const handleUpdateRolloverTime = async (id) => {
    if (access.write_access) {
      setSubmitStatus(true);
      if (rollOverData && rollOverData.length > 0) {
        let selectedObjData = await rollOverData.filter((d) => d.id === id);
        // console.log("selectedObjData=>", selectedObjData);
        selectedObjData[0].hrs =
          selectedObjData[0].hrs.length === 1
            ? "0" + selectedObjData[0].hrs
            : selectedObjData[0].hrs;
        // selectedObjData[0].hrs = selectedObjData[0].hrs;

        // selectedObjData[0].meridiem =
        //   selectedObjData[0] >= "05" &&
        //   selectedObjData[0].min > "00" &&
        //   selectedObjData[0].meridiem === "PM"
        //     ? "AM"
        //     : selectedObjData[0].meridiem;

        // selectedObjData[0].hrs =
        //   parseFloat(selectedObjData[0].hrs) + parseFloat(7);
        selectedObjData[0]["value"] =
          selectedObjData[0].hrs +
          ":" +
          selectedObjData[0].min +
          ":00 " +
          selectedObjData[0].meridiem;
        // console.log(selectedObjData[0]);
        // return false;
        if (selectedObjData) {
          let requestBody = selectedObjData[0];
          // console.log("requestBody=>", requestBody);
          // return false;
          //     let response = await settingsService.rolloverTimeUpdate(requestBody);
          let response = await rolloverTimeUpdate(requestBody);

          if (response && response.status === 200) {
            // showToastMessage(undefined, response.data.message, "success", false);
            setSubmitStatus(false);
            handleRolloverTimeSetting();
          } else {
            showToastMessage(undefined, response.data.message, "error", false);
          }
          //setIsLoading(false);
        }
      }
    } else {
      showToastMessage(
        undefined,
        "You are not authorized to update this setting.",
        "error",
        false
      );
    }
  };

  // const handleSubmitClick = async (event) => {
  // 	if(!rollOverData) {
  // 		showToastMessage(undefined, "Please select time properly", "error", false);
  // 		event.preventDefault();
  // 	} else {
  // 		event.preventDefault();
  // 		setSubmitStatus(true);
  // 		if (rollOverData) {
  // 			setIsLoading(true);
  // 			let response = await rollOverData.map( rdata => {
  // 				return settingsService.rolloverTimeUpdate(rdata);
  // 			});

  // 			if (response && response.status === 200) {
  // 				showToastMessage(undefined, response.data.message, "success", false);
  // 				handleRolloverTimeSetting();
  // 			} else {
  // 				showToastMessage(undefined, response.data.message, "error", false);
  // 			}
  // 			setIsLoading(false);
  // 		}
  // 	}
  // };

  return (
    <React.Fragment>
      {rollOverData.map((r) => (
        <div
          key={r.id}
          className={`align-items-md-center mt-5 mb-1 ${styles.rollOver}`}
        >
          <h3 style={{ flexBasis: "30%" }}>{r.key}</h3>

          <div className={styles.selectItme}>
            <Select // parentClass="text-left" value={value}
              required={true}
              submitStatus={submitStatus}
              value={r.hrs}
              onChange={(data) => handleManageRolloverTimeHrs(r.id, data)}
              options={[
                { id: "01", title: "01" },
                { id: "02", title: "02" },
                { id: "03", title: "03" },
                { id: "04", title: "04" },
                { id: "05", title: "05" },
                { id: "06", title: "06" },
                { id: "07", title: "07" },
                { id: "08", title: "08" },
                { id: "09", title: "09" },
                { id: "10", title: "10" },
                { id: "11", title: "11" },
                { id: "12", title: "12" },
              ]}
              disabled={false}
            />
          </div>
          <div className={styles.selectItme2}>
            <Select
              parentClass="text-left"
              required={true}
              submitStatus={submitStatus}
              onChange={(data) => handleManageRolloverTimeMin(r.id, data)}
              value={r.min}
              options={[
                { id: "00", title: "00" },
                { id: "15", title: "15" },
                { id: "30", title: "30" },
                { id: "45", title: "45" },
              ]}
              disabled={false}
            />
          </div>
          <div className={`d-block text-left ${styles.changeButton}`}>
            <button
              onClick={(e) => handleManageRolloverTimeMer(r.id, r.meridiem)}
              className="btn pink-btn bold"
            >
              {r.meridiem}
            </button>
          </div>
        </div>
      ))}
      {/* <div className="d-block text-left mt-5 mb-4">
				{access.write_access && 
				<button onClick={(e) => handleSubmitClick(e)} className="btn pink-btn bold" >
					Save
				</button>}
			</div> */}
    </React.Fragment>
  );
};
export default RolloverTime;
