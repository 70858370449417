import { cancelOrder, createOrder, fraudOrder, getOrderEditByDetail, udpateOrder, updateOrderEditByDetail } from "api/order";
import { AppContext } from "Context/AppContext";
import { useUser } from "Context/UserContext/useUser";
import { useFormikContext } from "formik";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { initialValue } from "../context/order";
import { useOrderAttributeProvider } from "../context/order";
import { createOrderObject } from "../utility/createOrderObject";
import { editOrderObject } from "../utility/editOrderObject";
import * as Sentry from '@sentry/react';
import moment from "moment";
import { PAYMENT_METHODS } from "Helpers/Constants";
import { useCustomer } from "Context/Customer/useCustomer";
export function useProcessingOrder() {
  const formik = useFormikContext();
  const { customerState } = useCustomer();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { summary, currentCard, orderDetail, setModal } =
    useOrderAttributeProvider();
  const { userState, reLogin } = useUser();
  const { setIsLoading, showToastMessage, lastOrderEditPath } = useContext(AppContext);
  let history = useHistory();

  const getUser = async () => {
    if (userState?.userProfile?.id) {
      return userState?.userProfile?.id;
    } else {
      const user = await reLogin();
      return user.id;
    }
  };

  const createOrderStart = async () => {
    let post_body;
    let payload;
    const user_id = await getUser();
    if (formik.values.payment_type === "credit_card") {
      try {
        //PAYABLI
        //Check type of payment_method Braintree or Payabli
        //If payment_method is Payabli then create payload object for Payabli payment
        if (formik.values.billing_info.payment_method === PAYMENT_METHODS.PAYABLI) {
          //Create Object for Payabli payment same as Braintree paybload nonce 
          const payabliResponse = customerState.paymentNonce?.responseData;
          payload = {
            nonce: payabliResponse?.referenceId,
            methodReferenceId: payabliResponse?.methodReferenceId,
          }
        }else{
          payload = await window.hostedFieldInstance.tokenize(summary);
        }
        post_body = createOrderObject(
          formik.values,
          summary,
          user_id,
          currentCard,
          payload
        );
      } catch (error) {
        console.log(error);
        Sentry.captureMessage("ERROR OCCURED")
        setIsLoading(false);
        showToastMessage(
          undefined,
          `There is some issue with the payment information you entered. Please fill up the card details properly.`,
          "error",
          false
        );
        return;
      }
    } else {
      post_body = createOrderObject(
        formik.values,
        summary,
        user_id,
        currentCard,
        null
      );
    }
    Sentry.captureMessage(`Create Order started from CSR for customer ${post_body?.customer_info?.customer_first_name} ${post_body?.customer_info?.customer_last_name}`);
    try {
      await createOrder(post_body);
      setIsLoading(false);
      history.push(lastOrderEditPath);
      formik.resetForm();
      formik.setValues((v) => {
        return {
          ...initialValue,
        };
      });
      showToastMessage(
        undefined,
        `Order placed successfully`,
        "success",
        false
      );
    } catch (error) {
      setIsLoading(false);
      showToastMessage(
        undefined,
        `${error.response?.data?.message}`,
        "error",
        false
      );
    }
  };

  const processOrder = async () => {
    await formik.validateForm();
    if (!formik.isValid) {
      formik.submitForm();
      setShowErrorModal(true);
      return;
    } else {
      // Check delivery time and product price for on-demand order
      if(formik.values?.delivery_information?.burq_quote_id  && formik.values?.delivery_information?.burq_quote_id !== "") {

        let currentDateTime = moment.utc().format();          // Get current date and time in utc
        let deliveryDateTime = moment.utc(formik.values.delivery_information.delivery_date_time).format();          // Get delivery date and time in utc

        // Calculate hours difference between current date time and delivery date time
        var deliveryHoursDuration = moment.duration(moment(deliveryDateTime).diff(moment(currentDateTime)));
        var deliveryHoursDiff = deliveryHoursDuration.asHours();

        // Get burq quote expires date and time in utc
        let burqQuoteExpires = (formik.values?.delivery_information?.burq_quote_expires && formik.values?.delivery_information?.burq_quote_expires !== "" && formik.values?.delivery_information?.burq_quote_expires !== null) ? moment.utc(formik.values.delivery_information.burq_quote_expires).format() : "";
        
        // Calculate current date time before burq quote expires date time
        var burqQuoteExpiresBefore = burqQuoteExpires ? moment(currentDateTime).isBefore(moment(burqQuoteExpires)) : true;
        console.log("burqQuoteExpiresBefore", currentDateTime, burqQuoteExpires, burqQuoteExpiresBefore);

        // Calculate total price of products
        let totalProductPrice = 0;
        await formik?.values?.recipients.map(async (recipientsObj) => {
          await recipientsObj?.products.map((productObj) => {
            totalProductPrice += productObj.total_price
          })
        })

        if(formik.values.is_subscribe_order === 1 || (formik?.values?.recipients && formik?.values?.recipients.length > 1)){
          // Null delivery date and time information object
          formik.setValues((v) => ({
            ...v,
            delivery_information: {
              ...v.delivery_information,
              delivery_date: "",
              delivery_date_time: "",
              delivery_time: "",
              delivery_time_id: 0,
              delivery_time_type: "",
              burq_quote_id:"",
              burq_quote_expires:""
            }
          }));
          showToastMessage(undefined,"Please reselect the delivery date and time.","error",false);
        }else{
          // Check the time slot with delivery time with 2 hours different from the current time
          if(deliveryHoursDiff < 2 || totalProductPrice > 400 || !burqQuoteExpiresBefore) {
            // Null delivery date and time information object
            formik.setValues((v) => ({
              ...v,
              delivery_information: {
                ...v.delivery_information,
                delivery_date: "",
                delivery_date_time: "",
                delivery_time: "",
                delivery_time_id: 0,
                delivery_time_type: "",
                burq_quote_id:"",
                burq_quote_expires:""
              }
            }));
            showToastMessage(
              undefined,
              (deliveryHoursDiff < 2 || !burqQuoteExpiresBefore) ? "Please reselect the delivery date and time." : summary?.flower_total_amount > 400 ? "Please reselect the delivery date & time. Your cart size requires special care by our team." : "" ,
              "error",
              false
            );
          }
          else {
            setIsLoading(true);
            createOrderStart();
          }
        }
      }else {
        setIsLoading(true);
        createOrderStart();
      }
    }
  };

  const makeOrderFraud = async (note) => {
    setIsLoading(true);
    let post_req = {
      order_id: orderDetail.id,
      notes: note,
    };
    try {
      let response = await fraudOrder(post_req);
      setModal("");
      setIsLoading(false);
      showToastMessage(undefined, response.data.message, "success", false);
      history.push(lastOrderEditPath);
    } catch (error) {
      setModal("");
      setIsLoading(false);
      showToastMessage(undefined, error.response.data.message, "error", false);
    }
  };

  const cancelOrderCall = async (note, refundAmount, fullRefundFlag ,refundFlag ,noteOptionVal) => {
    // let amount_to_be_refund =
    //   formik.values.total_fund - formik.values.total_refund;
    const post_req = {
      order_id: orderDetail.id,
      notes: note,
      // refund: amount_to_be_refund ?? 0,
      refund: refundAmount ?? 0,
      fullRefundFlag: fullRefundFlag,
      reason_message: note,
      reason_message_option: noteOptionVal,
      refund_flag: refundFlag,
    };
    try {
      setIsLoading(true);
      let response = await cancelOrder(post_req);
      setModal("");
      setIsLoading(false);
      showToastMessage(undefined, response.data.message, "success", false);
      history.push(lastOrderEditPath);
    } catch (error) {
      showToastMessage(undefined, error.response.data.message, "error", false);
      setModal("");
      setIsLoading(false);
    }
  };

  const createEditOrderObject = async (action, note) => {
    setIsLoading(true);
    const user_id = await getUser();
    const post_body = editOrderObject(
      formik.values,
      summary,
      user_id,
      orderDetail,
      action,
      note
    );
    Sentry.captureMessage(`Edit Order started from CSR for customer ${post_body?.customer_info?.customer_first_name} ${post_body?.customer_info?.customer_last_name}`);
    try {
      const response = await udpateOrder(post_body);
      setModal("");
      setIsLoading(false);
      showToastMessage(undefined, response.data.message, "success", false);
      history.push(lastOrderEditPath);
    } catch (error) {
      showToastMessage(undefined, error.response.data.message, "error", false);
      setModal("");
      setIsLoading(false);
    }
  };

  const approveOrder = async (note) => {
    await formik.validateForm();
    if (!formik.isValid) {
      formik.submitForm();
      setShowErrorModal(true);
      return;
    } else {
      createEditOrderObject("submit", note);
    }
  };

  const saveOrder = async (note) => {
    await formik.validateForm();
    if (!formik.isValid) {
      formik.submitForm();
      setShowErrorModal(true);
      return;
    } else {
      createEditOrderObject("save", note);
    }
  };

  const validate = async () => {
    await formik.validateForm();
    if (!formik.isValid) {
      formik.submitForm();
      setShowErrorModal(true);
      return true;
    } else {
      return false;
    }
  };

  const orderAssignCheck = async (editButton = false) => {

    let postData = {
      orderId: orderDetail.id,
      editButton: editButton
    }
    const response = await getOrderEditByDetail(postData);
    return response.data;
    // if (response.data.data.editFlag) {   
    //   return false;
    // } else {
    //   return true;
    // }
  };

  const overRideEditOrder = async () => {
    let postData = {
      orderId: orderDetail.id
    }

    const response = await updateOrderEditByDetail(postData);

    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  };

  const cancleOverRideEditOrder = async () => {
    history.push(lastOrderEditPath);
  };

  return {
    showErrorModal,
    processOrder,
    setShowErrorModal,
    makeOrderFraud,
    cancelOrderCall,
    approveOrder,
    saveOrder,
    validate,
    orderAssignCheck,
    overRideEditOrder,
    cancleOverRideEditOrder
  };
}
