export function homePageDataCall(homePageState, scrollableText,frontendTopBannertext) {

      let product_categories_data = homePageState.product_categories.map((categoryData, i) => {
            let products = categoryData.products.map((productData) => {
                  return {
                        product_id: productData?.id??0,
                        sequence_number: productData?.sequence_number??1,
                        title:productData?.title??1
                  }
            });

            let children = [];            
            if(i === 0) {
                  categoryData.children.map((childrenData, j) => {
                        if(childrenData.name && childrenData.name !== "") {
                              let childrenProducts = childrenData.products.map((productData) => {
                                    return {
                                          product_id: productData?.id??0,
                                          sequence_number: productData?.sequence_number??1,
                                          title:productData?.title??1
                                    }
                              });
                  
                              let temp_children = {
                                    name: childrenData?.name??"",
                                    parent_id: childrenData.parent_id??0,
                                    module: childrenData.module??"home-page",
                                    sequence_number: childrenData?.sequence_number??1,
                                    button_label: childrenData?.button_label??"",
                                    button_url: childrenData?.button_url??"",
                                    description: childrenData?.description??"",
                                    products: childrenProducts
                              }
                              if(childrenData.id && childrenData.id !== 0) {
                                    temp_children.id = childrenData.id
                              }
                              children.push(temp_children);
                        }
                  });
            } else {
                  children = []; 
            }

            let temp_category =  {
                  name: categoryData?.name??"",
                  parent_id: categoryData.parent_id??0,
                  module: categoryData.module??"home-page",
                  sequence_number: categoryData?.sequence_number??1,
                  button_label: categoryData?.button_label??"",
                  button_url: categoryData?.button_url??"",
                  description: categoryData?.description??"",
                  products: products,
                  children: children
            }
            if(categoryData.id && categoryData.id !== 0) {
                  temp_category.id = categoryData.id
            }
            return temp_category;
      });    

      let reqBody = {
            scrollable_text: scrollableText,
            frontend_top_banner_text: frontendTopBannertext,
            banners: homePageState.banners,
            seo: {
                  module: homePageState.seo.module,
                  is_robot: homePageState.seo.is_robot,
                  is_no_follow: homePageState.seo.is_no_follow,
                  is_no_index: homePageState.seo.is_no_index,
                  image_alt_text: homePageState.seo.image_alt_text,
                  page_title: homePageState.seo.page_title,
                  meta_title: homePageState.seo.meta_title,
                  meta_description: homePageState.seo.meta_description,
                  canonical_url: homePageState.seo.canonical_url,
                  og_title: homePageState.seo.og_title,
                  og_description: homePageState.seo.og_description,
                  og_image_url: homePageState.seo.og_image_url,
                  og_image_alt: homePageState.seo.og_image_alt,
                  og_url: homePageState.seo.og_url,
                  og_image_type: homePageState.seo.og_image_type,
                  og_site_name: homePageState.seo.og_site_name,
                  og_image_height: homePageState.seo.og_image_height,
                  og_image_width: homePageState.seo.og_image_width,
                  twitter_title: homePageState.seo.twitter_title,
                  twitter_image_url: homePageState.seo.twitter_image_url,
                  twitter_description: homePageState.seo.twitter_description,
                  twitter_site: homePageState.seo.twitter_site,
                  twitter_creator: homePageState.seo.twitter_creator,
                  schema: homePageState.seo.schema,
            },
            worked_with: homePageState.worked_with,
            certifications: homePageState.certifications,
            product_categories: product_categories_data,
      };

      return { ...reqBody };
}