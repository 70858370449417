import { callPostApi } from "./api";

export async function getCmsMenuList(data) {
      // return this.interceptors.POST("page", data);
      try {
            const response = await callPostApi({ url: "menus/menu-list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getMenuWithSequence(data) {
      try {
            const response = await callPostApi({ url: "menus/menu-sequence", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function setMenuWithSequence(data) {
      try {
            const response = await callPostApi({ url: "menus/set-menu-sequence", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}