import React from "react";
import RadioBoxLabel from "./../../../Components/FormControl/RadioBox/RadioBox";
import styles from "./index.module.scss";
const ChartHeading = ({ heading }) => {
  return (
    <div className="d-flex justify-content-between">
      <h3 className="bold mt-4 align-items-center">
        <span>{heading}</span>
      </h3>
      {/* <div className="mt-2 d-flex align-items-right">
        <div className={`row ${styles.ChartFormRadio}`}>
          <div className="col">
            <RadioBoxLabel
              label="Weekly"
              value="Weekly"
              className={styles.redioButton}
            />
          </div>
          <div className="col">
            <RadioBoxLabel
              label="Monthly"
              value="Monthly"
              className={styles.redioButton}
            />
          </div>
          <div className="col">
            <RadioBoxLabel
              label="Yearly"
              value="Yearly"
              className={styles.redioButton}
            />
          </div>
          <div className=""></div>
        </div>
      </div> */}
    </div>
  );
};
export default ChartHeading;
