import * as customerActions from "./customerActions";
export default function customerReducer(state, action) {
  switch (action.type) {
    case customerActions.customerActions.CREATE_PAYMENT_NONCE:
      console.log("action.payload256255: ", action.payload);
      return {
        ...state,
        paymentNonce: action.payload,
      };
    default:
      return state;
  }
}
