import React, { Component } from "react";
import CardIcon from "Assets/images/card-icon.svg";
import {
  getBraintreeClient,
  getHostedFieldInstance
} from "Helpers/braintree";
import {
  braintreeFieldsSettings,
  braintreeInputStyles
} from "Helpers/braintreeFieldOptions";
import classes from "./index.module.scss";


class CardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      cardType: "",
    };
  }

  getClass(field) {
    let classInput = [classes.CardInput];
    if (field) {
      if (field.isFocused) {
        classInput.push(classes.focused);
      }
      if (!field.isEmpty && !field?.isValid) {
        classInput.push(classes.error);
      } else if (!field.isEmpty && field.isValid) {
        classInput.push(classes.success);
      }
    }
    return classInput.join(" ");
  }

  async componentDidMount() {
    const client = await getBraintreeClient(
      process.env.REACT_APP_BRAINTREE_TOKEN
    );

    const hostedFields = await getHostedFieldInstance(
      client,
      braintreeFieldsSettings,
      braintreeInputStyles
    );
    this.hostedFieldInstance = hostedFields;
    window.hostedFieldInstance = hostedFields;
    hostedFields.on("validityChange", this.validityChange);
    hostedFields.on("cardTypeChange", this.cardTypeChange);
  }

  validityChange = (event) => {
    const field = event.fields[event.emittedBy];
    if (event.emittedBy === "number") {
      this.setState({ number: field });
    }
    if (event.emittedBy === "cardholderName") {
      this.setState({ cardholderName: field });
    }
    if (event.emittedBy === "cvv") {
      this.setState({ cvv: field });
    }
    if (event.emittedBy === "expirationDate") {
      this.setState({ expirationDate: field });
    }
  };

  cardTypeChange = (event) => {
    if (event.cards.length === 1) {
      var card = event.cards[0];

      // update the security code label
      this.setState({ cardType: card.type });
    } else {
      // reset to defaults
      this.setState({ cardType: "" });
    }
  };

  tokenize = async () => {
    return new Promise((rs, rj) => {
      this.hostedFieldInstance.tokenize((err, payload) => {
        if (err) {
          rj(err);
        } else {
          return rs(payload);
        }
      });
    });
  };

  
  
  OnsavecustomerCard = async () => {
    
    try {
        let payload = await this.tokenize();
        this.props.saveCard(payload);
    } catch (error) {
      this.props.showToastMessage(
        undefined,
        "Please enter valid payment details.",
        "error",
        false
      );
      return
    }
  };
  
  render() {
    let cardNumberClass = this.getClass(this.state["number"]);
    let cardHolderClass = this.getClass(this.state["cardholderName"]);
    let cvvClass = this.getClass(this.state["cvv"]);
    let expDateClass = this.getClass(this.state["expirationDate"]);
    return (
      <div
        className={`position-relative ${this.props.upDownSpace} ${classes.cardBlock} ${this.props.className}`}
      >
        <div className={`mb-3 ${classes.cardTitle}`}>
          <img src={CardIcon} alt="Card" className="mr-3" />
          Card Type
        </div>
        <div className="row">
          <div className="col-12 mb-1">
            <div className={cardNumberClass} id="cc-number"></div>
          </div>
          <div className="col-7  mb-1">
            <div className={expDateClass} id="expiration-date"></div>
          </div>
          <div className="col-5  mb-1">
            <div className={cvvClass} id="cvv"></div>
          </div>
          <div className="col-12  mb-1">
            <div className={cardHolderClass} id="cc-name"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardForm;
