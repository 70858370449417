import React from "react";
import { Line } from "react-chartjs-2";
import ChartHeading from "./ChartHeading";

const Productivity = ({ productivityData }) => {
  const data = {
    labels: productivityData.label,
    datasets: [
      {
        label: 'Cost',
        data: productivityData.data,
        fill: true,
        borderDashOffset: 0.0,
        pointHoverBackgroundColor: "#CB998D",
        pointHoverBorderColor: "#CB998D",
        pointBackgroundColor: "#CB998D",
        pointBorderColor: "#CB998D",
        backgroundColor: "#CB998D",
        borderColor: "#C6C6C6",
        pointRadius: 4,
        // data: [100, 500, 1000, 1500, 2000],
      },
    ],
  };

  const options = {
    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //     },
    //   ],
    // },
    // scales: {
    //   y: {
    //     suggestedMin: 50,
    //     suggestedMax: 100,
    //   },
    // },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <>
      <div className="header">
        <ChartHeading heading="Productivity" />
      </div>
      <Line
        data={data}
        options={options}
        style={{ backgroundColor: "#FAFAFB", padding: "20px" }}
      />
    </>
  );
};

export default Productivity;
