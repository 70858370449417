import React, { useState, useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// SCSS
import styles from "./index.module.scss";
// Component
import Text from "../../FormControl/Text/Text";
import WYSIWIGEditor from "../../../Components/WYSIWIGEditor/WYSIWIGEditor";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddChangeRequest = (props) => {
  const [faqQuestions, setFaqQuestions] = useState("");
  const [faqAnswers, setFaqAnswers] = useState("");

  useEffect(() => {
    setFaqQuestions(props.defaultValue);
    setFaqAnswers(props.defaultValueSlug);
  }, [props.defaultValue, props.defaultValueSlug]);

  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          // console.log("hii23");
        }}
        className={`row`}
      >
        <Dialog
          open={props.showModal}
          TransitionComponent={Transition}
          onClose={() => {
            props.handleCancelClick();
            setFaqQuestions("");
            setFaqAnswers("");
          }}
          classes={{
            paper: styles.modalDialog,
          }}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogContent className={`${styles.modalContent} px-0 py-0`}>
            <div className={`text-center ${styles.modalBody}`}>
              <h5 className={`my-3 text-center ${styles.modalTitle}`}>
                {props.pageTitle}
              </h5>

              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <Text
                    type="text"
                    label="Question"
                    value={faqQuestions}
                    submitStatus={props.submitStatus}
                    onChange={(data) => {
                      setFaqQuestions(data);
                    }}
                    required={true}
                    id="question"
                  />
                </div>
              </div>

              <div className={`row`}>
                <label
                  className="bmd-label-floating"
                  style={{ marginLeft: "15px" }}
                >
                  Description
                </label>
                <div className={`col-md-12 col-xs-12`}>
                  <WYSIWIGEditor
                    data={faqAnswers}
                    onChange={(data) => {
                      setFaqAnswers(data);
                    }}
                  />
                </div>
              </div>
              <br></br>
              <button
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  props.handleSubmitClick(faqQuestions, faqAnswers);
                }}
                className="btn gray-btn d-inline-block mb-3 mr-2"
              >
                Save
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </form>
    </React.Fragment>
  );
};

export default AddChangeRequest;
