import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import styles from "../OrderhistoryCard/OrderhistoryCard.module.scss";
import EditIcon from "../../Assets/images/edit.svg";
import { PAYMENT_METHODS } from "Helpers/Constants";

export default function PaymentHistoryCard(props) {
  return (
    <Card className={` ${styles.OrderHistoryCard} ${styles.default}`}>
      <CardContent className={`${styles.content}`}>
        <div className="d-flex justify-content-between">
          <p className={`${styles.CustomerName}`}>
            {props.data.name_on_card ?? "-"}
          </p>
          {/* //PAYABLI */}
          {props.data.payment_method === PAYMENT_METHODS.BRAINTREE && (
            <img
              src={EditIcon}
              alt=""
              style={{ width: "24px", height: "24px", cursor: "pointer" }}
              onClick={props.EditClick}
            />
          )}
        </div>
        <div className={`d-flex justify-content-between mt-4`}>
          <p className={`${styles.OrderId}`}>
            xxxx xxxx xxxx {props.data.last_4number ?? "xxxx"}
          </p>
          <p className={`${styles.OrderPrice}`} style={{ fontSize: "18px" }}>
            {props.data.card_type ?? ""}
          </p>
        </div>
      </CardContent>
    </Card>
  );
}
