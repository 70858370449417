import { MODULES } from "./../../../Helpers/Constants";
import { getAccessByModuleName } from "./../../../Routes/Routes";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { CSVLink } from "react-csv";
// Components
import Header from "../../../Components/Header/Header";
import ReactTable from "./../../../Components/Table/Table";
// import MostKeywordSearchFilter from "../../../Components/MostKeywordSearchFilter/MostKeywordSearchFilter";
// Services
import {
  getKeywordList,
  getKeywordExportList
} from "../../../api/mostKeywordSearch";
//scss
import styles from "./index.module.scss";
// Context
import { AppContext } from "./../../../Context/AppContext";
// Images
import ExportIcon from "./../../../Assets/images/export.svg";
const MostKeywordSearch = (props) => {
  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);
  const reference = React.useRef(null);
  const [access, setAccess] = useState({});
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: ""
  });
  // Date Range Filter Start
  let defaultType = "concat"; // date : concat
  // const [type, setType] = useState(defaultType);
  const [searchType,setSearchType] = useState(defaultType);
  // let defaultFromDate = moment().startOf("isoWeek").format("ddd MMM DD, YYYY");
  // let deafultToDate = moment().endOf("isoWeek").format("ddd MMM DD, YYYY");
  // const [fromDate, setFromDate] = useState(defaultFromDate);
  // const [toDate, setToDate] = useState(deafultToDate);
  const [exportData, setExportData] = React.useState([]);
  const [exportHeader, setExportHeader] = React.useState([]);
  const [isCustomSearch,setIsCustomSearch] = useState(false);
  // Date Range Filter End

  // // Handle On Start Date Change Validation
  // const handleFromDate = async (fromDate) => {
  //   setFromDate(fromDate);
  // };
  // // Handle On End Date Change Validation
  // const handleToDate = async (toDate) => {
  //   setToDate(toDate);
  // };
  // // Date Validation
  // const dateValidation = (fromDate,toDate) => {
  //   let startDate = moment(fromDate).format("x");
  //   let endDate = moment(toDate).format("x");
  //   if(startDate > endDate){
  //     showToastMessage(
  //       undefined,
  //       "End date should be greater than start date",
  //       "error",
  //       false
  //     );
  //     return false;
  //   }
  //   return true;
  // }
  
  // const handleCustomSearchData = async(event) => {
  //   handleGetKeywordList({
  //     ...defaultFilter,
  //   });
  //   setIsCustomSearch((isCustomSearch)?false:true);
  // }
  
  // const handleClearSearch = async (event) => {
  //   event.preventDefault();
  //   // Condition According To Order Type For Start Date And End Date
  //   setFromDate(defaultFromDate);
  //   setToDate(deafultToDate);
  //   setType(defaultType);
  //   handleGetKeywordList({
  //     ...defaultFilter,
  //     fromDateDefault: defaultFromDate,
  //     toDateDefault: deafultToDate,
  //     searchType : defaultType
  //   });
  //   setIsCustomSearch((isCustomSearch)?false:true);
  // };

  const handleGetKeywordList = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    // fromDateDefault,
    // toDateDefault,
    // searchType
  }) => {
    // if(!dateValidation(fromDateDefault ? fromDateDefault :fromDate,toDateDefault ? toDateDefault :toDate)){
    //   return false;
    // }
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy});
    // let startDate = fromDateDefault ? fromDateDefault : fromDate;
    // let endDate = toDateDefault ? toDateDefault : toDate;
    // let searchTypeValue = searchType ? searchType : type;
    let searchTypeValue = defaultType;
    setSearchType(searchTypeValue);
    setLoading(true);
    const reqBody = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      // start_date: moment(startDate).format("YYYY-MM-DD"),
      // end_date: moment(endDate).format("YYYY-MM-DD"),
      // searchType : searchType ? searchType : type
      searchType : searchTypeValue
    };
    setIsLoading(true);
    let userAccess = getAccessByModuleName(modules, MODULES.MOST_KEYWORD_SEARCH);
    if (userAccess && userAccess.permissions.read_access) {
      const response = await getKeywordList(reqBody);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        let count = 0;
        let countArray = response.data.data.count;
        if(searchTypeValue == 'concat'){
          count = countArray.length;
        } else {
          count = countArray[0].count;
        }
        setPageCount(Math.ceil(count / pageSize));
      } else showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
    setLoading(false);
  };

  const handleExportMostKeywordSearch = async ({
    sortBy,
    // fromDateDefault,
    // toDateDefault,
    // searchTypeValue
  }) => {
    // if(!dateValidation(fromDateDefault ? fromDateDefault :fromDate,toDateDefault ? toDateDefault :toDate)){
    //   return false;
    // }
    let postData = {
      sortBy,
      // start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      // end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
      // searchType : searchTypeValue ?? type,
      searchType : defaultType
    };
    const response = await getKeywordExportList(postData);
    if (response && response.status === 200) {
      setExportHeader(response.data.data.header);
      setExportData(response.data.data.rows);
      reference.current?.link?.click();
    } else if (response.status === 403) {
      showToastMessage(undefined, response.data.message, "error", false);
      props.history.push("/profile");
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.MOST_KEYWORD_SEARCH);
    let rolesAccess = getAccessByModuleName(modules, MODULES.ROLES_RIGHTS);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      window.location.href = "/dashboard";
    } else {
      if (rolesAccess && !rolesAccess.permissions.read_access) {
        showToastMessage(
          undefined,
          "You are not authorized to access.",
          "error",
          false
        );
        window.location.href = "/dashboard";
      }
    }
  }
  const columns = React.useMemo(
    () => [
      {
        Header: "Keyword",
        accessor: "keyword",
        isVisible: true,
        placeholder: "Keyword",
      },
      {
        Header: "Search Count",
        accessor: "search_count",
        isVisible: true,
        placeholder: "Search Count",
        disableFilters: true
      },
      {
        Header: "Date",
        accessor: "date",
        isVisible: (searchType === 'date') ? true : false,
        placeholder: "Date",
        Cell: ({ value, row, column }) => {
          return `${
            row.original.date &&
            row.original.date !== null
              ? moment(row.original.date).zone("America/Los_Angeles").format("ddd MMM DD, YYYY")
                    
              : ""
          }`;
        },
        disableFilters: true
      }
    ],
    [searchType]
  );
  return (
    <React.Fragment>
      <Header
        title="Most Keyword Search"
      />
      <div id="MainContent" className="main-content" />
      {access.read_access && (
        <React.Fragment>
          {/* <MostKeywordSearchFilter
            type={type}
            setType={setType}
            fromDate={fromDate}
            handleFromDate={handleFromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            handleToDate={handleToDate}
            fetchData={handleCustomSearchData}
            handleClearSearch={handleClearSearch}
            defaultFilter={defaultFilter}
            exportData={exportData}
            exportHeader={exportHeader}
            exportAccess={access.write_access}
            handleExportMostKeywordSearch = {handleExportMostKeywordSearch}
          />

            {
              (access.write_access &&
                <CSVLink
                  ref={reference}
                  data={exportData}
                  headers={exportHeader}
                  filename={"MostKeywordSearch.csv"}
                  className={`link cursor-pointer ml-3 ${styles.searchIconWrapper} hidden`}
                  style={{
                    display: "none",
                    float: "right",
                    marginTop: "25px",
                  }}
                >
                </CSVLink>
              )
            }
              */}
          
          {
            (access.write_access &&
              <>
                <span
                  onClick={(e) => handleExportMostKeywordSearch(defaultFilter)}
                  className={`link cursor-pointer ml-3 ${styles.searchIconWrapper} float-right`}
                >
                  <img className={styles.searchIcon} src={ExportIcon} alt="" />
                </span>
                <CSVLink
                  ref={reference}
                  data={exportData}
                  headers={exportHeader}
                  filename={"MostKeywordSearch.csv"}
                  className={`link cursor-pointer ml-3 ${styles.searchIconWrapper} hidden`}
                  style={{
                    display: "none",
                    float: "right",
                    marginTop: "25px",
                  }}
                >
                </CSVLink>
              </>
            )
          }

          <ReactTable
            columns={columns}
            data={data}
            fetchData={handleGetKeywordList}
            loading={loading}
            pageCount={pageCount}
            noDataText={"There is no data to display"}
            defaultFilter={defaultFilter}
            displayGlobalSearch={false}
            isCustomSearch={isCustomSearch}
            displayLastColumnSorting={true}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default MostKeywordSearch;