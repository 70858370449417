import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
// Sub Components
import Personal from "./Personal";
import Administrative from "./Administrative";
// Components
import Header from "../../../Components/Header/Header";
import NewTodoRemindersModel from "../../../Components/Modal/Todo/RemindersModel";
import DeleteConfirmation from "../../../Components/Modal/DeleteConfirmation/DeleteConfirmation";
import StatusConfirmation from "../../../Components/Modal/StatusConfirmation/StatusConfirmation";
// Context
import { AppContext } from "../../../Context/AppContext";
import { useUser } from "Context/UserContext/useUser";
// Utills
import { updateStatus } from "./../../../api/utils";
// Routes
import { getAccessByModuleName } from "./../../../Routes/Routes";
// Helper
import { MODULES } from "./../../../Helpers/Constants";
import {
  addReminder,
  updateReminder,
  updateDailyReminder,
  getPersonalReminderList,
  getReminderList,
  getReminderDetails,
  getDailyReminderDetails,
} from "../../../api/todo";
// Style
import styles from "./index.module.scss";
const TodoReminders = () => {
  // User Values
  const { showToastMessage, modules } = useContext(AppContext);
  const { userState, reLogin } = useUser();
  let defaultType = "PERSONAL";
  let defaultUserAccess = {
    read: true,
    write: true,
    delete: true,
  };
  const [userAccess, setUserAccess] = useState(defaultUserAccess);
  const [accessType, setAccessType] = useState(defaultType);
  const [userID, setUserID] = useState(null);
  const [reminderID, setReminderID] = useState(null);
  const [dailyReminderID, setDailyReminderID] = useState(null);
  // Table Values
  const [recordType, setRecordType] = useState(defaultType);
  let defaultFilterValues = {
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    globalFilter: "",
    filters: [],
    type: recordType,
  };
  const [defaultFilter, setDefaultFilter] = React.useState(defaultFilterValues);
  const [data, setData] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  // Status Confirmation Modal
  const [reminderStatus, setReminderStatus] = useState(null);
  const [statusConfirmationModal, setStatusConfirmationModal] = useState(false);
  // Delete Confirmation Modal
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  // Modal Values
  const [modal, setModal] = useState(false);
  const [disableType, setDisableType] = useState(null);
  let deafultModalTitle = "Add Reminder-Checklist";
  const [modalTitle, setModalTitle] = useState(deafultModalTitle);
  let defaultModalValues = {
    title: "",
    reminderType: null,
    fromDate: null,
    endDate: null,
    reminderUsers: [],
    tempReminderUsers: [],
    timer: null,
    repeatType: null,
    keyValuesVal: [],
    monthlyDate: null,
  };
  const [modalValues, setModalValues] = useState(defaultModalValues);
  // Set User Access
  async function getModuleAccess() {
    let access = getAccessByModuleName(
      modules,
      MODULES.ADMINISTRATIVE_REMINDERS
    );
    if (access) {
      let userAccessValue = {
        read: access.permissions?.read_access ? true : false,
        write: access.permissions?.write_access ? true : false,
        delete: access.permissions?.delete_access ? true : false,
      };
      if (
        userAccessValue.read ||
        userAccessValue.write ||
        userAccessValue.delete
      ) {
        setAccessType("ADMINISTRATIVE");
        setUserAccess(userAccessValue);
      }
    } else {
      setAccessType("PERSONAL");
    }
  }
  useEffect(getModuleAccess, [modules]);
  // Handle Tab Change
  const handleTabChange = (recordTypeValue) => {
    setRecordType(recordTypeValue);
    setDefaultFilter(defaultFilterValues);
  };
  // To Do Reminder Modal Toggle
  const handleTodoReminderCode = async (event, type = "add", editData = {}) => {
    event.preventDefault();
    resetModalValues();
    let userID = await getUser();
    setUserID(userID);
    if (type === "edit") {
      setModalTitle("Edit Reminder-Checklist");
      let isDaily = editData.reminder_id ? true : false;
      if (isDaily) {
        setDailyReminderID(editData.id);
        setDisableType(true);
        let reminderDataModalValues = {
          title: editData.title,
        };
        setModalValues(reminderDataModalValues);
        setModal(true);
      } else {
        let reminderData = await getReminderDetails(editData.id);
        if (reminderData && reminderData.status === 200) {
          let data = reminderData.data.data;
          setReminderID(data.id);
          let reminderTypeValue = data.type;
          let keyValues = data.days ? data.days.split(",") : [];
          let monthlyDate =
            data.date_monthly && data.date_monthly != "0000-00-00"
              ? moment(data.date_monthly).format("ddd MMM DD, YYYY")
              : null;
          let reminderUsers = data.reminderUsers;
          let usersArray = [];
          if (reminderUsers) {
            reminderUsers.forEach((d, i) => {
              let tempArr = {
                id: d.user.id,
                name: d.user.first_name + d.user.last_name,
              };
              usersArray.push(tempArr);
            });
          }
          // Reminder Data For Set Values
          let reminderDataModalValues = {
            title: data.title,
            reminderType: reminderTypeValue,
            fromDate: moment(data.fromDate).format("ddd MMM DD, YYYY"),
            endDate: moment(data.endDate).format("ddd MMM DD, YYYY"),
            timer: moment(data.time, "HH:mm").format("hh:mm A"),
            repeatType: data.repeat_type,
            keyValuesVal: keyValues,
            monthlyDate: monthlyDate,
            reminderUsers: [],
            tempReminderUsers: usersArray,
          };
          setModalValues(reminderDataModalValues);
          setDisableType(false);
          setModal(true);
        } else {
          showToastMessage(
            undefined,
            reminderData.data.message,
            "error",
            false
          );
        }
      }
    } else {
      let reminderDataModalValues = defaultModalValues;
      if(userAccess.read && !userAccess.write){
        reminderDataModalValues["reminderType"] = "PERSONAL";
      }
      if (accessType === "PERSONAL") {
        reminderDataModalValues["reminderType"] = "PERSONAL";
      }
      setModalValues(reminderDataModalValues);
      setModal(true);
    }
  };
  // Reset Modal Values
  const resetModalValues = () => {
    setModalTitle(deafultModalTitle);
    setReminderID(null);
    setDailyReminderID(null);
    setDisableType(null);
    setModalValues(defaultModalValues);
  };
  // Get User Data;
  const getUser = async () => {
    if (userState?.userProfile?.id) {
      return userState?.userProfile?.id;
    } else {
      const user = await reLogin();
      return user.id;
    }
  };
  // Submit Data
  const saveReminderData = async (data) => {
    let userID = await getUser();
    let response;
    if (recordType === "PERSONAL" && dailyReminderID) {
      let reminderData = {
        title: data.title,
      };
      response = await updateDailyReminder(reminderData, dailyReminderID);
    } else {
      let startDateValue = moment(data.fromDate).format("YYYY-MM-DD");
      let endDateValue = moment(data.endDate).format("YYYY-MM-DD");
      let timeValue = moment(data.timer, "hh:mm A").format("HH:mm");
      let monthValue = data.monthlyDate
        ? moment(data.monthlyDate).format("YYYY-MM-DD")
        : "";
      let keyValues = data.keyValuesVal;
      keyValues = keyValues.join(",");
      let reminderUsersArray = [];
      let reminderData = {
        title: data.title,
        type: data.reminderType,
        fromDate: startDateValue,
        endDate: endDateValue,
        time: timeValue,
        repeat_type: data.repeatType,
        days: keyValues,
        date_monthly: monthValue,
        createdBy: userID,
      };
      if (data.reminderUsers && data.reminderUsers.length > 0) {
        data.reminderUsers.forEach((d, i) => {
          let tempArr = {
            user_id: d.id,
          };
          reminderUsersArray.push(tempArr);
        });
      }
      reminderData["reminderUsers"] = reminderUsersArray;
      if (reminderID) {
        response = await updateReminder(reminderData, reminderID);
      } else {
        response = await addReminder(reminderData);
      }
    }
    if (response && response.status === 200) {
      showToastMessage(undefined, response.data.message, "success", false);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setModal(false);
    resetModalValues();
    fetchData(defaultFilter);
  };
  // Set Checked Checkbox
  const setChecked = async (ID, defaultFilterValues) => {
    if (ID) {
      let response;
      let updateData = {
        reminder_status: 1,
      };
      if (recordType === "PERSONAL") {
        response = await updateDailyReminder(updateData, ID);
      } else {
        response = await updateReminder(updateData, ID);
      }
      if (response) {
        fetchData({
          ...defaultFilterValues,
          type: recordType,
        });
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }
  };
  // Fetch Records
  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    type,
  }) => {
    setDefaultFilter({
      pageSize,
      pageIndex,
      filters,
      globalFilter,
      sortBy,
      type,
    });
    let recordTypeValue = type ? type : recordType;
    let postType = accessType === "PERSONAL" ? accessType : recordTypeValue;
    let userID = await getUser();
    let postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      sortBy,
      globalSearch: globalFilter,
      filters,
    };
    if (recordTypeValue === "PERSONAL") {
      postData["user_id"] = userID;
    }
    if (recordTypeValue === "ADMINISTRATIVE") {
      postData["createdBy"] = userID;
      postData["type"] = postType;
    }
    let response =
      recordTypeValue === "PERSONAL"
        ? await getPersonalReminderList(postData)
        : await getReminderList(postData);
    if (response && response.status === 200) {
      setData(response.data.data.rows);
      setPageCount(Math.ceil(response.data.data.count / pageSize));
    } else if (response.status === 403) {
      showToastMessage(undefined, response.data.message, "error", false);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);
  };
  // Active / Inactive Status Modal
  const handleStatusReminderClick = async (event, id, status) => {
    event.preventDefault();
    setReminderID(id);
    setReminderStatus(status);
    setStatusConfirmationModal(true);
  };
  // Update Reminder Status
  const handleReminderStatus = async (event) => {
    event.preventDefault();
    let postData = {
      status: reminderStatus === 1 ? 0 : 1,
      id: reminderID,
      table: "reminders",
    };
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setStatusConfirmationModal(false);
      setReminderID(null);
      setReminderStatus(null);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };
  // Delete Reminder Modal
  const handleDeleteClick = async (event, id) => {
    event.preventDefault();
    setReminderID(id);
    setDeleteConfirmationModal(true);
  };
  // Delete Reminder
  const handleDeleteReminder = async (event) => {
    event.preventDefault();
    let postData = {
      status: 2,
      id: reminderID,
      table: "reminders",
    };
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setDeleteConfirmationModal(false);
      setReminderID(null);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };
  return (
    <React.Fragment>
      <Header
        title="Reminder-Checklist"
        hasAddButton={userAccess.read}
        handleAddClick={handleTodoReminderCode}
      />
      <div className={styles.remindersTab}>
        <button
          onClick={() => handleTabChange("PERSONAL")}
          className={
            recordType === "PERSONAL"
              ? styles.tabButtonCustomActive
              : styles.tabButtonCustom
          }
        >
          Daily
        </button>
        <button
          onClick={() => handleTabChange("ADMINISTRATIVE")}
          className={
            recordType === "ADMINISTRATIVE"
              ? styles.tabButtonCustomActive
              : styles.tabButtonCustom
          }
        >
          {accessType === "PERSONAL" ? "Personal" : "Administrative"}
        </button>
        <br />
        <br />
        {recordType === "PERSONAL" && (
          <Personal
            userAccess={userAccess}
            handleTodoReminderCode={handleTodoReminderCode}
            setChecked={setChecked}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            defaultFilter={defaultFilter}
          />
        )}
        {recordType === "ADMINISTRATIVE" && (
          <Administrative
            userAccess={userAccess}
            setChecked={setChecked}
            handleTodoReminderCode={handleTodoReminderCode}
            handleStatusReminderClick={handleStatusReminderClick}
            handleDeleteClick={handleDeleteClick}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            defaultFilter={defaultFilter}
            adminAccess={accessType}
          />
        )}
      </div>
      {/* Reminder To Do Modal Form */}
      <NewTodoRemindersModel
        userAccess={userAccess}
        showModal={modal}
        disableType={disableType}
        userID={userID}
        adminAccess={accessType}
        pageTitle={modalTitle}
        modalValues={modalValues}
        saveReminderData={saveReminderData}
        handleCancelClick={(e) => {
          setModal(false);
          resetModalValues();
        }}
      />
      {/* Active / Inactive Status Confirmation Module */}
      <StatusConfirmation
        showModal={statusConfirmationModal}
        title={"Reminder"}
        message={"reminder?"}
        buttonLabel={reminderStatus ? "Inactivate" : "Activate"}
        statusFlag={reminderStatus}
        customFlag={false}
        handleCancelClick={(event) => {
          event.preventDefault();
          setStatusConfirmationModal(false);
          setReminderID(null);
          setReminderStatus(null);
        }}
        handleSubmitClick={handleReminderStatus}
      />
      {/* Delete Reminder Confirmation */}
      <DeleteConfirmation
        showModal={deleteConfirmationModal}
        title="Delete Reminder"
        message="Are you sure want to delete this Reminder?"
        handleCancelClick={(event) => {
          event.preventDefault();
          setDeleteConfirmationModal(false);
          setReminderID(null);
        }}
        handleSubmitClick={handleDeleteReminder}
      />
    </React.Fragment>
  );
};

export default TodoReminders;
