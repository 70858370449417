import { useEffect } from "react";
import styles from "./Payabli.module.css";
import { useCustomer } from "Context/Customer/useCustomer";
import { useFormikContext } from "formik";
import PayabliComponent from "Helpers/PayabliLibraryComponent";
//PAYABLI
const PaymentComponent = (props) => {
  const { setPaymentMethodNonce, customerState } = useCustomer();
  const formik = useFormikContext();

  useEffect(() => {
    const url = new URL(process.env.REACT_APP_API_URL);
    const domainPath = `${url.protocol}//${url.hostname}${url.port ? `:${url.port}` : ''}`; 
    const token = process.env.REACT_APP_PAYABLI_TOKEN;
    const customCssUrl=`${domainPath}/Payabli/cardFormAdmin.css`;
    const timestamp = Date.now();
    const payabliConfigCard = {
      type: "methodEmbedded",
      rootContainer: "pay-component-card",
      defaultOpen: "card",
      token: token,
      temporaryToken: true, // Set the token to be temporary
      forceCustomerCreation: false,
      customCssUrl,
      entryPoint: props.entryPoint,
      customerData: {
        customerNumber: `${timestamp}` // Pass the timestamp as customerNumber
      },
      card: {
        enabled: true,
        inputs: {
          cardNumber: {
            label: " ",
            placeholder: "xxxx xxxx xxxx xxxx",
            floating: false,
            size: 12,
            row: 0,
            order: 0,
          },
          cardExpirationDate: {
            label: " ",
            placeholder: "MM/YY",
            floating: false,
            size: 6,
            row: 2,
            order: 1,
          },
          cardCvv: {
            label: " ",
            placeholder: "CVV",
            floating: false,
            size: 6,
            row: 2,
            order: 0,
          },
          cardHolderName: {
            label: " ",
            placeholder: "Name On The Card",
            floating: false,
            size: 12,
            row: 3,
            order: 2,
          },
        },
      },
      ach: {
        enabled: true,
        inputs: {
          achAccountHolderName: { label: "ACCOUNTHOLDER NAME", size: 6 },
          achRouting: { label: "ROUTING NUMBER", size: 6 },
          achAccount: { label: "ACCOUNT NUMBER", size: 6 },
        },
      },
      functionCallBackSuccess: function (response) {
        if (response.isSuccess) {
          setPaymentMethodNonce(response);
        }
      },
      functionCallBackError: function (errors) {
        console.log("errors: ", errors);
      },
    };

    // Payabli component initialization
    const paycomponentCard = new PayabliComponent(payabliConfigCard);
    window.payabliInstance = paycomponentCard;
  }, [formik.values.payabli_paypoint, customerState.paymentNonce]);

  return (
    <>
      <div
        id="embeddedComponentStyle"
        className={`${styles.embeddedComponentStyle} ${props.className}`}
      >
        <div className="payment-form tab-content">
          <div
            className="tab-pane fade show active"
            id="pay-component-card-cont"
            role="tabpanel"
          >
            <div id="pay-component-card"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentComponent;
