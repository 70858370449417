import { callPostApi, callDeleteApi, callPutApi, callGetApi } from "./api";

export async function getCategoryList(data) {
      // return this.interceptors.POST("categories", data);
      try {
            const response = await callPostApi({ url: "categories", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getCategoryNormalList(data) {
      // return this.interceptors.POST("categories/normal-list", data);
      try {
            const response = await callPostApi({ url: "categories/normal-list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getCategoryHierarchyList(data = "") {
      // return this.interceptors.POST("categories/category-hierarchy-list", data);
      try {
            const response = await callPostApi({ url: "categories/category-hierarchy-list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function deleteCategory(categoryId) {
      // return this.interceptors.DELETE("categories/" + categoryId);
      try {
            const response = await callDeleteApi({ url: "categories/" + categoryId });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function createCategory(data) {
      // return this.interceptors.POST("categories/add", data);
      try {
            const response = await callPostApi({ url: "categories/add", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateCategory(data, categoryId) {
      // return this.interceptors.PUT("categories/" + categoryId, data);
      try {
            const response = await callPutApi({ url: "categories/" + categoryId, body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getCategoryDetail(categoryId) {
      // return this.interceptors.GET("categories/" + categoryId);
      try {
            const response = await callGetApi({ url: "categories/" + categoryId });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateCategoryHierarchy(categoryId, postData) {
      // return this.interceptors.PUT("categories/hierarchiesUpdate/" + categoryId, postData);
      try {
            const response = await callPutApi({ url: "categories/hierarchiesUpdate/" + categoryId, body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function deleteCategoryHierarchy(deleteCategoryHierarchyId) {
      // return this.interceptors.DELETE("categories/hierarchiesUpdate/" + deleteCategoryHierarchyId);
      try {
            const response = await callDeleteApi({ url: "categories/hierarchiesUpdate/" + deleteCategoryHierarchyId });
            return response;
      } catch (error) {
            throw error;
      }
}


