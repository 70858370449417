import Header from "Components/Header/Header";
import ReactTable from "Components/Table/Table";
import { AppContext } from "Context/AppContext";
import { MODULES, ORDER_STATUS_FILTER } from "Helpers/Constants";
import React, { useContext, useState } from "react";
import { withRouter } from "react-router";
// Components
import { getAccessByModuleName } from "Routes/Routes";
import { getSubscriptionSubOrder } from "api/order";
import { formatUsPhone } from "Hooks/commanUtility";
import { DateTime } from "luxon";

const SubscriptionSubOrderList = (props) => {
  const { showToastMessage, modules } = useContext(AppContext);
  // Add / Edit
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    parentId: props.match.params.id,
  });

  const statusList = ORDER_STATUS_FILTER;

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      filters,
      sortBy,
      parentId: props.match.params.id,
    };

    let accountAccess = getAccessByModuleName(
      modules,
      MODULES.SUBSCRIPTION_LIST
    );
    if (accountAccess && accountAccess.permissions.read_access) {
      const response = await getSubscriptionSubOrder(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }

    setLoading(false);
  };

  /* Check Module Access */

  const columns = React.useMemo(() => [
    {
      Header: "Order Number",
      accessor: "order_number",
      placeholder: "Order Number",
      isVisible: true,
      Cell: ({ value, row, column }) => {
        return `${row.original.order_number}`;
      },
    },
    {
      Header: "Order Total",
      accessor: "total",
      placeholder: "Order Total",
      isVisible: true,
      Cell: ({ value, row, column }) => {
        return `${row.original.total}`;
      },
    },
    {
      Header: "Status",
      accessor: "order_status",
      isVisible: true,
      placeholder: "Status",
      isSelectFilter: true,
      selectFilterOption: statusList,
      colWidth: 160,
      Cell: ({ value, row, column }) => {
        let orderStatusData = statusList.filter(
          (status) => status.id === row.original.order_status
        );
        return `${
          orderStatusData.length ? orderStatusData[0]["title"] : "-"
        }`;
      },
    },
    {
      Header: "Recipient Name",
      accessor: "recipient_name",
      isVisible: true,
      placeholder: "Recipient Name",
      Cell: ({ value, row, column }) => {
        return `${row.original.order_recipients[0].recipient_name}`;
      },
    },
    {
      Header: "Recipient Phone",
      accessor: "phone",
      isVisible: true,
      placeholder: "Recipient Phone",
      Cell: ({ value, row, column }) => {
        return `${
          row.original.order_recipients[0].phone
            ? row.original.order_recipients[0].phone_country_code +
              " " +
              formatUsPhone(row.original.order_recipients[0].phone)
            : ""
        }`;
      },
    },
    {
      Header: "Delivery Date",
      accessor: "delivery_date_time",
      isVisible: true,
      placeholder: "Delivery Date",
      Cell: ({ value, row, column }) => {
        return `${
          row.original.delivery_date_time &&
          row.original.delivery_date_time !== null
            ? row.original.delivery_time_type === "Anytime"
              ? DateTime.fromISO(row.original.delivery_date_time)
                  .setZone("America/Los_Angeles")
                  .toFormat("ccc LLL dd, yyyy") +
                " " +
                row.original.delivery_time_type
              : DateTime.fromISO(row.original.delivery_date_time)
                  .setZone("America/Los_Angeles")
                  .toFormat("ccc LLL dd, yyyy, hh:mm a")
            : ""
        }`;
      },
      disableFilters: true,
    },
    {
      Header: "Order Date",
      accessor: "createdAt",
      isVisible: true,
      placeholder: "Order Date",
      Cell: ({ value, row, column }) => {
        return `${
          row.original.createdAt && row.original.createdAt !== null
            ? DateTime.fromISO(row.original.createdAt)
                .setZone("America/Los_Angeles")
                .toFormat("ccc LLL dd, yyyy, hh:mm a")
            : ""
        }`;
      },
      disableFilters: true,
    },
    {
      Header: "Customer Name",
      accessor: "customer_name",
      placeholder: "Customer Name",
      isVisible: true,
      Cell: ({ value, row, column }) => {
        return `${row.original.order_transaction.customer_name}`;
      },
    },
    {
      Header: "Customer Phone",
      accessor: "customer_phone",
      isVisible: true,
      placeholder: "Customer Phone",
      Cell: ({ value, row, column }) => {
        return `${
          row.original.order_transaction.customer_phone
            ? row.original.order_transaction.customer_phone_country_code +
              " " +
              formatUsPhone(row.original.order_transaction.customer_phone)
            : ""
        }`;
      },
    },
    {
      Header: "Customer Email",
      accessor: "email",
      placeholder: "Customer Email",
      isVisible: true,
      disableFilters: false,
      Cell: ({ value, row, column }) => {
        return `${row.original.order_transaction.customer_email}`;
      },
    },
  ]);

  return (
    <React.Fragment>
      <Header title={`Subscription Order List`} />

      <div id="MainContent" className="main-content" />
      <div>
        <ReactTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
          displayColumnVisibility={false}
          displayLastColumnSorting={false}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(SubscriptionSubOrderList);
