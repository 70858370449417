import Interceptors from '../Interceptors/Interceptors';

class Settings {
    constructor() {
        this.interceptors = new Interceptors();
    }

	// General configuration
	async get() {
        return this.interceptors.GET('configuration');
    }

    async update(data) {
        return this.interceptors.PUT('configuration', data);
    }

    async delete(id) {
        return this.interceptors.PUT('configuration/' + id);
    }
	
	async getByConfigurationKey(key) {
        return this.interceptors.GET('configuration/key/'+key);
    }

	// Exception list
    async getExceptions() {
        return this.interceptors.GET('exception-master');
    }

    async addExceptions(data) {
        return this.interceptors.POST('exception-master', data);
    }

    async updateExceptions(data) {
        return this.interceptors.PUT('exception-master', data);
    }

    async deleteExceptions(id) {
        return this.interceptors.DELETE('exception-master/' + id);
    }

	// Payroll date setting for time clock
    async getPayrollDates() {
        return this.interceptors.GET('payroll-date');
    }

    async addPayrollDates(data) {
        return this.interceptors.POST('payroll-date', data);
    }

    async updatePayrollDates(data) {
        return this.interceptors.PUT('payroll-date', data);
    }

    async handleDeletePayrollDate(id) {
        return this.interceptors.DELETE('payroll-date/' + id);
    }

	// Email Notification setting
	async getEmailNotificationList(data) {
        return this.interceptors.POST('store-settings/email-notification/list', data);
    }

	async emailNotificationAdd(data) {
        return this.interceptors.POST('store-settings/email-notification/', data);
    }

	async emailNotificationUpdate(data) {
        return this.interceptors.PUT('store-settings/email-notification/', data);
    }

	async emailNotificationGetById(id) {
        return this.interceptors.GET('store-settings/email-notification/'+ id);
    }

	async emailNotificationDeleteById(id) {
        return this.interceptors.DELETE('store-settings/email-notification/'+ id);
    }

	// Rollover time setting
	async getRolloverTimeInfo() {
        return this.interceptors.GET('store-settings/rollover-time/');
    }

	async rolloverTimeUpdate(data) {
        return this.interceptors.PUT('store-settings/rollover-time/', data);
    }

	// Upcharge Dates setting
	async getUpchargeDatesList(data) {
        return this.interceptors.POST('store-settings/upcharge-date/list', data);
    }

	async upchargeDatesAdd(data) {
        return this.interceptors.POST('store-settings/upcharge-date/', data);
    }

	async upchargeDatesUpdate(data) {
        return this.interceptors.PUT('store-settings/upcharge-date/', data);
    }

	async upchargeDatesGetById(id) {
        return this.interceptors.GET('store-settings/upcharge-date/'+ id);
    }

	async upchargeDatesDeleteById(id) {
        return this.interceptors.DELETE('store-settings/upcharge-date/'+ id);
    }

	// Zipcode group setting
	async getFolderGroups(data) {
        // console.log("1.0.0 folder list => ", data);
		return this.interceptors.GET('store-settings/folder/list', data);
    }

	async getFolderNormalList() {
		return this.interceptors.GET('store-settings/folder/normal-list');
    }

	async groupZipcodeAdd(data) {
        return this.interceptors.POST('store-settings/folder/', data);
    }

	async groupZipcodeUpdate(data) {
        return this.interceptors.PUT('store-settings/folder/', data);
    }

	async groupZipcodeGetById(id) {
        return this.interceptors.GET('store-settings/folder/'+ id);
    }

	async groupZipcodeDeleteById(id) {
        return this.interceptors.DELETE('store-settings/folder/'+ id);
    }

	async zipcodeListByGroup(data, id) {
		// console.log("1.0.0 zipcode list => ", data);
        return this.interceptors.POST('store-settings/zipcode/list/group/'+ id, data);
    }

	async zipcodeAdd(data) {
        return this.interceptors.POST('store-settings/zipcode/', data);
    }

	async zipcodeUpdate(data) {
        return this.interceptors.PUT('store-settings/zipcode/', data);
    }

	async zipcodeGetById(id) {
        return this.interceptors.GET('store-settings/zipcode/'+ id);
    }

	async zipcodeDeleteById(id) {
        return this.interceptors.DELETE('store-settings/zipcode/'+ id);
    }
}

export default Settings;