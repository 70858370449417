import { TextField } from "@material-ui/core";
import { useFormikContext } from "formik";
import React from "react";
import { useLoqate } from "../hooks/useLoqate";
import DropdownLoqate from "./DropdownLoqate";
import { useOrderAttributeProvider } from "../context/order";
const BillingInfo = () => {
  const formik = useFormikContext();
  const { active } = useOrderAttributeProvider();

  const { fetchAddressList, items, onItemClick, show } = useLoqate({
    onResult: (item) => {
      let BuildingNumber = item.BuildingNumber ? item.BuildingNumber + " " : "";
      let BuildingName = item.BuildingName ? item.BuildingName + " " : "";
      let Street = item.Street ? item.Street + " " : "";
      let billing_info = { ...formik.values.billing_info };
      billing_info.customer_billing_address_1 =
        BuildingNumber + BuildingName + `${Street?.trim()}, ` + item.SubBuilding;
      billing_info.customer_billing_city = item.City ?? "";
      billing_info.customer_billing_state = item.Province ?? "";
      billing_info.customer_billing_zipcode =
        (item.PostalCode.includes("-")
          ? item.PostalCode.split("-")[0]
          : item.PostalCode) ?? "";
      formik.setValues((v) => {
        return {
          ...v,
          billing_info,
        };
      });
    },
  });
  return (
    <div className="row mt-5">
      <div className="col-12">
        <h3 className="bold">Billing Information</h3>
      </div>
      {/* <div className="my-2 col-md-6">
        <TextField
          disabled={formik.values.payment_type === "house_account"}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched?.billing_info?.customer_billing_first_name &&
            Boolean(formik.errors?.billing_info?.customer_billing_first_name)
          }
          helperText={
            formik.touched?.billing_info?.customer_billing_first_name &&
            formik.errors?.billing_info?.customer_billing_first_name
          }
          value={formik.values.billing_info.customer_billing_first_name}
          name="billing_info.customer_billing_first_name"
          className="w-100"
          label="Billing First Name"
        />
      </div>
      <div className="my-2 col-md-6">
        <TextField
          disabled={formik.values.payment_type === "house_account"}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched?.billing_info?.customer_billing_last_name &&
            Boolean(formik.errors?.billing_info?.customer_billing_last_name)
          }
          helperText={
            formik.touched?.billing_info?.customer_billing_last_name &&
            formik.errors?.billing_info?.customer_billing_last_name
          }
          value={formik.values.billing_info.customer_billing_last_name}
          name="billing_info.customer_billing_last_name"
          className="w-100"
          label="Billing First Name"
        />
      </div> */}
      <div className="my-2 col-md-8">
        <TextField
          disabled={formik.values.payment_type === "house_account"}
          onChange={(e) => {
            formik.handleChange(e);
            fetchAddressList(e.target.value);
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched?.billing_info?.customer_billing_address_1 &&
            Boolean(formik.errors?.billing_info?.customer_billing_address_1)
          }
          helperText={
            formik.touched?.billing_info?.customer_billing_address_1 &&
            formik.errors?.billing_info?.customer_billing_address_1
          }
          value={formik.values.billing_info.customer_billing_address_1}
          disabled={active === 0 ? false : true}
          name="billing_info.customer_billing_address_1"
          className="w-100"
          label="Billing Address"
        />
        <DropdownLoqate
          addressList={items}
          showDropDown={show}
          onItemClick={onItemClick}
        />
      </div>
      <div className="my-2 col-md-4">
        <TextField
          disabled={formik.values.payment_type === "house_account"}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched?.billing_info?.customer_billing_city &&
            Boolean(formik.errors?.billing_info?.customer_billing_city)
          }
          helperText={
            formik.touched?.billing_info?.customer_billing_city &&
            formik.errors?.billing_info?.customer_billing_city
          }
          value={formik.values.billing_info.customer_billing_city}
          disabled={active === 0 ? false : true}
          name="billing_info.customer_billing_city"
          className="w-100"
          label="Billing City"
        />
      </div>
      <div className="my-2 col-md-6">
        <TextField
          disabled={formik.values.payment_type === "house_account"}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched?.billing_info?.customer_billing_state &&
            Boolean(formik.errors?.billing_info?.customer_billing_state)
          }
          helperText={
            formik.touched?.billing_info?.customer_billing_state &&
            formik.errors?.billing_info?.customer_billing_state
          }
          value={formik.values.billing_info.customer_billing_state}
          disabled={active === 0 ? false : true}
          name="billing_info.customer_billing_state"
          className="w-100"
          label="Billing State"
        />
      </div>
      <div className="my-2 col-md-6">
        <TextField
          disabled={formik.values.payment_type === "house_account"}
          onChange={(e) => {
            if (e.target.value.length < 21) formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched?.billing_info?.customer_billing_zipcode &&
            Boolean(formik.errors?.billing_info?.customer_billing_zipcode)
          }
          helperText={
            formik.touched?.billing_info?.customer_billing_zipcode &&
            formik.errors?.billing_info?.customer_billing_zipcode
          }
          value={formik.values.billing_info.customer_billing_zipcode}
          disabled={active === 0 ? false : true}
          name="billing_info.customer_billing_zipcode"
          className="w-100"
          label="Billing Zipcode"
        />
      </div>
    </div>
  );
};

export default BillingInfo;
