import { callPostApi, callDeleteApi, callGetApi } from "./api";

export async function getHolidaysCutoffList(data) {
  // return this.interceptors.POST("holiday-cutoff", data);
  try {
    const response = await callPostApi({ url: "holiday-cutoff", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}
export async function deleteHolidaysCutoff(id) {
  // return this.interceptors.DELETE("holiday-cutoff/delete/" + id);
  try {
    const response = await callDeleteApi({ url: "holiday-cutoff/delete/" + id });
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getDetailsHoliday(id) {
  // return this.interceptors.GET("holiday-cutoff/" + id);
  try {
    const response = await callGetApi({ url: "holiday-cutoff/" + id });
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getCategoriesOnSearch(search) {
  // return this.interceptors.GET("categories/search?keyword=" + search);
  try {
    const response = await callGetApi({ url: "categories/search?keyword=" + search });
    return response;
  } catch (error) {
    throw error;
  }
}
export async function addCutOff(data) {
  // return this.interceptors.POST("holiday-cutoff/add", data)
  try {
    const response = await callPostApi({ url: "holiday-cutoff/add", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}
export async function updateCutOff(id, data) {
  // return this.interceptors.POST("holiday-cutoff/update/" + id, data)
  try {
    const response = await callPostApi({ url: "holiday-cutoff/update/" + id, body: data });
    return response;
  } catch (error) {
    throw error;
  }
}
