import { CardContent } from '@material-ui/core'
import Header from 'Components/Header/Header'
import React from 'react'
import ReactDiffViewer from "react-diff-viewer";
//scss
import classes from "./index.module.scss";

const AuditTrailDetails = ({oldValue,newValue}) => {
  return (
    <React.Fragment>
        <Header title="Audit Trail Detail"/>
        <div className="row">
          <div className={`${classes.cardBody}`}>
          <CardContent >
                <ReactDiffViewer
                oldValue={oldValue}
                newValue={newValue}
                // oldValue={oldCode}
                // newValue={newCode}
                splitView={true}
                useDarkTheme={false}
                rightTitle={"New Data"}
                leftTitle={"Old Data"}
                hideLineNumbers={false}
                linesOffset={0}
                disableWordDiff={true}
                showDiffOnly={false}
                />
            </CardContent>
          </div>
        </div>
    </React.Fragment>
  )
}

export default AuditTrailDetails