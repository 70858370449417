import React from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import styles from "./index.module.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const TranferOnfleetToBurq = ({ open, setOpen, changeOndemandToBurq }) => {

    const closeModel = () => {
        setOpen(false)
    }
    return (
        <React.Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={closeModel}
                classes={{
                    paper: styles.confirmmodalDialog,
                }}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description">
                <DialogContent className={`${styles.modalContent} `}>
                    <div style={{ color: "#f30000", fontSize: "17px" }}>
                    This action will instantly charge the French Florist credit card. The client will NOT be automatically billed. You MUST manually charge the client.
                    </div>
                    <div>
                        <a data-dismiss="modal"
                            onClick={changeOndemandToBurq}
                            className="btn gray-btn d-inline-block mb-3 mr-2 mt-2"
                            style={{ color: "white" }}>
                            Proceed
                        </a>
                        <a data-dismiss="modal"
                            onClick={closeModel}
                            className={`secondary-btn d-inline-block mt-4 mb-3 mr-2  cursor-pointer`}
                            style={{ color: "white" }}>
                            Cancel
                        </a>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default TranferOnfleetToBurq