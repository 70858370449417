// Packages
import React, { useContext, useEffect } from "react";

// Services
import Users from "./../../Services/Users/Users";
import { userLogout } from "api/user";

// Context
import { AppContext } from "./../../Context/AppContext";

// Scss
import styles from "./index.module.scss";

// Images
import NotificationIcon from "./../../Assets/images/notification.svg";
import ProfileIcon from "./../../Assets/images/profile-icon.svg";
import LogoutIcon from "./../../Assets/images/logout.svg";
import BarIcon from "./../../Assets/images/bar.svg";
import AddIcon from "./../../Assets/images/add.svg";
import orderDuplicate from "./../../Assets/images/order-duplicate.svg";
import orderReplace from "./../../Assets/images/order-replace.svg";
import { withRouter } from "react-router";
import CheckBox from "Components/FormControl/CheckBox/CheckBox";
import { useFormikContext } from "formik";
import { useOrderAttributeProvider } from "Pages/Order/Order/context/order";
import { OrderConfigContext } from "Context/OrderConfigContext";
import CustomCheckBox from "Components/FormControl/CustomCheckbox/CustomCheckBox";
import StatusConfirmation from "Components/Modal/StatusConfirmation/StatusConfirmation";
import { useState } from "react";

const Header = (props) => {
  const usersService = new Users();

  const { logout, notificationCount } = useContext(AppContext);
  const formik = useFormikContext();
  const { active, setActive, module } = useOrderAttributeProvider();
  const config = useContext(OrderConfigContext);
  
  const [showStatusModal,setShowStatusModal] = useState(false)
  //when CSR can create a new order and check is_subscribe_order at that time CSR doesn't select multi recipients,order_type only selects deliver order, delivery_type only selects delivery in the dropdown 
  useEffect(()=>{
    if(formik?.values?.is_subscribe_order && module==="new-order"){
      formik.setValues((v)=>({
        ...v,
        recipients:[formik?.values?.recipients[0]],
        delivery_information:{
          ...v.delivery_information,
          frequency:7,
          order_type:0,
          delivery_type_id:2,
          delivery_date:null,
        }
      }))
      setActive(0);
    }
  },[formik?.values?.is_subscribe_order])

  const handleSubscription = (event)=>{
    event.preventDefault();
    formik.setValues((v) => ({
      ...v,
      is_subscribe_order:1
  }));
  setShowStatusModal(false)
  }
  const onSubScriptionChange = (data)=>{
    if(formik?.values?.recipients?.length>1){
      setShowStatusModal(true)
    }else{

      //console.log("subscriptiondata",data)
      formik.setValues((v) => ({
            ...v,
            delivery_information: {
              ...v.delivery_information,
              delivery_type_id: 2,
              delivery_date: null,
              order_type: 0,
              delivery_time: "",
              charge: 0,
              upcharge_id: 0,
              upcharge_amount: 0,
              delivery_time_id: null,
              delivery_type: "Delivery Service",
              burq_quote_id:"",
              burq_quote_expires:""
            },
            is_subscribe_order:data ? 1 : 0
      }));
    }
  }

  const handleLogout = async (event) => {
    event.preventDefault();

    // await usersService.logout();
    await userLogout();

    logout();
    localStorage.removeItem("adminMenuData");

    props.history.replace("/login");
  };


  return (
    <div
      className={`${styles.topPanel} mb-4 d-flex justify-content-between align-items-center`}
    >
      <h2 className={styles.pageHeading}>
          {props.title}
        {props.subscriptionChecbox && (
          <span className={styles.SubscriptionCheckbox}>
            <CustomCheckBox
            label="Is Subscription Order"
            customClassName={'isSubscriptionCheckbox'}
            value={formik.values.is_subscribe_order}
            onChange={(data)=>{onSubScriptionChange(data)}}
            />
          </span>
          )
        }
        {props.hasAddButton && (
          <span
            onClick={props.handleAddClick}
            className={`link cursor-pointer ${styles.iconWrapper}`}
          >
            <img className={styles.icon} src={AddIcon} alt="Add" />
          </span>
        )}
        {props.hasDuplicateOrderButton && (
          // <a
          //   onClick={props.handleDuplicateOrderClick}
          //   href="/"
          //   className={`link cursor-pointer ${styles.iconWrapper}`}
          // >
          //   <img
          //     className={`${styles.icon} ${styles.duplicateIcon}`}
          //     src={orderDuplicate}
          //     alt=""
          //   />
          // </a>

          <span
            onClick={props.handleDuplicateOrderClick}
            className={`link cursor-pointer ${styles.iconWrapper}`}
          >
            <img
              className={`${styles.icon} ${styles.duplicateIcon}`}
              src={orderDuplicate}
              alt=""
            />
          </span>
        )}
        {props.hasReplaceOrderButton && (
          // <a
          //   onClick={props.handleReplaceOrderClick}
          //   href="/"
          //   className={`link cursor-pointer ${styles.iconWrapper}`}
          // >
          //   <img className={styles.icon} src={orderReplace} alt="" />
          // </a>
          <span
            onClick={props.handleReplaceOrderClick}
            className={`link cursor-pointer ${styles.iconWrapper}`}
          >
            <img className={styles.icon} src={orderReplace} alt="" />
          </span>
        )}
        {props.hasFraudOrderButton && (
          <button
            onClick={props.handleFraudOrderClick}
            className={`btn pink-btn ${styles.orderButton}`}
          >
            Mark As Fraud
          </button>
        )}
        {props.hasUnFraudOrderButton && (
          <button
            onClick={props.handleUnFraudOrderClick}
            className={`btn pink-btn ${styles.orderButton}`}
          >
            Mark As Un-Fraud
          </button>
        )}
        {props.hasOnfleetOrderButton && (
          <button
            onClick={props.handleOnfleetOrderClick}
            className={`btn pink-btn ${styles.orderButton}`}
          >
            Onfleet
          </button>
        )}
        {props.hasBurqOrderButton && (
          <button
            onClick={props.handleBurqOrderClick}
            className={`btn pink-btn ${styles.orderButton}`}
          >
            On-Demand
          </button>
        )}
      </h2>

      <ul className={styles.topLinks}>
        <li>
          <a
            className="link"
            href="/"
            onClick={(e) => {
              e.preventDefault();
              props.history.push("/notifications");
            }}
          >
            {notificationCount > 0 && <span></span>}
            <img src={NotificationIcon} alt="" />
          </a>
        </li>
        <li>
          <a
            className="link"
            href="/"
            onClick={(e) => {
              e.preventDefault();
              props.history.push("/profile");
            }}
          >
            <img src={ProfileIcon} alt="Profile" />
          </a>
        </li>
        <li>
          <a
            className="link"
            href="/"
            onClick={(e) => {
              e.preventDefault();
              handleLogout(e);
            }}
          >
            <img src={LogoutIcon} alt="Logout" />
          </a>
        </li>
        <li className="d-inline-block d-lg-none">
          <a
            className="link"
            href="/"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <img src={BarIcon} alt="" />
          </a>
        </li>
      </ul>
      <StatusConfirmation
        showModal={showStatusModal}
        title={"You are going to make a subscription order."}
        message={"The subscription order accepts only 1 recipient, hence all the additional recipient(s) except the 1st recipient will be removed along with all the other details that might have been added. Are you sure to make a subscription order?"}
        buttonLabel={"Confirm"}
        customFlag={true}
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowStatusModal(false);
        }}
        handleSubmitClick={handleSubscription}
      />
    </div>
  );
};

export default withRouter(Header);
