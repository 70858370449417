import React from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// SCSS
import styles from "./index.module.scss";

// Component
import Text from "./../../FormControl/Text/Text";
import Select from "../../FormControl/Select/Select";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const GroupZipCodeModel = ({
  showModal,
  pageTitle,
  submitStatus,
  group_name,
//  status,
  onChange,
  handleCancelClick,
  handleSubmitClick,
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <Text
                  type="text"
                  label="Group Name"
                  value={group_name}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("group_name", data)}
                  id="group_name"
                />
              </div>
			  {/* { pageTitle == "Edit group" && <div className="col-md-12 col-xs-12">
					<Select
						label="Status"
						parentClass='text-left'
						value={status}
						required={true}
						submitStatus={submitStatus}
						onChange={(data) => onChange('status', data)}
						options={[{ id: 0, title: 'In Active' }, { id: 1, title: 'Active' }]}
						disabled={false}
					/>
				</div>} */}
            </div>
            <a
              href="/"
              data-dismiss="modal"
              onClick={handleSubmitClick}
              className="btn gray-btn d-inline-block mt-4 mb-4 mr-2"
            >
              Save
            </a>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default GroupZipCodeModel;
