export const blogState = {
  primary_info: {
    title: "",
    detail:"",
    slug: "",
    description: "",
    keywords: "",
    author_name: "",
    read_time: "",
    blog_date: "",
    url_category_slug:"",
  },  
  categories: [],
  banners: [
    {
      id: 0,
      parent_id: 0,
      module: "blogs",
      position: "LEFT",
      banner_text: "",
      button_label: "",
      button_url: "",
      description: "",
      image: "",
      is_supported_image: 1,
      show_oval: 0,
    },
  ],
  seo: {
    id: 0,
    parent_id: 0,
    module: "blogs",
    is_robot: false,
    is_no_follow: false,
    is_no_index: false,
    image_alt_text: "",
    page_title: "",
    meta_title: "",
    meta_description: "",
    canonical_url: "",
    og_title: "",
    og_description: "",
    og_image_url: "",
    og_image_alt: "",
    og_url: "",
    og_image_type: "",
    og_site_name: "",
    og_image_height: 0,
    og_image_width: 0,
    twitter_title: "",
    twitter_image_url: "",
    twitter_description: "",
    twitter_site: "",
    twitter_creator: "",
    schema: "",
  },
  setBlogAllData: "",
  blogError: false,
  bannerError: false,
  seoError: false,
  ogInfoError: false,
  twitterError: false,
  schemaError: false,
  similarBlogData: []
};