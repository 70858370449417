import React from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';

// SCSS
import styles from './index.module.scss';

// let yesterday = moment().subtract(1, "day");
// function valid(current) {
//   return current.isAfter(yesterday);
// }

// disable past dates
const yesterday = moment().subtract(1, 'day');
const today = moment();
const valid = (current) => {
  return current.isAfter(yesterday);
};
const todayValid = (current) => {
  return current.isAfter(today);
};
const Date = ({
  label,
  type,
  value,
  placeholder,
  submitStatus,
  required,
  disabled,
  onChange,
  dateFormat,
  timeFormat,
  className,
  parentClass,
  checkPassDate,
  disableToday,
  fieldIcon,
}) => {
  return (
    <React.Fragment>
      <div
        className={`form-group bmd-form-group ${value ? 'is-filled' : ''} ${
          submitStatus && required && !value ? styles.errorInput : ''
        } ${parentClass}`}
      >
        {label && <label className="bmd-label-floating">{label}</label>}{' '}
        {checkPassDate ? (
          <Datetime
            value={value}
            closeOnSelect
            className={`${className} ${fieldIcon ? styles.hasIcon : ''}`}
            // dateFormat={type === "date" ? dateFormat : false}
            dateFormat={
              type === 'date'
                ? dateFormat
                : type === 'dateTime'
                ? dateFormat
                : false
            }
            // timeFormat={type === "time" ? true : false}
            timeFormat={
              type === 'time' ? true : type === 'dateTime' ? timeFormat : false
            }
            inputProps={{ placeholder, disabled }}
            onChange={(event) => onChange(event)}
            isValidDate={disableToday ? todayValid : valid}
          />
        ) : (
          <Datetime
            value={value}
            closeOnSelect
            className={`${className} ${fieldIcon ? styles.hasIcon : ''}`}
            // dateFormat={type === "date" ? dateFormat : false}
            dateFormat={
              type === 'date'
                ? dateFormat
                : type === 'dateTime'
                ? dateFormat
                : false
            }
            // timeFormat={type === "time" ? true : false}
            timeFormat={
              type === 'time' ? true : type === 'dateTime' ? timeFormat : false
            }
            inputProps={{ placeholder, disabled }}
            onChange={(event) => onChange(event)}
          />
        )}
        {fieldIcon && (
          <img
            src={fieldIcon}
            className={`${styles.bmdIcon}`}
            alt="fieldIcon"
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Date;
