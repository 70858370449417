import React from "react";

// SCSS
import styles from "./index.module.scss";

// COMPONENTS
import Text from "../../../Components/FormControl/Text/Text";
import WYSIWIGEditor from "../../../Components/WYSIWIGEditor/WYSIWIGEditor";

import { useCmsPage } from "../../../Context/Cms-page/useCmsPage";

function PrimaryInformation(props) {
  const {
    cmsPageState,
    setPageName,
    setPageSlugName,
    setPageDescription,
  } = useCmsPage();

  return (
    <form className={`row`}>
      <div className={`${styles.productsWrapper}`} id="primary-information">
        <div className={`mt-2 md-4`}>
          <div className={`row`}>
            <div className={`col-md-6 col-xs-12`}>
              <Text
                type="text"
                label="Page Title"
                value={cmsPageState.title}
                onChange={(data) => setPageName(data)}
                id="title"
              />
            </div>
            <div className={`col-md-6 col-xs-12`}>
              <Text
                type="text"
                label="Slug"
                value={cmsPageState.slug}
                onChange={(data) => setPageSlugName(data)}
                id="slug"
              />
            </div>
          </div>

          <br></br>
          <div className={`row`}>
            <label
              className="bmd-label-floating"
              style={{ marginLeft: "15px" }}
            >
              Description
            </label>
            <div className={`col-md-12 col-xs-12`}>
              <WYSIWIGEditor
                data={cmsPageState.description}
                onChange={(data) => {
                  // console.log("WYSIWIGEditor", data);
                  setPageDescription(data)
                  // BlogFormik.setValues((v) => {
                  //   return { ...v, description: data };
                  // });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <button
        className="d-none primary-info-submit-button"
        type="submit"
      ></button>
    </form>
  );
}

export default PrimaryInformation;
