/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import AddIcon from "../../Assets/images/add.svg";

// SCSS
import styles from "./index.module.scss";

//IMAGES
import DeleteIcon from "../../Assets/images/delete.svg";

import { useBlog } from "../../Context/Cms-blog/useBlog";

// COMPONENTS
import ManageSimilarBlogModal from "../../Components/Modal/Blogs/AddSimilarBlog";

function ProductVariations(props) {
  // console.log("props.similarBlogData", props.similarBlogData);
  const [showAddModal, setShowAddModal] = useState(false);
  const [duplicateBlog, setDuplicateBlog] = useState(0);
  const [duplicateBlogMessage, setDuplicateBlogMessage] = useState(0);

  const { deleteSimilarBlogData, setSimilarBlogData } = useBlog();

  const handleManageBlogClick = async (id) => {
    //     alert(id);
    
    if (id !== "") {
      let filterData = props.allBlogList?.filter((data) => {
        if (data.id === id) {
          data["similar_blog_id"] = data.id;
          return data;
        }
      });

      //check for duplicate blog data
      let checkDuplicateFlag;
      if (props.updateBlogId !== "" && props.updateBlogId !== undefined) {
        if (props.updateBlogId === filterData[0]["similar_blog_id"].toString()) {
          checkDuplicateFlag = 1;
        }
      }

      if (checkDuplicateFlag && checkDuplicateFlag === 1) {
        setDuplicateBlog(1);
        setDuplicateBlogMessage('You can not add same blog as similar blog');
      } else {
        let checkForDuplicate = props.similarBlogData?.filter((data) => {
          if (data.similar_blog_id === filterData[0]["similar_blog_id"]) {
            return true;
          }
        });

        if (checkForDuplicate && checkForDuplicate.length > 0) {
          setDuplicateBlog(1);
          setDuplicateBlogMessage('This blog is already added');
        } else {
          setSimilarBlogData(filterData[0]);
          setShowAddModal(false);
        }
      }
    } else {
      setShowAddModal(false);
      setDuplicateBlog(0);
      setDuplicateBlogMessage('');
    }
  };

  const handleManageSimilarBlogClick = async () => {
    setShowAddModal(true);
  };

  return (
    <React.Fragment>
      <div className={`${styles.productsWrapper}`} id="variations">
        <div className="">
          <div className="">
            <div className={styles.title}>You Might Also Like</div>

            <span
              className={`link cursor-pointer ml-2 ${styles.searchIconWrapper}`}
              onClick={(e) => {
                e.preventDefault();
                handleManageSimilarBlogClick();
              }}
            >
              <img className={styles.searchIcon} src={AddIcon} alt="" />
            </span>

            <div className="table-responsive mt-2 mb-4">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-left"></th>
                    <th className="text-left">Title</th>
                    <th className="text-left"></th>
                    <th className="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  {props.similarBlogData && props.similarBlogData.length > 0 ? (
                    props.similarBlogData?.map((d, i) => {
                      return (
                        <tr id={i}>
                          <td className={``}>
                            {d.banners.length > 0 &&
                            d.banners[0].image !== "" ? (
                              <div className={styles.ProductImageWrapper}>
                                <img src={d.banners[0].image} alt={d.title} />
                              </div>
                            ) : (
                              <div className={`${styles.emptyImage}`}>
                                <span>Image</span>
                              </div>
                            )}
                          </td>
                          <td className={``}>{d.title}</td>
                          <td className={``}></td>
                          <td className={`link cursor-pointer`}>
                            <img
                              onClick={(e) => deleteSimilarBlogData(i)}
                              className={`${styles.searchIcon} mr-3`}
                              src={DeleteIcon}
                              alt=""
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className={``}>
                        <div className={`${styles.emptyImage}`}>
                          <span>Image</span>
                        </div>
                      </td>
                      <td className={``}>No Data</td>
                      <td className={``}></td>
                      <td className={``}></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ManageSimilarBlogModal
        showModal={showAddModal}
        pageTitle="Add Similar Blogs"
        allBlogList={props.allBlogList}
        handleCancelClick={(e) => {
          setShowAddModal(false);
          setDuplicateBlog(0);
          setDuplicateBlogMessage('');
        }}
        duplicateBlog={duplicateBlog}
        duplicateBlogMessage={duplicateBlogMessage}
        handleSubmitClick={handleManageBlogClick}
      />
    </React.Fragment>
  );
}

export default ProductVariations;
