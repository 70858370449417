import { Dialog, DialogContent, Slide } from '@material-ui/core'
import PhoneNumber from 'Components/FormControl/PhoneNumber/PhoneNumber';
import SwitchBox from 'Components/FormControl/SwitchBox/SwitchBox';
import Text from 'Components/FormControl/Text/Text';
import { DEFAULT_COUNTRY_CODE_NAME } from 'Helpers/Constants';
import React, { useState } from 'react'
import styles from "./index.module.scss";
import { useFormik } from "formik";
import { useEffect } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const CreateCustomer = ({
  showModal,
  pageTitle,
  handleCancelClick,
  handleSubmitClick,
}) => {
    const [showErrors, setShowErrors] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(false);
    
    const formik = useFormik({
        initialValues:{
          first_name:"",
          last_name: "",
          email: "",
          password:"",
          phone: "",
          alt_phone:"",
          country_code:"+1",
          alt_country_code:"+1",
          unique_about_customer:"",
          is_house_account: 0,
          is_business_account: 0,
        },
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values) => {
          handleSubmitClick(values);
          setShowErrors(false)
        },
        validate:(values) => {
          const errors = {};
        //   values.first_name = values.title.trim();
        if (values.first_name?.trim().length === 0) {
            errors.first_name = "First name is required";
          }
          if (values.last_name?.trim().length === 0) {
            errors.last_name = "Last name is required";
          }
          if (!values.email?.trim()) {
            errors.email = "Email address is required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
    
          if (values.password?.trim().length < 5) {
            errors.password = "Password must be more than 5 characters";
          }
          if (values.phone?.trim().length === 0) {
            errors.phone = "Phone number is required";
          } else if (values.phone.length < 10) {
            errors.phone = "Phone number is invalid";
          }
          return errors;
        }
      })
      useEffect(()=>{
          if(!showModal){
             formik.setValues({
                 first_name:"",
                 last_name: "",
                 email: "",
                 password:"",
                 phone: "",
                 alt_phone:"",
                 country_code:"+1",
                 alt_country_code:"+1",
                 unique_about_customer:"",
                 is_house_account: 0,
                 is_business_account: 0,
               })
          }
      },[showModal])
  return (
    <React.Fragment>
        <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
            <form onSubmit={(e) => {
              setShowErrors(true);
              formik.handleSubmit(e);
              setSubmitStatus(true)
            }}>
                <div className={`text-center ${styles.modalBody}`}>
                <h5 className={`my-3 text-center ${styles.modalTitle}`}>
                    {pageTitle}
                </h5>
                <div className="row">
                    <div className="col-md-6 col-xs-12">
                        <Text
                            type="text"
                            label="First Name"
                            value={formik.values.first_name}
                            required={true}
                            submitStatus={submitStatus}
                            onChange={(data) =>
                                formik.setValues((v) => {
                                  return { ...v, first_name: data };
                                })
                            }
                            touched={formik.touched.type}
                            error={formik.errors.first_name}
                            />
                            {showErrors && (
                                <p className={`${styles.error} text-left`}>
                                  {formik.errors.first_name}
                                </p>
                              )}
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <Text
                            type="text"
                            label="Last Name"
                            value={formik.values.last_name}
                            required={true}
                            submitStatus={submitStatus}
                            onChange={(data) =>
                                formik.setValues((v) => {
                                    return { ...v, last_name: data };
                                })
                            }
                            touched={formik.touched.type}
                            error={formik.errors.last_name}
                        />
                        {showErrors && (
                                <p className={`${styles.error} text-left`}>
                                  {formik.errors.last_name}
                                </p>
                              )}
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <Text
                            type="email"
                            label="Email"
                            value={formik.values.email}
                            required={true}
                            submitStatus={submitStatus}
                            onChange={(data) =>
                                formik.setValues((v) => {
                                  return { ...v, email: data };
                                })
                            }
                            touched={formik.touched.type}
                            error={formik.errors.email}
                        />
                        {showErrors && (
                                <p className={`${styles.error} text-left`}>
                                  {formik.errors.email}
                                </p>
                              )}
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <Text
                            type="password"
                            label="Password"
                            value={formik.values.password}
                            required={true}
                            submitStatus={submitStatus}
                            touched={formik.touched.type}
                            error={formik.errors.password}
                            onChange={(data) =>
                                formik.setValues((v) => {
                                  return { ...v, password: data };
                                })
                            }
                        />
                        {showErrors && (
                                <p className={`${styles.error} text-left`}>
                                  {formik.errors.password}
                                </p>
                              )}
                    </div>
                    <div className="col-md-6 col-xs-12 mt-3">
                        <PhoneNumber
                            countryCode={DEFAULT_COUNTRY_CODE_NAME}
                            specialLabel={"Phone 1"}
                            initValue={formik.values.country_code + formik.values.phone}
                            fieldName={`Phone 1`}
                            onChange={(phone, countryData, event, formatedValue) => {
                                let countryCode = "+" + countryData.dialCode;
                                let finalMobileNum = formatedValue
                                ? formatedValue?.split(countryCode)[1]?.trim()
                                : "";
                                formik.setValues((v) => {
                                    return { ...v, country_code: countryCode,phone: finalMobileNum };
                                })
                              }}
                        />
                            {showErrors && (
                                <p className={`${styles.error} text-left`}>
                                  {formik.errors.phone}
                                </p>
                              )}
                    </div>
                    <div className="col-md-6 col-xs-12 mt-3">
                        <PhoneNumber
                            countryCode={DEFAULT_COUNTRY_CODE_NAME}
                            specialLabel={"Phone 2"}
                            initValue={formik.values.alt_country_code + formik.values.alt_phone}
                            fieldName={`Phone 2`}
                            onChange={(phone, countryData, event, formatedValue) => {
                                let countryCode = "+" + countryData.dialCode;
                                let finalMobileNum = formatedValue
                                ? formatedValue?.split(countryCode)[1]?.trim()
                                : "";
                                formik.setValues((v) => {
                                    return { ...v, alt_country_code: countryCode,alt_phone: finalMobileNum };
                                })
                                
                            }}
                        />
                        
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <Text
                            type="text"
                            label="What's unique about this customer?"
                            required={false}
                            value={formik.values.unique_about_customer}
                            submitStatus={submitStatus}
                            onChange={(data) =>
                                formik.setValues((v) => {
                                  return { ...v, unique_about_customer: data };
                                })
                            }
                        />
                    </div>
                    <div className="col-md-3 col-xs-12 pr-2 mt-2">
                        <div class="form-group bmd-form-group is-filled text-left">
                            <label class="bmd-label-floating">House Account</label>
                            <SwitchBox
                                value={formik.values.is_house_account}
                                onChange={(data) =>
                                    formik.setValues((v) => {
                                      return { ...v, is_house_account: data ? 1 : 0 };
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="col-md-3 col-xs-12 mt-2">
                        <div class="form-group bmd-form-group is-filled text-left">
                            <label class="bmd-label-floating">Business Account</label>
                            <SwitchBox
                                value={formik.values.is_business_account}
                                onChange={(data) =>
                                    formik.setValues((v) => {
                                      return { ...v, is_business_account: data ? 1 : 0 };
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
                <button
                  type="submit"
                  className="btn gray-btn d-inline-block mt-4 mb-4 mr-2"
                >
                  Save
                </button>
                </div>
            </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default CreateCustomer