export const ProductActions = {
  SET_PRIMARY_INFO: "SET_PRIMARY_INFO",
  CHECK_PRIMARY_INFO: "CHECK_PRIMARY_INFO",
  SET_NAVIGATION_INFO: "SET_NAVIGATION_INFO",
  CHECK_NAVIGATION_INFO: "CHECK_NAVIGATION_INFO",
  SET_VARIANT_INFO: "SET_VARIANT_INFO",
  CHECK_VARIANT_INFO: "CHECK_VARIANT_INFO",
  SET_ACTIVE_INDEX: "SET_ACTIVE_INDEX",
  DELETE_PRODUCT_VARIANT: "DELETE_PRODUCT_VARIANT",
  SET_PRODUCT_FLAG_INFO: "SET_PRODUCT_FLAG_INFO",
  SET_IS_LOCAL_VALUE: "SET_IS_LOCAL_VALUE",
  SET_SEO_DATA: "SET_SEO_DATA",
  CHECK_SEO_DATA: "CHECK_SEO_DATA",
  SET_PRODUCT_NOTE_INFO: "SET_PRODUCT_NOTE_INFO",
  DELETE_PRODUCT_NOTE: "DELETE_PRODUCT_NOTE",
  SET_ACTIVE_INDEX_NOTE: "SET_ACTIVE_INDEX_NOTE",
  CLR_DATA_PRODUCT: "CLR_DATA_PRODUCT",
  SET_ALL_PRODUCT_DATA: "SET_ALL_PRODUCT_DATA",
  UPDATE_SEO_DATA: "UPDATE_SEO_DATA",
  UPDATE_OG_INFO_DATA: "UPDATE_OG_INFO_DATA",
  CHECK_OG_INFO_DATA: "CHECK_OG_INFO_DATA",
  UPDATE_TWITTER_INFO_DATA: "UPDATE_TWITTER_INFO_DATA",
  CHECK_TWITTER_INFO_DATA: "CHECK_TWITTER_INFO_DATA",
  UPDATE_SCHEMA_DATA: "UPDATE_SCHEMA_DATA",
  CHECK_SCHEMA_DATA: "CHECK_SCHEMA_DATA",
  SET_SIMILAR_PRODUCT: "SET_SIMILAR_PRODUCT",
  SET_SIMILAR_PRODUCT_INDEX: "SET_SIMILAR_PRODUCT_INDEX",
  DELETE_SIMILAR_PRODUCT: "DELETE_SIMILAR_PRODUCT",
  SET_PRODUCT_IMAGE_DATA: "SET_PRODUCT_IMAGE_DATA",
  SET_EDIT_VARIANT_PRODUCT_INDEX: "SET_EDIT_VARIANT_PRODUCT_INDEX",
  SET_USER_DATA_INFO: "SET_USER_DATA_INFO",
  SET_PAGE_FOR_VIEW: "SET_PAGE_FOR_VIEW",
  SET_CATEGORY_HIERARCHY_DATA: "SET_CATEGORY_HIERARCHY_DATA",
  DELETE_CATEGORY_HIERARCHY_DATA: "DELETE_CATEGORY_HIERARCHY_DATA",
  DELETE_PRODUCT_IMAGE_DATA: "DELETE_PRODUCT_IMAGE_DATA",
  SET_DELETE_PRODUCT_IMAGE_DATA: "SET_DELETE_PRODUCT_IMAGE_DATA",
  ADD_NEW_BANNER: "ADD_NEW_BANNER",
  REMOVE_BANNER: "REMOVE_BANNER",
  UPDATE_BANNER_DATA: "UPDATE_BANNER_DATA",
  CHECK_BANNER_DATA: "CHECK_BANNER_DATA",
  SET_PRODUCT_BANNER_DATA: "SET_PRODUCT_BANNER_DATA",
  ADD_SIMILAR_PRODUCT_DATA: "ADD_SIMILAR_PRODUCT_DATA",
  DELETE_SIMILAR_PRODUCT_DATA: "DELETE_SIMILAR_PRODUCT_DATA",
  CHECK_PRODUCT_NOTE_DATA: "CHECK_PRODUCT_NOTE_DATA",
  SET_SIMILAR_PRODUCTPRODUCT_ARRAY: "SET_SIMILAR_PRODUCTPRODUCT_ARRAY",
  CLR_PRODUCT_VARIANT_DATA: "CLR_PRODUCT_VARIANT_DATA",
  UPDATE_PRODUCT_IMAGE_DATA: "UPDATE_PRODUCT_IMAGE_DATA",
};
