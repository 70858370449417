import React, { useState } from "react";
// SCSS
import styles from "./index.module.scss";

const Text = ({
  label,
  type,
  value,
  fieldIcon,
  submitStatus,
  required,
  disabled,
  onChange,
  error,
  defaultValue,
  onBlur,
  touched,
  onFocus,
  iconClick,
  onChangeWithEvent,
  id,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(!!value);
  return (
    <div
      className={`form-group bmd-form-group ${isFocused ? "is-focused" : ""} ${
        value ? "is-filled" : ""
      } ${submitStatus && required && !value ? styles.errorInput : ""} ${
        fieldIcon ? styles.hasIcon : ""
      }`}
    >
      <label className={`${styles.bmdLabelFloating} bmd-label-floating`}>
        {label}
      </label>
      <input
        id={id ?? ""}
        type={type}
        value={value}
        onFocus={(e) => {
          setIsFocused(true);
          if (onFocus) {
            onFocus(e);
          }
        }}
        onBlur={(e) => {
          setIsFocused(false);
          if (onBlur) {
            onBlur(e);
          }
        }}
        // onBlur={onBlur}
        disabled={disabled}
        className="form-control"
        onChange={(e) => {
          if (onChangeWithEvent) {
            onChangeWithEvent(e);
          }

          if (onChange) {
            onChange(e.target.value);
          }
        }}
      />
      {fieldIcon && (
        <img
          src={fieldIcon}
          alt=""
          className={`${styles.bmdIcon} cursor-pointer`}
          onClick={() => {
            iconClick();
          }}
        />
      )}
      {error && touched && <p className="invalid-feedback d-block">{error}</p>}
    </div>
  );
};

export default Text;
