import { DateTime } from "luxon";
import { PST_ZONE, UTC_ZONE } from "Pages/Order/Order/utility/util";

export function formatUsPhone(phoneNumberString) {
  if (phoneNumberString === "") {
    return "";
  }
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumberString;
}
export function formatUsPhoneNormal(phoneNumberString) {
  if (phoneNumberString === "") {
    return "";
  }
  var pnoeNumber = phoneNumberString.replace(/[^A-Z0-9]/gi, "");
  return pnoeNumber;
}
export function driverInstuctionFormatUTCtoPST(dt) {
  let sp = dt?.trim().split(" ");
  let a = [...sp];
  let time = a[a.length - 2] + " " + a[a.length - 1];

  let psttime = DateTime.fromFormat(time, "hh:mm a", { zone: "UTC" })
    .setZone("America/Los_Angeles")
    .toFormat("hh:mm a");

  sp.pop();
  sp.pop();
  sp.push(psttime);
  return sp.join(" ");
}
// convert normal url to imgix
export function convertimgixurl(
  imgUrl,
  findDomain,
  replaceDomain,
  width = "",
  height = "",
  dpr = "",
  xlib = ""
) {
  if (
    imgUrl == "null" ||
    imgUrl == null ||
    imgUrl == "" ||
    imgUrl == undefined ||
    imgUrl == "undefined"
  )
    return;

  if (!(imgUrl && replaceDomain)) return;

  // check find domain exits or not in imgurl
  if (imgUrl.indexOf(replaceDomain) !== -1) {
    // append query parameter
    let final_url = imgUrl;
    if (width) final_url = appendurl(final_url, "w", width);

    if (height) final_url = appendurl(final_url, "h", height);

    if (dpr) final_url = appendurl(final_url, "dpr", dpr);

    if (xlib) final_url = appendurl(final_url, "xlib", xlib);

    return final_url;
  } else {
    return imgUrl;
  }
}

// append query paramenter in url
export function appendurl(imgUrl, appendKey, appendValue) {
  if (!(imgUrl && appendKey && appendValue)) return;

  var url = new URL(imgUrl);
  var search_params = url.searchParams;

  search_params.set(appendKey, appendValue);

  url.search = search_params.toString();

  // the new url string
  var new_url = url.toString();

  return new_url;
}

// Check for the valid Email ID
export function checkValidEmailId(emilId) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emilId)) {
    return true;
  } else {
    return false;
  }
}

//convert time
export function convertUtcToPstTime(time) {
  let convertTime = DateTime.fromSeconds(time, { zone: UTC_ZONE })
    .setZone(PST_ZONE)
    .toFormat("hh:mm a");
  return convertTime;
}

export function convertUtcTimestampToPstDate(time, format) {
  let convertTime = DateTime.fromSeconds(time, { zone: UTC_ZONE })
    .setZone(PST_ZONE)
    .toFormat(format);
  return convertTime;
}

export function removeEmojiFromString(string) {
  //FUNCTION FOR REMOVE EMOJIS FROM STRING

  var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

  return string.replace(regex, '');

}