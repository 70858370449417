import React, { useState, useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// SCSS
import styles from "./index.module.scss";
// Component
import Text from "../../FormControl/Text/Text";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddChangeRequest = (props) => {
  const [emailId, setEmailId] = useState("");
  useEffect(() => {
    setEmailId(props.email);
  }, [props.email]);

  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          // console.log("hii23");
        }}
        className={`row`}
      >
        <Dialog
          open={props.showModal}
          TransitionComponent={Transition}
          onClose={() => {
            props.handleCancelClick();
            setEmailId("");
          }}
          classes={{
            paper: styles.modalDialog,
          }}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogContent className={`${styles.modalContent} px-0 py-0`}>
            <div className={`text-center ${styles.modalBody}`}>
              <h5 className={`my-3 text-center ${styles.modalTitle}`}>
                {props.pageTitle}
              </h5>

              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <Text
                    type="email"
                    label="Enter Email Id"
                    value={emailId}
                    submitStatus={props.submitStatus}
                    onChange={(data) => {
                      setEmailId(data);
                    }}
                    required={true}
                    id="email"
                  />
                </div>
              </div>

              <button
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  props.handleSubmitClick(emailId);
                }}
                className="btn gray-btn d-inline-block mb-3 mr-2"
              >
                Send Mail
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </form>
    </React.Fragment>
  );
};

export default AddChangeRequest;
