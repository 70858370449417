import React from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// SCSS
import styles from "./Add.module.scss";
// Component
import Text from "./../../../FormControl/Text/Text";
import SwitchBox from "./../../../FormControl/SwitchBox/SwitchBox";
import { formatUsPhone } from "./../../../../Hooks/commanUtility";
import { DEFAULT_COUNTRY_CODE_NAME } from "Helpers/Constants";
import PhoneNumber from "Components/FormControl/PhoneNumber/PhoneNumber";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditCustomer = ({
  showModal,
  pageTitle,
  submitStatus,
  firstName,
  lastName,
  email,
  phone1,
  phone2,
  address,
  city,
  state,
  country,
  zipCode,
  uniqueAboutCustomer,
  isHouseAccount,
  isBusinessAccount,
  onChange,
  handleCancelClick,
  handleSubmitClick,
  countryCode1,
  countryCode2,
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <Text
                  type="text"
                  label="First Name"
                  value={firstName}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("firstName", data)}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <Text
                  type="text"
                  label="Last Name"
                  value={lastName}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("lastName", data)}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <Text
                  type="email"
                  label="Email"
                  value={email}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("email", data)}
                />
              </div>
              <div className="col-md-3 col-xs-12">
                <div class="form-group bmd-form-group is-filled text-left">
                  <label class="bmd-label-floating">House Account</label>
                  <SwitchBox
                    value={isHouseAccount ? isHouseAccount : 0}
                    onChange={(data) => onChange("isHouseAccount", data)}
                  />
                </div>
              </div>
              <div className="col-md-3 col-xs-12">
                <div class="form-group bmd-form-group is-filled text-left">
                  <label class="bmd-label-floating">Business Account</label>
                  <SwitchBox
                    value={isBusinessAccount ? isBusinessAccount : 0}
                    onChange={(data) => onChange("isBusinessAccount", data)}
                  />
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                {/* <Text
                  type="tel"
                  label="Phone 1"
                  value={formatUsPhone(phone1)}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => {
                    if (data.length <= 10) {
                      onChange("phone1", data);
                    }
                  }}
                /> */}
                
                <PhoneNumber
                  countryCode={DEFAULT_COUNTRY_CODE_NAME}
                  specialLabel={"Phone 1"}
                  initValue={countryCode1 + phone1}
                  fieldName={`Phone 1`}
                  onChange={(phone, countryData, event, formatedValue) => {
                    let countryCode = "+" + countryData.dialCode;
                    let finalMobileNum = formatedValue
                      ? formatedValue?.split(countryCode)[1]?.trim()
                      : "";
                    onChange("countryCode1", countryCode);
                    onChange("phone1", finalMobileNum);
                  }}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                {/* <Text
                  type="tel"
                  label="Phone 2"
                  value={formatUsPhone(phone2)}
                  submitStatus={submitStatus}
                  onChange={(data) => {
                    if (data.length <= 10) {
                      onChange("phone2", data);
                    }
                  }}
                /> */}
                {/* <PhoneInput
                  buttonStyle={{}}
                  specialLabel={"Phone 2"}
                  // dropdownStyle={{ height: "50px" }}
                  country={DEFAULT_COUNTRY_CODE_NAME}
                  value={countryCode2 + phone2}
                  onChange={(phone, countryData, event, formatedValue) => {
                    // debugger
                    let countryCode = "+" + countryData.dialCode;
                    let finalMobileNum = formatedValue
                      ? formatedValue?.split(countryCode)[1]?.trim()
                      : "";
                    onChange("countryCode2", countryCode);
                    onChange("phone2", finalMobileNum);
                  }}
                /> */}
                <PhoneNumber
                  countryCode={DEFAULT_COUNTRY_CODE_NAME}
                  specialLabel={"Phone 2"}
                  initValue={countryCode2 + phone2}
                  fieldName={`Phone 1`}
                  onChange={(phone, countryData, event, formatedValue) => {
                    let countryCode = "+" + countryData.dialCode;
                    let finalMobileNum = formatedValue
                      ? formatedValue?.split(countryCode)[1]?.trim()
                      : "";
                    onChange("countryCode2", countryCode);
                    onChange("phone2", finalMobileNum);
                  }}
                />
              </div>
              <div className="col-md-12 col-xs-12">
                <Text
                  type="text"
                  label="What's unique about this customer?"
                  value={uniqueAboutCustomer}
                  required={false}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("uniqueAboutCustomer", data)}
                />
              </div>
              {/* <div className="col-md-12 col-xs-12">
                                <Text
                                    type="text"
                                    label="Apt/Unit/Office#"
                                    value={address}
                                    required={true}
                                    submitStatus={submitStatus}
                                    onChange={(data) => onChange('address', data)}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <Text
                                    type="text"
                                    label="Country"
                                    value={country}
                                    required={true}
                                    submitStatus={submitStatus}
                                    onChange={(data) => onChange('country', data)}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <Text
                                    type="text"
                                    label="State"
                                    value={state}
                                    required={true}
                                    submitStatus={submitStatus}
                                    onChange={(data) => onChange('state', data)}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <Text
                                    type="text"
                                    label="City"
                                    value={city}
                                    required={true}
                                    submitStatus={submitStatus}
                                    onChange={(data) => onChange('city', data)}
                                />
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <Text
                                    type="text"
                                    label="Zipcode"
                                    value={zipCode}
                                    required={true}
                                    submitStatus={submitStatus}
                                    onChange={(data) => onChange('zipCode', data)}
                                />
                            </div> */}
            </div>
            <sapn
              data-dismiss="modal"
              onClick={handleSubmitClick}
              className="btn gray-btn d-inline-block mb-3 mr-2"
            >
              {" "}
              Ok{" "}
            </sapn>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default EditCustomer;
