export function postReqLocation(locationState) {

      let seoData = {
            id: null,
            module: "location-categories",
            is_robot: false,
            is_no_follow: false,
            is_no_index: false,
            image_alt_text: "",
            page_title: "",
            meta_title: "",
            meta_description: "",
            canonical_url: "",
            og_title: "",
            og_description: "",
            og_image_url: "",
            og_url: "",
            og_image_alt: " ",
            og_image_type: "",
            og_image_width: 0,
            og_image_height: 0,
            og_site_name: "",
            twitter_title: "",
            twitter_description: "",
            twitter_image_url: "",
            twitter_site: "",
            twitter_creator: ""
      }


      if (locationState.cms_location_categories !== undefined && locationState.cms_location_categories.length > 0) {
            locationState.cms_location_categories?.forEach((d, i) => {
                  if (locationState.cms_location_categories[i]['seo'].length === 0 && locationState.cms_location_categories[i]['seo'] === null && locationState.cms_location_categories[i]['seo'] === undefined) {
                        locationState.cms_location_categories[i]['seo'] = seoData
                  }
            });
      }

      if (locationState.banners !== undefined && locationState.banners.length > 0) {
            locationState.banners?.forEach((d, i) => {
                  if (locationState.banners[i]['id'] === null || locationState.banners[i]['id'] === undefined || locationState.banners[i]['id'] === 0) {
                        delete locationState.banners[i]['id'];
                        delete locationState.banners[i]['parent_id'];
                  }
            });
      }
      let location_banner_data = (locationState.banners !== undefined && locationState.banners.length > 0) ? locationState.banners : [];

      delete locationState.seo['id']
      let seoDataNew = locationState.seo;

      let post_Req = {
            name: locationState.primary_info.name,
            type: locationState.primary_info.type,
            parent_id: locationState.primary_info.parent_id,
            main_parent_id: locationState.primary_info.main_parent_id,
            service_offered: locationState.primary_info.service_offered,
            need_opening_hour: locationState.primary_info.need_opening_hour,
            deliver_throughout_description: locationState.primary_info.deliver_throughout_description,
            your_local_florist_description: locationState.primary_info.your_local_florist_description,
            sequence_number: locationState.primary_info.sequence_number,
            slug: locationState.primary_info.slug,
            blurb: locationState.primary_info.blurb,
            description: locationState.primary_info.description,
            cms_location_categories: locationState.cms_location_categories,
            banners: location_banner_data,
            seo: seoDataNew

      };

      return { ...post_Req };
}