import { InputBase, NativeSelect } from "@material-ui/core";
import React, { useState } from "react";
import classes from "./styles.module.scss";
const SortAndSearch = (props) => {
  const [value, setValue] = useState("");

  return (
    <div
      className={`${classes.SortAndSearch} my-4 d-flex align-items-center justify-content-end`}
    >
      <InputBase
        value={value}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (props.search) {
              props.search(value);
            }
          }
        }}
        onChange={(e) => {
          if (e.target.value === "") {
            if (props.search) {
              props.search("");
            }
          }
          setValue(e.target.value);
        }}
        className="border-bottom mr-5"
        style={{ borderColor: "#c6c6c6" }}
        type="text"
        placeholder="Search"
      />
      <div className="d-flex align-items-center">
        <p
          className="mr-3 my-0"
          style={{ fontWeight: "700", fontSize: "16px" }}
        >
          Sort By
        </p>
        <NativeSelect
          onChange={(e) => {
            let sort = {
              id: "price",
            };
            if (e.target.value === "desc") {
              sort = {
                id: "price",
                desc: "desc",
              };
            }
            if (props.applySort) {
              props.applySort(sort);
            }
          }}
          placeholder="Price"
          name="age"
          inputProps={{ "aria-label": "Price" }}
        >
          <option value={"asc"}>Low to High</option>
          <option value={"desc"}>High to Low</option>
        </NativeSelect>
      </div>
    </div>
  );
};

export default SortAndSearch;
