import { callPostApi, callPutApi, callGetApi } from "./api";

export async function todaysDetails() {
      // return this.interceptors.GET('clocks/details');
      try {
            const response = await callGetApi({ url: "clocks/details" });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getClockDetailsById(id) {
      // return this.interceptors.GET('clocks/' + id + '/change-request');
      try {
            const response = await callGetApi({ url: 'clocks/' + id + '/change-request' });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function usersAdminHistory(data) {
      // return this.interceptors.POST('clocks/admin', data);
      try {
            const response = await callPostApi({ url: "clocks/admin", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function exportTimeHistory(data) {
      // return this.interceptors.POST('clocks/admin/export', data);
      try {
            const response = await callPostApi({ url: "clocks/admin/export", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getUserHistory() {
      // return this.interceptors.GET('clocks');
      try {
            const response = await callGetApi({ url: 'clocks' });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function details(id) {
      // return this.interceptors.GET('clocks/' + id);
      try {
            const response = await callGetApi({ url: 'clocks/' + id });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function edit(data) {
      // return this.interceptors.PUT('clocks', data);
      try {
            const response = await callPutApi({ url: "clocks", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function clockInOut(data) {
      // return this.interceptors.POST('clocks', data);
      try {
            const response = await callPostApi({ url: "clocks", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function continueBreak() {
      // return this.interceptors.GET('clocks/continue');
      try {
            const response = await callGetApi({ url: 'clocks/continue' });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function applyChangeRequest(data) {
      // return this.interceptors.POST('change-request', data);
      try {
            const response = await callPostApi({ url: "change-request", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateChangeRequest(data) {
      // return this.interceptors.PUT('change-request', data);
      try {
            const response = await callPutApi({ url: "change-request", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function clockedInCount(data) {
      // return this.interceptors.POST('clocks/count', data);
      try {
            const response = await callPostApi({ url: "clocks/count", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateCheckInOutEntry(data){
      try{
            const response = await callPostApi({url: "clocks/update_clock_data",body: data});
            return response;
      }catch(error){
            throw error;
      }
}

export async function timeClockAuditTrail(data){
      try{
            const response = await callPostApi({url: "clocks/audit_trail_timeclock_list",body: data});
            return response;
      }catch(error){
            throw error;
      }
}

export async function timeClockAuditTrailDetails(id){
      try{
            const response = await callGetApi({url: "clocks//audit_trail_timeclock_details/" + id});
            return response;
      }catch(error){
            throw error;
      }
}