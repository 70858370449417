import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";

// Scss
import styles from "./index.module.scss";

// Context
import { AppContext } from "./../../../Context/AppContext";
import { getAccessByModuleName } from "./../../../Routes/Routes";
import { MODULES } from "./../../../Helpers/Constants";

// Components
import Header from "./../../../Components/Header/Header";
import Text from "./../../../Components/FormControl/Text/Text";
import Exception from "./../../../Components/Modal/Exception/Exception";
import PayrollDate from "./../../../Components/Modal/PayrollDate/PayrollDate";
import DeleteConfirmation from "./../../../Components/Modal/DeleteConfirmation/DeleteConfirmation";

// Services
import SettingsApi from "./../../../Services/Settings/Settings";
import {
  get,
  update,
  getExceptions,
  addExceptions,
  updateExceptions,
  deleteExceptions,
  getPayrollDates,
  addPayrollDates,
  updatePayrollDates,
  handleDeletePayrollDateData,
} from "./../../../api/settings";

// Images
import AddIcon from "./../../../Assets/images/add.svg";
import EditIcon from "./../../../Assets/images/edit.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";
import ClockIcon from "../../../Assets/images/clock.svg";

const Settings = (props) => {
  const [hourPerDayOverTime1, setHourPerDayOverTime1] = useState("");
  const [hourPerDayOverTime2, setHourPerDayOverTime2] = useState("");
  const [hourPerWeekOverTime1, setHourPerWeekOverTime1] = useState("");
  const [lunchBeforeTime, setLunchBeforeTime] = useState("");
  const [lunchTime, setLunchTime] = useState("");

  const [exceptionsList, setExceptionsList] = useState([]);
  const [showExceptionModal, setShowExceptionModal] = useState(false);
  const [exceptionTitle, setExceptionTitle] = useState("");
  const [exceptionId, setExceptionId] = useState("");

  const [payrollDateList, setPayrollDateList] = useState([]);
  const [showPayrollDateModal, setPayrollDateModal] = useState(false);
  const [payrollStartDate, setPayrollStartDate] = useState(null);
  const [payrollEndDate, setPayrollEndDate] = useState(null);
  const [payrollDateId, setPayrollDateId] = useState("");

  const [deleteExceptionId, setDeleteExceptionId] = useState();
  const [showDeleteExceptionModal, setShowDeleteExceptionModal] = useState(
    false
  );
  const [deletePayrollDateId, setDeletePayrollDateId] = useState();
  const [showDeletePayrollDateModal, setShowDeletePayrollDateModal] = useState(
    false
  );
  const [access, setAccess] = useState({});

  const settingsService = new SettingsApi();

  const { showToastMessage, modules, setIsLoading } = useContext(AppContext);

  useEffect(() => {
    let timeClockAccess = getAccessByModuleName(modules, MODULES.TIME_CLOCK);
    if (timeClockAccess && timeClockAccess.permissions.write_access) {
      handleGetSettings();
      handleGetExceptions();
      handleGetPayrollDates();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  const handleGetExceptions = async () => {
    // const response = await settingsService.getExceptions();
    const response = await getExceptions();

    if (response && response.status === 200) {
      setExceptionsList(response.data.data);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const handleGetPayrollDates = async () => {
    // const response = await settingsService.getPayrollDates();
    const response = await getPayrollDates();

    if (response && response.status === 200) {
      setPayrollDateList(response.data.data);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const handleGetSettings = async () => {
    // const response = await settingsService.get();
    const response = await get();

    if (response && response.status === 200) {
      let hourPerDayOverTime1Index = response.data.data.findIndex(
        (settings) => settings.key === "hourPerDayOverTime1"
      );
      if (hourPerDayOverTime1Index > -1)
        setHourPerDayOverTime1(
          response.data.data[hourPerDayOverTime1Index].value
        );

      let hourPerDayOverTime2Index = response.data.data.findIndex(
        (settings) => settings.key === "hourPerDayOverTime2"
      );
      if (hourPerDayOverTime2Index > -1)
        setHourPerDayOverTime2(
          response.data.data[hourPerDayOverTime2Index].value
        );

      let setHourPerWeekOverTime1Index = response.data.data.findIndex(
        (settings) => settings.key === "hourPerWeekOverTime1"
      );
      if (setHourPerWeekOverTime1Index > -1)
        setHourPerWeekOverTime1(
          response.data.data[setHourPerWeekOverTime1Index].value
        );

      let lunchBeforeTimeIndex = response.data.data.findIndex(
        (settings) => settings.key === "lunchBeforeTime"
      );
      if (lunchBeforeTimeIndex > -1)
        setLunchBeforeTime(response.data.data[lunchBeforeTimeIndex].value);

      let lunchTimeIndex = response.data.data.findIndex(
        (settings) => settings.key === "lunchTime"
      );
      if (lunchTimeIndex > -1)
        setLunchTime(response.data.data[lunchTimeIndex].value);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.TIME_CLOCK);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.write_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  const handleExceptionSubmit = async (event) => {
    if (event) event.preventDefault();

    let reqBody = {
      title: exceptionTitle,
    };

    let response;
    setIsLoading(true);
    if (exceptionId) {
      reqBody.id = exceptionId;
    //   response = await settingsService.updateExceptions(reqBody);
      response = await updateExceptions(reqBody);
    } else response = await addExceptions(reqBody);

    if (response && response.status === 200) {
      handleGetExceptions();
      setExceptionId("");
      setExceptionTitle("");
      setShowExceptionModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const handlePayrollSubmit = async (event) => {
    if (event) event.preventDefault();

    let reqBody = {
      from_date: moment(payrollStartDate).format("DD-MM-YYYY"),
      to_date: moment(payrollEndDate).format("DD-MM-YYYY"),
    };
    setIsLoading(true);

    let response;
    if (payrollDateId) {
      reqBody.id = payrollDateId;
    //   response = await settingsService.updatePayrollDates(reqBody);
      response = await updatePayrollDates(reqBody);
    } else response = await addPayrollDates(reqBody);

    if (response && response.status === 200) {
      handleGetPayrollDates();
      setPayrollStartDate(null);
      setPayrollEndDate(null);
      setPayrollDateId("");
      setPayrollDateModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const handleDeletePayrollDateClick = async (event, id) => {
    event.preventDefault();

    setDeletePayrollDateId(id);
    setShowDeletePayrollDateModal(true);
  };

  const handleDeletePayrollDate = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    // const response = await settingsService.handleDeletePayrollDate(
    //   deletePayrollDateId
    // );
    const response = await handleDeletePayrollDateData(
      deletePayrollDateId
    );

    if (response && response.status === 200) {
      setShowDeletePayrollDateModal(false);
      setDeletePayrollDateId(null);

      showToastMessage(undefined, response.data.message, "success", false);
      handleGetPayrollDates();
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const handleDeleteExceptionClick = async (event, id) => {
    event.preventDefault();

    setDeleteExceptionId(id);
    setShowDeleteExceptionModal(true);
  };

  const handleDeleteException = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    // const response = await settingsService.deleteExceptions(deleteExceptionId);
    const response = await deleteExceptions(deleteExceptionId);

    if (response && response.status === 200) {
      setShowDeleteExceptionModal(false);
      setDeleteExceptionId(null);

      showToastMessage(undefined, response.data.message, "success", false);
      handleGetExceptions();
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const handleUpdateSettings = async (key, value) => {
    if (key === "hourPerDayOverTime1") setHourPerDayOverTime1(value);
    if (key === "hourPerDayOverTime2") setHourPerDayOverTime2(value);
    if (key === "hourPerWeekOverTime1") setHourPerWeekOverTime1(value);
    if (key === "lunchBeforeTime") setLunchBeforeTime(value);
    if (key === "lunchTime") setLunchTime(value);

    if (value && value >= 0) {
      let reqBody = { key, value };
    //   const response = await settingsService.update(reqBody);
      const response = await update(reqBody);

      if (response.status !== 200)
        showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  return (
    <React.Fragment>
      <Header title="Time Clock Configuration" />

      <div id="MainContent" className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <Text
                label="Hours per day until overtime 1.5"
                value={hourPerDayOverTime1}
                type="text"
                fieldIcon={ClockIcon}
                onChange={(time) =>
                  handleUpdateSettings("hourPerDayOverTime1", time)
                }
              />
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <Text
                label="Hours per day until overtime 2"
                value={hourPerDayOverTime2}
                type="text"
                fieldIcon={ClockIcon}
                onChange={(time) =>
                  handleUpdateSettings("hourPerDayOverTime2", time)
                }
              />
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <Text
                label="Hours per week until overtime 1"
                value={hourPerWeekOverTime1}
                type="text"
                fieldIcon={ClockIcon}
                onChange={(time) =>
                  handleUpdateSettings("hourPerWeekOverTime1", time)
                }
              />
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <Text
                label="Time before Lunch Break Notification"
                value={lunchBeforeTime}
                type="text"
                fieldIcon={ClockIcon}
                onChange={(time) =>
                  handleUpdateSettings("lunchBeforeTime", time)
                }
              />
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <Text
                label="Lunch break"
                value={lunchTime}
                type="text"
                fieldIcon={ClockIcon}
                onChange={(time) => handleUpdateSettings("lunchTime", time)}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-sm-6 col-xs-12">
              <div className={styles.title}>Exceptions</div>
              {access.write_access && (
                <a
                  href="/"
                  className={`link cursor-pointer ml-2 mt-1 ${styles.searchIconWrapper}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowExceptionModal(true);
                  }}
                >
                  <img className={styles.searchIcon} src={AddIcon} alt="Add" />
                </a>
              )}
              <div className="table-responsive my-5">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Task Name</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exceptionsList &&
                      exceptionsList.length > 0 &&
                      exceptionsList.map((exceptionsObj, index) => {
                        return (
                          <tr key={index}>
                            <td>{exceptionsObj.title}</td>
                            <td className="cursor-pointer text-right">
                              {access.write_access && (
                                <img
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowExceptionModal(true);
                                    setExceptionId(exceptionsObj.id);
                                    setExceptionTitle(exceptionsObj.title);
                                  }}
                                  className={styles.searchIcon}
                                  src={EditIcon}
                                  alt="Edit"
                                />
                              )}
                              {access.delete_access && (
                                <img
                                  onClick={(e) =>
                                    handleDeleteExceptionClick(
                                      e,
                                      exceptionsObj.id
                                    )
                                  }
                                  className={`ml-3 ${styles.searchIcon}`}
                                  src={DeleteIcon}
                                  alt="Delete"
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    {exceptionsList && exceptionsList.length === 0 && (
                      <tr>
                        <td colSpan="2" className="text-center">
                          <span className="no-data-message">
                            No data available.
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-sm-6 col-xs-12">
              <div className={styles.title}>Monthly Payroll Date</div>

              {access.write_access && (
                <a
                  href="/"
                  className={`link cursor-pointer ml-2 mt-1 ${styles.searchIconWrapper}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setPayrollDateModal(true);
                  }}
                >
                  <img className={styles.searchIcon} src={AddIcon} alt="Add" />
                </a>
              )}
              <div className="table-responsive my-5">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Payroll Date</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payrollDateList &&
                      payrollDateList.length > 0 &&
                      payrollDateList.map((payrollDateObj, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {moment
                                .unix(payrollDateObj.from_date)
                                .utc()
                                .format("ddd MMM-DD-YYYY")}{" "}
                              -{" "}
                              {moment
                                .unix(payrollDateObj.to_date)
                                .utc()
                                .format("ddd MMM-DD-YYYY")}
                            </td>
                            <td className="cursor-pointer text-right">
                              {access.write_access && (
                                <img
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setPayrollDateModal(true);
                                    setPayrollDateId(payrollDateObj.id);
                                    setPayrollStartDate(
                                      moment
                                        .unix(payrollDateObj.from_date)
                                        .utc()
                                        .format("ddd MMM-DD-YYYY")
                                    );
                                    setPayrollEndDate(
                                      moment
                                        .unix(payrollDateObj.to_date)
                                        .utc()
                                        .format("ddd MMM-DD-YYYY")
                                    );
                                  }}
                                  className={styles.searchIcon}
                                  src={EditIcon}
                                  alt="Edit"
                                />
                              )}
                              {access.delete_access && (
                                <img
                                  onClick={(e) =>
                                    handleDeletePayrollDateClick(
                                      e,
                                      payrollDateObj.id
                                    )
                                  }
                                  className={`ml-3 ${styles.searchIcon}`}
                                  src={DeleteIcon}
                                  alt="Delete"
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    {payrollDateList && payrollDateList.length === 0 && (
                      <tr>
                        <td colSpan="2" className="text-center">
                          <span className="no-data-message">
                            No data available.
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Exception
        showModal={showExceptionModal}
        exceptionId={exceptionId}
        value={exceptionTitle}
        onChange={(data) => setExceptionTitle(data)}
        handleSubmitClick={handleExceptionSubmit}
        handleCancelClick={(e) => {
          setExceptionTitle("");
          setExceptionId("");
          setShowExceptionModal(false);
        }}
      />

      <PayrollDate
        showModal={showPayrollDateModal}
        payrollDateId={payrollDateId}
        payrollStartDate={payrollStartDate}
        payrollEndDate={payrollEndDate}
        onChange={(type, data) => {
          if (type === "payrollStartDate") setPayrollStartDate(data);
          if (type === "payrollEndDate") setPayrollEndDate(data);
        }}
        handleSubmitClick={handlePayrollSubmit}
        handleCancelClick={(e) => {
          setPayrollStartDate(null);
          setPayrollEndDate(null);
          setPayrollDateId("");
          setPayrollDateModal(false);
        }}
      />

      <DeleteConfirmation
        showModal={showDeleteExceptionModal}
        title="Delete Exception"
        message="Are you sure want to delete this exception?"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteExceptionModal(false);
          setDeleteExceptionId(null);
        }}
        handleSubmitClick={handleDeleteException}
      />

      <DeleteConfirmation
        showModal={showDeletePayrollDateModal}
        title="Delete Payroll Date"
        message="Are you sure want to delete this payroll date?"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeletePayrollDateModal(false);
          setDeletePayrollDateId(null);
        }}
        handleSubmitClick={handleDeletePayrollDate}
      />
    </React.Fragment>
  );
};

export default withRouter(Settings);
