import { isBeforetwelveandsix } from "Helpers/date";
import { DateTime } from "luxon";
import React, { useContext } from "react";
import Select from "../../../Components/FormControl/Select/Select";
import ReactTable from "../../../Components/Table/Table";
import { AppContext } from "../../../Context/AppContext";
import { MODULES } from "../../../Helpers/Constants";
import { getAccessByModuleName } from "../../../Routes/Routes";
import { Link } from "react-router-dom";
//scss
import styles from "./index.module.scss";
import CheckBox from "Components/FormControl/CheckBox/CheckBox";

const DesignerStatusTomorrow = (props) => {
  const { modules } = useContext(AppContext);

  let editOrderAccess = getAccessByModuleName(modules, MODULES.EDIT_ORDER);
  let threeMark = (
    <span className={` ${styles.ProducatBadge} ${styles.ProducatBadgeRed}`}>
      !!!
    </span>
  );

  let oneMark = (
    <span className={` ${styles.ProducatBadge} ${styles.ProducatBadgeGreen}`}>
      !
    </span>
  );

  let actionButtonAccess = getAccessByModuleName(
    modules,
    MODULES.DESIGNER_STATUS_PAGE_ACTION
  );

  let viewOrderAccess = getAccessByModuleName(modules, MODULES.VIEW_ORDER);
  const columnsTomorrow = React.useMemo(
    () => [
      {
        Header: "Products",
        accessor: "image",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          let time = isBeforetwelveandsix(row.original.delivery_date_time);
          return (
            <>
              <div className={` ${styles.ProducatImageWapper}`}>
                <img
                  src={
                    row.original.variation_image === "" ||
                    row.original.variation_image === "null" ||
                    row.original.variation_image === null
                      ? ""
                      : `${row.original.variation_image}`
                  }
                  alt=""
                />
                {row.original.is_stock_order && (
                  <span
                    className={` ${styles.ProducatBadge} ${styles.ProducatBadgeGrey}`}
                  >
                    S
                  </span>
                )}
                {!row.original.is_stock_order && time.beforTwelve && threeMark}
                {!row.original.is_stock_order &&
                  !time.beforTwelve &&
                  time.beforSix &&
                  oneMark}
              </div>
              <p>
                {row.original.variation_name ? row.original.variation_name : ""}
              </p>
            </>
          );
        },
      },
      {
        Header: "Order ID",
        accessor: "order_number",
        isVisible: true,
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {viewOrderAccess &&
              viewOrderAccess.permissions.read_access &&
              !row.original.is_stock_order ? (
                <Link to={`/admin/orders/view/${row.original.id}`}>
                  <span className={`cursor-pointer`}>
                    {`#${row.original.order_number}`}
                  </span>
                </Link>
              ) : (
                // <span style={{ width: 34, height: 34, display: "block" }} />
                `#${row.original.order_number}`
              )}
            </div>
          );
        },
      },
      {
        Header: "Order Title",
        accessor: "product_name",
        isVisible: true,
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          return `${
            row.original.product_name ? row.original.product_name : ""
          }`;
        },
      },
      {
        Header: "Assign To",
        accessor: "designer_name",
        isVisible: true,
        disableFilters: true,
        // disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <Select
              value={row.original.designer_id}
              onChange={(data) => {
                props.handleSelectedAssginTo(
                  data,
                  row.original.id,
                  row.original.is_stock_order,
                  "tomorrow",
                  row.index
                );
              }}
              placeholder={"Unassigned"}
              options={props.designersList}
              disabled={
                editOrderAccess && editOrderAccess.permissions.write_access
                  ? false
                  : true
              }
              parentClass="m-0 p-0"
              usedFor="Filter"
              menuPortal={document.body}
            />
          );
        },
      },
      {
        Header: "Delivered Time",
        accessor: "delivery_date_time",
        isVisible: true,
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          return `${
            row.original.delivery_date_time &&
            row.original.delivery_date_time !== null
              ? row.original.delivery_time_type
                ? row.original.delivery_time_type +
                  " " +
                  DateTime.fromISO(row.original.delivery_date_time)
                    .setZone("America/Los_Angeles")
                    .toFormat("hh:mm a")
                : DateTime.fromISO(row.original.delivery_date_time)
                    .setZone("America/Los_Angeles")
                    .toFormat("hh:mm a")
              : ""
          }`;
        },
      },
      {
        Header: "Price",
        accessor: "total",
        isVisible: true,
        disableFilters: true,
        disableSortBy: false,
        Cell: ({ value, row, column }) => {
          return `$${row.original.total.toFixed(2)}`;
        },
      },
      {
        Header: "Action",
        accessor: "df",
        isVisible: true,
        disableFilters: false,
        disableSortBy: false,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {!row.original?.is_stock_order &&
                actionButtonAccess &&
                actionButtonAccess.permissions.read_access && (
                  <button
                    onClick={(e) =>
                      props.greetingCardRePrintData(
                        e,
                        row.original.id,
                        row.original.recipent_id
                      )
                    }
                    className={`btn pink-btn ${styles.actionButton}`}
                  >
                    Print Card Message
                  </button>
                )}

              {actionButtonAccess &&
                actionButtonAccess.permissions.read_access && (
                  <button
                    onClick={(e) =>
                      props.orderMarkAsComplete(
                        e,
                        row.original.id,
                        row.original.designer_id,
                        row.original.is_stock_order,
                        "tomorrow",
                        row.index
                      )
                    }
                    className={`btn pink-btn ml-2 ${styles.actionButton}`}
                  >
                    Mark Completed
                  </button>
                )}
            </div>
          );
        },
      },
    ],
    [props.dataTomorrow]
  );

  return (
    <ReactTable
      columns={columnsTomorrow}
      data={props.dataTomorrow}
      // fetchData={props.fetchDataTomorrow}
      fetchData={(filterData) => {
        props.fetchDataTomorrow(filterData);
      }}
      loading={props.loadingTomorrow}
      pageCount={props.pageCountTomorrow}
      noDataText={"There is no data to display"}
      defaultFilter={props.defaultFilterTomorrow}
      displayGlobalSearch={false}
      displayColumnVisibility={false}
      displayLastColumnSorting={false}
      showCheckBox={props.printAllAccess}
      selectedCheckBoxValue={props.selectedCheckBoxValue}
    />
  );
};

export default DesignerStatusTomorrow;
