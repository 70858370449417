import React from "react";
import Select from "../../../../Components/FormControl/Select/Select";
import Text from "../../../../Components/FormControl/Text/Text";
import AddIcon from "../../../../Assets/images/add.svg";
import styles from "../index.module.scss";
import DeleteIcon from "./../../../../Assets/images/delete.svg";

const AddSendRow = (props) => {
  if (props?.requestInventory?.length && props?.requestInventory?.length > 0) {
    return props?.requestInventory?.map((val, idx) => {
      return (
        <tr key={val.index}>
          <td className="text-center col-md-4 col-xs-12 p-0 pr-3" style={{ width: "40%" }}>
            <div className="">
              {props.isLoading ? (
                <Select
                  label="Loading..."
                  value={""}
                  onChange={""}
                  options={[]}
                />
              ) : (
                <Select
                  placeholder={"Select Raw Material"}
                  parentClass={"text-left"}
                  options={props.rawMaterials}
                  value={val?.inventory_raw_material_id}
                  onChange={(data) =>
                    props.onChange("requestInventory", data, idx)
                  }
                  key={val?.inventory_raw_material}
                  disableUnderline={false}
                />
              )}
            </div>
          </td>
          <td className="text-center p-0">
            <Text
              value={val?.raw_matrial_unit}
              required={true}
              id={"unit"}
              disabled={true}
            />
          </td>
          <td className="text-center p-0 pl-3">
            <Text
              value={val?.inventory_available_quantity}
              required={true}
              id={"avalableQty"}
              disabled={true}
            />
          </td>
          <td className="text-center p-0 pl-3">
            <Text
              value={val.raw_material_price}
              required={true}
              id={"price"}
              disabled={true}
            />
          </td>
          <td className="text-center p-0 pl-3">
            <Text
              value={val.filled_quantity}
              required={true}
              onChange={(qty) => {
                ///^[0-9]*(\.[0-9]{0,2})?$/
                let regex = /^\d*\.?\d*$/;
                if (regex.test(qty)) {
                  props.setValues("filled_quantity", qty, idx);
                } else {
                  props.setValues("filled_quantity", "", idx);
                }
              }}
              id={"fillQty"}
            />
          </td>
          <td className="d-flex justify-content-start">
            {idx === 0 ? (
              <>
                {props?.requestInventory?.length && props?.requestInventory?.length > 1 && (
                  <div onClick={() => props.delete(val)}>
                    <span>
                      <img
                        className={`cursor-pointer ${styles.icon}`}
                        src={DeleteIcon}
                        alt=""
                      />
                    </span>
                  </div>
                )}
                <div onClick={props.add}>
                  <span className={`link cursor-pointer ${styles.iconWrapper} ml-3`}>
                    <img className={styles.icon} src={AddIcon} alt="" />
                  </span>
                </div>
              </>
            ) : (
              <div onClick={() => props.delete(val)}>
                <span>
                  <img
                    className={`cursor-pointer ${styles.icon}`}
                    src={DeleteIcon}
                    alt=""
                  />
                </span>
              </div>
            )}
          </td>
        </tr>
      );
    });
  }
};

export default AddSendRow;
