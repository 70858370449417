import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import Header from "../../Components/Header/Header";
import PrimaryInformation from "./PrimaryInformation";
import MatrixToCopy from "./MatrixToCopy";
import TableContent from "./TableContent";

import { withRouter } from "react-router";

import { AppContext } from "../../Context/AppContext";

//services
// import SettingsApi from "./../../Services/Settings/Settings";
// import AddressTypeApi from "./../../Services/AddressType/AddressType";
// import DeliveryMatrixApi from "./../../Services/DeliveryMatrix/DeliveryMatrix";
import { getAddressTypeList } from "./../../api/addressType";
import { getFolderNormalList } from "./../../api/settings";
import {
  addDeliveryMatrix,
  getDeliveryMatrixById,
  updateDeliveryMatrix,
  getDeliveryMatrixNameList,
} from "./../../api/deliveryMatrix";

import { useDeliveryMatrix } from "./../../Context/DeliveryMatrixCurd/useDeliveryMatrix";
//hooks
import { postReqDeliveryMatrix } from "./../../Hooks/deliveryMatrixApiCall";

const AddFormDeliveryMatrix = (props) => {
  const { showToastMessage, setIsLoading } = useContext(AppContext);

  // const usersSetting = new SettingsApi();
  // const addressTypeService = new AddressTypeApi();
  // const deliveryMatrixService = new DeliveryMatrixApi();

  const {
    DeliveryMatrixState,
    clearDeliveryState,
    setDeliveryMatrixName,
    setDeliveryMatrixStartDate,
    setDeliveryMatrixEndDate,
    addDeliveryTimeData,
    setDeliveryMatrixZipcode,
    setDeliveryMatrixAddressType,
    setDeliveryMatrixDays,
    deleteAllDeliveryItemData,
  } = useDeliveryMatrix();

  const [folderList, setFolderList] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = React.useState([]);
  const [deliveryMatrixNameList, setDeliveryMatrixNameList] = React.useState(
    []
  );
  const [deliveryMatrixIdUpdate, setDeliveryMatrixIdUpdate] = React.useState(
    ""
  );
  const [loaded, setLoaded] = useState(false);
  const [pageTitle, setPageTitle] = useState("Add Delivery Matrix");

  const daysList = [
    { id: "Monday", title: "Monday" },
    { id: "Tuesday", title: "Tuesday" },
    { id: "Wednesday", title: "Wednesday" },
    { id: "Thursday", title: "Thursday" },
    { id: "Friday", title: "Friday" },
    { id: "Saturday", title: "Saturday" },
    { id: "Sunday", title: "Sunday" },
  ];

  useEffect(() => {
    (async () => {
      clearDeliveryState();
      await handelGetAllFolder();
      await getAddressType();
      await getDeliveryMatrixName();
      setLoaded(true);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { deliveryMatrixId } = props.match.params;
      if (deliveryMatrixId && deliveryMatrixId !== undefined) {
        if (loaded) {
          setPageTitle("Edit Delivery Matrix");
          await setDeliveryMatrixIdUpdate(deliveryMatrixId);
          await handleGetDeliveryMatrixDetailById(deliveryMatrixId);
        }
      }
    })();
  }, [loaded]);

  const handelGetAllFolder = async () => {
    setIsLoading(true);
    // const response = await usersSetting.getFolderNormalList();
    const response = await getFolderNormalList();

    if (response && response.status === 200) {
      let folderListArr = [];
      response.data.data?.forEach((d, i) => {
        let tempArr = {
          id: d.id,
          title: d.group_name,
        };
        folderListArr.push(tempArr);
      });
      setFolderList(folderListArr);
    } else showToastMessage(undefined, response.data.message, "error", false);
    setIsLoading(false);
  };

  const getAddressType = async () => {
    setIsLoading(true);
    // let response = await addressTypeService.getAddressTypeList();
    let response = await getAddressTypeList();
    if (response && response.status === 200) {
      let addressTypeArr = [];
      response.data.data.rows?.forEach((d, i) => {
        let tempArr = {
          id: d.id,
          title: d.name,
        };
        addressTypeArr.push(tempArr);
      });
      setDeliveryAddress(addressTypeArr);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const getDeliveryMatrixName = async () => {
    setIsLoading(true);
    // let response = await deliveryMatrixService.getDeliveryMatrixName();
    let response = await getDeliveryMatrixNameList();
    if (response && response.status === 200) {
      let deliveryMatrixArr = [];
      response.data.data?.forEach((d, i) => {
        let tempArr = {
          id: d.id,
          title: d.name,
        };
        deliveryMatrixArr.push(tempArr);
      });
      setDeliveryMatrixNameList(deliveryMatrixArr);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handelChangeDeliveryMatrix = async (data) => {
    if (data !== "" && data !== undefined) {
      deleteAllDeliveryItemData();
      await handleGetDeliveryMatrixDetailById(data, 1);
    }
  };

  const handleGetDeliveryMatrixDetailById = async (
    id,
    selectFromDropdownFlag = ""
  ) => {
    setIsLoading(true);
    // const response = await deliveryMatrixService.getDeliveryMatrixById(id);
    const response = await getDeliveryMatrixById(id);

    if (response && response.status === 200) {
      if (response.data.data) {
        let tempResponseData = response.data.data;

        //select from dropdown then set only time table
        if (selectFromDropdownFlag === 1) {
          tempResponseData.delivery_matrix_items?.map((d, i) => {
            let deliveryOrderType =
              d.delivery_order_type === "Same Day" ? "sameDay" : "in_advance";
            var tempArr = {
              delivery_order_type: deliveryOrderType,
              delivery_time_type: d.delivery_time_type,
              price: d.price,
              delivery_time: d.delivery_time,
              order_by_time: d.order_by_time,
            };

            addDeliveryTimeData(tempArr);
          });
        } else {
          setDeliveryMatrixName(tempResponseData.name);
          setDeliveryMatrixStartDate(moment(tempResponseData.start_date));
          setDeliveryMatrixEndDate(moment(tempResponseData.end_date));

          let zipcodeArrData = [];
          tempResponseData.delivery_matrix_zipcode_folders?.map((d, i) => {
            let filterData = folderList?.filter((data) => {
              if (data.id === d.store_setting_folder_id) {
                return data;
              }
            });
            let tempArr = {
              id: d.store_setting_folder_id,
              title: filterData[0]["title"],
            };
            zipcodeArrData.push(tempArr);
          });
          setDeliveryMatrixZipcode(zipcodeArrData);

          let addressArrData = [];
          tempResponseData.delivery_matrix_address_types?.map((d, i) => {
            let filterData = deliveryAddress?.filter((data) => {
              if (data.id === d.address_type_id) {
                return data;
              }
            });
            let tempArr = {
              id: d.address_type_id,
              title: filterData[0]["title"],
            };
            addressArrData.push(tempArr);
          });
          setDeliveryMatrixAddressType(addressArrData);

          let daysArrData = [];
          tempResponseData.delivery_matrix_days?.map((d, i) => {
            let tempArr = {
              id: d.day,
              title: d.day,
            };
            daysArrData.push(tempArr);
          });
          setDeliveryMatrixDays(daysArrData);

          tempResponseData.delivery_matrix_items?.map((d, i) => {
            let deliveryOrderType =
              d.delivery_order_type === "Same Day" ? "sameDay" : "in_advance";
            let deliveryTime = d.delivery_time;
              // .subtract(12.5, "hour");
            let orderByTime = d.order_by_time;
              // .subtract(12.5, "hour");
            var tempArr = {
              id: d.id,
              delivery_order_type: deliveryOrderType,
              delivery_time_type: d.delivery_time_type,
              price: d.price,
              delivery_time: deliveryTime,
              order_by_time: orderByTime,
              // order_by_time: moment.unix(d.order_by_time),
            };

            addDeliveryTimeData(tempArr);
          });
        }
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handleSubmitButton = async () => {
    // console.log("DeliveryMatrixStatemain=>", DeliveryMatrixState);

    let activeStartDateTimestamp = moment
      .tz(DeliveryMatrixState.start_date, "America/New_York")
      .utc()
      .format("X");
    let activeEndDateTimestamp = moment
      .tz(DeliveryMatrixState.end_date, "America/New_York")
      .utc()
      .format("X");

    let deliveryMatrixApiCall = true;
    let validationMessage = "Please add required data";

    if (
      DeliveryMatrixState.delivery_matrix_days &&
      DeliveryMatrixState.delivery_matrix_days.length > 0
    ) {
    } else {
      if (!DeliveryMatrixState.start_date) {
        deliveryMatrixApiCall = false;
        validationMessage = "Please select days or (start/end) dates";
      } else if (!DeliveryMatrixState.end_date) {
        deliveryMatrixApiCall = false;
        validationMessage = "Please add end date";
      } else if (activeStartDateTimestamp > activeEndDateTimestamp) {
        deliveryMatrixApiCall = false;
        validationMessage = "You can not add start date bigger then end date";
      }
    }
    if (!DeliveryMatrixState.name) {
      deliveryMatrixApiCall = false;
      validationMessage = "Please add matrix name";
    }
    if (deliveryMatrixApiCall) {
      const post_req = postReqDeliveryMatrix(DeliveryMatrixState);
      // console.log("post_req=>>>", post_req);
      // return false;
      if (deliveryMatrixIdUpdate && deliveryMatrixIdUpdate !== "") {
        // let response = await deliveryMatrixService.updateDeliveryMatrix(
        //   post_req,
        //   deliveryMatrixIdUpdate
        // );
        let response = await updateDeliveryMatrix(
          post_req,
          deliveryMatrixIdUpdate
        );
        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          clearDeliveryState();
          setIsLoading(false);
          props.history.push("/delivery-matrix");
        } else {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "error", false);
        }
      } else {
        // let response = await deliveryMatrixService.addDeliveryMatrix(post_req);
        let response = await addDeliveryMatrix(post_req);
        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          clearDeliveryState();
          setIsLoading(false);
          props.history.push("/delivery-matrix");
        } else {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "error", false);
        }
      }
    } else {
      setIsLoading(false);
      showToastMessage(undefined, validationMessage, "error", false);
    }
  };

  // console.log(folderList);
  // console.log(deliveryAddress);

  return (
    <div>
      <Header title={pageTitle} />
      <PrimaryInformation
        folderList={folderList}
        deliveryAddress={deliveryAddress}
        daysList={daysList}
        handleSubmitButton={handleSubmitButton}
      />
      {deliveryMatrixIdUpdate === "" && (
        <MatrixToCopy
          deliveryMatrixNameList={deliveryMatrixNameList}
          handelChangeDeliveryMatrix={handelChangeDeliveryMatrix}
        />
      )}
      <TableContent />
    </div>
  );
};
export default withRouter(AddFormDeliveryMatrix);
