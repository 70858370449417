import React, { useState } from "react";
// import moment from "moment";
import EditIcon from "./../../Assets/images/edit.svg";
import DeleteIcon from "./../../Assets/images/delete.svg";
import AddIcon from "./../../Assets/images/add.svg";

import DeleteConfirmation from "../../Components/Modal/DeleteConfirmation/DeleteConfirmation";
import DeliveryMatrixTime from "../../Components/Modal/DeliveryMatrix/AddDeliveryMatrixTime";

import styles from "./index.module.scss";

import { useDeliveryMatrix } from "./../../Context/DeliveryMatrixCurd/useDeliveryMatrix";
// import { DateTime } from "luxon";
// import { PST_ZONE, UTC_ZONE } from "Pages/Order/Order/utility/util";

import { convertUtcToPstTime } from "../../Hooks/commanUtility";

const TableContent = () => {
  const {
    DeliveryMatrixState,
    addDeliveryTimeData,
    deleteDeliveryTimeData,
    editDeliveryTimeData,
  } = useDeliveryMatrix();

  const [showAddModal, setShowAddModal] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [editId, setEditId] = React.useState(false);
  const [pageTitle, setPageTitle] = React.useState(false);
  const [editDataTime, setEditDataTime] = React.useState(null);
  const [deleteId, setDeleteId] = React.useState();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const handleDeleteClick = async (event, id) => {
    event.preventDefault();
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    setLoading(true);
    deleteDeliveryTimeData(deleteId);
    setDeleteId("");
    setShowDeleteModal(false);
    setLoading(false);
  };

  const handleManageDeliveryMatrixClick = async (type = "", id = "") => {
    setEditId(id);
    if (type === "edit") {
      setEditDataTime(DeliveryMatrixState.delivery_matrix_items[id]);
      setPageTitle("Edit Delivery Matrix Time");
    } else {
      setEditDataTime({
        delivery_order_type: "",
        delivery_time: 1629856800,
        delivery_time_type: "",
        order_by_time: 1629935940,
        price: 0,
      });
      setPageTitle("Add Delivery Matrix Time");
    }
    setShowAddModal(true);
  };

  const handleSaveClickTimeData = async (deliveryMatrixTime) => {
    // console.log("deliveryMatrixTime=>", deliveryMatrixTime);
    // console.log("editDataTime=>", editDataTime.length);
    
    if (editId === "" || editId === null || editId === undefined ) {
      addDeliveryTimeData(deliveryMatrixTime);
    } else {
      editDeliveryTimeData(deliveryMatrixTime, editId);
    }

    setShowAddModal(false);
  };

  return (
    <React.Fragment>
      <div className={`mt-5`}></div>
      <div className={`${styles.productsWrapper}`} id="">
        <div className="">
          <div className="">
            <div className={styles.title}>Add Delivery Matrix Time</div>

            <span
              className={`link cursor-pointer ml-2 ${styles.searchIconWrapper}`}
              onClick={(e) => {
                e.preventDefault();
                handleManageDeliveryMatrixClick();
              }}
            >
              <img className={styles.searchIcon} src={AddIcon} alt="" />
            </span>

            <div className="table-responsive mt-2 mb-4">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-left">Order In Advance/Same Day</th>
                    <th className="text-left">Order By</th>
                    <th className="text-left">Delivered</th>
                    <th className="text-left">Delivery Time</th>
                    <th className="text-left">Price</th>
                    <th className="text-left">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {DeliveryMatrixState.delivery_matrix_items &&
                  DeliveryMatrixState.delivery_matrix_items.length > 0 ? (
                    DeliveryMatrixState.delivery_matrix_items?.map((d, i) => {
                      return (
                        <tr id={i}>
                          <td className={``}>
                            {d.delivery_order_type === "sameDay"
                              ? "Same Day"
                              : "In Advance"}
                          </td>
                          <td className={``}>
                            {/* {DateTime.fromSeconds(d.order_by_time,{zone:UTC_ZONE}).setZone(PST_ZONE).toFormat("hh:mm a")} */}
                            {convertUtcToPstTime(d.order_by_time)}
                          </td>
                          <td className={``}>{d.delivery_time_type}</td>
                          <td className={``}>
                            {/* {moment(d.delivery_time).format("hh:mm a")} */}
                            {/* {DateTime.fromSeconds(d.delivery_time,{zone:UTC_ZONE}).setZone(PST_ZONE).toFormat("hh:mm a")} */}
                            {convertUtcToPstTime(d.delivery_time)}
                          </td>
                          <td className={``}>{d.price}</td>
                          <td className={``}>
                            <img
                              className={`cursor-pointer ml-3`}
                              src={EditIcon}
                              alt="Edit"
                              onClick={(e) =>
                                handleManageDeliveryMatrixClick("edit", i)
                              }
                            />

                            <img
                              className={`cursor-pointer ml-3`}
                              src={DeleteIcon}
                              alt="Delete"
                              onClick={(e) => handleDeleteClick(e, i)}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className={``}></td>
                      <td className={``}></td>
                      <td className={``}></td>
                      <td className={``}></td>
                      <td className={``}></td>
                      <td className={``}></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className={`mt-5`}></div>
      <DeleteConfirmation
        showModal={showDeleteModal}
        title="Delete Delivery Matrix Time"
        message="Are you sure want to delete this delivery matrix time?"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteModal(false);
          setDeleteId(null);
        }}
        handleSubmitClick={handleDelete}
      />

      <DeliveryMatrixTime
        showModal={showAddModal}
        pageTitle="Add Delivery Matrix Time"
        handleCancelClick={(e) => {
          setShowAddModal(false);
          setEditId("");
          setPageTitle("");
          setEditDataTime(null);
        }}
        editId={editId}
        editDataTime={editDataTime}
        setPageTitle={setPageTitle}
        handleSubmitClick={handleSaveClickTimeData}
      />
    </React.Fragment>
  );
};
export default TableContent;
