import RadioBox from "Components/FormControl/RadioBox/RadioBox";
import { MODULES } from "Helpers/Constants";
import { setAllStore } from "Helpers/setAllStore";
import moment from "moment";
import React, { useContext, useState } from "react";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router";
import { getAccessByModuleName } from "Routes/Routes";
import { getProductOrder } from "../../../api/report";
import ViewIcon from "../../../Assets/images/error-outline.svg";
import Select from "../../../Components/FormControl/Select/Select";
import ProductOrderFilter from "../../../Components/ProductOrderFilter/ProductOrderFilter";
import ReactTable from "../../../Components/Table/Table";
import { AppContext } from "../../../Context/AppContext";
import ExportIcon from "./../../../Assets/images/export.svg";
// Components
import Header from "./../../../Components/Header/Header";
import styles from "./index.module.scss";

const ProductsOrder = (props) => {
  const { showToastMessage, modules } = useContext(AppContext);
  let accessExport = getAccessByModuleName(
    modules,
    MODULES.PRODUCT_ORDER_REPORT
  );
  const statusList = [
    { id: "ALL", title: "All" },
    { id: "NON_ADD_ONS", title: "Product" },
    { id: "ADD_ONS", title: "Add On" },
  ];
  const [type, setType] = useState("delivery_date");
  const filterOption = [
    { id: "weekly", title: "Weekly" },
    { id: "monthly", title: "Monthly" },
    { id: "yearly", title: "Yearly" },
    { id: "custom", title: "Custom" },
  ];

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [productType, setProductType] = React.useState("");
  const reference = React.useRef(null);

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  const [fromDate, setFromDate] = useState(
    moment().startOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [toDate, setToDate] = useState(
    moment().endOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [selectedPeriod, setSelectedPeriod] = React.useState("weekly");
  const [selectedPeriodLabel, setSelectedPeriodLabel] = React.useState(
    "By Week"
  );

  const [firstTimePageLoad, setFirstTimePageLoad] = React.useState(false);
  const [exportData, setExportData] = React.useState([]);
  const [exportHeader, setExportHeader] = React.useState([]);
  
  const [checkAllStore,setCheckAllStore] = useState(true);

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
  }) => {

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);

    
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    let postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      type: productType === "ALL" ? "" : productType,
      isExport: false,
      date_type: type,
      filters,
      sortBy,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
    };

    if (!firstTimePageLoad) {
      const response = await getProductOrder(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count / pageSize));

        // if (accessExport && accessExport.permissions.write_access)
        //   handleExportProductOrders(
        //     sortBy,
        //     filters,
        //     fromDateDefault,
        //     toDateDefault
        //   );
      } else if (response.status === 403) {
        showToastMessage(undefined, response.data.message, "error", false);
        props.history.push("/profile");
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    } else {
      setFirstTimePageLoad(false);
    }
    setLoading(false);

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  const handleExportProductOrders = async (
    sortBy,
    filters,
    fromDateDefault,
    toDateDefault
  ) => {
    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);

    let postData = {
      // currentPage: event.pageIndex,
      // itemsPerPage: event.pageSize,
      // globalSearch: event.globalFilter,
      type: productType === "ALL" ? "" : productType,
      isExport: true,
      filters,
      date_type: type,
      sortBy,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
    };

    const response = await getProductOrder(postData);

    if (response && response.status === 200) {
      setExportHeader(response.data.data.header);
      setExportData(response.data.data.data);
      reference.current?.link?.click();
    } else if (response.status === 403) {
      showToastMessage(undefined, response.data.message, "error", false);
      props.history.push("/profile");
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  const handleClearSearch = async (event) => {
    event.preventDefault();

    setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
    setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
    setSelectedPeriod("weekly");

    fetchData({
      ...defaultFilter,
      fromDateDefault: moment().startOf("isoWeek").format("YYYY-MM-DD"),
      toDateDefault: moment().endOf("isoWeek").format("YYYY-MM-DD"),
    });
    // handleExportProductOrders(event);
  };

  const handleSelectedPeriod = async (id) => {
    if (id === "weekly") {
      setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Week");
    } else if (id === "monthly") {
      setFromDate(moment().startOf("month").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("month").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Month");
    } else if (id === "yearly") {
      setFromDate(moment().startOf("year").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("year").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Year");
    }
  };

  const handleFromDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setToDate(moment(data).add(7, "days").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "monthly") {
      setToDate(moment(data).add(1, "months").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "yearly") {
      setToDate(moment(data).add(1, "years").utc().format("ddd MMM DD, YYYY"));
    }
  };

  const handleToDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setFromDate(
        moment(data).subtract(7, "days").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "monthly") {
      setFromDate(
        moment(data).subtract(1, "months").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "yearly") {
      setFromDate(
        moment(data).subtract(1, "years").utc().format("ddd MMM DD, YYYY")
      );
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "title",
        isVisible: true,
        placeholder: "Search by Product Name",
        Cell: ({ value, row, column }) => {
          return (
            <div className="row">
              <div className={`col-md-4 ${styles.ProducatImageWapper}`}>
                <img
                  src={
                    row.original.product_images.length === 0 ||
                    row.original.product_images[0].image === "" ||
                    row.original.product_images[0].image === "null" ||
                    row.original.product_images[0].image === null
                      ? ""
                      : `${row.original.product_images[0].image}`
                  }
                  alt=""
                />
              </div>
              <div className="col-md-8">
                <h4>{row.original.title}</h4>
              </div>
            </div>
          );
        },
      },
      {
        Header: "SKU",
        accessor: "sku_id",
        isVisible: true,
        placeholder: "Search by SKU",
      },
      {
        Header: "Number Of Units",
        accessor: "no_of_products",
        isVisible: true,
        disableFilters: true,
      },
      {
        Header: "Number Of Orders",
        accessor: "no_of_orders",
        isVisible: false,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Order Total",
        accessor: "order_total",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return `$${row.original.order_total}`;
        },
      },
      {
        Header: "Action",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              <img
                className={`cursor-pointer mr-2 view-icon`}
                src={ViewIcon}
                alt="View Order"
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push(
                    `/reports/product-orders/${row.original.id}`
                  );
                }}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Header title="Products Ordered Report" />
      <div id="MainContent" className="main-content">
        <div className="row">
          <div className="col-12 d-flex align-items-center">
            <span
              className="mb-0 mt-2 pb-0 d-inline-bock mr-3"
              style={{ lineHeight: 1 }}
            >
              Filter By
            </span>
            <RadioBox
              selectedValue={type}
              onChange={() => {
                if (setType) {
                  setType("order_date");
                }
              }}
              label="Order date"
              value="order_date"
            />
            <RadioBox
              selectedValue={type}
              onChange={() => {
                if (setType) {
                  setType("delivery_date");
                }
              }}
              label="Delivery date"
              value="delivery_date"
            />
          </div>
          <div className="col-md-11">
            <ProductOrderFilter
              selectedPeriodLabel={selectedPeriodLabel}
              selectedPeriod={selectedPeriod}
              setSelectedPeriod={setSelectedPeriod}
              handleSelectedPeriod={handleSelectedPeriod}
              fromDate={fromDate}
              setFromDate={setFromDate}
              handleFromDate={handleFromDate}
              toDate={toDate}
              setToDate={setToDate}
              handleToDate={handleToDate}
              fetchData={fetchData}
              handleClearSearch={handleClearSearch}
              defaultFilter={defaultFilter}
              filterOption={filterOption}
              AllStoreCheckbox={true}
              defaultSetCheckAllStore={checkAllStore}
              checkAllStore={(data)=>{setCheckAllStore(data)}}
            />
          </div>
          {accessExport && accessExport.permissions.write_access && (
            <div className="col-md-1">
              {exportData && exportData.length > 0 && (
                <CSVLink
                  ref={reference}
                  style={{
                    display: "none",
                    float: "right",
                    marginTop: "25px",
                  }}
                  data={exportData}
                  headers={exportHeader}
                  filename={"ProductsOrderedReport.csv"}
                  className={`link cursor-pointer ml-3 ${styles.searchIconWrapper}`}
                >
                  DownLoad
                </CSVLink>
              )}
              {accessExport && accessExport.permissions.write_access && (
                <span
                  onClick={(e) => handleExportProductOrders(defaultFilter)}
                  className={`link cursor-pointer ${styles.searchIconWrapper}`}
                  style={{ float: "right", marginTop: "25px" }}
                >
                  <img className={styles.searchIcon} src={ExportIcon} alt="" />
                </span>
              )}
            </div>
          )}
        </div>
        <div className="col-md-3" style={{ paddingLeft: "0px" }}>
          <Select
            label="Type"
            value={productType}
            onChange={(data) => {
              setProductType(data);
            }}
            options={statusList}
            disabled={false}
          />
        </div>

        <br />
        <ReactTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(ProductsOrder);
