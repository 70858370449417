// import moment from "moment";

export function postReqCoupon(couponState) {
  let newArrSpecificCollection = [];
  if (couponState.coupon_apply_to === "Specific Product") {
    couponState.coupons_specific_collections.forEach((data) => {
      newArrSpecificCollection.push({ product_id: data.id });
    });
  } else if (couponState.coupon_apply_to === "Specific Collection") {
    couponState.coupons_specific_collections.forEach((data) => {
      newArrSpecificCollection.push({
        category_hierarchy_id: data.category_hierarchy_id,
      });
    });
  } else {
  }

  let userLimit = 0;
  if (couponState.usage_limit_one_per_user) {
    userLimit = 1;
  }

  // let activeStartDate = moment.utc(couponState.active_start_date);
  // let activeStartDateTimestamp = moment(activeStartDate).format("X");
  // let activeStartDateTimestamp = moment.tz(couponState.active_start_date, 'America/New_York').utc().format("X");

  // let activeEndDateTimestamp = null;
  // if (couponState.active_end_date) {
  // let activeEndDate = moment.utc(couponState.active_end_date);
  // activeEndDateTimestamp = moment(activeEndDate).format("X");
  // activeEndDateTimestamp = moment.tz(couponState.active_end_date, 'America/New_York').utc().format("X");
  // }

  let totalCouponCount = null;
  if (
    couponState.usage_limit_number_of_time &&
    couponState.usage_limit_number_of_time?.trim() !== "" &&
    couponState.usage_limit_number_of_time !== undefined
  ) {
    totalCouponCount = parseInt(couponState.usage_limit_number_of_time);
  }

  let post_Req = {
    coupon_code: couponState.coupon_code,
    coupon_type: couponState.coupon_type,
    coupon_value: couponState.coupon_value,
    coupon_apply_to: couponState.coupon_apply_to,
    coupon_redeem_for_mobile: couponState.coupon_redeem_for_mobile,
    coupons_specific_collections: newArrSpecificCollection,
    usage_limit_one_per_user: userLimit,
    usage_limit_number_of_time: totalCouponCount,
    // active_start_date: activeStartDateTimestamp,
    // active_end_date: activeEndDateTimestamp
    active_start_date: couponState.active_start_date,
    active_end_date: couponState.active_end_date,
  };

  return { ...post_Req };
}
