// Packages
import React, { useContext } from 'react';
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import moment from 'moment';
import 'moment-timezone';

// SCSS
import styles from './ChangeRequest.module.scss';

// Component
import Text from "./../../../FormControl/Text/Text";
import Date from "./../../../FormControl/Date/Date";

// Context
import { AppContext } from "./../../../../Context/AppContext";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddChangeRequest = ({ showModal, handleCancelClick, handleSubmitClick, clockDetails, reason, setReason, time, setTime }) => {
    const { timezone } = useContext(AppContext);

    return (
        <React.Fragment>
            <Dialog
                open={showModal}
                TransitionComponent={Transition}
                onClose={handleCancelClick}
                classes={{
                    paper: styles.modalDialog
                }}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogContent
                    className={`${styles.modalContent} px-0 py-0`}
                >
                    <div className={`text-center ${styles.modalBody}`}>
                        <div className="d-flex justify-content-between align-items-center mt-3 mb-5">
                            <h5 className={`my-0 text-center ${styles.modalTitle}`}>Request Change</h5>
                            <span className="current-date">
                                {
                                    (clockDetails && timezone && clockDetails.clock_in) ? moment.unix(clockDetails.clock_in).tz(timezone).format('ddd MMM DD, YYYY') : (clockDetails && timezone && clockDetails.clock_out) ? moment.unix(clockDetails.clock_out).tz(timezone).format('ddd MMM DD, YYYY') : ''
                                }
                            </span>
                        </div>

                        <div className="px-3">
                            <div className="row align-items-center">
                                <div className="col-md-4 col-7 popupLabel text-left">Current Clock {clockDetails.clock_in ? 'In' : 'Out'} -</div>
                                <div className="col-md-4 col-5 popup-inTime text-left">
                                    {
                                        (clockDetails && timezone && clockDetails.clock_in) ? moment.unix(clockDetails.clock_in).tz(timezone).format('hh:mm A') : (clockDetails && timezone && clockDetails.clock_out) ? moment.unix(clockDetails.clock_out).tz(timezone).format('hh:mm A') : ''
                                    }
                                </div>
                            </div>
                            <div className="row align-items-center mt-4">
                                <div className="col-md-4 col-7 popupLabel text-left">Change Clock {clockDetails.clock_in ? 'In' : 'Out'} -</div>
                                <div className="col-md-4 col-5 text-left">
                                    <Date
                                        parentClass="pt-0 mb-1"
                                        value={time}
                                        type="time"
                                        onChange={(time) => setTime(time)}
                                    />
                                </div>
                            </div>
                            <div className="form-group bmd-form-group mb-5">
                                <Text
                                    type="text"
                                    label="Reason"
                                    value={reason}
                                    onChange={(reason) => setReason(reason)}
                                />
                            </div>
                        </div>


                        <a href="/" data-dismiss="modal" onClick={handleSubmitClick} className="btn gray-btn d-inline-block mb-3 mr-2"> Submit </a>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment >
    );
}

export default AddChangeRequest;
