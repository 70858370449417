import React from "react";
import moment from "moment";
// Components
import ReactTable from "../../../Components/Table/Table";
import CheckBox from "../../../Components/FormControl/CheckBox/CheckBox";
// Style
import styles from "./index.module.scss";
// Images
import Check from "./../../../Assets/images/Checks.svg";
import EditIcon from "./../../../Assets/images/edit.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";
import ActivatedIcon from "./../../../Assets/images/activated.svg";
const Administrative = (props) => {
  // const daysValues = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];
  // Table Columns
  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "ids",
        // isVisible: props.userAccess && props.userAccess?.write ? true : false,
        isVisible: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          return row.original.reminder_status === 1 ? (
            <div className={styles.completed}>
              <img src={Check} alt={""} />{" "}
            </div>
          ) : (
            <div className={styles.pending}>
              {((props.userAccess && props.userAccess?.write) ||
                row.original.type === "PERSONAL") && (
                <CheckBox
                  value={row.original.reminder_status === 1 ? true : false}
                  onChange={() => {
                    props.setChecked(row.original.id, props.defaultFilter);
                  }}
                  name="checkedB"
                />
              )}
            </div>
          );
        },
      },
      {
        Header: "Reminder-Checklist",
        accessor: "title",
        isVisible: true,
        Cell: ({ row, value }) => {
          return row.original.reminder_status === 1 ? (
            <div className={styles.completed}>{value}</div>
          ) : (
            <div>{value}</div>
          );
        },
      },
      {
        Header: "Reminder Type",
        accessor: "type",
        isVisible: true,
        disableFilters: props.adminAccess === 'PERSONAL' ? true : false,
        Cell: ({ row, value }) => {
          return row.original.reminder_status === 1 ? (
            <div className={styles.completed}>{value}</div>
          ) : (
            <div>{value}</div>
          );
        },
      },
      {
        Header: "Repeat Type",
        accessor: "repeat_type",
        isVisible: true,
        Cell: ({ row, value }) => {
          let displayValue = value + "\n";
          // if(value === 'WEEKLY'){
          //   let selectedDays = row.original.days;
          //   selectedDays = selectedDays.split(',');
          //   selectedDays.forEach(element => {
          //     displayValue += daysValues[element]+'\n';
          //   });
          // }
          if (value === "MONTHLY") {
            displayValue += moment(row.original.monthly_date).format(
              "ddd MMM DD, YYYY"
            );
          }
          return row.original.reminder_status === 1 ? (
            <div className={styles.completed}>{displayValue}</div>
          ) : (
            <div>{displayValue}</div>
          );
        },
      },
      {
        Header: "From Date",
        accessor: "fromDate",
        isVisible: true,
        isDateFilter: true,
        dateFormatValue: "YYYY-MM-DD",
        Cell: ({ row, value }) => {
          value = moment(value).format("ddd MMM DD, YYYY");
          return row.original.reminder_status === 1 ? (
            <div className={styles.completed}>{value}</div>
          ) : (
            <div>{value}</div>
          );
        },
      },
      {
        Header: "End Date",
        accessor: "endDate",
        isVisible: true,
        isDateFilter: true,
        dateFormatValue: "YYYY-MM-DD",
        Cell: ({ row, value }) => {
          value = moment(value).format("ddd MMM DD, YYYY");
          return row.original.reminder_status === 1 ? (
            <div className={styles.completed}>{value}</div>
          ) : (
            <div>{value}</div>
          );
        },
      },
      {
        Header: "Due Time",
        accessor: "time",
        isVisible: true,
        disableFilters: true,
        Cell: ({ row, value }) => {
          value = moment(value, "HH:mm").format("hh:mm A");
          return row.original.reminder_status === 1 ? (
            <div className={styles.completed}>{value}</div>
          ) : (
            <div>{value}</div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        isVisible: true,
        disableFilters: true,
        Cell: ({ row, value }) => {
          return (
            <div className={styles.status}>
              <div className={value === 1 ? styles.Active : styles.Inactive}>
                {value === 1 ? "Active" : "Inactive"}
              </div>
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return row.original.reminder_status !== 1 ? (
            <div>
              {((props.userAccess && props.userAccess?.write) ||
                row.original.type === "PERSONAL") && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={ActivatedIcon}
                  alt=""
                  onClick={(e) =>
                    props.handleStatusReminderClick(
                      e,
                      value,
                      row.original.status
                    )
                  }
                />
              )}
              {((props.userAccess && props.userAccess?.delete) ||
                row.original.type === "PERSONAL") && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={DeleteIcon}
                  alt="Delete"
                  onClick={(e) => props.handleDeleteClick(e, value)}
                />
              )}
              {((props.userAccess && props.userAccess?.write) ||
                row.original.type === "PERSONAL") && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={EditIcon}
                  alt="Edit"
                  onClick={(e) =>
                    props.handleTodoReminderCode(e, "edit", row.original)
                  }
                />
              )}
            </div>
          ) : (
            <div></div>
          );
        },
      },
    ],
    [props.defaultFilter]
  );
  return (
    <React.Fragment>
      {/* Table For Adminstrative */}
      <ReactTable
        columns={columns}
        data={props.data}
        fetchData={props.fetchData}
        loading={props.loading}
        pageCount={props.pageCount}
        noDataText={"There is no data to display"}
        defaultFilter={props.defaultFilter}
        displayGlobalSearch={false}
        displayColumnVisibility={false}
      />
    </React.Fragment>
  );
};
export default Administrative;
