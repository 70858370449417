import React from "react";
import moment from "moment";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// SCSS
import styles from "./index.module.scss";

// Component
import Text from "../../FormControl/Text/Text";
import Select from "../../FormControl/Select/Select";
import CheckBox from "../../FormControl/CheckBox/CheckBox";
import Date from "../../FormControl/Date/Date";
import CalendarIcon from "../../../Assets/images/calendar.svg";
import { TrainRounded } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const daySelect = (day) => {
  return moment(day).format("MM/DD/YYYY");
};

const ManageEmailNotificationInfoModal = ({
  showModal,
  pageTitle,
  submitStatus,
  email_id,
  template_name,
  username,
  password,
  status,
  handleCancelClick,
  handleSubmitClick,
  onChange
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <Text
                  type="text"
                  label="Email Address"
                  value={email_id}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("email_id", data)}
                  id="emailId"
                />
              </div>
			  <div className="col-md-6 col-xs-12">
                <Text
                  type="text"
                  label="Email Template Name"
                  value={template_name}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("template_name", data)}
                  id="template_name"
                />
              </div>
			  
              <div className="col-md-6 col-xs-12">
			  <Text
                  type="text"
                  label="User Name"
                  value={username}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("username", data)}
                  id="username"
                />
              </div>
              <div className="col-md-6 col-xs-12">
			  <Text
                  type="password"
                  label="Password"
                  value={password}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("password", data)}
                  id="password"
                />
              </div>
			  <div className="col-md-6 col-xs-12">
					<Select
						label="Status"
						parentClass='text-left'
						value={status}
						required={true}
						submitStatus={submitStatus}
						onChange={(data) => onChange('status', data)}
						options={[{ id: 0, title: 'In Active' }, { id: 1, title: 'Active' }]}
						disabled={false}
					/>
				</div>
            </div>
            <a
              href="/"
              data-dismiss="modal"
              onClick={handleSubmitClick}
              className="btn gray-btn d-inline-block mb-3 mr-2"
            >
              Save
            </a>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ManageEmailNotificationInfoModal;
