import { useFormik } from "formik";
import moment from 'moment';

export function BlockOutDatesForm(
      submit,
      initialVal,
) {
      const validation = (values) => {
            let errors = {};
            if (!values.title) {
                  errors.title = "Title is required";
            }


            // console.log('blogError ===>', errors, blogError);          
            return errors;
      };

      const initialValues = {
            title: "",
            start_blocking_on: moment().unix(),
            stop_repeating_on: moment().unix(),
            start_blocking_at: moment().unix(),
            services_block_local_delivery: true,
            services_block_non_local_delivery: true,
            services_block_will_call: true,
            status: 1
      };

      const formik = useFormik({
            initialValues: initialVal || initialValues,
            onSubmit: submit,
            enableReinitialize: true,
            validate: validation,
      });

      return { formik };
}