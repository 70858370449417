import Interceptors from './../Interceptors/Interceptors';

class Utils {
    constructor() {
        this.interceptors = new Interceptors();
    }

    async getFeaturedProduct(id, moduleName) {
        return this.interceptors.GET(`featured-product/${moduleName}/${id}/${false}`);
    }

    async uploadImage(data) {
        return this.interceptors.POST('utils/upload-image', data);
    }

    async updateStatus(postData) {
        return this.interceptors.PUT("utils/update-status/", postData);
    }
}

export default Utils;