import Select from "../../../Components/FormControl/Select/Select";
import Date from "../../../Components/FormControl/Date/Date";
import CalendarIcon from "../../../Assets/images/calendar.svg";
import SearchIcon from "../../../Assets/images/search.svg";
import CloseIcon from "../../../Assets/images/close.svg";
import React, { useState, useMemo, useContext } from "react";
import Header from "../../../Components/Header/Header";
import moment from "moment";
import { withRouter } from "react-router";
import styles from "./index.module.scss";
import ReactTable from "../../../Components/Table/Table";
import { getBusinessAccountReport } from "../../../api/report";
import { AppContext } from "Context/AppContext";
import RevenueBox from "Components/RevenueBox/RevenueBox";
import totalRevenue from "../../../Assets/images/icons/Vector.png";
import downArrow from "../../../Assets/images/downArrow.svg";
import upArrow from "../../../Assets/images/upArrow.svg";
import person from "../../../Assets/images/person.svg";
import CheckBox from "Components/FormControl/CheckBox/CheckBox";
import { setAllStore } from "Helpers/setAllStore";

const BusinessReport = (props) => {
  const { showToastMessage, modules } = useContext(AppContext);
  const [selectedPeriod, setSelectedPeriod] = useState("monthly");
  const [selectedPeriodLabel, setSelectedPeriodLabel] = useState("By Month");
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [data, setData] = useState([]);
  const [firstTimePageLoad, setFirstTimePageLoad] = useState(false);
  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  const [isTotalRevenue, setTotalRevenue] = useState(0);
  const [statisticPriorMonth, setStatisticPriorMonth] = useState(0);
  const [statisticPriorYear, setStatisticPriorYear] = useState(0);
  const [businessAccounts, setBusinessAccounts] = useState(0);
  const [monthArrow, setMonthArrow] = useState("");
  const [yearArrow, setYearArrow] = useState("");
  console.log(monthArrow);

  const [fromDate, setFromDate] = useState(
    moment().startOf("month").format("ddd MMM DD, YYYY")
  );

  const [toDate, setToDate] = useState(moment());
  
  const [checkAllStore,setCheckAllStore] = useState(true);
  
  const handleSelectedPeriod = async (id) => {
    if (id === "weekly") {
      setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Week");
    } else if (id === "monthly") {
      setFromDate(moment().startOf("month").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("month").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Month");
    } else if (id === "yearly") {
      setFromDate(moment().startOf("year").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("year").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Year");
    }
  };

  const handleFromDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setToDate(moment(data).add(7, "days").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "monthly") {
      setToDate(moment(data).add(1, "months").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "yearly") {
      setToDate(moment(data).add(1, "years").utc().format("ddd MMM DD, YYYY"));
    }
  };

  const handleToDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setFromDate(
        moment(data).subtract(7, "days").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "monthly") {
      setFromDate(
        moment(data).subtract(1, "months").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "yearly") {
      setFromDate(
        moment(data).subtract(1, "years").utc().format("ddd MMM DD, YYYY")
      );
    }
  };

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
  }) => {

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);

    setDefaultFilter({
      pageSize,
      pageIndex,
      filters,
      globalFilter,
      sortBy,
    });

    setLoading(true);

    const postData = {
      currentPage: 0, //pageIndex
      itemsPerPage: 10, //pageSize
      globalSearch: globalFilter,
      is_include_header_data: true,
      // data_type: type,
      filters,
      sortBy,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
    };

    const response = await getBusinessAccountReport(postData);
    if (!firstTimePageLoad) {
      const response = await getBusinessAccountReport(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setTotalRevenue(response.data.data.headerData.totalRevenue);
        setStatisticPriorMonth(response.data.data.headerData.priorMonthRevenue);
        setStatisticPriorYear(response.data.data.headerData.priorYearRevenue);
        setBusinessAccounts(response.data.data.headerData.newBusinessAccounts);
        setYearArrow(response.data.data.headerData.priorYearRevenue.type);
        setMonthArrow(response.data.data.headerData.priorMonthRevenue.type);

        setPageCount(Math.ceil(response.data.data.count.length / pageSize));
      } else if (response.status === 403) {
        showToastMessage(undefined, response.data.message, "error", false);
        props.history.push("/dashboard");
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    } else {
      setFirstTimePageLoad(false);
    }
    setLoading(false);

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  const handleClearSearch = async (event) => {
    event.preventDefault();
    setFromDate(moment().startOf("month").format("ddd MMM DD, YYYY"));
    setToDate(moment().endOf("month").format("ddd MMM DD, YYYY"));
    setSelectedPeriod("monthly");
    fetchData({
      ...defaultFilter,
      fromDateDefault: moment().startOf("month").format("ddd MMM DD, YYYY"),
      toDateDefault: moment().endOf("month").format("ddd MMM DD, YYYY"),
    });
  };

  const columns = useMemo(() => [
    {
      Header: "Customer Name",
      accessor: "customer_name",
      isVisible: true,
      placeholder: "Customer Name",
      Cell: ({ value, row, column }) => {
        // console.log(row);
        return `${row.original.customer_name}`;
      },
    },
    {
      Header: "Email",
      accessor: "email",
      isVisible: true,
      placeholder: "Email",
      Cell: ({ value, row, column }) => {
        return `${row.original.email}`;
      },
    },
    {
      Header: "Total Revenue",
      accessor: "order_total_amount",
      isVisible: true,
      disableFilters: true,
      Cell: ({ value, row, column }) => {
        return `${row.original.order_total_amount}`;
      },
    },
    {
      Header: "No.Of Orders",
      accessor: "total_orders",
      isVisible: true,
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value, row, column }) => {
        return `${row.original.total_orders}`;
      },
    },
    {
      Header: "Average Order Value",
      accessor: "averageOrderAmount",
      isVisible: true,
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value, row, column }) => {
        return `${row.original.averageOrderAmount}`;
      },
    },
  ]);

  return (
    <React.Fragment>
      <Header title="Business Report" />

      <div id="MainContent" className="main-content">
        <div style={{ float: "left", width: "100%" }}>
          <div className={"row "}>
            <div className="col-sm-2 col-xs-12">
              <Select
                label={selectedPeriodLabel}
                value={selectedPeriod}
                onChange={(data) => {
                  setSelectedPeriod(data);
                  handleSelectedPeriod(data);
                }}
                options={[
                  { id: "weekly", title: "Weekly" },
                  { id: "monthly", title: "Monthly" },
                  { id: "yearly", title: "Yearly" },
                  { id: "custom", title: "Custom" },
                ]}
                disabled={false}
              />
            </div>
            <div className="col-sm-3 col-xs-12">
              <Date
                label="Filter From "
                value={fromDate}
                dateFormat="ddd MMM DD, YYYY"
                type="date"
                fieldIcon={CalendarIcon}
                onChange={(fromDate) => {
                  setFromDate(moment(fromDate).format("ddd MMM DD, YYYY"));
                  handleFromDate(fromDate);
                }}
              />
            </div>
            <div className="col-sm-3 col-xs-12">
              <Date
                label="Filter To "
                value={toDate}
                dateFormat="ddd MMM DD, YYYY"
                type="date"
                fieldIcon={CalendarIcon}
                onChange={(toDate) => {
                  setToDate(moment(toDate).format("ddd MMM DD, YYYY"));
                  handleToDate(toDate);
                }}
              />
            </div>
            <div className="col-sm-2 col-xs-12">
              <CheckBox
              value={checkAllStore}
              label="All Stores"
              onChange={(data)=>{setCheckAllStore(data)}}
              />
            </div>
            <div className="col-sm-2 col-xs-12">
              <div className="td-actions pt-4">
                <span
                  onClick={(e) => fetchData(defaultFilter)}
                  className={`link cursor-pointer ${styles.searchIconWrapper}`}
                >
                  <img className={styles.searchIcon} src={SearchIcon} alt="" />
                </span>
                <span
                  onClick={(e) => handleClearSearch(e)}
                  className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
                >
                  <img className={styles.clearIcon} src={CloseIcon} alt="" />
                </span>
              </div>
            </div>
          </div>

          <div
            className={"row "}
            style={{ marginBottom: "92px", marginTop: "30px" }}
          >
            <div className="col-sm-3 col-xs-12">
              <RevenueBox
                text="Total Revenue"
                stastic={isTotalRevenue}
                displayIcon={totalRevenue}
                currency="$"
              />
            </div>

            <div className="col-sm-3 col-xs-12" style={{ color: monthArrow === "up" ? "#8FC897" : "#CB998D" }}>
              <RevenueBox
                text="Prior Month"
                stastic={statisticPriorMonth.percentage}
                displayIcon={monthArrow === "up" ? upArrow : downArrow}
                percentage="%"
              />
            </div>

            <div className="col-sm-3 col-xs-12" style={{ color: yearArrow === "up" ? "#8FC897" : "#CB998D" }}>
              <RevenueBox
                text="Prior Year"
                stastic={statisticPriorYear.percentage}
                displayIcon={yearArrow === "up" ? upArrow : downArrow}
                percentage="%"
              />
            </div>

            <div className="col-sm-3 col-xs-12">
              <RevenueBox
                text="New Business Accounts"
                stastic={businessAccounts}
                displayIcon={person}
              />
            </div>
          </div>

          <ReactTable
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            noDataText={"There is no data to display"}
            defaultFilter={defaultFilter}
            displayGlobalSearch={false}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(BusinessReport);
