import React from "react";
import moment from "moment";
// Component
import Text from "../../../Components/FormControl/Text/Text";
import RadioBox from "../../../Components/FormControl/RadioBox/RadioBox";
// import Select from "../../../Components/FormControl/Select/Select";
import CheckBox from "../../../Components/FormControl/CheckBox/CheckBox";
import Date from "../../../Components/FormControl/Date/Date";
// import Datetime from "react-datetime";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

//IMAGES
import DeleteIcon from "../../../Assets/images/delete.svg";

//css
import styles from "./index.module.scss";
import { DateTime } from "luxon";
import { PST_ZONE, UTC_ZONE } from "Pages/Order/Order/utility/util";
import { convertUtcTimestampToPstDate } from "Hooks/commanUtility";

const TypeAndValue = (props) => {
  // console.log("props", props);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleInputChange = (data) => {
    props.setCouponApplyTo(data);
    props.emptySelectedData();
    setErrorMessage("");
  };

  const handleInputChangeType = (data) => {
    props.setCouponCodeType(data);
  };

  const handleSetSelectedCategoryData = (SelectedData) => {
    let duplicateData = props.couponState.coupons_specific_collections?.filter(
      (data) => {
        if (
          data.category_hierarchy_id === SelectedData[0].category_hierarchy_id
        ) {
          return data;
        }
      }
    );

    if (duplicateData && duplicateData.length > 0) {
      setErrorMessage("This Category is already added");
    } else {
      setErrorMessage("");
      props.setSelectedData(SelectedData[0]);
      // let tempVar = {
      //   category_section: false,
      //   product_section: true,
      //   order_section: true,
      // }
      // props.disableSectionOnSelect(tempVar)
    }
  };

  const handleSetSelectedProductData = (SelectedData) => {
    let duplicateData = props.couponState.coupons_specific_collections?.filter(
      (data) => {
        if (data.id === SelectedData[0].id) {
          return data;
        }
      }
    );

    if (duplicateData && duplicateData.length > 0) {
      setErrorMessage("This Product is already added");
    } else {
      setErrorMessage("");
      props.setSelectedData(SelectedData[0]);
    }
  };

  return (
    <div id="MainContent" className="main-content">
      <div className="container-fluid">
        <h3 className="bold mt-5 mb-3 d-flex align-items-center">
          <span>Type</span>
        </h3>
        <div className="row ml-1">
          <div className={`col-mb-3`}>
            <RadioBox
              label="Percentage"
              labelClass={styles.RadioLabel}
              value="Percentage"
              required={true}
              selectedValue={props.couponState.coupon_type}
              onChange={(data) => {
                handleInputChangeType(data);
              }}
              disabled={false}
            />
          </div>
          <div className={`col-mb-3`}>
            <RadioBox
              label="Fixed Amount"
              labelClass={styles.RadioLabel}
              value="Fixed Amount"
              selectedValue={props.couponState.coupon_type}
              onChange={(data) => {
                handleInputChangeType(data);
              }}
              disabled={false}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <h3 className="bold mt-5 mb-3 d-flex align-items-center">
          <span>Value</span>
        </h3>
        <div className="row">
          <div className={`col-12 col-mb-12`}>
            <Text
              type="text"
              label="Coupon Value"
              value={props.couponState.coupon_value}
              submitStatus={props.submitStatus}
              required={true}
              disabled={false}
              // onChange={(data) => props.setCouponCodeValue(data)}
              onChange={(data) => {
                if (!/^[0-9]*$/.test(data) || data.length > 8) {
                  return;
                }
                let val = (+data).toString() || "0";
                props.setCouponCodeValue(val);
              }}
              id="coupon_value"
              maxlength={8}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <h3 className="bold mt-5 mb-3 d-flex align-items-center">
          <span>Apply To</span>
        </h3>
        <div className="row ml-1">
          <div className={`col-mb-3`}>
            {/* comment on 22-07-2021 as told by mem */}
            {/* <RadioBox
              label="Specific Category"
              labelClass={styles.RadioLabel}
              value="Specific Collection"
              required={true}
              selectedValue={props.couponState.coupon_apply_to}
              onChange={(data) => {
                handleInputChange(data);
              }}
              disabled={false}
            /> */}
          </div>
          <div className={`col-mb-3`}>
            <RadioBox
              label="Specific Product"
              labelClass={styles.RadioLabel}
              value="Specific Product"
              required={true}
              selectedValue={props.couponState.coupon_apply_to}
              onChange={(data) => {
                handleInputChange(data);
              }}
              disabled={false}
            />
          </div>
          <div className={`col-mb-3`}>
            <RadioBox
              label="Order Total"
              labelClass={styles.RadioLabel}
              value="Order Total"
              required={true}
              selectedValue={props.couponState.coupon_apply_to}
              onChange={(data) => {
                handleInputChange(data);
              }}
              disabled={false}
            />
          </div>
        </div>
        <div className="row ml-1">
          <div className={`col-mb-3`}>
            <CheckBox
              label="Is redeemable only on mobile app orders?"
              value={props.couponState.coupon_redeem_for_mobile}
              required={false}
              submitStatus={props.submitStatus}
              onChange={(data) => props.setCouponRedeemForMobile(data)}
              id="coupon_redeem_for_mobile"
            />
          </div>
        </div>
      </div>

      {props.couponState.coupon_apply_to !== "Order Total" ? (
        <div className={`row`}>
          <div className={`col-md-12`}>
            {props.couponState.coupon_apply_to === "Specific Collection" ? (
              <Autocomplete
                id="categorySearching"
                className="auto-select"
                freeSolo
                options={props.categoryList.map((option) => option.title)}
                renderInput={(params) => (
                  <TextField {...params} label="Search Category" />
                )}
                onInputChange={(event, newValue) => {
                  event.preventDefault();
                  if (newValue !== undefined) {
                    let filterData = props.categoryList?.filter((data) => {
                      if (data.title === newValue) {
                        return data;
                      }
                    });
                    if (
                      filterData !== undefined &&
                      filterData !== null &&
                      filterData.length > 0
                    ) {
                      handleSetSelectedCategoryData(filterData);
                    }
                  }
                }}
              />
            ) : (
              <Autocomplete
                id="productSearching"
                className="auto-select"
                freeSolo
                options={props.productsList.map((option) => option.title)}
                renderInput={(params) => (
                  <TextField {...params} label="Search Product" />
                )}
                onInputChange={(event, newValue) => {
                  event.preventDefault();
                  if (newValue !== undefined) {
                    let filterData = props.productsList?.filter((data) => {
                      if (data.title === newValue) {
                        return data;
                      }
                    });
                    if (
                      filterData !== undefined &&
                      filterData !== null &&
                      filterData.length > 0
                    ) {
                      handleSetSelectedProductData(filterData);
                    }
                  }
                }}
              />
            )}
            {errorMessage !== "" ? (
              <p className={styles.paragraphBackground}>{errorMessage}</p>
            ) : (
              ""
            )}
          </div>
          <div className={`col-md-12`}>
            {/* <SimilarProduct productList={null} offSet={true} /> */}
            <div className="table-responsive mt-2 mb-4">
              <table className="table">
                {/* <thead>
                  <tr>
                    <th className="text-left"></th>
                    <th className="text-left">Category</th>
                    <th />
                  </tr>
                </thead> */}
                <tbody>
                  {props.couponState.coupons_specific_collections &&
                  props.couponState.coupons_specific_collections.length > 0 ? (
                    props.couponState.coupons_specific_collections?.map(
                      (d, i) => {
                        return (
                          <tr id={i}>
                            {props.couponState.coupon_apply_to ===
                            "Specific Collection" ? (
                              <td className={``}>
                                {d.category_image !== "" ? (
                                  <div className={styles.ProductImageWrapper}>
                                    <img src={d.category_image} alt={d.title} />
                                  </div>
                                ) : (
                                  <div className={`${styles.emptyImage}`}>
                                    <span>Image</span>
                                  </div>
                                )}
                              </td>
                            ) : (
                              <td className={``}>
                                {d.product_images[0]?.image !== "" ? (
                                  <div className={styles.ProductImageWrapper}>
                                    <img
                                      src={d.product_images[0]?.image}
                                      alt={d.title}
                                    />
                                  </div>
                                ) : (
                                  <div className={`${styles.emptyImage}`}>
                                    <span>Image</span>
                                  </div>
                                )}
                              </td>
                            )}
                            <td className={``}>{d.title}</td>
                            <td className={`link cursor-pointer`}>
                              <img
                                onClick={(e) => props.deleteSelectedData(i)}
                                className={`${styles.searchIcon} mr-3`}
                                src={DeleteIcon}
                                alt=""
                              />
                            </td>
                          </tr>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      <td className={``}>
                        <div className={`${styles.emptyImage}`}>
                          <span>Image</span>
                        </div>
                      </td>
                      <td className={``}>No Data</td>
                      <td className={` text-center`}>-</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="container-fluid">
        <h3 className="bold mt-5 mb-3 d-flex align-items-center">
          <span>Usage Limits</span>
        </h3>
        <div className="row">
          <div className={`col-md-6`}>
            {/* <CheckBox label="Limit to one user per customer" /> */}
            <CheckBox
              label="Limit to one use per customer"
              value={props.couponState.usage_limit_one_per_user}
              required={false}
              submitStatus={props.submitStatus}
              onChange={(data) => props.setUsageLimitPerUser(data)}
              id="usage_limit_one_per_user"
            />
          </div>
          <div className={`col-md-6`}>
            <Text
              type="text"
              label="Max Coupon Use Limit"
              value={props.couponState.usage_limit_number_of_time}
              submitStatus={props.submitStatus}
              required={true}
              disabled={false}
              onChange={(data) => {
                if (!/^[0-9]*$/.test(data) || data.length > 10) {
                  return;
                }
                let val = data.toString() || "";
                props.setCouponUseCount(val);
              }}
              id="total_coupon_use"
            />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <h3 className="bold mt-5 mb-3 d-flex align-items-center">
          <span>Active Dates</span>
        </h3>
        <div className="row">
          <div className={`col-md-6`}>
            <Date
              type="dateTime"
              label="State Date"
              // value={props.couponState.active_start_date}
              // value={props.startDate}
              value={
                props.startDate !== ""
                  ? convertUtcTimestampToPstDate(
                      props.startDate,
                      "ccc LLLL dd, yyyy hh:mm a"
                    )
                  : ""
              }
              dateFormat="ddd LL"
              timeFormat="hh:mm A"
              // onChange={(data) => props.setStartDate(data)}
              onChange={(data) => {
                let pst = moment(data).format("ddd LL hh:mm A");
               
                let timestamp = DateTime.fromFormat(
                  pst,
                  "ccc LLLL d, yyyy hh:mm a",
                  {
                    zone: PST_ZONE,
                  }
                )
                  .setZone(UTC_ZONE)
                  .toSeconds();
                  
                props.setStartDate(timestamp);
              }}
              id="start_date"
              checkPassDate={true}
            />
          </div>
          <div className={`col-md-6`}>
            <Date
              label="End Date(Optional)"
              // value={props.couponState.active_end_date}
              // value={props.endDate}
              value={
                props.endDate !== "" && props.endDate !== 0
                  ? convertUtcTimestampToPstDate(
                      props.endDate,
                      "ccc LLLL dd, yyyy hh:mm a"
                    )
                  : ""
              }
              dateFormat="ddd LL"
              timeFormat="hh:mm A"
              type="dateTime"
              // onChange={(fromDate) => props.setEndDate(fromDate)}
              onChange={(endDate) => {
                let pst = moment(endDate).format("ddd LL hh:mm A");
                let timestamp = DateTime.fromFormat(
                  pst,
                  "ccc LLLL d, yyyy hh:mm a",
                  {
                    zone: PST_ZONE,
                  }
                )
                  .setZone(UTC_ZONE)
                  .toSeconds();

                props.setEndDate(timestamp);
              }}
              id="end_date"
              checkPassDate={true}
            />
          </div>
        </div>
      </div>
      {/* <a
        href="/"
        data-dismiss="modal"
        onClick={(e) => console.log(e)}
        className="btn gray-btn d-inline-block mt-5 mb-3 mr-2"
        style={{ marginLeft: "280px" }}
      >
        Save
      </a> */}
      <span
        data-dismiss="modal"
        onClick={(e) => props.handleSubmitClick(e)}
        className="btn gray-btn d-inline-block mt-5 mb-3 mr-2"
        style={{ marginLeft: "280px" }}
      >
        Save
      </span>
    </div>
  );
};
export default TypeAndValue;
