import Interceptors from "../Interceptors/Interceptors";

class CmsFaq {
  constructor() {
    this.interceptors = new Interceptors();
  }

  async getFaqList(data) {
    return this.interceptors.POST("faq", data);
  }

  async getFaqDetail(faqId) {
    return this.interceptors.GET("faq/" + faqId);
  }

  async createFaq(postData) {
    return this.interceptors.POST("faq/add", postData);
  }

  async updateFaq(postData,faqId) {
    return this.interceptors.PUT("faq/"+faqId, postData);
  }

  async deleteFaq(id) {
    return this.interceptors.DELETE("faq/" + id);
  }

}

export default CmsFaq;
