import React from "react";
import Select from "react-select";

// SCSS
import styles from "./index.module.scss";

const SelectInput = ({
  label,
  value,
  placeholder,
  submitStatus,
  parentClass,
  options,
  required,
  disabled,
  onChange,
  isSearchable,
  usedFor = "",
  menuPortal,
  isMulti,
}) => {
  let customCss = {};

  if (usedFor === "Filter") {
    customCss = {
      container: (styles) => ({ ...styles, maxHeight: "33px", fontSize: 14 }),
      control: (styles, state) => ({
        ...styles,
        borderTop: "none !important",
        borderRight: "none !important",
        borderLeft: "none !important",
        borderBottomWidth: state.isFocused ? 2 : 1,
        borderRadius: 0,
        background: "transparent",
        maxHeight: "33px",
        minHeight: 33,
        minWidth: "100px",
        boxShadow: "none",
      }),
      indicatorsContainer: (styles) => ({ ...styles, maxHeight: "33px" }),
      valueContainer: (styles) => ({
        ...styles,
        paddingLeft: 0,
        maxHeight: "33px",
        color: "#212721",
      }),
      input: (styles) => ({ ...styles, maxHeight: "24px" }),
      placeholder: (styles) => ({ ...styles, maxHeight: "24px" }),
      singleValue: (styles) => ({
        ...styles,
        maxHeight: "33px",
        color: "#212721",
      }),
      option: (provided, state) => ({
        ...provided,
        lineHeight: 1,
        color: "#212721",
      }),
    };
  } else {
    customCss = {
      control: (styles, state) => ({
        ...styles,
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
        borderBottomWidth: state.isFocused ? 2 : 1,
        borderRadius: 0,
        background: "transparent",
        boxShadow: "none",
        fontSize: 20,
        height: 44,
      }),
      singleValue: (styles) => ({ ...styles, color: "#212721" }),
      valueContainer: (styles) => ({
        ...styles,
        paddingLeft: 0,
        height: 44,
        color: "#212721",
      }),
    };
  }
  return (
    <div
      className={`form-group bmd-form-group ${
        value || value === 0 ? "is-filled" : ""
      } ${parentClass ? parentClass : ""} ${
        submitStatus && required && !value ? styles.errorInput : ""
      }`}
    >
      <label className={`bmd-label-floating ${styles.selectLabel}`}>
        {label}
      </label>
      <Select
        placeholder={placeholder || ""}
        menuPortalTarget={menuPortal && menuPortal}
        value={options.find((optionsObj) => optionsObj.id === value) ?? ""}
        isDisabled={disabled}
        getOptionLabel={(option) => {
          if (!value && options.length === 0) {
            return "";
          } else {
            return option.length !== 0
              ? option.first_name
                ? `${option.first_name} ${option.last_name}`
                : option.name
                ? option.name
                : option.title
                ? option.title
                : option.zone && option.country_name
                ? option.zone + " (" + option.country_name + ")"
                : ""
              : "";
          }
        }}
        getOptionValue={(option) => `${option.id}`}
        onChange={(data) => onChange(data.id)}
        options={options}
        isSearchable={isSearchable}
        styles={customCss}
        components={{
          IndicatorSeparator: () => null,
        }}
        isMulti={isMulti}
        maxMenuHeight={usedFor === "Filter" ? 200 : 300}
        // menuPortalTarget={document.body}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#CB998D",
            primary25: "rgba(203,153,141,.15)",
          },
        })}
      />
    </div>
  );
};

export default SelectInput;
