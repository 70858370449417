import Header from "Components/Header/Header";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "Context/AppContext";
import { MODULES, REQUEST_STATUS, REQUEST_TYPE_FILTER } from "Helpers/Constants";
import { getAccessByModuleName } from "./../../../Routes/Routes";
import {
  getRequestInventoryList,
  deleteRequestedInventory,
} from "api/inventoryProduct";
import ReactTable from "Components/Table/Table";
// Images
import EditIcon from "./../../../Assets/images/edit.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";
import ViewIcon from "../../../Assets/images/error-outline.svg";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import DeleteConfirmation from "./../../../Components/Modal/DeleteConfirmation/DeleteConfirmation";

const RequestResources = (props) => {
  const { modules, showToastMessage, setIsLoading } = useContext(AppContext);
  const [access, setAccess] = useState({});
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [data, setData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState({
    isDelete: false,
    data: null,
  });
  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [
      {
        id: "id",
        desc: true,
      },
    ],
    filters: [],
    globalFilter: "",
    list_type: "request",
  });
  const statusList = REQUEST_STATUS;
  const typeList = REQUEST_TYPE_FILTER;

  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.REQUEST_RESOURCES);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/profile");
    }
  }

  const history = useHistory();
  const handleManageResourceClick = async (
    event,
    type = "add",
    resource = {}
  ) => {
    setIsLoading(true);
    if (type === "add") {
      event.preventDefault();
      history.push(`/inventory-management/request-resources/add-request`);
      setIsLoading(false);
    } else if (type === "edit") {
      let id = resource.id;
      history.push(
        `/inventory-management/request-resources/edit-request/${id}`
      );
      setIsLoading(false);
    } else {
      let id = resource.id;
      history.push(
        `/inventory-management/request-resources/view-request/${id}`
      );
      setIsLoading(false);
    }
  };

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      list_type: "request",
    };
    const response = await getRequestInventoryList(postData);
    if (response && response.status === 200) {
      setData(response.data.data.rows);
      setPageCount(Math.ceil(response.data.data.count / pageSize));
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);
  };

  useEffect(async () => {
    const postData = {
      ...defaultFilter,
      list_type: "request",
    };
    await fetchData(postData);
  }, []);

  const requestDeleteHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (
      showDeleteModal.isDelete &&
      (showDeleteModal.data.request_status === "pending_review" ||
        showDeleteModal.data.request_status === "saved_as_draft")
    ) {
      const response = await deleteRequestedInventory(showDeleteModal.data.id);
      if (response && response.status === 200) {
        setIsLoading(false);
        setShowDeleteModal({ isDelete: false, data: null });
        showToastMessage(undefined, response.data.message, "success", false);
        fetchData(defaultFilter);
      } else {
        setIsLoading(false);
        setShowDeleteModal({ isDelete: false, data: null });
        showToastMessage(undefined, response.data.message, "error", false);
      }
    } else {
      setIsLoading(false);
      showToastMessage(
        undefined,
        "Sorry you can't delete approved the details.",
        "error",
        false
      );
      setShowDeleteModal({ isDelete: false, data: null });
    }
  };

  const columns = React.useMemo(() => [
    {
      Header: "Request No.",
      accessor: "request_number",
      isVisible: true,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Source Store",
      accessor: "source_store_id",
      placeholder: "Search By Source Store Name",
      disableSortBy: true,
      isVisible: true,
      Cell: ({ value, row, column }) => {
        return <span>{row.original?.sourceStore?.title}</span>;
      },
    },
    {
      Header: "Requested By",
      accessor: "request_created_by",
      placeholder: "Search Requested by Name",
      isVisible: true,
      disableSortBy: true,
      Cell: ({ value, row, column }) => {
        return <span>{row.original?.requestCreatedBy?.full_name}</span>;
      },
    },
    {
      Header: "Request Date",
      accessor: "createdAt",
      isVisible: true,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ value, row, column }) => {
        return (
          <span>
            {DateTime.fromISO(row.original.createdAt)
              .setZone("America/Los_Angeles")
              .toFormat("ccc LLL dd, yyyy, hh:mm a")}
          </span>
        );
      },
    },
    {
      Header: "Status",
      accessor: "request_status",
      isVisible: true,
      disableSortBy: true,
      disableFilters: true,
      selectFilterOption: statusList,
      Cell: ({ value, row, column }) => {
        let requestStatus = statusList.filter(
          (status) => status.id === row.original.request_status
        );
        return `${requestStatus.length ? requestStatus[0]["title"] : "-"}`;
      },
    },
    {
      Header: "Type",
      accessor: "request_type",
      isVisible: true,
      disableSortBy: true,
      isSelectFilter: true,
      selectFilterOption: typeList,
      Cell: ({ value, row, column }) => {
        let requestType = typeList.filter(
          (status) => status.id === row.original.request_type
        );
        return `${requestType.length ? requestType[0]["title"] : "-"}`;
      },
    },
    {
      Header: "Action",
      accessor: "id",
      isVisible: true,
      disableSortBy: true,
      Cell: ({ value, row, column }) => {
        const checkFilledReqst =
          row.original.request_status === "partially_filled" ||
          row.original.request_status === "filled";
        return (
          <div className={"d-flex justify-content-start"}>
            {access && access.read_access && (
              <img
                className={`cursor-pointer`}
                src={ViewIcon}
                alt="Edit"
                onClick={(e) => {
                  e.preventDefault();
                  handleManageResourceClick(e, "view", row.original);
                }}
              />
            )}
            {access && access.write_access && !checkFilledReqst && (
              <img
                className={`cursor-pointer ml-3`}
                src={EditIcon}
                alt="Edit"
                onClick={(e) => {
                  e.preventDefault();
                  handleManageResourceClick(e, "edit", row.original);
                }}
              />
            )}
            {access && access.delete_access && !checkFilledReqst && (
              <img
                className={`cursor-pointer ml-3`}
                src={DeleteIcon}
                alt="Delete"
                onClick={(e) => {
                  e.preventDefault();
                  setShowDeleteModal({ isDelete: true, data: row.original });
                }}
              />
            )}
          </div>
        );
      },
    },
  ]);

  return (
    <>
      <Header
        title="Request Resources"
        hasAddButton={access.write_access}
        handleAddClick={handleManageResourceClick}
      />
      <ReactTable
        columns={columns}
        data={data}
        expandedRows={true}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        noDataText={"There is no data to display"}
        defaultFilter={defaultFilter}
        displayGlobalSearch={false}
      />

      <DeleteConfirmation
        showModal={showDeleteModal.isDelete}
        title="Delete Requested Resource"
        message="Are you sure want to delete this Requested Resource?"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteModal({ isDelete: false, data: null });
        }}
        handleSubmitClick={requestDeleteHandler}
      />
    </>
  );
};

export default withRouter(RequestResources);
