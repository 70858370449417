import { useFormik } from "formik";

export function HolidayForm(
      submit,
      initialVal,
) {
      const validation = (values) => {
            let errors = {};
            let blogError = false;
            if (!values.product_label) {
                  errors.product_label = "Product Label is required";
                  blogError = true;
            }

            if (!values.url) {
                  errors.url = "url is required";
                  blogError = true;
            }

            if (!values.headline) {
                  errors.headline = "headline is required";
                  blogError = true;
            }

            // console.log('blogError ===>', errors, blogError);          
            return errors;
      };

      const initialValues = {
            product_label: "",
            url: "",
            copy: "",
            url: "",
            start_date: "",
            end_date: "",
            preview_text: "",
      };

      const formik = useFormik({
            initialValues: initialVal || initialValues,
            onSubmit: submit,
            enableReinitialize: true,
            validate: validation,
      });

      return { formik };
}