import React from "react";
import classes from "./index.module.scss";
import CardContent from "@material-ui/core/CardContent";
const Goto = (props) => {

  return (
    <div className={`${classes.summaryBody}`}>
      <CardContent>
        <div className={`link cursor-pointer row`}>
          <div className={`col-md-12`}>
            <h3 className={`bold`}>Summary</h3>
          </div>
          <div className={`row`}>
            <ul class={` ${classes.summaryList}`}>
              {props.summaryList.map((data, index) => (
                <li
                  // onClick={() => {
                  //   let el = document.getElementById(data.id);
                  //   window.scrollTo(el.offsetLeft, el.offsetTop);
                  // }}
                  key={index}
                  class=""
                >
                  <span>{data.title}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={`link cursor-pointer row`}>
          <div className={`col-md-12`}>
            <h3 className={`bold`}>Coupon Used</h3>
          </div>
          <div className={`row`}>
            <ul class={` ${classes.summaryList}`}>
              {props.couponSummaryList.map((data, index) => (
                <li
                  // onClick={() => {
                  //   let el = document.getElementById(data.id);
                  //   window.scrollTo(el.offsetLeft, el.offsetTop);
                  // }}
                  key={index}
                  class=""
                >
                  <span>{data.title}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </CardContent>
    </div>
  );
};
export default Goto;
