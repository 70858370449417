import Interceptors from "../Interceptors/Interceptors";

class PaymentCardApi {
  constructor() {
    this.interceptors = new Interceptors();
  }

  async getPaymentCardlist(data) {
    return this.interceptors.POST("customers/csr/cardDetails", data);
  }
  async updatePaymentCardDetails(data) {
    return this.interceptors.PUT("customers/csr/card/updateDetails", data);
  }
}

export default PaymentCardApi;
