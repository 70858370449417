import CheckBox from "Components/FormControl/CheckBox/CheckBox";
import moment from "moment";
import React from "react";
// images
import CalendarIcon from "../../Assets/images/calendar.svg";
import CloseIcon from "../../Assets/images/close.svg";
import SearchIcon from "../../Assets/images/search.svg";
import Date from "../../Components/FormControl/Date/Date";
import Select from "../../Components/FormControl/Select/Select";
import styles from "./ProductOrderFilter.module.scss";
import { useUser } from "Context/UserContext/useUser";

const ProductOrderFilter = (props) => {
  const { userState } = useUser();
  let defaultOption = [
    { id: "weekly", title: "Weekly" },
    { id: "monthly", title: "Monthly" },
    { id: "yearly", title: "Yearly" },
  ];
  return (
    <div className={"row " + props.wrapperClass}>
      <div className="col-sm-2 col-xs-12">
        <Select
          label={props.selectedPeriodLabel}
          value={props.selectedPeriod}
          onChange={(data) => {
            props.setSelectedPeriod(data);
            props.handleSelectedPeriod(data);
          }}
          options={props.filterOption || defaultOption}
          disabled={false}
        />
      </div>
      <div className="col-sm-2 col-xs-12">
        <Date
          label="Filter From"
          value={props.fromDate}
          dateFormat="ddd MMM DD, YYYY"
          type="date"
          fieldIcon={CalendarIcon}
          onChange={(fromDate) => {
            props.setFromDate(moment(fromDate).format("ddd MMM DD, YYYY"));
            props.handleFromDate(fromDate);
          }}
        />
      </div>
      <div className="col-sm-2 col-xs-12">
        <Date
          label="Filter To"
          value={props.toDate}
          dateFormat="ddd MMM DD, YYYY"
          type="date"
          fieldIcon={CalendarIcon}
          onChange={(toDate) => {
            props.setToDate(moment(toDate).format("ddd MMM DD, YYYY"));
            props.handleToDate(toDate);
          }}
        />
      </div>
      {
        props.AllStoreCheckbox && 
      <div className="col-sm-2 col-xs-12">
        <CheckBox
        value={props.defaultSetCheckAllStore}
        label="All Stores"
        onChange={(data)=>{props.checkAllStore(data)}}
        />
      </div>
      }
      <div className={`col-sm-2 col-xs-12`}>
        <div className="td-actions pt-4">
          <span
            onClick={(e) => props.fetchData(props.defaultFilter)}
            className={`link cursor-pointer ${styles.searchIconWrapper}`}
          >
            <img className={styles.searchIcon} src={SearchIcon} alt="" />
          </span>
          <span
            onClick={(e) => props.handleClearSearch(e)}
            className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
          >
            <img className={styles.clearIcon} src={CloseIcon} alt="" />
          </span>

          {/* <CSVLink
            data={props.exportData}
            headers={props.exportHeader}
            filename={"timeHistory.csv"}
            className={`link cursor-pointer ml-3 ${styles.searchIconWrapper}`}
          >
            <img className={styles.export} src={ExportIcon} alt="" />
          </CSVLink> */}
        </div>
      </div>
        {userState && userState?.userProfile?.user_type === "super_admin" && (
          props.AllUserLogoutCheckbox &&(
            <>
              <div className={`col-sm-4 col-xs-12`} style={{ justifyContent:"end" , display : "flex"}}>
                <div className={`${styles.showAllCheckBox}`}>
                  <CheckBox
                  value={props.defaultSetCheckShowAll}
                  label="Show All"
                  onChange={(data)=>{props.checkShowAll(data)}}
                  />
                </div>
                <div className="">
                <span
                    onClick={(data)=>{props.handleAllUserLogout(data)}}
                    className={`btn gray-btn d-inline-block mt-3 ${styles.logoutAllButton}`}
                  >
                    {" "}
                    Logout All{" "}
                </span>
                </div>
              </div>
            </>
          )
        )
      }
    </div>
  );
};

export default ProductOrderFilter;
