import { useFormik } from "formik";

export function ProductNavigationInfoForm(
      submit,
      initialVal,
      cb,
      checkNavigationData
) {

      const validation = (values) => {
            
            let errors = {};
            let productNavigationError = false;
            if (!values.slug) {
                  errors.slug = "Slug required";
                  productNavigationError = true;
            } else {
                  productNavigationError = false;
            }
            
            if (cb) {
                  cb(values);
            }
            checkNavigationData(productNavigationError);
            return errors;
      };

      const initialValues = {
            slug: "",
            blurb: "",
      };

      const formik = useFormik({
            initialValues: initialVal || initialValues,
            onSubmit: submit,
            enableReinitialize: true,
            validate: validation,
      });

      return { formik };
}