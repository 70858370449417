import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React from "react";
// SCSS
import styles from "./index.module.scss";
import CheckSvg from "../../../Assets/images/check-fill.svg";
import CheckOutlineSvg from "../../../Assets/images/check-outline.svg";
const CheckBox = ({
  label,
  type,
  value,
  submitStatus,
  required,
  disabled,
  onChange,
}) => {
  return (
    <FormControlLabel
      label={label}
      className={`${styles.FormControlLabel}`}
      disabled={disabled}
      control={
        <Checkbox
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          icon={<img src={CheckOutlineSvg} alt={label} />}
          checkedIcon={<img src={CheckSvg} alt={label} />}
        />
      }
    />
  );
};

export default CheckBox;
