import { duplicate, EditOrderState } from "./editOrderStateSet";
import { OrderActions } from "./orderActions";
import { orderCaculation } from "./OrderCalculation";
import { orderState } from "./orderState";

const newRec = {
  form: {
    recipients_id: 0,
    whats_the_occasion: "",
    whats_the_occasion_id: null,
    first_name: "",
    last_name: "",
    greetings_on_card: "",
    card_signature: "",
    completed: false,
  },
  // isVerifiedRecipient: 0,
  added_product: [],
  addedAddons: [],
  flower_total: 0,
  tax: 0,
  delivery_charge: 0,
  subtotal: 0,
  total: 0,
};
const clrState = {
  activeRecipentIndex: 0,
  recipents: [
    {
      form: {
        recipients_id: 0,
        whats_the_occasion: "",
        whats_the_occasion_id: null,
        first_name: "",
        last_name: "",
        greetings_on_card: "",
        card_signature: "",
      },
      added_product: [],
      addedAddons: [],
    },
  ],
  isVerifiedCustomer: false,
  formInfo: {
    order_id: 0,
    address_type: "",
    address_type_id: null,
    attn: "",
    address_line_1: "",
    address_line_2: "",
    phone: "",
    alt_phone: "",
    city: "",
    state: "",
    zipcode: "",
    country: "usa",
    is_local: false,
    latitude: "",
    longitude: "",
  },
  leadTime: 0,
  order: {
    frequency: 0,
    parent_order_id: 0,
    is_subscribe_order: false,
    csr_id: 0,
    order_type: 0,
    source: "website",
    designer_id: 0,
    actual_delivered_time: "",
    recipient_signature_or_picture: "",
    driver_instruction: "",
    delivery_date: "",
    delivery_type: "",
    delivered_time_id: null,
    delivered_time: "",
    custom_delivery_time: "",
    custom_delivery_time_before_after: "",
    custom_delivery_time_amount: 0,
    shopcode: "",
    external_shop_name: "",
    external_phone_number: "",
    external_person_name: "",
    external_order_number: "",
    designer_instruction: "",
    total_qty: "",
    sub_total: 0,
    promocode_type: "",
    promocode_id: 0,
    promocode: "",
    created_by: 1,
    discount_percentage: 0,
    discount_price: 0,
    upcharge_id: 0,
    upcharge_amount: 0,
    delivery_charges: 0,
    custom_delivery_time_ampm: "",
    custom_reason_for_discount: "",
    earned_petal_rewards_point: 0,
    flower_total_amount: 0,
    gift_card_amount: 0,
    gift_card_id: 0,
    gift_card_detail: "",
    internal_notes: "",
    is_fraud_order: 0,
    is_local_order: 0,
    order_status: "",
    petal_rewards_earn_amount: 0,
    petal_rewards_id: 0,
    petal_rewards_point_amount: 0,
    petal_rewards_point_applied: 0,
    tax: 0,
    total: 0,
    tax_id: "",
    total_commission_amount: 0,
    add_fund: 0,
    refund: 0,
    burq_quote_id:"",
    burq_quote_expires:""
  },
  order_transaction: {
    customer_address_id: 0,
    total_qty: 0,
    sub_total: 0,
    promocode: "",
    promocode_id: 0,
    discount_price: 0,
    delivery_charges: 0,
    upcharge_amount: 0,
    tax: 0,
    tax_id: "",
    flower_total_amount: 0,
    petal_rewards_id: 0,
    petal_rewards_point_amount: 0,
    petal_rewards_point_applied: 0,
    total: 0,
    total_commission_amount: 0,
    gift_card_amount: 0,
    gift_card_detail: "",
    gift_card_id: "",
    order_from: 0,
    customer_id: 0,
    promocode_type: "",
    earned_petal_rewards_point: 0,
  },
  customer_info: {
    customer_first_name: "",
    customer_last_name: "",
    customer_email: "",
    customer_phone: "",
    customer_alt_phone: "",
    customer_id: 0,
    is_house_account: 0,
    is_business_account: 0,
  },
  billing_data: {
    customer_billing_first_name: "",
    customer_billing_last_name: "",
    customer_billing_address: "",
    customer_billing_city: "",
    customer_billing_state: "",
    customer_billing_country: "United States",
    customer_billing_zipcode: "",
    customer_id: 0,
  },

  delivery_type_id: 0,
  not_available_at_home_key: "",
  not_available_at_home_option: "",
  is_local_available: false,
  card_list: [],
  order_payment_transaction: [
    {
      transaction_type: "",
      type: "",
      card_id: 0,
      card_last_4_digits: "",
      paymentMethodNonce: "",
      total_amount: 0,
      description: "",
      is_subscription_paymeny: 0,
      card_type: "",
    },
  ],
  order_card_list: [],
  frequencyList: [],
  petalrewardlist: [],
  blockoutdate: [],
  upCharge: [],
  customererror: false,
  deliveryMatrixError: false,
  recipentscommanError: false,
  recipentsError: false,
  orderCardCompletionStatus: false,
  paymetTypeSelect: "credit_card",
  holidayCutOff: [],
  biilingAddressError: false,
};

function orderReducer(state, action) {
  switch (action.type) {
    case OrderActions.SET_ACTIVE_INDEX:
      return {
        ...state,
        activeRecipentIndex: action.payload,
      };
    case OrderActions.ADDON_ADD:
      let recipents = [...state.recipents];
      recipents[state.activeRecipentIndex].addedAddons = [
        ...recipents[state.activeRecipentIndex].addedAddons,
        ...action.payload,
      ];
      // recipents[state.activeRecipentIndex].addedAddons.push(action.payload);
      return {
        ...state,
        recipents: recipents,
      };
    case OrderActions.REMOVE_ADDON_ITEM:
      let recipentsAddon = [...state.recipents];
      recipentsAddon[state.activeRecipentIndex].addedAddons.splice(
        action.payload,
        1
      );
      return {
        ...state,
        recipents: recipentsAddon,
      };
    case OrderActions.INCRESS_ADDON_QTY:
      let recipentsAddonQty = [...state.recipents];
      recipentsAddonQty[state.activeRecipentIndex].addedAddons[action.payload]
        .qty++;
      recipentsAddonQty[state.activeRecipentIndex].addedAddons[
        action.payload
      ].total_price =
        recipentsAddonQty[state.activeRecipentIndex].addedAddons[action.payload]
          .qty *
        recipentsAddonQty[state.activeRecipentIndex].addedAddons[action.payload]
          .selectedVariant.price;

      return { ...state, recipents: recipentsAddonQty };
    case OrderActions.DECRESS_ADDON_QTY:
      let recipentsAddonQtyd = [...state.recipents];
      recipentsAddonQtyd[state.activeRecipentIndex].addedAddons[action.payload]
        .qty--;
      recipentsAddonQtyd[state.activeRecipentIndex].addedAddons[
        action.payload
      ].total_price =
        recipentsAddonQtyd[state.activeRecipentIndex].addedAddons[
          action.payload
        ].qty *
        recipentsAddonQtyd[state.activeRecipentIndex].addedAddons[
          action.payload
        ].selectedVariant.price;
      return { ...state, recipents: recipentsAddonQtyd };
    case OrderActions.REMOVE_PRODUCT_ITEM:
      let recipentsProduct = [...state.recipents];
      recipentsProduct[state.activeRecipentIndex].added_product.splice(
        action.payload,
        1
      );
      return {
        ...state,
        recipents: recipentsProduct,
      };
    case OrderActions.PRODUCT_ADD:
      let rcp = [...state.recipents];
      rcp[state.activeRecipentIndex].added_product = [
        ...rcp[state.activeRecipentIndex].added_product,
        ...action.payload,
      ];
      // rcp[state.activeRecipentIndex].added_product.push(action.payload);
      return {
        ...state,
        recipents: rcp,
      };
    case OrderActions.SET_HLOIDAY_CUT_OFF:
      return {
        ...state,
        holidayCutOff: action.payload,
      };
    case OrderActions.INCRESS_PRODUCT_QTY:
      let recipentsProductQty = [...state.recipents];
      recipentsProductQty[state.activeRecipentIndex].added_product[
        action.payload
      ].qty++;
      recipentsProductQty[state.activeRecipentIndex].added_product[
        action.payload
      ].total_price =
        recipentsProductQty[state.activeRecipentIndex].added_product[
          action.payload
        ].qty *
        recipentsProductQty[state.activeRecipentIndex].added_product[
          action.payload
        ].selectedVariant.price;
      return { ...state, recipents: recipentsProductQty };
    case OrderActions.DECRESS_PRODUCT_QTY:
      let recipentsProductQtyd = [...state.recipents];
      recipentsProductQtyd[state.activeRecipentIndex].added_product[
        action.payload
      ].qty--;
      recipentsProductQtyd[state.activeRecipentIndex].added_product[
        action.payload
      ].total_price =
        recipentsProductQtyd[state.activeRecipentIndex].added_product[
          action.payload
        ].qty *
        recipentsProductQtyd[state.activeRecipentIndex].added_product[
          action.payload
        ].selectedVariant.price;
      return { ...state, recipents: recipentsProductQtyd };
    case OrderActions.ADD_NEW_RECIPENT:
      let rec = state.recipents || [];
      rec.push(newRec);
      return {
        ...state,
        recipents: rec,
      };
    case OrderActions.CALCULATE:
      let newState = orderCaculation(state);
      return newState;
    case OrderActions.REMOVE_RECIPENT:
      let rect = { ...state };
      rect.recipents[action.payload].added_product = [];
      rect.recipents[action.payload].addedAddons = [];
      rect.recipents.splice(action.payload, 1);
      return {
        ...rect,
      };
    case OrderActions.UPDATE_COMMON_RECIPIENT_FPOM:
      let form = { ...state.formInfo };
      form = { ...form, ...action.payload };
      return {
        ...state,
        formInfo: form,
      };
    case OrderActions.UPDATE_RECIPIENT_FPOM:
      // console.log(action.payload);
      let recupdate = state.recipents ? [...state.recipents] : [];
      recupdate[state.activeRecipentIndex].form = {
        ...recupdate[state.activeRecipentIndex].form,
        ...action.payload,
      };
      return {
        ...state,
        recipents: recupdate,
      };
    case OrderActions.UPDATE_GREETING_CARD_FORM:
      let updateCard = state.recipents ? [...state.recipents] : [];
      updateCard[state.activeRecipentIndex].form = {
        ...updateCard[state.activeRecipentIndex].form,
        greetings_on_card: action.payload,
      };
      return {
        ...state,
        recipents: updateCard,
      };
    case OrderActions.UPDATE_CUSTOMER_DATA:
      let customerData = { ...state.customer_info, ...action.payload };
      return {
        ...state,
        customer_info: customerData,
      };
    case OrderActions.UPDATE_BILLING_ADDRESS:
      let Billingdata = { ...state.billing_data, ...action.payload };
      return {
        ...state,
        billing_data: Billingdata,
      };
    case OrderActions.UPDATE_DESIGNER_INSTRUCTION:
      let dins = { ...state.order, designer_instruction: action.payload };
      return {
        ...state,
        order: dins,
      };
    case OrderActions.TAX_SET:
      let orderTaxid = {
        ...state.order,
        tax_id: action.payload.toString(),
        tax: 0,
      };
      let orderTransactionTaxid = {
        ...state.order_transaction,
        tax_id: action.payload.toString(),
        tax: 0,
      };

      return {
        ...state,
        order: orderTaxid,
        order_transaction: orderTransactionTaxid,
      };
    case OrderActions.SET_ADD_FUND:
      let addFundAmount = {
        ...state.order,
        add_fund: action.payload,
        refund: 0,
      };
      return { ...state, order: addFundAmount };
    case OrderActions.SET_REFUND:
      let refundamount = {
        ...state.order,
        refund: action.payload,
        add_fund: 0,
      };
      return { ...state, order: refundamount };
    case OrderActions.UPDATE_DRIVER_INSTRUCTION:
      let driverins = { ...state.order, driver_instruction: action.payload };
      return {
        ...state,
        order: driverins,
      };
    case OrderActions.UPDATE_DATE_TIME_INSTRUCTION:
      let dateTime = { ...state.order, ...action.payload };
      return {
        ...state,
        order: dateTime,
      };
    case OrderActions.SET_CARD_DETAIL:
      let paymentTransactionDetail = [...state.order_payment_transaction];
      paymentTransactionDetail[0] = {
        ...paymentTransactionDetail[0],
        ...action.payload,
      };

      return {
        ...state,
        order_payment_transaction: paymentTransactionDetail,
      };
    case OrderActions.UPDATE_INTERNAL_NOTES:
      let inotes = { ...state.order, internal_notes: action.payload };
      return {
        ...state,
        order: inotes,
      };
    case OrderActions.GET_ORDER_DETAILS:
      let newOrderState = EditOrderState(action.payload, state);
      // console.log("newOrderState", newOrderState);
      return newOrderState;

    case OrderActions.CLR_DATA_TEST:
      return clrState;
    case OrderActions.SET_PATEL_REWARDS:
      return {
        ...state,
        petalrewardlist: action.payload,
      };
    case OrderActions.SET_BLOCKOUT_DATE:
      let dates = [...action.payload];
      if (state.blockoutdate && state.blockoutdate?.length > 0) {
        dates = [...state.blockoutdate, ...dates];
      }
      return {
        ...state,
        blockoutdate: dates,
      };
    case OrderActions.SET_UP_CHGARGE:
      return {
        ...state,
        upCharge: action.payload,
      };
    case OrderActions.DELETE_RECIPENT_ID:
      let deletRecipentId = [...state?.order?.deleted_recipient_id];
      deletRecipentId.push(action.payload?.toString());
      return {
        ...state,
        deleted_recipient_id: deletRecipentId,
      };
    case OrderActions.DELETE_PRODUCT_ID:
      let deleteArray = [...state.recipents];
      if (deleteArray[state.activeRecipentIndex]?.form?.id) {
        deleteArray[state.activeRecipentIndex].deleted_recipient_product_id =
          deleteArray[state.activeRecipentIndex].deleted_recipient_product_id ||
          [];
        deleteArray[
          state.activeRecipentIndex
        ]?.deleted_recipient_product_id.push(action.payload?.toString());
        return {
          ...state,
          recipents: deleteArray,
        };
      } else {
        return state;
      }
    case OrderActions.DELETE_ADDON_ID:
      let deleteArrayAddon = [...state.recipents];
      deleteArrayAddon[
        state.activeRecipentIndex
      ]?.deleted_recipient_product_id.push(action.payload?.toString());
      return {
        ...state,
        recipents: deleteArrayAddon,
      };
    case OrderActions.SET_CARD_DATA_LIST: {
      return {
        ...state,
        order_card_list: action.payload,
      };
    }
    case OrderActions.SET_VERIFIED: {
      return {
        ...state,
        isVerifiedCustomer: !state.isVerifiedCustomer,
      };
    }
    case OrderActions.SET_CUSTOMER_DETAILS:
      let cardBllingdata = { ...state.billing_data, ...action.payload };
      return {
        ...state,
        billing_data: cardBllingdata,
      };
    case OrderActions.CLEAR_CUSTOMER_DATA:
      let order_payment_transactionData = [...state.order_payment_transaction];
      order_payment_transactionData[0].card_id = 0;
      order_payment_transactionData[0].card_token = "";
      let order_transactionData = {
        ...state.order_transaction,
        customer_card_id: 0,
      };

      return {
        ...state,
        order_card_list: [],
        order_transaction: order_transactionData,
        order_payment_transaction: order_payment_transactionData,
      };
    case OrderActions.SET_CARD_DEFALT:
      return {
        ...state,
        order_card_list: action.payload,
      };
    case OrderActions.OVERRIDE_ORDER_NOTE:
      let note = { ...state.order, internal_notes: action.payload };
      return {
        ...state,
        order: note,
      };
    case OrderActions.CLRINTERNALNOTE:
      let removenote = { ...state.order, internal_notes: "" };
      return {
        ...state,
        order: removenote,
      };
    case OrderActions.SET_DISSCOUNT_DETAILS:
      let orderDisscount = {
        ...state.order,
        discount_percentage: action.payload.discount_percentage,
        discount_price: action.payload.discount_price,
        custom_reason_for_discount: action.payload.custom_reason_for_discount,
      };
      let orderTransaction = {
        ...state.order_transaction,
        discount_price: action.payload.discount_price,
      };
      return {
        ...state,
        order: orderDisscount,
        order_transaction: orderTransaction,
      };
    case OrderActions.SET_FERQUENCY_LIST:
      return {
        ...state,
        frequencyList: action.payload,
      };
    case OrderActions.SET_CUSTOMER_DETAILS_INFO:
      let customerDataSet = { ...state.customer_info, ...action.payload };
      return {
        ...state,
        customer_info: customerDataSet,
      };
    case OrderActions.SET_PAYMENT_TYPE:
      return {
        ...state,
        paymetTypeSelect: action.payload,
      };
    // case OrderActions.CHECK_ADDFUND_REFUND:
    //   let checkfund = checkFundchange(state);
    //   return {
    //     ...state,
    //   };
    case OrderActions.CLEAR_DELIVERY_DATA:
      return {
        ...state,
        leadTime: action.payload,
      };
    case OrderActions.CHECK_CUSTOMER_INFORMATION:
      return {
        ...state,
        customererror: action.payload,
      };
    case OrderActions.CHECK_DELIVERY_INFORMATION:
      return {
        ...state,
        deliveryMatrixFilled: action.payload,
      };
    case OrderActions.CHECK_RECIPENT_INFORMATION:
      return {
        ...state,
        recipentsError: action.payload,
      };
    case OrderActions.CHECK_COMMAN_RECIPENT_INFORMATION:
      return {
        ...state,
        recipentscommanError: action.payload,
      };
    case OrderActions.CAHECK_CARD_COMPLETION_STATUS:
      return {
        ...state,
        orderCardCompletionStatus: action.payload,
      };
    case OrderActions.SET_CARD_SIGNATURE:
      let updateCardSignature = state.recipents ? [...state.recipents] : [];
      updateCardSignature[state.activeRecipentIndex].form = {
        ...updateCardSignature[state.activeRecipentIndex].form,
        card_signature: action.payload,
      };
      return {
        ...state,
        recipents: updateCardSignature,
      };
    case OrderActions.CLR_DATA_TEST_TIME:
      let clrDateAndTime = {
        ...state.order,
        delivery_date: "",
        delivered_time_id: null,
        delivered_time: "",
        dateKeyIndex: null,
        delivery_charges: 0,
        actual_delivered_time: "",
      };
      return {
        ...state,
        order: clrDateAndTime,
      };
    case OrderActions.BILLING_ADDRESS_CHECK:
      return {
        ...state,
        biilingAddressError: action.payload,
      };
    case OrderActions.UPDATE_PRODUCT_CATAGORY:
      let productid = action.payload.product_id;
      let productCatagies = action.payload.product_categories;
      let newrecipent = state.recipents.map((recipient) => {
        let addedProductsList = recipient.added_product.map((product) => {
          if (product.product_id === productid) {
            product.product_categories = productCatagies;
          }
          return product;
        });
        return {
          ...recipient,
          added_product: addedProductsList,
        };
      });
      return {
        ...state,
        recipents: newrecipent,
      };

    case OrderActions.CLEAR_ADDON:
      let clearedAddonsRecipent = [...state.recipents];
      clearedAddonsRecipent[state.activeRecipentIndex].addedAddons = [];
      return {
        ...state,
        recipents: clearedAddonsRecipent,
      };

    case OrderActions.DUPLICATE_ORDER:
      let nos = duplicate(action.payload, state, "Duplicate");
      return nos;

    case OrderActions.SET_CARD:
      return {
        ...state,
        currentSelectedCard: action.payload,
      };
    case OrderActions.RESET:
      let o = {
        ...orderState,
        recipents: [
          {
            form: {
              recipients_id: 0,
              whats_the_occasion: "",
              whats_the_occasion_id: null,
              first_name: "",
              last_name: "",
              greetings_on_card: "",
              card_signature: "",
            },
            added_product: [],
            addedAddons: [],
          },
        ],
        billing_data: {
          customer_billing_first_name: "",
          customer_billing_last_name: "",
          customer_billing_address: "",
          customer_billing_city: "",
          customer_billing_state: "",
          customer_billing_country: "United States",
          customer_billing_zipcode: "",
          customer_id: 0,
          completed: false,
        },
      };
      console.log("RESET CALLED", o);

      return o;

    case OrderActions.SET_EDIT_MODE:
      return {
        ...orderState,
        editMode: action.payload,
      };
    case OrderActions.REPLACE_ORDER:
      let repl = duplicate(action.payload, state, "Replace");
      return {
        ...repl,
        order_type_identifier: 1,
      };

    case OrderActions.CHANGE_ORDER_TYPE:
      let r = state.recipents.map((c) => {
        return {
          ...c,
          added_product: [],
        };
      });

      return {
        ...state,
        custom_order: state.custom_order === 0 ? 1 : 0,
        recipents: r,
      };

    case OrderActions.SET_ORDER_INTIAL_CONFIG:
      const { petalConfig, frequencyList, holidayCutOff, uplist, bllist } =
        action.payload;
      return {
        ...state,
        petalrewardlist: petalConfig,
        frequencyList: frequencyList,
        holidayCutOff: holidayCutOff,
        upCharge: uplist,
        blockoutdate: bllist,
      };
    case OrderActions.CLEAR_DELIVERY_DATE_TIME:
      let st = { ...state };
      st.order.delivery_date_time = "";
      return st;
    default:
      return state;
  }
}

export default orderReducer;
