import React from "react";
import InternalNoteAccordion from "./InternalNoteAccordion";

const InternalNoteView = (props) => {
  return (
    <div>
      {props.data?.map((d, i) => {
        return <InternalNoteAccordion data={d} key={i} panel={i.toString()} />;
      })}
    </div>
  );
};

export default InternalNoteView;
