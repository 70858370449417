import { Button, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import React, { useEffect, useState } from "react";
// SCSS
import styles from "./cancelNote.module.scss";
import CheckBox from "../../../../Components/FormControl/CheckBox/CheckBox";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalWithNote = ({
  showModal,
  pageTitle,
  handleCancelClick,
  handleSubmitClick,
  placeholder,
  totalRefundAmount = 0,
}) => {
  const [addnote, setAddNote] = useState("");
  const [checkBox, setCheckBox] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0);
  const [refundAmountInitial, setRefundAmountInitial] = useState(0);
  const [error, setErrorMessage] = useState("");
  const [errorAmount, setErrorMessageAmount] = useState("");

  useEffect(() => {
    if (totalRefundAmount) {
      // setRefundAmount(totalRefundAmount);
      setRefundAmountInitial(totalRefundAmount);
    }
  }, [totalRefundAmount]);

  const submit = (e) => {
    if (addnote === "") {
      setErrorMessage("Please add note");
      if (
        pageTitle === "Cancel Order" &&
        parseFloat(refundAmount) > parseFloat(refundAmountInitial)
      ) {
        setErrorMessageAmount(
          "You can not refund amount more than remaning fund"
        );
      }
    } else {
      setErrorMessage("");
      setErrorMessageAmount("");
      handleSubmitClick(addnote, refundAmount, checkBox);
    }
  };
  // const { orderState } = useOrder();
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="row">
              <div
                className={`col-md-6 col-xs-12 mt-5 ${
                  pageTitle === "Cancel Order"
                    ? styles.marginAutoCustom
                    : styles.marginAuto
                }`}
              >
                <TextField
                  className="w-100"
                  label={placeholder ? placeholder : "Additonal Notes"}
                  value={addnote}
                  onChange={(e) => {
                    setErrorMessage("");
                    setAddNote(e.target.value);
                  }}
                  error={Boolean(error)}
                  helperText={error}
                />
              </div>
            </div>
            {pageTitle === "Cancel Order" && (
              <div className="row">
                <div
                  className={`col-md-6 col-xs-12 mr-5 mt-5 ${styles.marginAutoCustom}`}
                >
                  <TextField
                    className="w-100"
                    label={"Refund Amount"}
                    value={refundAmount}
                    onChange={(e) => {
                      setErrorMessageAmount("");
                      setRefundAmount(e.target.value);
                    }}
                    error={Boolean(errorAmount)}
                    helperText={errorAmount}
                    disabled={checkBox ? true : false}
                  />
                </div>
                <div className={`col-md-3 col-xs-12 mt-5 mr-5`}>
                  <CheckBox
                    label="Full Refund"
                    value={checkBox}
                    onChange={(e) => {
                      setCheckBox(e);
                      setErrorMessageAmount("");
                      setRefundAmount(refundAmountInitial);
                    }}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12 my-3">
                <Button
                  onClick={submit}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ModalWithNote;
