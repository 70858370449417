import React, { useContext, useEffect, useState } from "react";
import Header from "../../../Components/Header/Header";
import Text from "../../../Components/FormControl/Text/Text";
// Context
import { AppContext } from "./../../../Context/AppContext";
import { getAccessByModuleName } from "./../../../Routes/Routes";
import { MODULES } from "./../../../Helpers/Constants";
// Services
// import SettingsApi from "./../../../Services/Settings/Settings";
import { update, getByConfigurationKey } from "./../../../api/settings";
import _ from "lodash";

// // Checking for Integer
// console.log("The Value is Integer : "
//         + _.isInteger(100));

const DesignerSetting = (props) => {
  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);

  const [maxAllocation, setMaxAllocation] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [access, setAccess] = useState({});

  // const settingsService = new SettingsApi();

  useEffect(() => {
    handleGetDesignerSetting();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  const onChange = (type, data) => {
    if (type === "maxAllocation") setMaxAllocation(data);
  };

  function getModuleAccess() {
    let access = getAccessByModuleName(
      modules,
      MODULES.CONFIG_DESIGNER_SETTING
    );
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      window.location.href = "/dashboard";
    }
  }

  const handleGetDesignerSetting = async () => {
    setIsLoading(true);
    //     let response = await settingsService.getByConfigurationKey(
    //       "designerSetting"
    //     );
    let response = await getByConfigurationKey("designer_max_task_allocation");

    if (response && response.status === 200) {
      setMaxAllocation(response.data.data.value);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handleSubmitClick = async (event) => {
    let val = Number(maxAllocation);
    if (maxAllocation && maxAllocation <= 0) {
      showToastMessage(
        undefined,
        "Please enter valid positive number greater than 0",
        "error",
        false
      );
      event.preventDefault();
    } else if (maxAllocation && _.isInteger(val) == false) {
      showToastMessage(
        undefined,
        "Please enter valid integer number",
        "error",
        false
      );
      event.preventDefault();
    } else {
      event.preventDefault();
      setSubmitStatus(true);
      if (maxAllocation) {
        setIsLoading(true);
        // let obj = { max_allocation: maxAllocation };
        let requestBody = {
          key: "designer_max_task_allocation",
          value: maxAllocation,
        };

        //   let response = await settingsService.update(requestBody);
        let response = await update(requestBody);

        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          setSubmitStatus(false);
          handleGetDesignerSetting();
        } else {
          showToastMessage(undefined, response.data.message, "error", false);
        }
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <Header title="Designer Setting" />
      <div className="row mt-4 mb-4">
        <div className={`col-md-8`}>
          <Text
            type="number"
            label="Max Allocation"
            value={maxAllocation}
            required={true}
            submitStatus={submitStatus}
            id="max_allocaton"
            onChange={(data) => onChange("maxAllocation", data)}
          />
        </div>
      </div>
      {access.write_access && (
        <a
          href="/"
          data-dismiss="modal"
          onClick={handleSubmitClick}
          className="btn gray-btn d-inline-block mb-3 mr-2"
        >
          {" "}
          Save{" "}
        </a>
      )}
    </div>
  );
};
export default DesignerSetting;
