import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  list: {
    padding: "0.8rem 0.3rem",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(203,153,141,0.5)",
    },
  },
});

const Dropdown = ({ recipientList, showDropDown, onItemClick }) => {
  const classes = useStyles();
  return (
    <ul
      className={showDropDown && recipientList.length !== 0 ? "" : "d-none"}
      style={{
        maxHeight: "300px",
        width: "100%",
        boxShadow: "1px 1px 4px #ccc",
        position: "absolute",
        left: "0",
        top: "100%",
        backgroundColor: "#fff",
        zIndex: 10,
        padding: 0,
      }}
    >
      {recipientList?.map((rec) => (
        <li
          key={rec.id}
          onClick={() => onItemClick(rec)}
          className={classes.list}
          style={{ fontSize: "18px" }}
        >
          {rec.first_name + " " + rec.last_name}
        </li>
      ))}
    </ul>
  );
};

export default Dropdown;
