import React from "react";
import { Bar } from "react-chartjs-2";
import ChartHearding from "./ChartHeading";
const Commission = ({ commissionData }) => {
  const data = {
    labels: commissionData.label,
    datasets: [
      {
        label: "Commission($)",
        data: commissionData.data,
        backgroundColor: "#E4E0E5",
        borderColor: "#E4E0E5",
        borderWidth: 1,
      },
    ],
  };

  // const options = {
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //         },
  //       },
  //     ],
  //   },
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //   },
  // };

  const optionsData = {
    scales: {
      y: {
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value) {
            return "$" + value;
          },
        },
      },
    },
  };

  return (
    <>
      <div className="header">
        <ChartHearding heading="Commission" />
      </div>
      <Bar
        data={data}
        options={optionsData}
        height={160}
        style={{ backgroundColor: "#FAFAFB", padding: "20px" }}
      />
    </>
  );
};

export default Commission;
