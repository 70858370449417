import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";

import CardContent from "@material-ui/core/CardContent";
// import Seo from "../../../Components/Cms/Seo";
// import OGInfo from "../../../Components/Cms/OgInfo";
// import Twitterinfo from "../../../Components/Cms/TwitterInfo";
// import Editer from "../../../Components/Cms/Schema";
import Header from "../../../Components/Header/Header";
import Banner from "../../../Components/Cms/Banner";
import Goto from "../../../Components/Cms/Goto";
import classes from "./index.module.scss";
// import BottomBanner from "./../../../Components/Cms/BottomBanner";
import RelatedCategory from "../../../Components/Cms/RelatedCategory";
import Text from "../../../Components/FormControl/Text/Text";
import TextArea from "../../../Components/FormControl/TextArea/TextArea";
import Select from "../../../Components/FormControl/Select/Select";
import WYSIWIGEditor from "../../../Components/WYSIWIGEditor/WYSIWIGEditor";
// Context
import { getAccessByModuleName } from "../../../Routes/Routes";
import { AppContext } from "../../../Context/AppContext";
import { LOCATION_TYPE, MODULES, STORE_LOCATION_SLUG_ARRAY } from "../../../Helpers/Constants";
import { useLocation } from "../../../Context/Cms-Locations/useLocation";
import { getCategoryHierarchyList } from "api/category";
import {
  getCmsLocationsDetail,
  updateCmsLocations,
  createCmsLocations,
  getCmsLocationsRawDetail,
} from "api/cmsLocation";
import { postReqLocation } from "Hooks/locationDataApiCall";
import Seo from "Components/Cms/Seo";
import Oginfo from "Components/Cms/OgInfo";
import TwitterInfo from "Components/Cms/TwitterInfo";

const AddLocations = (props) => {
  const { showToastMessage, modules, setIsLoading } = useContext(AppContext);
  const [categoryList, setCategoryList] = useState([]);
  const [updateLocationId, setUpdateLocationId] = useState();
  const [parentLocationId, setParentLocationId] = useState();

  const gotoList = [
    { id: "primary-information", title: "Primary Information" },
    { id: "banner-section", title: "Banner Style" },
    { id: "related-category", title: "Related Category" },
    // { id: "ralated-product", title: "Ralated Product" },
    // { id: "bottom-banner", title: "Bottom Banner" },
    // { id: "seo-section", title: "SEO" },
  ];

  useEffect(() => {
    clearLocationState();
    handleGetCategory();

    const { updateId, parentId } = props.match.params;
    setUpdateLocationId(updateId);
    setParentLocationId(parentId);
  }, []);

  useEffect(() => {
    if (updateLocationId) getLocationDataById(updateLocationId);
  }, [updateLocationId]);

  useEffect(() => {
    if (parentLocationId) getLocationRawData(parentLocationId);
  }, [parentLocationId]);

  const {
    locationState,
    addNewBanner,
    removeBanner,
    setBannerData,
    checkBannerData,
    setLocationBannerData,
    setLocationPrimaryData,
    setExistingRelatedCategoryData,
    clearLocationState,
    setSeoData,
    checkSeoData,
    setOgInfoData,
    checkOgInfoData,
    setTwitterInfoData,
    checkTwitterInfoData,
    setSchemaData,
  } = useLocation();

  // const [value, setValue] = useState();
  const [access, setAccess] = useState({});

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  // Access Contorol functionm call
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.LOCATIONS);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.write_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  //get category details
  const handleGetCategory = async () => {
    setIsLoading(true);
    // const response = await categoryService.getCategoryHierarchyList();
    const response = await getCategoryHierarchyList();

    if (response && response.status === 200) {
      if (response.data.data && response.data.data.length) {
        let tempArr = [];
        response.data.data.forEach((element) => {
          let tempArrCat = {
            category_hierarchy_id: element.category_hierarchy_id,
            id: element.category_hierarchy_id,
            title: element.title,
            categoryTitle: element.categoryTitle,
          };
          tempArr.push(tempArrCat);
        });
        setCategoryList(tempArr);
      }
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const getLocationDataById = async (id) => {
    setIsLoading(true);
    const response = await getCmsLocationsDetail(id);
    if (response && response.status === 200) {
      if (response.data.data) {
        let tempResponseData = response.data.data;

        let locationStateData = { ...locationState.primary_info };
        setLocationPrimaryData({
          ...locationStateData,
          name: tempResponseData.name,
          type: tempResponseData.type,
          parent_id: tempResponseData.parent_id ?? null,
          main_parent_id: tempResponseData.main_parent_id ?? null,
          service_offered: tempResponseData.service_offered,
          need_opening_hour: tempResponseData.need_opening_hour,
          deliver_throughout_description: tempResponseData.deliver_throughout_description,
          your_local_florist_description: tempResponseData.your_local_florist_description,
          sequence_number: tempResponseData.sequence_number ?? 0,
          slug: tempResponseData.slug,
          blurb: tempResponseData.blurb,
          description: tempResponseData.description,
          status: tempResponseData.status,
        });

        tempResponseData.banners?.forEach((d, i) => {
          tempResponseData.banners[i]["module"] = "locations";
          setLocationBannerData(tempResponseData.banners[i]);
        });

        tempResponseData.cms_location_categories?.forEach((d, i) => {
          delete tempResponseData.cms_location_categories[i].category_hierarchy;
          delete tempResponseData.cms_location_categories[i].cms_location_id;
          delete tempResponseData.cms_location_categories[i].createdAt;
          delete tempResponseData.cms_location_categories[i].updatedAt;
          let tempArrCat = {
            category_hierarchy_id:
              tempResponseData.cms_location_categories[i]
                .category_hierarchy_id ?? null,
            seo: tempResponseData.cms_location_categories[i].seo ?? [],
          };
          setExistingRelatedCategoryData(tempArrCat);
        });

        setSeoData(tempResponseData.seo);
        setOgInfoData(tempResponseData.seo);
        setTwitterInfoData(tempResponseData.seo);
        setSchemaData(tempResponseData.seo);
      }
    } else {
      setIsLoading(false);
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const getLocationRawData = async (id) => {
    setIsLoading(true);
    const response = await getCmsLocationsRawDetail(id);
    if (response && response.status === 200) {
      if (response.data.data) {
        let tempResponseData = response.data.data;

        let locationStateData = { ...locationState.primary_info };
        setLocationPrimaryData({
          ...locationStateData,
          parent_id: tempResponseData.id ?? 0,
          main_parent_id:
            tempResponseData.main_parent_id ?? tempResponseData.id ?? 0,
        });
      }
    } else {
      setIsLoading(false);
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handleSubmitClick = async (event) => {
    // console.log("locationState=>", locationState);
    event.preventDefault();

    let showBannerError = false;
    locationState.banners.forEach((bannerData, index) => {
      const bannerSubmit = document.getElementById(
        `banner-submit-button-${index}`
      );
      if (bannerSubmit) {
        bannerSubmit.click();
      }
      if (!bannerData.image) {
        showBannerError = true;
      }
    });
    let locationApiCall = true;
    let validationMessage = "";
    if (locationState.banners.length > 0 && showBannerError) {
      locationApiCall = false;
      validationMessage = "Please enter required banner details";
    } else if (
      !locationState.primary_info.name ||
      locationState.primary_info.name?.trim() === ""
    ) {
      locationApiCall = false;
      validationMessage = "Please enter location name";
    } else if (
      !locationState.primary_info.type ||
      locationState.primary_info.type?.trim() === ""
    ) {
      locationApiCall = false;
      validationMessage = "Please select location type";
    } else if (
      !locationState.primary_info.slug ||
      locationState.primary_info.slug?.trim() === ""
    ) {
      locationApiCall = false;
      validationMessage = "Please enter location slug";
    } else if (locationState.cms_location_categories.length === 0) {
      locationApiCall = false;
      validationMessage = "Please enter minimum one related category";
    } else if (
      locationState.cms_location_categories.length > 0 &&
      locationState.cms_location_categories[0].category_hierarchy_id === null
    ) {
      locationApiCall = false;
      validationMessage = "Please enter minimum one related category";
    }

    if (locationApiCall) {
      setIsLoading(true);
      const post_req = postReqLocation(locationState);
      // console.log(post_req);
      if (updateLocationId !== "" && updateLocationId !== undefined) {
        let response = await updateCmsLocations(post_req, updateLocationId);
        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          clearLocationState();
          setIsLoading(false);
          props.history.push("/cms/locations");
        } else {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "error", false);
        }
      } else {
        let response = await createCmsLocations(post_req);
        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          clearLocationState();
          setIsLoading(false);
          props.history.push("/cms/locations");
        } else {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "error", false);
        }
      }
    } else {
      showToastMessage(undefined, validationMessage, "error", false);
    }
  };

  // console.log("locationState=>", locationState);
  return (
    <React.Fragment>
      <Header title="CMS - Locations Page" />
      <div className="row">
        <div id="MainContent" className="col-8">
          <div className={`${classes.cardBody}`}>
            <CardContent>
              <div className={`row`} id={`primary-information`}>
                <div className={`col-md-6 col-xs-12`}>
                  <Text
                    type="text"
                    label="Location Name"
                    value={locationState.primary_info.name}
                    required={true}
                    onChange={(data) => {
                      let locationStateData = { ...locationState.primary_info };
                      setLocationPrimaryData({
                        ...locationStateData,
                        name: data,
                      });
                    }}
                    id="name"
                  />
                </div>
                <div className={`col-md-6 col-xs-12`}>
                  <Select
                    label="Type"
                    parentClass="text-center"
                    value={locationState.primary_info.type}
                    required={false}
                    onChange={(data) => {
                      let locationStateData = { ...locationState.primary_info };
                      setLocationPrimaryData({
                        ...locationStateData,
                        type: data,
                      });
                    }}
                    options={LOCATION_TYPE}
                    disabled={false}
                    id="state"
                  />
                </div>

                <div className={`col-md-6 col-xs-12`}>
                  <Text
                    type="text"
                    label="Sequence Number"
                    value={locationState.primary_info.sequence_number.toString()}
                    required={true}
                    onChange={(data) => {
                      let locationStateData = { ...locationState.primary_info };
                      if (!/^[0-9]*$/.test(data)) {
                        return;
                      }
                      if (data.length > 10) {
                        return;
                      }
                      let val = data === "" ? "" : (+data).toString() || "";

                      setLocationPrimaryData({
                        ...locationStateData,
                        sequence_number: val,
                      });
                    }}
                    id="sequence_number"
                  />
                </div>
                {locationState.primary_info.status === 1 ? (
                  <div className={`cursor-pointer col-md-6 col-xs-12`}>
                    <label>Status</label>
                    <div className={classes.status}>
                      <div
                        className={classes.Active}
                        style={{ "font-weight": "bold" }}
                      >
                        Active
                      </div>
                      <div
                        className={classes.Inactive}
                        onClick={(data) => {
                          let locationStateData = {
                            ...locationState.primary_info,
                          };
                          setLocationPrimaryData({
                            ...locationStateData,
                            status: 0,
                          });
                        }}
                      >
                        Inactive
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={`cursor-pointer col-md-6 col-xs-12`}>
                    <label>Status</label>
                    <div className={classes.status}>
                      <div
                        className={classes.Active}
                        onClick={(data) => {
                          let locationStateData = {
                            ...locationState.primary_info,
                          };
                          setLocationPrimaryData({
                            ...locationStateData,
                            status: 1,
                          });
                        }}
                      >
                        Active
                      </div>

                      <div
                        className={classes.Inactive}
                        style={{ "font-weight": "bold" }}
                      >
                        Inactive
                      </div>
                    </div>
                  </div>
                )}
                <div className={`col-md-6 col-xs-12`}>
                  <Text
                    type="text"
                    label="Slug"
                    value={locationState.primary_info.slug}
                    required={true}
                    onChange={(data) => {
                      let locationStateData = { ...locationState.primary_info };
                      setLocationPrimaryData({
                        ...locationStateData,
                        slug: data,
                      });
                    }}
                    id="slug"
                  />
                </div>
                <div className={`col-md-6 col-xs-12`}>
                  <Text
                    type="text"
                    label="Blurb"
                    value={locationState.primary_info.blurb}
                    required={true}
                    onChange={(data) => {
                      let locationStateData = { ...locationState.primary_info };
                      setLocationPrimaryData({
                        ...locationStateData,
                        blurb: data,
                      });
                    }}
                    id="blurb"
                  />
                </div>

                <div className={`row`}>
                    <label className="bmd-label-floating"
                    style={{ marginLeft: "25px" }}>Description</label>
                    <div className={`col-md-12 col-xs-12`}>
                      <WYSIWIGEditor 
                        data={locationState.primary_info.description}
                        onChange={(data) => {
                          let locationStateData = { ...locationState.primary_info };
                          setLocationPrimaryData({
                            ...locationStateData,
                            description: data,
                          });
                        }}
                      />
                    </div>
                </div>
                {/* <div className={`col-md-12 col-xs-12`}>
                  <TextArea
                    type="text"
                    label="description"
                    rows={3}
                    value={locationState.primary_info.description}
                    required={true}
                    onChange={(data) => {
                      let locationStateData = { ...locationState.primary_info };
                      setLocationPrimaryData({
                        ...locationStateData,
                        description: data,
                      });
                    }}
                    id="description"
                  />
                </div> */}
                {/* <div className={`col-md-12 col-xs-12`}>
                  <TextArea
                    type="text"
                    label="Service Offered"
                    rows={3}
                    value={value}
                    required={true}
                    onChange={(data) => console.log("Hello")}
                    id="service_offered"
                  />
                </div> */}
                <div className={`row`}>
                  <label
                    className="bmd-label-floating"
                    style={{ marginLeft: "25px" }}
                  >
                    Service Offered
                  </label>
                  <div className={`col-md-12 col-xs-12`}>
                    <WYSIWIGEditor
                      data={locationState.primary_info.service_offered}
                      height="200"
                      onChange={(data) => {
                        let locationStateData = {
                          ...locationState.primary_info,
                        };
                        setLocationPrimaryData({
                          ...locationStateData,
                          service_offered: data,
                        });
                      }}
                    />
                  </div>
                </div>

                {/* <div className={`col-md-12 col-xs-12`}>
                  <TextArea
                    type="text"
                    label="Opening Hour"
                    rows={3}
                    value={value}
                    required={true}
                    onChange={(data) => console.log("Hello")}
                    id="opening-hour"
                  />
                </div> */}

                <div className={`row`} style={{ "margin-top": "20px" }}>
                  <label
                    className="bmd-label-floating"
                    style={{ marginLeft: "25px" }}
                  >
                    Opening Hour
                  </label>
                  <div className={`col-md-12 col-xs-12`}>
                    <WYSIWIGEditor
                      data={locationState.primary_info.need_opening_hour}
                      height="200"
                      onChange={(data) => {
                        let locationStateData = {
                          ...locationState.primary_info,
                        };
                        setLocationPrimaryData({
                          ...locationStateData,
                          need_opening_hour: data,
                        });
                      }}
                    />
                  </div>
                </div>
                {STORE_LOCATION_SLUG_ARRAY.includes(locationState.primary_info.slug) && (
                  <>
                    <div className={`row`} style={{ "margin-top": "20px" }}>
                      <label
                        className="bmd-label-floating"
                        style={{ marginLeft: "25px" }}
                      >
                        Deliver Throughout Description
                      </label>
                      <div className={`col-md-12 col-xs-12`}>
                        <WYSIWIGEditor
                          data={locationState.primary_info.deliver_throughout_description}
                          height="200"
                          onChange={(data) => {
                            let locationStateData = {
                              ...locationState.primary_info,
                            };
                            setLocationPrimaryData({
                              ...locationStateData,
                              deliver_throughout_description: data,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className={`row`} style={{ "margin-top": "20px" }}>
                      <label
                        className="bmd-label-floating"
                        style={{ marginLeft: "25px" }}
                      >
                        Your Local Florist Description
                      </label>
                      <div className={`col-md-12 col-xs-12`}>
                        <WYSIWIGEditor
                          data={locationState.primary_info.your_local_florist_description}
                          height="200"
                          onChange={(data) => {
                            let locationStateData = {
                              ...locationState.primary_info,
                            };
                            setLocationPrimaryData({
                              ...locationStateData,
                              your_local_florist_description: data,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <Banner
                bannerModule="blogs"
                bannersData={locationState.banners}
                addNewBanner={addNewBanner}
                removeBanner={removeBanner}
                setBannerData={setBannerData}
                checkBannerData={checkBannerData}
                uploadImageType="LOCATIONS_BANNER"
              />
              <RelatedCategory categoryList={categoryList} />
              {/* <BottomBanner /> */}
              {/* <Seo />
              <OGInfo />
              <Twitterinfo /> */}
              {/* <Editer /> */}
              <Seo
                data={locationState.seo}
                setSeoData={setSeoData}
                checkSeoData={checkSeoData}
              />
              <Oginfo
                data={locationState.seo}
                setOgInfoData={setOgInfoData}
                checkOgInfoData={checkOgInfoData}
              />
              <TwitterInfo
                data={locationState.seo}
                setTwitterInfoData={setTwitterInfoData}
                checkTwitterInfoData={checkTwitterInfoData}
              />
              {/* <Editer 
                data={locationState.seo}
                setSchemaData={setSchemaData}
                checkSchemaData={checkSchemaData}
              /> */}
            </CardContent>
          </div>
        </div>
        <div className="col-4">
          <Goto gotoList={gotoList} handleSubmitClick={handleSubmitClick} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AddLocations);
