import React from "react";
import AddBlackIcon from "./../../../Assets/images/add-black.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";
import styles from "./index.module.scss";

const ImageGallery = (props) => {
    const ImageRef = React.useRef();
   
  return (
    <div>
      <div className="form-group bmd-form-group is-filled text-left">
        <label className="bmd-label-floating">Recipe Image</label>
        <div
          onClick={() => {
            ImageRef.current.click();
          }}
          className={styles.uploadBox}
        >
          <input
            type="file"
            className="d-none"
            ref={ImageRef}
            accept="image/*"
            onChange={props.onclickHandler}
          />

          {props.image_url ? (
            <img
              className="img-fluid"
              style={{
                height: "100%",
                objectFit: "cover",
              }}
              src={`${props.image_url}?w=100&q=75&auto=compress,format`}
              alt="recipe image"
            />
          ) : (
            <img src={AddBlackIcon} alt="recipe image" />
          )}
        </div>
        {props.image_url ? (
          <img
            onClick={props.deleteHandler}
            src={DeleteIcon}
            style={{
              position: "absolute",
              top: "30px",
              left: "125px",
              cursor: "pointer",
            }}
            width="14px"
            height="14px"
            alt="DELETE"
          />
        ) : (
          ""
        )}
        {props.error ? (
          <p class="invalid-feedback d-block">
            {props.error}
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ImageGallery