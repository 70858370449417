import React, { useState } from "react";
// SCSS
import styles from "./index.module.scss";

const Text = ({
  label,
  type,
  value,
  submitStatus,
  required,
  disabled,
  onChange,
  onBlur,
  touched,
  onFocus,
  id,
  error,
  onKeyDown,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [defaultValue, setDefaultValue] = useState(value);
  return (
    <div
      className={`form-group bmd-form-group ${isFocused ? "is-focused" : ""} ${
        value ? "is-filled" : ""
      } ${submitStatus && required && !value ? styles.errorInput : ""}`}
    >
      <label className={`${styles.bmdLabelFloating} bmd-label-floating`}>
        {label}
      </label>
      <input
        id={id ?? ""}
        type={type}
        value={value}
        disabled={disabled}
        className="form-control"
        onFocus={() => {
          setIsFocused(true);
        }}
        // onBlur={() => {
        //   onBlur();
        //   setIsFocused(false);
        // }}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onChange={(e) => {
          setDefaultValue(e.target.value);
          onChange(e.target.value);
        }}
      />
      {error && touched && <p className="invalid-feedback d-block">{error}</p>}
    </div>
  );
};

export default Text;
