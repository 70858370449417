import moment from "moment";
import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router";
//ICONS
import ActivatedIcon from "./../../../Assets/images/activated.svg";
import Header from "../../../Components/Header/Header";
import PlusIcon from "./../../../Assets/images/plus_1.svg";
import CloseIcon from "./../../../Assets/images/minus_1.svg";
import SearchIcon from "../../../Assets/images/search.svg";
import CloseIconNew from "../../../Assets/images/close.svg";
// CONTEXT
import { AppContext } from "../../../Context/AppContext";
import { useHistory } from "react-router-dom";
import { MODULES } from "./../../../Helpers/Constants";
import { getAccessByModuleName } from "./../../../Routes/Routes";
//API
import { getCmsMenuList } from "api/cmsMenu";
import { updateStatus } from "../../../api/utils";
//CSS
import styles from "./index.module.scss";
import "react-nestable/dist/styles/index.css";
//COMPONENT

import StatusConfirmation from "../../../Components/Modal/StatusConfirmation/StatusConfirmation";
import Nestable from "react-nestable";
import Select from "../../../Components/FormControl/Select/Select";

// Details Image
import ViewProductList from "./../../../Assets/images/View_product_list_1.svg";

const MenuManagement = (props) => {
  const history = useHistory();
  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);

  const [data, setData] = React.useState([]);

  const [access, setAccess] = useState({});
  const [statusMenuId, setStatusMenuId] = useState(false);
  const [statusOfMenu, setStatusOfMenu] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);

  const [menuForFilter, setMenuForFilter] = React.useState("web");
  const menuFilterData = [
    { id: "web", title: "Web" },
    { id: "mobile", title: "Mobile" },
    { id: "admin", title: "Admin" },
  ];

  const [menuPosition, setMenuPosition] = React.useState("TOP");
  const menuPositionData = [
    { id: "TOP", title: "Top" },
    { id: "LEFT", title: "Left" },
    { id: "RIGHT", title: "Right" },
    { id: "LEFT-BOTTOM", title: "Left Bottom" },
    { id: "BOTTOM", title: "Bottom" },
    { id: "CENTER", title: "Center" },
  ];

  useEffect(getModuleAccess, [modules]);
  useEffect(() => {
    fetchData(defaultFilter);
  }, []);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.MENU);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/users");
    }
  }

  const [defaultFilter, setDefaultFilter] = useState({
    menuFor: "web",
    position: "TOP",
  });

  const fetchData = async (filterData) => {
    setIsLoading(true);
    let postData;
    if (filterData && filterData !== undefined) {
      postData = {
        menuFor: filterData.menuFor ?? menuForFilter,
        position: filterData.position ?? menuPosition,
      };
    } else {
      postData = {
        menuFor: menuForFilter,
        position: menuPosition,
      };
    }

    const response = await getCmsMenuList(postData);
    if (response && response.status === 200) {
      setData(response.data.data);
      // setPageCount(Math.ceil(response.data.data.count / pageSize));
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handleStatusMenuClick = async (event, id, status) => {
    event.preventDefault();

    setStatusMenuId(id);
    setStatusOfMenu(status);
    setShowStatusModal(true);
  };

  const handleMenuStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfMenu === 1 ? 0 : 1,
      id: statusMenuId,
      table: "menu_masters",
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setStatusMenuId(null);
      setStatusOfMenu(null);
      setShowStatusModal(false);
      showToastMessage(undefined, response.data.message, "success", false);

      let tempFilter = {
        menuFor: menuForFilter ?? "web",
        position: menuPosition ?? "TOP",
      };

      fetchData(tempFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  const handleClearSearch = async (event) => {
    event.preventDefault();
    setMenuForFilter("web");
    setMenuPosition("TOP");

    fetchData(defaultFilter);
  };

  // Handle Menu/SubMenu Details Page
  const handleMenuSubMenuDetailsPage = (id) => {
    // console.log("id", id);
    history.push("/cms/menu/sequence/" + id);
  };

  const renderItem = ({ collapseIcon, depth, handler, index, item }) => (
    <div className={styles.GridTable}>
      <div
        className={
          "d-flex align-items-center justify-cotent-between cursor-pointer ml-3"
        }
      >
        {collapseIcon}
      </div>
      <div>{item.title}</div>
      <div>{item.module}</div>
      <div className={styles.status}>
        {item.status === 1 ? (
          <div className={styles.Active}>Active</div>
        ) : (
          <div className={styles.Inactive}>Inactive </div>
        )}
      </div>

      <div className={styles.CreatedRow}>
        {moment(item.createdAt).format("ddd MMM DD, YYYY")}
      </div>
      <div className={styles.UpdateRow}>
        {moment(item.updatedAt).format("ddd MMM DD, YYYY")}
      </div>
      <div>
        {access && access.write_access && (
          <img
            className={`cursor-pointer ml-3`}
            src={ActivatedIcon}
            alt="Active"
            onClick={(e) => handleStatusMenuClick(e, item.id, item.status)}
          />
        )}
        {access && access.write_access && item?.children && item?.children.length > 0 && (
          <span className={`link cursor-pointer ml-2`}>
            <img
              className={`cursor-pointer`}
              src={ViewProductList}
              style={{
                height: "38px",
              }}
              alt="Add"
              onClick={(e) => {
                e.preventDefault();
                handleMenuSubMenuDetailsPage(item.id);
              }}
            />
          </span>
        )}
      </div>
    </div>
  );

  const handlerStub = () => <span />;
  return (
    <React.Fragment>
      <Header title="CMS - Menu" />
      <div id="MainContent" className="main-content" />
      <div className="row mb-5">
        <div className="col-md-4">
          <Select
            label="Menu For"
            value={menuForFilter}
            options={menuFilterData}
            required={false}
            onChange={(data) => setMenuForFilter(data)}
            disabled={false}
          />
        </div>
        <div className="col-md-4">
          <Select
            label="Menu Position"
            value={menuPosition}
            options={menuPositionData}
            onChange={(data) => setMenuPosition(data)}
            disabled={false}
          />
        </div>
        <div className="col-sm-2 col-xs-12">
          <div className="td-actions pt-4">
            <span
              onClick={(e) => fetchData()}
              className={`link cursor-pointer ${styles.searchIconWrapper}`}
            >
              <img className={styles.searchIcon} src={SearchIcon} alt="" />
            </span>
            <span
              onClick={(e) => handleClearSearch(e)}
              className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
            >
              <img className={styles.clearIcon} src={CloseIconNew} alt="" />
            </span>
          </div>
        </div>
      </div>
      <div className={styles.GridTable}>
        <div className={styles.MoveButton}></div>
        <div>Name</div>
        <div>Module</div>
        <div>Status</div>
        <div>Created At</div>
        <div>Updated At</div>
        <div className={styles.Actions}></div>
      </div>
      {data.length !== 0 ? (
        <Nestable
          renderCollapseIcon={(props) => (
            <img
              className="cursor-pointer mr-2"
              style={{ width: "25px", height: "25px" }}
              src={props.isCollapsed ? PlusIcon : CloseIcon}
              alt="Collapse"
            />
          )}
          handler={handlerStub}
          className={styles.NestedGroup}
          items={data}
          renderItem={renderItem}
        />
      ) : (
        "No data found"
      )}

      {access.write_access && (
        <StatusConfirmation
          showModal={showStatusModal}
          title={"Menu"}
          message={"menu?"}
          buttonLabel={statusOfMenu ? "Inactivate" : "Activate"}
          statusFlag={statusOfMenu}
          customFlag={false}
          handleCancelClick={(event) => {
            event.preventDefault();
            setShowStatusModal(false);
            statusMenuId(null);
            statusOfMenu(null);
          }}
          handleSubmitClick={handleMenuStatus}
        />
      )}
    </React.Fragment>
  );
};

export default withRouter(MenuManagement);
