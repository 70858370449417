import Interceptors from "../Interceptors/Interceptors";

class Blogs {
  constructor() {
    this.interceptors = new Interceptors();
  }

  async getBlogsList(data) {
    return this.interceptors.POST("cms-blog/list", data);
  }

  async getBlogDetail(blogId) {
    return this.interceptors.GET("cms-blog/" + blogId);
  }

  async createBlog(postData) {
    return this.interceptors.POST("cms-blog", postData);
  }

  async updateBlog(postData) {
    return this.interceptors.PUT("cms-blog", postData);
  }

  async deleteBlog(id) {
    return this.interceptors.DELETE("cms-blog/" + id);
  }

  async getCategoryLookup() {
    return this.interceptors.GET("cms-blog/category/lookup");
  }

  async getAllBlogsList() {
    return this.interceptors.POST("cms-blog/blog-name-list");
  }

  async getBlogCategoryList(postData) {
    return this.interceptors.POST("cms-blog/category/list",postData);
  }

  async getBlogCategoryAdd(postData) {
    return this.interceptors.POST("cms-blog/category",postData);
  }

  async getBlogCategoryUpdate(postData) {
    return this.interceptors.PUT("cms-blog/category",postData);
  }

  async deleteBlogCategory(id) {
    return this.interceptors.DELETE("cms-blog/category/" + id);
  }

}

export default Blogs;
