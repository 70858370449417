import { callPostApi, callDeleteApi, callPutApi } from "./api";

export async function getRecipeRawMaterial(data) {
      // return this.interceptors.POST("product-recipe/raw-materials/list", data);
      try {
            const response = await callPostApi({ url: "product-recipe/raw-materials/list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function deleteRawMaterial(id) {
      // return this.interceptors.DELETE("product-recipe/raw-materials/" + id);
      try {
            const response = await callDeleteApi({ url: "product-recipe/raw-materials/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function recipeTypeList(data) {
      // return this.interceptors.POST("product-recipe/types/list", data);
      try {
            const response = await callPostApi({ url: "product-recipe/types/list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function addRawMaterial(data) {
      // return this.interceptors.POST("product-recipe/raw-materials", data);
      try {
            const response = await callPostApi({ url: "product-recipe/raw-materials", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function updateRawMaterial(id, data) {
      // return this.interceptors.PUT("product-recipe/raw-materials/" + id, data);
      try {
            const response = await callPutApi({ url: "product-recipe/raw-materials/" + id, body: data });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function addUnit(data) {
      // return this.interceptors.POST("product-recipe/units", data);
      try {
            const response = await callPostApi({ url: "product-recipe/units", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function unitList(data) {
      // return this.interceptors.POST("product-recipe/units/list", data);
      try {
            const response = await callPostApi({ url: "product-recipe/units/list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function unitDeleteData(id) {
      // return this.interceptors.DELETE("product-recipe/units/" + id);
      try {
            const response = await callDeleteApi({ url: "product-recipe/units/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function unitUpdate(id, data) {
      // return this.interceptors.PUT("product-recipe/units/" + id, data);
      try {
            const response = await callPutApi({ url: "product-recipe/units/" + id, body: data });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function addType(data) {
      // return this.interceptors.POST("product-recipe/types", data);
      try {
            const response = await callPostApi({ url: "product-recipe/types", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function typeList(data) {
      // return this.interceptors.POST("product-recipe/types/list", data);
      try {
            const response = await callPostApi({ url: "product-recipe/types/list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function typeDeleteData(id) {
      // return this.interceptors.DELETE("product-recipe/types/" + id);
      try {
            const response = await callDeleteApi({ url: "product-recipe/types/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function typeUpdate(id, data) {
      // return this.interceptors.PUT("product-recipe/types/" + id, data);
      try {
            const response = await callPutApi({ url: "product-recipe/types/" + id, body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateWholeSalePrice(id,data){
      try {
            const response = await callPutApi({ url: `product-recipe/raw-materials/update-whole-sale-price/${id}`, body: data });
            return response;
      } catch (error) {
            throw error; 
      }
}

export async function updateRetailPrice(id,data){
      try {
            const response = await callPutApi({ url: `product-recipe/raw-materials/update-retail-price/${id}`, body: data });
            return response;
      } catch (error) {
            throw error; 
      }
}