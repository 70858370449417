import Interceptors from "../Interceptors/Interceptors";
class RecipeSetting {
  constructor() {
    this.interceptors = new Interceptors();
  }
  async getRecipeRawMaterial(data) {
    return this.interceptors.POST("product-recipe/raw-materials/list", data);
  }
  async deleteRawMaterial(id) {
    return this.interceptors.DELETE("product-recipe/raw-materials/" + id);
  }
  async recipeTypeList(data) {
    return this.interceptors.POST("product-recipe/types/list", data);
  }
  async addRawMaterial(data) {
    return this.interceptors.POST("product-recipe/raw-materials", data);
  }
  async updateRawMaterial(id, data) {
    return this.interceptors.PUT("product-recipe/raw-materials/" + id,data);
  }
  async addUnit(data) {
    return this.interceptors.POST("product-recipe/units", data);
  }
  async unitList(data) {
    return this.interceptors.POST("product-recipe/units/list", data);
  }
  async unitDelete(id) {
    return this.interceptors.DELETE("product-recipe/units/" + id);
  }
  async unitUpdate(id, data) {
    return this.interceptors.PUT("product-recipe/units/" + id, data);
  }
  async addType(data) {
    return this.interceptors.POST("product-recipe/types", data);
  }
  async typeList(data) {
    return this.interceptors.POST("product-recipe/types/list", data);
  }
  async typeDelete(id) {
    return this.interceptors.DELETE("product-recipe/types/" + id);
  }
  async typeUpdate(id, data) {
    return this.interceptors.PUT("product-recipe/types/" + id, data);
  }
}

export default RecipeSetting;