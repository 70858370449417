import { TextField } from "@material-ui/core";
import { useFormikContext } from "formik";
import { formatUsPhone } from "Hooks/commanUtility";
import React, { useEffect } from "react";
import { Checkbox, FormControlLabel, withStyles } from "@material-ui/core";
import { useOrderAttributeProvider } from "../context/order";
import PhoneNumber from "Components/FormControl/PhoneNumber/PhoneNumber";
import { DEFAULT_COUNTRY_CODE_NAME } from "Helpers/Constants";
import SwitchBox from "../../../../Components/FormControl/SwitchBox/SwitchBox";

const PROCESSING_STATE = "processing";

const CustomerInfo = () => {
  const formik = useFormikContext();
  const { module, active, orderDetail } = useOrderAttributeProvider();
  const GreenCheckbox = withStyles({
    root: {
      color: "#CB998D",
      "&$checked": {
        color: "#CB998D",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  return (
    <div className="row mt-5">
      <div className="col-12">
        <h3 className="bold">Customer Information</h3>
      </div>
      <div className="my-2 col-md-4">
        <TextField
          value={formik.values.customer_info.customer_first_name}
          id="customer_info.customer_first_name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={
            formik.touched?.customer_info?.customer_first_name &&
            Boolean(formik.errors?.customer_info?.customer_first_name)
          }
          helperText={
            formik.touched?.customer_info?.customer_first_name &&
            formik.errors?.customer_info?.customer_first_name
          }
          disabled={active === 0 ? false : true}
          className="w-100"
          label="First Name"
        />
        {/* {console.log(formik)} */}
      </div>
      <div className="my-2 col-md-4">
        <TextField
          value={formik.values.customer_info.customer_last_name}
          id="customer_info.customer_last_name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={
            formik.touched?.customer_info?.customer_last_name &&
            Boolean(formik.errors?.customer_info?.customer_last_name)
          }
          helperText={
            formik.touched?.customer_info?.customer_last_name &&
            formik.errors?.customer_info?.customer_last_name
          }
          disabled={active === 0 ? false : true}
          className="w-100"
          label="Last Name"
        />
      </div>
      <div className="my-2 col-md-4">
        <TextField
          value={formik.values.customer_info.customer_email}
          id="customer_info.customer_email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          disabled={formik.values.customer_info.customer_id !== 0}
          error={
            formik.touched?.customer_info?.customer_email &&
            Boolean(formik.errors?.customer_info?.customer_email)
          }
          helperText={
            formik.touched?.customer_info?.customer_email &&
            formik.errors?.customer_info?.customer_email
          }
          disabled={active === 0 ? false : true}
          className="w-100"
          label="Email"
          type="email"
        />
      </div>
      <div className="my-2 col-md-4">
        {/* <TextField
          value={formatUsPhone(formik.values.customer_info.customer_phone)}
          id="customer_info.customer_phone"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={
            formik.touched?.customer_info?.customer_phone &&
            Boolean(formik.errors?.customer_info?.customer_phone)
          }
          helperText={
            formik.touched?.customer_info?.customer_phone &&
            formik.errors?.customer_info?.customer_phone
          }
          disabled={active === 0 ? false : true}
          className="w-100"
          label="Phone"
        /> */}
        <PhoneNumber
          countryCode={DEFAULT_COUNTRY_CODE_NAME}
          initValue={
            formik.values.customer_info?.customer_phone_country_code +
            formik.values.customer_info?.customer_phone
          }
          specialLabel={"Phone"}
          fieldName={`customer_info.customer_phone`}
          onChange={(phone, countryData, event, formatedValue) => {
            let countryCode = "+" + countryData.dialCode;
            let finalMobileNum = formatedValue
              ? formatedValue?.split(countryCode)[1]?.trim()
              : "";
            let valueUpdate = {
              ...formik.values.customer_info,
              customer_phone: finalMobileNum,
              customer_phone_country_code: countryCode,
            };
            formik.setValues((v) => {
              return {
                ...v,
                customer_info: valueUpdate,
              };
            });
          }}
          disabled={active === 0 ? false : true}
        />
      </div>
      <div className="my-2 col-md-4">
        {/* <TextField
          value={formatUsPhone(formik.values.customer_info.customer_alt_phone)}
          id="customer_info.customer_alt_phone"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={
            formik.touched?.customer_info?.customer_alt_phone &&
            Boolean(formik.errors?.customer_info?.customer_alt_phone)
          }
          helperText={
            formik.touched?.customer_info?.customer_alt_phone &&
            formik.errors?.customer_info?.customer_alt_phone
          }
          disabled={active === 0 ? false : true}
          className="w-100"
          label="ALT Phone"
        /> */}
        <PhoneNumber
          countryCode={DEFAULT_COUNTRY_CODE_NAME}
          initValue={
            formik.values.customer_info?.customer_alt_phone_country_code +
            formik.values.customer_info?.customer_alt_phone
          }
          specialLabel={"ALT Phone"}
          fieldName={`customer_info.customer_alt_phone`}
          onChange={(phone, countryData, event, formatedValue) => {
            let countryCode = "+" + countryData.dialCode;
            let finalMobileNum = formatedValue
              ? formatedValue?.split(countryCode)[1]?.trim()
              : "";
            let valueUpdate = {
              ...formik.values.customer_info,
              customer_alt_phone: finalMobileNum,
              customer_alt_phone_country_code: countryCode,
            };
            formik.setValues((v) => {
              return {
                ...v,
                customer_info: valueUpdate,
              };
            });
          }}
          disabled={active === 0 ? false : true}
        />
      </div>
      <div className="my-2 col-md-4">
        <div class="form-group bmd-form-group is-filled text-left">
          <label class="bmd-label-floating">Business Account</label>
          <SwitchBox
            value={formik.values?.customer_info?.is_business_account}
            onChange={(data) => {
              let businessData = {
                ...formik.values?.customer_info,
                is_business_account: data ? 1 : 0,
              };
              formik.setValues((e) => {
                console.log("businessData", businessData);
                return {
                  ...e,
                  customer_info: businessData,
                };
              });
            }}
          />
        </div>
      </div>
      {module === "edit-order" && (
        <div className="col-12">
          <FormControlLabel
            control={
              <GreenCheckbox
                // disabled={orderState.editMode}
                checked={formik.values.verified}
                onChange={() => {
                  formik.setValues((v) => {
                    return {
                      ...v,
                      verified: !v.verified,
                      keep_processing: false,
                    };
                  });
                }}
              />
            }
            label="Update and Process Order"
          />

          {/* WE ONLY NEED TO DISPLAY THIS CHECKBOX IF THE ORDER IS IN "processing" STATE. */}
          {orderDetail?.order_status === PROCESSING_STATE && (
            <FormControlLabel
              control={
                <GreenCheckbox
                  // disabled={orderState.editMode}
                  checked={formik.values.keep_processing}
                  onChange={() => {
                    formik.setValues((v) => {
                      return {
                        ...v,
                        verified: false,
                        keep_processing: !v.keep_processing,
                      };
                    });
                  }}
                />
              }
              label="Update and Keep in Process"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CustomerInfo;
