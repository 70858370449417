import React from 'react';

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// SCSS
import styles from './ChangePassword.module.scss';

// Components
import Text from '../../FormControl/Text/Text';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ChangePassword = ({ showModal, onChangePassword, currentPassword, newPassword, confirmPassword, submitStatus, handleCancelClick, handleSubmitClick, message }) => {
    return (
        <React.Fragment>
            <Dialog
                open={showModal}
                TransitionComponent={Transition}
                onClose={handleCancelClick}
                classes={{
                    paper: styles.modalDialog
                }}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogContent
                    className={styles.modalContent}
                >
                    <div className={`text-center ${styles.modalBody}`}>
                        <h5 className={`my-3 text-center ${styles.modalTitle}`}>Change Password</h5>
                        <Text
                            type="password"
                            label="Old Password"
                            value={currentPassword}
                            required={true}
                            submitStatus={submitStatus}
                            onChange={(password) => onChangePassword('currentPassword', password)}
                        />
                        <Text
                            type="password"
                            label="New Password"
                            value={newPassword}
                            required={true}
                            submitStatus={submitStatus}
                            onChange={(password) => onChangePassword('newPassword', password)}
                        />
                        <Text
                            type="password"
                            label="Confirm Password"
                            value={confirmPassword}
                            required={true}
                            submitStatus={submitStatus}
                            onChange={(password) => onChangePassword('confirmPassword', password)}
                        />
                        <a href="/" data-dismiss="modal" onClick={handleSubmitClick} className="btn gray-btn d-inline-block mb-3 mr-2"> Update </a>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default ChangePassword;
