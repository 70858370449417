import * as User_Actions from "./userAction";
export function UserReducer(state, action) {
  switch (action.type) {
    case User_Actions.GET_PROFILE_DATA:
      return {
        ...state,
        userProfile: action.payload,
      };
    default:
      return state;
  }
}
