import { makeStyles, TextField } from "@material-ui/core";
import RadioBox from "Components/FormControl/RadioBox/RadioBox";
import Select from "Components/FormControl/Select/NewSelect";
import DatePickerWithInput from "Components/NewOrder/DatePicker/DatePickerWithInput";
import { OrderConfigContext } from "Context/OrderConfigContext";
import { useFormikContext } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Datetime from "react-datetime";
import { useOrderAttributeProvider } from "../context/order";
import { useDelivery } from "../hooks/useDelivery";
import {
  CUSTOM_ANYTIME,
  utcDateTimeToPSTTime,
  utctimeToPSTTime,
} from "../utility/util";
import Text from "Components/FormControl/Text/Text";
import {storeList} from "../../../../api/stores";
import { PAYMENT_METHOD_DETAIL } from "Helpers/Constants";
const useStyles = makeStyles({
  time: {
    paddingTop: "16px",
    marginBottom: "16px",
  },
});

const Delivery = (props) => {
  const config = useContext(OrderConfigContext);
  const classes = useStyles();
  const orderDelivery = [
    { id: "Before", title: "Before" },
    { id: "Anytime", title: "AnyTime" },
    { id: "After", title: "After" },
    { id: "By", title: "By" },
  ];

  const orderDeliveryWithOutAnytime = [
    { id: "Before", title: "Before" },
    { id: "After", title: "After" },
    { id: "By", title: "By" },
  ];

  const formik = useFormikContext();
  const {
    show,
    handleDateChange,
    handleDeliveryType,
    handleTimeChange,
    blockoutDates,
    tooltipDays,
    matrix,
    setMatrix,
    handleDeliveryMatrix,
    enableTimeEdit,
    setEnableTimeEdit,
    handleDeliveryChargeChange,
    handleDeliveredBy,
    timeslectionDropdown
  } = useDelivery();

  

  let tempDataFormik = formik.values.delivery_information.charge ? formik.values.delivery_information.charge : 0;
  const [tempCharge, setTempCharge] = useState(tempDataFormik);

  const { active, module } = useOrderAttributeProvider();
  // selected store list
  const [selectedStore,setSelectedStore] = useState([]);

  // console.log("tempDataFormik",tempDataFormik);
  useEffect(() => {
    setTempCharge(tempDataFormik);
  }, [tempDataFormik]);

  useEffect(() => {
    handleDeliveryChargeChange(parseFloat(tempCharge));
  }, [tempCharge]);

  return (
    <div className="row">
      <div className="col-4">
        <RadioBox
          disabled={active > 0 || formik.values.is_burq_order === 1}
          selectedValue={formik.values.delivery_information.order_type.toString()}
          label="Delivery Order"
          value="0"
          onChange={() => {
            setEnableTimeEdit(true);
            setMatrix([]);
            formik.setValues((v) => ({
              ...v,
              delivery_information: {
                ...v.delivery_information,
                order_type: 0,
                delivery_time: "",
                charge: 0,
                upcharge_id: 0,
                upcharge_amount: 0,
                delivery_type_id: 2,
                delivery_type:
                  config?.deliveryTypesList?.find((c) => c.id === 2)?.name ??
                  "",
                delivery_date: null,
                delivery_time_id: null,
                delivery_date_time: "",
                storeId:0
              },
              //PAYABLI
              billing_info : {
                ...v.billing_info,
                zip_code_store_id: null,
                payment_method : "",
              },
              payment_type:""
            }));
          }}
        />
        {
          (!formik?.values?.is_subscribe_order || module != "new-order") && 
          (
            <RadioBox
          disabled={active > 0 || formik.values.is_burq_order === 1}
          selectedValue={formik.values.delivery_information.order_type.toString()}
          label="Pickup Order"
          value="1"
          onChange={() => {
            setMatrix([]);
            setEnableTimeEdit(true);
            formik.setValues((v) => ({
              ...v,
              delivery_information: {
                ...v.delivery_information,
                order_type: 1,
                charge: 0,
                upcharge_id: 0,
                upcharge_amount: 0,
                delivery_time: "",
                delivery_date: null,
                delivery_date_time: "",
                delivery_type_id: 1,
                delivery_type:
                  config?.deliveryTypesList?.find((c) => c.id === 1)?.name ??
                  "",
                delivery_time_id: null,
                storeId:module==="edit-order"?formik.values.delivery_information.storeId || 1:1
              },
              //PAYABLI
              billing_info : {
                ...v.billing_info,
                zip_code_store_id: null,
                payment_method : PAYMENT_METHOD_DETAIL[module==="edit-order"?formik.values.delivery_information.storeId || 1:1]
              },
              payabli_paypoint:null,
              payment_type:""
            }));
          }}
        />
          )
        }
        
      </div>
        {
        formik.values.delivery_information.order_type && module!="edit-order" && !formik?.values?.is_subscribe_order  ?
          <div className="col-4">
              <Select
                  label= "Select Store"
                  value={formik.values.delivery_information.storeId}
                  required={false}
                  disabled={!formik.values.delivery_information.order_type}
                  onChange={(data) => {
                 
                    formik.setValues((v) => ({
                      ...v,
                      delivery_information: {
                        ...v.delivery_information,
                        storeId:data.id
                      },
                      //PAYABLI
                      billing_info : {
                        ...v.billing_info,
                        zip_code_store_id:data?.id,
                        payment_method : data?.paymentgateway
                      },
                      payabli_paypoint:data?.payabli_paypoint,
                      payment_type:""
                    }));
                  }}
                  options={config.storeList}
                />
          </div>
          :<div className="col-4"></div>
        }
        <div className="col-2"></div>
          
      <div className="col-md-4">
        <DatePickerWithInput
          disabled={active > 0 || formik.values.is_burq_order === 1}
          label="Delivery Date"
          // blockDates={blockoutDates} // open when you want to check block out days
          blockDates={[]}
          tooltipDays={tooltipDays}
          value={
            formik.values.delivery_information.delivery_date
              ? moment(
                  formik.values.delivery_information.delivery_date
                ).toDate()
              : null
          }
          onChange={handleDateChange}
        />
      </div>
      <div className="col-md-4">
        <Select
          disabled={active > 0 || formik.values.is_burq_order === 1}
          options={formik.values.is_subscribe_order && module=== "new-order" ? config.deliveryTypesList.filter(elem=>elem.id===2) : config.deliveryTypesList}
          label="Delivery Type"
          onChange={handleDeliveryType}
          value={formik.values.delivery_information.delivery_type_id}
        />
      </div>
      <div className="col-md-4">
        {!enableTimeEdit && (
          <TextField
            className="w-100"
            disabled
            label={
              formik.values.delivery_information.order_type === 0
                ? "Delivery time"
                : "Pickup time"
            }
            value={
              formik.values.delivery_information.order_type === 0
                ? formik.values.delivery_information.delivery_time_id === -1
                  ? "Custom"
                  : `${
                      formik.values.delivery_information.delivery_time_type
                    }  ${
                      formik.values.delivery_information.delivery_time_type ===
                      "Anytime"
                        ? ""
                        : utcDateTimeToPSTTime(
                            formik.values.delivery_information
                              .delivery_date_time
                          )
                    }`
                : `${utcDateTimeToPSTTime(
                    formik.values.delivery_information.delivery_date_time
                  )}`
            }
          />
        )}
        {enableTimeEdit && (
          <>
            {formik.values.delivery_information.order_type === 1 ? (
              <Datetime
                value={utctimeToPSTTime(
                  (formik.values?.delivery_information?.delivery_date_time)?formik.values.delivery_information.delivery_date_time:null,formik.values.delivery_information.delivery_date,formik.values.delivery_information.delivery_time
                )}
                // value={utctimeToPSTTime(
                //   formik.values.delivery_information.delivery_date,formik.values.delivery_information.delivery_time
                // )}
                className={classes.time}
                dateFormat={false}
                input={true}
                inputProps={{ placeholder: "Select your pickup time" }}
                onChange={handleTimeChange}
              />
            ) : (
              <>
              {timeslectionDropdown ? (
                <Select
                  label="Calculating..."
                  value={formik.values.delivery_information.delivery_time_id}
                  onChange={""}
                  options={[]}
                />
              ) : (
                <Select
                  label="Delivery Time"
                  value={formik.values.delivery_information.delivery_time_id}
                  onChange={handleDeliveryMatrix}
                  options={matrix}
                />
              )}
              </>
            )}
          </>
        )}
      </div>
      {formik.values.delivery_information.delivery_time_id === -1 && (
        <>
          <div className="col-md-4">
            <Select
              value={formik.values.delivery_information.delivery_time_type}
              label="Delivered By"
              onChange={(data) => {
                handleDeliveredBy(data.id);
                // formik.setValues((v) => ({
                //   ...v,
                //   delivery_information: {
                //     ...v.delivery_information,
                //     delivery_time_type: data.id,
                //   },
                // }));
              }}
              options={
                formik.values.delivery_information.address_type_id > 2
                  ? orderDeliveryWithOutAnytime
                  : orderDelivery
              }
              id="delivery_time_type"
              error={
                formik.touched?.delivery_information?.delivery_time_type &&
                Boolean(formik.errors?.delivery_information?.delivery_time_type)
              }
            />
          </div>
          {formik.values.delivery_information.delivery_time_type !==
            "Anytime" && (
            <div className="col-md-4">
              <Datetime
                value={utctimeToPSTTime(
                  (formik.values?.delivery_information?.delivery_date_time)?formik.values.delivery_information.delivery_date_time:null,formik.values.delivery_information.delivery_date,formik.values.delivery_information.delivery_time
                )}
                className={classes.time}
                dateFormat={false}
                input={true}
                inputProps={{ placeholder: "Select your delivery time" }}
                onChange={handleTimeChange}
              />
            </div>
          )}

          <div className="col-md-4">
            {/* <TextField
              value={formik.values.delivery_information.charge}
              className={classes.time}
              dateFormat={false}
              input={true}
              inputProps={{ placeholder: "Add Delivery Charge" }}
              onChange={handleDeliveryChargeChange}
            /> */}
            <Text
              type="text"
              label="Delivery Charge"
              // disabled
              value={tempCharge.toString()}
              required={true}
              onChange={(value) => {
                if (/^[0-9]*(\.[0-9]*)?$/.test(value)) {
                  // handleDeliveryChargeChange(value);
                  setTempCharge(value);
                }
              }}
            />
          </div>
        </>
      )}
      {formik.values.is_subscribe_order === 1 && (
        <div className="col-md-4">
          <Select
            disabled={module === "new-order" ? false : true}
            options={config.frequencyList}
            value={formik.values.delivery_information.frequency}
            label="Subscription Type"
            onChange={(data) => {
              formik.setValues((v) => ({
                ...v,
                delivery_information:{
                  ...v.delivery_information,
                  frequency:data.id
                }
              }));
            }}
          />
        </div>
      )}
      {show.two_field && (
        <>
          <div className="col-md-4">
            <TextField
              disabled={active > 0}
              className="w-100 my-2"
              value={formik.values.delivery_information.shop_code}
              onChange={formik.handleChange}
              id="delivery_information.shop_code"
              error={
                formik.touched?.delivery_information?.shop_code &&
                Boolean(formik.errors?.delivery_information?.shop_code)
              }
              onBlur={formik.handleBlur}
              label="Shop Code"
            />
          </div>
          {show.all_field && (
            <div className="col-md-4">
              <TextField
                disabled={active > 0}
                className="w-100 my-2"
                value={formik.values.delivery_information.shop_name}
                onChange={formik.handleChange}
                id="delivery_information.shop_name"
                error={
                  formik.touched?.delivery_information?.shop_name &&
                  Boolean(formik.errors?.delivery_information?.shop_name)
                }
                onBlur={formik.handleBlur}
                label="Shop Name"
              />
            </div>
          )}
          {show.all_field && (
            <div className="col-md-4">
              <TextField
                disabled={active > 0}
                className="w-100 my-2"
                value={formik.values.delivery_information.person_name}
                onChange={formik.handleChange}
                id="delivery_information.person_name"
                error={
                  formik.touched?.delivery_information?.person_name &&
                  Boolean(formik.errors?.delivery_information?.person_name)
                }
                onBlur={formik.handleBlur}
                label="Person Name"
              />
            </div>
          )}
          <div className="col-md-4">
            <TextField
              disabled={active > 0}
              className="w-100 my-2"
              value={formik.values.delivery_information.external_order_number}
              onChange={formik.handleChange}
              id="delivery_information.external_order_number"
              error={
                formik.touched?.delivery_information?.external_order_number &&
                Boolean(
                  formik.errors?.delivery_information?.external_order_number
                )
              }
              onBlur={formik.handleBlur}
              label="External Order Number"
            />
          </div>
          {show.all_field && (
            <div className="col-md-4">
              <TextField
                disabled={active > 0}
                className="w-100 my-2"
                value={formik.values.delivery_information.phone_number}
                onChange={formik.handleChange}
                id="delivery_information.phone_number"
                error={
                  formik.touched?.delivery_information?.phone_number &&
                  Boolean(formik.errors?.delivery_information?.phone_number)
                }
                onBlur={formik.handleBlur}
                label="Phone Number"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Delivery;
