import RadioBox from "Components/FormControl/RadioBox/RadioBox";
import { isBeforetwelveandsix } from "Helpers/date";
import { DateTime } from "luxon";
import moment from "moment";
import { PST_ZONE } from "Pages/Order/Order/utility/util";
import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router";
import { getAddressTypeList } from "../../../api/addressType";
import { getReplaceRefundIrderDetails } from "../../../api/report";
import { Bar,Line } from "react-chartjs-2";
import { Link } from "react-router-dom";

import SearchIcon from "../../../Assets/images/search.svg";
import Date from "../../../Components/FormControl/Date/Date";
import Select from "../../../Components/FormControl/Select/Select";
import ReactTable from "../../../Components/Table/Table";
import TableContainer from 'Components/Table/TableContainer';
import CalendarIcon from "../../../Assets/images/calendar.svg";
import CloseIcon from "../../../Assets/images/close.svg";
import ExportIcon from "./../../../Assets/images/export.svg";

// Context
import { AppContext } from "../../../Context/AppContext";
import { MODULES } from "../../../Helpers/Constants";
import { getAccessByModuleName } from "../../../Routes/Routes";

// Components
import Header from "./../../../Components/Header/Header";
import styles from "./index.module.scss";
import CheckBox from "Components/FormControl/CheckBox/CheckBox";
import { setAllStore } from "Helpers/setAllStore";

const ReplaceRefundOrders = (props) => {
  const { showToastMessage } = useContext(AppContext);
  const [type, setType] = useState("delivery_date");
  const [buildingList, setBuildingList] = React.useState([]);


  const [fromDate, setFromDate] = useState(
    moment().startOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [toDate, setToDate] = useState(
    moment().endOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [data, setData] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(1);
  const reference = React.useRef(null);
  const [exportData, setExportData] = React.useState([]);
  const [exportHeader, setExportHeader] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [firstTimePageLoad, setFirstTimePageLoad] = React.useState(false);
  const [selectedPeriod, setSelectedPeriod] = React.useState("weekly");
  const [selectedPeriodLabel, setSelectedPeriodLabel] =
    React.useState("By Week");
  const [recordType, setRecordType] = useState("all");
  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  const [checkAllStore, setCheckAllStore] = useState(true);
  const [chartData, setChartData] = React.useState(null);
  const [fullRefundCount, setFullRefundCount] = React.useState(1);
  const [partialRefundCount, setPartialRefundCount] = React.useState(1);
  const [replacementCount, setReplacementCount] = React.useState(1);
  const [graphAllData, setGraphAllData] = React.useState([]);

  const getAddressType = async () => {
    let response = await getAddressTypeList();
    if (response && response.status === 200) {
      let addressTypeArr = [];
      response.data.data.rows?.forEach((d, i) => {
        let tempArr = {
          id: d.name,
          title: d.name,
        };
        addressTypeArr.push(tempArr);
      });
      setBuildingList(addressTypeArr);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  useEffect(() => {
    getAddressType();
  }, []);

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
  }) => {

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
    // setChartData(null);
    setAllStore(checkAllStore);

    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });

    setLoading(true);
    const postData = {
      date_type: type,
      record_type: recordType,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
      sortBy,
    };

    if (!firstTimePageLoad) {
      const response = await getReplaceRefundIrderDetails(postData);
      if (response && response.status === 200) {
        setData(response.data.data.final_data);
        setFullRefundCount(response.data.data.full_refund_order)
        setPartialRefundCount(response.data.data.partial_refund_order)
        setReplacementCount(response.data.data.replaced_order)
        setPageCount(Math.ceil(response.data.data.final_data.length / pageSize));
        if(pageIndex === 0){
          setGraphAllData(response?.data?.data?.graphDataArr);
          handleGraphDataChange(response?.data?.data?.graphDataArr)
        }
        
      } else if (response.status === 403) {
        showToastMessage(undefined, response.data.message, "error", false);
        props.history.push("/profile");
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    } else {
      setFirstTimePageLoad(false);
    }
    setLoading(false);

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if (checkAllStore) { setAllStore(false) }
  };

  const handleSelectedPeriod = async (id) => {
    if (id === "weekly") {
      setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Week");
    } else if (id === "monthly") {
      setFromDate(moment().startOf("month").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("month").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Month");
    } else if (id === "yearly") {
      setFromDate(moment().startOf("year").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("year").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Year");
    }
  };

  const handleFromDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setToDate(moment(data).add(7, "days").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "monthly") {
      setToDate(moment(data).add(1, "months").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "yearly") {
      setToDate(moment(data).add(1, "years").utc().format("ddd MMM DD, YYYY"));
    }
  };

  const handleToDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setFromDate(
        moment(data).subtract(7, "days").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "monthly") {
      setFromDate(
        moment(data).subtract(1, "months").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "yearly") {
      setFromDate(
        moment(data).subtract(1, "years").utc().format("ddd MMM DD, YYYY")
      );
    }
  };

  const handleExportReplaceRefundOrders = async () => {

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
    setAllStore(checkAllStore);

    let postData = {
      isExport: true,
      date_type: type,
      record_type: recordType,
      start_date: moment(fromDate).format("YYYY-MM-DD"),
      end_date: moment(toDate).format("YYYY-MM-DD"),
    };

    const response = await getReplaceRefundIrderDetails(postData);

    if (response && response.status === 200) {
      setExportHeader(response.data.data.header);
      setExportData(response.data.data.data.final_data);
      reference.current?.link?.click();
    } else if (response.status === 403) {
      showToastMessage(undefined, response.data.message, "error", false);
      props.history.push("/profile");
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if (checkAllStore) { setAllStore(false) }
  };

  const handleClearSearch = async (event) => {
    event.preventDefault();
    setChartData(null);
    setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
    setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
    setSelectedPeriod("weekly");
    fetchData({
      ...defaultFilter,
      fromDateDefault: moment().startOf("isoWeek").format("ddd MMM DD, YYYY"),
      toDateDefault: moment().endOf("isoWeek").format("ddd MMM DD, YYYY"),
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Order No",
        accessor: "order_number",
        isVisible: true,
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          return (
            <Link to={`/admin/orders/view/${row.original.id}`}>
              <span className={`cursor-pointer`}>
                {`${row.original.order_number}`}
              </span>
            </Link>
          );
        },
      },
      // {
      //   Header: "Order Placed Date",
      //   accessor: "order_date",
      //   isVisible: true,
      //   disableFilters: true,
      //   disableSortBy: true,
      //   colWidth: 200,
      //   Cell: ({ value, row, column }) => {
      //     return `${row.original.order_date && row.original.order_date !== null
      //         ? DateTime.fromISO(row.original.order_date)
      //           .setZone(PST_ZONE)
      //           .toFormat("ccc LLL dd, yyyy")
      //         : ""
      //       }`;
      //   },
      // },
      // {
      //   Header: "Delivery Date",
      //   accessor: "delivery_date",
      //   isVisible: true,
      //   placeholder: "Search By Delivery Date",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   colWidth: 200,
      //   Cell: ({ value, row, column }) => {
      //     return `${row.original.delivery_date && row.original.delivery_date !== null
      //         ? DateTime.fromISO(row.original.delivery_date)
      //           .setZone(PST_ZONE)
      //           .toFormat("ccc LLL dd, yyyy")
      //         : ""
      //       }`;
      //   },
      // },
      {
        Header: "Action Type",
        accessor: "action_type",
        isVisible: true,
        disableFilters: true,
        colWidth: 160,
      },
      {
        Header: "Original Order Value",
        accessor: "total",
        isVisible: true,
        placeholder: "Price",
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          return `$${row.original.total}`;
        },
      },
      {
        Header: "Total Amount Refunded",
        accessor: "refund",
        isVisible: true,
        placeholder: "Price",
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          return `$${row.original.refund}`;
        },
      },
      {
        Header: "Reason",
        accessor: "message",
        isVisible: true,
        disableFilters: true,
      },
      {
        Header: "Products Ordered",
        accessor: "product_name",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [buildingList]
  );

  const handleTabChange = (recordTypeValue) => {
    setRecordType(recordTypeValue);
  };

  const handleGraphDataChange = (data) => {
    let chartDataSet = {
      labels: recordType === "all" ? data?.graphAllData?.graphAllKey : recordType === "full-refund" ? data?.graphFullRefundData?.graphAllKey: recordType === "partial-refund" ? data?.graphPartialRefundData?.graphAllKey: recordType === "replacement" ? data?.graphReplaceData?.graphAllKey: "",
      datasets: [
        {
          label: "Reasons",
          data: recordType === "all" ? data?.graphAllData?.graphAllValue : recordType === "full-refund" ? data?.graphFullRefundData?.graphAllValue: recordType === "partial-refund" ? data?.graphPartialRefundData?.graphAllValue: recordType === "replacement" ? data?.graphReplaceData?.graphAllValue:"",
          backgroundColor: "#CB998D",
        },
      ],
    };

    setChartData(chartDataSet);
  }

  useEffect(() => {
    if(recordType && graphAllData){
      handleGraphDataChange(graphAllData);
      fetchData({
        ...defaultFilter,
        pageIndex: 0,
      });
    }
  }, [recordType]);
  return (
    <>
      <Header title="Replacements and Refunds" />
      <div id="MainContent" className="main-content">
        <div style={{ float: "left", width: "100%" }}>
          <div className={"row "}>
            <div className="col-12 d-flex align-items-center">
              <span
                className="mb-0 mt-2 pb-0 d-inline-bock mr-3"
                style={{ lineHeight: 1 }}
              >
                Filter By
              </span>
              <RadioBox
                selectedValue={type}
                onChange={() => {
                  if (setType) {
                    setType("order_date");
                  }
                }}
                label="Order date"
                value="order_date"
              />
              <RadioBox
                selectedValue={type}
                onChange={() => {
                  if (setType) {
                    setType("delivery_date");
                  }
                }}
                label="Delivery date"
                value="delivery_date"
              />
            </div>
            <div className="col-sm-2 col-xs-12">
              <Select
                label={selectedPeriodLabel}
                value={selectedPeriod}
                onChange={(data) => {
                  setSelectedPeriod(data);
                  handleSelectedPeriod(data);
                }}
                options={[
                  { id: "weekly", title: "Weekly" },
                  { id: "monthly", title: "Monthly" },
                  { id: "yearly", title: "Yearly" },
                  { id: "custom", title: "Custom" },
                ]}
                disabled={false}
              />
            </div>
            <div className="col-sm-3 col-xs-12">
              <Date
                label="Filter From"
                value={fromDate}
                dateFormat="ddd MMM DD, YYYY"
                type="date"
                fieldIcon={CalendarIcon}
                onChange={(fromDate) => {
                  setFromDate(moment(fromDate).format("ddd MMM DD, YYYY"));
                  handleFromDate(fromDate);
                }}
              />
            </div>
            <div className="col-sm-3 col-xs-12">
              <Date
                label="Filter To "
                value={toDate}
                dateFormat="ddd MMM DD, YYYY"
                type="date"
                fieldIcon={CalendarIcon}
                onChange={(toDate) => {
                  setToDate(moment(toDate).format("ddd MMM DD, YYYY"));
                  handleToDate(toDate);
                }}
              />
            </div>
            <div className="col-sm-2 col-xs-12">
              <CheckBox
                value={checkAllStore}
                label="All Stores"
                onChange={(data) => { setCheckAllStore(data) }}
              />
            </div>
            <div className="col-sm-2 col-xs-12">
              <div className="td-actions pt-4">
                <span
                  onClick={(e) => fetchData(defaultFilter)}
                  className={`link cursor-pointer ${styles.searchIconWrapper}`}
                >
                  <img className={styles.searchIcon} src={SearchIcon} alt="" />
                </span>
                <span
                  onClick={(e) => handleClearSearch(e)}
                  className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
                >
                  <img className={styles.clearIcon} src={CloseIcon} alt="" />
                </span>
                {exportData && exportData.length > 0 && (
                  <CSVLink
                    ref={reference}
                    style={{
                      display: "none",
                      float: "right",
                      marginTop: "25px",
                    }}
                    data={exportData}
                    headers={exportHeader}
                    filename={`replacements_and_refunds_report${DateTime.local().valueOf()}.csv`}
                    className={`link cursor-pointer ml-3 ${styles.searchIconWrapper}`}
                  >
                    DownLoad
                  </CSVLink>
                )}
                {/* {accessExport && accessExport.permissions.write_access && ( */}
                <span
                  onClick={(e) => handleExportReplaceRefundOrders()}
                  className={`link cursor-pointer ${styles.searchIconWrapper}`}
                  style={{ float: "right" }}
                >
                  <img
                    className={styles.searchIcon}
                    src={ExportIcon}
                    alt=""
                  />
                </span>
                {/* )} */}
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <h3 style={{ color: "#7A7A7A", marginLeft: "20px" }}># Full Refunds : {fullRefundCount}</h3>
            <h3 style={{ color: "#7A7A7A", marginLeft: "20px" }}># Partial Refunds : {partialRefundCount}</h3>
            <h3 style={{ color: "#7A7A7A", marginLeft: "20px" }}># Replacements : {replacementCount}</h3>
          </div>
          <br />
          <br />
          <div style={{ display: "flex",marginLeft: "20px" }}>
            <h3 style={{ color: "#7A7A7A",width:"95px" , margin:"0px" , lineHeight:"1.5" }}>View : </h3>
            <div style={{ width:"calc(100% - 50px)" }}>
              <button
                onClick={() => handleTabChange("all")}
                className={
                  recordType === "all"
                    ? styles.tabButtonCustomActive
                    : styles.tabButtonCustom
                }
                value={recordType}
              >
                All
              </button>
              <button
                onClick={() => handleTabChange("full-refund")}
                className={
                  recordType === "full-refund"
                    ? styles.tabButtonCustomActive
                    : styles.tabButtonCustom
                }
                value={recordType}
              >
                Full Refunds
              </button>
              <button
                onClick={() => handleTabChange("partial-refund")}
                className={
                  recordType === "partial-refund"
                    ? styles.tabButtonCustomActive
                    : styles.tabButtonCustom
                }
                value={recordType}
              >
                Partial Refunds
              </button>
              <button
                onClick={() => handleTabChange("replacement")}
                className={
                  recordType === "replacement"
                    ? styles.tabButtonCustomActive
                    : styles.tabButtonCustom
                }
                value={recordType}
              >
                Replacements
              </button>
            </div>
          </div>
          <br />
          <br />
          {chartData &&
            chartData.datasets.length !== 0 && (
              <Bar
                data={chartData}
                style={{ backgroundColor: "#FAFAFB", padding: "20px" }}
                className={styles.graphChart}
              />
            )}
          <br></br>
          <br></br>
          {/* <ReactTable
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            noDataText={"There is no data to display"}
            defaultFilter={defaultFilter}
            displayGlobalSearch={false}
            manualPagination={false}
          /> */}
          <TableContainer
            columns={columns}
            data={data}
            noDataText={'There is no data to display'}
            loading={loading}
            disableLastColumnFilter={true}
          />
        </div>
      </div>
    </>
  );
};

export default withRouter(ReplaceRefundOrders);
