import React from "react";
import { Bar } from "react-chartjs-2";

const RevenueBreakdown = ({ revenueData }) => {
  let randomNumber = () => {
    return Math.random() * (40 - 0) + 0;
  };
  const data = {
    labels: revenueData.label,
    datasets: [
      {
        label: "Revenue($)",
        data: revenueData.data,
        fill: false,
        backgroundColor: "#E4E0E5",
        borderColor: "#E4E0E5",
        borderWidth: 1,
      },
    ],
  };

  // const options = {
  //   legend: {
  //     display: false,
  //   },
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //         },
  //       },
  //     ],
  //   },
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //   },
  // };

  const optionsData = {
    scales: {
      y: {
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value) {
            return "$" + value;
          },
        },
      },
    },
  };

  return (
    <>
      <div className="header">
        <h3 className="bold">Revenue Breakdown</h3>
      </div>
      <Bar
        data={data}
        options={optionsData}
        height={200}
        style={{ backgroundColor: "#FAFAFB", padding: "20px" }}
      />
    </>
  );
};

export default RevenueBreakdown;
