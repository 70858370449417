import { BlogActions } from "./blogActions";

const clearBlog = {
  primary_info: {
    title: "",
    slug: "",
    description: "",
    keywords: "",
    author_name: "",
    read_time: "",
    blog_date: "",
    url_category_slug:"",
  },
  categories: [],
  banners: [],
  seo: {
    id: 0,
    parent_id: 0,
    module: "blogs",
    is_robot: false,
    is_no_follow: false,
    is_no_index: false,
    image_alt_text: "",
    page_title: "",
    meta_title: "",
    meta_description: "",
    canonical_url: "",
    og_title: "",
    og_description: "",
    og_image_url: "",
    og_image_alt: "",
    og_url: "",
    og_image_type: "",
    og_site_name: "",
    og_image_height: 0,
    og_image_width: 0,
    twitter_title: "",
    twitter_image_url: "",
    twitter_description: "",
    twitter_site: "",
    twitter_creator: "",
    schema: "",
  },
  setBlogAllData: "",
  blogError: false,
  bannerError: false,
  seoError: false,
  ogInfoError: false,
  twitterError: false,
  schemaError: false,
  similarBlogData: []
};

const newBanner = {
  id: 0,
  parent_id: 0,
  module: "blogs",
  position: "LEFT",
  banner_text: "",
  button_label: "",
  button_url: "",
  description: "",
  image: "",
  is_supported_image: 1,
  show_oval: 0,
};

function blogReducer(state, action) {
  switch (action.type) {
    case BlogActions.CLEAR_DATA_BLOG:
      return clearBlog;
    case BlogActions.SET_ALL_BLOG_DATA:
      return {
        ...state,
        setBlogAllData: action.payload,
      };
    case BlogActions.SET_PAGE_FOR_VIEW:
      return {
        ...state,
        setPageForView: action.payload,
      };
    case BlogActions.SET_PRIMARY_INFO:
      let primaryData = { ...state.primary_info, ...action.payload };
      return {
        ...state,
        primary_info: primaryData,
      };
    case BlogActions.CHECK_PRIMARY_INFO:
      return {
        ...state,
        blogError: action.payload,
      };
    case BlogActions.SET_CATEGORY_DATA:
      let categoryDataSet = state.categories ? [...state.categories] : [];
      categoryDataSet.push(action.payload);
      return {
        ...state,
        categories: categoryDataSet,
      };
    case BlogActions.DELETE_CATEGORY_DATA:
      let categoryData = [...state.categories];
      categoryData.splice(action.payload, 1);
      return {
        ...state,
        categories: categoryData,
      };
    case BlogActions.SET_BANNER_DATA:
      let setBannersData = state.banners ? [...state.banners] : [];
      setBannersData.push(action.payload);
      return {
        ...state,
        banners: setBannersData,
      };
    case BlogActions.ADD_NEW_BANNER:
      let aBanner = state.banners ? [...state.banners] : [];
      aBanner.push(newBanner);
      return {
        ...state,
        banners: aBanner,
      };
    case BlogActions.REMOVE_BANNER:
      let rBanner = [...state.banners];
      rBanner.splice(action.payload, 1);
      return {
        ...state,
        banners: rBanner,
      };
    case BlogActions.UPDATE_BANNER_DATA:
      let bannersData = [...state.banners];
      bannersData[action.payload.index] = {
        ...action.payload.values,
      };
      return {
        ...state,
        banners: bannersData,
      };
    case BlogActions.CHECK_BANNER_DATA:
      return {
        ...state,
        bannerError: action.payload,
      };
    case BlogActions.UPDATE_SEO_DATA:
      let seoData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: seoData,
      };
    case BlogActions.CHECK_SEO_DATA:
      return {
        ...state,
        seoError: action.payload,
      };
    case BlogActions.UPDATE_OG_INFO_DATA:
      let ogInfoData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: ogInfoData,
      };
    case BlogActions.CHECK_OG_INFO_DATA:
      return {
        ...state,
        ogInfoError: action.payload,
      };
    case BlogActions.UPDATE_TWITTER_INFO_DATA:
      let twitterInfoData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: twitterInfoData,
      };
    case BlogActions.CHECK_TWITTER_INFO_DATA:
      return {
        ...state,
        twitterInfoError: action.payload,
      };
    case BlogActions.UPDATE_SCHEMA_DATA:
      let schemaData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: schemaData,
      };
    case BlogActions.CHECK_SCHEMA_DATA:
      return {
        ...state,
        schemaError: action.payload,
      };
    case BlogActions.SET_SIMILAR_BLOG_DATA:
      let similarBlogDataSet = state.similarBlogData ? [...state.similarBlogData] : [];
      similarBlogDataSet.push(action.payload);
      return {
        ...state,
        similarBlogData: similarBlogDataSet,
      };

    case BlogActions.DELETE_SIMILAR_BLOG_DATA:
      let similarData = [...state.similarBlogData];
      similarData.splice(action.payload, 1);
      return {
        ...state,
        similarBlogData: similarData,
      };
    default:
      return state;
  }
}

export default blogReducer;