import React, { useContext, useEffect, useState } from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// SCSS
import styles from "../Customer/Add/Add.module.scss";
import classes from "./paymentcard.module.scss";
import { getBraintreeClient, getHostedFieldInstance } from "Helpers/braintree";
import PaymentCardApi from "Services/PaymentCard/PaymentCard";
import { AppContext } from "Context/AppContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentCardEditModal = ({
  showModal,
  pageTitle,
  data,
  setPaymentModal,
  handleCancelClick,
}) => {
  const { showToastMessage } = useContext(AppContext);
  const [submitting, setSubmitting] = useState(false);
  const paymentService = new PaymentCardApi();
  const [hostedFieldsState, sethostedFieldsState] = useState(null);
  useEffect(() => {
    (async () => {
      const client = await getBraintreeClient(process.env.REACT_APP_BRAINTREE_TOKEN);
      const hostedFields = await getHostedFieldInstance(
        client,
        {
          expirationDate: {
            selector: "#expiration-date",
            placeholder: "mm / yyyy",
          },
          cvv: {
            selector: "#cvv",
            placeholder: "***",
            type: "password",
          },
        },
        {
          input: {
            "font-size": "16px",
            "font-family": "'Messina', sans-serif",
            "font-weight": "normal",
            color: "black",
          },
          ":focus": {
            color: "black",
          },
          ".valid": {
            color: "#69ba78",
          },
          ".invalid": {
            color: "#e44a41",
          },
        }
      );
      sethostedFieldsState(hostedFields);
    })();
  }, []);

  const tokenize = () => {
    return new Promise((resolve, reject) => {
      hostedFieldsState.tokenize((err, payload) => {
        if (err) reject(err);
        return resolve(payload.nonce);
      });
    });
  };

  const handleSubmitPaymentCard = async (event) => {
    setSubmitting(true);
    try {
      event.preventDefault();
      const payload = await tokenize();
      const sendbody = {
        id: data.id,
        paymentMethodNonce: payload,
        customer_billing_first_name:data.customer_billing_first_name,
        customer_billing_last_name:data.customer_billing_last_name
      };
      await paymentService.updatePaymentCardDetails(sendbody).then((data) => {
        if (data && data.status === 200) {
          showToastMessage(undefined, data.data.message, "success", false);
          //PAYABLI
          setPaymentModal({isOpen:false, payment_method:""});
        } else {
          showToastMessage(undefined, data.data.message, "error", false);
        }
      });
    } catch (error) {
      if (error.name === "BraintreeError") {
        showToastMessage(
          undefined,
          `${error.details.invalidFieldKeys.join(", ")} has an invalid input`,
          "error",
          false
        );
      } else {
        showToastMessage(undefined, error.data.message, "error", false);
      }
    }
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={showModal.isOpen}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="form-group bmd-form-group is-filled text-left">
                  <label className={`${classes.paymentCardDetailsHeading}`}>
                    Card Number
                  </label>
                  <p className={`${classes.paymentCardDetails}`}>
                    xxxx xxxx xxxx {data.last_4number ?? "xxxx"}
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="form-group bmd-form-group is-filled text-left">
                  <label className={`${classes.paymentCardDetailsHeading}`}>
                    Card Holder
                  </label>
                  <p className={`${classes.paymentCardDetails}`}>
                    {data.name_on_card}
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="form-group bmd-form-group is-filled text-left">
                  <label
                    htmlFor="expiration-date"
                    className={`${classes.paymentCardDetailsHeading}`}
                  >
                    Expiry Date
                  </label>
                  <div
                    id="expiration-date"
                    className={`${classes.inputClass}`}
                  ></div>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="form-group bmd-form-group is-filled text-left">
                  <label
                    htmlFor="cvv"
                    className={`${classes.paymentCardDetailsHeading}`}
                  >
                    CVC
                  </label>
                  <div id="cvv" className={`${classes.inputClass}`}></div>
                </div>
              </div>
            </div>
            <button
              data-dismiss="modal"
              onClick={handleSubmitPaymentCard}
              className={`btn gray-btn d-inline-block mb-3 mr-2 mt-5`}
              disabled={submitting}
            >
              Update
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default PaymentCardEditModal;
