import React from "react";
import Select from "react-select";

// SCSS
import styles from "./index.module.scss";

const SelectInput = ({
  label,
  value,
  submitStatus,
  parentClass,
  options,
  required,
  disabled,
  onChange,
  onInputChange,
  isSearchable,
  focus,
  blur,
  error,
  touched,
  usedFor = "",
  menuPortal,
}) => {
  let customCss = {};
  if (usedFor === "Filter") {
    customCss = {
      container: (styles) => ({ ...styles, maxHeight: "33px" }),
      control: (styles, state) => ({
        ...styles,
        borderTop: "none !important",
        borderRight: "none !important",
        borderLeft: "none !important",
        borderRadius: 0,
        background: "transparent",
        maxHeight: "33px",
        boxShadow: "none",
      }),
      valueContainer: (styles) => ({
        ...styles,
        paddingLeft: 0,
        maxHeight: "33px",
        color: "#212721",
      }),
      input: (styles) => ({ ...styles, maxHeight: "24px" }),
      placeholder: (styles) => ({ ...styles, maxHeight: "24px" }),
      singleValue: (styles) => ({ ...styles, maxHeight: "33px" }),
      option: (provided, state) => ({
        ...provided,
        lineHeight: 1,
      }),
    };
  } else {
    customCss = {
      control: (styles, state) => ({
        ...styles,
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
        borderRadius: 0,
        background: "transparent",
        boxShadow: "none",
        fontSize: 20,
        height: 44,
      }),
      valueContainer: (styles) => ({
        ...styles,
        paddingLeft: 0,
        height: 44,
        color: "#212721",
      }),
    };
  }

  return (
    <div
      className={`form-group bmd-form-group ${
        value || value === 0 ? "is-filled" : ""
      } ${parentClass ? parentClass : ""} ${
        submitStatus && required && !value ? styles.errorInput : ""
      }`}
    >
      <label className={`bmd-label-floating ${styles.selectLabel}`}>
        {label}
      </label>
      <Select
        placeholder=""
        menuPortalTarget={menuPortal && menuPortal}
        value={options?.find((optionsObj) => optionsObj.id === value) ?? ""}
        isDisabled={disabled}
        blur={blur}
        onInputChange={(e) => {
          if (onInputChange) {
            onInputChange(e);
          }
        }}
        getOptionLabel={(option) => {
          if (!value && options.length === 0) {
            return "";
          } else {
            return option.length !== 0
              ? option.first_name
                ? `${option.first_name} ${option.last_name}`
                : option.name
                ? option.name
                : option.title
                ? option.title
                : option.zone && option.country_name
                ? option.zone + " (" + option.country_name + ")"
                : ""
              : "";
          }
        }}
        getOptionValue={(option) => `${option.id}`}
        onChange={(data) => onChange(data)}
        options={options}
        styles={customCss}
        components={{
          IndicatorSeparator: () => null,
        }}
        isSearchable={isSearchable}
        maxMenuHeight={usedFor === "Filter" ? 200 : 300}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#CB998D",
            primary25: "rgba(203,153,141,.15)",
          },
        })}
      />
      {error && touched && <p className="invalid-feedback d-block">{error}</p>}
    </div>
  );
};

export default SelectInput;
