import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import axios from "axios";

const LOQATE_KEY = "NZ96-MR58-GF76-WC35";
export async function getSuggestion(query, container) {
  try {
    const data = await axios.get(
      `https://api.addressy.com/Capture/Interactive/Find/v1.00/json3.ws`,
      {
        params: {
          Key: LOQATE_KEY,
          Countries: "USA",
          Language: "en",
          $block: true,
          $cache: true,
          Text: query,
          Container: container ?? "",
        },
      }
    );
    return data.data.Items;
  } catch (e) {
    throw e;
  }
}

export async function getDetails(id) {
  try {
    const data = await axios.get(
      `https://api.addressy.com/Capture/Interactive/Retrieve/v1.00/json3.ws`,
      {
        params: {
          Key: LOQATE_KEY,
          Id: id,
        },
      }
    );
    return data.data.Items[0];
  } catch (error) {
    throw error;
  }
}

export function useLoqate({ onResult }) {
  const [items, setItems] = useState([]);

  const [show, setShow] = useState(false);

  useEffect(() => {
    document.addEventListener('click', reset)
    return () => {
      document.removeEventListener('click', reset)
    }
  }, [])

  const reset = () => {
    setItems([])
  }

  const fetchAddress = async (query, container) => {
    let c = container ?? "";
    let it = await getSuggestion(query, c);
    if (query === "") {
      setItems([]);
      return;
    }
    setItems(it);
    setShow(true);
  };

  const fetchAddressList = useCallback(debounce(fetchAddress, 100), []);

  const onItemClick = async (item) => {
    if (item.Type === "Address") {
      const data = await getDetails(item.Id);
      setItems([]);
      setShow(false);
      if (onResult) {
        onResult(data);
      }
    } else {
      let its = await getSuggestion(item.Text, item.Id);
      setItems(its);
      setShow(true);
    }
  };

  return { fetchAddressList, onItemClick, items, show, setShow, setItems };
}
