import React, { useState } from "react";

// SCSS
import styles from "./index.module.scss";

const TextArea = ({
  label,
  rows,
  value,
  submitStatus,
  required,
  disabled,
  onChange,
  error,
  onBlur,
  touched,
  id,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div
      style={props.style}
      className={`form-group bmd-form-group ${isFocused ? "is-focused" : ""} ${
        value ? "is-filled" : ""
      } ${submitStatus && required && !value ? styles.errorInput : ""}`}
    >
      <label className={`${styles.bmdLabelFloating} bmd-label-floating`}>
        {label}
      </label>
      <textarea
        style={props.inputStyle}
        id={id ? id : ""}
        value={value}
        rows={rows}
        disabled={disabled}
        className="form-control h-100"
        onFocus={() => setIsFocused(true)}
        // onBlur={() => setIsFocused(false)}
        onBlur={onBlur}
        onChange={(e) => {
          if (props.onChangeWithEvent) {
            props.onChangeWithEvent(e);
          }
          if (onChange) {
            onChange(e.target.value);
          }
        }}
      />
      {error && touched && <p className="invalid-feedback d-block">{error}</p>}
    </div>
  );
};

export default TextArea;
