import React from "react";
import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
const PhoneNumber = (props) => {
  // const [value, setValue] = useState(props.initValue || "");
  // useEffect(() => {
  //   setValue(props.initValue);
  // }, [props.initValue]);

  return (
    // <div className={`phone `}>
    //   <PhoneInput
    //     inputExtraProps={{
    //       name: "phone",
    //       required: true,
    //       autoFocus: true,
    //     }}
    //     onBlur={props.onBlur}
    //     country="us"
    //     placeholder={props.label}
    //     value={value}
    //     // onlyCountries={["us"]}
    //     disableCountryCode={true}
    //     onChange={(e) => {
    //       props.onChange(e);
    //     }}
    //   />
    //   {props.touched && props.error && (
    //     <small className="invalid-feedback d-block">{props.error}</small>
    //   )}
    // </div>
    <PhoneInput
      buttonStyle={{}}
      country={props.countryCode}
      specialLabel={props.specialLabel ? props.specialLabel : "Phone"}
      value={props.initValue}
      name={props.fieldName}
      countryCodeEditable={false}
      onChange={(phone, countryData, event, formatedValue) =>
        props.onChange(phone, countryData, event, formatedValue)
      }
      disabled={props.disabled ? props.disabled : false}
    />
  );
};

export default PhoneNumber;
