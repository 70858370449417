import moment from "moment";
import React, { useContext, useState } from "react";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router";
import { getCommissions } from "../../../api/report";
import CalendarIcon from "../../../Assets/images/calendar.svg";
import CloseIcon from "../../../Assets/images/close.svg";
import ViewIcon from "../../../Assets/images/error-outline.svg";
import ExportIcon from "../../../Assets/images/export.svg";
import SearchIcon from "../../../Assets/images/search.svg";
import Date from "../../../Components/FormControl/Date/Date";
import Select from "../../../Components/FormControl/Select/Select";
import ReactTable from "../../../Components/Table/Table";
import { AppContext } from "../../../Context/AppContext";
// import { MODULES } from "../../../Helpers/Constants";
// Context
// import { getAccessByModuleName } from "../../../Routes/Routes";
// Components
import Header from "./../../../Components/Header/Header";
import styles from "./index.module.scss";
import { Bar } from "react-chartjs-2";
import RadioBox from "Components/FormControl/RadioBox/RadioBox";
import { getAccessByModuleName } from "Routes/Routes";
import { MODULES } from "Helpers/Constants";
import CheckBox from "Components/FormControl/CheckBox/CheckBox";
import { setAllStore } from "Helpers/setAllStore";

const Commission = (props) => {
  const { showToastMessage, modules } = useContext(AppContext);
  // Add / Edit
  // const [access, setAccess] = useState({});
  let accessExport = getAccessByModuleName(modules, MODULES.COMMISSION_REPORT);

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [chartData, setChartData] = React.useState(null);
  const [type, setType] = useState("delivery_date");
  const reference = React.useRef(null);

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  const [fromDate, setFromDate] = useState(
    moment().startOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [toDate, setToDate] = useState(
    moment().endOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [selectedPeriod, setSelectedPeriod] = React.useState("weekly");
  const [selectedPeriodLabel, setSelectedPeriodLabel] = React.useState(
    "By Week"
  );

  // const [firstTimePageLoad, setFirstTimePageLoad] = React.useState(false);
  const [exportData, setExportData] = React.useState([]);
  const [exportHeader, setExportHeader] = React.useState([]);

  const [checkAllStore,setCheckAllStore] = useState(true);


  // if (accessExport && accessExport.permissions.write_access)
  // handleExportCommissions(sortBy, filters, fromDateDefault, toDateDefault);
  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
  }) => {

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);

    setChartData(null);
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });

    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      date_type: type,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
      date_range_type: selectedPeriod,
    };

    const response = await getCommissions(postData);
    if (response && response.status === 200) {
      setData(response.data.data.rows);
      setPageCount(Math.ceil(response.data.data.count / pageSize));

      const chartData = {
        labels: response.data.data.graphData.commissionDataKey,
        datasets: [
          {
            label: "Commission($)",
            data: response.data.data.graphData.commissionDataValue,
            backgroundColor: "rgb(228,224,229)",
          },
        ],
      };
      setChartData(chartData);
    } else if (response.status === 403) {
      showToastMessage(undefined, response.data.message, "error", false);
      props.history.push("/profile");
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setLoading(false);

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  const handleExportCommissions = async (
    sortBy,
    filters,
    fromDateDefault,
    toDateDefault
  ) => {

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);

    let postData = {
      // currentPage: event.pageIndex,
      // itemsPerPage: event.pageSize,
      // globalSearch: event.globalFilter,
      isExport: true,
      filters,
      sortBy,
      date_type: type,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
    };

    const response = await getCommissions(postData);

    if (response && response.status === 200) {
      setExportHeader(response.data.data.header);
      setExportData(response.data.data.data);
      reference.current?.link?.click();
    } else if (response.status === 403) {
      showToastMessage(undefined, response.data.message, "error", false);
      props.history.push("/profile");
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  const handleClearSearch = async (event) => {
    event.preventDefault();

    setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
    setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
    setSelectedPeriod("weekly");

    fetchData({
      ...defaultFilter,
      fromDateDefault: moment().startOf("isoWeek").format("ddd MMM DD, YYYY"),
      toDateDefault: moment().endOf("isoWeek").format("ddd MMM DD, YYYY"),
    });
    // handleExportCommissions(event);
  };

  const handleSelectedPeriod = async (id) => {
    if (id === "weekly") {
      setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Week");
    } else if (id === "monthly") {
      setFromDate(moment().startOf("month").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("month").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Month");
    } else if (id === "yearly") {
      setFromDate(moment().startOf("year").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("year").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Year");
    }
  };

  const handleFromDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setToDate(moment(data).add(7, "days").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "monthly") {
      setToDate(moment(data).add(1, "months").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "yearly") {
      setToDate(moment(data).add(1, "years").utc().format("ddd MMM DD, YYYY"));
    }
  };

  const handleToDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setFromDate(
        moment(data).subtract(7, "days").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "monthly") {
      setFromDate(
        moment(data).subtract(1, "months").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "yearly") {
      setFromDate(
        moment(data).subtract(1, "years").utc().format("ddd MMM DD, YYYY")
      );
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Sales Representative",
        accessor: "sales_representative",
        isVisible: true,
      },
      {
        Header: "Commission",
        accessor: "commission",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => "$" + value,
      },
      {
        Header: "No of Orders",
        accessor: "no_of_order",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Total Sales",
        accessor: "total_sales",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => "$" + value,
      },
      {
        Header: "Action",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <img
              className={`cursor-pointer mr-2 view-icon`}
              src={ViewIcon}
              alt="View Order"
              onClick={(e) => {
                e.preventDefault();
                props.history.push(`/reports/commission/${row.original.id}`);
              }}
            />
          );
        },
      },
    ],
    []
  );

  const optionsData = {
    scales: {
      y: {
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value) {
            return "$" + value;
          },
        },
      },
    },
  };

  return (
    <React.Fragment>
      <Header title="Commission Report" />

      <div id="MainContent" className="main-content">
        <div className={"row "}>
          <div className="col-12 d-flex align-items-center">
            <span
              className="mb-0 mt-2 pb-0 d-inline-bock mr-3"
              style={{ lineHeight: 1 }}
            >
              Filter By
            </span>
            <RadioBox
              selectedValue={type}
              onChange={() => {
                if (setType) {
                  setType("order_date");
                }
              }}
              label="Order date"
              value="order_date"
            />
            <RadioBox
              selectedValue={type}
              onChange={() => {
                if (setType) {
                  setType("delivery_date");
                }
              }}
              label="Delivery date"
              value="delivery_date"
            />
          </div>
          <div className="col-sm-2 col-xs-12">
            <Select
              label={selectedPeriodLabel}
              value={selectedPeriod}
              onChange={(data) => {
                setSelectedPeriod(data);
                handleSelectedPeriod(data);
              }}
              options={[
                { id: "weekly", title: "Weekly" },
                { id: "monthly", title: "Monthly" },
                { id: "yearly", title: "Yearly" },
                { id: "custom", title: "Custom" },
              ]}
              disabled={false}
            />
          </div>
          <div className="col-sm-3 col-xs-12">
            <Date
              label="Filter From"
              value={fromDate}
              dateFormat="ddd MMM DD, YYYY"
              type="date"
              fieldIcon={CalendarIcon}
              onChange={(fromDate) => {
                setFromDate(moment(fromDate).format("ddd MMM DD, YYYY"));
                handleFromDate(fromDate);
              }}
            />
          </div>
          <div className="col-sm-3 col-xs-12">
            <Date
              label="Filter To"
              value={toDate}
              dateFormat="ddd MMM DD, YYYY"
              type="date"
              fieldIcon={CalendarIcon}
              onChange={(toDate) => {
                setToDate(moment(toDate).format("ddd MMM DD, YYYY"));
                handleToDate(toDate);
              }}
            />
          </div>
          <div className="col-sm-2 col-xs-12">
            <CheckBox
            value={checkAllStore}
            label="All Stores"
            onChange={(data)=>{setCheckAllStore(data)}}
            />
          </div>
          <div className="col-sm-2 col-xs-12">
            <div className="td-actions pt-4">
              <span
                onClick={(e) => fetchData(defaultFilter)}
                className={`link cursor-pointer ${styles.searchIconWrapper}`}
              >
                <img className={styles.searchIcon} src={SearchIcon} alt="" />
              </span>
              <span
                onClick={(e) => handleClearSearch(e)}
                className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
              >
                <img className={styles.clearIcon} src={CloseIcon} alt="" />
              </span>

              {exportData && exportData.length > 0 && (
                <CSVLink
                  ref={reference}
                  style={{
                    display: "none",
                    float: "right",
                    marginTop: "25px",
                  }}
                  data={exportData}
                  headers={exportHeader}
                  filename={"CommissionReportLookup.csv"}
                  className={`link csvLink cursor-pointer ml-3 ${styles.searchIconWrapper}`}
                >
                  DownLoad
                </CSVLink>
              )}

              {accessExport && accessExport.permissions.write_access && (
                <span
                  onClick={(e) => handleExportCommissions(defaultFilter)}
                  className={`link cursor-pointer ${styles.searchIconWrapper}`}
                  style={{ float: "right" }}
                >
                  <img className={styles.searchIcon} src={ExportIcon} alt="" />
                </span>
              )}
            </div>
          </div>
        </div>
        {selectedPeriod !== "custom" &&
          chartData &&
          chartData?.datasets?.length !== 0 && (
            <Bar data={chartData} options={optionsData} height={100} />
          )}

        <div style={{ position: "absolute", width: "100%", marginTop: "60px" }}>
          <ReactTable
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            noDataText={"There is no data to display"}
            defaultFilter={defaultFilter}
            displayGlobalSearch={false}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Commission);
