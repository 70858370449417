import Interceptors from "../Interceptors/Interceptors";

class Category {
  constructor() {
    this.interceptors = new Interceptors();
  }

  async getCategoryList(data) {
    return this.interceptors.POST("categories", data);
  }

  async getCategoryNormalList(data) {
    return this.interceptors.POST("categories/normal-list", data);
  }
  
  async getCategoryHierarchyList(data="") {
    return this.interceptors.POST("categories/category-hierarchy-list",data);
  }

  async deleteCategory(categoryId) {
    return this.interceptors.DELETE("categories/" + categoryId);
  }

  async createCategory(data) {
    return this.interceptors.POST("categories/add", data);
  }

  async updateCategory(data,categoryId) {
    return this.interceptors.PUT("categories/"+categoryId, data);
  }

  async getCategoryDetail(categoryId) {
    return this.interceptors.GET("categories/"+categoryId);
  }

  async updateCategoryHierarchy(categoryId,postData) {
    return this.interceptors.PUT("categories/hierarchiesUpdate/"+categoryId,postData);
  }

  async deleteCategoryHierarchy(deleteCategoryHierarchyId) {
    return this.interceptors.DELETE("categories/hierarchiesUpdate/" + deleteCategoryHierarchyId);
  }

}

export default Category;
