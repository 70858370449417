import React, { useContext, useEffect, useState } from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// SCSS
import styles from "../Customer/Add/Add.module.scss";
import classes from "./paymentcard.module.scss";
import PaymentCardApi from "Services/PaymentCard/PaymentCard";
import { AppContext } from "Context/AppContext";
import PaymentComponent from "Components/PayabliPaymentEditCardForm/PayabliPaymentEditCardForm";
//PAYABLI
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentCardEditModal = ({
  showModal,
  pageTitle,
  data,
  setPaymentModal,
  handleCancelClick,
  entryPoint,
}) => {
  const { showToastMessage } = useContext(AppContext);
  const [submitting, setSubmitting] = useState(false);
  const paymentService = new PaymentCardApi();

  /**
   * Handles the submission of the payment card details.
   *
   * @param {string} nonce - The payment method nonce.
   * @returns {Promise<void>} - A promise that resolves when the payment card details have been updated.
   * @async
   * @function handleSubmitPaymentCard
   */
  const handleSubmitPaymentCard = async (nonce) => {
    setSubmitting(true);
    try {
      const payload = nonce;
      const sendbody = {
        id: data.id,
        paymentMethodNonce: payload,
        customer_billing_first_name: data.customer_billing_first_name,
        customer_billing_last_name: data.customer_billing_last_name,
      };
      await paymentService.updatePaymentCardDetails(sendbody).then((data) => {
        if (data && data.status === 200) {
          showToastMessage(undefined, data.data.message, "success", false);
          setPaymentModal({ isOpen: false, payment_method: "" });
        } else {
          showToastMessage(undefined, data.data.message, "error", false);
          setPaymentModal({ isOpen: false, payment_method: "" });
        }
      });
    } catch (error) {
      showToastMessage(undefined, error.data.message, "error", false);
      setPaymentModal({ isOpen: false, payment_method: "" });
    }
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={showModal.isOpen}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="form-group bmd-form-group is-filled text-left">
                  <label className={`${classes.paymentCardDetailsHeading}`}>
                    Card Number
                  </label>
                  <p className={`${classes.paymentCardDetails}`}>
                    xxxx xxxx xxxx {data.last_4number ?? "xxxx"}
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="form-group bmd-form-group is-filled text-left">
                  <label className={`${classes.paymentCardDetailsHeading}`}>
                    Card Holder
                  </label>
                  <p className={`${classes.paymentCardDetails}`}>
                    {data.name_on_card}
                  </p>
                </div>
              </div>
              <PaymentComponent
                entryPoint={entryPoint}
                handleSubmitPaymentCard={handleSubmitPaymentCard}
              />
            </div>
            <button
              data-dismiss="modal"
              className={`btn gray-btn d-inline-block mb-3 mr-2 mt-2`}
              disabled={submitting}
              onClick={() =>
                window.payabliEditCardInstance.payabliExec("method")
              }
            >
              Update
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default PaymentCardEditModal;
