import React, { useState, useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// SCSS
import styles from "./index.module.scss";
// Component
import Text from "../../FormControl/Text/Text";
import Seo from "Components/Cms/Seo";
import OGInfo from "Components/Cms/OgInfo";
import TwitterInfo from "Components/Cms/TwitterInfo";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddChangeRequest = (props) => {
  const [categoryName, setCategoryName] = useState("");
  const [categorySlug, setCategorySlug] = useState("");
  const [seoDataTemp, setSeoDataTemp] = useState([]);

  useEffect(() => {
    setCategoryName(props.defaultValue);
    setCategorySlug(props.defaultValueSlug);
    setSeoDataTemp(props.defaultSeoData);
  }, [props.defaultValue, props.defaultValueSlug]);

  const checkSeoData = async (data) => {
    return true;
  };

  const setSeoDataJson = async (data) => {
    // debugger
    let seoDataTempData = {
      ...seoDataTemp,
    };
    setSeoDataTemp({
      ...seoDataTempData,
      ...data,
    });
  };

  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          // console.log("hii23");
        }}
        className={`row`}
      >
        <Dialog
          open={props.showModal}
          TransitionComponent={Transition}
          onClose={() => {
            props.handleCancelClick();
            setCategoryName("");
            setCategorySlug("");
          }}
          classes={{
            paper: styles.modalDialog,
          }}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogContent className={`${styles.modalContent} px-0 py-0`}>
            <div className={`text-center ${styles.modalBody}`}>
              <h5 className={`my-3 text-center ${styles.modalTitle}`}>
                {props.pageTitle}
              </h5>

              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <Text
                    type="text"
                    label="Blog Category"
                    value={categoryName}
                    submitStatus={props.submitStatus}
                    onChange={(data) => {
                      setCategoryName(data);
                    }}
                    required={true}
                    id="blog_category"
                  />
                </div>
                <div className="col-md-12 col-xs-12">
                  <Text
                    type="text"
                    label="Blog Slug"
                    value={categorySlug}
                    submitStatus={props.submitStatus}
                    onChange={(data) => {
                      setCategorySlug(data);
                    }}
                    required={true}
                    id="blog_category_slug"
                  />
                </div>
              </div>

              <Seo
                data={seoDataTemp}
                setSeoData={setSeoDataJson}
                checkSeoData={checkSeoData}
              />
              <OGInfo
                data={seoDataTemp}
                setOgInfoData={setSeoDataJson}
                checkOgInfoData={checkSeoData}
              />
              <TwitterInfo
                data={seoDataTemp}
                setTwitterInfoData={setSeoDataJson}
                checkTwitterInfoData={checkSeoData}
              />

              <button
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  props.handleSubmitClick(
                    categoryName,
                    categorySlug,
                    seoDataTemp
                  );
                }}
                className="btn gray-btn d-inline-block mb-3 mr-2"
              >
                Save
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </form>
    </React.Fragment>
  );
};

export default AddChangeRequest;
