import React from "react";
import Text from "../FormControl/Text/Text";

const BillingForm = (props) => {  
  return (
    <div>
      <div className={`row`}>
        <div className={`col-md-6 col-xs-12`}>
          <Text
            type="text"
            label="Billing First Name"
            value={props.billingData.customer_billing_first_name ? props.billingData.customer_billing_first_name : "-"}
            disabled={true}
          />
        </div>
        <div className={`col-md-6 col-xs-12`}>
          <Text
            type="text"
            label="Billing Last Name"
            value={props.billingData.customer_billing_last_name ? props.billingData.customer_billing_last_name : "-"}
            disabled={true}
          />
        </div>
        <div className={`col-md-12 col-xs-12`}>
          <Text
            type="text"
            label="Billing Address"
            value={props.billingData.customer_billing_address_1 ? props.billingData.customer_billing_address_1 : "-"}
            disabled={true}
          />
        </div>
        <div className={`col-md-4 col-xs-12`}>
          <Text
            type="text"
            label="Billing City"
            value={props.billingData.customer_billing_city ? props.billingData.customer_billing_city : "-"}
            disabled={true}
          />
        </div>
        <div className={`col-md-4 col-xs-12`}>
          <Text
            type="text"
            label="Billing State"
            value={props.billingData.customer_billing_state ? props.billingData.customer_billing_state : "-"}
            disabled={true}
          />
        </div>
        <div className={`col-md-4 col-xs-12`}>
          <Text
            type="text"
            label="Billing Zipcode"
            value={props.billingData.customer_billing_zipcode ? props.billingData.customer_billing_zipcode : "-"}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
};

export default BillingForm;