import React from "react";
import Card from "./Card";
import classes from "./styles.module.scss";
const List = ({
  lists,
  onCardClick,
  selectedProducts,
  totalCount,
  loadMore,
}) => {
  return (
    <div className={classes.List}>
      {lists.length === 0 && (
        <p
          className="text-center w-100"
          style={{ gridColumn: "1 / -1", fontWeight: "700" }}
        >
          No products found for selected category
        </p>
      )}
      {lists.map((c, i) => (
        c.status === 1 && <Card
          is_selected={!!selectedProducts.find((x) => x.id === c.id)}
          key={i}
          {...c}
          onClick={() => onCardClick(c)}
        />
      ))}
      {totalCount > lists.length && (
        <div
          className="text-center w-100"
          style={{ gridColumn: "1 / -1", fontWeight: "700" }}
        >
          <span style={{ cursor: "pointer" }} onClick={loadMore}>
            Load More...
          </span>
        </div>
      )}
    </div>
  );
};

export default List;
