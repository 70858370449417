import React from "react";
import Header from "../../../Components/Header/Header";
import Tabs from "../../../Components/Settings/Tabs";
import TabPane from "../../../Components/Settings/TabPane";
import InfoSettings from "../../../Components/Settings/InfoSetting";
import EmailAndNotifications from "../../../Components/Settings/EmailNotifications";
import DeliveryCharge from "../../../Components/Settings/DeliveryCharge";
import RolloverTime from "../../../Components/Settings/RolloverTime";
import UpchargeDates from "../../../Components/Settings/UpchargeDates";
import ZipcodeSettings from "../../../Components/Settings/ZipcodeSettings";

const StoreSetting = () => {
	// const [currentTab, setCurrentTab] = React.useState([]);

	// const setCurrentTabFunc = async (name) => {
	// 	// console.log("1.0 tab selected => ", name);
	// 	setCurrentTab(name);
	// }

	return (
		<React.Fragment>
		<Header title="Store Setting"  />
		<Tabs  >
			<TabPane name="Info" key="1"   >
				<InfoSettings />
			</TabPane>
			<TabPane name="Email/Notifications" key="2">
				<EmailAndNotifications />
			</TabPane>
			<TabPane name="Rollover Time" key="3">
				<RolloverTime />
			</TabPane>
			<TabPane name="Upcharge Dates" key="4">
				<UpchargeDates />
			</TabPane>
			<TabPane name="Zipcode Settings" key="5">
				<ZipcodeSettings />
			</TabPane>
			<TabPane name="Delivery Charge" key="6">
				<DeliveryCharge />
			</TabPane>
		</Tabs>
		</React.Fragment>
	);
};
export default React.memo(StoreSetting);
