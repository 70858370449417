/* eslint-disable react-hooks/exhaustive-deps */
import { AppContext } from "Context/AppContext";
import React, { useContext, useEffect, useReducer } from "react";
// Cookies
import Cookies from "universal-cookie";
import { getProfile } from "../../api/user";
import * as User_Action from "./userAction";
import { UserReducer } from "./userReducer";
import { userInitalState } from "./userState";

export const UserContext = React.createContext({
  userState: userInitalState,
  dispatch: () => null,
});

const UserDataProvider = (props) => {
  const cookies = new Cookies();
  const { isLogin, logout } = useContext(AppContext);
  const [state, dispatch] = useReducer(UserReducer, userInitalState);
  useEffect(() => {
    if (isLogin) {
      // usersService.getProfile().then((data) => {
      getProfile()
        .then((data) => {
          dispatch({
            type: User_Action.GET_PROFILE_DATA,
            payload: data.data.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: User_Action.GET_PROFILE_DATA,
            payload: null,
          });
          logout();
        });
    } else {
      dispatch({
        type: User_Action.GET_PROFILE_DATA,
        payload: null,
      });
    }
  }, [isLogin]);
  return (
    <UserContext.Provider value={{ userState: state, dispatch }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserDataProvider;
