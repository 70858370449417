import TableContainer from "Components/Table/TableContainer";
import React from "react";
import styles from "./index.module.scss";

const RecipeIngredients = (props) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Row Material Type",
        accessor: "type",
        colWidth: 400,
      },
      {
        Header: "Row Material Name",
        accessor: "name",
        colWidth: 200,
      },
      {
        Header: "Unit",
        accessor: "unit",
      },
      {
        Header: "Quantity Needed Now",
        accessor: "qty",
      },
      {
        Header: "Current Inventory",
        accessor: "inventory_products_qty",
      },
      {
        Header: "Predicted Quantity",
        accessor: "predicted_qty",
      },
      {
        Header: "",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          const checkValue =
            row.original?.inventory_products_qty - row.original?.qty;
          return (
            checkValue < 0 ? (
              <span className={styles.negative}>Deficit</span>
            ) : (
              <span className={styles.positive}>Overage</span>
            )
          )
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
        <h3 className='bold'>Materials Required</h3>
        <TableContainer
        columns={columns}
        data={props.data}
        noDataText={'There is no data to display'}
        loading={props.loading}
        disableLastColumnFilter={true}
      />
    </React.Fragment>
  )
}

export default RecipeIngredients