import React from "react";
import Select from "../../Components/FormControl/Select/Select";
const MatrixToCopy = (props) => {
  const [value, setValue] = React.useState("");
  // const handleManageMatrixCopyClick = async (event) => {
  //   event.preventDefault();
  // };
  const changeName = (data) => {
    setValue(data);
  };

  return (
    <div className={`row mt-4`}>
      <div className={`col-md-3`}>
        <Select
          parentClass="text-left"
          label="Matrix To Copy"
          value={value}
          required={false}
          onChange={(data) => changeName(data)}
          options={props.deliveryMatrixNameList}
          disabled={false}
        />
      </div>
      <div className={`col-md-9`}>
        <div className="d-md-flex align-items-md-center justify-content-md-between my-3">
          <button
            onClick={(e) => props.handelChangeDeliveryMatrix(value)}
            className="btn pink-btn bold float-right mb-2"
          >
            Copy Times
          </button>
        </div>
      </div>
    </div>
  );
};
export default MatrixToCopy;
