import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import React from "react";
// SCSS
import styles from "./index.module.scss";
import RadioFillIcon from "../../../Assets/images/radio-fill.svg";
import RadioIcon from "../../../Assets/images/radio-outline.svg";
const RadioBox = ({
  label,
  type,
  value,
  selectedValue,
  submitStatus,
  required,
  disabled,
  onChange,
  labelClass,
}) => {
  return (
    <FormControlLabel
      label={label}
      className={`${styles.FormControlLabel} RadioButtonStyle ${labelClass}`}
      disabled={disabled}
      control={
        <Radio
          checked={selectedValue === value}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          icon={<img src={RadioIcon} alt={label} />}
          checkedIcon={<img src={RadioFillIcon} alt={label} />}
          // className={}
        />
      }
    />
  );
};

export default RadioBox;
