export const CmsPageActions = {
    UPDATE_SEO_DATA: "UPDATE_SEO_DATA",
    CHECK_SEO_DATA: "CHECK_SEO_DATA",
    UPDATE_OG_INFO_DATA: "UPDATE_OG_INFO_DATA",
    CHECK_OG_INFO_DATA: "CHECK_OG_INFO_DATA",
    UPDATE_TWITTER_INFO_DATA: "UPDATE_TWITTER_INFO_DATA",
    CHECK_TWITTER_INFO_DATA: "CHECK_TWITTER_INFO_DATA",
    UPDATE_SCHEMA_DATA: "UPDATE_SCHEMA_DATA",
    CHECK_SCHEMA_DATA: "CHECK_SCHEMA_DATA",
    CLEAR_DATA_PAGE: "CLEAR_DATA_PAGE",
    SET_PAGE_NAME: "SET_PAGE_NAME",
    SET_PAGE_SLUG_NAME: "SET_PAGE_SLUG_NAME",
    SET_PAGE_DESCRIPTION: "SET_PAGE_DESCRIPTION",
}; 