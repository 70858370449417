import {
      callDeleteApiv2,
      callPostApiv2,
      callPutApiv2,
      callGetApiv2,
      callPostApi,
      callPutApi,
      callGetApi
} from "./api";

export async function getInventoryProducts(data, notoken) {
      return callPostApiv2("inventory-products", data, {}, notoken);
}

export async function createInventoryProduct(postData) {
      return callPostApiv2("inventory-products/add", postData);
}

export async function deleteInventoryProduct(id) {
      return callDeleteApiv2("inventory-products/delete/" + id);
}

export async function updateInventoryProduct(id, postData) {
      return callPutApiv2("inventory-products/update/" + id, postData);
}


export async function updateInventoryQty(id,data){
      return callPutApiv2(`inventory-products/update-quantity/${id}`, data)
}

export async function updateInventoryReorder(id,data){
      return callPutApiv2(`inventory-products/update-reorder/${id}`, data)
}

export async function getRequestInventoryList(data){
      return callPostApiv2("inventory-resources/request/list",data)
}

// export async function getRequestedInventoryById(id){
//       return callGetApiv2(`inventory-resources/request/${id}`)
// }

export async function getRequestedInventoryById(id) {
      try {
            const response = await callGetApi({ url: `inventory-resources/request/${id}`});
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getRowMaterialsList(storeId){
      return callGetApiv2(`inventory-products/raw-material-list/${storeId}`)
}

export async function deleteRequestedInventory(id){
      return callDeleteApiv2(`inventory-resources/request/${id}`)
}

export async function addNewRequestResource(postData) {
      try {
            const response = await callPostApi({ url: "inventory-resources/request", body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateRequestInventory(id, postData){
      return callPutApiv2(`inventory-resources/request/${id}`,postData)
}

export async function addNewSendResource(postData) {
      try {
            const response = await callPostApi({ url: "inventory-resources/send-resources", body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateRequestedResourceById(id,postData) {
      try {
            const response = await callPutApi({ url: `inventory-resources/send-resources/${id}`, body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getSentRequestList(postData){
      return callPostApiv2("inventory-resources/send-resources/list",postData)
}

// export async function getDetailsSentRequest(id){
//       return callGetApiv2(`inventory-resources/send-resources/${id}`)
// }

export async function getDetailsSentRequest(id) {
      try {
            const response = await callGetApi({ url: `inventory-resources/send-resources/${id}`});
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getWasteSheetList(postData) {
      try {
            const response = await callPostApi({ url: "inventory-waste-sheets/list", body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function addNewWasteSheet(postData) {
      try {
            const response = await callPostApi({ url: "inventory-waste-sheets", body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

// export async function getWasteSheetDetails(id){
//       return callGetApiv2(`inventory-waste-sheets/${id}`)
// }

export async function getWasteSheetDetails(id) {
      try {
            const response = await callGetApi({ url: `inventory-waste-sheets/${id}`});
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateWasteSheet(id,postData) {
      try {
            const response = await callPutApi({ url: `inventory-waste-sheets/${id}`, body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function viewWasteSheetDetail(id){
      return callGetApiv2(`inventory-waste-sheets/view/${id}`)
}

export async function clearAllInventoryProduct(data){
      return callPutApiv2(`inventory-products/clear-all-invetory`, data)
}