import React, { useState } from "react";
import moment from "moment";
import { getAccessByModuleName } from "./../../../Routes/Routes";
import Header from "../../../Components/Header/Header";
import ReactTable from "../../../Components/Table/Table";
import { MODULES, STATUS_FILTER } from "./../../../Helpers/Constants";
import { AppContext } from "../../../Context/AppContext";
import { useHistory } from "react-router-dom";
import EditIcon from "./../../../Assets/images/edit.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";
import ActivatedIcon from "./../../../Assets/images/activated.svg";
import styles from "./index.module.scss";
//,odal
import DeleteConfirmation from "../../../Components/Modal/DeleteConfirmation/DeleteConfirmation";
import StatusConfirmation from "../../../Components/Modal/StatusConfirmation/StatusConfirmation";
//services
// import HolidaysCutOffApi from "../../../Services/HolidaysCutoff/HolidaysCutoff";
// import UtilsApi from "../../../Services/Utils/Utils";
import { updateStatus } from "../../../api/utils";
import {
  getHolidaysCutoffList,
  deleteHolidaysCutoff,
} from "../../../api/holidaysCutoff";
// import { DateTime } from "luxon";
// import { UTC_ZONE } from "Pages/Order/Order/utility/util";
import { convertUtcTimestampToPstDate } from "Hooks/commanUtility";
import { DateTime } from "luxon";
import { UTC_ZONE } from "Pages/Order/Order/utility/util";

const HolidaysCutOff = (props) => {
  const history = useHistory();
  const { showToastMessage, modules, setIsLoading } = React.useContext(
    AppContext
  );
  const [showDeleteModal, setShowDeleteModal] = React.useState({
    isDelete: false,
    id: null,
  });

  const [statusHolidayId, setStatusHolidayId] = useState(false);
  const [statusOfHoliday, setStatusOfHoliday] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);

  const statusList = STATUS_FILTER;
  const [data, setData] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [access, setAccess] = React.useState({});
  const [defaultFilter, setDefaultFilter] = React.useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });
  // const holidaysCutOffservice = new HolidaysCutOffApi();
  // const utilsService = new UtilsApi();
  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });

    let updatedFilters = [];

    for (let i = 0; i < filters.length; i++) {
      updatedFilters[i] = Object.assign({}, filters[i]);
    }

    let findIndexOfStartDate = updatedFilters.findIndex(
      (item) => item.id === "start_date"
    );
    let findIndexOfEndDate = updatedFilters.findIndex(
      (item) => item.id === "end_date"
    );
    if (findIndexOfStartDate >= 0) {
      updatedFilters[findIndexOfStartDate].value = moment(
        updatedFilters[findIndexOfStartDate].value,
        "ddd MMM DD, YYYY"
      ).unix();
    }
    if (findIndexOfEndDate >= 0) {
      updatedFilters[findIndexOfEndDate].value = moment(
        updatedFilters[findIndexOfEndDate].value,
        "ddd MMM DD, YYYY"
      ).unix();
    }
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters: updatedFilters,
      sortBy,
    };

    // const response = await holidaysCutOffservice.getHolidaysCutoffList(
    //   postData
    // );
    const response = await getHolidaysCutoffList(postData);
    // console.log(response);
    if (response && response.status === 200) {
      setData(response.data.data.rows);
      setPageCount(Math.ceil(response.data.data.count / pageSize));
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);
  };

  React.useEffect(getModuleAccess, [modules]);
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.HOLIDAYS_CUTOFF);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/users");
    }
  }

  const deleteCutOff = async () => {
    // const response = await holidaysCutOffservice.deleteHolidaysCutoff(
    //   showDeleteModal.id
    // );
    const response = await deleteHolidaysCutoff(showDeleteModal.id);
    if (response && response.status === 200) {
      setShowDeleteModal({ isDelete: false, id: null });
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    }
  };

  const handleStatusHolidayClick = async (event, id, status) => {
    event.preventDefault();

    setStatusHolidayId(id);
    setStatusOfHoliday(status);
    setShowStatusModal(true);
  };

  const handleHolidayStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfHoliday === 1 ? 0 : 1,
      id: statusHolidayId,
      table: "holiday_cutoffs",
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setStatusHolidayId(null);
      setStatusOfHoliday(null);
      setShowStatusModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  let holidayAccess = getAccessByModuleName(modules, MODULES.HOLIDAYS_CUTOFF);
  const columns = React.useMemo(
    () => [
      {
        Header: "Product Lable",
        accessor: "product_label",
        isVisible: true,
      },
      {
        Header: "Headline",
        accessor: "headline",
        isVisible: true,
      },
      {
        Header: "URL",
        accessor: "url",
        isVisible: true,
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        isVisible: true,
        Cell: ({ value }) =>
          DateTime.fromSeconds(value)
            .setZone(UTC_ZONE)
            .toFormat("ccc LLL dd, yyyy"),
        // isDateFilter:true,
        // Cell: ({ value }) => moment.unix(value).format("ddd MMM DD, YYYY"),
        // Cell: ({ value }) => convertUtcTimestampToPstDate(value,"ccc LLL dd, yyyy"),
      },
      {
        Header: "End Date",
        accessor: "end_date",
        isVisible: true,
        Cell: ({ value }) =>
          DateTime.fromSeconds(value)
            .setZone(UTC_ZONE)
            .toFormat("ccc LLL dd, yyyy"),
        // Cell: ({ value }) => convertUtcTimestampToPstDate(value,"ccc LLL dd, yyyy"),
      },
      {
        Header: "Status",
        accessor: "status",
        isVisible: true,
        placeholder: "Status",
        isSelectFilter: true,
        selectFilterOption: statusList,
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.status}>
              {row.original.status === 1 ? (
                <div className={styles.Active}>Active</div>
              ) : (
                <div className={styles.Inactive}>Inactive </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {holidayAccess && holidayAccess.permissions.write_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={EditIcon}
                  alt="Edit"
                  onClick={(e) => handleBlockOutUpdate(value)}
                />
              )}
              {holidayAccess && holidayAccess.permissions.delete_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={DeleteIcon}
                  alt="Delete"
                  onClick={() =>
                    setShowDeleteModal({ isDelete: true, id: value })
                  }
                />
              )}
              {holidayAccess && holidayAccess.permissions.write_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={ActivatedIcon}
                  alt=""
                  onClick={(e) =>
                    handleStatusHolidayClick(e, value, row.original.status)
                  }
                />
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const handleBlockOutUpdate = (id) => {
    history.push("/settings/holidays-cutoff/add", { id });
  };

  const handleBlockoutAdd = () => {
    history.push("/settings/holidays-cutoff/add");
  };

  return (
    <div>
      <Header
        title="Holidays CutOff"
        hasAddButton={access.write_access}
        handleAddClick={handleBlockoutAdd}
      />
      <div>
        <ReactTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
          displayColumnVisibility={false}
        />
        <DeleteConfirmation
          showModal={showDeleteModal.isDelete}
          title="Delete Holiday Cutoff"
          message="Are you sure want to delete this holiday cutoff?"
          handleCancelClick={(event) => {
            event.preventDefault();
            setShowDeleteModal({ isDelete: false, id: null });
            // setDeleteNotesId(null);
          }}
          handleSubmitClick={deleteCutOff}
        />

        <StatusConfirmation
          showModal={showStatusModal}
          title={"Holiday Cutoff"}
          message={"holiday cutoff?"}
          buttonLabel={statusOfHoliday ? "Inactivate" : "Activate"}
          statusFlag={statusOfHoliday}
          customFlag={false}
          handleCancelClick={(event) => {
            event.preventDefault();
            setShowStatusModal(false);
            setStatusHolidayId(null);
            setStatusOfHoliday(null);
          }}
          handleSubmitClick={handleHolidayStatus}
        />
      </div>
    </div>
  );
};
export default HolidaysCutOff;
