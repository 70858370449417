import React, { useReducer } from "react";
import locationReducer from "./locationReducer";
import { locationState } from "./locationState";

export const LocationContext = React.createContext({
  locationState: locationState,
  dispatch: () => null,
});

const LocationProvider = (props) => {
  const [state, dispatch] = useReducer(locationReducer, locationState);
  return (
    <LocationContext.Provider value={{ locationState: state, dispatch }}>
      {props.children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;