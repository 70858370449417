import React from "react";

// SCSS
import styles from "./OrderReason.module.scss";

const OrderReason = ({ title, reason }) => {
  return title && reason ? (
    <div className={`${styles.reasonWrapper} mt-3`}>
      <div className={`${styles.reasonTitle} ${styles.ml40}`}>
        {title}
      </div>
      <div className={`${styles.reasonText} ${styles.ml40}`}>
        {reason}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default OrderReason;
