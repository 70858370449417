import Interceptors from '../Interceptors/Interceptors';

class Notification {
    constructor() {
        this.interceptors = new Interceptors();
    }

    async list() {
        return this.interceptors.GET('notification');
    }

    async delete(id) {
        return this.interceptors.DELETE('notification/delete/' + id);
    }

    async getCount() {
        return this.interceptors.GET('notification/count');
    }
}

export default Notification;