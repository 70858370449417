import React, { useState, useContext, useEffect } from "react";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// SCSS
import styles from "./Manage.module.scss";

// Component
import Text from "./../../../FormControl/Text/Text";

/* UTILS */
import rolesApi from "./../../../../Services/Roles";
import { addRole, updateRole } from "api/roles";
import { AppContext } from "./../../../../Context/AppContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ManageRole({
  showModal,
  data,
  modules: modulesData,
  handleSubmitClick,
  handleCancelClick,
}) {
  let [title, setTitle] = useState(data.title || ""),
    [modules, setModules] = useState([]),
    [submitStatus, setSubmitStatus] = useState(false);

  useEffect(() => {
    let tempArr = data.modules;
    if (tempArr) {
      let arrayDifference = modulesData.map((data) => {
        let findData = tempArr?.find((d) => d.id === data.id);

        if (findData) {
          data["permissions"] = findData.permissions;
        }
        return data;
      });
      setModules(arrayDifference);
    } else {
      let modules = modulesData.map((module) => {
        return {
          ...module,
          permissions: {
            module_id: module.id,
            read_access: false,
            write_access: false,
            delete_access: false,
          },
        };
      });

      setModules(modules);
    }
  }, [modulesData]);

  const { showToastMessage } = useContext(AppContext);

  function onTitleChange(data) {
    setTitle(data);
  }

  function toggleAccess(index, key, checkForRead) {
    let newModules = [...modules];
    let module = newModules[index].permissions;

    module[key] = !module[key];
    if (
      (key === "write_access" || key === "delete_access") &&
      module[key] &&
      !module.read_access
    ) {
      module["read_access"] = true;
    }

    if (key === "read_access" && !module[key]) {
      module["write_access"] = false;
      module["delete_access"] = false;
    }
    if (checkForRead && !module.read_access)
      module.read_access = module.write_access || module.delete_access;

    setModules(newModules);
  }

  async function submitData(event) {
    if (event) event.preventDefault();

    setSubmitStatus(true);

    if (!title) return;

    let payload = {
      title,
      permissions: modules.map((module) => {
        return { ...module.permissions, role_id: data.id ?? null };
      }),
    };

    let response,
      isError = false;

    if (data.id)
      // MEANS EDIT
      //   response = await new rolesApi().updateRole(data.id, payload);
      response = await updateRole(data.id, payload);
    else response = await addRole(payload);

    isError = !response || response.status !== 200;

    showToastMessage(
      undefined,
      response.data.message,
      !isError ? "success" : "error",
      false
    );

    if (!isError) {
      setModules(modulesData);
      handleSubmitClick();
    }
  }

  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {data && data.id ? "Edit" : "Add"} Role & Rights
            </h5>

            <div className={styles.formContainer}>
              <div className="row">
                <div className="col-6">
                  <Text
                    type="text"
                    label="Title"
                    name={"title"}
                    value={title}
                    required={true}
                    submitStatus={submitStatus}
                    onChange={onTitleChange}
                  />
                </div>
              </div>

              <div className={styles.modulesContainer}>
                <div className="table-responsive my-4">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-left">Module</th>
                        <th className="text-left">Access</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modules &&
                        modules.map((module, index) => (
                          <tr key={module.id}>
                            <td className="py-3 text-left">{module.name}</td>
                            <td className="py-3 text-left">
                              <div className={styles.labelGrp}>
                                <div
                                  className={`${styles.label} ${
                                    module.permissions.read_access
                                      ? styles.selected
                                      : ""
                                  }`}
                                  onClick={() =>
                                    toggleAccess(index, "read_access")
                                  }
                                >
                                  Read
                                </div>
                                <div
                                  className={`${styles.label} ${
                                    module.permissions.write_access
                                      ? styles.selected
                                      : ""
                                  }`}
                                  onClick={() =>
                                    toggleAccess(index, "write_access")
                                  }
                                >
                                  Write
                                </div>
                                <div
                                  className={`${styles.label} ${
                                    module.permissions.delete_access
                                      ? styles.selected
                                      : ""
                                  }`}
                                  onClick={() =>
                                    toggleAccess(index, "delete_access")
                                  }
                                >
                                  Delete
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <a
              href="/"
              data-dismiss="modal"
              onClick={submitData}
              className="btn gray-btn d-inline-block mb-3 mr-2"
            >
              {" "}
              Save{" "}
            </a>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default ManageRole;
