import { useFormik } from "formik";

export function ProductFlagInfoForm(
      submit,
      initialVal,
      cb,
      
) {

      const validation = (values) => {
            
            let errors = {};
            let productPrimaryError = false;
            // if (!values.type && values.type == null) {
            //       errors.type = "Product Type required";
            //       productPrimaryError = true;
            // } else {
            //       productPrimaryError = false;
            // }
            
            if (cb) {
                  cb(values);
            }
            // checkProductPrimaryData(productPrimaryError);
            return errors;
      };

      const initialValues = {
            is_eligible_for_commission: false,
            is_limited_availability: false,
            is_sold_out: false,
            is_exclude_from_search: false,
            temporary_unavailable: false,
      };

      const formik = useFormik({
            initialValues: initialVal || initialValues,
            onSubmit: submit,
            enableReinitialize: true,
            validate: validation,
      });

      return { formik };
}