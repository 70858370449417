import { CheckCircle, RadioButtonUncheckedOutlined } from "@material-ui/icons";
import React from "react";
import classes from "./styles.module.scss";
const Variation = (props) => {
  return (
    <div className={classes.Variation} onClick={props.onClick}>
      <div className={classes.Image}>
        <div>
          <img
            src={props.image + "?auto=format,compress&w=380"}
            alt={props.title}
          />
        </div>
        {props.is_sold_out && <p className={classes.SoldOut}>Sold Out</p>}
      </div>
      <div>
        <p className="mt-1">{props.title}</p>
        <p
          className="d-flex justify-content-between"
          style={{ fontWeight: "700", color: "#3c4858" }}
        >
          <span>Comm. ${props.commission}</span>
        </p>
        <p className="d-flex justify-content-between">
          <span>${props.sale_price}</span>
          <span>
            {props.is_selected ? (
              <CheckCircle color="disabled" />
            ) : (
              <RadioButtonUncheckedOutlined color="disabled" />
            )}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Variation;
