import React, { useReducer } from "react";
import cmsPageReducer from "./cmsPageReducer";
import { cmsPageState } from "./cmsPageState";

export const cmsPageContext = React.createContext({
  cmsPageState: cmsPageState,
  dispatch: () => null,
});

const CmsPageProvider = (props) => {
  const [state, dispatch] = useReducer(cmsPageReducer, cmsPageState);
  return (
    <cmsPageContext.Provider value={{ cmsPageState: state, dispatch }}>
      {props.children}
    </cmsPageContext.Provider>
  );
};

export default CmsPageProvider;