import React, { useState, useContext } from "react";
import { withRouter } from "react-router";

// SCSS
import styles from "./index.module.scss";

// Components
import Text from "../../Components/FormControl/Text/Text";

// Context
import { AppContext } from "./../../Context/AppContext";

// Services
import Users from "./../../Services/Users/Users";
import { userResetPassword } from "./../../api/user";

// Images
import AdminLogo from "./../../Assets/images/admin-logo.svg";

const ResetPassword = (props) => {
  const usersService = new Users();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submitStatus, setSubmitStatus] = useState(false);

  const { showToastMessage, setIsLoading } = useContext(AppContext);

  const handleResetPassword = async (event) => {
    event.preventDefault();

    let token = new URLSearchParams(props.location.search).get("data");

    if (newPassword && confirmPassword) {
      setIsLoading(true);
      let reqBody = {
        token: token,
        new_password: newPassword,
        confirm_password: confirmPassword,
      };
      setSubmitStatus(true);

      //   const response = await usersService.resetPassword(reqBody);
      const response = await userResetPassword(reqBody);

      if (response && response.status === 200) {
        showToastMessage(undefined, response.data.message, "success", false);
        props.history.replace("/login");
      } else showToastMessage(undefined, response.data.message, "error", false);

      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div
        className={`d-flex align-items-center justify-content-center h-100 ${styles.loginWrapper}`}
      >
        <div className="w-100">
          <div className="logo d-block mb-5 text-center">
            <img src={AdminLogo} alt="Admin Logo" />
          </div>
          <div className={styles.loginBlock}>
            <div className="mt-2 h2 text-center">Reset Password</div>
            <form onSubmit={handleResetPassword}>
              <Text
                type="password"
                label="New Password"
                value={newPassword}
                required={true}
                submitStatus={submitStatus}
                onChange={(newPassword) => setNewPassword(newPassword)}
              />
              <Text
                type="password"
                label="Confirm Password"
                value={confirmPassword}
                required={true}
                submitStatus={submitStatus}
                onChange={(confirmPassword) =>
                  setConfirmPassword(confirmPassword)
                }
              />
              <div className="d-block mt-5 text-center">
                <button type="submit" className="btn gray-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ResetPassword);
