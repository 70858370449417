import Header from "Components/Header/Header";
import React, { useState, useEffect, useContext } from "react"; 
import Text from "../../../Components/FormControl/Text/Text";
import { AppContext } from "./../../../Context/AppContext";
import _ from "lodash";
import { update, getByConfigurationKey } from "../../../api/settings";
import { MODULES } from "../../../Helpers/Constants";
import { getAccessByModuleName } from "./../../../Routes/Routes";

const PetalRewardsConfig = (props) => {
    const { showToastMessage, setIsLoading, modules } = useContext(AppContext);
    const [point, setPoint] = React.useState("");
    const [amount, setAmount] = React.useState("");
    const [petalRewardsDetail, setPetalRewardsDetail] = useState({
      amount: 0,
      points: 0,
    });
    const [access, setAccess] = useState({});
    const onChange = (type, value) => {
        if (type === "point") setPoint(value);
        if (type === "amount") setAmount(value);
    
        if (type) {
          petalRewardsDetail[type] = value;
          setPetalRewardsDetail(petalRewardsDetail);
        }
      };
      useEffect(getModuleAccess, [modules]);
      useEffect(() => {
        getPetalRewardsConfig();
      }, []);

    function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.PETAL_REWARDS_CONFIG);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
        showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
        );
        window.location.href = "/dashboard";
    }
    }
        // Amount to point Config
        const getPetalRewardsConfig = async () => {
        let response = await getByConfigurationKey("petalRewardsAmountsToPoints");
            if (response && response.status === 200) {
            if (response.data.data.value["points"])
                setPoint(response.data.data.value["points"]);
            if (response.data.data.value["amount"])
                setAmount(response.data.data.value["amount"]);
                setPetalRewardsDetail(response.data.data.value);
            } else {
                showToastMessage(undefined, response.data.message, "error", false);
            }
    };
      const handleSubmitPetalRewardsClick = async (event) => {
        let pointVal = Number(point);
        if (point && point <= 0) {
        showToastMessage(
            undefined,
            "Please enter point with valid positive number greater than 0",
            "error",
            false
        );
          event.preventDefault();
        } else if (amount && amount <= 0) {
          showToastMessage(
            undefined,
            "Please enter amount with valid positive number greater than 0",
            "error",
            false
          );
          event.preventDefault();
        } else if (point && _.isInteger(pointVal) == false) {
          showToastMessage(
            undefined,
            "Please enter point with valid integer number",
            "error",
            false
          );
          event.preventDefault();
        } else {
          event.preventDefault();
          if (point && amount && petalRewardsDetail) {
            setIsLoading(true);
            petalRewardsDetail["points"] = point;
            let requestBody = {
              key: "petalRewardsAmountsToPoints",
              value: JSON.stringify(petalRewardsDetail),
            };
    
            //   let response = await configService.update(requestBody);
            let response = await update(requestBody);
    
            if (response && response.status === 200) {
              showToastMessage(undefined, response.data.message, "success", false);
              getPetalRewardsConfig();
            } else {
              showToastMessage(undefined, response.data.message, "error", false);
            }
            setIsLoading(false);
          }
        }
      };
    
    return(
        <React.Fragment>
        <Header title="Petal Rewards Config" />
        <div id="MainContent" className="main-content" />
        <h3 className="bold">Points Configuration</h3>
        {/* <PetalRewordForm  /> */}
         <div className={`row mb-5`}>
          <div className={`col-md-4`}>
            <Text
              type="number"
              label="Point"
              value={point}
              onChange={(data) => onChange("point", data)}
              id="point"
            />
          </div>
          <div className={`col-md-4`}>
            <Text
              type="number"
              label="Amount ($)"
              value={amount}
              onChange={(data) => onChange("amount", data)}
              id="Amount"
            />
          </div>
          <div className="col-md-2 mt-4 mb-4">
            {access.write_access && (
              <button
                onClick={(e) => handleSubmitPetalRewardsClick(e)}
                className="btn pink-btn bold"
              >
                Save
              </button>
            )}
          </div>
        </div>
      </React.Fragment>
    );
}

export default PetalRewardsConfig;