import React, { useEffect, useState, useContext, useMemo } from "react";
import { AppContext } from "../../../../Context/AppContext";
import styles from "../index.module.scss";
import { withRouter } from "react-router";
import { MODULES } from "Helpers/Constants";
import { getAccessByModuleName } from "Routes/Routes";
import Header from "Components/Header/Header";
import {
  getDetailsSentRequest,
  updateRequestedResourceById,
} from "api/inventoryProduct";
import { DateTime } from "luxon";
import { useFormik } from "formik";
import { Input } from "@material-ui/core";
import Text from "../../../../Components/FormControl/Text/Text";
import Confirmation from "Components/Modal/Confirmation/Confirmation";

const ResourceDetails = (props) => {
  const { modules, showToastMessage, setIsLoading } = useContext(AppContext);

  //Check page accessibility of user
  useEffect(getModuleAccess, [modules]);
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.SEND_RESOURCES);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/profile");
    }
  }
  const [data, setData] = useState({});
  const [showErrors, setShowError] = useState(false);
  const [viewResource, setViewResource] = useState(false);
  /**
   * Toggle the visibility of the confirmation modal.
   *
   * @param {boolean} showConfirmation - The current visibility state of the confirmation modal.
   * @param {function} setShowConfirmation - The function to update the visibility state of the confirmation modal.
   */
  const [showConfirmation, setShowConfirmation] = useState(false);

  const formik = useFormik({
    initialValues: {
      inventory_request_resource_id: "",
      inventory_request_resource_ingredients: [
        {
          id: "",
          source_inventory_product_id: "",
          requested_quantity: 0,
          filled_quantity: 0,
          inventory_raw_material: "",
          raw_matrial_unit: "",
          inventory_available_quantity: "",
          raw_material_price: "",
        },
      ],
    },
    onSubmit: (values) => {
      onSubmitHandler(values);
    },
    validate: (values) => {
      values.inventory_request_resource_ingredients.forEach((value) => {
        if (value.filled_quantity >= 0) {
          setShowError(false);
        } else {
          setShowError(true);
        }
      });
    },
  });

  //Use only in view case
  useEffect(() => {
    (async () => {
      const { viewId } = props?.match?.params;
      if (viewId && viewId > 0) {
        setViewResource(true);
        await requestedResourceDetailHanlder(viewId);
      } else {
        setViewResource(false);
      }
    })();
  }, []);

  //update values by key names
  const setValues = async (index, value) => {
    await formik.setValues({ ...formik.values, [index]: value });
  };

  useEffect(() => {
    (async () => {
      const { requestId } = props?.match?.params;
      if (requestId && requestId > 0) {
        await requestedResourceDetailHanlder(requestId);
      }
    })();
  }, []);

  const requestedResourceDetailHanlder = async (id) => {
    try {
      setIsLoading(true);
      const response = await getDetailsSentRequest(id);
      if (response && response.status === 200) {
        setData(response.data.data);
        ResourceFillDataHandle(response.data.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        props.history.push("/inventory-management/send-resources");
        showToastMessage(undefined, response.data.message, "error", false);
      }
    } catch (error) {
      setIsLoading(false);
      showToastMessage(undefined, error.message, "error", false);
    }
  };

  const ResourceFillDataHandle = async (data) => {
    let updatedData = data?.inventory_request_resource_ingredients?.map(
      (da) => {
        return {
          id: da.id,
          source_inventory_product_id: da?.source_inventory_product_id,
          requested_quantity: da?.requested_quantity,
          filled_quantity: da.filled_quantity,
          inventory_raw_material:
            da?.sourceInventoryProduct?.product_recipe_raw_material.title,
          raw_matrial_unit: da?.raw_matrial_unit,
          inventory_available_quantity: da?.sourceInventoryProduct?.quantity,
          raw_material_price: da?.raw_material_price,
        };
      }
    );
    await formik.setValues((value) => {
      return {
        ...value,
        inventory_request_resource_id: data.id,
        inventory_request_resource_ingredients: [...updatedData],
      };
    });
  };

  //Onchange fill quantity handler
  const onchangeFillQtyHandler = (value, position = "") => {
    const prevValue = formik.values.inventory_request_resource_ingredients;
    let newValue = prevValue[position];
    newValue.filled_quantity = value;
    formik.values.inventory_request_resource_ingredients[position] = newValue;
    setValues("inventory_request_resource_ingredients", [
      ...formik.values.inventory_request_resource_ingredients,
    ]);
  };

  //Handler final submit to update fill request resources
  const onSubmitHandler = async (value) => {
    if (showErrors) {
      setShowConfirmation(false);
      showToastMessage(
        undefined,
        "Please add all ingredients data.",
        "error",
        false
      );
      return;
    }
    try {
      setIsLoading(true);
      const response = await updateRequestedResourceById(
        value.inventory_request_resource_id,
        value
      );
      if (response && response.status === 200) {
        setIsLoading(false);
        showToastMessage(undefined, response.data.message, "success", false);
        props.history.push("/inventory-management/send-resources");
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
        setIsLoading(false);
      }
      setShowConfirmation(false);
    } catch (error) {
      setShowConfirmation(false);
      setIsLoading(false);
      showToastMessage(undefined, error.message, "error", false);
    }
  };

  return (
    <>
      <Header title={viewResource ? "View Request" : "Fill Request"} />
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <span className={styles.heading}>Request No.</span>
          <span className={styles.content}>{data?.request_number}</span>
        </div>
        <div className="d-flex flex-column">
          <span className={styles.heading}>Source Store Name</span>
          <span className={styles.content}>{data?.sourceStore?.title}</span>
        </div>
        <div className="d-flex flex-column">
          <span className={styles.heading}>Destination Store Name</span>
          <span className={styles.content}>
            {data?.destinationStore?.title}
          </span>
        </div>
        <div className="d-flex flex-column">
          <span className={styles.heading}>Request Date</span>
          <span className={styles.content}>
            {DateTime.fromISO(data?.updatedAt)
              .setZone("America/Los_Angeles")
              .toFormat("ccc LLL dd, yyyy, hh:mm a")}
          </span>
        </div>
      </div>
      <form
      onSubmit={(e) => {
        e.preventDefault();
        // formik.handleSubmit(e);
      }}
      >
        <div className="mt-5 row">
          <table className="rt-table table fs-2 border-bottom">
            <thead className="rt-thead -header">
              <tr className="rt-tr">
                <th className="rt-th rt-resizable-header">Raw Material</th>
                <th className="rt-th rt-resizable-header">Unit</th>
                <th className="rt-th rt-resizable-header">Available Qty</th>
                <th className="rt-th rt-resizable-header">Price</th>
                <th className="rt-th rt-resizable-header">Requested Qty</th>
                <th className="rt-th rt-resizable-header">Filled Qty</th>
              </tr>
            </thead>
            <tbody className="rt-tbody">
              {formik.values.inventory_request_resource_ingredients?.map(
                (da, idx) => {
                  return (
                    <tr className={viewResource ? styles.viewResource : ""}>
                      <td className="p-0">{da?.inventory_raw_material}</td>
                      <td className="p-0">{da?.raw_matrial_unit}</td>
                      <td className="p-0 pl-3">
                        {da?.inventory_available_quantity}
                      </td>
                      <td className="p-0 pl-3">{da?.raw_material_price}</td>
                      <td className="p-0 pl-3">{da?.requested_quantity}</td>
                      <td className="p-0 pl-3 col-md-2 col-xs-12" style={{ width: "20%" }}>
                        <Text
                          type="number"
                          value={da?.filled_quantity}
                          onChange={(value) => {
                            let regex = /^\d*\.?\d*$/;
                            if (regex.test(value)) {
                              onchangeFillQtyHandler(value, idx);
                            } else {
                              onchangeFillQtyHandler("", idx);
                            }
                          }}
                          disabled={viewResource}
                        />
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
        <div className={`row d-flex justify-content-center`}>
          <div>
            {!viewResource && (
              <button
                type="button"
                className="btn gray-btn d-inline-block mb-3 mr-2"
                onClick={(e) => setShowConfirmation(true)}
              >
                {"Fill Order"}
              </button>
            )}
          </div>
        </div>
      </form>
      <Confirmation
        showModal={showConfirmation}
        title={"Fill Request"}
        message={`Are you sure you want to submit?`}
        btn1_title="Submit"
        btn2_title="Cancel"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowConfirmation(false);
        }}
        handleSubmitClick={formik.handleSubmit}
      />
    </>
  );
};

export default withRouter(ResourceDetails);
