import moment from "moment";

export function postReqDeliveryMatrix(DeliveryMatrixState) {

      let activeStartDate = (DeliveryMatrixState.start_date !== '' && DeliveryMatrixState.start_date !== undefined) ? moment(DeliveryMatrixState.start_date).format("MM/DD/YYYY") : null;

      let activeEndDate = (DeliveryMatrixState.end_date !== '' && DeliveryMatrixState.end_date !== undefined) ? moment(DeliveryMatrixState.end_date).format("MM/DD/YYYY") : null;

      let zipcodeFolder = [];
      DeliveryMatrixState.delivery_matrix_zipcode_folders?.map((d, i) => (
            zipcodeFolder.push(d.id)
      ));

      let addressType = [];
      DeliveryMatrixState.delivery_matrix_address_types?.map((d, i) => (
            addressType.push(d.id)
      ));

      let days = [];
      DeliveryMatrixState.delivery_matrix_days?.map((d, i) => (
            days.push(d.id)
      ));

      let deliveryMatrixTime = [];
      DeliveryMatrixState.delivery_matrix_items?.map((d, i) => {
            // let deliveryTime = moment.tz(d.delivery_time, 'America/New_York').add(7, "hour").utc().format("X");
            // // let deliveryTimeNew = moment.unix(deliveryTime).add(7, "hour");
            // let orderByTime = moment.tz(d.order_by_time, 'America/New_York').add(7, "hour").utc().format("X");
            let deliveryTime = d.delivery_time;
            let orderByTime = d.order_by_time;
            let deliveryOrderType = (d.delivery_order_type === "sameDay") ? 'Same Day' : 'In Advance';
            if (d.id && d.id !== "" && d.id !== undefined) {
                  var tempArr = {
                        id: d.id,
                        delivery_order_type: deliveryOrderType,
                        delivery_time_type: d.delivery_time_type,
                        price: d.price,
                        delivery_time: deliveryTime,
                        order_by_time: orderByTime,
                  }
            } else {
                  var tempArr = {
                        // id: (d.id) ? d.id : null,
                        delivery_order_type: deliveryOrderType,
                        delivery_time_type: d.delivery_time_type,
                        price: d.price,
                        delivery_time: deliveryTime,
                        order_by_time: orderByTime,
                  }
            }
            deliveryMatrixTime.push(tempArr);
      });

      let post_Req = {

            name: DeliveryMatrixState.name,
            start_date: activeStartDate,
            end_date: activeEndDate,
            delivery_matrix_zipcode_folders: zipcodeFolder,
            delivery_matrix_address_types: addressType,
            delivery_matrix_days: days,
            delivery_matrix_items: deliveryMatrixTime,

      };

      return { ...post_Req };
}