import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import Text from "../FormControl/Text/Text";
import ReactTable from "../Table/Table";
import EditIcon from "./../../Assets/images/edit.svg";
import DeleteIcon from "./../../Assets/images/delete.svg";
import styles from "./index.module.scss";
import AddIcon from "./../../Assets/images/add.svg";
import ActivatedIcon from "./../../Assets/images/activated.svg";
import ManageEmailNotificationInfoModal from "./../../Components/Modal/EmailNotificationInfo";
import DeleteConfirmation from "./../../Components/Modal/DeleteConfirmation/DeleteConfirmation";
import StatusConfirmation from "./../../Components/Modal/StatusConfirmation/StatusConfirmation";
import { checkValidEmailId } from "./../../Hooks/commanUtility";

// Context
import { AppContext } from "./../../Context/AppContext";
import { getAccessByModuleName } from "./../../Routes/Routes";
import { MODULES, STATUS_FILTER } from "./../../Helpers/Constants";
// Services
// import SettingsApi from "./../../Services/Settings/Settings";
// import UtilsApi from "./../../Services/Utils/Utils";
import { updateStatus } from "./../../api/utils";
import {
  update,
  getByConfigurationKey,
  getEmailNotificationList,
  emailNotificationUpdate,
  emailNotificationAdd,
  emailNotificationDeleteById,
} from "./../../api/settings";

const EmailAndNotifications = () => {
  const [data, setData] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [
    emailNotificationDetails,
    setEmailNotificationDetails,
  ] = React.useState({});
  const [showAddModal, setShowAddModal] = React.useState(false);
  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [access, setAccess] = useState({});
  const [deleteEmailNotificationId, setDeleteEmailNotificationId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [
    manageEmailNotificationPageTitle,
    setManageEmailNotificationPageTitle,
  ] = useState(false);
  const statusList = STATUS_FILTER;
  // const settingsService = new SettingsApi();
  // const utilsService = new UtilsApi();

  // Add / Edit
  const [email_id, setEmailId] = React.useState(false);
  const [template_name, setEmailTemplateName] = React.useState(false);
  const [username, setUsername] = React.useState(false);
  const [password, setPassword] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [createdAt, setCreatedAt] = React.useState(false);
  const [updatedAt, setUpdatedAt] = React.useState(false);
  // const [emailNotifications, setEmailNotifications] = useState({
  //   email_id: "",
  //   template_name: "",
  //   username: "",
  //   password: "",
  //   status: "",
  //   createdAt: "",
  //   updatedAt: "",
  // });
  const [emailSmtpDetail, setEmailSmtpDetail] = useState({
    port: "",
    server: "",
  });
  const [port, setSmtpPort] = useState("");
  const [server, setSmtpServer] = useState("");

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });
  const [statusEmailId, setStatusEmailId] = useState(false);
  const [statusOfEmail, setStatusOfEmail] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);

  useEffect(() => {
    getEmailNotification();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.CONFIG_STORE_SETTING);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      window.location.href = "/dashboard";
    }
  }

  // Email server setting information
  const onChange = (type, value) => {
    if (type === "port") setSmtpPort(value);
    if (type === "server") setSmtpServer(value);

    if (type) {
      emailSmtpDetail[type] = value;
      setEmailSmtpDetail(emailSmtpDetail);
    }
  };

  const handleAddEmailNotification = async (
    event,
    type = "add",
    EmailNotification = {}
  ) => {
    event.preventDefault();
    setEmailNotificationDetails(EmailNotification);
    setShowAddModal(true);
    setManageEmailNotificationPageTitle("Email Notification");
  };

  const getEmailNotification = async () => {
    // let response = await settingsService.getByConfigurationKey(
    //   "emailSmtpDetail"
    // );
    let response = await getByConfigurationKey("emailSmtpDetail");
    if (response && response.status === 200) {
      if (response.data.data.value["port"])
        setSmtpPort(response.data.data.value["port"]);
      if (response.data.data.value["server"])
        setSmtpServer(response.data.data.value["server"]);
      setEmailSmtpDetail(response.data.data.value);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const handleSubmitEmailNotificationClick = async (event) => {
    event.preventDefault();
    setSubmitStatus(true);

    if (port && server && emailSmtpDetail) {
      let requestBody = {
        key: "emailSmtpDetail",
        value: JSON.stringify(emailSmtpDetail),
      };

      // let response = await settingsService.update(requestBody);
      let response = await update(requestBody);

      if (response && response.status === 200) {
        showToastMessage(undefined, response.data.message, "success", false);
        setSubmitStatus(false);
        getEmailNotification();
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
      // setIsLoading(false);
    }
  };

  // Email notification list
  const handleGetEmailNotificationList = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    // setLoading(true);
    const reqBody = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
    };

    // setIsLoading(true);
    let storeSettingAccess = getAccessByModuleName(
      modules,
      MODULES.CONFIG_STORE_SETTING
    );

    if (storeSettingAccess && storeSettingAccess.permissions.read_access) {
      // const response = await settingsService.getEmailNotificationList(reqBody);
      const response = await getEmailNotificationList(reqBody);

      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else showToastMessage(undefined, response.data.message, "error", false);
    }
    // setIsLoading(false);
    // setLoading(false);
  };

  const handleManageEmailNotificationClick = async (
    event,
    type = "add",
    emailNotification = {}
  ) => {
    event.preventDefault();
    setEmailNotificationDetails(emailNotification);
    setShowAddModal(true);
    setManageEmailNotificationPageTitle("Create Email Notification");

    if (type === "edit") {
      setManageEmailNotificationPageTitle("Edit Email Notification");

      setEmailId(emailNotification.email_id);
      setEmailTemplateName(emailNotification.template_name);
      setUsername(emailNotification.username);
      setPassword(emailNotification.password);
      setStatus(emailNotification.status);
      setCreatedAt(emailNotification.createdAt);
      setUpdatedAt(emailNotification.updatedAt);
    }
  };

  const handleManageEmailNotification = async (event) => {
    if (email_id && !checkValidEmailId(email_id)) {
      showToastMessage(
        undefined,
        "You have entered an invalid email address!",
        "error",
        false
      );
      event.preventDefault();
    } else {
      event.preventDefault();
      setSubmitStatus(true);
      if (
        email_id &&
        template_name &&
        username &&
        password &&
        (status == true || status == false)
      ) {
        setIsLoading(true);
        let reqBody = {
          email_id,
          template_name,
          username,
          password,
          status,
        };

        let response;
        if (emailNotificationDetails.id) {
          reqBody.id = emailNotificationDetails.id;

          // response = await settingsService.emailNotificationUpdate(reqBody);
          response = await emailNotificationUpdate(reqBody);
        } else response = await emailNotificationAdd(reqBody);

        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          setShowAddModal(false);
          setSubmitStatus(false);
          handleGetEmailNotificationList(defaultFilter);

          // Clear all value
          setEmailNotificationDetails({});
          setEmailId(null);
          setEmailTemplateName(null);
          setUsername(null);
          setPassword(null);
          setStatus(null);
          setCreatedAt(null);
          setUpdatedAt(null);
        } else {
          showToastMessage(undefined, response.data.message, "error", false);
        }
        setIsLoading(false);
      }
    }
  };

  const handleDeleteEmailNotificationClick = async (event, id) => {
    event.preventDefault();
    setDeleteEmailNotificationId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteEmailNotification = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // const response = await settingsService.emailNotificationDeleteById(
    //   deleteEmailNotificationId
    // );
    const response = await emailNotificationDeleteById(
      deleteEmailNotificationId
    );

    if (response && response.status === 200) {
      setDeleteEmailNotificationId(null);
      setShowDeleteModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      handleGetEmailNotificationList(defaultFilter);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const handleStatusEmailClick = async (event, id, status) => {
    event.preventDefault();

    setStatusEmailId(id);
    setStatusOfEmail(status);
    setShowStatusModal(true);
  };

  const handleEmailStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfEmail === 1 ? 0 : 1,
      id: statusEmailId,
      table: "store_settings_email_notifications",
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setShowStatusModal(false);
      setStatusEmailId(null);
      setStatusOfEmail(null);
      showToastMessage(undefined, response.data.message, "success", false);
      handleGetEmailNotificationList(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Email Address",
        accessor: "email_id",
        isVisible: true,
        disableSortBy: false,
        disableFilters: false,
        Cell: ({ value, row, column }) => {
          return `${row.original.email_id}`;
        },
      },
      {
        Header: "Email Template",
        accessor: "template_name",
        isVisible: true,
        disableSortBy: false,
        disableFilters: false,
        Cell: ({ value, row, column }) => {
          return `${row.original.template_name}`;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        isVisible: true,
        placeholder: "Status",
        isSelectFilter: true,
        selectFilterOption: statusList,
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.status}>
              {row.original.status === 1 ? (
                <div className={styles.Active}>Active</div>
              ) : (
                <div className={styles.Inactive}>Inactive </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Created",
        accessor: "createdAt",
        isVisible: true,
        disableSortBy: false,
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          return `${moment(row.original.createdAt).format("ddd MMM DD, YYYY")}`;
        },
      },
      {
        Header: "Updated",
        accessor: "updatedAt",
        isVisible: true,
        disableSortBy: false,
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          return `${moment(row.original.updatedAt).format("ddd MMM DD, YYYY")}`;
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          let accessCol = getAccessByModuleName(
            modules,
            MODULES.CONFIG_STORE_SETTING
          );
          return (
            <div>
              {accessCol.permissions.write_access && (
                <img
                  onClick={(e) =>
                    handleManageEmailNotificationClick(e, "edit", row.original)
                  }
                  className={`${styles.searchIcon} mr-4`}
                  src={EditIcon}
                  alt=""
                />
              )}
              {accessCol.permissions.delete_access && (
                <img
                  onClick={(e) => handleDeleteEmailNotificationClick(e, value)}
                  className={`${styles.searchIcon} mr-4`}
                  src={DeleteIcon}
                  alt=""
                />
              )}
              {accessCol.permissions.write_access && (
                <img
                  className={`${styles.searchIcon}`}
                  src={ActivatedIcon}
                  alt=""
                  onClick={(e) =>
                    handleStatusEmailClick(e, value, row.original.status)
                  }
                />
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="d-md-flex align-items-md-center mt-4 mb-1">
        <h3 className="bold">Basic Information</h3>
        {access.write_access && (
          <span
            className={`cursor-pointer ml-3 ${styles.searchIconWrapper}`}
            onClick={handleAddEmailNotification}
          >
            <img className={styles.searchIcon} src={AddIcon} alt="" />
          </span>
        )}
      </div>
      <div id="MainContent" className="col-12">
        <div className={`mt-5 ${styles.cardBody}`}>
          <div className="row">
            <div className="col-md-4 mt-4 mb-4">
              <Text
                type="text"
                label="SMTP Port"
                value={port}
                required={true}
                id="smtp_port"
                disabled={false}
                submitStatus={submitStatus}
                onChange={(data) => onChange("port", data)}
              />
            </div>
            <div className="col-md-4 mt-4 mb-4">
              <Text
                type="text"
                label="SMTP Server"
                value={server}
                required={true}
                id="smtp_server"
                disabled={false}
                submitStatus={submitStatus}
                onChange={(data) => onChange("server", data)}
              />
            </div>
            <div className="col-md-2 mt-4 mb-4">
              {access.write_access && (
                <button
                  onClick={(e) => handleSubmitEmailNotificationClick(e)}
                  className="btn pink-btn bold"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <ReactTable
            columns={columns}
            data={data}
            fetchData={handleGetEmailNotificationList}
            loading={loading}
            pageCount={pageCount}
            noDataText={"There is no data to display"}
            defaultFilter={defaultFilter}
            displayGlobalSearch={false}
            displayColumnVisibility={false}
          />
        </div>
      </div>
      <div>
        <DeleteConfirmation
          showModal={showDeleteModal}
          title="Delete Email Notification"
          message="Are you sure want to delete this Email notification?"
          handleCancelClick={(event) => {
            event.preventDefault();
            setShowDeleteModal(false);
            setDeleteEmailNotificationId(null);
          }}
          handleSubmitClick={handleDeleteEmailNotification}
        />

        <ManageEmailNotificationInfoModal
          showModal={showAddModal}
          pageTitle={manageEmailNotificationPageTitle}
          email_id={email_id ? email_id : ""}
          template_name={template_name ? template_name : ""}
          username={username ? username : ""}
          password={password ? password : ""}
          status={status}
          submitStatus={submitStatus}
          handleCancelClick={(e) => {
            setShowAddModal(false);

            // Clear all value
            setEmailNotificationDetails({});
            setEmailId(null);
            setEmailTemplateName(null);
            setUsername(null);
            setPassword(null);
            setStatus(null);
            setCreatedAt(null);
            setUpdatedAt(null);
            setSubmitStatus(null);
          }}
          handleSubmitClick={handleManageEmailNotification}
          onChange={(type, data) => {
            if (type === "email_id") setEmailId(data);
            if (type === "template_name") setEmailTemplateName(data);
            if (type === "username") setUsername(data);
            if (type === "password") setPassword(data);
            if (type === "status") setStatus(data);
          }}
        />
        <StatusConfirmation
          showModal={showStatusModal}
          title={"Email / Notification"}
          message={
            statusOfEmail
              ? "email / notification?"
              : "email / notification?"
          }
          buttonLabel={statusOfEmail ? "Inactivate" : "Activate"}
          statusFlag={statusOfEmail}
          customFlag={false}
          handleCancelClick={(event) => {
            event.preventDefault();
            setShowStatusModal(false);
            setStatusEmailId(null);
            setStatusOfEmail(null);
          }}
          handleSubmitClick={handleEmailStatus}
        />
      </div>
    </React.Fragment>
  );
};
export default EmailAndNotifications;
