import React, { useContext } from "react";
import InputType from "../FormControl/Text/Text";
import TextArea from "../FormControl/TextArea/TextArea";
import styles from "./index.module.scss";
import AddBlackIcon from "./../../Assets/images/add-black.svg";
import DeleteIcon from "../../Assets/images/delete.svg";

import { TwitterForm } from "../../Hooks/Seo/twitterFormFormik";

import { AppContext } from "../../Context/AppContext";
import UtilsApi from "../../Services/Utils/Utils";

const TwitterInfo = (props) => {
  const utilsService = new UtilsApi();
  const { showToastMessage, setIsLoading } = useContext(AppContext);

  const twitterData = props.data ? props.data : {};
  const twitterInitalValues = {
    twitter_title: twitterData.twitter_title ? twitterData.twitter_title : "",
    twitter_image_url: twitterData.twitter_image_url
      ? twitterData.twitter_image_url
      : "",
    twitter_description: twitterData.twitter_description
      ? twitterData.twitter_description
      : "",
    twitter_site: twitterData.twitter_site ? twitterData.twitter_site : "",
    twitter_creator: twitterData.twitter_creator
      ? twitterData.twitter_creator
      : "",
  };

  const submit = (values) => {};
  const cbSubmit = (values) => {
    props.setTwitterInfoData(values);
  };

  const { formik: twitterFormik } = TwitterForm(
    submit,
    twitterInitalValues,
    cbSubmit,
    props.checkTwitterInfoData
  );

  const twitterImageRef = React.useRef();
  const handleUploadImage = async (imageData, imageType = "OTHER") => {
    let imageFormdata = new FormData();
    imageFormdata.append("image", imageData);
    imageFormdata.append("type", imageType);
    setIsLoading(true);
    const response = await utilsService.uploadImage(imageFormdata);

    // console.log('handleUploadImage', response);
    if (response && response.status === 200) {
      setIsLoading(false);
      return response.data.data;
    } else {
      setIsLoading(false);
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };
  
  const deleteTwitterImageData = () => {
    twitterFormik.setValues((v) => {
      return {
        ...v,
        twitter_image_url: "",
      };
    });
  };

  return (
    <React.Fragment>
      <form
        onSubmit={(e) => twitterFormik.handleSubmit(e)}
        id={`twitter-section`}
      >
        <div>
          <h3 className={styles.seotitle}>Twitter Info</h3>
          <InputType
            type="text"
            label="Twitter Title"
            value={twitterFormik.values.twitter_title}
            required={true}
            onChange={(data) => {
              twitterFormik.setValues((v) => {
                return {
                  ...v,
                  twitter_title: data,
                };
              });
            }}
            onBlur={twitterFormik.handleBlur}
            touched={twitterFormik.touched.twitter_title}
            error={twitterFormik.errors.twitter_title}
            id="twitter_title"
          />
          <TextArea
            label="Twitter Description"
            rows={3}
            value={twitterFormik.values.twitter_description}
            required={true}
            onChange={(data) => {
              twitterFormik.setValues((v) => {
                return {
                  ...v,
                  twitter_description: data,
                };
              });
            }}
            onBlur={twitterFormik.handleBlur}
            touched={twitterFormik.touched.twitter_description}
            error={twitterFormik.errors.twitter_description}
            id="twitter_description"
          />
          <div className="form-group bmd-form-group is-filled text-left">
            <label className="bmd-label-floating">Twitter Image URL</label>
            <div
              onClick={() => {
                twitterImageRef.current.click();
              }}
              className={styles.uploadBox}
            >
              <input
                type="file"
                className="d-none"
                ref={twitterImageRef}
                accept="image/*"
               
                onChange={async (e) => {
                  const file = e.target.files[0];
                  const uploadResponse = await handleUploadImage(file);
                  // console.log('uploadResponse', uploadResponse);
                  twitterFormik.setValues((v) => {
                    return {
                      ...v,
                      twitter_image_url: uploadResponse[0].url,
                    };
                  });
                }}
              />
              {twitterFormik.values.twitter_image_url ? (
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={twitterFormik.values.twitter_image_url}
                  alt="Twitter_Image_URL"
                />
              ) : (
                <img src={AddBlackIcon} alt="Twitter_Image_URL" />
              )}
            </div>
            {twitterFormik.values.twitter_image_url ? (
              <img
                onClick={(e) => {
                  e.preventDefault();
                  deleteTwitterImageData();
                }}
                src={DeleteIcon}
                style={{
                  position: "absolute",
                  top: "30px",
                  left: "125px",
                  cursor: "pointer",
                }}
                width="14px"
                height="14px"
                alt="DELETE"
              />
            ) : (
              ""
            )}
            {twitterFormik.errors.twitter_image_url ? (
              <p class="invalid-feedback d-block">
                {/* {twitterFormik.errors.twitter_image_url} */}
              </p>
            ) : (
              ""
            )}
          </div>
          <InputType
            type="text"
            label="Twitter Site"
            value={twitterFormik.values.twitter_site}
            required={true}
            onChange={(data) => {
              twitterFormik.setValues((v) => {
                return {
                  ...v,
                  twitter_site: data,
                };
              });
            }}
            onBlur={twitterFormik.handleBlur}
            touched={twitterFormik.touched.twitter_site}
            error={twitterFormik.errors.twitter_site}
            id="twitter_site"
          />
          <InputType
            type="text"
            label="Twitter Creator"
            value={twitterFormik.values.twitter_creator}
            required={true}
            onChange={(data) => {
              twitterFormik.setValues((v) => {
                return {
                  ...v,
                  twitter_creator: data,
                };
              });
            }}
            onBlur={twitterFormik.handleBlur}
            touched={twitterFormik.touched.twitter_creator}
            error={twitterFormik.errors.twitter_creator}
            id="twitter_creator"
          />
          <button
            className="d-none twitter-submit-button"
            type="submit"
          ></button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default TwitterInfo;
