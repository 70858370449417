import { Close } from "@material-ui/icons";
import NewModal from "Components/NewModal/NewModal";
import List from "Components/OrderComponents/TableSection/ProductComponent/List";
import SortAndSearch from "Components/OrderComponents/TableSection/ProductComponent/SortAndSearch";
import { useAddonsCall } from "Components/OrderComponents/TableSection/ProductComponent/useAddonsCall";
import VariationView from "Components/OrderComponents/TableSection/ProductComponent/VariationView";
import React from "react";

const AddonContent = (props) => {
  const {
    setPage,
    render,
    setRender,
    setIsLoading,
    getProducts,
    setSort,
    page,
    totalCount,
    productsToAdd,
    productList,
    showToastMessage,
    setCurrentSelected,
    currentSelected,
    setProductsToAdd,
  } = useAddonsCall();

  return (
    <div style={{ height: "100vh", backgroundColor: "#fff" }}>
      <div className="container-fluid">
        <h1 className="text-center">Select Addons</h1>
        <SortAndSearch
          search={async (value) => {
            setIsLoading(true);
            await getProducts(value);
            setIsLoading(false);
          }}
          applySort={(sort) => {
            setSort(sort);
            setPage(0);
            setRender(render + 1);
          }}
        />
        <List
          loadMore={async () => {
            setIsLoading(true);
            await getProducts("", page + 1);
            setPage(page + 1);
            setIsLoading(false);
          }}
          totalCount={totalCount}
          selectedProducts={productsToAdd}
          lists={productList}
          onCardClick={(c) => {
            // if (c.is_out_of_stock) {
            //   showToastMessage(
            //     undefined,
            //     `${c.title} is currently out of stock`,
            //     "error",
            //     false
            //   );
            //   return;
            // }
            // if (c.is_sold_out) {
            //   showToastMessage(
            //     undefined,
            //     `${c.title} is currently out of stock`,
            //     "error",
            //     false
            //   );
            //   return;
            // }

            setCurrentSelected(c);
          }}
        />
      </div>
      <div
        className="py-3 text-center"
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          background: "#fff",
          zIndex: 20,
          boxShadow: "0 0 10px hsl(0deg 0% 76% / 50%)",
        }}
      >
        <button
          className="btn gray-btn"
          onClick={() => {
            props.onAdd(productsToAdd);
          }}
        >
          Add
        </button>
      </div>
      <NewModal show={!!currentSelected}>
        <Close
          onClick={() => {
            setCurrentSelected(null);
          }}
          style={{
            position: "fixed",
            top: 10,
            right: 10,
            fontSize: "2rem",
            cursor: "pointer",
          }}
        />
        <VariationView
          productSelected={productsToAdd}
          currentProduct={currentSelected}
          onVariationClick={(c) => {
            // if (c.is_sold_out) {
            //   showToastMessage(
            //     undefined,
            //     `${c.title} variation is currently sold out.`,
            //     "error",
            //     false
            //   );
            //   return;
            // }
            let added_products = [...productsToAdd];
            let alreadyAddedIndex = added_products.findIndex(
              (x) =>
                x.id === currentSelected.id && x.selectedVariant.id === c.id
            );
            let product = { ...currentSelected };
            //unique key for each product recipent wise
            product.product_key = product.product_key || `prod_${product.id}_${c.id}_${new Date().getTime()}`;
            product.qty = 1;
            product.total_price = c.price;
            product.selectedVariant = c;
            if (alreadyAddedIndex === -1) {
              added_products.push(product);
            } else {
              added_products.splice(alreadyAddedIndex, 1);
            }
            setProductsToAdd(added_products);
            setCurrentSelected(null);
          }}
        />
      </NewModal>
    </div>
  );
};

export default AddonContent;
