import { CategoryAction } from "./categoryAction";

const newRec = {
  id: 0,
  module: "category",
  position: "LEFT",
  banner_text: "",
  button_label: "",
  button_url: "",
  description: "",
  image: "",
  is_supported_image: 1,
  show_oval: 0,
};

const clrStateCategory = {
  seo: {
    id: null,
    parent_id: 0,
    module: "category-hierarchy",
    is_robot: false,
    is_no_follow: false,
    is_no_index: false,
    image_alt_text: "",
    page_title: "",
    meta_title: "",
    meta_description: "",
    canonical_url: "",
    og_title: "",
    og_description: "",
    og_image_url: "",
    og_url: "",
    og_image_alt: " ",
    og_image_type: "",
    og_image_width: 0,
    og_image_height: 0,
    og_site_name: "",
    twitter_title: "",
    twitter_description: "",
    twitter_image_url: "",
    twitter_site: "",
    twitter_creator: ""
  },
  banners: [],
  faqs:[],
  setCategoryAllData: "",
  categoryPrimaryError: false,
  category_primary_info: {
    type: null,
    inventory_remaining: 0,
    title: "",
    sequence_number: 0,
    status: "",
    keywords: "",
    customer_note: "",
    description: "",
    show_certification: false,
    category_image: "",
    is_filter: false,
    faq_title:"",
    faq_description:"",
  },
  categoryNavigationError: false,
  category_navigation_info: {
    slug: "",
    blurb: "",
  },
  seoError: false,
  ogInfoError: false,
  twitterError: false,
  schemaError: false,
  categoryHierarchyId: null,
  categoryHierarchyParentId: null
}

function CategoryReducer(state, action) {
  switch (action.type) {

    case CategoryAction.ADD_NEW_BANNER:
      let aBanner = state.banners ? [...state.banners] : [];
      aBanner.push(newRec);
      return {
        ...state,
        banners: aBanner,
      };

    case CategoryAction.REMOVE_BANNER:
      let rBanner = [...state.banners];
      rBanner.splice(action.payload, 1);
      return {
        ...state,
        banners: rBanner,
      };

    case CategoryAction.UPDATE_BANNER_DATA:
      let bannersData = [...state.banners];
      bannersData[action.payload.index] = {
        ...action.payload.values,
      };
      return {
        ...state,
        banners: bannersData,
      };

    case CategoryAction.CHECK_BANNER_DATA:
      return {
        ...state,
        bannerError: action.payload,
      };

    case CategoryAction.SET_SEO_DATA:
      let seoDataSet = { ...state.seo, ...action.payload };

      return {
        ...state,
        seo: seoDataSet,
      };

    case CategoryAction.CHECK_SEO_DATA:
      return {
        ...state,
        seoError: action.payload,
      };

    case CategoryAction.UPDATE_OG_INFO_DATA:
      let ogInfoData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: ogInfoData,
      };

    case CategoryAction.CHECK_OG_INFO_DATA:
      return {
        ...state,
        ogInfoError: action.payload,
      };

    case CategoryAction.UPDATE_TWITTER_INFO_DATA:
      let twitterInfoData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: twitterInfoData,
      };

    case CategoryAction.CHECK_TWITTER_INFO_DATA:
      return {
        ...state,
        twitterError: action.payload,
      };

    case CategoryAction.UPDATE_SCHEMA_DATA:
      let schemaData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: schemaData,
      };

    case CategoryAction.CHECK_SCHEMA_DATA:
      return {
        ...state,
        schemaError: action.payload,
      };

    case CategoryAction.SET_CATEGORY_PRIMARY_DATA:
      let primaryDataSetCategory = { ...state.category_primary_info, ...action.payload };

      return {
        ...state,
        category_primary_info: primaryDataSetCategory,
      };

    case CategoryAction.CHECK_CATEGORY_PRIMARY_DATA:
      return {
        ...state,
        categoryPrimaryError: action.payload,
      };

    case CategoryAction.SET_CATEGORY_NAVIGATION_INFO:
      let navigationDataSetCategory = {
        ...state.category_navigation_info,
        ...action.payload,
      };

      return {
        ...state,
        category_navigation_info: navigationDataSetCategory,
      };

    case CategoryAction.CHECK_CATEGORY_NAVIGATION_INFO:
      return {
        ...state,
        categoryNavigationError: action.payload,
      };

    case CategoryAction.CLEAR_CATEGORY_DATA:
      return clrStateCategory;

    case CategoryAction.SET_ALL_CATEGORY_DATA:
      return {
        ...state,
        setCategoryAllData: action.payload,
      };

    case CategoryAction.SET_CATEGORY_BANNER_DATA:
      let bannerDataSet = state.banners ? [...state.banners] : [];
      bannerDataSet.push(action.payload);
      return {
        ...state,
        banners: bannerDataSet,
      };

    case CategoryAction.SET_CATEGORY_FAQ_DATA:
      let faqDataSet = state.faqs ? [...state.faqs] : [];
      faqDataSet.push(action.payload);
      return {
        ...state,
        faqs:faqDataSet,
      }
    case CategoryAction.REMOVE_FAQ:
      let rFaq = [...state.faqs];
      rFaq.splice(action.payload, 1);
      return {
        ...state,
        faqs: rFaq,
      };

    case CategoryAction.UPDATE_FAQ_DATA:
      let faqsData = [...state.faqs];
      const current_data = faqsData[action.payload.index];
      faqsData[action.payload.index] = {
        ...current_data, ...action.payload.values,
      };
      return {
        ...state,
        faqs: faqsData,
      };

    case CategoryAction.SET_CATEGORY_HIERARCHY_ID:
      return {
        ...state,
        categoryHierarchyId: action.payload,
      };

    case CategoryAction.SET_CATEGORY_HIERARCHY_PARENT_ID:
      return {
        ...state,
        categoryHierarchyParentId: action.payload,
      };

    case CategoryAction.SET_CATEGORY_LIST_DATA:
      return {
        ...state,
        categoryListData: action.payload,
      };

    default:
      return state;
  }
}

export default CategoryReducer;
