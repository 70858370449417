import { callPostApi, callDeleteApi, callPutApi, callGetApi } from "./api";

export async function getCouponList(data) {
      // return (this.interceptors.POST("coupon/list", data));
      try {
            const response = await callPostApi({ url: "coupon/list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getCouponDetailById(id) {
      // return (this.interceptors.GET("coupon/" + id));
      try {
            const response = await callGetApi({ url: "coupon/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function deleteCoupon(id) {
      // return (this.interceptors.DELETE("coupon/" + id));
      try {
            const response = await callDeleteApi({ url: "coupon/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function createCoupon(postData) {
      // return (this.interceptors.POST("coupon/", postData));
      try {
            const response = await callPostApi({ url: "coupon/", body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateCoupon(postData, id) {
      // return (this.interceptors.PUT("coupon/" + id, postData));
      try {
            const response = await callPutApi({ url: "coupon/" + id, body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}


