import React from "react";
import Text from "../../../Components/FormControl/Text/Text";

let randomstring = require("randomstring");

const CreateCode = (props) => {
  // const [value, setValue] = React.useState("");

  const generateRandomCode = async (event) => {
    event.preventDefault();
    let randomCode = randomstring.generate({
      length: 6,
      charset: "alphanumeric",
      capitalization: "uppercase",
    });
    props.setCouponCodeName(randomCode);
  };

  return (
    <div id="MainContent" className="main-content">
      <div className="container-fluid">
        <div className="row">
          <h3 className="bold col-md-9 mt-3 mb-3 d-flex align-items-center">
            <span>Create Code</span>
          </h3>
          <h3 className="bold col-md-3 mt-3 mb-3 d-flex align-items-center">
            <h4
              className={`cursor-pointer`}
              style={{ float: "right", color: "#CB998C" }}
              onClick={(e) => generateRandomCode(e)}
            >
              Generate Code
            </h4>
          </h3>
          {/* <h4 bold mt-3 mb-3 d-flex align-items-center></h4> */}
        </div>
      </div>
      <div className="row">
        <div className={`col-12 col-mb-12`}>
          <Text
            type="text"
            label="Add Coupon Code"
            value={props.couponCodeName}
            onChange={(data) =>
              props.setCouponCodeName(data.toUpperCase().replace(/\s/g, ""))
            }
            id="add_coupon_code"
            submitStatus={props.submitStatus}
            required={true}
            disabled={false}
          />
          <p
            className={`mt-2 d-block`}
            style={{ color: "#C6C6C6", fontSize: "15px" }}
          >
            This coupon code user will use while placing the order.
          </p>
        </div>
      </div>
    </div>
  );
};
export default CreateCode;
