import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: "54px",
    height: "26px",
    padding: "0px",
    margin: "8px",
  },
  switchBase: {
    padding: "4px 5px 3px",
    color: "#CB998D !important",
    "&$checked": {
      transform: "translateX(26px)",
      color: "#CB998D !important",
      "& + $track": {
        backgroundColor: "#fff !important",
        opacity: 1,
      },
    },
    "&$focusVisible $thumb": {
      color: "#fff",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: "18px",
    height: "18px",
  },
  track: {
    borderRadius: 26 / 2,
    border: "1px solid #c4c4c4",
    backgroundColor: "#fff !important",
    opacity: "1 !important",
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const SwitchBox = ({
  label,
  type,
  value,
  submitStatus,
  required,
  disabled,
  onChange,
}) => {
  return (
    <FormControlLabel
      label={value ? "ON" : "OFF"}
      className={`Custom-SwitchBox ${
        value ? "Custom-SwitchBox-on" : "Custom-SwitchBox-off"
      }`}
      disabled={disabled}
      control={
        <IOSSwitch
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
        />
      }
    />
  );
};

export default SwitchBox;
