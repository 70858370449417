import React from "react";
import AddIcon from "../../../Assets/images/add.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";
import Select from "../../../Components/FormControl/Select/Select";
import Text from "../../../Components/FormControl/Text/Text";
import styles from "./index.module.scss";

const AddRecipeTable = (props) => {
  if (props.recipe.length && props.recipe.length > 0) {
    return props.recipe.map((val, idx) => {
      return (
        <tr key={val.index}>
          <td className="text-center col-md-4 col-xs-12 p-0 pr-3" style={{ width: "50%" }}>
            <Select
              parentClass="text-left"
              value={val.ingredients_raw_material_id}
              required={false}
              submitStatus={props.submitStatus}
              onChange={(data) => props.onChange("stockRowMaterial", data, idx)}
              options={props.rowMaterialList}
              disabled={false}
            />
          </td>
          <td className="text-center p-0">
            <Text
              value={val.ingredients_type}
              required={false}
              submitStatus={props.submitStatus}
              disabled={true}
            />
          </td>
          <td className="text-center p-0">
            <Text
              value={val.ingredients_unit}
              required={false}
              submitStatus={props.submitStatus}
              disabled={true}
            />
          </td>
          <td
            className="text-center p-0"
            style={{
              width: "15%",
            }}
          >
            <Text
              type="number"
              value={val.quantity}
              required={true}
              submitStatus={props.submitStatus}
              onChange={(data) => {
                const regex =
                  /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                if (data.length > 8) {
                } else {
                  if (regex.test(data.toString())) {
                    props.onChange("stockQuantity", data, idx);
                  } else {
                    props.onChange("stockQuantity", "", idx);
                  }
                }
              }}
              id="quantity"
            />
          </td>
          <td className="d-flex justify-content-start">
            {idx === 0 ? (
              <>
                {props.recipe.length && props.recipe.length > 1 && (
                  <div onClick={() => props.delete(val)}>
                    <span>
                      <img className={styles.icon} src={DeleteIcon} alt="" />
                    </span>
                  </div>
                )}
                <div onClick={props.add}>
                  <span className={`link cursor-pointer ${styles.iconWrapper} ml-3`}>
                    <img className={styles.icon} src={AddIcon} alt="" />
                  </span>
                </div>
              </>
            ) : (
              <div onClick={() => props.delete(val)}>
                <span>
                  <img className={styles.icon} src={DeleteIcon} alt="" />
                </span>
              </div>
            )}
          </td>
        </tr>
      );
    });
  }
};
export default AddRecipeTable;
