import { useContext } from "react";
import { HomePageContext } from "./homePageContext";
import { HomePageActions } from "./homePageActions";

export function useHomePage() {
  const { homePageState, dispatch } = useContext(HomePageContext);
  const getHomePageDetails = (payload) => {
    dispatch({ type: HomePageActions.GET_HOME_PAGE_DETAILS, payload });
  };
  const getHomePageProductCategories = (payload) => {
    dispatch({ type: HomePageActions.GET_HOME_PAGE_PRODUCT_CATEGRIES, payload });
  };
  const addNewBanner = () => {
    dispatch({ type: HomePageActions.ADD_NEW_BANNER });
  };
  const removeBanner = (index) => {
    dispatch({ type: HomePageActions.REMOVE_BANNER, payload: index });
  };
  const setBannerData = (values) => {
    dispatch({ type: HomePageActions.UPDATE_BANNER_DATA, payload: values });
  };
  const checkBannerData = (payload) => {
    dispatch({ type: HomePageActions.CHECK_BANNER_DATA, payload });
  };
  const addNewPartner = (payload) => {
    dispatch({ type: HomePageActions.ADD_NEW_PARTNER, payload });
  };
  const removePartner = (index) => {
    dispatch({ type: HomePageActions.REMOVE_PARTNER, payload: index });
  };
  const addNewCertification = (payload) => {
    dispatch({ type: HomePageActions.ADD_NEW_CERTIFICATION, payload });
  };
  const removeCertification = (index) => {
    dispatch({ type: HomePageActions.REMOVE_CERTIFICATION, payload: index });
  };
  const setSeoData = (values) => {
    dispatch({ type: HomePageActions.UPDATE_SEO_DATA, payload: values });
  };
  const checkSeoData = (payload) => {
    dispatch({ type: HomePageActions.CHECK_SEO_DATA, payload });
  };
  const setOgInfoData = (values) => {
    dispatch({ type: HomePageActions.UPDATE_OG_INFO_DATA, payload: values });
  };
  const checkOgInfoData = (payload) => {
    dispatch({ type: HomePageActions.CHECK_OG_INFO_DATA, payload });
  };
  const setTwitterInfoData = (values) => {
    dispatch({
      type: HomePageActions.UPDATE_TWITTER_INFO_DATA,
      payload: values,
    });
  };
  const checkTwitterInfoData = (payload) => {
    dispatch({ type: HomePageActions.CHECK_TWITTER_INFO_DATA, payload });
  };
  const setSchemaData = (values) => {
    dispatch({ type: HomePageActions.UPDATE_SCHEMA_DATA, payload: values });
  };
  const checkSchemaData = (payload) => {
    dispatch({ type: HomePageActions.CHECK_SCHEMA_DATA, payload });
  };
  const setFeaturedProductData = (values) => {
    dispatch({ type: HomePageActions.UPDATE_FEATURED_PRODUCT_DATA, payload: values });
  };
  const checkFeaturedProductData = (payload) => {
    dispatch({ type: HomePageActions.CHECK_FEATURED_PRODUCT_DATA, payload });
  };
  const setFeaturedCategoryData = (values) => {
    dispatch({ type: HomePageActions.UPDATE_FEATURED_CATEGORY_DATA, payload: values });
  };
  const checkFeaturedCategoryData = (payload) => {
    dispatch({ type: HomePageActions.CHECK_FEATURED_CATEGORY_DATA, payload });
  };
  const addFeaturedProduct = (payload) => {
    dispatch({
      type: HomePageActions.ADD_FEATURED_PRODUCT_PRODUCT,
      payload,
    });
  };
  const deleteFeaturedProduct = (payload) => {
    dispatch({
      type: HomePageActions.DELETE_FEATURED_PRODUCT_PRODUCT,
      payload,
    });
  };
  const setFeaturedProductSequence = (payload) => {
    dispatch({
      type: HomePageActions.SET_FEATURED_PRODUCT_SEQUENCE,
      payload,
    });
  };
  const addFeaturedCategory = (payload) => {
    dispatch({
      type: HomePageActions.ADD_FEATURED_PRODUCT_CATEGORY,
      payload,
    });
  };
  const deleteFeaturedCategory = (payload) => {
    dispatch({
      type: HomePageActions.DELETE_FEATURED_PRODUCT_CATEGORY,
      payload,
    });
  };
  const setFeaturedCategorySequence = (payload) => {
    dispatch({
      type: HomePageActions.SET_FEATURED_CATEGORY_SEQUENCE,
      payload,
    });
  };
  return {
    homePageState,
    getHomePageDetails,
    getHomePageProductCategories,
    addNewBanner,
    removeBanner,
    setBannerData,
    checkBannerData,
    addNewPartner,
    removePartner,
    addNewCertification,
    removeCertification,
    setSeoData,
    checkSeoData,
    setOgInfoData,
    checkOgInfoData,
    setTwitterInfoData,
    checkTwitterInfoData,
    setSchemaData,
    checkSchemaData,
    setFeaturedProductData,
    checkFeaturedProductData, 
    setFeaturedCategoryData,
    checkFeaturedCategoryData,
    addFeaturedProduct,
    deleteFeaturedProduct,
    addFeaturedCategory,
    deleteFeaturedCategory,
    setFeaturedProductSequence,
    setFeaturedCategorySequence,
  };
}
