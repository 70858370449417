export const SELECTED_ADDON = "SELECTED_ADDON";
export const ADDON_ADD = "ADDON_ADD";
export const ADDON_VARIANT_SELECTED = "ADDON_VARIANT_SELECTED";
export const SET_ADDON_LIST = "SET_ADDON_LIST";
export const CLEAR_SELECTED_ADDON = "CLEAR_SELECTED_ADDON";
export const SET_CURRENT_ADD = "SET_CURRENT_ADD";
export const CLEAR_CURRENT_ADD = "CLEAR_CURRENT_ADD";
export const DELETE_ADDON_SELECTED = "DELETE_ADDON_SELECTED";
export const UPDATE_QTY = "UPDATE_QTY";
export const UPDATE_SELECTED_ADDON = "UPDATE_SELECTED_ADDON";
