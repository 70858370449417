import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import { useOrderAttributeProvider } from "../context/order";
import { getCustomerCard } from "api/customer";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { getCardImage } from "../utility/util";
import { DEFAULT_BRAINTREE_STORE } from "Helpers/Constants";

const useStyles = makeStyles({
  card: {
    color: "#7d858c",
    padding: "10px 20px",
    "& p": {
      fontWeight: 300,
      fontSize: 18,
      lineHeight: "1.5",
      font: "inherit",
    },
  },
});

const Card = (props) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.card} d-flex justify-content-between align-items-center`}
      onClick={props.onClick}
      style={{
        backgroundColor: props.selected ? "rgba(203, 153, 141, 0.1)" : "#fff",
      }}
    >
      <p className="m-0">xxxx xxxx {props.last_4number}</p>
      <img
        style={{ filter: "grayscale(1)" }}
        height={25}
        src={getCardImage(props.card_type)}
        alt={props.card_type}
      />
    </div>
  );
};

const CardList = () => {
  const { currentCard, setCurrentCard } = useOrderAttributeProvider();
  const [cardList, setCardList] = useState([]);
  const formik = useFormikContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCustomerCardList();
  }, []);

  //PAYABLI
  const getCustomerCardList = React.useCallback(async () => {
    try {
      const defaultZipcode = DEFAULT_BRAINTREE_STORE;
      const response = await getCustomerCard({
        customer_id: formik.values.customer_info.customer_id,
        store_id: formik.values.billing_info.zip_code_store_id ?? defaultZipcode,
      });
      setCardList(response.data.data.rows);
      if (response.data.data.rows.length !== 0) {
        let curr =
          response.data.data.rows.find((c) => c.is_default) ||
          response.data.data.rows[0];
        setCard(curr);
      }
      setLoading(false);
    } catch (error) {
      setCardList([]);
      setLoading(false);
    }
  }, [formik.values.customer_info.customer_id, formik.values.billing_info.zip_code_store_id]);

  const setCard = (card) => {
    setCurrentCard(card);
    let billing_info = { ...formik.values.billing_info };
    billing_info.customer_billing_first_name = card.customer_billing_first_name;
    billing_info.customer_billing_last_name = card.customer_billing_last_name;
    billing_info.customer_billing_address_2 = card.address_line_2;
    billing_info.customer_billing_address_1 = card.address_line_1;
    billing_info.customer_billing_city = card.city;
    billing_info.customer_billing_state = card.state;
    billing_info.customer_billing_country = card.country;
    billing_info.customer_billing_zipcode = card.zip_code;
    formik.setValues((v) => {
      return {
        ...v,
        billing_info,
      };
    });
  };

  if (loading) {
    return (
      <div className="text-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ boxShadow: "rgb(196 196 196 / 20%) 0px 0px 10px" }}>
      {cardList.length === 0 && (
        <p>No saved cards available for the selected customer</p>
      )}
      {cardList.length !== 0 &&
        cardList.map((card) => (
          <Card
            selected={currentCard.id === card.id}
            onClick={() => setCard(card)}
            {...card}
            key={card.id}
          />
        ))}
    </div>
  );
};

export default CardList;
