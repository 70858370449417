import { AppContext } from "Context/AppContext";
import { useContext } from "react";
import { OrderActions } from "./orderActions";
import { OrderContext } from "./orderContext";
import ProductsApi from "Services/Products/Products";
export function useOrder() {
  const { orderState, dispatch } = useContext(OrderContext);
  const { showToastMessage } = useContext(AppContext);
  const productsService = new ProductsApi();
  const setActiveIndex = (index) => {
    dispatch({ type: OrderActions.SET_ACTIVE_INDEX, payload: index });
  };
  const addNewRecipent = () => {
    dispatch({ type: OrderActions.ADD_NEW_RECIPENT });
  };
  const calculateTotal = () => {
    dispatch({ type: OrderActions.CALCULATE });
  };
  const removeRecipent = (index) => {
    dispatch({ type: OrderActions.REMOVE_RECIPENT, payload: index });
    dispatch({ type: OrderActions.CALCULATE });
  };
  const setRecipentCommonForm = (values) => {
    dispatch({
      type: OrderActions.UPDATE_COMMON_RECIPIENT_FPOM,
      payload: values,
    });
  };
  const setRecipentForm = (values) => {
    dispatch({ type: OrderActions.UPDATE_RECIPIENT_FPOM, payload: values });
  };
  const setGreetingCardData = (values) => {
    dispatch({ type: OrderActions.UPDATE_GREETING_CARD_FORM, payload: values });
  };
  const setCustomerData = (values) => {
    dispatch({ type: OrderActions.UPDATE_CUSTOMER_DATA, payload: values });
  };
  const setBillingCustomerData = (values) => {
    dispatch({ type: OrderActions.UPDATE_BILLING_ADDRESS, payload: values });
  };
  const setDriverInstruction = (values) => {
    dispatch({ type: OrderActions.UPDATE_DRIVER_INSTRUCTION, payload: values });
  };
  const setDesignerInstructions = (values) => {
    dispatch({
      type: OrderActions.UPDATE_DESIGNER_INSTRUCTION,
      payload: values,
    });
  };

  const setPaymentTransatction = (values) => {
    dispatch({ type: OrderActions.SET_CARD_DETAIL, payload: values });
  };
  const setDateAndTime = (values) => {
    dispatch({
      type: OrderActions.UPDATE_DATE_TIME_INSTRUCTION,
      payload: values,
    });
  };

  const addAddonRecipent = (payload) => {
    dispatch({ type: OrderActions.ADDON_ADD, payload });
    dispatch({ type: OrderActions.CALCULATE });
  };
  const deleteAddonRecipent = (payload) => {
    dispatch({ type: OrderActions.REMOVE_ADDON_ITEM, payload });
    dispatch({ type: OrderActions.CALCULATE });
  };
  const addProductRecipent = (payload) => {
    dispatch({ type: OrderActions.PRODUCT_ADD, payload });
    dispatch({ type: OrderActions.CALCULATE });
  };
  const deleteProductRecipent = (payload) => {
    dispatch({ type: OrderActions.REMOVE_PRODUCT_ITEM, payload });
    dispatch({ type: OrderActions.CALCULATE });
  };
  const internalNotesUpdate = (payload) => {
    dispatch({ type: OrderActions.UPDATE_INTERNAL_NOTES, payload });
  };
  const editOrderDataSetUseOrder = (payload) => {
    dispatch({ type: OrderActions.GET_ORDER_DETAILS, payload });
    // dispatch({ type: OrderActions.CALCULATE });
  };
  const clearOrderState = () => {
    // dispatch({ type: OrderActions.RESET });
    dispatch({ type: OrderActions.CLR_DATA_TEST });
  };

  const resetState = () => {
    dispatch({ type: OrderActions.RESET });
  };

  const taxSetup = (payload) => {
    dispatch({ type: OrderActions.TAX_SET, payload });
    dispatch({ type: OrderActions.CALCULATE });
  };
  const addfundAmount = (payload) => {
    dispatch({ type: OrderActions.SET_ADD_FUND, payload });
    // dispatch({ type: OrderActions.CALCULATE });
  };
  const refundAmount = (payload) => {
    dispatch({ type: OrderActions.SET_REFUND, payload });
    // dispatch({ type: OrderActions.CALCULATE });
  };

  const setOrderConfig = ({
    petalConfig,
    frequencyList,
    holidayCutOff,
    uplist,
    bllist,
  }) => {
    dispatch({
      type: OrderActions.SET_ORDER_INTIAL_CONFIG,
      payload: { petalConfig, frequencyList, holidayCutOff, uplist, bllist },
    });
    // dispatch({ type: OrderActions.SET_PATEL_REWARDS, petalConfig });
    // dispatch({ type: OrderActions.SET_FERQUENCY_LIST, frequencyList });
    // dispatch({ type: OrderActions.SET_HLOIDAY_CUT_OFF, holidayCutOff });
  };

  const setPatelRewardConfig = (payload) => {
    dispatch({ type: OrderActions.SET_PATEL_REWARDS, payload });
  };
  const setBlockOutDate = (payload) => {
    dispatch({ type: OrderActions.SET_BLOCKOUT_DATE, payload });
  };
  const setUpcharge = (payload) => {
    dispatch({ type: OrderActions.SET_UP_CHGARGE, payload });
  };
  const deleteRecipent = (payload) => {
    dispatch({ type: OrderActions.DELETE_RECIPENT_ID, payload });
    dispatch({ type: OrderActions.CALCULATE });
  };
  const deleteRecipentProduct = (payload) => {
    dispatch({ type: OrderActions.DELETE_PRODUCT_ID, payload });
    dispatch({ type: OrderActions.CALCULATE });
  };
  const deleteRecipentAddon = (payload) => {
    dispatch({ type: OrderActions.DELETE_ADDON_ID, payload });
    dispatch({ type: OrderActions.CALCULATE });
  };
  const orderOverideNoteSet = (payload) => {
    dispatch({ type: OrderActions.OVERRIDE_ORDER_NOTE, payload });
  };
  const incressProduct = (payload) => {
    dispatch({ type: OrderActions.INCRESS_PRODUCT_QTY, payload });
    dispatch({ type: OrderActions.CALCULATE });
  };
  const decressProduct = (payload) => {
    dispatch({ type: OrderActions.DECRESS_PRODUCT_QTY, payload });
    dispatch({ type: OrderActions.CALCULATE });
  };
  const incressADDON = (payload) => {
    dispatch({ type: OrderActions.INCRESS_ADDON_QTY, payload });
    dispatch({ type: OrderActions.CALCULATE });
  };
  const decressADDON = (payload) => {
    dispatch({ type: OrderActions.DECRESS_ADDON_QTY, payload });
    dispatch({ type: OrderActions.CALCULATE });
  };
  const setCardList = (payload) => {
    dispatch({ type: OrderActions.SET_CARD_DATA_LIST, payload });
  };
  const setVerified = (payload) => {
    dispatch({ type: OrderActions.SET_VERIFIED });
  };
  const setCustomerBillingData = (payload) => {
    dispatch({ type: OrderActions.SET_CUSTOMER_DETAILS, payload });
  };
  const setCustomerCardDefalt = (payload) => {
    dispatch({ type: OrderActions.SET_CARD_DEFALT, payload });
  };
  const setDisscountOrder = (payload) => {
    dispatch({ type: OrderActions.SET_DISSCOUNT_DETAILS, payload });
    dispatch({ type: OrderActions.CALCULATE });
  };
  const clearCustomerData = () => {
    dispatch({ type: OrderActions.CLEAR_CUSTOMER_DATA });
  };
  const celarDiliveryData = (payload) => {
    dispatch({ type: OrderActions.CLEAR_DELIVERY_DATA, payload });
  };
  const frequencyList = (payload) => {
    dispatch({ type: OrderActions.SET_FERQUENCY_LIST, payload });
  };
  const setCustomerDataInfo = (payload) => {
    dispatch({ type: OrderActions.SET_CUSTOMER_DETAILS_INFO, payload });
  };
  const clrorderOverideNoteSet = () => {
    dispatch({ type: OrderActions.CLRINTERNALNOTE });
  };
  const clrDateandTimeFromDeliveryMatrix = () => {
    dispatch({ type: OrderActions.CLR_DATA_TEST_TIME });
    dispatch({ type: OrderActions.CALCULATE });
  };
  const checkRecipentInformation = (payload) => {
    dispatch({ type: OrderActions.CHECK_RECIPENT_INFORMATION, payload });
  };
  const checkCustomerInformation = (payload) => {
    dispatch({ type: OrderActions.CHECK_CUSTOMER_INFORMATION, payload });
  };
  const checkRecipentCommanInformation = (payload) => {
    dispatch({ type: OrderActions.CHECK_COMMAN_RECIPENT_INFORMATION, payload });
  };
  const checkDeliveryInformation = (payload) => {
    dispatch({ type: OrderActions.CHECK_DELIVERY_INFORMATION, payload });
  };
  const checkCardCompletionStatus = (payload) => {
    dispatch({ type: OrderActions.CAHECK_CARD_COMPLETION_STATUS, payload });
  };
  const setPaymentTypeList = (payload) => {
    dispatch({ type: OrderActions.SET_PAYMENT_TYPE, payload });
  };
  const setCardSignature = (payload) => {
    dispatch({ type: OrderActions.SET_CARD_SIGNATURE, payload });
  };
  const setHolidayCutoff = (payload) => {
    dispatch({ type: OrderActions.SET_HLOIDAY_CUT_OFF, payload });
  };
  const updateProductCatagery = (payload) => {
    dispatch({ type: OrderActions.UPDATE_PRODUCT_CATAGORY, payload });
  };
  const billingAddressFormCheck = (payload) => {
    dispatch({ type: OrderActions.BILLING_ADDRESS_CHECK, payload });
  };

  const duplicateOrder = (payload) => {
    dispatch({ type: OrderActions.DUPLICATE_ORDER, payload });
    dispatch({ type: OrderActions.CALCULATE });
  };

  const replaceOrder = (payload) => {
    dispatch({ type: OrderActions.REPLACE_ORDER, payload });
    dispatch({
      type: OrderActions.SET_DISSCOUNT_DETAILS,
      payload: {
        discount_percentage: 100,
        discount_price: 0,
        custom_reason_for_discount: "Replace order discount",
      },
    });
    dispatch({ type: OrderActions.CALCULATE });
  };

  const setCard = (payload) => {
    dispatch({ type: OrderActions.SET_CARD, payload });
  };

  const changeEditMode = (payload) => {
    dispatch({ type: OrderActions.SET_EDIT_MODE, payload });
  };

  const toggleOrderType = () => {
    dispatch({ type: OrderActions.CHANGE_ORDER_TYPE });
  };

  const validateOrder = () => {
    let recipientErrors = [];
    let recipentProductsError = [];

    orderState.recipents.forEach((c, i) => {
      if (!c.form.completed) {
        recipientErrors.push(i + 1);
      }
      if (!c?.added_product || c?.added_product?.length === 0) {
        recipentProductsError.push(i + 1);
      }
    });

    if (recipientErrors.length !== 0) {
      showToastMessage(
        undefined,
        `Please fill out detail for recipients ${recipientErrors.join(", ")}`,
        "error",
        false
      );
      return false;
    }

    if (recipentProductsError.length !== 0) {
      showToastMessage(
        undefined,
        `Please add at least one product for recipients ${recipentProductsError.join(
          ", "
        )}`,
        "error",
        false
      );
      return false;
    }

    if (!orderState.formInfo.completed) {
      showToastMessage(
        undefined,
        `Please fill out detail for recipients address details`,
        "error",
        false
      );
      return false;
    }

    if (!orderState.deliveryMatrixFilled) {
      showToastMessage(
        undefined,
        `Please fill out the delivery details`,
        "error",
        false
      );
      return false;
    }

    if (!orderState.billing_data.completed) {
      showToastMessage(
        undefined,
        `Please fill out the billing information`,
        "error",
        false
      );
      return false;
    }

    if (!orderState.customer_info.completed) {
      showToastMessage(
        undefined,
        `Please fill out the customer information`,
        "error",
        false
      );
      return false;
    }
    return true;
  };

  const handleGetProductData = async (callPass) => {
    if (
      orderState.order_type_identifier === 1 ||
      orderState.order_type_identifier === 2 ||
      callPass
    ) {
      let productIds = new Set();
      orderState.recipents.forEach((pdata) => {
        let plenght = pdata.added_product
          .filter((c) => !c.product_type)
          .map((z) => z.id);
        productIds.add(...plenght);
        // promises.push(productsService.getProducts(postData));
      });
      let Pids = Array.from(productIds);
      let postData = {
        currentPage: 0,
        itemsPerPage: Pids.length,
        sortBy: [],
        filters: [
          {
            id: "id",
            value: Pids,
          },
        ],
      };
      const response = await productsService.getProducts(postData);
      if (response && response.status === 200) {
        response.data.data.rows.forEach((responseData) => {
          let payLoad = {
            product_id: responseData.id,
            product_categories: responseData.product_categories,
          };
          updateProductCatagery(payLoad);
        });
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }
    // }
  };

  const clearDeliveryDateTime = () => {
    dispatch({ type: OrderActions.CLEAR_DELIVERY_DATE_TIME });
  };

  return {
    clearDeliveryDateTime,
    toggleOrderType,
    validateOrder,
    setCard,
    changeEditMode,
    checkCustomerInformation,
    billingAddressFormCheck,
    updateProductCatagery,
    setHolidayCutoff,
    setPaymentTypeList,
    setCardSignature,
    checkCardCompletionStatus,
    checkDeliveryInformation,
    checkRecipentCommanInformation,
    checkRecipentInformation,
    clrDateandTimeFromDeliveryMatrix,
    clearCustomerData,
    clrorderOverideNoteSet,
    frequencyList,
    taxSetup,
    setCustomerDataInfo,
    celarDiliveryData,
    setBillingCustomerData,
    setCustomerBillingData,
    setDisscountOrder,
    setCustomerCardDefalt,
    setVerified,
    setCardList,
    incressADDON,
    decressADDON,
    incressProduct,
    decressProduct,
    orderOverideNoteSet,
    deleteRecipent,
    deleteRecipentAddon,
    deleteRecipentProduct,
    setBlockOutDate,
    setUpcharge,
    setPatelRewardConfig,
    addfundAmount,
    refundAmount,
    orderState,
    clearOrderState,
    editOrderDataSetUseOrder,
    addProductRecipent,
    addAddonRecipent,
    internalNotesUpdate,
    deleteProductRecipent,
    deleteAddonRecipent,
    setDriverInstruction,
    setDateAndTime,
    setPaymentTransatction,
    setDesignerInstructions,
    setCustomerData,
    setRecipentForm,
    setActiveIndex,
    addNewRecipent,
    removeRecipent,
    setRecipentCommonForm,
    setOrderConfig,
    setGreetingCardData,
    calculateTotal,
    resetState,
    duplicateOrder,
    replaceOrder,
    handleGetProductData,
  };
}
