import moment from "moment";

export function blogDataCall(blogState) {

      let categoriesData = [];
      blogState.categories?.map((d, i) => {
            categoriesData.push(d.categoryId);
      });

      if (blogState.banners !== undefined && blogState.banners.length > 0) {
            blogState.banners?.map((d, i) => {
                  if (blogState.banners[i]['id'] == null || blogState.banners[i]['id'] === undefined || blogState.banners[i]['id'] === 0) {
                        delete blogState.banners[i]['id'];
                        delete blogState.banners[i]['parent_id'];
                  }
            });
      }
      let bannersData = (blogState.banners !== undefined && blogState.banners.length > 0) ? blogState.banners : [];

      delete blogState.seo['id']
      let seoData = blogState.seo;

      let similarBlogData = [];
      if (blogState.similarBlogData !== undefined && blogState.similarBlogData.length > 0) {
            blogState.similarBlogData?.map((d, i) => {
                  similarBlogData.push(d.similar_blog_id);
            });
      }

      let reqBody = {
            title: blogState.primary_info.title,
            detail: blogState.primary_info.detail,
            slug: blogState.primary_info.slug,
            description: blogState.primary_info.description,
            keywords: blogState.primary_info.keywords,
            author_name: blogState.primary_info.author_name,
            categories: categoriesData,
            banners: bannersData,
            similar_blogs: similarBlogData,
            seo: seoData,
            url_category_slug: blogState.primary_info.url_category_slug, 
      };

      if(blogState.primary_info.read_time && blogState.primary_info.read_time !== "") {
            reqBody.read_time = blogState.primary_info.read_time
      }

      if(blogState.primary_info.blog_date && blogState.primary_info.blog_date !== "") {
            reqBody.blog_date = moment(blogState.primary_info.blog_date).format("YYYY-MM-DD")
      }


      return { ...reqBody };
}