import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import React, { useState } from "react";
// import { useOrder } from "../../../Context/OrderContext/useOrder";
// Component
import Text from "./../../FormControl/Text/Text";
// SCSS
import styles from "./OrderFund.module.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProductSequnceModel = ({
  showModal,
  pageTitle,
  submitStatus,
  fundAmount,
  onChange,
  handleCancelClick,
  handleSubmitClick,
  ...props
}) => {
  const [checkSequnseError, setCheckSequnseError] = useState(false);
  const onsetSequnce = () => {
    let re = /^[0-9\b]+$/;
    let testre = re.test(props.sequnceNumber);
    if (!testre && props.sequnceNumber !== "" && props.sequnceNumber !== 0) {
      setCheckSequnseError(true);
      //   showToastMessage(undefined, "Enter valid value", "error", false);
    } else {
      setCheckSequnseError(false);
      props.submitCall(props.productId, +props.sequnceNumber, props.index);
      handleCancelClick();
    }
  };
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="row">
              <div className={`col-md-4 col-xs-12 mt-5 ${styles.marginAuto}`}>
                <Text
                  type="text"
                  label="Sequence"
                  value={props.sequnceNumber}
                  onChange={(data) => {
                    props.setSequnceNumber(data);
                  }}
                />
              </div>
            </div>
            {checkSequnseError && (
              <p className="invalid-feedback d-block">Enter valid value</p>
            )}
            <div className="d-flex align-items-center justify-content-center">
              <span
                data-dismiss="modal"
                onClick={() => {
                  onsetSequnce();
                }}
                className="btn gray-btn d-inline-block mt-5 mb-5 mr-2 mt-5 cursor-pointer"
              >
                Save
              </span>
              <span
                data-dismiss="modal"
                onClick={() => {
                  //   setsequnce(0);
                  setCheckSequnseError(false);
                  handleCancelClick();
                }}
                className="btn gray-btn d-inline-block mt-5 mb-5 mr-2 mt-5 cursor-pointer"
              >
                Cancel
              </span>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ProductSequnceModel;
