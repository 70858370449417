import * as Addon_ACTIONS from "./AddonAction";

export function AddonReducer(state, action) {
  switch (action.type) {
    case Addon_ACTIONS.SELECTED_ADDON:
      return {
        ...state,
        selectedAddon: action.payload,
      };
    case Addon_ACTIONS.SET_ADDON_LIST:
      // console.log("Addon List ", action.payload);
      return {
        ...state,
        addonList: action.payload,
      };
    case Addon_ACTIONS.CLEAR_SELECTED_ADDON:
      return {
        ...state,
        selectedAddon: null,
      };
    case Addon_ACTIONS.SET_CURRENT_ADD:
      return {
        ...state,
        selectedAddonArray: action.payload,
      };
    case Addon_ACTIONS.CLEAR_CURRENT_ADD:
      let emptyArray = [];
      return {
        ...state,
        setCurrentAddonToAdd: null,
        selectedAddonArray: emptyArray,
      };
    case Addon_ACTIONS.UPDATE_SELECTED_ADDON:
  
      return {
        ...state,
        selectedAddonArray: action.payload,
      };
    case Addon_ACTIONS.ADDON_ADD:
      let p = [...state.addedAddons];
      let selectedAddon = action.payload;
      selectedAddon["qty"] = 1;
      p.push(selectedAddon);
      return {
        ...state,
        addedAddons: p,
      };
    case Addon_ACTIONS.DELETE_ADDON_SELECTED:
      let data = [...state.addedAddons];
      data.splice(action.payload, 1);
      return {
        ...state,
        addedAddons: data,
      };
    default:
      return state;
  }
}
