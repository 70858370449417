/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import debounce from "lodash/debounce";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "../../Assets/images/add.svg";
import { useOrder } from "../../Context/OrderContext/useOrder";
import { useProduct } from "../../Context/Product/useProduct";
import Text from "../FormControl/Text/TextInput";
// Context
import { AppContext } from "./../../Context/AppContext";
// Services
// import ProductsApi from "./../../Services/Products/Products";
import { getProducts } from "../../api/product"

// SCSS
import styles from "./index.module.scss";
// COMPONENTS
import ProductsDrawer from "./ProductsDrawer";

function Products(props) {
  const { showToastMessage, setIsLoading } = useContext(AppContext);
  const { setProductList, productState, clearCurrentAdd } = useProduct();

  //State for opening and closing drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [price, setPrice] = React.useState({ value: "aes", label: "" });
  const [searchValue, setSearchValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(12);
  const [addCmsProduct, setAddCmsProduct] = React.useState(
    productState.productList
  );
  const [selectedProductArray, setSelectedProductArray] = React.useState([]);
  const [searchString, setSearchString] = React.useState("");
  const [urlSlugData, setUrlSlugData] = React.useState({
    categoryHierarchyIds: [],
    hasLocation: false,
  });
  const [sortData, setSortData] = React.useState("");
  const [urlLink, setUrlLink] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const {
    orderState,
    deleteProductRecipent,
    deleteRecipentProduct,
    incressProduct,
    decressProduct,
  } = useOrder();
  // const productsService = new ProductsApi();

  const deleteData = (i) => {
    deleteProductRecipent(i);
  };
  const deleteDataProduct = (id, index) => {
    // let productLenght =
    //   orderState.recipents[orderState.activeRecipentIndex].added_product.length;
    deleteRecipentProduct(id);
    deleteData(index);
    // if (productLenght > 1 || productLenght !== 1) {
    // } else {
    //   showToastMessage(
    //     undefined,
    //     "You need to atleast one product selected",
    //     "error",
    //     false
    //   );
    // }
  };

  useEffect(() => {
    // if (selectedProductArray?.length <= 0 && props.productList?.length > 0) {
    // console.log("setSelectedProductArray 000", props.productList);
    setSelectedProductArray(props.productList);
    // }
  }, [props.productList]);
  useEffect(() => {
    if (!props.isVarianNeeded && props.addProduct) {
      let arrayOfProduct = productState.productList.map((data) => {
        data["isSelected"] = false;
        data["sequence_number"] = 1;
        return data;
      });
      setAddCmsProduct(arrayOfProduct);
    }
  }, [props.isVarianNeeded, productState.productList]);
  useEffect(() => {
    (async () => {
      if (
        !props.isVarianNeeded &&
        props.addProduct &&
        props.productList?.length > 0
      ) {
        let arrayOfProduct = await productState.productList.map((data) => {
          data["isSelected"] = false;
          return data;
        });
        props.productList?.forEach((productLisData) => {
          //
          let indexOfarrayOfProduct = arrayOfProduct.findIndex(
            (arrayOfProduct) => {
              return arrayOfProduct.id === productLisData.id;
            }
          );
          if (indexOfarrayOfProduct > -1) {
            arrayOfProduct[indexOfarrayOfProduct]["isSelected"] = true;
          }
        });
        if (selectedProductArray.length > 0) {
          selectedProductArray?.forEach((productLisData) => {
            let indexOfarrayOfProduct = arrayOfProduct.findIndex(
              (arrayOfProduct) => {
                return arrayOfProduct.id === productLisData.id;
              }
            );
            if (indexOfarrayOfProduct > -1) {
              arrayOfProduct[indexOfarrayOfProduct]["isSelected"] = true;
            }
          });
        }
        setAddCmsProduct(arrayOfProduct);
      }
    })();
  }, [props.isVarianNeeded, props.productList, productState.productList]);
  const handleManageProductSelected = (data) => {
    let selectedProductList = [...selectedProductArray];
    if (selectedProductList.length === 0) {
      let selectedProduct = { ...data, isSelected: true };
      selectedProductList.push(selectedProduct);
    } else {
      // Current selected product array
      // selectedProductList.forEach((productdata) => {
      let selectedProductIndex = selectedProductList.findIndex((item) => {
        return item.id === data.id;
      });
      if (selectedProductIndex === -1) {
        let selectedProduct = { ...data, isSelected: true };
        selectedProductList.push(selectedProduct);
      } else {
        selectedProductList.splice(selectedProductIndex, 1);
      }
      // });
    }

    console.log("Product selected array", selectedProductList);
    setSelectedProductArray(selectedProductList);
  };
  const addProductCms = async (data) => {
    let addCmsProductList = [...addCmsProduct];
    let productlistIndex = addCmsProduct.findIndex(
      (item) => item.id === data.id
    );

    if (productlistIndex === -1) {
      addCmsProductList[productlistIndex]["isSelected"] = false;
    } else {
      if (addCmsProductList[productlistIndex]["isSelected"] === true) {
        addCmsProductList[productlistIndex]["isSelected"] = false;
      } else {
        addCmsProductList[productlistIndex]["isSelected"] = true;
      }
    }
    await handleManageProductSelected(data);
    setAddCmsProduct(addCmsProductList);
  };
  const handleAddProductSubmitCms = () => {
    let submitProductArray = [...addCmsProduct];
    let addProductArray = [];
    submitProductArray.forEach((spdata) => {
      if (spdata.isSelected === true) {
        addProductArray.push(spdata);
      }
    });
    console.log("Added Product", addProductArray);

    props.addProductList(selectedProductArray);
    setSelectedProductArray([]);
    // setAddCmsProduct([]);
  };
  const handleProductDeleteCms = (id) => {
    let submitProductArray = [...props.productList];
    submitProductArray.splice(id, 1);
    props.removeProductList(submitProductArray);
  };
  const scrollTimeProductsCall = async () => {
    let local = orderState.formInfo.is_local;
    let postData = {};
    if (local) {
      postData = {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        sortBy: [{ id: "id", desc: false }],

        filters: [
          {
            id: "type",
            value: "NON_ADD_ONS",
          },
          {
            id: "url-slugs",
            value: urlLink.length === 0 ? ["flowers"] : urlLink,
          },
        ],
      };
      if (urlSlugData.categoryHierarchyIds.length > 0) {
        postData = {
          ...postData,
          urlSlugData: {
            categoryHierarchyIds: urlSlugData.categoryHierarchyIds,
            hasLocation: urlSlugData.hasLocation,
          },
        };
      }
      if (searchString !== "" || searchString.length > 0) {
        let filters = [...postData.filters];
        let searchData = {
          id: "title",
          value: searchString,
        };
        filters.push(searchData);
        postData = {
          ...postData,
          filters: filters,
        };
      }
      if (sortData !== "") {
        let sortedBy = [];
        if (sortData === "aec") {
          sortedBy = [
            {
              id: "price",
            },
          ];
        } else {
          sortedBy = [
            {
              id: "price",
              desc: "desc",
            },
          ];
        }
        postData = {
          ...postData,
          sortBy: sortedBy,
        };
      }
    } else {
      postData = {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        sortBy: [{ id: "id", desc: false }],
        filters: [
          {
            id: "type",
            value: "NON_ADD_ONS",
          },
          {
            id: "is_local_only",
            value: 0,
          },
          {
            id: "url-slugs",
            value: urlLink.length === 0 ? ["flowers"] : urlLink,
          },
        ],
      };
      if (urlSlugData.categoryHierarchyIds.length > 0) {
        postData = {
          ...postData,
          urlSlugData: {
            categoryHierarchyIds: urlSlugData.categoryHierarchyIds,
            hasLocation: urlSlugData.hasLocation,
          },
        };
      }
      if (searchString !== "" || searchString.length > 0) {
        let filters = [...postData.filters];
        let searchData = {
          id: "title",
          value: searchString,
        };
        filters.push(searchData);
        postData = {
          ...postData,
          filters: filters,
        };
      }
      if (sortData !== "") {
        let sortedBy = [];
        if (sortData === "aec") {
          sortedBy = [
            {
              id: "price",
            },
          ];
        } else {
          sortedBy = [
            {
              id: "price",
              desc: "desc",
            },
          ];
        }
        postData = {
          ...postData,
          sortBy: sortedBy,
        };
      }
    }
    console.log("Post Data", postData);
    // const response = await productsService.getProducts(postData);
    const response = await getProducts(postData);
    if (response && response.status === 200) {
      let productList = [];
      let default_varaition = {
        title: "",
        commision: 0,
      };
      response.data.data.rows.forEach((data, index) => {
        data.product_variations.forEach((dataVariation) => {
          if (dataVariation.is_default_selected === true)
            default_varaition = {
              title: dataVariation.title,
              commision: dataVariation.commission,
            };
        });
        data["defalt_variation"] = default_varaition;
        // productList[index] = data;
        productList.push(data);
      });
      let list = [...productState.productList, ...response.data.data.rows];
      setProductList(list);
      // setAddCmsProduct(response.data.data.rows);
      setCurrentPage(currentPage + 1);
      setCount(response.data.data.count);
    } else showToastMessage(undefined, response.data.message, "error", false);
  };
  const handleGetProducts = async () => {
    console.log("CALLING.....");
    let local = orderState.formInfo.is_local;
    let postData = {};
    if (local) {
      postData = {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        sortBy: [
          {
            id: "sequence_number",
            asc: "asc",
          },
        ],
        filters: [
          {
            id: "type",
            value: "NON_ADD_ONS",
          },
          {
            id: "url-slugs",
            value: ["flowers"],
          },
        ],
      };
    } else {
      postData = {
        currentPage: 0,
        itemsPerPage: itemsPerPage,
        sortBy: [
          {
            id: "sequence_number",
            asc: "asc",
          },
        ],
        filters: [
          {
            id: "type",
            value: "NON_ADD_ONS",
          },
          {
            id: "is_local_only",
            value: 0,
          },
          {
            id: "url-slugs",
            value: ["flowers"],
          },
        ],
      };
    }
    // setIsLoading(true);
    // const response = await productsService.getProducts(postData);
    const response = await getProducts(postData);

    if (response && response.status === 200) {
      let productList = [];
      let default_varaition = {
        title: "",
        commision: 0,
      };
      setIsLoading(false);
      response.data.data.rows.forEach((data, index) => {
        data.product_variations.forEach((dataVariation) => {
          if (dataVariation.is_default_selected === true)
            default_varaition = {
              title: dataVariation.title,
              commision: dataVariation.commission,
            };
        });
        data["defalt_variation"] = default_varaition;
        // productList[index] = data;
        productList.push(data);
      });
      setProductList(response.data.data.rows);
      setAddCmsProduct(...response.data.data.rows);
      let urlSlug = {
        categoryHierarchyIds:
          response.data.data.urlSlugInfo.categoryHierarchyIds,
        hasLocation: response.data.data.urlSlugInfo.hasLocation,
      };
      setUrlSlugData(urlSlug);
      setCurrentPage(1);
      setCount(response.data.data.count);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
      setIsLoading(false);
    }
  };

  //Function for searching product
  const handleProductSearch = async (data) => {
    let local = orderState.formInfo.is_local;
    setSearchString(data);
    let post_data = {};
    if (local) {
      post_data = {
        currentPage: 0,
        itemsPerPage: itemsPerPage,
        sortBy: [
          {
            id: "sequence_number",
            asc: "asc",
          },
        ],
        filters: [
          {
            id: "title",
            value: data,
          },
          {
            id: "type",
            value: "NON_ADD_ONS",
          },
          {
            id: "url-slugs",
            value: urlLink.length === 0 ? ["flowers"] : urlLink,
          },
        ],
      };
    } else {
      post_data = {
        currentPage: 0,
        itemsPerPage: itemsPerPage,
        sortBy: [
          {
            id: "sequence_number",
            asc: "asc",
          },
        ],
        filters: [
          {
            id: "title",
            value: data,
          },
          {
            id: "type",
            value: "NON_ADD_ONS",
          },
          {
            id: "is_local_only",
            value: 0,
          },
          {
            id: "url-slugs",
            value: urlLink.length === 0 ? ["flowers"] : urlLink,
          },
        ],
      };
    }
    // setIsLoading(true);
    // const response = await productsService.getProducts(post_data);
    const response = await getProducts(post_data);
    if (response && response.status === 200) {
      let productList = [];
      let default_varaition = {
        title: "",
        commision: 0,
      };
      // setIsLoading(false);
      response.data.data.rows.forEach((data, index) => {
        data.product_variations.forEach((dataVariation) => {
          if (dataVariation.is_default_selected === true)
            default_varaition = {
              title: dataVariation.title,
              commision: dataVariation.commission,
            };
        });
        data["defalt_variation"] = default_varaition;
        // productList[index] = data;
        productList.push(data);
      });
      // let list = [...productState.productList, ...response.data.data.rows];
      setProductList(response.data.data.rows);
      setCurrentPage(1);
      setCount(response.data.data.count);
    } else {
      setIsLoading(false);
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const search = React.useCallback(debounce(handleProductSearch, 500), []);

  const handleProductSort = async (data) => {
    let local = orderState.formInfo.is_local;
    setSortData(data);
    let post_data = {};
    if (local) {
      if (data === "aec") {
        post_data = {
          currentPage: 0,
          itemsPerPage: itemsPerPage,
          filters: [
            {
              id: "type",
              value: "NON_ADD_ONS",
            },
            {
              id: "url-slugs",
              value: urlLink.length === 0 ? ["flowers"] : urlLink,
            },
          ],
          sortBy: [
            {
              id: "price",
            },
          ],
        };
      } else {
        post_data = {
          currentPage: 0,
          itemsPerPage: itemsPerPage,
          filters: [
            {
              id: "type",
              value: "NON_ADD_ONS",
            },
            {
              id: "url-slugs",
              value: urlLink.length === 0 ? ["flowers"] : urlLink,
            },
          ],
          sortBy: [
            {
              id: "price",
              desc: "desc",
            },
          ],
        };
      }
    } else {
      if (data === "aec") {
        post_data = {
          currentPage: 0,
          itemsPerPage: itemsPerPage,
          filters: [
            {
              id: "type",
              value: "NON_ADD_ONS",
            },
            {
              id: "is_local_only",
              value: 0,
            },
            {
              id: "url-slugs",
              value: urlLink.length === 0 ? ["flowers"] : urlLink,
            },
          ],
          sortBy: [
            {
              id: "price",
            },
          ],
        };
      } else {
        post_data = {
          currentPage: 0,
          itemsPerPage: itemsPerPage,
          filters: [
            {
              id: "type",
              value: "NON_ADD_ONS",
            },
            {
              id: "is_local_only",
              value: 0,
            },
            {
              id: "url-slugs",
              value: urlLink.length === 0 ? ["flowers"] : urlLink,
            },
          ],
          sortBy: [
            {
              id: "price",
              desc: "desc",
            },
          ],
        };
      }
    }
    setIsLoading(true);
    // const response = await productsService.getProducts(post_data);
    const response = await getProducts(post_data);
    if (response && response.status === 200) {
      let productList = [];
      let default_varaition = {
        title: "",
        commision: 0,
      };
      setIsLoading(false);
      response.data.data.rows.forEach((data, index) => {
        data.product_variations.forEach((dataVariation) => {
          if (dataVariation.is_default_selected === true)
            default_varaition = {
              title: dataVariation.title,
              commision: dataVariation.commission,
            };
        });
        data["defalt_variation"] = default_varaition;
        // productList[index] = data;
        productList.push(data);
      });
      // let list = [...productState.productList, ...response.data.data.rows];
      setProductList(response.data.data.rows);
      setCurrentPage(1);
      setCount(response.data.data.count);
    } else {
      setIsLoading(false);
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };
  const handleEligeble = async () => {
    let local = orderState.formInfo.is_local;
    let post_data = {};
    if (local) {
      post_data = {
        currentPage: 0,
        itemsPerPage: itemsPerPage,
        filters: [
          {
            id: "type",
            value: "NON_ADD_ONS",
          },
          { id: "is_eligible_for_commission", value: 1 },
        ],
        sortBy: [
          {
            id: "sequence_number",
            asc: "asc",
          },
        ],
      };
    } else {
      post_data = {
        currentPage: 0,
        itemsPerPage: itemsPerPage,
        filters: [
          {
            id: "type",
            value: "NON_ADD_ONS",
          },
          { id: "is_eligible_for_commission", value: 1 },
          {
            id: "is_local_only",
            value: 0,
          },
        ],
        sortBy: [
          {
            id: "sequence_number",
            asc: "asc",
          },
        ],
      };
    }
    // setIsLoading(true);
    // const response = await productsService.getProducts(post_data);
    const response = await getProducts(post_data);
    if (response && response.status === 200) {
      console.log("Product Data -->", response.data.data.rows);
      let productList = [];
      let default_varaition = {
        title: "",
        commision: 0,
      };
      setIsLoading(false);
      response.data.data.rows.forEach((data, index) => {
        data.product_variations.forEach((dataVariation) => {
          if (dataVariation.is_default_selected === true)
            default_varaition = {
              title: dataVariation.title,
              commision: dataVariation.commission,
            };
        });
        data["defalt_variation"] = default_varaition;
        // productList[index] = data;
        productList.push(data);
      });
      setProductList(response.data.data.rows);
      setCurrentPage(1);
      setCount(response.data.data.count);
    } else {
      setIsLoading(false);
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };
  const handleonclose = async () => {
    setIsDrawerOpen(false);
    setCurrentPage(0);
    clearCurrentAdd();
  };
  const handleManageMenuProduct = async (parentProductName) => {
    let local = orderState.formInfo.is_local;
    setUrlLink(parentProductName);
    let postData = {};
    if (local) {
      postData = {
        currentPage: 0,
        itemsPerPage: itemsPerPage,
        sortBy: [
          {
            id: "sequence_number",
            asc: "asc",
          },
        ],
        filters: [
          {
            id: "type",
            value: "NON_ADD_ONS",
          },
          {
            id: "url-slugs",
            value: parentProductName,
          },
        ],
      };
    } else {
      postData = {
        currentPage: 0,
        itemsPerPage: itemsPerPage,
        sortBy: [
          {
            id: "sequence_number",
            asc: "asc",
          },
        ],
        filters: [
          {
            id: "type",
            value: "NON_ADD_ONS",
          },
          {
            id: "is_local_only",
            value: 0,
          },
          {
            id: "url-slugs",
            value: parentProductName,
          },
        ],
      };
    }
    // setIsLoading(true);
    // const response = await productsService.getProducts(postData);
    const response = await getProducts(postData);
    if (response && response.status === 200) {
      let productList = [];
      let default_varaition = {
        title: "",
        commision: 0,
      };
      // setIsLoading(false);
      response.data.data.rows.forEach((data, index) => {
        data.product_variations.forEach((dataVariation) => {
          if (dataVariation.is_default_selected === true)
            default_varaition = {
              title: dataVariation.title,
              commision: dataVariation.commission,
            };
        });
        data["defalt_variation"] = default_varaition;
        // productList[index] = data;
        productList.push(data);
      });
      // let list = [...productState.productList, ...response.data.data.rows];
      setProductList(response.data.data.rows);
      let urlSlug = {
        categoryHierarchyIds:
          response.data.data.urlSlugInfo.categoryHierarchyIds,
        hasLocation: response.data.data.urlSlugInfo.hasLocation,
      };
      setUrlSlugData(urlSlug);
      setCurrentPage(1);
      setCount(response.data.data.count);
    } else {
      // setIsLoading(false);
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  if (!props.isVarianNeeded) {
    if (props.hideFeaturedProduct) {
      return (
        <div className={`${styles.productsWrapper}`}>
          <div className="">
            <div className="">
              <div className="table-responsive mt-2 mb-4">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-left">Item</th>
                      <th className="text-left">Name</th>
                      {props.showSequenceNumber ? (
                        <th className="text-left">Sequence Number</th>
                      ) : (
                        ""
                      )}
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {props.productList?.length === 0 ? (
                      <tr>
                        <td className={``}>
                          <div className={`${styles.emptyImage}`}>
                            <span>Image</span>
                          </div>
                        </td>
                        <td className={``}>No Product</td>
                        <td className={` text-center`}>-</td>
                      </tr>
                    ) : (
                      props.productList?.map((d, i) => {
                        return (
                          <tr className={`${styles.tableStyle}`} key={i}>
                            <td className={``}>
                              <div className={`${styles.tableimg}`}>
                                <img
                                  src={d.product_images[0]?.image}
                                  alt=""
                                  title="image"
                                />
                              </div>
                            </td>
                            <td className={``}>{d.title}</td>

                            {props.showSequenceNumber ? (
                              <td>
                                <Text
                                  type="text"
                                  label="Sequence Number"
                                  value={d.sequence_number}
                                  required={true}
                                  onChange={(data) => {
                                    if (!/^[0-9]*$/.test(data)) {
                                      return;
                                    }
                                    let val = (+data).toString() || "0";
                                    props.setProductSequence(i, val);
                                  }}
                                />
                              </td>
                            ) : (
                              ""
                            )}
                            <td className={` text-center `}>
                              {props.setPageForView === 1 ? (
                                <div></div>
                              ) : (
                                <div className={``}>
                                  <DeleteOutlineIcon
                                    onClick={() => {
                                      handleProductDeleteCms(i);
                                    }}
                                    style={{
                                      color: "#F37C7C",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {props.addProduct && (
           <ProductsDrawer
             open={props.addProduct}
             // Pass prdouct List Over herer
             data={addCmsProduct}
             onClose={() => {
               props.handleCloseProduct();
               // setAddCmsProduct([]);
             }}
             openAddProductDrawer={props.openAddProductDrawer}
             handleProductSort={handleProductSort}
             price={price}
             setPrice={setPrice}
             handleProductSearch={handleProductSearch}
             handleGetProducts={handleGetProducts}
             searchValue={searchValue}
             addProductCms={addProductCms}
             count={count}
             isVarianNeeded={props.isVarianNeeded}
             setSearchValue={setSearchValue}
             handleEligeble={handleEligeble}
             handleAddProductSubmitCms={handleAddProductSubmitCms}
             handleManageMenuProduct={(parentProductName) => {
               handleManageMenuProduct(parentProductName);
             }}
             scrollTimeProductsCall={scrollTimeProductsCall}
           />
         )}
        </div>
      );
    } else {
      return "";
    }
  }
  return (
    <div className={`${styles.productsWrapper}`}>
      <div className="">
        <div className="">
          <div className={styles.title}>Products</div>
          {props.is_subscribe_order === false && (
            <span
              className={`link cursor-pointer ml-2 ${styles.searchIconWrapper}`}
              onClick={(e) => {
                e.preventDefault();
                setIsDrawerOpen(true);
                setIsLoading(true);
              }}
            >
              <img
                className={styles.searchIcon}
                src={AddIcon}
                alt="SearchIcon"
              />
            </span>
          )}
          <div className="table-responsive mt-2 mb-4">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-left">Item</th>
                  <th className="text-left">Name</th>
                  <th className="text-left">Qty</th>
                  <th className="text-center">Price</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {orderState.recipents[orderState.activeRecipentIndex]
                  ?.added_product?.length === 0 ? (
                  <tr>
                    <td className={``}>
                      <div className={`${styles.emptyImage}`}>
                        <span>Image</span>
                      </div>
                    </td>
                    <td className={``}>No Product</td>
                    <td className={` text-center`}>-</td>
                    <td className={` text-center`}>-</td>
                    <td className={` text-center`}>-</td>
                    <td className={` text-center `}></td>
                  </tr>
                ) : (
                  orderState.recipents[
                    orderState.activeRecipentIndex
                  ]?.added_product?.map((d, i) => {
                    return (
                      <tr className={`${styles.tableStyle}`} key={i}>
                        <td className={``}>
                          <div className={`${styles.tableimg}`}>
                            <img
                              src={d.selectedVariant?.image}
                              alt=""
                              title="image"
                            />
                          </div>
                        </td>
                        <td className={``}>
                          {d.title} <br />
                          <small>{d.selectedVariant?.title}</small>
                        </td>
                        <td className={styles.quantityCol}>
                          <div className={`d-flex align-items-center`}>
                            <div className={styles.quantityLabel}>{d.qty}</div>
                            {props.is_subscribe_order === false && (
                              <ButtonGroup className={`incressdecress ml-3`}>
                                <Button
                                  disabled={d.qty <= 1}
                                  onClick={() => decressProduct(i)}
                                >
                                  -
                                </Button>
                                <Button
                                  disabled={d.qty >= 10}
                                  onClick={() => incressProduct(i)}
                                >
                                  +
                                </Button>
                              </ButtonGroup>
                            )}
                          </div>
                        </td>
                        <td
                          className={` text-center`}
                          style={{ minWidth: "90px" }}
                        >
                          {d.total_price.toFixed(2)}
                        </td>
                        <td className={` text-center `}>
                          <div className={``}>
                            {props.is_subscribe_order === false && (
                              <DeleteOutlineIcon
                                onClick={() => {
                                  if (props.isEdit) {
                                    deleteDataProduct(d.sqlid, i);
                                  } else {
                                    deleteData(i);
                                  }
                                }}
                                style={{ color: "#F37C7C", cursor: "pointer" }}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isDrawerOpen && (
        <ProductsDrawer
          open={isDrawerOpen}
          data={productState.productList}
          onClose={handleonclose}
          openAddProductDrawer={setIsDrawerOpen}
          handleProductSort={handleProductSort}
          price={price}
          setPrice={setPrice}
          handleProductSearch={search}
          handleGetProducts={handleGetProducts}
          searchValue={searchValue}
          isVarianNeeded={props.isVarianNeeded}
          addProductCms={addProductCms}
          addCmsProduct={addCmsProduct}
          count={count}
          setSearchValue={setSearchValue}
          handleEligeble={handleEligeble}
          handleManageMenuProduct={(parentProductName) => {
            handleManageMenuProduct(parentProductName);
          }}
          scrollTimeProductsCall={scrollTimeProductsCall}
        />
      )}
    </div>
  );
}

export default Products;
