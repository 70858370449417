// Packages
import React, { useContext } from 'react';
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import moment from 'moment';
import 'moment-timezone';
//Icons
import DeleteIcon from "../../../Assets/images/delete.svg"

//Components
import Date from 'Components/FormControl/Date/Date';
import Text from 'Components/FormControl/Text/Text';

// SCSS
import styles from './ClockDetail.module.scss';

// Context
import { AppContext } from "./../../../Context/AppContext";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let deleteArr = [];
const ClockDetail = ({ showModal, handleCloseModal,handleSubmitClick, clockDetails, onChangeValue,handleRemove,isEditable,isDeleteable, deleteDataArray }) => {
    if(!showModal){deleteArr=[]}

    const { timezone } = useContext(AppContext);
    const { showToastMessage } = useContext(AppContext);

    const handleDeleteClick = (id,index) =>{
        if(clockDetails.clock_time.length>1){
            if(clockDetails.clock_time[index].clock_out===0){
                showToastMessage(undefined, "This entry cannot be deleted because the user has not yet clocked out", "error", false);
            }else{
                handleRemove(clockDetails.clock_time.filter((cur,i) => i !== index));
                deleteArr.push(id)
                deleteDataArray(deleteArr)
            }
        }else{
            showToastMessage(undefined, "Minimum 1 entry should be required", "error", false);
        }
    }
    return (
        <React.Fragment>
            <Dialog
                open={showModal}
                TransitionComponent={Transition}
                onClose={handleCloseModal}
                classes={{
                    paper: styles.modalDialog
                }}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogContent
                    className={`${styles.modalContent} px-0 py-0`}
                >
                    <div className={`text-center ${styles.modalBody}`}>
                        <div className="d-flex justify-content-between mt-3">
                            <h5 className={`mt-0 mb-3 text-center ${styles.modalTitle}`}>{clockDetails.user && clockDetails.user.first_name} {clockDetails.user && clockDetails.user.last_name}</h5>
                            <span className={`current-date ${styles.date}`}>{clockDetails.date}</span>
                        </div>

                        <div className=" my-4">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="text-left">Clock In</th>
                                        <th className="text-left">Clock Out</th>
                                        <th className="text-center">Regular Hours</th>
                                        <th className="text-center">OT (1.5)</th>
                                        <th className="text-center">OT (2)</th>
                                       { isDeleteable? <th className="text-center">Action</th>:""}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        clockDetails.clock_time && clockDetails.clock_time.map((timeClock, index) => {
                                            let requestCheckIn  = timeClock.change_requests.filter((elem)=>{
                                                return elem.type==="IN" && elem.status===1
                                            })
                                            let requestCheckOut  = timeClock.change_requests.filter((elem)=>{
                                                return elem.type==="OUT" && elem.status===1
                                            })
                                            
                                            return (
                                                <>
                                                {
                                                    isEditable===true ? 
                                                    <tr key={index}>
                                                        
                                                    <td className="text-left time-box-padding">
                                                        
                                                        {
                                                            timezone &&
                                                                // timeClock.clock_in ? moment.unix(timeClock.clock_in).tz(timezone).format('hh:mm A') : '-'
                                                                <Date
                                                                    parentClass="pt-0 mb-1"
                                                                    className="change-time-model"
                                                                    value={timeClock.clock_in ? moment.unix(timeClock.clock_in).tz(timezone).format('hh:mm A') : '-'}
                                                                    type="time"
                                                                    onChange={(time) => onChangeValue({ id: timeClock.id, time, type: 'in' })}
                                                                    disabled={!isEditable}
                                                                />
                                                        }
                                                    </td>
                                                    <td className="text-left time-box-padding">
                                                        <span>
                                                            {
                                                                timeClock.clock_out?
                                                                <Date
                                                                    parentClass="pt-0 mb-1"
                                                                    className="change-time-model"
                                                                    value={timeClock.clock_out ? moment.unix(timeClock.clock_out).tz(timezone).format('hh:mm A') : '-'}
                                                                    type="time"
                                                                    onChange={(time) => onChangeValue({ id: timeClock.id, time, type: 'out' })}
                                                                    disabled={!isEditable}
                                                                /> : "-"
                                                            }
                                                        </span>
                                                                {/* // timeClock.clock_out ? moment.unix(timeClock.clock_out).tz(timezone).format('hh:mm A') : '-'
                                                                <Date
                                                                    parentClass="pt-0 mb-1"
                                                                    className="change-time-model"
                                                                    value={timeClock.clock_out ? moment.unix(timeClock.clock_out).tz(timezone).format('hh:mm A') : '-'}
                                                                    type="time"
                                                                    onChange={(time) => onChangeValue({ id: timeClock.id, time, type: 'out' })}
                                                                    disabled={!isEditable}
                                                                /> */}
                                                        
                                                    </td>
                                                    <td className="text-left">
                                                        <Text
                                                            type="number"
                                                            value={timeClock.regular_hours}
                                                            onChange={(data) => onChangeValue({ 
                                                                id: timeClock.id, 
                                                                regular_hours:data, 
                                                                type: 'regular_hours' 
                                                            })}
                                                            disabled={!isEditable}
                                                        />
                                                    </td>
                                                    <td className="text-left">
                                                        <Text
                                                            type="number"
                                                            value={timeClock.ot1}
                                                            onChange={(data) => onChangeValue({ 
                                                                id: timeClock.id, 
                                                                ot1:data, 
                                                                type: 'ot1' 
                                                            })}
                                                            disabled={!isEditable}
                                                        />
                                                    </td>
                                                    <td className="text-left">
                                                        <Text
                                                            type="number"
                                                            value={timeClock.ot2}
                                                            onChange={(data) => onChangeValue({ 
                                                                id: timeClock.id, 
                                                                ot2:data, 
                                                                type: 'ot2' 
                                                            })}
                                                            disabled={!isEditable}
                                                        />
                                                    </td>
                                                    {isDeleteable ?
                                                        <td className="text-center ">
                                                            <img 
                                                                style={{cursor: "pointer"}}
                                                                src={DeleteIcon} alt="" 
                                                                onClick={()=>handleDeleteClick(timeClock.id,index)}
                                                            />
                                                        </td> : ""
                                                    }
                                                    
                                                </tr> 

                                                : 

                                                <tr key={index}>
                                                <td className="text-left time-box-padding">
                                                   {
                                                    requestCheckIn.length>0 ?
                                                    <span >
                                                        <span style={{textDecorationLine: 'line-through'}}>
                                                            {moment.unix(timeClock.clock_in).tz(timezone).format('hh:mm A') }
                                                        </span> <span>
                                                            {moment.unix(requestCheckIn[0].time).tz(timezone).format('hh:mm A') }
                                                        </span>
                                                    </span> :
                                                    <span>
                                                        {timeClock.clock_in ? moment.unix(timeClock.clock_in).tz(timezone).format('hh:mm A') : '-'}
                                                    </span>
                                                   }
                                                    
                                                </td>
                                                <td className="text-left time-box-padding">
                                                   {
                                                    requestCheckOut.length>0 ?
                                                    <span >
                                                        <span style={{textDecorationLine: 'line-through'}}>
                                                            {moment.unix(timeClock.clock_out).tz(timezone).format('hh:mm A') }
                                                        </span> <span>
                                                        {moment.unix(requestCheckOut[0].time).tz(timezone).format('hh:mm A') }
                                                        </span>
                                                    </span> :
                                                    <span>
                                                        {timeClock.clock_out ? moment.unix(timeClock.clock_out).tz(timezone).format('hh:mm A') : '-'}
                                                    </span>
                                                   }
                                                    
                                                </td>
                                                <td className="text-center">{timeClock.regular_hours ? timeClock.regular_hours : '-'}</td>
                                                    <td className="text-center">{timeClock.ot1 ? timeClock.ot1 : '-'}</td>
                                                    <td className="text-center">{timeClock.ot2 ? timeClock.ot2 : '-'}</td>
                                                </tr>
                                                }

                                                </>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="totalHrs mb-4 text-left">
                            Total Hours: <span>{clockDetails.duration}</span>
                        </div>

                        {
                            clockDetails.exception && clockDetails.exception.length > 0 &&
                            <div className="table-responsive my-4">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="text-left">Exception</th>
                                            <th className="text-left">Regular Hours</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            clockDetails.exception && clockDetails.exception.map((exceptionObj, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-left">{exceptionObj.title ? exceptionObj.title : '-'}</td>
                                                        <td className="text-left">{exceptionObj.duration ? exceptionObj.duration : '-'}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                        <a href="/" data-dismiss="modal" onClick={handleSubmitClick?handleSubmitClick:handleCloseModal} className="btn gray-btn d-inline-block mb-3">{isEditable?"Save":"Ok"}</a>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default ClockDetail;
