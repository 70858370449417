import { timeClockAuditTrail, timeClockAuditTrailDetails } from 'api/clock';
import Detail from 'Pages/Order/Order/component/Detail'
import React, { useEffect,useContext } from 'react'
import stringify from "json-stable-stringify";
import { useHistory } from "react-router-dom";
import { useState } from 'react';
import { withRouter } from 'react-router';
import AuditTrailDetails from "../../../Components/AuditTrailDetails/AuditTrailDetails"
import { AppContext } from 'Context/AppContext';
import { getAccessByModuleName } from 'Routes/Routes';
import { MODULES } from 'Helpers/Constants';
const AuditTrailDetailsPage = (props) => {
  const history = useHistory();
  const { showToastMessage, modules } = useContext(AppContext);
  //define state
  const [oldValueData, setOldValueData] = useState("");
  const [newValueData, setNewValueData] = useState("");

  useEffect(() => {
    const { reportId } = props.match.params;
    console.log(reportId);
    getReportDetail(reportId);
  }, []);

  const getReportDetail = async (id) => {
    const response = await timeClockAuditTrailDetails(id);
    if (response && response.status === 200) {
      let tempResponseDataUser = { ...response.data.data[0] };
      if (
        tempResponseDataUser.old_value &&
        tempResponseDataUser.old_value !== ""
      ) {
        let oldDataParse = JSON.parse(tempResponseDataUser.old_value);
        let oldDataSerialize = JSON.parse(stringify(oldDataParse));
        let formatDataOld = await jsonFormat(oldDataSerialize);
        setOldValueData(formatDataOld.props.children);
      }

      if (
        tempResponseDataUser.new_value &&
        tempResponseDataUser.new_value !== ""
      ) {
        let newDataParse = JSON.parse(tempResponseDataUser.new_value);
        let newDataSerialize = JSON.parse(stringify(newDataParse));
        let formatDataNew = await jsonFormat(newDataSerialize);
        setNewValueData(formatDataNew.props.children);
      }
    } else showToastMessage(undefined, response.data.message, "error", false);
  };
  const jsonFormat = async (data) => {
    return <pre>{JSON.stringify(data, null, 2)}</pre>;
  };

  useEffect(getModuleAccess, [modules]);
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.AUDIT_TRAIL_LOG_REPORT);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
        );
        props.history.push("/profile");
      }
  }
  return (
    <React.Fragment>
      <AuditTrailDetails 
      oldValue={oldValueData}
      newValue={newValueData}
      />
    </React.Fragment>
  )
}

export default withRouter(AuditTrailDetailsPage)