import React, { useReducer } from "react";
import ProductReducer from "./productReducer";
import { productState } from "./productState";

export const ProductContext = React.createContext({
  productState: productState,
  dispatch: () => null,
});

const ProductProviderCurd = (props) => {
  const [state, dispatch] = useReducer(ProductReducer, productState);
  return (
    <ProductContext.Provider value={{ productState: state, dispatch }}>
      {props.children}
    </ProductContext.Provider>
  );
};

export default ProductProviderCurd;
