import { useContext } from "react";
import { CouponContext } from "./CouponContext";
import { CouponAction } from "./couponAction";

export function useCoupon() {
  const { couponState, dispatch } = useContext(CouponContext);

  const setCouponCodeName = (values) => {
    dispatch({ type: CouponAction.SET_COUPON_CODE_NAME, payload: values });
  };
  const setCouponCodeType = (values) => {
    dispatch({ type: CouponAction.SET_COUPON_CODE_TYPE, payload: values });
  };
  const setCouponCodeValue = (values) => {
    dispatch({ type: CouponAction.SET_COUPON_CODE_VALUE, payload: values });
  };
  const setCouponApplyTo = (values) => {
    dispatch({ type: CouponAction.SET_COUPON_APPLY_TO, payload: values });
  };
  const setCouponRedeemForMobile = (values) => {
    dispatch({ type: CouponAction.SET_COUPON_REDEEM_FOR_MOBILE, payload: values });
  };
  const setUsageLimitPerUser = (values) => {
    dispatch({ type: CouponAction.SET_USAGE_LIMIT_PER_USER, payload: values });
  };
  const setStartDate = (values) => {
    dispatch({ type: CouponAction.SET_START_DATE, payload: values });
  };
  const setEndDate = (values) => {
    dispatch({ type: CouponAction.SET_END_DATE, payload: values });
  };
  const setSelectedData = (payload) => {
    dispatch({ type: CouponAction.SET_SELECTED_DATA, payload });
  };
  const deleteSelectedData = (values) => {
    dispatch({ type: CouponAction.DELETE_SELECTED_DATA, payload: values });
  };
  const disableSectionOnSelect = (values) => {
    dispatch({ type: CouponAction.DISABLE_SECTION_ON_SELECT, payload: values });
  };
  const emptySelectedData = (values) => {
    dispatch({ type: CouponAction.EMPTY_SELECTED_DATA, payload: values });
  };
  const clearCouponState = (values) => {
    dispatch({ type: CouponAction.CLEAR_COUPON_DATA });
  };
  const setCouponUseCount = (values) => {
    dispatch({ type: CouponAction.SET_COUPON_USE_COUNT, payload: values });
  };

  return {
    couponState,
    setCouponCodeName,
    setCouponCodeType,
    setCouponCodeValue,
    setCouponApplyTo,
    setCouponRedeemForMobile,
    setUsageLimitPerUser,
    setStartDate,
    setEndDate,
    setSelectedData,
    deleteSelectedData,
    disableSectionOnSelect,
    emptySelectedData,
    clearCouponState,
    setCouponUseCount
  };
}
