import React, { useContext, useState } from "react";
import { withRouter } from "react-router";
// Components
import Text from "../../Components/FormControl/Text/Text";
import { userForgotPassword } from "./../../api/user";
// Images
import AdminLogo from "./../../Assets/images/admin-logo.svg";
// Context
import { AppContext } from "./../../Context/AppContext";
// SCSS
import styles from "./index.module.scss";

const ForgotPassword = (props) => {
  // const usersService = new Users();
  const [email, setEmail] = useState("");
  const [submitStatus, setSubmitStatus] = useState(false);

  const { showToastMessage } = useContext(AppContext);

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    setSubmitStatus(true);

    if (email) {
      //   const response = await usersService.forgotPassword({ email });
      const response = await userForgotPassword({ email });
      setSubmitStatus(false);

      if (response && response.status === 200) {
        showToastMessage(undefined, response.data.message, "success", false);
        props.history.replace("/login");
      } else showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  return (
    <React.Fragment>
      <div
        className={`d-flex align-items-center justify-content-center h-100 ${styles.loginWrapper}`}
      >
        <div className="w-100">
          <div className="logo d-block mb-5 text-center">
            <img src={AdminLogo} alt="Admin" />
          </div>
          <div className={styles.loginBlock}>
            <div className="mt-2 h2 text-center">Forgot Password?</div>
            <p className="h4 text-center">
              Enter your email address and we will send you instructions to
              reset your password.
            </p>
            <form onSubmit={handleForgotPassword}>
              <Text
                type="email"
                label="Email"
                value={email}
                required={true}
                submitStatus={submitStatus}
                onChange={(email) => setEmail(email)}
              />
              <div className="d-block mt-5 text-center">
                <button type="submit" className="btn gray-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ForgotPassword);
