import React from "react";
import Text from "../FormControl/Text/Text";
import { formatUsPhone } from "../../Hooks/commanUtility";
import SwitchBox from "../../Components/FormControl/SwitchBox/SwitchBox"

const CoustumerForm = (props) => {
  return (
    <div>
      <div className={`row`}>
        <div className={`col-md-4 col-xs-12`}>
          <Text
            type="text"
            label="First Name"
            value={
              props.coustumerData.customer_first_name
                ? props.coustumerData.customer_first_name
                : "-"
            }
            disabled={true}
          />
        </div>
        <div className={`col-md-4 col-xs-12`}>
          <Text
            type="text"
            label="Last Name"
            value={
              props.coustumerData.customer_last_name
                ? props.coustumerData.customer_last_name
                : "-"
            }
            disabled={true}
          />
        </div>
        <div className={`col-md-4 col-xs-12`}>
          <Text
            type="email"
            label="Email"
            value={
              props.coustumerData.customer_email
                ? props.coustumerData.customer_email
                : "-"
            }
            disabled={true}
          />
        </div>
        <div className={`col-md-4 col-xs-12`}>
          <Text
            type="tel"
            label="Phone"
            value={
              props.coustumerData.customer_phone
                ? props.coustumerData.customer_phone_country_code +
                  " " +
                  formatUsPhone(props.coustumerData.customer_phone)
                : "-"
            }
            disabled={true}
          />
        </div>
        <div className={`col-md-4 col-xs-12`}>
          <Text
            type="tel"
            label="ALT Phone"
            value={
              props.coustumerData.customer_alt_phone
                ? props.coustumerData.customer_alt_phone_country_code +
                  " " +
                  formatUsPhone(props.coustumerData.customer_alt_phone)
                : "-"
            }
            disabled={true}
          />
        </div>
        <div className="col-md-4 col-xs-12">
                <div class="form-group bmd-form-group is-filled text-left">
                  <label class="bmd-label-floating">Business Account</label>
                  <SwitchBox
                    value={props.coustumerData.customer ? props.coustumerData.customer.is_business_account : 0 }
                    disabled={true}
                  />
                </div>
              </div>
      </div>
    </div>
  );
};

export default CoustumerForm;
