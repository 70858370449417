import OrderSummery from "Components/NewOrder/OrderSummery";
import { ORDER_SOURCE, ORDER_STATUS } from "Helpers/Constants";
import React from "react";
import { useOrderAttributeProvider } from "../context/order";
import defaultSignature from "Assets/images/default-signature.svg";
import { DateTime } from "luxon";
const Detail = () => {
  const { orderDetail } = useOrderAttributeProvider();
  if (!orderDetail) return <></>;
  
  return (
    <div>
      <OrderSummery
        orderNumber={orderDetail.order_number}
        orderDate={orderDetail.createdAt}
        deliveryDate={orderDetail.delivery_date_time}
        delivery_time={orderDetail.delivery_time_type === "Anytime" ? orderDetail.delivery_time_type : orderDetail.delivery_time_type+" "+DateTime.fromISO(orderDetail.delivery_date_time).setZone("America/Los_Angeles").toFormat("hh:mm a")}
        deliveryStatus={
          orderDetail.order_status
            ? ORDER_STATUS[orderDetail.order_status.toUpperCase()]
            : "-"
        }
        timeDelivered={
          orderDetail?.order_status === "delivered"
            ? orderDetail.actual_order_delivered_time
            : null
        }
        takenBy={
          orderDetail.csr_id && orderDetail.csr_detail
            ? orderDetail.csr_detail.full_name
            : orderDetail.created_by && orderDetail.created_detail
            ? orderDetail.created_detail.full_name
            : "-"
        }
        designedBy={
          orderDetail.designer_id && orderDetail.designer_detail
            ? orderDetail.designer_detail.full_name
            : "-"
        }
        driverName={
          orderDetail.driver_name ? orderDetail.driver_name : "-"
        }
        signature={
          orderDetail.recipient_signature_or_picture
            ? orderDetail.recipient_signature_or_picture
            : defaultSignature
        }
        source={
          orderDetail.source
            ? ORDER_SOURCE[orderDetail.source.toUpperCase()]
            : "-"
        }
        deliveringStore={orderDetail?.store}
      />
    </div>
  );
};

export default Detail;
