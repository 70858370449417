import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import EditIcon from "./../../../Assets/images/edit.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";
import ActivatedIcon from "./../../../Assets/images/activated.svg";

// Components
import Header from "../../../Components/Header/Header";
import ReactTable from "../../../Components/Table/Table";
import DeleteConfirmation from "../../../Components/Modal/DeleteConfirmation/DeleteConfirmation";
import StatusConfirmation from "../../../Components/Modal/StatusConfirmation/StatusConfirmation";

// Services
import BlogsApi from "../../../Services/Blogs/Blogs";
import UtilsApi from "../../../Services/Utils/Utils";
import { updateStatus } from "../../../api/utils";
import { getBlogsList, deleteBlog } from "../../../api/blogs";
// Context
import { getAccessByModuleName } from "./../../../Routes/Routes";
import { AppContext } from "../../../Context/AppContext";
import { MODULES, STATUS_FILTER } from "./../../../Helpers/Constants";

import { useHistory } from "react-router-dom";

//scss
import styles from "./index.module.scss";

const BlogsManagement = (props) => {
  const history = useHistory();
  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);

  // Add / Edit
  const [deleteBlogId, setDeleteBlogId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [statusBlogId, setStatusBlogId] = useState(false);
  const [statusOfBlog, setStatusOfBlog] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [access, setAccess] = useState({});

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const statusList = STATUS_FILTER;
  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  const blogsService = new BlogsApi();
  const utilsService = new UtilsApi();

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
    };

    // const response = await blogsService.getBlogsList(postData);
    const response = await getBlogsList(postData);
    if (response && response.status === 200) {
      setData(response.data.data.rows);
      setPageCount(Math.ceil(response.data.data.count / pageSize));
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);
  };

  const handleManageHeaderClick = async (
    event,
    type = "add",
    blogData = {}
  ) => {
    event.preventDefault();
    if (type === "add") {
      history.push(`/cms/blog-management/add-blog`);
    } else if (type === "view") {
      let blogId = blogData.id;
      props.history.push(`/cms/blog-management/view-blog/${blogId}`);
    } else {
      let blogId = blogData.id;
      props.history.push(`/cms/blog-management/edit-blog/${blogId}`);
    }
  };

  const handleDeleteBlogClick = async (event, id) => {
    event.preventDefault();

    setDeleteBlogId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteBlog = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // const response = await blogsService.deleteBlog(deleteBlogId);
    const response = await deleteBlog(deleteBlogId);
    if (response && response.status === 200) {
      setDeleteBlogId(null);
      setShowDeleteModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  const handleStatusBlogClick = async (event, id, status) => {
    event.preventDefault();

    setStatusBlogId(id);
    setStatusOfBlog(status);
    setShowStatusModal(true);
  };

  const handleBlogStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfBlog === 1 ? 0 : 1,
      id: statusBlogId,
      table: "cms_blogs",
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setStatusBlogId(null);
      setStatusOfBlog(null);
      setShowStatusModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  useEffect(getModuleAccess, [modules]);
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.BLOGS);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/users");
    }
  }

  let blogsAccess = getAccessByModuleName(modules, MODULES.BLOGS);
  const columns = React.useMemo(
    () => [
      {
        Header: "Page Name",
        accessor: "title",
        isVisible: true,
        placeholder: "Page Name",
        Cell: ({ value, row, column }) => {
          return `${row.original.title}`;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        isVisible: true,
        placeholder: "Status",
        isSelectFilter: true,
        selectFilterOption: statusList,
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.status}>
              {row.original.status === 1 ? (
                <div className={styles.Active}>Active</div>
              ) : (
                <div className={styles.Inactive}>Inactive </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Created",
        accessor: "createdAt",
        isVisible: true,
        placeholder: "Date",
        isDateFilter : true,
        dateFormatValue : 'yyyy-MM-DD hh:mm:ss',
        Cell: ({ value, row, column }) => {
          return `${moment(row.original.createdAt).format("ddd MMM DD, YYYY")}`;
        },
      },
      {
        Header: "Updated",
        accessor: "updatedAt",
        isVisible: true,
        placeholder: "Date",
        Cell: ({ value, row, column }) => {
          return `${moment(row.original.updatedAt).format("ddd MMM DD, YYYY")}`;
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {blogsAccess && blogsAccess.permissions.write_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={ActivatedIcon}
                  alt="Active"
                  onClick={(e) =>
                    handleStatusBlogClick(e, value, row.original.status)
                  }
                />
              )}

              {blogsAccess && blogsAccess.permissions.delete_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={DeleteIcon}
                  alt="Delete"
                  onClick={(e) => handleDeleteBlogClick(e, value)}
                />
              )}

              {blogsAccess && blogsAccess.permissions.write_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={EditIcon}
                  alt="Edit"
                  onClick={(e) =>
                    handleManageHeaderClick(e, "edit", row.original)
                  }
                />
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Header
        title="CMS - Blog Pages"
        hasAddButton={access.write_access}
        handleAddClick={handleManageHeaderClick}
      />

      <div id="MainContent" className="main-content" />

      <div>
        {access.read_access && (
          <ReactTable
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            noDataText={"There is no data to display"}
            defaultFilter={defaultFilter}
            displayGlobalSearch={false}
            displayColumnVisibility={false}
          />
        )}
      </div>

      {access.delete_access && (
        <DeleteConfirmation
          showModal={showDeleteModal}
          title="Delete Blog"
          message="Are you sure want to delete this blog?"
          handleCancelClick={(event) => {
            event.preventDefault();
            setShowDeleteModal(false);
            setDeleteBlogId(null);
          }}
          handleSubmitClick={handleDeleteBlog}
        />
      )}
      {access.write_access && (
        <StatusConfirmation
          showModal={showStatusModal}
          title={"Blog"}
          message={"blog?"}
          buttonLabel={statusOfBlog ? "Inactivate" : "Activate"}
          statusFlag={statusOfBlog}
          customFlag={false}
          handleCancelClick={(event) => {
            event.preventDefault();
            setShowStatusModal(false);
            setStatusBlogId(null);
            setStatusOfBlog(null);
          }}
          handleSubmitClick={handleBlogStatus}
        />
      )}
    </React.Fragment>
  );
};

export default withRouter(BlogsManagement);
