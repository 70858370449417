import Select from "../../../Components/FormControl/Select/Select";
import Date from "../../../Components/FormControl/Date/Date";
import CalendarIcon from "../../../Assets/images/calendar.svg";
import SearchIcon from "../../../Assets/images/search.svg";
import CloseIcon from "../../../Assets/images/close.svg";
import React, { useState, useMemo, useContext } from "react";
import Header from "../../../Components/Header/Header";
import moment from "moment";
import { withRouter } from "react-router";
import { getCouponReports } from "api/report";
import { AppContext } from "Context/AppContext";
import styles from "./index.module.scss";
import ReactTable from "../../../Components/Table/Table";
import ViewIcon from "../../../Assets/images/error-outline.svg";
import CheckBox from "Components/FormControl/CheckBox/CheckBox";
import { setAllStore } from "Helpers/setAllStore";

const CouponReport = (props) => {

  const { showToastMessage, modules } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [firstTimePageLoad, setFirstTimePageLoad] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(1);

  const [selectedPeriodLabel, setSelectedPeriodLabel] = useState("By Month");
  const [selectedPeriod, setSelectedPeriod] = useState("monthly");

  const [fromDate, setFromDate] = useState(
    moment().startOf("month").format("ddd MMM DD, YYYY")
  );
  
  const [toDate, setToDate] = useState(moment());

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  const [checkAllStore,setCheckAllStore] = useState(true);

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
  }) => {
    setDefaultFilter({
      pageSize,
      pageIndex,
      filters,
      globalFilter,
      sortBy,
    });

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);

    setLoading(true);

    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      // globalSearch: "",
      globalSearch: globalFilter,
      filters,
      // sortBy: [{
      //    id:"coupon_redeemed",
      //    desc: false
      // }],
      sortBy,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
   }

    // const response = await getCouponReports(postData);
    if (!firstTimePageLoad) {
      const response = await getCouponReports(postData);
      console.log("Testing data=====>", response.data.data.count)
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else if (response.status === 403) {
        showToastMessage(undefined, response.data.message, "error", false);
        props.history.push("/dashboard");
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    } else {
      setFirstTimePageLoad(false);
    }
    setLoading(false);

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  const handleFromDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setToDate(moment(data).add(7, "days").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "monthly") {
      setToDate(moment(data).add(1, "months").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "yearly") {
      setToDate(moment(data).add(1, "years").utc().format("ddd MMM DD, YYYY"));
    }
  };

  const handleToDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setFromDate(
        moment(data).subtract(7, "days").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "monthly") {
      setFromDate(
        moment(data).subtract(1, "months").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "yearly") {
      setFromDate(
        moment(data).subtract(1, "years").utc().format("ddd MMM DD, YYYY")
      );
    }
  };

  const handleSelectedPeriod = async (id) => {
    if (id === "weekly") {
      setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Week");
    } else if (id === "monthly") {
      setFromDate(moment().startOf("month").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("month").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Month");
    } else if (id === "yearly") {
      setFromDate(moment().startOf("year").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("year").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Year");
    }
  };

  const handleClearSearch = async (event) => {
    event.preventDefault();
    setFromDate(moment().startOf("month").format("ddd MMM DD, YYYY"));
    setToDate(moment().endOf("month").format("ddd MMM DD, YYYY"));
    setSelectedPeriod("monthly");
    fetchData({
      ...defaultFilter,
      fromDateDefault: moment().startOf("month").format("ddd MMM DD, YYYY"),
      toDateDefault: moment().endOf("month").format("ddd MMM DD, YYYY"),
    });
  };

  const columns = useMemo(() => [
    {
      Header: "Coupon Name",
      accessor: "coupon_name",
      isVisible: true,
      placeholder: "Search By Coupon Name",
      Cell: ({ value, row, column }) => {
        return `${row.original.coupon_name}`;
      },
    },
    {
      Header: "Total Redeemed",
      accessor: "coupon_redeemed",
      isVisible: true,
      disableFilters: true,
      Cell: ({ value, row, column }) => {
        // return `${row.original.coupon_redeemed}`;
        return <div style={{ textAlign: "center" }}>{row.original.coupon_redeemed}</div>
      },
    },
    {
      Header: "Avg. Before Coupon",
      accessor: "average_order_value_before_coupon",
      isVisible: true,
      disableFilters: true,
      Cell: ({ value, row, column }) => {
        return <div style={{ textAlign: "center" }}>{row.original.average_order_value_before_coupon}</div>
      },
    },
    {
      Header: "Avg. After Coupon",
      accessor: "average_order_value_after_coupon",
      isVisible: true,
      disableFilters: true,
      Cell: ({ value, row, column }) => {
        return <div style={{ textAlign: "center" }}>{row.original.average_order_value_after_coupon}</div>
      },
    },
    {
      Header: "Avg. Redeemed",
      accessor: "average_coupon_size_redeemed",
      isVisible: true,
      disableFilters: true,
      Cell: ({ value, row, column }) => {
        return <div style={{ textAlign: "center" }}>{row.original.average_coupon_size_redeemed}</div>

      },
    },
    {
      Header: "Distinct Buyers",
      accessor: "distinct_buyers",
      isVisible: true,
      disableFilters: true,
      Cell: ({ value, row, column }) => {
        return <div style={{ textAlign: "center" }}>{row.original.distinct_buyers}</div>
      },
    },
    {
      Header: "Lifetime Revenue",
      accessor: "average_lifetime_revenue",
      isVisible: true,
      disableFilters: true,
      Cell: ({ value, row, column }) => {
        return <div style={{ textAlign: "center" }}>{row.original.average_lifetime_revenue}</div>
      },
    },
  ]) 

  return (
    <React.Fragment>
      <Header title="Coupon Report" />

      <div id="MainContent" className="main-content">
        <div style={{ float: "left", width: "100%" }}>
          <div className={"row "}>
            <div className="col-sm-2 col-xs-12">
              <Select
                label={selectedPeriodLabel}
                value={selectedPeriod}
                onChange={(data) => {
                  setSelectedPeriod(data);
                  handleSelectedPeriod(data);
                }}
                options={[
                  { id: "weekly", title: "Weekly" },
                  { id: "monthly", title: "Monthly" },
                  { id: "yearly", title: "Yearly" },
                  { id: "custom", title: "Custom" },
                ]}
                disabled={false}
              />
            </div>
            <div className="col-sm-3 col-xs-12">
              <Date
                label="Filter From "
                value={fromDate}
                dateFormat="ddd MMM DD, YYYY"
                type="date"
                fieldIcon={CalendarIcon}
                onChange={(fromDate) => {
                  setFromDate(moment(fromDate).format("ddd MMM DD, YYYY"));
                  handleFromDate(fromDate);
                }}
              />
            </div>
            <div className="col-sm-3 col-xs-12">
              <Date
                label="Filter To "
                value={toDate}
                dateFormat="ddd MMM DD, YYYY"
                type="date"
                fieldIcon={CalendarIcon}
                onChange={(toDate) => {
                  setToDate(moment(toDate).format("ddd MMM DD, YYYY"));
                  handleToDate(toDate);
                }}
              />
            </div>
            <div className="col-sm-2 col-xs-12">
              <CheckBox
              value={checkAllStore}
              label="All Stores"
              onChange={(data)=>{setCheckAllStore(data)}}
              />
            </div>
            <div className="col-sm-2 col-xs-12">
              <div className="td-actions pt-4">
                <span
                  onClick={(e) => fetchData(defaultFilter)}
                  className={`link cursor-pointer ${styles.searchIconWrapper}`}
                >
                  <img className={styles.searchIcon} src={SearchIcon} alt="" />
                </span>
                <span
                  onClick={(e) => handleClearSearch(e)}
                  className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
                >
                  <img className={styles.clearIcon} src={CloseIcon} alt="" />
                </span>
              </div>
            </div>
          </div>

          <ReactTable
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            noDataText={"There is no data to display"}
            defaultFilter={defaultFilter}
            displayGlobalSearch={false}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter (CouponReport);
