import React from 'react';

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// SCSS
import styles from './Exception.module.scss';

// Components
import Text from './../../FormControl/Text/Text';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Exception = ({ showModal, onChange, exceptionId, value, submitStatus, handleCancelClick, handleSubmitClick, message }) => {
    return (
        <React.Fragment>
            <Dialog
                open={showModal}
                TransitionComponent={Transition}
                onClose={handleCancelClick}
                classes={{
                    paper: styles.modalDialog
                }}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogContent
                    className={styles.modalContent}
                >
                    <div className={`text-center ${styles.modalBody}`}>
                        {
                            exceptionId &&
                            <h5 className={`my-3 text-center ${styles.modalTitle}`}>Edit Exception</h5>
                        }
                        {
                            !exceptionId &&
                            <h5 className={`my-3 text-center ${styles.modalTitle}`}>Add Exception</h5>
                        }
                        <div className="py-5">
                            <Text
                                type="text"
                                label="Task Name"
                                value={value}
                                required={true}
                                submitStatus={submitStatus}
                                onChange={(data) => onChange(data)}
                            />
                        </div>
                        <a href="/" data-dismiss="modal" onClick={handleSubmitClick} className="btn gray-btn d-inline-block mb-3 mr-2"> Save </a>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default Exception;
