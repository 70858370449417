import CardContent from "@material-ui/core/CardContent";
import React from "react";
import { useHistory } from "react-router";
import Style from "./index.module.scss";
const PopularProduct = ({ list }) => {
  const history = useHistory();
  const mystyle = {
    position: "absolute",
    fontSize: "11.64px",
    color: "#7a7a7a",
    fontWeight: "700",
  };
  const handleMostPopularProduct = () => {
    history.push("/dashboard/most-popular-product");
  };
  return (
    <div className={Style.card} style={{ height: "490px" }}>
      <CardContent>
        <div className="d-flex justify-content-between">
          <h3 className="bold mb-5">Most Popular Products</h3>
          <div className={`${Style.PopularProductItem}`}>
            <p className="flex-grow-1">Top 10</p>
            <span>|</span>
            <p className={`cursor-pointer`} onClick={handleMostPopularProduct}>
              View More
            </p>
          </div>
        </div>
        <div className={Style.PopularProductList}>
          {list && list.length > 0 ? (
            list.map((item, j) => {
              // let image = item.product.product_images.findIndex(c=>c.is_default_selected);
              // image = image !== -1 ? image : 0;
              // return (
              // <div key={j} style={{ position: "relative" }}>
              //   {item.product.product_images.length > 0 &&
              //   item.product.product_images[0]?.image !== null ? (
              //     <div>
              //       <img
              //         className={Style.PopularProductListImage}
              //         src={item.product.product_images[image]?.image}
              //         alt={item.title}
              //       />
              //     </div>
              //   ) : (
              //     <div className={Style.emptyImage}>
              //       <span>No Image</span>
              //     </div>
              //   )}
              //   <p style={mystyle}>${item.order_total}</p>
              //   <div className={Style.textDiv}>
              //     <span style={{ fontSize: "12px", color: "#010101" }}>
              //       {item.product.title}
              //     </span>
              //     <p className={Style.itemPrice}>{item.title}</p>
              //   </div>
              // </div>
              // )
              return (
                //if no data found
                <div key={j} style={{ position: "relative" }}>
                  {item.product_variation?.image &&
                  item.product_variation?.image !== null ? (
                    <div>
                      <img
                        className={Style.PopularProductListImage}
                        src={item.product_variation?.image}
                        alt={item.product_variation.title}
                      />
                    </div>
                  ) : (
                    <div className={Style.emptyImage}>
                      <span>No Image</span>
                    </div>
                  )}
                  <p style={mystyle}>${item.total_price}</p>
                  <div className={Style.textDiv}>
                    <span style={{ fontSize: "12px", color: "#010101" }}>
                      {item.product.title}
                    </span>
                    <p className={Style.itemPrice}>
                      {item.product_variation?.title}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <span>Awaiting Orders </span>
          )}
        </div>
      </CardContent>
    </div>
  );
};

export default PopularProduct;
