import CheckBox from "Components/FormControl/CheckBox/CheckBox";
import styles from "./index.module.scss";

const CommonButtonComponent = (props) => {
  return (
    <div style={{ marginLeft: "10px", marginBottom: "10px" }}>
      <button
        onClick={(e) => props.printAllOrder(props.component)}
        className={`btn pink-btn ${styles.actionButtonCommon}`}
      >
        Print selected
      </button>
      <button
        onClick={(e) => props.completedAllOrder(props.component)}
        className={`btn pink-btn ml-2 ${styles.actionButtonCommon}`}
      >
        Mark as completed
      </button>
    </div>
  );
};

export default CommonButtonComponent;
