import { TextField } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import Text from "../../FormControl/Text/Text";
import classes from "./index.module.scss";
function renderDay(day, tooltipDay) {
  const date = day.getDate();
  const month = day.getMonth();
  const year = day.getFullYear();
  let tooltp = tooltipDay?.find((d) => d.date === date && d.month === month && d.year === year);
  const dateStyle = {
    // position: "",
    bottom: 0,
    right: 0,
    fontSize: "13px",
  };

  const cellStyle = {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  };

  return (
    <>
      {tooltp?.date === date && month === tooltp?.month  && year === tooltp?.year ? (
        <>
          <div
            style={cellStyle}
            data-tip
            data-for={tooltp?.id}
            className={classes.dt}
          >
            <div style={{ ...dateStyle }}>{date}</div>
            <div className={classes.caret}>{tooltp.message}</div>
          </div>
        </>
      ) : (
        <div style={cellStyle}>
          <div style={dateStyle}>{date}</div>
        </div>
      )}
    </>
  );
}

const DatePickerWithInput = ({
  onChange,
  value,
  blockDates,
  tooltipDays,
  disabled,
  datePickerclass,
  showDateclass
}) => {
  let disableDays = blockDates ?? [];
  const [showPicker, setShowPicker] = useState(false);
  const [insidePicker, setInsidePicker] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);

  useEffect(() => {
    const dp = document.querySelector(".DayPicker");
    dp.addEventListener("mouseenter", () => {
      setInsidePicker(true);
    });
    dp.addEventListener("mouseleave", () => {
      setInsidePicker(false);
    });
    return () => {
      dp.removeEventListener("mouseenter", () => {
        setInsidePicker(true);
      });
      dp.removeEventListener("mouseleave", () => {
        setInsidePicker(false);
      });
    };
  }, []);

  useEffect(() => {
    if (inputFocused || insidePicker) {
      setShowPicker(true);
    } else {
      setShowPicker(false);
    }
    return () => {};
  }, [insidePicker, inputFocused]);

  return (
    <>
      <TextField
        className="w-100"
        disabled={disabled}
        onBlur={() => {
          setInputFocused(false);
        }}
        onFocus={() => {
          // setShowPicker(true);
          setInputFocused(true);
        }}
        onChange={(e) => {
          return;
        }}
        value={value ? moment(value).format("MM-DD-YYYY") : ""}
        type="text"
        label="Delivery Date"
      />

      <DayPicker
        className={`${showPicker ? "" : "d-none"} ${showDateclass ? datePickerclass : ""}`}
        showOutsideDays
        onDayClick={(day, mods) => {
          if (mods.disabled) {
            return;
          }
          onChange(day);
          setShowPicker(false);
        }}
        selectedDays={value}
        renderDay={(date) => renderDay(date, tooltipDays)}
        disabledDays={[
          {
            before: new Date(
              new Date().toLocaleString("en-US", {
                timeZone: "America/Los_Angeles",
              })
            ),
          },
          ...disableDays,
        ]}
      />
    </>
  );
};

export default DatePickerWithInput;
