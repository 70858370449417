import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
// Component
import Text from "Components/FormControl/Text/Text";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
// SCSS
import styles from "./OrderTax.module.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderTax = ({ showModal, pageTitle, handleCancelClick }) => {
  const formik = useFormikContext();
  const [taxId, setTaxId] = useState(formik.values.tax_id);

  useEffect(() => {
    setTaxId(formik.values.tax_id);
  }, [formik.values.tax_id]);
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="row">
              <div
                className={`col-md-8 col-xs-12 mt-5 mb-5 ${styles.marginAuto}`}
              >
                <Text
                  type="text"
                  label="Tax ID"
                  value={taxId}
                  required={true}
                  onChange={(data) => {
                    setTaxId(data);
                  }}
                />
              </div>
            </div>
            <span
              data-dismiss="modal"
              onClick={() => {
                formik.setValues((v) => {
                  return {
                    ...v,
                    tax_id: taxId,
                  };
                });
                handleCancelClick();
              }}
              className="btn gray-btn d-inline-block mt-5 mb-5 mr-2 mt-5 cursor-pointer"
            >
              {" "}
              Save{" "}
            </span>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default OrderTax;
