import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import { useFormik } from "formik";
import { AppContext } from "../../../Context/AppContext";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// SCSS
import styles from "./index.module.scss";
// Component
import Text from "./../../FormControl/Text/Text";
import CalendarIcon from "./../../../Assets/images/calendar.svg";
import Date from "./../../../Components/FormControl/Date/Date";
import Select from "./../../../Components/FormControl/Select/Select";
import TimeSetting from "./TimeSetting";
import { getUserList } from "../../../api/user";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const NewTodoRemindersModel = ({
  userAccess,
  showModal,
  disableType,
  userID,
  adminAccess,
  pageTitle,
  modalValues,
  saveReminderData,
  handleCancelClick,
}) => {
  // Formic Set Values
  const [showErrors, setShowErrors] = useState(false);
  const [users, setUsers] = useState([]);
  const { showToastMessage } = useContext(AppContext);
  const getAllUserList = async () => {
    const reqBody = {
      currentPage: 0,
      itemsPerPage: 100,
      globalSearch: "",
      filters: [],
      sortBy: [],
    };
    const response = await getUserList(reqBody);
    if (response && response.status === 200) {
      let addressTypeArr = [];
      response.data.data.rows?.forEach((d, i) => {
        // if (d.id !== userID) {
        let tempArr = {
          id: d.id,
          name: d.full_name,
        };
        addressTypeArr.push(tempArr);
        // }
      });
      setUsers(addressTypeArr);
    } else showToastMessage(undefined, response.data.message, "error", false);
  };
  useEffect(() => {
    getAllUserList();
  }, [userID]);
  // Date Validation
  const dateValidation = (fromDate, toDate) => {
    let startDateInfo = moment(fromDate).format("x");
    let endDateInfo = moment(toDate).format("x");
    if (startDateInfo > endDateInfo) {
      return false;
    }
    return true;
  };
  // In Between Date Validation
  const inBetweenDateValidation = (fromDate, toDate, compareDate) => {
    let startDateInfo = moment(fromDate).format("x");
    let endDateInfo = moment(toDate).format("x");
    let compareDateValue = moment(compareDate).format("x");
    if (compareDateValue < startDateInfo || compareDateValue > endDateInfo) {
      return false;
    }
    return true;
  };
  const formik = useFormik({
    initialValues: modalValues,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      saveReminderData({ ...values });
      setShowErrors(false);
    },
    validate: (values) => {
      const errors = {};
      values.title = values.title.trim();
      if (!values.title) {
        errors.title = "Please enter title";
      }
      if (!disableType) {
        if (values.title.length > 255) {
          errors.title = "Title cannot be greater that 255 character";
        }
        if (!values.reminderType) {
          errors.reminderType = "Please select reminder type";
        }
        if (!values.fromDate) {
          errors.fromDate = "Please select from date";
        }
        if (!values.endDate) {
          errors.endDate = "Please select end date";
        }
        if (
          values.reminderType &&
          values.reminderType === "ADMINISTRATIVE" &&
          values.reminderUsers.length === 0
        ) {
          errors.reminderUsers = "Please select at least one user";
        }
        if (!values.timer) {
          errors.timer = "Please select due time";
        }
        if (!values.repeatType) {
          errors.repeatType = "Please select repeat time";
        }
        if (
          values.repeatType &&
          values.repeatType === "WEEKLY" &&
          values.keyValuesVal.length === 0
        ) {
          errors.keyValuesVal = "Please select at least one week day";
        }
        if (
          values.repeatType &&
          values.repeatType === "MONTHLY" &&
          !values.monthlyDate
        ) {
          errors.monthlyDate = "Please select monthly date";
        }
        if (
          values.fromDate &&
          values.endDate &&
          !dateValidation(values.fromDate, values.endDate)
        ) {
          errors.fromDate =
            "From date should be greater than or equal to end date";
        }
        if (
          values.fromDate &&
          values.endDate &&
          !dateValidation(values.fromDate, values.endDate)
        ) {
          errors.fromDate = "From date should be greater than end date";
        }
        if (
          values.fromDate &&
          values.endDate &&
          values.monthlyDate &&
          !inBetweenDateValidation(
            values.fromDate,
            values.endDate,
            values.monthlyDate
          )
        ) {
          errors.monthlyDate =
            "Montly date should be between from date and end date";
        }
      }
      return errors;
    },
  });
  // Set Formik Values
  const setValues = async (index, value) => {
    if (index === "repeatType") {
      formik.errors.repeatType = "";
      formik.errors.keyValuesVal = "";
      formik.errors.monthlyDate = "";
    }
    await formik.setValues({ ...formik.values, [index]: value });
  };

  useEffect(() => {
    setShowErrors(false);

    if (
      modalValues?.tempReminderUsers &&
      modalValues?.tempReminderUsers.length > 0
    ) {
      let finalSelectedUserData = [];
      if (users.length > 0) {
        users?.map((d, i) => {
          let orderStatusData = modalValues.tempReminderUsers.filter(
            (status) => status.id === d.id
          );

          if (orderStatusData && orderStatusData.length > 0) {
            finalSelectedUserData.push(d);
          }
        });
      }
      // modalValues.reminderUsers = [];
      // console.log("modalValues",modalValues.tempReminderUsers);
      // console.log("finalSelectedUserData=>",finalSelectedUserData);
      modalValues.reminderUsers = [
        ...modalValues.reminderUsers,
        ...finalSelectedUserData,
      ];
    }

    formik.setValues(modalValues);
  }, [modalValues]);

  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <form
            onSubmit={(e) => {
              setShowErrors(true);
              formik.handleSubmit(e);
            }}
          >
            <div className={`text-center ${styles.modalBody}`}>
              <h5 className={`my-3 text-center ${styles.modalTitle}`}>
                {pageTitle}
              </h5>
              {disableType && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <Text
                        type="text"
                        label="Title"
                        onChange={(value) => {
                          setValues("title", value);
                        }}
                        value={formik.values.title}
                      />
                      {showErrors && (
                        <p className={`${styles.error} text-left`}>
                          {formik.errors.title}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}
              {!disableType && (
                <>
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <Text
                        type="text"
                        label="Title"
                        onChange={(value) => {
                          setValues("title", value);
                        }}
                        value={formik.values.title}
                      />
                      {showErrors && (
                        <p className={`${styles.error} text-left`}>
                          {formik.errors.title}
                        </p>
                      )}
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <Select
                        label="Reminder Type"
                        parentClass="text-left"
                        onChange={(value) => {
                          setValues("reminderType", value);
                        }}
                        value={formik.values.reminderType}
                        options={[
                          { id: "PERSONAL", title: "Personal" },
                          { id: "ADMINISTRATIVE", title: "Administrative" },
                        ]}
                        disabled={
                          disableType
                            ? true
                            : adminAccess === "PERSONAL"
                            ? true
                            : userAccess.read && !userAccess.write
                            ? true
                            : false
                        }
                      />
                      {showErrors && (
                        <p className={`${styles.error} text-left`}>
                          {formik.errors.reminderType}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <Date
                        label="From Date"
                        dateFormat="ddd MMM DD, YYYY"
                        type="date"
                        fieldIcon={CalendarIcon}
                        disabled={disableType}
                        onChange={(value) => {
                          setValues(
                            "fromDate",
                            moment(value).format("ddd MMM DD, YYYY")
                          );
                        }}
                        value={formik.values.fromDate}
                        checkPassDate={true}
                        disableToday={false}
                      />
                      {showErrors && (
                        <p className={`${styles.error} text-left`}>
                          {formik.errors.fromDate}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <Date
                        label="End Date"
                        dateFormat="ddd MMM DD, YYYY"
                        type="date"
                        disabled={disableType}
                        fieldIcon={CalendarIcon}
                        onChange={(value) => {
                          setValues(
                            "endDate",
                            moment(value).format("ddd MMM DD, YYYY")
                          );
                        }}
                        value={formik.values.endDate}
                        checkPassDate={true}
                        disableToday={false}
                      />
                      {showErrors && (
                        <p className={`${styles.error} text-left`}>
                          {formik.errors.endDate}
                        </p>
                      )}
                    </div>
                    {formik.values.reminderType === "ADMINISTRATIVE" &&
                    adminAccess === "ADMINISTRATIVE" ? (
                      <>
                        <div className="col-md-12 mt-3">
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            options={users}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, value) => {
                              setValues("reminderUsers", value);
                            }}
                            value={formik.values.reminderUsers}
                            // value={finalSelectedUserData}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Users"
                                placeholder="Select User"
                              />
                            )}
                            disabled={disableType}
                          />
                        </div>
                        {showErrors && (
                          <>
                            <p className={`${styles.error} text-left mt-3`}>
                              {formik.errors.reminderUsers}
                            </p>
                          </>
                        )}
                      </>
                    ) : (
                      <div></div>
                    )}
                    <div className="col-md-6 mt-3">
                      <Date
                        type="time"
                        label="Time"
                        format="hh:mm A"
                        disabled={disableType}
                        value={formik.values.timer}
                        onChange={(value) => {
                          setValues("timer", moment(value).format("hh:mm A"));
                        }}
                        id="timer"
                      />
                      {showErrors && (
                        <p className={`${styles.error} text-left`}>
                          {formik.errors.timer}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6">
                      {!disableType && (
                        <TimeSetting
                          monthlyDate={formik.values.monthlyDate}
                          repeatType={formik.values.repeatType}
                          keyValuesVal={formik.values.keyValuesVal}
                          setReminderToDoValuesTimeSettings={(type, data) => {
                            setValues(type, data);
                          }}
                        />
                      )}
                      {showErrors && formik.errors.repeatType && (
                        <>
                          <br />
                          <p className={`${styles.error} text-center`}>
                            {formik.errors.repeatType}
                          </p>
                        </>
                      )}
                      {showErrors &&
                        formik.values.repeatType &&
                        formik.values.repeatType === "WEEKLY" &&
                        formik.errors.keyValuesVal && (
                          <>
                            <br />
                            <p className={`${styles.error} text-center`}>
                              {formik.errors.keyValuesVal}
                            </p>
                          </>
                        )}
                      {showErrors &&
                        formik.values.repeatType &&
                        formik.values.repeatType === "MONTHLY" &&
                        formik.errors.monthlyDate && (
                          <p className={`${styles.error} text-left`}>
                            {formik.errors.monthlyDate}
                          </p>
                        )}
                    </div>
                  </div>
                </>
              )}
              <div>
                <button
                  type="submit"
                  className="btn gray-btn d-inline-block mt-4 mb-4 mr-2"
                >
                  Save
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleCancelClick();
                  }}
                  className="btn gray-btn d-inline-block mt-4 mb-4 mr-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default NewTodoRemindersModel;
