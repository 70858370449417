import React, { useEffect, useState } from "react";
import classes from "./styles.module.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DropdownMenu from "./DropdownMenu";
import { debounce } from "@material-ui/core";
const Menu = (props) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [onLink, setOnLink] = useState(false);
  const [insideDropdown, setInsideDropdown] = useState(false);

  useEffect(() => {
    if (!onLink && !insideDropdown) {
      setActiveMenu(null);
    }
  }, [onLink, insideDropdown]);

  const changeOnLink = React.useCallback(
    debounce(() => {
      setOnLink(false);
    }, 100),
    [onLink]
  );

  return (
    <div className={`${classes.Menu} my-4`}>
      <nav className="position-relative">
        <ul
          className="d-flex"
          style={{ gap: "0 2rem" }}
          onMouseLeave={() => setInsideDropdown(false)}
        >
          <li
            onClick={() => {
              let filter = {
                id: "is_eligible_for_commission",
                value: 1,
              };
              props.applyFilter(filter);
            }}
          >
            Comission Eligible
          </li>
          {props.list.map((c) => (
            <li
              key={c.slug}
              onMouseEnter={() => {
                if (c.children.length !== 0) {
                  setActiveMenu(c);
                  setOnLink(true);
                  setInsideDropdown(true);
                }
              }}
              onMouseLeave={changeOnLink}
              className="d-inline-flex"
              onClick={() => {
                if (c.slug) {
                  let filter = {
                    id: "url-slugs",
                    value: c.slug.split("/"),
                  };
                  props?.applyFilter(filter);
                }
              }}
            >
              {c.title}
              {c.children.length !== 0 && <ExpandMoreIcon />}
            </li>
          ))}
        </ul>
        {activeMenu && (
          <DropdownMenu
            activeMenu={activeMenu}
            onMouseEnter={() => setInsideDropdown(true)}
            onMouseLeave={() => setInsideDropdown(false)}
            onFilterClick={(item) => {
              let filter = {
                id: "url-slugs",
                value: item.slug.split("/"),
              };
              props?.applyFilter(filter);
              setOnLink(false);
              setInsideDropdown(false);
            }}
          />
        )}
      </nav>
    </div>
  );
};

export default Menu;
