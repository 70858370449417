import React from "react";
import classes from "./index.module.scss";
import CardContent from "@material-ui/core/CardContent";
const Goto = (props) => {
  return (
    <div className={`${classes.gotoBody}`}>
      <CardContent>
        <div className={`link cursor-pointer row`}>
          <div className={`col-md-6`}>
            <h3>Go To</h3>
          </div>
          {props.notShowSaveButton === 1 ? (
            <div className={"col-md-6"}></div>
          ) : (
            <div className={"col-md-6"}>
              <button
                type="submit"
                onClick={props.handleSubmitClick}
                className="btn gray-btn d-inline-block mb-1 mr-2"
              >
                Save
              </button>
            </div>
          )}
          <div className={`row`}>
            <ul class={` ${classes.gotoList}`}>
              {props.gotoList.map((data, index) => (
                <li
                  onClick={() => {
                    let el = document.getElementById(data.id);
                    window.scrollTo(el.offsetLeft, el.offsetTop);
                  }}
                  key={index}
                  class=""
                >
                  <span>{data.title}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </CardContent>
    </div>
  );
};
export default Goto;
