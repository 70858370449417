import React from "react";
import moment from "moment";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// SCSS
import styles from "./index.module.scss";

// Component
import Text from "./../../FormControl/Text/Text";
import Select from "../../FormControl/Select/Select";
import CheckBox from "../../FormControl/CheckBox/CheckBox";
import Date from "../../FormControl/Date/Date";
import CalendarIcon from "../../../Assets/images/calendar.svg";
import { convertUtcTimestampToPstDate } from "Hooks/commanUtility";
import { DateTime } from "luxon";
import {
  END_TIME_OF_DATE_TWENTY_FOUR,
  PST_ZONE,
  START_TIME_OF_DATE_TWENTY_FOUR,
  UTC_ZONE,
} from "Pages/Order/Order/utility/util";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const daySelect = (day) => {
  return moment(day).format("MM/DD/YYYY");
};
const UpchargeDatesModel = ({
  showModal,
  pageTitle,
  submitStatus,
  title,
  amount,
  apllies_on,
  apllies_untill,
  local,
  non_local,
  status,
  onChange,
  handleCancelClick,
  handleSubmitClick,
}) => {
  
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <Text
                  type="text"
                  label="title"
                  value={title}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("title", data)}
                  id="title"
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <Date
                  label="Applies On"
                  // value={apllies_on !== "" ? moment.unix(apllies_on) : ""}
                  value={
                    apllies_on !== ""
                      ? DateTime.fromSeconds(apllies_on)
                          .setZone(UTC_ZONE)
                          .toFormat("LL-dd-yyyy")
                      : ""
                  }
                  dateFormat="MM-DD-YYYY"
                  type="date"
                  required={true}
                  fieldIcon={CalendarIcon}
                  onChange={(data) => {
                    let pst = moment(data).format("MM-DD-YYYY");
                    let timestamp = DateTime.fromFormat(
                      `${pst} ${START_TIME_OF_DATE_TWENTY_FOUR}`,
                      "LL-dd-yyyy HH:mm",
                      {
                        zone: PST_ZONE,
                      }
                    )
                      .setZone(UTC_ZONE)
                      .toSeconds();
                    onChange("apllies_on", timestamp);
                  }}
                  id="apllies_on"
                  submitStatus={submitStatus}
                  checkPassDate={true}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <Date
                  label="Applies Until"
                  value={
                    apllies_untill !== "" ? moment.unix(apllies_untill) : ""
                  }
                  // value={
                  //   apllies_untill !== ""
                  //     ? convertUtcTimestampToPstDate(
                  //         apllies_untill,
                  //         "LL-dd-yyyy"
                  //       )
                  //     : ""
                  // }
                  dateFormat="MM-DD-YYYY"
                  type="date"
                  required={true}
                  fieldIcon={CalendarIcon}
                  onChange={(data) => {
                    
                    let pst = moment(data).format("MM-DD-YYYY");
                    let timestamp = DateTime.fromFormat(
                      `${pst} ${END_TIME_OF_DATE_TWENTY_FOUR}`,
                      "LL-dd-yyyy HH:mm",
                      {
                        zone: PST_ZONE,
                      }
                    )
                      .setZone(UTC_ZONE)
                      .toSeconds();
                    onChange("apllies_untill", timestamp);
                  }}
                  id="apllies_untill"
                  submitStatus={submitStatus}
                  checkPassDate={true}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <Select
                  label="Status"
                  parentClass="text-left"
                  value={status}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("status", data)}
                  options={[
                    { id: 0, title: "In Active" },
                    { id: 1, title: "Active" },
                  ]}
                  disabled={false}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <Text
                  type="number"
                  label="Upcharge Amount"
                  value={amount}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("amount", data)}
                  id="upcharge_amount"
                />
              </div>
              <div className="col-md-3 col-xs-6">
                <CheckBox
                  label="Local"
                  submitStatus={submitStatus}
                  value={local}
                  required={false}
                  onChange={(data) => onChange("local", data)}
                  id="local"
                />
              </div>
              <div className="col-md-3 col-xs-6">
                <CheckBox
                  label="Non Local"
                  submitStatus={submitStatus}
                  value={non_local}
                  required={false}
                  onChange={(data) => onChange("non_local", data)}
                  id="non_local"
                />
              </div>
            </div>
            <a
              href="/"
              data-dismiss="modal"
              onClick={handleSubmitClick}
              className="btn gray-btn d-inline-block mb-3 mr-2"
            >
              Save
            </a>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default UpchargeDatesModel;
