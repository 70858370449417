import React from "react";
import ReactDOM from "react-dom";

const NewModal = (props) => {
  if (process.browser) {
    return ReactDOM.createPortal(
      <>
        {props.show && (
          <div
            style={{
              height: "100vh",
              width: "100vw",
              backgroundColor: "rgba(0,0,0,0.3)",
              position: "fixed",
              top: "0",
              left: "0",
              zIndex: "1310",
            }}
          >
            {props.children}
          </div>
        )}
      </>,
      document.querySelector("#myModal")
    );
  } else {
    return null;
  }
};

export default NewModal;
