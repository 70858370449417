import React from "react";
import Variation from "./Variation";

const VariationView = (props) => {
  return (
    <div
      className="h-100 d-flex flex-wrap align-items-center justify-content-center"
      style={{ background: "rgba(203,153,141,.95)", gap: "1.5rem" }}
    >
      {props.currentProduct.product_variations.map((c, i) => (
        c.status === 1 && <Variation
          {...c}
          is_selected={
            !!props.productSelected.find(
              (z) => z.id === c.product_id && z.selectedVariant.id === c.id
            )
          }
          key={i}
          onClick={() => {
            props.onVariationClick(c);
          }}
        />
      ))}
    </div>
  );
};

export default VariationView;
