import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import AddIcon from "../../../Assets/images/add.svg";
import { formatUsPhone } from "../../../Hooks/commanUtility";
// import CustomersApi from "../../../Services/Customers/Customers";
import Header from "./../../../Components/Header/Header";
import ReactTable from "./../../../Components/Table/Table";
import { AppContext } from "./../../../Context/AppContext";
import { MODULES } from "./../../../Helpers/Constants";
// Components
import { getAccessByModuleName } from "./../../../Routes/Routes";
import styles from "./index.module.scss";
import { createCustomer, customerLookupExport, getCustomerList } from "api/customer";

import LoadingScreen from "Components/Loading/LoadingScreen";
// import UsersApi from "./../../../services/Users/Users";
import ExportIcon from "../../../Assets/images/export.svg";
import { useUser } from "Context/UserContext/useUser";
import { CSVLink } from "react-csv";
import { DateTime } from "luxon";
import CreateCustomer from "Components/Modal/Customer/CreateCustomer";
const Customers = (props) => {
  const { showToastMessage,setIsLoading, modules } = useContext(AppContext);

  // Add / Edit
  const [access, setAccess] = useState({});

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [firstRender, setFirstRender] = useState(true);
  const [customerExportHeader,setCustomerExportHeader] = useState([])
  const [customerExportData,setCustomerExportData] = useState([])
  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });
  const reference = React.useRef(null);
  const { userState } = useUser();

  const [showModel,setShowModel] = useState(false);
  // const [firstTimePageLoad, setFirstTimePageLoad] = React.useState(true);

  //   const usersService = new UsersApi();
  // const customersService = new CustomersApi();

  // Set Async Data
  const setAsyncData = (data) => {
    let asyncDataValuesAccountLookup = JSON.stringify(data);
    localStorage.setItem(
      "asyncDataValuesAccountLookup",
      asyncDataValuesAccountLookup
    );
  };

  // Get And Set Data Into State
  useEffect(() => {
    const filterData = JSON.parse(
      localStorage.getItem("asyncDataValuesAccountLookup")
    );
    if (filterData) {
      const {
        currentPage,
        itemsPerPage,
        globalSearch,
        filters,
        sortBy,
      } = filterData;
      setDefaultFilter({
        filters: filters,
        pageIndex: currentPage,
        globalFilter: globalSearch,
        pageSize: itemsPerPage,
        sortBy: sortBy,
      });
    }
    setFirstRender(false);
  }, []);

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
    };

    // if (!firstTimePageLoad) {
    const newFilter = filters.filter((x) => x.value.length > 3);

    if (newFilter.length > 0) {
      let accountAccess = getAccessByModuleName(
        modules,
        MODULES.ACCOUNT_LOOKUP
      );
      if (accountAccess && accountAccess.permissions.read_access) {
        // const response = await customersService.getCustomerList(postData);
        const response = await getCustomerList(postData);
        if (response && response.status === 200) {
          setData(response.data.data.rows);
          setPageCount(Math.ceil(response.data.data.count / pageSize));
        } else {
          showToastMessage(undefined, response.data.message, "error", false);
        }
      }
    } else {
      setData([]);
      setPageCount(Math.ceil(0 / pageSize));
    }
    // } else {
    //   setFirstTimePageLoad(false);
    // }
    setAsyncData(postData);
    setLoading(false);
  };

  //   useEffect(async () => {}, []);

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.ACCOUNT_LOOKUP);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  let accountAccess = getAccessByModuleName(modules, MODULES.ACCOUNT_LOOKUP);
  let newOrderAccess = getAccessByModuleName(modules, MODULES.NEW_ORDER);
  const columns = React.useMemo(() => [
    // {
    //     Header: 'Account #',
    //     accessor: 'id',
    //     isVisible: true,
    //     placeholder: 'Search By Customer Account #',
    // },
    {
      Header: "Customer Name",
      accessor: "customer_name",
      isVisible: true,
      placeholder: "Customer Name",
      Cell: ({ value, row, column }) => {
        return row.original.id === 0 ? (
          <span>
            {`${row.original.customer_name ? row.original.customer_name : "-"}`}
          </span>
        ) : accountAccess && accountAccess.permissions.write_access ? (
          <Link to={`/admin/customers/${row.original.id}`}>
            <span className={`cursor-pointer`}>
              {`${
                row.original.customer_name ? row.original.customer_name : "-"
              }`}
            </span>
          </Link>
        ) : (
          <span>
            {`${row.original.customer_name ? row.original.customer_name : "-"}`}
          </span>
        );
      },
    },
    {
      Header: "Phone #",
      accessor: "phone",
      isVisible: true,
      placeholder: "Phone #",
      Cell: ({ value, row, column }) => {
        return `${
          row.original.phone
            ? row.original.country_code +
              " " +
              formatUsPhone(row.original.phone)
            : ""
        }`;
      },
    },
    {
      Header: "Email",
      accessor: "email",
      isVisible: true,
      placeholder: "Email",
    },
    {
      Header: "Actions",
      accessor: "id",
      isVisible: true,
      disableSortBy: true,
      Cell: ({ value, row, column }) => {
        return (
          <div>
            {newOrderAccess && newOrderAccess.permissions.write_access ? (
              <Link
                to={{
                  pathname: `/admin/orders/new-order/${row.original.id}`,
                }}
              >
                <span className={`link cursor-pointer ${styles.iconWrapper}`}>
                  <img className={styles.icon} src={AddIcon} alt="" />
                </span>
              </Link>
            ) : (
              <span style={{ width: 34, height: 34, display: "block" }} />
            )}
          </div>
        );
      },
    },
  ]);

  const customerLookupExportData = async()=>{
    setIsLoading(true)
    const response = await customerLookupExport()
    setIsLoading(false)
    setCustomerExportHeader(response.data.data.header);
    setCustomerExportData(response.data.data.data);
    reference.current?.link?.click();
  }

  const handleManageUserClick = ()=>{
    setShowModel(true);
  }
 
  const handleManageCustomer = async (values)=>{
    const postData = {
      ...values,
      alt_phone:values.alt_phone==="" ? null : values.alt_phone
    }
    const response = await createCustomer(postData)
    if (response && response.status === 200) {
      showToastMessage(undefined, response.data.message, "success", false);
      setShowModel(false);
    }else{
        showToastMessage(undefined, response.data.message, "error", false);
    }

  }
  if (firstRender) return <LoadingScreen />;
  return (
    <React.Fragment>
      <Header 
        title="Account Lookup"
        hasAddButton={true}
        handleAddClick={handleManageUserClick}
       />

      <div id="MainContent" className="main-content">
      {userState && userState?.userProfile?.user_type === "super_admin" && (
          <div className="col-md-12">
            {customerExportData && customerExportData?.length > 0 && (
              <CSVLink
                ref={reference}
                style={{
                  display: "none",
                  float: "right",
                  marginTop: "25px",
                }}
                data={customerExportData}
                headers={customerExportHeader}
                filename={"customerLookup.csv"}
                className={`link csvLink cursor-pointer ml-3 ${styles.searchIconWrapper}`}
              >
                DownLoad
              </CSVLink>
            )}
             <span
            onClick={(e) => customerLookupExportData()}
            className={`link cursor-pointer ${styles.searchIconWrapper}`}
            style={{ float: "right"}}
            >
              <img className={styles.searchIcon} src={ExportIcon} alt="" />
          </span>
          </div>
        )}
        <CreateCustomer
        showModal={showModel}
        handleCancelClick={()=>{
          setShowModel(false)
        }}
        pageTitle="Create Customer"
        handleSubmitClick={handleManageCustomer}
        />
        {access.read_access && (
          <ReactTable
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            noDataText={"There is no data to display"}
            defaultFilter={defaultFilter}
            displayGlobalSearch={false}
            displayColumnVisibility={false}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Customers);
