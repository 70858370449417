import React, { useState,useContext } from 'react'
import moment from "moment";
import { DateTime } from "luxon";
import { PST_ZONE } from 'Pages/Order/Order/utility/util';
import { MODULES } from 'Helpers/Constants';
import { useHistory } from "react-router-dom";
//components
import Header from 'Components/Header/Header'
import Date from 'Components/FormControl/Date/Date';
import ReactTable from '../../../Components/Table/Table';
//Icon
import CalendarIcon from "../../../Assets/images/calendar.svg";
import SearchIcon from "../../../Assets/images/search.svg";
import CloseIcon from "../../../Assets/images/close.svg";
import AddIcon from "../../../Assets/images/add.svg"

// Scss
import styles from "./index.module.scss";
import { timeClockAuditTrail, timeClockAuditTrailapi } from 'api/clock';
import { AppContext } from 'Context/AppContext';
import { getAccessByModuleName } from 'Routes/Routes';

const TimeclockAuditTrail = (props) => {
  const history = useHistory();
  const { showToastMessage, modules } = useContext(AppContext);


    //define state
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [fromDate, setFromDate] = useState(moment().format("ddd MMM DD, YYYY"));
    const [toDate, setToDate] = useState(moment().format("ddd MMM DD, YYYY"));
    const [defaultFilter, setDefaultFilter] = useState({
        currentPage: 0,
        itemsPerPage: 10,
        sortBy: [],
        filters: [],
      });
    
    const handleClearSearch = async (event) => {
        event.preventDefault();
        setFromDate(moment().format("ddd MMM DD, YYYY"));
        setToDate(moment().format("ddd MMM DD, YYYY"));
        fetchData({
          ...defaultFilter,
          fromDateDefault: moment().format("YYYY-MM-DD"),
          toDateDefault: moment().format("YYYY-MM-DD"),
        });
      };
    console.log(data); 
    const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
    }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
        const postData = {
            currentPage: pageIndex,
            itemsPerPage: pageSize,
            globalSearch: globalFilter,
            filters,
            sortBy,
            start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
            end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
        };
        const response = await timeClockAuditTrail(postData);

        if (response && response.status === 200) {
          setData(response.data.data.rows);
          // let lengthCount = response.data.data.count
          setPageCount(Math.ceil(response.data.data.count / 10));
        } else if (response.status === 403) {
          showToastMessage(undefined, response.data.message, "error", false);
          history.push("/profile");
        } else {
          showToastMessage(undefined, response.data.message, "error", false);
        }
        setLoading(false);
    };

    //AuditTrail Details Handler
    const AuditTrailDetailsHandler = (event,id)=>{
    event.preventDefault();
    console.log("jkdjksdj");
    history.push(`/time-clock/timeclock-audit-trail/detail/${id}`);
    }

    //action list for filter
    const actionList=[
      {id: "",title: "All" },
      {id:"clockin",title:"Clock-IN"},
      {id:"clockout",title:"Clock-OUT"},
      {id:"changerequest-IN",title:"Change Request-IN"},
      {id:"changerequest-OUT",title:"Change Request-OUT"},
      {id:"approvechangerequest",title:"Approve Change Request"},
      {id:"rejectchangerequest",title:"Reject Change Request"},
      {id:"edit_delete_clock_time_entry",title:"Edit Delete Clock Time Update"},
      {id:"autoclockout",title:"Auto Clock-OUT"},
    ]
    //react table
    let pageAccess = getAccessByModuleName(modules, MODULES.TIMECLOCK_AUDIT_TRAIL);
    const columns = React.useMemo(
    () => [
      {
        Header: "Action",
        accessor: "action",
        isVisible: true,
        isSelectFilter: true,
        selectFilterOption: actionList,
        colWidth: 280,
        placeholder: "Action",
        Cell: ({ value, row, column }) => {
          return (
          <h4>
              {row.original.action}
          </h4>
          );
      },
      },
      {
        Header: "Action By",
        accessor: "action_user_name",
        isVisible: true,
        placeholder: "Action By",
        Cell: ({ value, row, column }) => {
          return `${row.original.action_user_name}`;
        },
      },
      {
        Header: "Action On",
        accessor: "action_on_user_name",
        isVisible: true,
        placeholder: "Action On",
        Cell: ({ value, row, column }) => {
          return `${row.original.action_on_user.action_on_user_name}`;
        },
      },
      {
        Header: "Clock Master Id",
        accessor: "clock_time_masters_id",
        isVisible: true,
        placeholder: "clock_time_masters_id",
        Cell: ({ value, row, column }) => {
          return `#${row.original.clock_time_masters_id}`;
        },
      },
      {
        Header: "Action Create Date",
        accessor: "createdAt",
        isVisible: true,
        placeholder: "Search By Action Create Date",
        Cell: ({ value, row, column }) => {
          return `${
            row.original.createdAt && row.original.createdAt !== null
              ? DateTime.fromISO(row.original.createdAt)
                  .setZone(PST_ZONE)
                  .toFormat("ccc LLL dd, yyyy, hh:mm a")
              : ""
          }`;
        },
      },
      {
        Header: "Action",
        accessor: "id",
        isVisible: (pageAccess && pageAccess.permissions.read_access) ? true : false,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div className={`${styles.iconWrapper}`}>
              {pageAccess && pageAccess.permissions.read_access && (
                <img
                  className={`cursor-pointer ml-3 ${styles.icon}`}
                  src={AddIcon}
                  alt="Detail"
                    onClick={(e) =>
                      AuditTrailDetailsHandler(e, row.original.id)
                    }
                />
              )}
            </div>
          );
        },
      },
    ],
    [data]
  );

  return (
    <React.Fragment>
        <Header title="Timeclock Audit Trail"/>
        <div id="MainContent" className="main-content" />
      <div className="row mb-5">
        <div className="col-sm-5 col-xs-12">
          <Date
            label="Filter From"
            value={fromDate}
            dateFormat="ddd MMM DD, YYYY"
            type="date"
            fieldIcon={CalendarIcon}
            onChange={(fromDate) => {
              setFromDate(moment(fromDate).format("ddd MMM DD, YYYY"));
            }}
          />
        </div>
        <div className="col-sm-5 col-xs-12">
          <Date
            label="Filter To"
            value={toDate}
            dateFormat="ddd MMM DD, YYYY"
            type="date"
            fieldIcon={CalendarIcon}
            onChange={(toDate) => {
              setToDate(moment(toDate).format("ddd MMM DD, YYYY"));
            }}
          />
        </div>
        <div className="col-sm-2 col-xs-12">
          <div className="td-actions pt-4">
            <span
              onClick={(e) => fetchData(defaultFilter)}
              className={`link cursor-pointer ${styles.searchIconWrapper}`}
            >
              <img className={styles.searchIcon} src={SearchIcon} alt="" />
            </span>
            <span
              onClick={(e) => handleClearSearch(e)}
              className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
            >
              <img className={styles.clearIcon} src={CloseIcon} alt="" />
            </span>
          </div>
        </div>
      </div>
      <div>
        <ReactTable
          columns={columns}
          data={data}
          expandedRows={true}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
        />
      </div>
    </React.Fragment>
  )
}

export default TimeclockAuditTrail