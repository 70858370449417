import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { useContext } from "react";
import noImageInProduct from "../../Assets/images/noImageInProduct.png";
import { AppContext } from "../../Context/AppContext";
import { useProduct } from "../../Context/Product/useProduct";
import styles from "./ProductsDrawer.module.scss";
// import { useOrder } from "../../Context/OrderContext/useOrder";

const ProductVariant = ({
  id,
  image,
  price,
  title,
  commission,
  isSelected,
  isSoldOut,
  setProductVariation,
  ...props
}) => {
  const { productState, setCurrentAdd, clearSelectedProduct } = useProduct();
  const { showToastMessage } = useContext(AppContext);
  const onSelected = () => {
    if (
      productState.selectedProduct?.is_sold_out ||
      productState.selectedProduct?.is_out_of_stock ||
      productState.selectedProduct?.inventory_remaining === 0
    ) {
      showToastMessage(
        undefined,
        "Selected Product is out of stock",
        "error",
        false
      );
    } else {
      let selectedVariant = {
        id: id,
        productid: props.product_id,
        image: image,
        price: props.sale_price,
        title: title,
        commission: commission,
      };
      let qty = 1;
      let total_price = qty * props.sale_price;
      let p = {
        ...productState.selectedProduct,
        selectedVariant: selectedVariant,
        qty: qty,
        total_price: total_price,
      };

      let selectedProductTem = props.selectedArray;
      let selectedVariantIndex = selectedProductTem.findIndex(
        (data) => data.selectedVariant.id === id
      );
      if (selectedVariantIndex === -1) {
        selectedProductTem.push(p);
      } else {
        selectedProductTem.splice(selectedVariantIndex, 1);
      }
      props.setSelectedArray(selectedProductTem);
      let selectedProduct = selectedProductTem;
      setCurrentAdd(selectedProduct);
      clearSelectedProduct();
    }
  };
  return (
    <div
      className={styles.card}
      onClick={() => {
        if (props.is_out_of_stock) {
          showToastMessage(
            undefined,
            `Product ${title} is currently out of stock`,
            "error",
            false
          );
        } else if (props.is_sold_out) {
          // console.log("SOLD OUT", props);
          showToastMessage(
            undefined,
            `Product ${title} is currently Sold out`,
            "error",
            false
          );
        } else if (props.inventory_remaining === 0) {
          showToastMessage(
            undefined,
            `Product ${title} is currently out of stock`,
            "error",
            false
          );
        } else {
          onSelected();
        }
        // !props.is_sold_out && onSelected();
      }}
    >
      <img src={image ?? noImageInProduct} className={styles.image} alt="" />
      <div className={styles.name}>{title}</div>
      <div className={styles.textSection}>
        <div className={styles.comm}>Comm. ${commission}</div>
        <div className={styles.sold}>{"Sold out"}</div>
        {props.is_sold_out ? (
          <CheckBoxIcon color="disabled" />
        ) : (
          <div className={styles.soldIcon} />
        )}
      </div>
      <div className={styles.actions}>
        {price === props.sale_price ? (
          <div className={styles.price}>${price}</div>
        ) : (
          <div className={styles.price} style={{ position: "relative" }}>
            <span>${price}</span>{" "}
            <span className={`${styles.horizontalLine}`}></span>
            <span style={{ color: "#db3333" }}>${props.sale_price}</span>
          </div>
        )}

        {isSelected ? (
          <CheckCircleIcon
            color="disabled"
            className={`${styles.circlechecked}`}
          ></CheckCircleIcon>
        ) : (
          <div className={styles.circle}></div>
        )}
      </div>
    </div>
  );
};

export default ProductVariant;
