import React from "react";
import styles from "./index.module.scss";

const TotalBox = (props) => {
  return (
    <div>
      <div className={`${styles.subTotalBlock}`}>
        <div className={`${styles.totalBlockItem} mb-2`}>
          <span>Sub Total</span>
          <span>
            $
            {props.orderData.sub_total
              ? props.orderData.sub_total.toFixed(2)
              : 0}
          </span>
        </div>
        <div
          className={`${styles.totalBlockItem} align-items-center mb-2`}
          style={{ position: "relative" }}
        >
          <span>Discount</span>
          <br></br>
          {props.orderData?.promocode && (
            <span
              style={{
                fontSize: "13px",
                position: "absolute",
                bottom: "-10px",
                margin: 0,
                padding: 0,
                lineHeight: 1,
              }}
            >
              {"(Promo Code : " + props.orderData?.promocode + ")"}
            </span>
          )}
          <span
            className={`${
              props.orderData.subscription_discount === 0
                ? styles.borderBottom
                : ""
            }`}
          >
            - $
            {props.orderData.discount_price
              ? props.orderData.discount_price.toFixed(2)
              : 0}
          </span>
        </div>
        {props.orderData.subscription_discount !== 0 && (
          <div className={`${styles.totalBlockItem} align-items-center mb-2`}>
            <span>Subscription Discount</span>
            <span className={`${styles.borderBottom}`}>
              - $
              {props.orderData.subscription_discount
                ? props.orderData.subscription_discount.toFixed(2)
                : 0}
            </span>
          </div>
        )}
        <div className={`${styles.totalBlockItem} mb-2`}>
          <span>Service/Delivery</span>
          <span>
            $
            {props.orderData.delivery_charges
              ? (props.orderData.delivery_charges + props.orderData.upcharge_amount).toFixed(2)
              : 0}
          </span>
        </div>
        <div className={`${styles.totalBlockItem} align-items-center mb-2`}>
          <span>Tax</span>
          <span>
            ${props.orderData.tax ? props.orderData.tax.toFixed(2) : 0}
          </span>
        </div>
        {/* <div className={`${styles.totalBlockItem} align-items-center mb-2`}>
          <span>Add Funds</span>
          <span>
            $
            {props.orderData.add_fund ? props.orderData.add_fund.toFixed(2) : 0}
          </span>
        </div>
        <div className={`${styles.totalBlockItem} align-items-center mb-2`}>
          <span>ReFunds</span>
          <span>
            -${props.orderData.refund ? props.orderData.refund.toFixed(2) : 0}
          </span>
        </div> */}
        <div className={`${styles.totalBlockItem} align-items-center mb-2`}>
          <span>User Redeem Amount</span>
          <span>
            ${props.orderData?.petal_rewards_point_amount?.toFixed(2)}
          </span>
        </div>
      </div>{" "}
      <div className={`${styles.totalBlock} mt-2`}>
        <div className={`${styles.mainTotalBlockItem} mb-2`}>
          <span>Total</span>
          <span>
            ${props.orderData.total ? props.orderData.total.toFixed(2) : 0}
          </span>
        </div>

        <>
          <div
            className={`${styles.totalBlockItem} align-items-center mb-2`}
            style={{ fontSize: "14px" }}
          >
            <span>Amount recieved till now</span>
            <span>${props.orderData?.total_fund_amount?.toFixed(2)}</span>
          </div>
          <div
            className={`${styles.totalBlockItem} align-items-center mb-2`}
            style={{ fontSize: "14px" }}
          >
            <span>Amount refunded till now</span>
            <span>-${props.orderData?.total_refund_amount?.toFixed(2)}</span>
          </div>
        </>
        {props.orderData.custom_reason_for_discount !== "" && (
          <div>
            <div className={`${styles.totalBlockItem} mb-2`}>
              <span>Discount Reason</span>
            </div>
            <div className={`${styles.totalBlockItem} mb-2`}>
              <span>{props.orderData.custom_reason_for_discount}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default TotalBox;
