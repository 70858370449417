import { getMenuWithSequence, setMenuWithSequence } from "api/cmsMenu";
import React, { useContext, useEffect, useState } from "react";
import Nestable from "react-nestable";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import "../../../../node_modules/react-nestable/dist/styles/index.css";
// Components
import Header from "../../../Components/Header/Header";
import LoadingScreen from "../../../Components/Loading/LoadingScreen";
// Context
import { AppContext } from "../../../Context/AppContext";
import { MODULES } from "../../../Helpers/Constants";
import { getAccessByModuleName } from "../../../Routes/Routes";
import Frame from "./../../../Assets/images/Frame 697@2x.svg";
//scss
import styles from "./index.module.scss";

const Sequence = (props) => {
  const history = useHistory();
  const { showToastMessage, modules } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const updateData = async () => {
    setLoading(true);
    let updateDataArray = [];
    data.forEach((element) => {
      let newData = {};
      newData["id"] = element.id;
      newData["sequence"] = element.sequence;
      updateDataArray.push(newData);
    });
    const postData = {
      data: updateDataArray,
    };
    const response = await setMenuWithSequence(postData);
    if (response && response.status === 200) {
      showToastMessage(
        undefined,
        "Sequence has been updated successfully.",
        "success",
        false
      );
      fetchData();
      // history.push("/product-catalog/category-management");
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const postData = {
      menuID: props.match.params.id,
    };
    const response = await getMenuWithSequence(postData);
    if (response && response.status === 200) {
      setData(response.data.data.rows);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);
  };

  // Back To Menu List
  const menuList = () => {
    history.push("/cms/menu/");
  };

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  // Access Contorol functionm call
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.CATEGORY);
    if (access && !access.permissions.write_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }
  const handleMenuSequence = async (event) => {
    setData(event.items);
  };
  const renderItem = ({ collapseIcon, depth, handler, index, item }) => (
    <div>
      <div className={styles.GridTable}>
        <div
          className={
            "d-flex align-items-center justify-cotent-between cursor-pointer ml-3"
          }
        >
          {collapseIcon}
          <img
            style={{ width: "25px", height: "25px" }}
            src={Frame}
            alt="Move"
          />
        </div>
        <div>{item.title}</div>
        <div>{item.sequence}</div>
      </div>
    </div>
  );
  const SequenceData = () =>
    data && data.length > 0 ? (
      <div>
        <div className="row mb-4">
          <div className={`col-md-9`}>
            <h3 style={{ marginLeft: "0%" }} className="bold mt-5">
            {
              (data[0].parent_id !== data[0].main_parent_id) ? data[0].main_parent.title+' / ' : ''
            }
            {data[0].menu_master.title} &nbsp; & &nbsp; Menu For : {data[0].menu_for} &nbsp;& &nbsp; Position : {data[0].position} 
            </h3>
          </div>
          {/* <div className={`col-md-3`}></div> */}
          <div className={`col-md-1`}>
            <span
              onClick={() => {
                menuList();
              }}
              style={{
                fontSize: "14px",
                padding: "7px 44px",
                width: "100%",
              }}
              className="btn gray-btn d-inline-block mb-2 mr-2 mt-5 cursor-pointer text-right"
            >
              Back
            </span>
          </div>
          <div className={`col-md-1`}>
            <span
              onClick={() => {
                updateData();
              }}
              style={{
                fontSize: "14px",
                padding: "7px 15px",
                marginLeft: "76%",
              }}
              className="btn gray-btn d-inline-block mb-2 mr-2 mt-5 cursor-pointer text-right"
            >
              Save Sequence
            </span>
          </div>
        </div>
        <div className={styles.GridTableHeader}>
          <div className={styles.MoveButton}></div>
          <div className={styles.header}>Title</div>
          <div className={styles.header}>Sequence</div>
        </div>
        <Nestable
          className={`${styles.NestedGroup} nestedSequence`}
          items={data}
          renderItem={renderItem}
          maxDepth={1}
          onChange={handleMenuSequence}
        />
      </div>
    ) : (
      <div>No data found</div>
    );
  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <React.Fragment>
      <Header title="Menu Sequence" />
      <div id="MainContent" className="main-content" />
      <div>
        <SequenceData />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Sequence);
