import React from "react";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// Scss
import styles from "./StatusConfirmation.module.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StatusConfirmation = ({
  showModal,
  title,
  message,
  buttonLabel,
  handleCancelClick,
  handleSubmitClick,
  statusFlag,
  customFlag
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={styles.modalContent}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
                
             {customFlag ? (title) : (statusFlag ? `Inactivate ${title}`  : `Activate ${title}`)}
            </h5>
            <p className={`popup-info-text ${styles.modalMessage}`}>
              {/* {" "}
              {message}{" "} */}
              {customFlag ? (message) : (statusFlag ? `Are you sure want to inactivate this ${message}`  : `Are you sure want to activate this ${message}`)}
            </p>
            <a
              href="/"
              data-dismiss="modal"
              onClick={handleSubmitClick}
              className="btn gray-btn d-inline-block mb-3 mr-2"
            >
              {/* {" "}
              {buttonLabel}{" "} */}
              {/* {statusFlag ? `Inactivate`  : `Activate`} */}
              {customFlag ? (buttonLabel) : (statusFlag ? `Inactivate`  : `Activate`)}
            </a>
            <a
              href="/"
              data-dismiss="modal"
              onClick={handleCancelClick}
              className="btn gray-btn d-inline-block mb-3"
            >
              {" "}
              Cancel{" "}
            </a>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default StatusConfirmation;
