import React, { useState, useContext } from "react";
import AddFaqModal from "./../Modal/Faqs/AddFaq";
import Text from "../FormControl/Text/Text";
import TextArea from "../FormControl/TextArea/TextArea";

// Scss
import styles from "./index.module.scss";

// Context
import { AppContext } from "./../../Context/AppContext";
import { getAccessByModuleName } from "./../../Routes/Routes";
import { MODULES } from "./../../Helpers/Constants";
import { useCategory } from "../../Context/CategoryCurd/useCategory";

// Images
import AddIcon from "./../../Assets/images/add.svg";
import EditIcon from "./../../Assets/images/edit.svg";
import DeleteIcon from "./../../Assets/images/delete.svg";
import ActivatedIcon from "./../../Assets/images/activated.svg";

const CategoryFaq = (props) => {
  const {
    categoryState,
    setCategoryFaqData,
    setCategoryPrimaryData,
    setFaqData,
    removeFaq,
  } = useCategory();

  const faqData = categoryState.faqs ? categoryState.faqs : [];
  const faqInitData = {
    faq_title: categoryState.category_primary_info.faq_title,
    faq_description: categoryState.category_primary_info.faq_description,
    faqs: faqData,
  };

  const { showToastMessage, modules } = useContext(AppContext);

  const [faqQuestion, setFaqQuestion] = useState("");
  const [faqAnswer, setFaqAnswer] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);

  let faqAccess = getAccessByModuleName(modules, MODULES.FAQ);

  const handleChangeVal = (val) => {
    const data = { ...categoryState.category_primary_info, ...val };
    setCategoryPrimaryData(data);
  };
  const handleAddClick = (action = "add", id = null, data = {}) => {
    setUpdateId("");
    setFaqQuestion("");
    setFaqAnswer("");
    if (!isNaN(id)) {
      setUpdateId(id);
      setFaqQuestion(data.question);
      setFaqAnswer(data.description);
    }
    setShowAddModal(true);
  };

  const handleFaqAction = (action, index, status) => {
    if(action === "edit") {
      setFaqData({ index, values: { status: status === 1 ? 0 : 1 } });
    } else if(action === "delete") {
      removeFaq(index);
    }
  };

  const handleManageFaqAddClick = (question, description) => {
    let error = false;
    let validationMessage = "Please add required data";
    if (!question?.trim()) {
      validationMessage = "Please add Faq question";
      error = true;
    } else if (!description?.trim()) {
      validationMessage = "Please add Faq description";
      error = true;
    }

    if (error) {
      showToastMessage(undefined, validationMessage, "error", false);
    } else {
      if (!updateId && updateId!==0) {
        setCategoryFaqData({ id: 0, question, description, status: 1 });
      } else {
        setFaqData({ index: updateId, values: { question, description } });
      }
      setUpdateId("");
      setFaqQuestion("");
      setFaqAnswer("");
      setShowAddModal(false);
    }
  };

  return (
    <>
      <div className={`${styles.productsWrapper}`} id="category_faq">
        <div className={styles.title}>FAQ</div>
        <span
          className={`link cursor-pointer ml-2 ${styles.searchIconWrapper}`}
          onClick={(e) => {
            e.preventDefault();
            handleAddClick();
          }}
        >
          <img className={styles.searchIcon} src={AddIcon} alt="" />
        </span>
        <div className={`col-xs-12 mt-3`}>
          <Text
            type="text"
            label="Faq Title"
            required={false}
            submitStatus={props.submitStatus}
            value={faqInitData.faq_title}
            onChange={(data) => handleChangeVal({ faq_title: data })}
            id="faq_title"
          />
        </div>
        <div className={`col-xs-12`}>
          <TextArea
            label="Faq Description"
            rows={3}
            submitStatus={props.submitStatus}
            value={faqInitData.faq_description}
            required={false}
            onChange={(data) => handleChangeVal({ faq_description: data })}
            disabled={false}
            id="faq_description"
          />
        </div>
        <div className="table-responsive mt-2 mb-4">
          <table className="table">
            <thead>
              <tr>
                <th className="text-left">Question</th>
                <th className="text-left">Status</th>
                <th className="text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              {faqData && faqData.length > 0 ? (
                faqData.map((val, i) => {
                  return (
                    <tr>
                      <td>{val.question}</td>
                      <td>
                        <div className={styles.status}>
                          {val.status === 1 ? (
                            <div className={styles.Active}> Active </div>
                          ) : (
                            <div className={styles.Inactive}>Inactive</div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div>
                          {faqAccess && faqAccess.permissions.write_access && (
                            <img
                              className={`cursor-pointer ml-3`}
                              src={EditIcon}
                              alt="Edit"
                              onClick={(e) => handleAddClick("edit", i, val)}
                            />
                          )}
                          {faqAccess && faqAccess.permissions.write_access && (
                            <img
                              className={`cursor-pointer ml-3`}
                              src={ActivatedIcon}
                              alt="Active"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFaqAction("edit", i, val.status);
                              }}
                            />
                          )}

                          {faqAccess && faqAccess.permissions.delete_access && (
                            <img
                              className={`cursor-pointer ml-3`}
                              src={DeleteIcon}
                              alt="Delete"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFaqAction("delete", i);
                              }}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3} className="text-center">
                    No Faq Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <AddFaqModal
        showModal={showAddModal}
        pageTitle={(!updateId && updateId!==0) ? "Add FAQ" : "Edit FAQ"}
        defaultValue={faqQuestion}
        defaultValueSlug={faqAnswer}
        handleCancelClick={(e) => {
          setUpdateId("");
          setFaqQuestion("");
          setFaqAnswer("");
          setShowAddModal(false);
        }}
        // submitStatus={submitStatus}
        handleSubmitClick={handleManageFaqAddClick}
      />
    </>
  );
};

export default CategoryFaq;
