// import AuditTrailLog from "Pages/Reports/AuditTrail/index";

import React from "react";

import { MODULES } from "../Helpers/Constants";

import Locations from "../Pages/Cms/locations";
import AddLocations from "../Pages/Cms/locations/add-locations";
import BlogManagement from "../Pages/Cms/blogs";
import BlogCategoryManagement from "../Pages/Cms/blogs-category";
import AddBlog from "../Pages/Cms/blogs/AddBlog.jsx";
import CmsFaq from "../Pages/Cms/faqs";
import Menu from "../Pages/Cms/menu";
// import HolidaysCutoff from "../Pages/Settings/HolidaysCutoff";
// import BlockoutDates from "../Pages/Settings/BlockoutDates";
// import DesignerSetting from "./../Pages/Settings/DesignerSettings";
// import PetalRewardsConfig from "../Pages/Settings/PetalRewardsConfig";
// import RecipeSetting from "../Pages/Settings/RecipeSetting";
// import AddBlockout from "../Pages/Settings/BlockoutDates/add";
// import HolidaysCutoffAdd from "../Pages/Settings/HolidaysCutoff/add"
//Cms
import CmsHomePage from "../Pages/Cms/home-page";
//Cms
import AddPages from "../Pages/Cms/pages/AddPage";
import CmsPages from "../Pages/Cms/pages/index";
import ComponentsTest from "../Pages/ComponentTest/ComponentTest";
import CustomerDetails from "../Pages/customers/details";
import CustomerList from "../Pages/customers/list";
import Dashboard from "../Pages/Dashboard/Dashboard";
import MenuSequence from "../Pages/Cms/menu/Sequence";
//DeshBord
import MostPopularProduct from "../Pages/Dashboard/Dashboard/PopularProductList";
import DeliveryMatrix from "../Pages/DeliveryMatrix";
import DeliveryMatrixAddForm from "../Pages/DeliveryMatrix/add";
import OrderHistory from "../Pages/Order/customer-history";
import DesignerStatus from "../Pages/Order/designer-status";
import EditOrder from "../Pages/Order/Edit-order/index.jsx";
import FraudOrders from "../Pages/Order/fraud-order";
import OrderList from "../Pages/Order/list";
import NewOrder from "../Pages/Order/new-order";
import Duplicate from "../Pages/Order/new-order/duplicate";
import Replace from "../Pages/Order/new-order/replace";
import Onfleet from "../Pages/Order/onfleet";
import PendingOrders from "../Pages/Order/pending-order";
import ProcessOrders from "../Pages/Order/process-order";
import ViewOrder from "../Pages/Order/view-order";
import CategoryManagement from "../Pages/ProductCatalog/Category-Management";
import AddCategory from "../Pages/ProductCatalog/Category-Management/add-category";
//Coupon
import DiscountCoupon from "../Pages/ProductCatalog/Coupon";
import AddDiscountCoupon from "../Pages/ProductCatalog/Coupon/add";
import ProductManagement from "../Pages/ProductCatalog/ProductManagement";
import AddProduct from "../Pages/ProductCatalog/ProductManagement/AddProduct";
import ProductSequence from "../Pages/ProductCatalog/ProductManagement/Sequence";
import SaleProductSequence from "Pages/ProductCatalog/ProductManagement/SaleProductSequence";
// product catalog
import Recipe from "../Pages/ProductCatalog/recipe";
import AddRecipe from "../Pages/ProductCatalog/recipe/addRecipe";
import CancelOrders from "../Pages/Reports/CancelOrders/CancelOrders";
import ReplaceRefundOrders from "../Pages/Reports/ReplaceRefundOrders/ReplaceRefundOrders";
//Coupon
import Commission from "../Pages/Reports/Commission/commission";
import CommissionReportLookUp from "../Pages/Reports/Commission/LookUp/index";
import Productivity from "../Pages/Reports/Productivity/Productivity";
import ProductsOrder from "../Pages/Reports/ProductOrders/index";
import ProductsOrderLookUp from "../Pages/Reports/ProductOrders/LookUp/index";
import RevenueBreakdown from "../Pages/Reports/RevenueBreakdown/index";
// import SalesGrowth from "../Pages/Reports/SalesGrowth/index";
import ShopCode from "../Pages/Reports/ShopCode/index";
import BlockoutDates from "../Pages/Settings/BlockoutDates";
import AddBlockout from "../Pages/Settings/BlockoutDates/add";
import HolidaysCutoff from "../Pages/Settings/HolidaysCutoff";
import HolidaysCutoffAdd from "../Pages/Settings/HolidaysCutoff/add";
import RecipeSetting from "../Pages/Settings/RecipeSetting";
import PetalRewardsConfig from "../Pages/Settings/PetalRewardsConfig";
// // Settings
import StoreSetting from "../Pages/Settings/StoreSetting";
import ForgotPassword from "./../Pages/ForgotPassword";
// Components
// Users
import Login from "./../Pages/Login/Login";
import Notification from "./../Pages/Notification";
import Profile from "./../Pages/Profile";
import ResetPassword from "./../Pages/ResetPassword";
import DesignerSetting from "./../Pages/Settings/DesignerSettings";
// // Settings
// import StoreSetting from "../Pages/Settings/StoreSetting";
import TimeClockSettings from "./../Pages/Settings/TimeClock";
// Time clock
import TimeClock from "./../Pages/TimeClock";
import ApproveTimeSheet from "./../Pages/TimeClock/ApproveTimesheet";
import TimeclockAuditTrail from "./../Pages/TimeClock/TimeclockAuditTrail"
import TimeClockDetails from "./../Pages/TimeClock/Details";
import TimeClockHistory from "./../Pages/TimeClock/History";
import AdminTimeClockHistory from "./../Pages/TimeClock/Report";
import UserList from "./../Pages/Users/List";
import Roles from "./../Pages/Users/Roles";
import VerifyUser from "./../Pages/VerifyUser";
//REPORTS
import AuditTrailPages from "../Pages/Reports/audit-trail";
import AuditTrailDetailPages from "../Pages/Reports/audit-trail/detailPage";
import StockArrangements from "../Pages/Reports/StockArrangements/StockArrangements";
import UnderMaintainence from "Pages/UnderMaintainence";
import BusinessReport from "Pages/Reports/BusinessReport/BusinessReport";
import CouponReport from "Pages/Reports/CouponReport/CouponReport";
import FlowerBuyerReport from "Pages/Reports/FlowerBuyerReport/FlowerBuyerReport";
//Todo
import RemindersTodo from "../Pages/Todo/Reminders"
import Inventory from "Pages/Todo/Inventory"
// MOST KEYWORD SEARCH
import MostKeywordSearch from "../Pages/Reports/MostKeywordSearch"

import CompletedOrder from "../Pages/Order/completed-order";
import SubscriptionList from "../Pages/Order/subscription-order/list";
import SubscriptionSubOrderList from "../Pages/Order/subscription-order/sub-order";
import AuditTrailDetails from "Pages/TimeClock/TimeclockAuditTrail/detailsPage";
import Burq from "Pages/Order/burq";
import OfficeProductivity from "Pages/Reports/OfficeProductivity/OfficeProductivity";
import RequestResources from "Pages/InventroryMangement/RequestResources";
import SendResources from "Pages/InventroryMangement/SendResources";
import Pricing from "Pages/InventroryMangement/Pricing";
import WasteSheet from "Pages/InventroryMangement/WasteSheetMangement";
import AddRequest from "Pages/InventroryMangement/RequestResources/AddRequest";
import AddSendResource from "Pages/InventroryMangement/SendResources/AddSendResource";
import ResourceDetails from 'Pages/InventroryMangement/SendResources/ResoucesDetails'
import AddEditWasteSheet from "Pages/InventroryMangement/WasteSheetMangement/AddWastesheet";

const routes = [
  {
    key: "login",
    displayName: "Login",
    children: [
      // {
      //   path: "*",
      //   displayName: "",
      //   showOnSideBar: false,
      //   exact: false,
      //   component: () => <UnderMaintainence />,
      // },
      {
        path: "/",
        displayName: "",
        showOnSideBar: false,
        exact: true,
      },
      {
        path: "/login",
        displayName: "Login",
        exact: true,
        showOnSideBar: false,
        component: () => <Login />,
      },
      {
        path: "/forgot-password",
        displayName: "Forgot Password",
        exact: true,
        showOnSideBar: false,
        component: () => <ForgotPassword />,
      },
      {
        path: "/reset-password",
        displayName: "Reset Password",
        exact: true,
        showOnSideBar: false,
        component: () => <ResetPassword />,
      },
      {
        path: "/verify-user",
        displayName: "Verify User",
        exact: true,
        showOnSideBar: false,
        component: () => <VerifyUser />,
      },
    ],
  },
  {
    key: "dashboard",
    displayName: "Dashboard",
    children: [
      {
        path: "/dashboard",
        displayName: "Dashboard",
        moduleName: MODULES.REPORTS,
        exact: true,
        showOnSideBar: true,
        component: () => <Dashboard />,
      },
      {
        path: "/dashboard/most-popular-product",
        displayName: "Most Popular Product",
        moduleName: MODULES.REPORTS,
        expect: true,
        showOnSideBar: false,
        component: () => <MostPopularProduct />,
      },
    ],
  },
  {
    key: "orders",
    displayName: "Orders",
    children: [
      {
        path: "/admin/customers/list",
        displayName: "Account Lookup",
        moduleName: MODULES.ACCOUNT_LOOKUP,
        exact: true,
        showOnSideBar: true,
        component: () => <CustomerList />,
      },
      {
        path: `/admin/customers/:id`,
        displayName: "Account Lookup",
        moduleName: MODULES.ACCOUNT_LOOKUP,
        exact: true,
        showOnSideBar: false,
        component: () => <CustomerDetails />,
      },
      {
        path: "/admin/orders/list",
        displayName: "Order Lookup",
        moduleName: MODULES.ORDER_LOOKUP,
        exact: true,
        showOnSideBar: true,
        component: () => <OrderList />,
      },
      {
        path: "/admin/orders/list/:id/:name",
        displayName: "Order History",
        moduleName: MODULES.ORDER_LOOKUP,
        exact: true,
        showOnSideBar: false,
        component: () => <OrderHistory />,
      },
      {
        path: "/admin/orders/new-order",
        displayName: "New Order",
        moduleName: MODULES.NEW_ORDER,
        exact: true,
        showOnSideBar: true,
        component: () => <NewOrder />,
      },
      {
        path: "/admin/orders/new-order/:id",
        displayName: "New Order",
        moduleName: MODULES.NEW_ORDER,
        exact: true,
        showOnSideBar: false,
        component: () => <NewOrder />,
      },
      {
        path: "/admin/orders/replace-order/:id",
        displayName: "Replace Order",
        moduleName: MODULES.NEW_ORDER,
        exact: true,
        showOnSideBar: false,
        component: () => <Replace />,
      },
      {
        path: "/admin/orders/duplicate-order/:id",
        displayName: "Duplicate Order",
        moduleName: MODULES.NEW_ORDER,
        exact: true,
        showOnSideBar: false,
        component: () => <Duplicate />,
      },
      {
        path: "/admin/orders/process",
        displayName: "Process Orders",
        moduleName: MODULES.PROCESS_ORDER,
        exact: true,
        showOnSideBar: true,
        component: () => <ProcessOrders />,
      },
      {
        path: "/admin/orders/pending",
        displayName: "Pending Orders",
        moduleName: MODULES.PENDING_ORDER,
        exact: true,
        showOnSideBar: true,
        component: () => <PendingOrders />,
      },
      {
        path: "/admin/orders/fraud",
        displayName: "Fraud Orders",
        moduleName: MODULES.FRAUD_ORDER,
        exact: true,
        showOnSideBar: true,
        component: () => <FraudOrders />,
      },
      {
        path: "/admin/subscriptions",
        displayName: "Subscription Order",
        moduleName: MODULES.SUBSCRIPTION_LIST,
        exact: true,
        showOnSideBar: true,
        component: () => <SubscriptionList />,
      },
      {
        path: "/admin/orders/view/:id",
        displayName: "View Order",
        moduleName: MODULES.VIEW_ORDER,
        exact: true,
        showOnSideBar: false,
        component: () => <ViewOrder />,
      },
      {
        path: "/admin/orders/edit/:id",
        displayName: "Edit Order",
        moduleName: MODULES.EDIT_ORDER,
        exact: true,
        showOnSideBar: false,
        component: () => <EditOrder />,
      },
      {
        path: "/admin/orders/complete",
        displayName: "Completed Orders",
        moduleName: MODULES.COMPLETED_ORDER,
        exact: true,
        showOnSideBar: true,
        component: () => <CompletedOrder />,
      },
      {
        path: "/admin/subscriptions/list/:id",
        displayName: "Subscription Sub Order",
        moduleName: MODULES.SUBSCRIPTION_LIST,
        exact: true,
        showOnSideBar: false,
        component: () => <SubscriptionSubOrderList />,
      },
    ],
  },
  {
    key: "users",
    displayName: "Users",
    children: [
      {
        path: "/users",
        displayName: "User List",
        moduleName: MODULES.USER,
        exact: true,
        showOnSideBar: true,
        component: () => <UserList />,
      },
      {
        path: "/users/roles",
        displayName: "Roles & Rights",
        moduleName: MODULES.ROLES_RIGHTS,
        exact: true,
        showOnSideBar: true,
        component: () => <Roles />,
      },
      {
        path: "/notifications",
        displayName: "Notifications",
        moduleName: MODULES.USER,
        exact: true,
        showOnSideBar: false,
        component: () => <Notification />,
      },
      {
        path: "/profile",
        displayName: "Profile",
        // moduleName: MODULES.USER_PROFILE,
        exact: true,
        showOnSideBar: false,
        component: () => <Profile />,
      },
    ],
  },
  {
    key: "dispatch",
    displayName: "Dispatch",
    children: [
      {
        path: "/admin/orders/onfleet",
        displayName: "Onfleet",
        moduleName: MODULES.ONFLEET,
        exact: true,
        showOnSideBar: true,
        component: () => <Onfleet />,
      },
      {
        path: "/admin/orders/on-demand",
        displayName: "On-Demand",
        moduleName: MODULES.ONDEMAND,
        exact: true,
        showOnSideBar: true,
        component: () => <Burq />,
      },
      {
        path: "/admin/orders/designer-status",
        displayName: "Designer Status",
        moduleName: MODULES.DESIGNER_STATUS,
        exact: true,
        showOnSideBar: true,
        component: () => <DesignerStatus />,
      },
    ],
  },
  {
    key: "todo",
    displayName: " To Do",
    children: [
      // {
      //   path: "/admin/todo/inventory",
      //   displayName: "Inventory",
      //   moduleName: MODULES.INVENTORY_PRODUCTS,
      //   exact: true,
      //   showOnSideBar: true,
      //   component: () => <Inventory />,
      // },
      {
        path: "/to-do/reminders",
        displayName: "Reminders For Me",
        // moduleName: MODULES.ADMINISTRATIVE_REMINDERS,
        accessPermission : true,
        exact: true,
        showOnSideBar: true,
        component: () => <RemindersTodo />,
      }
    ],
  },
  {
    key:"inventory-management",
    displayName:"Inventory Management",
    children : [
      {
        path: "/inventory-management/inventory",
        displayName: "Inventory",
        moduleName: MODULES.INVENTORY_PRODUCTS,
        exact: true,
        showOnSideBar: true,
        component: () => <Inventory />,
      },
      {
        path: "/inventory-management/request-resources",
        displayName: "Request Resources",
        moduleName: MODULES.REQUEST_RESOURCES,
        exact: true,
        showOnSideBar: true,
        component: () => <RequestResources />,
      },
      {
        path: "/inventory-management/request-resources/add-request",
        displayName: "Add Request Resources",
        moduleName: MODULES.REQUEST_RESOURCES,
        exact: true,
        showOnSideBar: false,
        component: () => <AddRequest />,
      },
      {
        path: "/inventory-management/request-resources/view-request/:viewId",
        displayName: "Add Request Resources",
        moduleName: MODULES.REQUEST_RESOURCES,
        exact: true,
        showOnSideBar: false,
        component: () => <AddRequest />,
      },
      {
        path: "/inventory-management/request-resources/edit-request/:updateId",
        displayName: "Edit Request Resources",
        moduleName: MODULES.REQUEST_RESOURCES,
        exact: true,
        showOnSideBar: false,
        component: () => <AddRequest />,
      },
      {
        path: "/inventory-management/send-resources",
        displayName: "Send Resources",
        moduleName: MODULES.SEND_RESOURCES,
        exact: true,
        showOnSideBar: true,
        component: () => <SendResources />,
      },
      {
        path: "/inventory-management/send-resources/add-request",
        displayName: "Add Send Resources",
        moduleName: MODULES.SEND_RESOURCES,
        exact: true,
        showOnSideBar: false,
        component: () => <AddSendResource />,
      },
      {
        path: "/inventory-management/send-resources/:requestId",
        displayName: "Send Resources",
        moduleName: MODULES.SEND_RESOURCES,
        exact: true,
        showOnSideBar: false,
        component: () => <ResourceDetails />,
      },
      {
        path: "/inventory-management/send-resources/view/:viewId",
        displayName: "Send Resources",
        moduleName: MODULES.SEND_RESOURCES,
        exact: true,
        showOnSideBar: false,
        component: () => <ResourceDetails />,
      },
      // {
      //   path: "/inventory-management/flower-buyer-report",
      //   displayName: "Flower Buyer Report",
      //   moduleName: MODULES.FLOWER_BUYER_REPORT,
      //   exact: true,
      //   showOnSideBar: true,
      //   component: () => <FlowerBuyerReport />,
      // },
      {
        path: "/inventory-management/pricing",
        displayName: "Pricing",
        moduleName: MODULES.PRICING,
        exact: true,
        showOnSideBar: true,
        component: () => <Pricing />,
      },
      {
        path: "/inventory-management/waste-sheet",
        displayName: "Waste Sheet",
        moduleName: MODULES.WASTE_SHEET,
        exact: true,
        showOnSideBar: true,
        component: () => <WasteSheet />,
      },
      {
        path: "/inventory-management/waste-sheet/add-waste",
        displayName: "Waste Sheet",
        moduleName: MODULES.WASTE_SHEET,
        exact: true,
        showOnSideBar: false,
        component: () => <AddEditWasteSheet/>
      },
      {
        path: "/inventory-management/waste-sheet/:wasteId",
        displayName: "Waste Sheet",
        moduleName: MODULES.WASTE_SHEET,
        exact: true,
        showOnSideBar: false,
        component: () => <AddEditWasteSheet/>
      },
      {
        path: "/inventory-management/waste-sheet/view/:viewId",
        displayName: "Waste Sheet",
        moduleName: MODULES.WASTE_SHEET,
        exact: true,
        showOnSideBar: false,
        component: () => <AddEditWasteSheet/>
      }
    ]
  },
  {
    key: "reports",
    displayName: "Reports",
    children: [
      {
        path: "/reports/product-orders",
        displayName: "Product Orders",
        moduleName: MODULES.PRODUCT_ORDER_REPORT,
        exact: true,
        showOnSideBar: true,
        component: () => <ProductsOrder />,
      },
      {
        path: "/reports/product-orders/:id",
        displayName: "Products Order Lookup",
        moduleName: MODULES.PRODUCT_ORDER_REPORT,
        exact: true,
        showOnSideBar: false,
        component: () => <ProductsOrderLookUp />,
      },
      {
        path: "/reports/audit-trail",
        displayName: "Audit Trail Log",
        moduleName: MODULES.AUDIT_TRAIL_LOG_REPORT,
        exact: true,
        showOnSideBar: true,
        component: () => <AuditTrailPages />,
      },
      {
        path: "/reports/audit-trail/detail/:reportId",
        displayName: "Audit Trail Log Detail",
        moduleName: MODULES.AUDIT_TRAIL_LOG_REPORT,
        exact: true,
        showOnSideBar: false,
        component: () => <AuditTrailDetailPages />,
      },
      {
        path: "/reports/cancel-orders",
        displayName: "Cancel Orders",
        moduleName: MODULES.CANCEL_ORDER_REPORT,
        exact: true,
        showOnSideBar: true,
        component: () => <CancelOrders />,
      },
      {
        path: "/reports/shop-code",
        displayName: "Shop Code",
        moduleName: MODULES.SHOP_CODE_REPORT,
        exact: true,
        showOnSideBar: true,
        component: () => <ShopCode />,
      },
      {
        path: "/reports/commission",
        displayName: "Commission",
        moduleName: MODULES.COMMISSION_REPORT,
        exact: true,
        showOnSideBar: true,
        component: () => <Commission />,
      },
      {
        path: "/reports/commission/:id",
        displayName: "Commission Report Lookup",
        moduleName: MODULES.COMMISSION_REPORT,
        exact: true,
        showOnSideBar: false,
        component: () => <CommissionReportLookUp />,
      },
      // {
      //   path: "/reports/sales-growth",
      //   displayName: "Sales Growth",
      //   moduleName: MODULES.REPORTS,
      //   exact: true,
      //   showOnSideBar: true,
      //   component: () => <SalesGrowth />,
      // },
      {
        path: "/reports/productivity",
        displayName: "Designer Productivity Report",
        moduleName: MODULES.PRODUCTIVITY_REPORT,
        exact: true,
        showOnSideBar: true,
        component: () => <Productivity />,
      },
      {
        path: "/reports/office-productivity",
        displayName: "Office Productivity",
        moduleName: MODULES.OFFICE_PRODUCTIVITY_REPORT,
        exact: true,
        showOnSideBar: true,
        component: () => <OfficeProductivity />,
      },
      {
        path: "/reports/revenue-breakdown",
        displayName: "Revenue Breakdown",
        moduleName: MODULES.REVENUE_BREAKDOWN_REPORT,
        exact: true,
        showOnSideBar: true,
        component: () => <RevenueBreakdown />,
      },
      {
        path: "/reports/stock-arrangements",
        displayName: "Stock Arrangements",
        moduleName: MODULES.STOCK_ARRANGEMENTS_REPORT,
        exact: true,
        showOnSideBar: true,
        component: () => <StockArrangements />,
      },
      {
        path: "/reports/MostKeywordSearch",
        displayName: "Most Keyword Search",
        moduleName: MODULES.MOST_KEYWORD_SEARCH,
        exact: true,
        showOnSideBar: true,
        component: () => <MostKeywordSearch />,
      },
      {
        path: "/reports/business-report",
        displayName: "Business Report",
        moduleName: MODULES.BUSINESS_REPORT,
        exact: true,
        showOnSideBar: true,
        component: () => <BusinessReport />,
      },
      {
        path:"/reports/replacements-refunds",
        displayName: "Replacements and Refunds",
        moduleName:MODULES.REPLACE_REFUND_ORDER_REPORT,
        exact:true,
        showOnSideBar:true,
        component: () => <ReplaceRefundOrders />,
      },
      {
        path: "/reports/coupon-report",
        displayName: "Coupon Report",
        moduleName: MODULES.REPORTS,
        exact: true,
        showOnSideBar: true,
        component: () => <CouponReport />,
      },
      {
        path: "/reports/flower-buyer-report",
        displayName: "Flower Buyer Report",
        moduleName: MODULES.FLOWER_BUYER_REPORT,
        exact: true,
        showOnSideBar: true,
        component: () => <FlowerBuyerReport />,
      },
    ],
  },
  {
    key: "time-clock",
    displayName: "Time Clock",
    children: [
      {
        path: "/time-clock",
        displayName: "Clock In (Clock Out)",
        moduleName: MODULES.CLOCK_IN_CLOCK_OUT,
        exact: true,
        showOnSideBar: true,
        component: () => <TimeClock />,
      },
      {
        path: "/time-clock/history",
        displayName: "Time History",
        moduleName: MODULES.TIME_HISTORY,
        exact: true,
        showOnSideBar: true,
        component: () => <TimeClockHistory />,
      },
      {
        path: "/time-clock/report",
        displayName: "Timesheet Report",
        moduleName: MODULES.TIMESHEET_REPORT,
        exact: true,
        showOnSideBar: true,
        component: () => <AdminTimeClockHistory />,
      },
      {
        path: "/time-clock/approve-timesheet",
        displayName: "Approve Timesheet",
        moduleName: MODULES.APPROVE_TIMESHEET,
        exact: true,
        showOnSideBar: true,
        component: () => <ApproveTimeSheet />,
      },
      {
        path: "/time-clock/timeclock-audit-trail",
        displayName: "Timeclock Audit Trail",
        moduleName: MODULES.TIMECLOCK_AUDIT_TRAIL,
        exact: true,
        showOnSideBar: true,
        component: () => <TimeclockAuditTrail />,
      },
      {
        path: "/time-clock/timeclock-audit-trail/detail/:reportId",
        displayName: "Timeclock Audit Trail",
        moduleName: MODULES.TIMECLOCK_AUDIT_TRAIL,
        exact: true,
        showOnSideBar: false,
        component: () => <AuditTrailDetails />,
      },
      {
        path: "/time-clock/:id",
        displayName: "Time History Details",
        moduleName: MODULES.TIME_HISTORY,
        exact: true,
        showOnSideBar: false,
        component: () => <TimeClockDetails />,
      },
      {
        path: "/component-test",
        displayName: "Component Test",
        moduleName: MODULES.TIME_HISTORY,
        exact: true,
        showOnSideBar: false,
        component: () => <ComponentsTest />,
      },
    ],
  },
  {
    key: "product-catalog",
    displayName: "Product Catalog",
    children: [
      {
        path: "/product-catalog/category-management",
        displayName: "Category",
        moduleName: MODULES.CATEGORY,
        exact: true,
        showOnSideBar: true,
        component: () => <CategoryManagement />,
      },
      {
        path: "/product-catalog/Category-Management/add-category",
        moduleName: MODULES.CATEGORY,
        exact: true,
        showOnSideBar: false,
        component: () => <AddCategory />,
      },
      {
        path: "/product-catalog/Category-Management/edit-category/:categoryId/:categoryHierarchyId",
        moduleName: MODULES.CATEGORY,
        exact: true,
        showOnSideBar: false,
        component: () => <AddCategory />,
      },
      {
        path: "/product-catalog/Category-Management/add-category-hierarchy/:categoryHierarchyId",
        moduleName: MODULES.CATEGORY,
        exact: true,
        showOnSideBar: false,
        component: () => <AddCategory />,
      },
      {
        path: "/ProductCatalog/Category-Management/add-category",
        moduleName: MODULES.USER,
        exact: true,
        showOnSideBar: false,
        component: () => <AddCategory />,
      },
      {
        path: "/product-catalog/Category-Management/edit-category/:categoryId",
        moduleName: MODULES.USER,
        exact: true,
        showOnSideBar: false,
        component: () => <AddCategory />,
      },
      {
        path: "/product-catalog/Category-Management/add-category-hierarchy/:categoryHierarchyId",
        moduleName: MODULES.USER,
        exact: true,
        showOnSideBar: false,
        component: () => <AddCategory />,
      },
      {
        path: "/ProductCatalog/Category-Management/sequence/:id",
        displayName: "Product Sequence",
        moduleName: MODULES.CATEGORY,
        exact: true,
        showOnSideBar: false,
        component: () => <ProductSequence />,
      },
      {
        path: "/product-catalog/product-management",
        displayName: "Product - Add Ons",
        moduleName: MODULES.PRODUCT_ADDONS,
        exact: true,
        showOnSideBar: true,
        component: () => <ProductManagement />,
      },
      {
        path: "/product-catalog/product-management/add-product",
        displayName: "Add Product",
        moduleName: MODULES.PRODUCT_ADDONS,
        exact: true,
        showOnSideBar: false,
        component: () => <AddProduct />,
      },
      {
        path: "/product-catalog/product-management/edit-product/:updateId",
        displayName: "Edit Product",
        moduleName: MODULES.PRODUCT_ADDONS,
        exact: true,
        showOnSideBar: false,
        component: () => <AddProduct />,
      },
      {
        path: "/product-catalog/product-management/:view-product/:updateId/",
        displayName: "View Product",
        moduleName: MODULES.PRODUCT_ADDONS,
        exact: true,
        showOnSideBar: false,
        component: () => <AddProduct />,
      },
      {
        path: "/product-catalog/sale-product",
        displayName: "Sale Product",
        moduleName: MODULES.SALE_PRODUCT,
        exact: true,
        showOnSideBar: true,
        component: () => <SaleProductSequence />,
      },
      {
        path: "/product-catalog/recipe",
        displayName: "Recipe",
        moduleName: MODULES.RECIPE,
        exact: true,
        showOnSideBar: true,
        component: () => <Recipe />,
      },
      {
        path: "/product-catalog/recipe/add-recipe",
        displayName: "Add Recipe",
        moduleName: MODULES.RECIPE,
        exact: true,
        showOnSideBar: false,
        component: () => <AddRecipe />,
      },
      {
        path: "/product-catalog/recipe/edit-recipe/:updateId",
        displayName: "Edit Recipe",
        moduleName: MODULES.RECIPE,
        exact: true,
        showOnSideBar: false,
        component: () => <AddRecipe />,
      },
      {
        path: "/product-catalog/discount-coupons",
        displayName: "Coupon",
        moduleName: MODULES.COUPON,
        exact: true,
        showOnSideBar: true,
        component: () => <DiscountCoupon />,
      },
      {
        path: "/product-catalog/discount-coupons/add",
        displayName: "Add Coupons",
        moduleName: MODULES.COUPON,
        exact: true,
        showOnSideBar: false,
        component: () => <AddDiscountCoupon />,
      },
      {
        path: "/product-catalog/discount-coupons/edit-coupon/:couponId",
        displayName: "Edit Coupons",
        moduleName: MODULES.COUPON,
        exact: true,
        showOnSideBar: false,
        component: () => <AddDiscountCoupon />,
      },
    ],
  },
  {
    key: "deliverymatrix",
    displayName: "Delivery Matrix",
    children: [
      {
        path: "/delivery-matrix",
        displayName: "Delivery Matrix",
        moduleName: MODULES.DELIVERY_MATRIX,
        exact: true,
        showOnSideBar: true,
        component: () => <DeliveryMatrix />,
      },
      {
        path: "/delivery-matrix/add",
        displayName: "Delivery Matrix",
        moduleName: MODULES.DELIVERY_MATRIX,
        exact: true,
        showOnSideBar: false,
        component: () => <DeliveryMatrixAddForm />,
      },
      {
        path: "/delivery-matrix/edit/:deliveryMatrixId",
        displayName: "Delivery Matrix",
        moduleName: MODULES.DELIVERY_MATRIX,
        exact: true,
        showOnSideBar: false,
        component: () => <DeliveryMatrixAddForm />,
      },
    ],
  },
  {
    key: "cms",
    displayName: "CMS",
    children: [
      {
        path: "/admin/Cms/home-page",
        displayName: "Home Page",
        moduleName: MODULES.HOME_PAGE,
        exact: true,
        showOnSideBar: true,
        component: () => <CmsHomePage />,
      },
      {
        path: "/cms/locations",
        displayName: "Locations",
        moduleName: MODULES.LOCATIONS,
        exact: true,
        showOnSideBar: true,
        component: () => <Locations />,
      },
      {
        path: "/cms/locations/add",
        displayName: "",
        moduleName: MODULES.LOCATIONS,
        exact: true,
        showOnSideBar: false,
        component: () => <AddLocations />,
      },
      {
        path: "/cms/locations/edit/:updateId",
        displayName: "",
        moduleName: MODULES.LOCATIONS,
        exact: true,
        showOnSideBar: false,
        component: () => <AddLocations />,
      },
      {
        path: "/cms/locations/add-hierarchy/:parentId",
        displayName: "",
        moduleName: MODULES.LOCATIONS,
        exact: true,
        showOnSideBar: false,
        component: () => <AddLocations />,
      },
      {
        path: "/cms/menu/sequence/:id",
        displayName: "",
        moduleName: MODULES.MENU,
        exact: true,
        showOnSideBar: false,
        component: () => <MenuSequence />,
      },
      {
        path: "/cms/menu",
        displayName: "Menu",
        moduleName: MODULES.MENU,
        exact: true,
        showOnSideBar: true,
        component: () => <Menu />,
      },
      {
        path: "/cms/cms-page",
        displayName: "Pages",
        moduleName: MODULES.PAGES,
        exact: true,
        showOnSideBar: true,
        component: () => <CmsPages />,
      },
      {
        path: "/cms/cms-page/add",
        displayName: "Pages",
        moduleName: MODULES.PAGES,
        exact: true,
        showOnSideBar: false,
        component: () => <AddPages />,
      },
      {
        path: "/cms/cms-page/edit/:updateId",
        displayName: "Pages",
        moduleName: MODULES.PAGES,
        exact: true,
        showOnSideBar: false,
        component: () => <AddPages />,
      },
      {
        path: "/cms/faq",
        displayName: "FAQs",
        moduleName: MODULES.FAQ,
        exact: true,
        showOnSideBar: true,
        component: () => <CmsFaq />,
      },
      {
        path: "/cms/blog-management",
        displayName: "Blogs",
        moduleName: MODULES.BLOGS,
        exact: true,
        showOnSideBar: true,
        component: () => <BlogManagement />,
      },
      {
        path: "/cms/blog-management/add-blog",
        displayName: "Add Blog",
        moduleName: MODULES.BLOGS,
        exact: true,
        showOnSideBar: false,
        component: () => <AddBlog />,
      },
      {
        path: "/cms/blog-management/edit-blog/:updateId",
        displayName: "Edit Blog",
        moduleName: MODULES.BLOGS,
        exact: true,
        showOnSideBar: false,
        component: () => <AddBlog />,
      },
      {
        path: "/cms/blog-category-management",
        displayName: "Blogs Category",
        moduleName: MODULES.BLOGS_CATEGORY,
        exact: true,
        showOnSideBar: true,
        component: () => <BlogCategoryManagement />,
      },
    ],
  },
  {
    key: "settings",
    displayName: "Settings",
    children: [
      {
        path: "/settings/store-setting",
        displayName: "Store Setting",
        moduleName: MODULES.CONFIG_STORE_SETTING,
        exact: true,
        showOnSideBar: true,
        component: () => <StoreSetting />,
      },
      {
        path: "/settings/time-clock",
        displayName: "Time Clock",
        moduleName: MODULES.TIME_CLOCK_CONFIGURATION,
        exact: true,
        showOnSideBar: true,
        component: () => <TimeClockSettings />,
      },
      {
        path: "/settings/holidays-cutoff",
        displayName: "Holidays Cutoff",
        moduleName: MODULES.HOLIDAYS_CUTOFF,
        exact: true,
        showOnSideBar: true,
        component: () => <HolidaysCutoff />,
      },
      {
        path: "/settings/blockout-dates",
        displayName: "Blockout Dates",
        moduleName: MODULES.BLOCKOUT_DATES,
        exact: true,
        showOnSideBar: true,
        component: () => <BlockoutDates />,
      },
      {
        path: "/settings/designer-settings",
        displayName: "Designer Settings",
        moduleName: MODULES.CONFIG_DESIGNER_SETTING,
        exact: true,
        showOnSideBar: true,
        component: () => <DesignerSetting />,
      },
      {
        path: "/settings/petal-rewards-config",
        displayName: "Petal Rewards Config",
        moduleName: MODULES.PETAL_REWARDS_CONFIG,
        exact: true,
        showOnSideBar: true,
        component: () => <PetalRewardsConfig />,
      },
      {
        path: "/settings/recipe-setting",
        displayName: "Recipe Setting",
        moduleName: MODULES.RECIPE_SETTINGS,
        exact: true,
        showOnSideBar: true,
        component: () => <RecipeSetting />,
      },
      {
        path: "/settings/blockout/add",
        displayName: "Blockout Add",
        moduleName: MODULES.BLOCKOUT_DATES,
        exact: true,
        showOnSideBar: false,
        component: () => <AddBlockout />,
      },
      {
        path: "/settings/holidays-cutoff/add",
        displayName: "",
        moduleName: MODULES.HOLIDAYS_CUTOFF,
        exact: true,
        showOnSideBar: false,
        component: () => <HolidaysCutoffAdd />,
      },
    ],
  },
  
];

export function getModuleNameFromURL(path) {
  let module;
  routes.forEach((route) => {
    route.children.forEach((child) => {
      if (child.path === path) module = child.moduleName;
    });
  });

  return module;
}

export function getAccessByModuleName(modules, moduleName) {
  return modules.find((module) => module.name === moduleName);
}

export default routes;
