// import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
// import moment from "moment";
import { withRouter } from "react-router";

import EditIcon from "../../../Assets/images/edit.svg";
import ActivatedIcon from "./../../../Assets/images/activated.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";

import ReactTable from "../../../Components/Table/Table";

// Context
// import { useHistory } from "react-router-dom";
import { getAccessByModuleName } from "../../../Routes/Routes";
import { AppContext } from "../../../Context/AppContext";
import { MODULES } from "../../../Helpers/Constants";
import { useCoupon } from "../../../Context/CouponCurd/useCoupon";

// Services
// import CouponsApi from "../../../Services/Coupons/Coupons";
// import UtilsApi from "../../../Services/Utils/Utils";
import { getCouponList, deleteCoupon } from "api/coupons";
import { updateStatus } from "api/utils";

// Components
import Header from "./../../../Components/Header/Header";
import StatusConfirmation from "../../../Components/Modal/StatusConfirmation/StatusConfirmation";
import DeleteConfirmation from "./../../../Components/Modal/DeleteConfirmation/DeleteConfirmation";

//scss
import styles from "./index.module.scss";

//helper
import { STATUS_FILTER } from "../../../Helpers/Constants";
import { convertUtcTimestampToPstDate } from "Hooks/commanUtility";

const Coupons = (props) => {
  // const history = useHistory();
  const { showToastMessage, modules, setIsLoading } = useContext(AppContext);
  // Add / Edit
  const [access, setAccess] = useState({});

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [statusCouponId, setStatusCouponId] = useState(false);
  const [statusOfCoupon, setStatusOfCoupon] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCouponId, setDeleteCouponId] = useState();

  const { clearCouponState } = useCoupon();

  const [defaultFilter, setDefaultFilter] = useState({
    currentPage: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });
  // const couponsService = new CouponsApi();
  // const utilsService = new UtilsApi();

  const statusList = STATUS_FILTER;

  const fetchData = async ({
    currentPage,
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({
      currentPage,
      pageSize,
      pageIndex,
      filters,
      globalFilter,
      sortBy,
    });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      sortBy: sortBy,
      globalSearch: globalFilter,
      filters: filters,
      product_name: "",
    };

    let couponAccess = getAccessByModuleName(modules, MODULES.COUPON);
    if (couponAccess && couponAccess.permissions.read_access) {
      // const response = await couponsService.getCouponList(postData);
      const response = await getCouponList(postData);
      // console.log(response);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }
    setLoading(false);
  };

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);
  const handleManageCouponClick = async (event, type = "add", id) => {
    event.preventDefault();
    await clearCouponState();
    if (type === "add") {
      props.history.push(`/product-catalog/discount-coupons/add`);
    } else {
      props.history.push(`/product-catalog/discount-coupons/edit-coupon/${id}`);
    }
  };

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.COUPON);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  const handleStatusCouponClick = async (event, id, status) => {
    event.preventDefault();

    setStatusCouponId(id);
    setStatusOfCoupon(status);
    setShowStatusModal(true);
  };

  const handleCouponStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfCoupon === 1 ? 0 : 1,
      id: statusCouponId,
      table: "coupons",
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setStatusCouponId(null);
      setStatusOfCoupon(null);
      setShowStatusModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  const handelDeleteClick = async (event, id) => {
    event.preventDefault();

    setDeleteCouponId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteCoupon = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // const response = await couponsService.deleteCoupon(deleteCouponId);
    const response = await deleteCoupon(deleteCouponId);
    if (response && response.status === 200) {
      setDeleteCouponId(null);
      setShowDeleteModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  let couponAccess = getAccessByModuleName(modules, MODULES.COUPON);
  const columns = React.useMemo(
    () => [
      {
        Header: "Coupon Name",
        accessor: "coupon_code",
        isVisible: true,
        placeholder: "Search By Coupons Name",
      },
      {
        Header: "Coupons Type",
        // accessor: "coupon_type",
        isVisible: true,
        placeholder: "Search By Coupons Type",
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.status}>
              {row.original.coupon_type === "Percentage" ? (
                <h4>{row.original.coupon_value}%</h4>
              ) : row.original.coupon_type === "Fixed Amount" ? (
                <h4>${row.original.coupon_value}</h4>
              ) : (
                <h4>{row.original.coupon_type}</h4>
              )}
            </div>
          );
        },
      },
      {
        Header: "Coupon Apply To",
        accessor: "coupon_apply_to",
        isVisible: true,
        placeholder: "Coupon Apply To",
      },
      {
        Header: "Used",
        // accessor: "usage_limit_number_count",
        isVisible: true,
        placeholder: "Search By Used",
      },
      // {
      //   Header: "Most Used",
      //   accessor: "usage_limit_number_of_time",
      //   isVisible: false,
      //   placeholder: "Most Used",
      // },
      {
        Header: "Status",
        accessor: "status",
        isVisible: true,
        placeholder: "Status",
        isSelectFilter: true,
        selectFilterOption: statusList,
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.status}>
              {row.original.status === 1 ? (
                <div className={styles.Active}>Active</div>
              ) : (
                <div className={styles.Inactive}>Inactive </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Published Date",
        accessor: "active_start_date",
        isVisible: true,
        placeholder: "Published Date",
        // Cell: ({ value, row, column }) => {
        //   return `${
        //     row.original.active_start_date &&
        //     row.original.active_start_date !== null
        //       ? moment
        //           .unix(row.original.active_start_date)
        //           // .tz("America/Los_Angeles")
        //           .format("ddd LL")
        //       : ""
        //   }`;
        // },
        Cell: ({ value }) =>
          convertUtcTimestampToPstDate(value, "ccc LLL dd, yyyy"),
      },
      {
        Header: "Last Created",
        accessor: "createdAt",
        isVisible: false,
        placeholder: "Last Created",
        Cell: ({ value, row, column }) => {
          // return `${
          //   row.original.createdAt && row.original.createdAt !== null
          //     ? DateTime.fromISO(row.original.createdAt)
          //         // .setZone("America/Los_Angeles")
          //         .toFormat("ccc LLL dd, yyyy")
          //     : ""
          // }`;
          return `${
            row.original.createdAt && row.original.createdAt !== null
              ? convertUtcTimestampToPstDate(value, "ccc LLL dd, yyyy")
              : ""
          }`;
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {couponAccess && couponAccess.permissions.write_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={ActivatedIcon}
                  alt="Active"
                  onClick={(e) =>
                    handleStatusCouponClick(e, value, row.original.status)
                  }
                />
              )}
              {couponAccess && couponAccess.permissions.delete_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={DeleteIcon}
                  alt="Delete"
                  onClick={(e) => handelDeleteClick(e, value)}
                />
              )}
              {couponAccess && couponAccess.permissions.write_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={EditIcon}
                  alt="Edit"
                  onClick={(e) => handleManageCouponClick(e, "edit", value)}
                />
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Header
        title="Coupons List"
        hasAddButton={access.write_access}
        handleAddClick={handleManageCouponClick}
      />
      <div id="MainContent" className="main-content">
        <ReactTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
          displayColumnVisibility={true}
        />
      </div>

      <DeleteConfirmation
        showModal={showDeleteModal}
        title="Delete Coupon"
        message="Are you sure want to delete this coupon?"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteModal(false);
          setDeleteCouponId(null);
        }}
        handleSubmitClick={handleDeleteCoupon}
      />

      <StatusConfirmation
        showModal={showStatusModal}
        title={"Coupon"}
        message={"coupon?"}
        buttonLabel={statusOfCoupon ? "Inactivate" : "Activate"}
        statusFlag={statusOfCoupon}
        customFlag={false}
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowStatusModal(false);
          setStatusCouponId(null);
          setStatusOfCoupon(null);
        }}
        handleSubmitClick={handleCouponStatus}
      />
    </React.Fragment>
  );
};

export default withRouter(Coupons);
