// Packages
import React, { useContext } from 'react';
import moment from 'moment';
import 'moment-timezone';

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// SCSS
import styles from './ChangeRequest.module.scss';

// Component
import Date from "./../../../FormControl/Date/Date";

// Context
import { AppContext } from "./../../../../Context/AppContext";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EditChangeRequest = ({ showModal, handleCancelClick, handleSubmitClick, clockDetails, reason, setReason, onChangeValue, submitStatus }) => {
    const { timezone } = useContext(AppContext);

    return (
        <React.Fragment>
            <Dialog
                open={showModal}
                TransitionComponent={Transition}
                onClose={handleCancelClick}
                classes={{
                    paper: styles.modalDialog
                }}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogContent
                    className={`${styles.modalContent} px-0 py-0`}
                >
                    <div className={`text-center ${styles.modalBody}`}>
                        <div className="d-flex justify-content-between mt-3 mb-3">
                            <h5 className={`mt-0 mb-3 text-center ${styles.modalTitle}`}>{clockDetails && clockDetails.user && clockDetails.user.first_name + ' ' + clockDetails.user.last_name}</h5>
                            <span className="current-date">
                                {clockDetails && clockDetails.date}
                            </span>
                        </div>

                        <div className="mb-2">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="text-left">Clock In</th>
                                        <th className="text-left">Clock Out</th>
                                        <th className="text-center">Regular Hours</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        clockDetails.clock_time && clockDetails.clock_time.map((timeClock, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="py-3">
                                                        {
                                                            timeClock.clock_in && timezone &&
                                                            <Date
                                                                parentClass="pt-0 mb-1"
                                                                className="change-time-model"
                                                                value={moment.unix(timeClock.clock_in).tz(timezone).format('hh:mm a')}
                                                                type="time"
                                                                onChange={(time) => onChangeValue({ id: timeClock.id, time, type: 'in' })}
                                                            />
                                                        }
                                                    </td>
                                                    <td className="py-3">
                                                        {
                                                            timeClock.clock_out && timezone &&
                                                            <Date
                                                                parentClass="pt-0 mb-1"
                                                                className="change-time-model"
                                                                value={moment.unix(timeClock.clock_out).tz(timezone).format('hh:mm a')}
                                                                type="time"
                                                                onChange={(time) => onChangeValue({ id: timeClock.id, time, type: 'out' })}
                                                            />
                                                        }
                                                    </td>
                                                    <td className="py-3 text-center">{timeClock.duration ? timeClock.duration : '-'}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="totalHrs mb-4 text-left">
                            Total Hours: <span>{clockDetails.duration}</span>
                        </div>

                        <a href="/" data-dismiss="modal" onClick={handleSubmitClick} className="btn gray-btn d-inline-block mb-3 mr-2"> Save </a>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment >
    );
}

export default EditChangeRequest;
