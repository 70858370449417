import React, { useEffect } from "react";
import card from "Assets/images/card.jpg";
import TextArea from "Components/FormControl/TextArea/TextArea";
import { Dialog, TextField } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useOrderAttributeProvider } from "../context/order";
import { useState } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  card: {
    "& textarea": {
      textAlign: "center",
    },
  },
});

const GreetingCard = () => {
  const classes = useStyles();
  const formik = useFormikContext();
  const { active } = useOrderAttributeProvider();
  const [show, setShow] = useState(false);

  const [iframe, setIframe] = useState("");

  useEffect(() => {
    if (
      formik.values.recipients[active].video_url &&
      formik.values.recipients[active].video_url.includes("vimeo")
    ) {
      fetch(
        `https://vimeo.com/api/oembed.json?url=${formik.values.recipients[active].video_url}`
      )
        .then((d) => d.json())
        .then((r) => {
          setIframe(r.html);
        });
    }
  }, [active, formik.values.recipients]);

  return (
    <div className="my-5">
      {formik.values.recipients[active].video_url && (
        <a
          className="d-inline-block mb-3"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setShow(true);
          }}
        >
          {"Show video message"}
        </a>
      )}
      <Dialog open={show} onClick={() => setShow(false)}>
        {formik.values.recipients[active].video_url && !iframe && (
          <video
            controls
            src={formik.values.recipients[active].video_url}
          ></video>
        )}
        {iframe && <div dangerouslySetInnerHTML={{ __html: iframe }}></div>}
      </Dialog>
      <div
        className={classes.card}
        style={{ height: 400, position: "relative", width: "max-content" }}
      >
        <img style={{ height: "100%" }} src={card} alt="Card" />
        <div
          style={{
            position: "absolute",
            top: "30%",
            overflowWrap: "break-word",
            height: "230px",
            width: "48%",
            textAlign: "center",
            left: "51.5%",
            transform: "translateX(-50%)",
            padding: "0 10px",
          }}
        >
          {formik.values.recipients[active].greetings_on_card}
        </div>
        <span
          style={{
            fontWeight: "bold",
            position: "absolute",
            bottom: "17%",
            height: "50px",
            padding: "0 1rem",
            display: "inline-block",
            textAlign: "center",
            whiteSpace: "pre-line",
            wordBreak: "break-all",
            width: "48%",
            lineHeight: 1,
            left: "51.5%",
            transform: "translateX(-50%)",
          }}
        >
          {formik.values?.recipients[active]?.card_signature}
        </span>
        <p className="text-right my-1">
          <span>
            {250 - formik.values?.recipients[active]?.greetings_on_card?.length}{" "}
            / 250 Characters Left
          </span>
        </p>
        <TextArea
          label="Write your greeting message here..."
          inputStyle={{ textAlign: "left" }}
          value={formik.values?.recipients[active]?.greetings_on_card}
          onChange={(e) => {
            if (e.length <= 250) {
              let recipients = [...formik.values?.recipients];
              recipients[active].greetings_on_card = e;
              formik.setValues((v) => {
                return {
                  ...v,
                  recipients,
                };
              });
            }
          }}
        />
        <TextField
          value={formik.values?.recipients[active]?.card_signature}
          onChange={(e) => {
            if (e.target.value.length < 60) {
              let newString = e.target.value;
              // let newString = removeEmojiFromString(e.target.value);

              let recipients = [...formik.values?.recipients];
              recipients[active].card_signature = newString;
              formik.setValues((v) => {
                return {
                  ...v,
                  recipients,
                };
              });
            } else {
              return;
            }
          }}
          className="w-100"
          label="Card Signature"
        />
      </div>
    </div>
  );
};

export default GreetingCard;
