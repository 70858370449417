import { callPostApi, callDeleteApi, callPutApi, callGetApi } from "./api";

export async function getFaqList(data) {
      //     return this.interceptors.POST("faq", data);
      try {
            const response = await callPostApi({ url: "faq", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getFaqDetail(faqId) {
      // return this.interceptors.GET("faq/" + faqId);
      try {
            const response = await callGetApi({ url: "faq/" + faqId });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function createFaq(postData) {
      // return this.interceptors.POST("faq/add", postData);
      try {
            const response = await callPostApi({ url: "faq/add", body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateFaq(postData, faqId) {
      // return this.interceptors.PUT("faq/" + faqId, postData);
      try {
            const response = await callPutApi({ url: "faq/" + faqId, body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function deleteFaq(id) {
      // return this.interceptors.DELETE("faq/" + id);
      try {
            const response = await callDeleteApi({ url: "faq/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}

