import React, { useState, useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// SCSS
import styles from "./index.module.scss";
// Component
import AutocompleteInput from "../../FormControl/Autocomplete/Autocomplete";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddChangeRequest = (props) => {
  const [similarBlogId, setSimilarBlogId] = useState("");

  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          console.log("hii23");
        }}
        className={`row`}
      >
        <Dialog
          open={props.showModal}
          TransitionComponent={Transition}
          onClose={() => {
            props.handleCancelClick();
            setSimilarBlogId("");
          }}
          classes={{
            paper: styles.modalDialog,
          }}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogContent className={`${styles.modalContent} px-0 py-0`}>
            <div className={`text-center ${styles.modalBody}`}>
              <h5 className={`my-3 text-center ${styles.modalTitle}`}>
                {props.pageTitle}
              </h5>

              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <AutocompleteInput
                    label="Select Similar Blogs"
                    required={true}
                    submitStatus={props.submitStatus}
                    onChangeParent={(data) => {
                      if (data.id) {
                        // props.handleSetSelectedCategoryData(data.id);
                        setSimilarBlogId(data.id);
                      }
                    }}
                    onChangeParentManual={(data) => {}}
                    disabled={false}
                    listData={props.allBlogList}
                    id="categorySearching"
                  />
                  {props.duplicateBlog === 1 ? (
                    <p className={styles.paragraphBackground}>
                      {props.duplicateBlogMessage}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <button
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  props.handleSubmitClick(similarBlogId);
                  setSimilarBlogId('');
                }}
                className="btn gray-btn d-inline-block mb-3 mr-2"
              >
                Save
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </form>
    </React.Fragment>
  );
};

export default AddChangeRequest;
