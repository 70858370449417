import React, { useState } from "react";
import moment from "moment";
import { withRouter } from "react-router";
import { getAccessByModuleName } from "./../../Routes/Routes";
import Header from "../../Components/Header/Header";
import ReactTable from "../../Components/Table/Table";
import { MODULES, STATUS_FILTER } from "./../../Helpers/Constants";
import { AppContext } from "../../Context/AppContext";
// import { useHistory } from "react-router-dom";
//icon
import EditIcon from "./../../Assets/images/edit.svg";
import DeleteIcon from "./../../Assets/images/delete.svg";
import ActivatedIcon from "./../../Assets/images/activated.svg";
//css
import styles from "./index.module.scss";
//services
// import DeliveryMatrixApi from "./../../Services/DeliveryMatrix/DeliveryMatrix";
// import UtilsApi from "./../../Services/Utils/Utils";
import { updateStatus } from "./../../api/utils";
import {
  getDeliveryMatrixList,
  deleteDeliveryMatrixList,
} from "./../../api/deliveryMatrix";
//component
import DeleteConfirmation from "./../../Components/Modal/DeleteConfirmation/DeleteConfirmation";
import StatusConfirmation from "./../../Components/Modal/StatusConfirmation/StatusConfirmation";

import { useDeliveryMatrix } from "./../../Context/DeliveryMatrixCurd/useDeliveryMatrix";

const DeliveryMatrix = (props) => {
  // const history = useHistory();
  const { showToastMessage, modules, setIsLoading } = React.useContext(
    AppContext
  );
  const [data, setData] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [access, setAccess] = React.useState({});

  const [statusDeliveryId, setStatusDeliveryId] = useState(false);
  const [statusOfDelivery, setStatusOfDelivery] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [deleteDeliveryId, setDeleteDeliveryId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { clearDeliveryState } = useDeliveryMatrix();

  const [defaultFilter, setDefaultFilter] = React.useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });
  const statusList = STATUS_FILTER;
  // const deliveryMatrixService = new DeliveryMatrixApi();
  // const utilsService = new UtilsApi();
  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
    };
    // const response = await deliveryMatrixService.getDeliveryMatrixList(
    //   postData
    // );
    const response = await getDeliveryMatrixList(postData);
    // console.log(response);
    if (response && response.status === 200) {
      setData(response.data.data.rows);
      setPageCount(Math.ceil(response.data.data.count / pageSize));
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);
  };

  React.useEffect(getModuleAccess, [modules]);
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.DELIVERY_MATRIX);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  const handleStatusDeliveryClick = async (event, id, status) => {
    event.preventDefault();

    setStatusDeliveryId(id);
    setStatusOfDelivery(status);
    setShowStatusModal(true);
  };

  const handleDeliveryStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfDelivery === 1 ? 0 : 1,
      id: statusDeliveryId,
      table: "delivery_matrix",
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setShowStatusModal(false);
      setStatusDeliveryId(null);
      setStatusOfDelivery(null);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  const handleDeleteDeliveryClick = async (event, id) => {
    event.preventDefault();

    setDeleteDeliveryId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteDelivery = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // const response = await deliveryMatrixService.deleteDeliveryMatrixList(
    //   deleteDeliveryId
    // );
    const response = await deleteDeliveryMatrixList(deleteDeliveryId);
    if (response && response.status === 200) {
      setDeleteDeliveryId(null);
      setShowDeleteModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  const handleDeliveryMatrixAdd = (event, type = "add", id) => {
    event.preventDefault();
    // history.push("/delivery-matrix/add");
    clearDeliveryState();
    if (type === "add") {
      props.history.push(`/delivery-matrix/add`);
    } else {
      props.history.push(`/delivery-matrix/edit/${id}`);
    }
  };

  let deliveryMatrixAccess = getAccessByModuleName(
    modules,
    MODULES.DELIVERY_MATRIX
  );
  const columns = React.useMemo(
    () => [
      {
        Header: "Delivery Matrix",
        accessor: "name",
        isVisible: true,
      },
      {
        Header: "Details",
        accessor: "details",
        isVisible: true,
        disableFilters: true,
        // Cell: ({ value }) => moment(value).utc().format("ddd MMM DD, YYYY"),
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {row.original.start_date &&
              row.original.start_date !== null &&
              row.original.start_date !== "" &&
              row.original.start_date !== "0000-00-00"
                ? moment(row.original.start_date).format("ddd MMM DD, YYYY") +
                  " - " +
                  moment(row.original.end_date).format("ddd MMM DD, YYYY")
                : ""}
            </div>
          );
        },
      },
      {
        Header: "Business Type",
        accessor: "businessType",
        isVisible: true,
        disableFilters: true,
        Cell: ({ value, row, column }) => {
          let data = "";
          // {
            row.original.delivery_matrix_address_types.map((d, i) => {
              data += d.address_type.name + ", ";
            });
          // }
          return data;
        },
      },
      // {
      //   Header: "Zip Code",
      //   accessor: "local",
      //   isVisible: true,
      //   disableFilters: true,
      // },
      {
        Header: "Status",
        accessor: "status",
        isVisible: true,
        placeholder: "Status",
        isSelectFilter: true,
        selectFilterOption: statusList,
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.status}>
              {row.original.status === 1 ? (
                <div className={styles.Active}>Active</div>
              ) : (
                <div className={styles.Inactive}>Inactive </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {deliveryMatrixAccess &&
                deliveryMatrixAccess.permissions.write_access && (
                  <img
                    className={`cursor-pointer ml-3`}
                    src={EditIcon}
                    alt="Edit"
                    onClick={(e) => handleDeliveryMatrixAdd(e, "edit", value)}
                  />
                )}
              {deliveryMatrixAccess &&
                deliveryMatrixAccess.permissions.write_access && (
                  <img
                    className={`cursor-pointer ml-3`}
                    src={ActivatedIcon}
                    alt=""
                    onClick={(e) =>
                      handleStatusDeliveryClick(e, value, row.original.status)
                    }
                  />
                )}
              {deliveryMatrixAccess &&
                deliveryMatrixAccess.permissions.delete_access && (
                  <img
                    className={`cursor-pointer ml-3`}
                    src={DeleteIcon}
                    alt="Delete"
                    onClick={(e) => handleDeleteDeliveryClick(e, value)}
                  />
                )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div>
        <Header
          title="Delivery Matrix List"
          hasAddButton={access.write_access}
          handleAddClick={handleDeliveryMatrixAdd}
        />
        <div>
          <ReactTable
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            noDataText={"There is no data to display"}
            defaultFilter={defaultFilter}
            displayGlobalSearch={false}
            displayColumnVisibility={false}
          />
        </div>
      </div>

      <DeleteConfirmation
        showModal={showDeleteModal}
        title="Delete Delivery Matrix"
        message="Are you sure want to delete this delivery matrix?"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteModal(false);
          setDeleteDeliveryId(null);
        }}
        handleSubmitClick={handleDeleteDelivery}
      />

      <StatusConfirmation
        showModal={showStatusModal}
        title={"Delivery Matrix"}
        message={"delivery matrix?"}
        buttonLabel={statusOfDelivery ? "Inactivate" : "Activate"}
        statusFlag={statusOfDelivery}
        customFlag={false}
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowStatusModal(false);
          setStatusDeliveryId(null);
          setStatusOfDelivery(null);
        }}
        handleSubmitClick={handleDeliveryStatus}
      />
    </React.Fragment>
  );
};
export default withRouter(DeliveryMatrix);
