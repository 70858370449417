import { DateTime } from "luxon";
import moment from "moment";
import { UTC_ZONE, PST_ZONE } from "../Pages/Order/Order/utility/util";

export function isBeforetwelveandsix(delivery_date_time) {
  let time = DateTime.fromISO(delivery_date_time, { zone: UTC_ZONE })
    .setZone(PST_ZONE)
    .toFormat("hh:mm a");
  // console.log(time);
  let beforTwelve = moment(time, "hh:mm a").isBefore(
    moment("02:00 PM", "hh:mm a")
  );
  let beforSix = moment(time, "hh:mm a").isBefore(
    moment("05:00 PM", "hh:mm a")
  );
  return { beforSix, beforTwelve };
}
