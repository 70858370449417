import React, { useState, useEffect, useContext } from "react";
import { DateTime } from "luxon";
// Scss
import styles from "./index.module.scss";

// Context
import { AppContext } from "./../../../Context/AppContext";
import { getAccessByModuleName } from "./../../../Routes/Routes";
import { MODULES } from "./../../../Helpers/Constants";

// Components
import Header from "./../../../Components/Header/Header";
import ReactTable from "../../../Components/Table/Table";
import DeleteConfirmation from "Components/Modal/DeleteConfirmation/DeleteConfirmation";

import hidden from "../../../Assets/images/default.svg";
import update from "../../../Assets/images/update.svg";

// Services
import {
  getInventoryProducts,
  updateInventoryQty,
  updateInventoryReorder,
  clearAllInventoryProduct
} from "api/inventoryProduct";

import Tab from '../../../Components/InventoryManagement/Tabs'
import TabPane from "../../../Components/InventoryManagement/Tabpane";
import RecipeSettingsApi from "../../../Services/RecipeSettings/RecipeSettings";
import { Input } from "@material-ui/core";


const Inventory = (props) => {
  const { showToastMessage, modules, setIsLoading } = useContext(AppContext);
  const [access, setAccess] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState({
    isDelete: false,
    data: null,
  });
  const [recipeType,setRecipeType] = useState([])
  const [checkInitState, setInitState] = useState(false);
  const [active, setActive] = useState("");
  const [activeID, setActiveID] = useState("");
  const [currenttTab,setCurrentTab] = useState("")
  const [resetPagination,setResetPagination] = useState(false)
  const [firtsTabData, setFirstTabData] = useState(true)


  const recipeSettingsService = new RecipeSettingsApi();
  
  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [
      {
        id: "id",
      },
    ],
    filters: [],
    globalFilter: "",
  });

  useEffect(getModuleAccess, [modules]);
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.INVENTORY_PRODUCTS);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/users");
    }
  }


  //call list type API
  const listType = async ()=>{
    const postData = {
      currentPage: 0,
      itemsPerPage: 200
    };
    try {
      const type = await recipeSettingsService.recipeTypeList(postData);
      if (type && type.status === 200) {
        return type.data.data.rows
      } else {
        showToastMessage(undefined, type.data.message, "error", false);
      return false
      }
    } catch (error) {
      showToastMessage(undefined, error.message, "error", false);
      return false
    }
  }  

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    list_type
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    let firstTab = ""
    if(firtsTabData){
      const type = await listType()
      firstTab = type[0].title;
      setCurrentTab(type[0].title)
    }
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      list_type : list_type ? list_type : (currenttTab ? currenttTab : firstTab)
    };
    let productAccess = getAccessByModuleName(
      modules,
      MODULES.INVENTORY_PRODUCTS
    );
    if (productAccess && productAccess.permissions.read_access) {
      // const response = await productService.getProducts(postData);
      const response = await getInventoryProducts(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setResetPagination(false)
        setFirstTabData(false)
        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else {
        setResetPagination(false)
        setFirstTabData(false)
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }
    setLoading(false);
  };

  //change tab and display data accordingly
  const changeTab = async (name) => {
    setCurrentTab(name)
    setActive(name);
    setActiveID(recipeType.find(r => r.title === name).id);
    setInitState(false);
    setResetPagination(true)
    const postData = {
      ...defaultFilter,
      currentPage: 0,
      pageIndex: 0,
      pageSize: 10,
      list_type : name,
    };
    await fetchData(postData);
  };

  useEffect(async () => {
    const type = await listType()
    if (type) {
      setInitState(true);
      setRecipeType(type);
    } else {
      setInitState(false);
    }
  }, []);

  const EditableCell = (props)=>{
    const {row:{index,...otherinfo}, 
    column: { id },updateMyData,
    value:initialValue,
    ...other} = props;
    
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);
    const [isEdit, setEdit] = useState(false);

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    
    const makeEditableHandler = () => {
      setEdit(true);
    };

    const onChange = (e) => {
      setValue(e.target.value);
    };
  
    const onClickSaveHandler = () => {
      if (initialValue != value) {
        updateMyData(index, id, value, other);
      }
      if (value === "" || value == null) {
        setValue(initialValue);
        showToastMessage(
          undefined,
          `Inverntry value can't be null`,
          "error",
          false
        );
      }
      setEdit(false);
    };

    return (
      <div className="d-flex justify-content-start align-items-center">
        <div>
          <div className="d-flex align-items-center">
            <Input
              disableUnderline={true}
              type="number"
              value={value ?? initialValue}
              onChange={onChange}
              onClick={makeEditableHandler}
              style={{paddingTop:10}}
            />
            <span>
              {isEdit && (
                <img
                  style={{ cursor: "pointer", width: 24, height: 24 }}
                  src={update}
                  alt="action"
                  onClick={onClickSaveHandler}
                />
              )}
              {!isEdit && (
                <img
                  src={hidden}
                  alt="action"
                  style={{ width: 24, height: 24 }}
                />
              )}
            </span>
          </div>
          <hr style={{ marginTop: "2px" }} />
        </div>
      </div>
    );

  }

  //update particular price 
  const updateMyData = async (rowIndex, columnId, value, other = {}) => {
    const data = other.data.find((elem, idx) => idx == rowIndex);

    const postData = {
      id: data.id,
      value: value,
    };
      if(columnId === "quantity"){
        await updateQty(data.id,{quantity:value})
      }else if(columnId === "re_order"){
        await updateReorder(data.id,{re_order:value})
      }
      setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
    // }else{
    //   showToastMessage(undefined, "You're not authorized to update price", "error", false);
    // }
  };

  const updateQty = async (id, data) => {
    try {
      setIsLoading(true);
      if(access.write_access){
        const response = await updateInventoryQty(id,data)
        if(response && response.status === 200){
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "success", false);
        }else{
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "error", false);
        }
      }else{
        setIsLoading(false);
        showToastMessage(undefined, "You are not authorized to access", "error", false);
      }
    } catch (error) {
      setIsLoading(false);
      showToastMessage(
        undefined,
        error.response.data.message,
        "error",
        false
      );
    }
  };

  const updateReorder = async (id, data) => {
    try {
      setIsLoading(true);
      if(access.write_access){
        const response = await updateInventoryReorder(id,data)
        if(response && response.status === 200){
          showToastMessage(undefined, response.data.message, "success", false);
          setIsLoading(false);
        }else{
          showToastMessage(undefined, response.data.message, "error", false);
          setIsLoading(false);
        }
      }else{
        showToastMessage(undefined, "You are not authorized to access", "error", false);
        setIsLoading(false);
      }
    } catch (error) {
      showToastMessage(
        undefined,
        error.response.data.data.message,
        "error",
        false
      );
      setIsLoading(false);
    }
  };

  const handleClearAllInventory = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      if(access.write_access){
        const response = await clearAllInventoryProduct({product_recipe_types_id: showDeleteModal.data});
        if (response && response.status === 200) {
          setIsLoading(false);
          setShowDeleteModal({ isDelete: false, data: null });
          showToastMessage(undefined, response.data.message, "success", false);
          fetchData(defaultFilter);
        } else {
          setIsLoading(false);
          setShowDeleteModal({ isDelete: false, data: null });
          showToastMessage(undefined, response.data.message, "error", false);
        }
      } else {
        setIsLoading(false);
        setShowDeleteModal({ isDelete: false, data: null });
        showToastMessage(undefined, "You are not authorized to access", "error", false);
      }
    } catch (error) {
      setIsLoading(false);
      setShowDeleteModal({ isDelete: false, data: null });
      showToastMessage(
        undefined,
        error.response.data.message,
        "error",
        false
      );
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Item",
        accessor: "image",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.ImageWapper}>
              <div>
                {row?.original?.product_recipe_raw_material?.image !== "" && 
                row?.original?.product_recipe_raw_material?.image !== null &&
                row?.original?.product_recipe_raw_material?.image !== undefined ? (
                  <img src={`${row?.original?.product_recipe_raw_material?.image}?w=70&q=75&auto=compress,format`} 
                  alt={row?.original?.product_recipe_raw_material?.title} />
                ) : (
                  <div className={styles.emptyImage}>
                    <span>No Image</span>
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Raw Material Name",
        accessor: "product_recipe_raw_material",
        isVisible: true,
        disableSortBy:true,
        placeholder: "Search By Raw Material Name",
        isCustomFilter:true,
        isCustomFilterLength:2,
        Cell: ({ value, row, column }) => {
          return (
            <span>{row?.original?.product_recipe_raw_material?.title}</span>
          );
        },
      },
      {
        Header: "Qty",
        accessor: "quantity",
        isVisible: true,
        disableFilters:true,
        // disableSortBy:true,
        Cell:EditableCell
      },
      {
        Header: "Re-Order",
        accessor: "re_order",
        isVisible: true,
        disableFilters:true,
        // disableSortBy:true,
        Cell:EditableCell
      },
      {
        Header: "Unit",
        accessor: "unit",
        isVisible: true,
        disableFilters:true,
        disableSortBy:true,
        Cell: ({value,row,column})=>{
          return(
            <span>{row.original?.product_recipe_raw_material?.product_recipe_unit?.title}</span>
          )
        }
      },
      {
        Header: "Inventory Updated By",
        accessor: "inventory_updated_by",
        isVisible: true,
        placeholder:"Search Inventory Updated By",
        disableSortBy:true,
        Cell:({value,row,column})=>{
          return(
            <span>{row.original?.user?.full_name ? row.original?.user?.full_name : "-"}</span>
          )
        }
      },
      {
        Header: "Inventory Updated At",
        accessor: "updatedAt",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <span
              className={"d-flex justify-content-start"}
            >
              {row.original.updatedAt
                ? DateTime.fromISO(row.original.updatedAt)
                    .setZone("America/Los_Angeles")
                    .toFormat("ccc LLL dd, yyyy, hh:mm a")
                : ""}
            </span>
          );
        },
      },
    ],
    [data]
  );

  return (
    <React.Fragment>
      <Header
        title="Inventory"/>
      <div className="d-flex mb-4">
        <Tab
          active={active}
          changeTab={changeTab}
          setActive={setActive}
          checkInit={checkInitState}
          >
          {recipeType.map((type,idx)=>{
            return(
              <TabPane name={type.title} key={type.id}/>
            )
          })}
        </Tab>
        <div>
          <span
            onClick={(e) => {
              e.preventDefault();
              setShowDeleteModal({ isDelete: true, data: activeID ? activeID : recipeType.find(r => r.title === currenttTab).id });
            }}
            style={{
              fontSize: "14px",
              padding: "7px 15px",
            }}
            className="btn gray-btn d-inline-block cursor-pointer"
          >
            Clear All
          </span>
        </div>
      </div>
      <div id="MainContent" className="main-content" />
      <div>
        <ReactTable
          columns={columns}
          data={data}
          expandedRows={true}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
          updateMyData={updateMyData}
          resetPagination={resetPagination}
        />
      </div>

      <DeleteConfirmation
        showModal={showDeleteModal.isDelete}
        title="Clear All Quantity"
        message={`Are you sure want to clear all quantity of ${currenttTab} category?`}
        btn1_title="Clear All"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteModal({ isDelete: false, data: null });
        }}
        handleSubmitClick={handleClearAllInventory}
      />
    </React.Fragment>
  );
};

export default Inventory;