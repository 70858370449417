import React, { useReducer } from "react";
import { AddonInitialState } from "./AddonInitialState";
import { AddonReducer } from "./AddonReducer";
// import { AddonList } from "./AddonData";
// import * as Addon_ACTIONS from "./AddonAction";

export const AddonContext = React.createContext({
  AddonState: AddonInitialState,
  dispatch: () => null,
});

const AddonProvider = (props) => {
  const [state, dispatch] = useReducer(AddonReducer, AddonInitialState);

  //   useEffect(() => {
  //     dispatch({ type: Addon_ACTIONS.SET_ADDON_LIST, payload: AddonList });
  //   }, []);

  return (
    <AddonContext.Provider value={{ AddonState: state, dispatch }}>
      {props.children}
    </AddonContext.Provider>
  );
};

export default AddonProvider;
