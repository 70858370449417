export const SET_MENU_LIST = "SET_MENU_LIST";

export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST";

export const SELECTED_PRODUCT = "SELECTED_PRODUCT";
export const CLEAR_SELECTED_PRODUCT = "CLEAR_SELECTED_PRODUCT";
export const DELETE_PRODUCT_SELECTED = "DELETE_PRODUCT_SELECTED";

export const PRODUCT_ADD = "PRODUCT_ADD";
export const SET_CURRENT_ADD = "SET_CURRENT_ADD";
export const CLEAR_CURRENT_ADD = "CLEAR_CURRENT_ADD";

export const PRODUCT_VARIANT_SELECTED = "PRODUCT_VARIANT_SELECTED";
export const CLR_VARIANT_SELECTED = "CLR_VARIANT_SELECTED";
export const SELECTED_PRODUCT_DATA_CMS = "SELECTED_PRODUCT_DATA_CMS";
