import React, { useState, useEffect, useContext } from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import { AppContext } from "../../../Context/AppContext";
// SCSS
import styles from "./index.module.scss";

import Text from "./../../FormControl/Text/Text";
import Select from "../../FormControl/Select/Select";
import Date from "../../FormControl/Date/Date";
import { DateTime } from "luxon";
import { PST_ZONE, UTC_ZONE } from "Pages/Order/Order/utility/util";
import moment from "moment";
import { convertUtcToPstTime } from "Hooks/commanUtility";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddChangeRequest = (props) => {
  // console.log(props);
  const { showToastMessage } = useContext(AppContext);
  const [deliveryMatrixTime, setDeliveryMatrixTime] = useState({
    delivery_order_type: "",
    delivery_time: 1629856800,
    delivery_time_type: "",
    order_by_time: 1629935940,
    price: 0,
  });

  const orderType = [
    { id: "in_advance", title: "In Advance" },
    { id: "sameDay", title: "Same Day" },
  ];

  const orderDelivery = [
    { id: "Before", title: "Before" },
    { id: "Anytime", title: "AnyTime" },
    { id: "After", title: "After" },
    { id: "By", title: "By" },
  ];

  useEffect(() => {
    if (props.editDataTime) {
      setDeliveryMatrixTime(props.editDataTime);
    }
  }, [props.editDataTime]);

  const handleSaveClickModal = async (event) => {
    if (
      deliveryMatrixTime.delivery_order_type === undefined ||
      deliveryMatrixTime.delivery_order_type === ""
    ) {
      showToastMessage(
        undefined,
        "Please select order in advance / same day",
        "error",
        false
      );
    } else if (
      deliveryMatrixTime.order_by_time === undefined ||
      deliveryMatrixTime.order_by_time === ""
    ) {
      showToastMessage(undefined, "Order By is required", "error", false);
    } else if (
      deliveryMatrixTime.delivery_time_type === undefined ||
      deliveryMatrixTime.delivery_time_type === ""
    ) {
      showToastMessage(undefined, "Delivered is required", "error", false);
    } else if (
      deliveryMatrixTime.delivery_time === undefined ||
      deliveryMatrixTime.delivery_time === ""
    ) {
      showToastMessage(undefined, "Delivery time is required", "error", false);
    } else if (
      deliveryMatrixTime.price === undefined ||
      deliveryMatrixTime.price === ""
    ) {
      showToastMessage(undefined, "Price is required", "error", false);
    } else {
      props.handleSubmitClick(deliveryMatrixTime);
      // setDeliveryMatrixTime(defaultData);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={(e) => {}} className={`row`}>
        <Dialog
          open={props.showModal}
          TransitionComponent={Transition}
          // onClose={handleCancelClick}
          onClose={() => {
            props.handleCancelClick();
            // setDeliveryMatrixTime(defaultData);
          }}
          classes={{
            paper: styles.modalDialog,
          }}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogContent className={`${styles.modalContent} px-0 py-0`}>
            <div className={`text-center ${styles.modalBody}`}>
              <h5 className={`my-3 text-center ${styles.modalTitle}`}>
                {props.setPageTitle}
              </h5>
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <Select
                    value={deliveryMatrixTime.delivery_order_type}
                    onChange={(e) => {
                      let deliveryMatrixTimeData = { ...deliveryMatrixTime };

                      setDeliveryMatrixTime({
                        ...deliveryMatrixTimeData,
                        delivery_order_type: e,
                      });
                    }}
                    label="Order In Advance/Same Day"
                    name="orderInAdvance"
                    options={orderType}
                    disabled={false}
                    id="delivery_order_type"
                  />
                </div>
                <div className="col-md-6 col-xs-12">
                  <Select
                    value={deliveryMatrixTime.delivery_time_type}
                    label="Delivered"
                    onChange={(data) => {
                      let deliveryMatrixTimeData = { ...deliveryMatrixTime };

                      setDeliveryMatrixTime({
                        ...deliveryMatrixTimeData,
                        delivery_time_type: data,
                      });
                    }}
                    options={orderDelivery}
                    id="delivery_time_type"
                  />
                </div>
                <div className="col-md-6 col-xs-12">
                  {deliveryMatrixTime?.order_by_time && (
                    <Date
                      type="time"
                      label="Order By"
                      // value={deliveryMatrixTime.order_by_time}
                      // value={DateTime.fromSeconds(
                      //   deliveryMatrixTime.order_by_time,
                      //   { zone: UTC_ZONE }
                      // )
                      //   .setZone(PST_ZONE)
                      //   .toFormat("hh:mm a")}
                      value={convertUtcToPstTime(
                        deliveryMatrixTime.order_by_time
                      )}
                      onChange={(data) => {
                        let deliveryMatrixTimeData = { ...deliveryMatrixTime };
                        let pst = moment(data).format("hh:mm A");
                        let timestamp = DateTime.fromFormat(pst, "hh:mm a", {
                          zone: PST_ZONE,
                        })
                          .setZone(UTC_ZONE)
                          .toSeconds();
                        setDeliveryMatrixTime({
                          ...deliveryMatrixTimeData,
                          order_by_time: timestamp,
                        });
                      }}
                      id="order_by_time"
                    />
                  )}
                </div>

                <div className="col-md-6 col-xs-12">
                  {deliveryMatrixTime?.delivery_time && (
                    <Date
                      type="time"
                      label="Delivery Time"
                      // value={DateTime.fromSeconds(
                      //   deliveryMatrixTime.delivery_time,
                      //   { zone: UTC_ZONE }
                      // )
                      //   .setZone(PST_ZONE)
                      //   .toFormat("hh:mm a")}
                      value={convertUtcToPstTime(
                        deliveryMatrixTime.delivery_time
                      )}
                      onChange={(data) => {
                        let deliveryMatrixTimeData = { ...deliveryMatrixTime };
                        let pst = moment(data).format("hh:mm A");
                        let timestamp = DateTime.fromFormat(pst, "hh:mm a", {
                          zone: PST_ZONE,
                        })
                          .setZone(UTC_ZONE)
                          .toSeconds();
                        setDeliveryMatrixTime({
                          ...deliveryMatrixTimeData,
                          delivery_time: timestamp,
                        });
                      }}
                      id="delivery_time"
                    />
                  )}
                </div>

                <div className="col-md-6 col-xs-12">
                  <Text
                    type="text"
                    value={deliveryMatrixTime.price.toString()}
                    label="Price"
                    onChange={(e) => {
                      let deliveryMatrixTimeData = { ...deliveryMatrixTime };

                      if (/^[0-9]*(\.[0-9]*)?$/.test(e)) {
                        let price = e;
                        setDeliveryMatrixTime({
                          ...deliveryMatrixTimeData,
                          price: price,
                        });
                      }
                    }}
                    id="price"
                  />
                </div>
              </div>

              <button
                data-dismiss="modal"
                onClick={(event) => {
                  handleSaveClickModal(event);
                }}
                className="btn gray-btn d-inline-block mb-3 mr-2"
              >
                Save
              </button>
              <button
                data-dismiss="modal"
                onClick={() => {
                  props.handleCancelClick();
                  // setDeliveryMatrixTime(defaultData);
                }}
                className="btn gray-btn d-inline-block mb-3 mr-2"
              >
                Cancel
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </form>
    </React.Fragment>
  );
};

export default AddChangeRequest;
