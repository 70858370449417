import { useFormik } from "formik";

export function ProductPrimaryInfoForm(
      submit,
      initialVal,
      cb,
      checkProductPrimaryData
) {

      const validation = (values) => {
      
            let errors = {};
            let productPrimaryError = false;
            // if (!values.status) {
            //       errors.status = "Product Status is required";
            //       productPrimaryError = true;
            // } else {
            //       productPrimaryError = false;
            // }
            if (!values.type || values.type === null) {
                  errors.type = "Product Type required";
                  productPrimaryError = true;
            } else {
                  productPrimaryError = false;
            }
            // if (!values.inventory_remaining) {
            //       errors.inventory_remaining = "Inventory Remaining required";
            //       productPrimaryError = true;
            // } else {
            //       productPrimaryError = false;
            // }
            if (!values.description) {
                  errors.description = "product Description required";
                  productPrimaryError = true;
            } else {
                  productPrimaryError = false;
            }
            if (!values.title) {
                  errors.title = "Product Title is required";
                  productPrimaryError = true;
            } else {
                  productPrimaryError = false;
            }
            // if (!values.sequence_number) {
            //       errors.sequence_number = "Product Sequence number is required";
            //       productPrimaryError = true;
            // } else {
            //       productPrimaryError = false;
            // }           
            
            if (cb) {
                  cb(values);
            }
            checkProductPrimaryData(productPrimaryError);
            return errors;
      };

      const initialValues = {
            type: "",
            inventory_remaining: "",
            title: "",
            sequence_number: "",
            status: "",
            sku_id: "",
            keywords: "",
            customer_note: "",
            lead_time: "",
            non_local_lead_time: "",
            product_images: [],
            description: "",
            care_description: "",
            declaimer: "",
            did_you_know: "",
            after_care: "",
            delivery_info: "",
      };

      const formik = useFormik({
            initialValues: initialVal || initialValues,
            onSubmit: submit,
            enableReinitialize: true,
            validate: validation,
      });

      return { formik };
}