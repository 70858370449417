import { TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { getProducts } from "api/product";
import { uploadImage } from "api/util";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
const Custom = (props) => {
  const [uploading, setUploading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: "",
      qty: 1,
      id: 0,
      selectedVariant: {
        price: 0,
        title: "",
        image: "",
      },
      total_price: 0,
    },
    onSubmit: (values) => {
      let v = { ...values };
      v.total_price = +v.total_price;
      v.selectedVariant.price = +v.selectedVariant.price;
      props.addProduct(v);
    },
    validate: (values) => {
      const errors = {};
      if (!values.title) {
        errors.title = "Please enter product title";
      }
      if (values.title.length > 50) {
        errors.title = "Product title cannot be greater than 50 character";
      }
      if (!values.selectedVariant.title) {
        errors.variationTitle = "Please Enter product variation name";
      }
      if (values.selectedVariant.title.length > 50) {
        errors.title =
          "Product variation title cannot be greater than 50 character";
      }
      if (+values.selectedVariant.price < 1) {
        errors.price = "Please enter price greater than 0";
      }
      if (+values.selectedVariant.price > 100000) {
        errors.price = "Please enter price greater than 100000";
      }

      return errors;
    },
  });

  useEffect(() => {
    (async () => {
      const resp = await getProducts({
        currentPage: 0,
        itemsPerPage: 10,
        sortBy: [],
        filters: [
          {
            id: "url-slugs",
            value: ["custom-category"],
          },
        ],
      });
      formik.setValues((v) => {
        return {
          ...v,
          ...resp.data.data.rows[0],
          selectedVariant: {
            ...resp.data.data.rows[0].product_variations[0],
          },
        };
      });
    })();
  }, []);

  const inputRef = React.useRef();

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <form
        onSubmit={(e) => {
          setShowErrors(true);
          formik.handleSubmit(e);
        }}
        className="row"
        style={{ maxWidth: "600px", padding: "2rem", overflowX: "hidden" }}
      >
        {showErrors && (
          <ul className="text-danger">
            {Object.values(formik.errors).map((c) => (
              <li>{c}</li>
            ))}
          </ul>
        )}
        <div className="col-12 my-3">
          <div
            onClick={() => {
              inputRef.current.click();
            }}
            style={{
              width: "120px",
              height: "120px",
              backgroundColor: "#ccc",
              display: "grid",
              placeItems: "center",
              position: "relative",
              cursor: "pointer",
            }}
          >
            {!formik.values.selectedVariant.image && uploading
              ? "uploading"
              : "Select Image"}
            {formik.values.selectedVariant.image && (
              <img
                src={formik.values.selectedVariant.image}
                alt="Variation"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            )}
          </div>
        </div>
        <TextField
          label="Title"
          className="col-md-6 py-2 px-1"
          id="title"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.title}
          error={formik.values.title.length > 50}
          helperText={(formik.values.title.length > 50)? "Product title cannot be greater than 50 character":""}
        />
        <TextField
          label="Variation name"
          className="col-md-6 py-2 px-1"
          id="selectedVariant.title"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.selectedVariant.title}
          error={formik.values.selectedVariant.title.length > 50}
          helperText={(formik.values.selectedVariant.title.length > 50)? "Product variation title cannot be greater than 50 character":""}
        />
        <TextField
          label="Price"
          type="text"
          className="col-md-6 py-2 px-1"
          id="selectedVariant.price"
          onChange={(e) => {
            if (/^[0-9]*(\.[0-9]*)?$/.test(e.target.value)) {
              let price = e.target.value;
              formik.setValues((v) => {
                return {
                  ...v,
                  selectedVariant: {
                    ...v.selectedVariant,
                    price: price,
                  },
                  total_price: price,
                };
              });
            }
          }}
          onBlur={formik.handleBlur}
          value={formik.values.selectedVariant.price.toString()}
        />
        <input
          ref={inputRef}
          className="sr-only"
          type="file"
          accept="image/png, image/jpeg"
          multiple={false}
          onChange={async (e) => {
            const d = new FormData();
            d.append("image", e.target.files[0]);
            setUploading(true);
            const response = await uploadImage(d);

            formik.setValues((v) => {
              return {
                ...v,
                selectedVariant: {
                  ...v.selectedVariant,
                  image: response.data.data[0].url,
                },
              };
            });

            setUploading(false);
          }}
        />
        <div className="col-12 text-center mt-3">
          <button className="btn pink-btn bold" type="submit">
            Add
          </button>
        </div>
      </form>
    </Dialog>
  );
};

export default Custom;
