import React from "react";
import styles from "./index.module.scss";

const CardInfoSaved = (props) => {
  if (props.isViewOrder) {
    return (
      <div
        className={`position-relative ${styles.cardBlockSaved}`}
        style={{
          background: ` rgba(203,153,141,0.1)`,
          color: "#212721",
        }}
      >
        <div className={`d-flex align-items-start ${styles.savedCardInfo}`}>
          {props.tr_type !== "paypal" && (
            <>
              <span>xxx</span> <span>xxxx</span>
            </>
          )}
          <span
            className={styles.cardNumberLast}
            style={{ whiteSpace: "break-spaces", wordBreak: "break-all" }}
          >
            {props?.card_last_4_digits}
          </span>
        </div>
      </div>
    );
  }
  return (
    <div
      className={`position-relative ${styles.cardBlockSaved}`}
      style={{
        background: `${
          props.isCurrent
            ? `rgba(203,153,141,0.1)
      `
            : "#ffffff"
        }`,
        cursor: "pointer",
        color: "#212721",
      }}
      onClick={() => {
        if (props.onCardSelect) {
          props.onCardSelect();
        }
      }}
    >
      <div className={`d-flex align-items-start ${styles.savedCardInfo}`}>
        <span>xxx</span> <span>xxxx</span>
        <span className={styles.cardNumberLast}>{props?.last_4number}</span>
      </div>
    </div>
  );
};

export default CardInfoSaved;
