import { callPostApi, callDeleteApi, callPutApi, callGetApi } from "./api";

export async function getRecipeList(postData) {
  // return this.Interceptors.POST("product-recipe/list", postData);
  try {
    const response = await callPostApi({ url: "product-recipe/list", body: postData });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteRecipe(id) {
  // return this.Interceptors.DELETE("product-recipe/" + id);
  try {
    const response = await callDeleteApi({ url: "product-recipe/" + id });
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getRecipeRawMaterials(postData) {
  // return this.Interceptors.POST("product-recipe/raw-materials/list", postData);
  try {
    const response = await callPostApi({ url: "product-recipe/raw-materials/list", body: postData });
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getRecipeType(postData) {
  // return this.Interceptors.POST("product-recipe/types/list", postData);
  try {
    const response = await callPostApi({ url: "product-recipe/types/list", body: postData });
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getRecipeUnit(postData) {
  // return this.Interceptors.POST("product-recipe/units/list", postData);
  try {
    const response = await callPostApi({ url: "product-recipe/units/list", body: postData });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function addRecipe(postData) {
  // return this.Interceptors.POST("product-recipe", postData);
  try {
    const response = await callPostApi({ url: "product-recipe", body: postData });
    return response;
  } catch (error) {
    throw error;
  }
}
export async function updateRecipe(postData, id) {
  // return this.Interceptors.PUT("product-recipe/" + id, postData);
  try {
    const response = await callPutApi({ url: "product-recipe/" + id, body: postData });
    return response;
  } catch (error) {
    throw error;
  }
}
export async function getRecipeDetail(id) {
  // return this.Interceptors.GET("product-recipe/" + id);
  try {
    const response = await callGetApi({ url: "product-recipe/" + id });
    return response;
  } catch (error) {
    throw error;
  }
}

