import React, { useContext, useEffect, useState } from "react";

import moment from "moment";

// SCSS
import styles from "./index.module.scss";

// Components
import ReactTable from "./../../../Components/Table/Table";
import Select from "./../../../Components/FormControl/Select/Select";
import Date from "./../../../Components/FormControl/Date/Date";
import EditChangeRequest from "./../../../Components/Modal/ChangeRequest/Edit/ChangeRequest";
import Header from "./../../../Components/Header/Header";
import AcceptReject from "./../../../Components/Modal/ChangeRequest/AcceptReject/AcceptReject";

// Services
import ChangeRequests from "./../../../Services/ChangeRequest/ChangeRequest";
import Clock from "././../../../Services/Clock/Clock";
import Users from "./../../../Services/Users/Users";
import { getUserLookup } from "./../../../api/user";
import { details, edit } from "./../../../api/clock";
import {
  getChangeRequest,
  updateChangeRequestStatus,
  getChangeRequestDetails,
} from "./../../../api/changeRequest";

// Context
import { AppContext } from "./../../../Context/AppContext";

// Icons
import EditIcon from "././../../../Assets/images/edit.svg";
import AcceptIcon from "././../../../Assets/images/accept.svg";
import RejectIcon from "././../../../Assets/images/reject.svg";
import SearchIcon from "././../../../Assets/images/search.svg";
import CloseIcon from "././../../../Assets/images/close.svg";
import Cookies from "universal-cookie";

import { PST_ZONE } from "Pages/Order/Order/utility/util";

const ApproveTimeSheet = () => {
  const [fromDate, setFromDate] = useState(
    moment().startOf("isoWeek").subtract(1, 'days').format("ddd MMM DD, YYYY")
  );
  const [toDate, setToDate] = useState(
    moment().endOf("isoWeek").subtract(1, 'days').format("ddd MMM DD, YYYY")
  );
  const [employeeList, setEmployeeList] = React.useState([]);
  const [selectedEmployee, setSelectedEmployee] = React.useState(0);

  const [changeRequestDetailsObj, setChangeRequestDetailsObj] = React.useState(
    {}
  );
  const [showAcceptRejectModal, setAcceptRejectModal] = useState(false);
  const [
    acceptRejectChangeRequestStatus,
    setAcceptRejectChangeRequestStatus,
  ] = useState(0);
  const [comment, setComment] = useState("");

  const [showChangeRequestModal, setChangeRequestModal] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [changeRequestClockDetails, setChangeRequestClockDetails] = useState(
    {}
  );
  const [reason, setReason] = useState("");

  const { showToastMessage, setIsLoading } = useContext(AppContext);

  const cookies = new Cookies();
  const timezone = cookies.get("defaultTimezone") ? cookies.get("defaultTimezone") : PST_ZONE ;
  
  // const clockService = new Clock();
  // const userService = new Users();
  // const changeRequestService = new ChangeRequests();

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  useEffect(() => {
    handleGetEmployeeLookup();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetEmployeeLookup = async () => {
    // const response = await userService.getUserLookup();
    const response = await getUserLookup();

    if (response && response.status === 200) {
      setEmployeeList(response.data.data);
      setSelectedEmployee(0);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    start_date,
    end_date,
    user_id,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    setIsLoading(true);
    const postData = {
      user_id: user_id ? user_id : selectedEmployee,
      start_date: start_date ? start_date : moment(fromDate).format("DD-MM-YYYY"),
      end_date: end_date ? end_date : moment(toDate).format("DD-MM-YYYY"),
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
    };
    // const response = await changeRequestService.getChangeRequest(postData);
    const response = await getChangeRequest(postData);

    if (response && response.status === 200) {
      setData(response.data.data.rows);
      setPageCount(Math.ceil(response.data.data.count / pageSize));
    } else showToastMessage(undefined, response.data.message, "error", false);

    setLoading(false);
    setIsLoading(false);
  };

  const handleClearSearch = async (event) => {
    event.preventDefault();

    setFromDate(moment().startOf("isoWeek").subtract(1, 'days').format("ddd MMM DD, YYYY"));
    setToDate(moment().endOf("isoWeek").subtract(1, 'days').format("ddd MMM DD, YYYY"));
    setSelectedEmployee(0);

    fetchData({
      ...defaultFilter,
      user_id: 0,
      currentPage: 0,
      start_date: moment().startOf("isoWeek").subtract(1, 'days').format("DD-MM-YYYY"),
      end_date: moment().endOf("isoWeek").subtract(1, 'days').format("DD-MM-YYYY"),
    });
  };

  const handleUpdateStatus = async (event) => {
    event.preventDefault();

    setSubmitStatus(true);

    if (comment) {
      let reqBody = {
        change_request_id: changeRequestDetailsObj.id,
        comment,
        status: acceptRejectChangeRequestStatus,
      };
      setSubmitStatus(false);
      setIsLoading(true);

    //   const response = await changeRequestService.updateChangeRequestStatus(
    //     reqBody
    //   );
      const response = await updateChangeRequestStatus(
        reqBody
      );
      setAcceptRejectModal(false);
      setComment("");

      if (response && response.status === 200) {
        fetchData({ ...defaultFilter });
        showToastMessage(undefined, response.data.message, "success", false);
      } else showToastMessage(undefined, response.data.message, "error", false);

      setIsLoading(false);
    }
  };

  const handleGetChangeRequestDetails = async (
    event,
    changeRequestId,
    clockMasterId
  ) => {
    event.preventDefault();

    setChangeRequestModal(true);

    // const response = await clockService.details(clockMasterId);
    const response = await details(clockMasterId);
    // const changeRequestResponse = await changeRequestService.getChangeRequestDetails(
    //   changeRequestId
    // );
    const changeRequestResponse = await getChangeRequestDetails(
      changeRequestId
    );

    if (changeRequestResponse && changeRequestResponse.status === 200) {
      setReason(changeRequestResponse.data.data.reason);
    }

    if (response && response.status === 200) {
      setChangeRequestClockDetails(response.data.data);
    } else if (response && response.status === 404) {
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const onChangeValue = async (data) => {
    let changeRequestClockObj = { ...changeRequestClockDetails };

    let index = changeRequestClockDetails.clock_time.findIndex(
      (clockTimeObj) => clockTimeObj.id === data.id
    );
    if (index > -1) {
      let changeDay;
      let changeTime = moment(data.time).startOf("minute").format("HH:mm");

      if (data.type === "in") {
        changeDay = moment
          .unix(changeRequestClockObj.clock_time[index].clock_in)
          .tz(timezone)
          .format("YYYY-MM-DD");
        let time = moment
          .tz(changeDay + " " + changeTime, timezone)
          .utc()
          .unix("X");
        changeRequestClockObj.clock_time[index]["clock_in"] = time;
      } else if (data.type === "out") {
        changeDay = moment
          .unix(changeRequestClockObj.clock_time[index].clock_out)
          .tz(timezone)
          .format("YYYY-MM-DD");
        let time = moment
          .tz(changeDay + " " + changeTime, timezone)
          .utc()
          .unix("X");
        changeRequestClockObj.clock_time[index]["clock_out"] = time;
      }

      setChangeRequestClockDetails(changeRequestClockObj);
    }
  };

  const handleChangeClockDetails = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // const response = await clockService.edit(changeRequestClockDetails);
    const response = await edit(changeRequestClockDetails);

    if (response && response.status === 200) {
      setChangeRequestModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData({ ...defaultFilter });
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Date Requested",
        accessor: "clock_time",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return timezone && value.clock_time_master.date
            ? moment
                .unix(value.clock_time_master.date)
                .utc()
                .format("ddd MMM DD, YYYY")
            : "-";
        },
      },
      {
        Header: "Employee Name",
        accessor: "user",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) =>
          value ? `${value.first_name} ${value.last_name}` : "-",
      },
      {
        Header: "Reason",
        accessor: "reason",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => (value ? value : "-"),
      },
      {
        Header: "Original Time",
        accessor: "",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) =>
          timezone && row.original.clock_time && row.original.type === "IN"
            ? row.original.clock_time.clock_in &&
              row.original.clock_time.clock_in > 0
              ? moment
                  .unix(row.original.clock_time.clock_in)
                  .tz(timezone)
                  .format("hh:mm a") + ", Clock In"
              : "-"
            : timezone &&
              row.original.clock_time.clock_out &&
              row.original.clock_time.clock_out > 0
            ? moment
                .unix(row.original.clock_time.clock_out)
                .tz(timezone)
                .format("hh:mm a") + " Clock Out"
            : "-",
      },
      {
        Header: "New Time",
        accessor: "time",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) =>
          timezone && value
            ? moment.unix(value).tz(timezone).format("hh:mm a")
            : "-",
      },
      {
        Header: "Actions",
        accessor: "id",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              <img
                onClick={(e) => {
                  setAcceptRejectModal(true);
                  setChangeRequestDetailsObj(row.original);
                  setAcceptRejectChangeRequestStatus(2);
                }}
                className={`mr-3 ${styles.searchIcon}`}
                src={AcceptIcon}
                alt="accept"
              />
              <img
                onClick={(e) => {
                  setAcceptRejectModal(true);
                  setChangeRequestDetailsObj(row.original);
                  setAcceptRejectChangeRequestStatus(3);
                }}
                className={`mr-3 ${styles.searchIcon}`}
                src={RejectIcon}
                alt="reject"
              />
              <img
                onClick={(event) =>
                  handleGetChangeRequestDetails(
                    event,
                    row.original.id,
                    row.original.clock_time.clock_master_id
                  )
                }
                className={`${styles.searchIcon}`}
                src={EditIcon}
                alt="Edit"
              />
            </div>
          );
        },
      },
    ],
    []
  ); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <Header title="Approve Timesheet" />

      <div id="MainContent" className="main-content">
        <div className="row">
          <div className="col-sm-4 col-xs-12">
            <Select
              label="Employee"
              value={selectedEmployee}
              // parentClass="pt-0"
              onChange={(data) => setSelectedEmployee(data)}
              options={employeeList}
              disabled={false}
            />
          </div>
          <div className="col-sm-2 col-xs-12">
            <Date
              label="Weekly From Date"
              value={fromDate}
              dateFormat="ddd MMM DD, YYYY"
              type="date"
              onChange={(fromDate) =>
                setFromDate(moment(fromDate).format("ddd MMM DD, YYYY"))
              }
            />
          </div>
          <div className="col-sm-2 col-xs-12">
            <Date
              label="Weekly To Date"
              value={toDate}
              dateFormat="ddd MMM DD, YYYY"
              type="date"
              onChange={(toDate) =>
                setToDate(moment(toDate).format("ddd MMM DD, YYYY"))
              }
            />
          </div>
          <div className="col-sm-4 col-xs-8">
            <div className="td-actions pt-4">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  fetchData({ ...defaultFilter });
                }}
                className={`link cursor-pointer ${styles.searchIconWrapper}`}
              >
                <img
                  className={styles.searchIcon}
                  src={SearchIcon}
                  alt="search"
                />
              </a>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  handleClearSearch(e);
                }}
                className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
              >
                <img className={styles.clearIcon} src={CloseIcon} alt="close" />
              </a>
            </div>
          </div>
        </div>
        <ReactTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"No data available."}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
        />
      </div>

      <AcceptReject
        showModal={showAcceptRejectModal}
        comment={comment}
        submitStatus={submitStatus}
        changeRequestDetailsObj={changeRequestDetailsObj}
        acceptRejectChangeRequestStatus={acceptRejectChangeRequestStatus}
        setComment={(comment) => setComment(comment)}
        handleCancelClick={(event) => {
          event.preventDefault();
          setAcceptRejectModal(false);
          setComment("");
          setAcceptRejectChangeRequestStatus(0);
          setSubmitStatus(false);
        }}
        handleSubmitClick={handleUpdateStatus}
      />

      <EditChangeRequest
        showModal={showChangeRequestModal}
        clockDetails={changeRequestClockDetails}
        submitStatus={submitStatus}
        handleCancelClick={(event) => {
          event.preventDefault();
          setChangeRequestModal(false);
          // handleGetChangeRequestList();
          fetchData({ ...defaultFilter });
          setSubmitStatus(false);
          setReason("");
        }}
        reason={reason}
        setReason={(data) => setReason(data)}
        onChangeValue={(data) => onChangeValue(data)}
        handleSubmitClick={handleChangeClockDetails}
      />
    </React.Fragment>
  );
};

export default ApproveTimeSheet;
