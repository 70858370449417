import Header from "Components/Header/Header";
import { AppContext } from "Context/AppContext";
import { MODULES } from "Helpers/Constants";
import { getAccessByModuleName } from "Routes/Routes";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import ReactTable from "../../../Components/Table/Table";
// Images
import EditIcon from "./../../../Assets/images/edit.svg";
import { getWasteSheetList } from "api/inventoryProduct";
import { DateTime } from "luxon";
import moment from "moment";
import ViewIcon from "../../../Assets/images/error-outline.svg";

const WasteSheet = (props) => {
  const { modules, showToastMessage, setIsLoading } = useContext(AppContext);
  const [access, setAccess] = useState({});
  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.WASTE_SHEET);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/profile");
    }
  }

  const [pageCount, setPageCount] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [
      {
        id: "id",
        desc: true,
      },
    ],
    filters: [],
    globalFilter: "",
  });

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
    };
    const response = await getWasteSheetList(postData);
    if (response && response.status === 200) {
      setData(response.data.data.rows);
      setPageCount(Math.ceil(response.data.data.count / pageSize));
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      const postData = {
        ...defaultFilter,
      };
      await fetchData(postData);
    })();
  }, []);

  const handleAction = (event, type = "add", data = {}) => {
    event.preventDefault();
    if (type === "add") {
      props.history.push(`/inventory-management/waste-sheet/add-waste`);
      setIsLoading(false);
    } else if (type === "edit") {
      props.history.push(`/inventory-management/waste-sheet/${data.id}`);
      setIsLoading(false);
    } else {
      props.history.push(`/inventory-management/waste-sheet/view/${data.id}`);
      setIsLoading(false);
    }
  };

  const columns = useMemo(() => [
    {
      Header: "Request No.",
      accessor: "request_number",
      isVisible: true,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Added By",
      accessor: "request_created_by",
      isVisible: true,
      placeholder: "Search Added By Name",
      Cell: ({ value, row, column }) => {
        return <span>{row.original?.user?.full_name}</span>;
      },
    },
    {
      Header: "Date",
      accessor: "updatedAt",
      placplaceholder: "Search By Date & Time",
      isVisible: true,
      Cell: ({ value, row, column }) => {
        return (
          <span>
            {DateTime.fromISO(row?.original?.updatedAt)
              .setZone("America/Los_Angeles")
              .toFormat("ccc LLL dd, yyyy, hh:mm a")}
          </span>
        );
      },
    },
    {
      Header: "Action",
      accessor: "id",
      isVisible: true,
      disableSortBy: true,
      Cell: ({ value, row, column }) => {
        const createdAt = moment(row?.original?.createdAt)
          .utc()
          .format("YYYY-MM-DD");
        const today = moment().utc().format("YYYY-MM-DD");
        const checkSameDate = moment(today).isSame(createdAt, "date");
        // if (checkSameDate) {
        return (
          <div className="d-flex justify-content-start">
            {access && access.read_access && (
              <img
                className={`cursor-pointer ml-3`}
                src={ViewIcon}
                alt="Delete"
                onClick={(e) => {
                  e.preventDefault();
                  handleAction(e, "view", row.original);
                }}
              />
            )}
            {access && access.write_access && checkSameDate && (
              <img
                className={`cursor-pointer ml-3`}
                src={EditIcon}
                alt="Edit"
                onClick={(e) => {
                  e.preventDefault();
                  handleAction(e, "edit", row.original);
                }}
              />
            )}
          </div>
        );
        // }
      },
    },
  ]);

  return (
    <>
      <Header
        title="Waste Sheet"
        hasAddButton={access.write_access}
        handleAddClick={handleAction}
      />
      <ReactTable
        pageCount={pageCount}
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        defaultFilter={defaultFilter}
        noDataText={"There is no data to display"}
      />
    </>
  );
};

export default withRouter(WasteSheet);
