import { callPostApi, callDeleteApi, callPutApi, callGetApi } from "./api";

export async function getDeliveryMatrixList(data) {
      // return this.interceptors.POST("delivery-matrix/list", data);
      try {
            const response = await callPostApi({ url: "delivery-matrix/list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function deleteDeliveryMatrixList(id) {
      // return this.interceptors.DELETE("delivery-matrix/" + id);
      try {
            const response = await callDeleteApi({ url: "delivery-matrix/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function addDeliveryMatrix(data) {
      // return this.interceptors.POST("delivery-matrix/", data);
      try {
            const response = await callPostApi({ url: "delivery-matrix/", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function getDeliveryMatrixById(id) {
      // return this.interceptors.GET("delivery-matrix/" + id);
      try {
            const response = await callGetApi({ url: "delivery-matrix/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function updateDeliveryMatrix(data, id) {
      // return this.interceptors.PUT("delivery-matrix/" + id, data);
      try {
            const response = await callPutApi({ url: "delivery-matrix/" + id, body: data });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function getDeliveryMatrixNameList() {
      // return this.interceptors.GET("delivery-matrix/name-list");
      try {
            const response = await callGetApi({ url: "delivery-matrix/name-list" });
            return response;
      } catch (error) {
            throw error;
      }
}

