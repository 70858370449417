import { callPostApi, callDeleteApi, callPutApi, callGetApi } from "./api";

export async function storeList() {
      // return storeList
      try {
            const response = await callGetApi({ url: "stores/storeList" });
            return response;
      } catch (error) {
            throw error;
      }
}
//display specific store details
export async function storeDetails() {
      try {
            const response = await callGetApi({ url: "stores/storedetails" });
            return response;
      } catch (error) {
            throw error;
      }
}
//update specific store details
export async function updateStoreDetails(postData) {
      console.log("postData",postData);
      try {
            const response = await callPutApi({ url: "stores/update", body: postData});
            return response;
      } catch (error) {
            throw error;
      }
}
//update specific store burq status
export async function updateStoreWiseBurqStatus(postData) {
      try {
            const response = await callPutApi({ url: "stores/burq/update-status", body: postData});
            return response;
      } catch (error) {
            throw error;
      }
}
