import { makeStyles } from "@material-ui/core";
import React from "react";
import Arrow from 'Assets/images/arrow.svg'
const useStyles = makeStyles({
  list: {
    padding: "0.8rem 0.3rem",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(203,153,141,0.2)",
    },
  },
});

const DropdownLoqate = ({ addressList, showDropDown, onItemClick }) => {
  const classes = useStyles();
  return (
    <ul
      className={showDropDown && addressList.length !== 0 ? "" : "d-none"}
      style={{
        maxHeight: "300px",
        width: "100%",
        boxShadow: "1px 1px 4px #ccc",
        position: "absolute",
        left: "0",
        top: "100%",
        overflowY: "scroll",
        backgroundColor: "#fff",
        zIndex: 10,
        padding: 0,
      }}
    >
      {addressList?.map((rec) => (
        <li
          key={rec.id}
          onClick={() => onItemClick(rec)}
          className={classes.list}
          style={{
            fontSize: "18px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>{rec.Text}&nbsp;&nbsp;&nbsp;<span style={{color:"#ccc"}}>{rec.Description}</span></span>
         
          <span>{rec.Type !== "Address" && <img width="15px" style={{transform:"rotate(-90deg)"}} src={Arrow} alt="more" />}</span>
        </li>
      ))}
    </ul>
  );
};

export default DropdownLoqate;
