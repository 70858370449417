import React from "react";
import styles from "./index.module.scss";
import FeaturedProductCard from "./FeaturedProductCard";

const FeaturedProduct = (props) => {
  const productsData  = props.productsData ? props.productsData : [];
  // console.log('productsData ===>', productsData);

  return (
    <React.Fragment>
      <div 
        className={`mb-2 ${styles.featuredTitle}`} 
        id={`featured-product-section`}
      >
        Featured Product
      </div>
      {productsData && productsData?.map((product, index) => {
        return (
          <FeaturedProductCard 
            index={index}
            productData={product}
            setFeaturedProductData={props.setFeaturedProductData}
            checkFeaturedProductData={props.checkFeaturedProductData}
            addProductList={(data) => {
              props.addProductList(data);
            }}
            removeProductList={(data) => {
              props.removeProductList(data);
            }}
            setProductSequence={(data) => {
              props.setProductSequence(data);
            }}
          />
        )
      })}
    </React.Fragment>
  );
};
export default FeaturedProduct;
