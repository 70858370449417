import { makeStyles } from "@material-ui/core";
import React from "react";
import { useOrderAttributeProvider } from "../context/order";
const useStyles = makeStyles({
  card: {
    color: "#7d858c",
    padding: "10px 20px",
    "& p": {
      fontWeight: 300,
      fontSize: 18,
      lineHeight: "1.5",
      font: "inherit",
    },
  },
});
const EditOrderPayment = () => {
  const classes = useStyles();
  const { orderDetail } = useOrderAttributeProvider();
  return (
    <div
      className={`${classes.card} d-flex justify-content-between align-items-center`}
      style={{
        backgroundColor: "rgba(203, 153, 141, 0.1)",
      }}
    >
      {orderDetail?.order_transaction?.order_payment_transactions[0]
        ?.transaction_type !== "paypal" && (
        <p
          className="m-0"
          style={{ whiteSpace: "break-spaces", wordBreak: "break-all" }}
        >
          xxx xxxx{" "}
          {
            orderDetail?.order_transaction?.order_payment_transactions[0]
              ?.card_last_4_digits
          }
        </p>
      )}
      {orderDetail?.order_transaction?.order_payment_transactions[0]
        ?.transaction_type === "paypal" && (
        <p
          className="m-0"
          style={{ whiteSpace: "break-spaces", wordBreak: "break-all" }}
        >
          {
            orderDetail?.order_transaction?.order_payment_transactions[0]
              ?.card_last_4_digits
          }
        </p>
      )}
      {/* <img
        style={{ filter: "grayscale(1)" }}
        height={25}
        src={getCardImage("")}
        alt={""}
      /> */}
    </div>
  );
};

export default EditOrderPayment;
