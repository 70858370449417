import OrderConfigProvider from "Context/OrderConfigContext";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App.jsx";
import Store from "./Context/Store";
import reportWebVitals from "./reportWebVitals";

import * as Sentry from "@sentry/react";
/*
ignoreErrors:
REsizeObserver loop error added in ignore list base on below finding
https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
*/

if(process.env.REACT_APP_SENTRY_ENVIRONMENT && process.env.REACT_APP_SENTRY_ENVIRONMENT=="production"){
  Sentry.init({
    ignoreErrors: [
      "ResizeObserver loop limit exceeded"
    ],
    dsn: "https://ddf15799fed2db3e729e3b181afcfb77@o4507258588495872.ingest.us.sentry.io/4507259134738432",
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    release: "frenchflorist-v2-web-admin",
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "dev",
    tracesSampleRate: 0.0,
    integrations: [Sentry.browserTracingIntegration()],
  });
}else{
  Sentry.init({
    ignoreErrors: [
      "ResizeObserver loop limit exceeded"
    ],
    // dsn: "https://fdgfdgfdgfdg@o525665.ingest.sentry.io/5665914-0000wrongkeyset",
    dsn :"https://ddf15799fed2db3e729e3b181afcfb77@o4507258588495872.ingest.us.sentry.io/4507259134738432",
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    release: "frenchflorist-v2-web-admin",
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "dev",
    tracesSampleRate: 0.0,
    integrations: [Sentry.browserTracingIntegration()],
  });
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={"An error has occurred"}>
    <Store>
      <OrderConfigProvider>
        <App />
      </OrderConfigProvider>
    </Store>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);
// ReactDOM.render(<React.StrictMode> <App /> </React.StrictMode>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
