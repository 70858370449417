import React from 'react';

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// Scss
import styles from './LogoutAllUserConfirmation.module.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const LogoutAllUsersConfirmation = ({ showModal, title, message, buttonLabel, handleCancelClick, handleSubmitClick }) => {
    return (
        <React.Fragment>
            <Dialog
                open={showModal}
                TransitionComponent={Transition}
                onClose={handleCancelClick}
                classes={{
                    paper: styles.modalDialog
                }}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogContent
                    className={styles.modalContent}
                >
                    <div className={`text-center ${styles.modalBody}`}>
                        <h5 className={`my-3 text-center ${styles.modalTitle}`}>{title}</h5>
                        <p className={`popup-info-text ${styles.modalMessage}`}> {message} </p>
                        <span data-dismiss="modal" onClick={handleSubmitClick} className="btn gray-btn d-inline-block mb-3 mr-2"> {buttonLabel} </span>
                        <span data-dismiss="modal" onClick={handleCancelClick} className="btn gray-btn d-inline-block mb-3"> Cancel </span>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default LogoutAllUsersConfirmation;
