import React from "react";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import styles from "./Confirmation.module.scss";
import { Button } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Confirmation = ({
  showModal,
  title,
  message,
  handleCancelClick,
  handleSubmitClick,
  btn1_title,
  btn2_title,
  btn1_type,
  btn2_type,
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={styles.modalContent}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`text-center ${styles.modalTitle}`}>{title}</h5>
            <p className={`${styles.modalMessage}`}>{message}</p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "40px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitClick}
                type={btn1_type ? btn1_type : "submit"}
                className="btn gray-btn d-inline-block mb-3 mr-2"
              >
                {btn1_title ? btn1_title : "Submit"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCancelClick}
                type={btn2_type ? btn2_type : "submit"}
                className="btn gray-btn d-inline-block mb-3 mr-2"
              >
                {btn1_title ? btn2_title : "Cancel"}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default Confirmation;
