import { DeliveryMatrixAction } from "./DeliveryMatrixAction";

const clrStateDeliveryMatrix = {
  name: "",
  start_date: "",
  end_date: "",
  delivery_matrix_zipcode_folders: [],
  delivery_matrix_address_types: [],
  delivery_matrix_days: [],
  delivery_matrix_items: [],
}

function DeliveryMatrixReducer(state, action) {
  switch (action.type) {

    case DeliveryMatrixAction.SET_DELIVERY_MATRIX_NAME:
      return {
        ...state,
        name: action.payload,
      };

    case DeliveryMatrixAction.SET_DELIVERY_MATRIX_ZIPCODE:
      return {
        ...state,
        delivery_matrix_zipcode_folders: action.payload,
      };

    case DeliveryMatrixAction.SET_DELIVERY_MATRIX_ADDRESS_TYPE:
      return {
        ...state,
        delivery_matrix_address_types: action.payload,
      };

    case DeliveryMatrixAction.SET_DELIVERY_MATRIX_DAYS:
      return {
        ...state,
        delivery_matrix_days: action.payload,
      };

    case DeliveryMatrixAction.SET_DELIVERY_MATRIX_START_DATE:
      return {
        ...state,
        start_date: action.payload,
      };

    case DeliveryMatrixAction.SET_DELIVERY_MATRIX_END_DATE:
      return {
        ...state,
        end_date: action.payload,
      };

    case DeliveryMatrixAction.SET_DELIVERY_TIME_DATA:
      let deliveryMatrixItems = state.delivery_matrix_items
        ? [...state.delivery_matrix_items]
        : [];
      deliveryMatrixItems.push(action.payload);

      return {
        ...state,
        delivery_matrix_items: deliveryMatrixItems,
      };

    case DeliveryMatrixAction.DELETE_DELIVERY_TIME_DATA:

      let deliveryMatrixItemsDelete = state.delivery_matrix_items
        ? [...state.delivery_matrix_items]
        : [];
      deliveryMatrixItemsDelete.splice(action.payload, 1);
      return {
        ...state,
        delivery_matrix_items: deliveryMatrixItemsDelete,
      };

    case DeliveryMatrixAction.EDIT_DELIVERY_TIME_DATA:
      let deliveryMatrixItemsEdit = state.delivery_matrix_items
        ? [...state.delivery_matrix_items]
        : [];

      deliveryMatrixItemsEdit[action.index] = {
        ...deliveryMatrixItemsEdit[action.index],
        ...action.payload,
      };

      return {
        ...state,
        delivery_matrix_items: deliveryMatrixItemsEdit,
      };

    case DeliveryMatrixAction.CLEAR_DELIVERY_MATRIX_DATA:
      return clrStateDeliveryMatrix;

    case DeliveryMatrixAction.DELETE_ALL_DELIVERY_TIME_DATA:

      return {
        ...state,
        delivery_matrix_items: [],
      };


    default:
      return state;
  }
}

export default DeliveryMatrixReducer;
