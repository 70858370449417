import { useFormik } from "formik";

export function BlogForm(submit, initialVal, cb, checkBlogData) {
  const validation = (values) => {
  function hasWhiteSpace(s) {
    return (/\s/).test(s);
  }
    // const regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    let errors = {};
    let blogError = false;
    if (!values.title) {
      errors.title = "Blog title is required";
      blogError = true;
    }

    if (values.title?.trim() === "") {
      errors.title = "Blog title is required";
      blogError = true;
    }

    if (!values.slug) {
      errors.slug = "Slug is required";
      blogError = true;
    }

    if (values.slug?.trim() === "") {
      errors.slug = "Slug is required";
      blogError = true;
    }

    if (!values.description) {
      errors.description = "Description is required";
      blogError = true;
    }

    if(hasWhiteSpace(values.url_category_slug) == true) {
        errors.url_category_slug = "Incorrect Category Slug";
        blogError = true;
    }
    // console.log('blogError ===>', errors, blogError);
    checkBlogData(blogError);
    if (cb) {
      cb(values);
    }
    return errors;
  };

  const initialValues = {
    title: "",
    detail: "",
    slug: "",
    description: "",
    keywords: "",
    author_name: "",
    read_time: "",
    blog_date: "",
    url_category_slug:"",
  };

  const formik = useFormik({
    initialValues: initialVal || initialValues,
    onSubmit: submit,
    enableReinitialize: true,
    validate: validation,
  });

  return { formik };
}
