import { callPutApi, callPostApi, callGetApi } from "./api";


export async function getFeaturedProduct(id, moduleName) {
      // return this.interceptors.GET(`featured-product/${moduleName}/${id}/${false}`);
      try {
            const response = await callGetApi({ url: `featured-product/${moduleName}/${id}/${false}` });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function uploadImage(data) {
      // return this.interceptors.POST('utils/upload-image', data);
      try {
            const response = await callPostApi({ url: "utils/upload-image", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateStatus(postData) {
      // return this.interceptors.PUT("utils/update-status/", postData);
      try {
            const response = await callPutApi({ url: "utils/update-status/", body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}
