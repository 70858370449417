import { getOrderList } from 'api/order';
import { isBeforetwelveandsix } from 'Helpers/date';
import { useLastOrderEditPath } from 'Hooks/useLastOrderEditPath';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import EditIcon from '../../../Assets/images/edit.svg';
import ViewIcon from '../../../Assets/images/error-outline.svg';
// Components
import Header from '../../../Components/Header/Header';
import DefaultOrderFilter from '../../../Components/DefaultOrderFilter/DefaultOrderFilter';
import ReactTable from '../../../Components/Table/Table';
import { formatUsPhone } from '../../../Hooks/commanUtility';
import { AppContext } from './../../../Context/AppContext';
import { MODULES } from './../../../Helpers/Constants';
// Context
import { getAccessByModuleName } from './../../../Routes/Routes';
import { useUser } from 'Context/UserContext/useUser';
//scss
import styles from './index.module.scss';
import LoadingScreen from 'Components/Loading/LoadingScreen';
import { setAllStore } from 'Helpers/setAllStore';
const Orders = (props) => {
  const { showToastMessage, modules } = useContext(AppContext);
  const [totalCount, setTotalCount] = useState(0);

  // Date Range Filter Start
  let defaultType = 'order_date'; // order_date : delivery_date
  const [type, setType] = useState(defaultType);
  let defaultFromDate = moment()
    .subtract(1, 'weeks')
    .startOf('isoWeek')
    .format('ddd MMM DD, YYYY');
  let defaultToDate = moment().endOf('isoWeek').format('ddd MMM DD, YYYY');
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  // Date Range Filter End

  const [userData, setUserData] = React.useState('');
  const { userState, reLogin } = useUser();
  const getUserData = async () => {
    if (userState?.userProfile?.id) {
      setUserData(userState?.userProfile?.user_type);
    } else {
      const user = await reLogin();
      setUserData(user?.user_type);
    }
  };
  useEffect(() => {
    getUserData();
  }, []);
  // Add / Edit

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [
      {
        id: 'order_number',
        desc: 'desc',
      },
    ],
    filters: [],
    globalFilter: '',
  });

  const [firstRender, setFirstRender] = useState(true);
  const [isCustomSearch, setIsCustomSearch] = useState(false);
  const [columnStateSaveData, setColumnStateSaveData] = useState({});

  const [checkAllStore,setCheckAllStore] = useState(true);

  // Set Async Data
  const setAsyncData = (data) => {
    let asyncDataValuesProcessOrders = JSON.stringify(data);
    localStorage.setItem(
      'asyncDataValuesProcessOrders',
      asyncDataValuesProcessOrders
    );
  };
  // Get And Set Data Into State
  useEffect(() => {
    const filterData = JSON.parse(
      localStorage.getItem('asyncDataValuesProcessOrders')
    );
    if (filterData) {
      const {
        currentPage,
        itemsPerPage,
        globalSearch,
        filters,
        sortBy,
        date_type,
        start_date,
        end_date,
      } = filterData;
      setDefaultFilter({
        filters: filters,
        pageIndex: currentPage,
        globalFilter: globalSearch,
        pageSize: itemsPerPage,
        sortBy: sortBy,
      });
      setType(date_type);
      setFromDate(moment(start_date).format('ddd MMM DD, YYYY'));
      setToDate(moment(end_date).format('ddd MMM DD, YYYY'));
    }
    setFirstRender(false);

    const columnStateData = JSON.parse(localStorage.getItem("columnDataStateSaveProcessOrder"));
    setColumnStateSaveData(columnStateData);
  }, []);

  // const ordersService = useMemo(() => new OrdersApi(), []);
  useLastOrderEditPath();
  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
    latestData,
  }) => {

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);
    
    if (
      !dateValidation(
        fromDateDefault ? fromDateDefault : fromDate,
        toDateDefault ? toDateDefault : toDate
      )
    ) {
      return false;
    }
    pageIndex = latestData ? 0 : pageIndex;
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      date_type: type,
      start_date: fromDateDefault
        ? fromDateDefault
        : moment(fromDate).format('YYYY-MM-DD'),
      end_date: toDateDefault
        ? toDateDefault
        : moment(toDate).format('YYYY-MM-DD'),
      list_type: 'processing',
    };
    // console.log("Process data req", postData);
    let processAccess = getAccessByModuleName(modules, MODULES.PROCESS_ORDER);
    if (processAccess && processAccess.permissions.read_access) {
      const response = await getOrderList(postData);
      if (response && response.status === 200) {
        // console.log("Order list", response.data.data.rows);
        setData(response.data.data.rows);
        setTotalCount(response.data.data.count);
        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else {
        showToastMessage(undefined, response.data.message, 'error', false);
      }
    }
    setLoading(false);
    setAsyncData(postData);
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setIsCustomSearch(false);
    
    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  const handleClearSearch = async (event) => {
    event.preventDefault();
    setType(defaultType);
    setFromDate(defaultFromDate);
    setToDate(defaultToDate);
    setIsCustomSearch(true);
    fetchData({
      ...defaultFilter,
      latestData: true,
      fromDateDefault: defaultFromDate,
      toDateDefault: defaultToDate,
    });
  };

  //   useEffect(async () => {}, []);
  // Handle On Start Date Change Validation
  const handleFromDate = async (data) => {
    setFromDate(data);
    // dateValidation(data,toDate);
  };
  // Handle On End Date Change Validation
  const handleToDate = async (data) => {
    setToDate(data);
    // dateValidation(fromDate,data);
  };
  // Date Validation
  const dateValidation = (fromDate, toDate) => {
    let todayDate = moment(new Date()).format('x');
    let startDate = moment(fromDate).format('x');
    let endDate = moment(toDate).format('x');
    if (startDate > endDate) {
      showToastMessage(
        undefined,
        'End date should be greater than start date',
        'error',
        false
      );
      return false;
    }
    let daysDiff = moment(fromDate).diff(new Date(), 'days');
    daysDiff = daysDiff < 0 ? daysDiff * -1 : daysDiff;
    if (
      userData &&
      userData !== 'super_admin' &&
      userData !== 'admin' &&
      startDate < todayDate &&
      daysDiff > 60
    ) {
      showToastMessage(
        undefined,
        'You can select maximum 60 days back from current date for start date',
        'error',
        false
      );
      return false;
    }
    return true;
  };
  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.PROCESS_ORDER);

    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        'You are not authorized to access.',
        'error',
        false
      );
      props.history.push(`/users`);
    }
  }

  const selectedColumn = async (column) => {
    let columnData = JSON.stringify(column);
    // console.log("column=>",columnData);
    localStorage.setItem("columnDataStateSaveProcessOrder", columnData);
  };

  let accountAccess = getAccessByModuleName(modules, MODULES.ACCOUNT_LOOKUP);
  let viewOrderAccess = getAccessByModuleName(modules, MODULES.VIEW_ORDER);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Order No',
        accessor: 'order_number',
        isVisible: columnStateSaveData?.order_number ?? true,
        placeholder: 'Order No',
      },
      {
        Header: 'Customer Name',
        accessor: 'customer_name',
        isVisible: columnStateSaveData?.customer_name ?? true,
        placeholder: 'Customer Name',
        Cell: ({ value, row, column }) => {
          return row.original.order_transaction.customer_id === 0 ||
            row.original.order_transaction.customer_id === undefined ? (
            <span>{`${row.original.order_transaction.customer_name}`}</span>
          ) : accountAccess && accountAccess.permissions.write_access ? (
            <Link
              to={`/admin/customers/${row.original.order_transaction.customer_id}`}
            >
              <span className={` cursor-pointer`}>
                {`${row.original.order_transaction.customer_name}`}
              </span>
            </Link>
          ) : (
            <span>{`${row.original.order_transaction.customer_name}`}</span>
          );
        },
      },
      {
        Header: 'Recipient Name',
        accessor: 'recipient_name',
        isVisible: columnStateSaveData?.recipient_name ?? true,
        placeholder: 'Recipient Name',
        Cell: ({ value, row, column }) => {
          return `${row.original.order_recipients[0].recipient_name}`;
        },
      },
      {
        Header: 'Delivery Date',
        accessor: 'delivery_date_time',
        isVisible: columnStateSaveData?.delivery_date_time ?? true,
        placeholder: 'Delivery Date',
        Cell: ({ value, row, column }) => {
          return `${
            row.original.delivery_date_time &&
            row.original.delivery_date_time !== null
              ? row.original.delivery_time_type === 'Anytime'
                ? DateTime.fromISO(row.original.delivery_date_time)
                    .setZone('America/Los_Angeles')
                    .toFormat('ccc LLL dd, yyyy') +
                  ' ' +
                  row.original.delivery_time_type
                : DateTime.fromISO(row.original.delivery_date_time)
                    .setZone('America/Los_Angeles')
                    .toFormat('ccc LLL dd, yyyy, hh:mm a')
              : ''
          }`;
        },
        disableFilters: true,
      },
      {
        Header: 'Order Date',
        accessor: 'createdAt',
        isVisible: columnStateSaveData?.createdAt ?? true,
        placeholder: 'Order Date',
        Cell: ({ value, row, column }) => {
          return `${
            row.original.createdAt && row.original.createdAt !== null
              ? DateTime.fromISO(row.original.createdAt)
                  .setZone('America/Los_Angeles')
                  .toFormat('ccc LLL dd, yyyy, hh:mm a')
              : ''
          }`;
        },
        disableFilters: true,
      },
      {
        Header: 'Building Type',
        accessor: 'building_type',
        isVisible: columnStateSaveData?.building_type ?? true,
        placeholder: 'Building Type',
        colWidth: 140,
        isSelectFilter: true,
        selectFilterOption: [
          { id: '', title: 'All' },
          { id: 'house', title: 'House' },
          { id: 'apartment', title: 'Apartment' },
          { id: 'business', title: 'Business' },
          { id: 'funeral_home', title: 'Funeral home' },
          { id: 'church', title: 'Church' },
          { id: 'hospital', title: 'Hospital' },
          { id: 'restaurant', title: 'Restaurant' },
          { id: 'hotel', title: 'Hotel' },
          { id: 'university', title: 'University' },
          { id: 'other_school ', title: 'Other school ' },
        ],
        Cell: ({ value, row, column }) => {
          return `${row.original.order_recipients[0].building_type}`;
        },
      },
      {
        Header: 'Price',
        accessor: 'total',
        isVisible: columnStateSaveData?.total ?? true,
        placeholder: 'Price',
        Cell: ({ value, row, column }) => {
          return `$${row.original.total.toFixed(2)}`;
        },
      },
      {
        Header: 'Products',
        accessor: 'image',
        isVisible: columnStateSaveData?.image ?? true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          let time = isBeforetwelveandsix(row.original.delivery_date_time);
          let threeMark = (
            <span
              className={` ${styles.ProducatBadge} ${styles.ProducatBadgeRed}`}
            >
              !!!
            </span>
          );

          let oneMark = (
            <span
              className={` ${styles.ProducatBadge} ${styles.ProducatBadgeGreen}`}
            >
              !
            </span>
          );
          let img = row.original.order_products.find((c) => !c.product_type);
          return (
            <div className={` ${styles.ProducatImageWapper}`}>
              <img
                src={
                  img?.variation_image === '' ||
                  img?.variation_image === 'null' ||
                  img?.variation_image === null
                    ? ''
                    : `${img.variation_image}`
                }
                alt=""
              />
              {time.beforTwelve && threeMark}
              {!time.beforTwelve && time.beforSix && oneMark}
            </div>
          );
        },
      },
      {
        Header: 'Customer Email',
        accessor: 'customer_email',
        placeholder: 'Customer Email',
        isVisible: columnStateSaveData?.customer_email ?? false,
        Cell: ({ value, row, column }) => {
          return `${row.original.order_transaction.customer_email}`;
        },
      },
      {
        Header: 'Customer Phone',
        accessor: 'customer_phone',
        placeholder: 'Customer Phone',
        isVisible: columnStateSaveData?.customer_phone ?? false,
        Cell: ({ value, row, column }) => {
          return `${
            row.original.order_transaction.customer_phone
              ? row.original.order_transaction.customer_phone_country_code +
                ' ' +
                formatUsPhone(row.original.order_transaction.customer_phone)
              : ''
          }`;
        },
      },
      {
        Header: 'Recipient Phone',
        accessor: 'recipient_phone',
        placeholder: 'Recipient Phone',
        isVisible: columnStateSaveData?.recipient_phone ?? false,
        Cell: ({ value, row, column }) => {
          return `${
            row.original.order_recipients[0].recipient_phone
              ? row.original.order_recipients[0].phone_country_code +
                ' ' +
                formatUsPhone(row.original.order_recipients[0].recipient_phone)
              : ''
          }`;
        },
      },
      {
        Header: 'Customer Address',
        accessor: 'customer_address',
        placeholder: 'Customer Address',
        isVisible: columnStateSaveData?.customer_address ?? false,
        Cell: ({ value, row, column }) => {
          return `${row.original.order_transaction.customer_address}`;
        },
      },
      {
        Header: 'Recipient Address',
        accessor: 'recipient_address',
        placeholder: 'Recipient Address',
        isVisible: columnStateSaveData?.recipient_address ?? true,
        Cell: ({ value, row, column }) => {
          return `${row.original.order_recipients[0].recipient_address}`;
        },
      },
      {
        Header: 'Status',
        accessor: 'order_status',
        placeholder: 'Status',
        disableFilters: true,
        disableSortBy: true,
        isVisible: columnStateSaveData?.order_status ?? false,
      },
      {
        Header: 'Type',
        accessor: 'source',
        placeholder: 'Type',
        isVisible: columnStateSaveData?.source ?? false,
      },
      {
        Header: 'External Order Number',
        accessor: 'external_order_number',
        placeholder: 'External Order Number',
        isVisible: columnStateSaveData?.external_order_number ?? false,
      },
      {
        Header: 'Actions',
        accessor: 'id',
        isVisible: columnStateSaveData?.id ?? true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {viewOrderAccess && viewOrderAccess.permissions.read_access ? (
                <img
                  className={`cursor-pointer view-icon`}
                  src={ViewIcon}
                  alt="View Order"
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push(`/admin/orders/view/${row.original.id}`);
                  }}
                />
              ) : (
                <span style={{ width: 34, height: 34, display: 'block' }} />
              )}
              {row?.original?.old_order_number === 0 && (
                <img
                  className={`cursor-pointer mr-2`}
                  src={EditIcon}
                  alt="Edit Order"
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push(`/admin/orders/edit/${row.original.id}`);
                  }}
                />
              )}
            </div>
          );
        },
      },
    ],
    [columnStateSaveData]
  );
  if (firstRender) return <LoadingScreen />;
  return (
    <React.Fragment>
      <Header title={`Process Order (${totalCount})`} />

      <div id="MainContent" className="main-content">
        <DefaultOrderFilter
          type={type}
          setType={setType}
          fromDate={fromDate}
          setFromDate={setFromDate}
          handleFromDate={handleFromDate}
          toDate={toDate}
          setToDate={setToDate}
          handleToDate={handleToDate}
          // fetchData={fetchData}
          fetchData={(data) => {
            fetchData({
              ...defaultFilter,
              ...data,
              latestData: true,
            });
            setIsCustomSearch(true);
          }}
          handleClearSearch={handleClearSearch}
          defaultFilter={defaultFilter}
          defaultSetCheckAllStore={checkAllStore}
          checkAllStore={(data)=>{setCheckAllStore(data)}}        
        />
        <ReactTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={'There is no data to display'}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
          displayColumnVisibility={true}
          isCustomSearch={isCustomSearch}
          selectedColumn={selectedColumn}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Orders);
