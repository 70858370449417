import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
// // Components
import Header from '../../../Components/Header/Header';
import BurqOrder from './BurqOrder';
import BurqManualOrder from './BurqManualOrder';
// // Context
import { getAccessByModuleName } from '../../../Routes/Routes';
import { AppContext } from '../../../Context/AppContext';
import { MODULES } from '../../../Helpers/Constants';

const Burq = (props) => {
  const { showToastMessage, modules } = useContext(AppContext);

  const [access, setAccess] = useState({});

  // /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.ONDEMAND);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        'You are not authorized to access.',
        'error',
        false
      );
      props.history.push(`/users`);
    }
  }

  // // Handle Tab Button Click
  const [tabId, setTabId] = React.useState(0);
  const handleTabChange = (event, newTabId) => {
    setTabId(newTabId);
  };

  const handleManageHeaderClick = async (event) => {
    event.preventDefault();
    window.open('https://dashboard.burqup.com/', '_blank');
  };

  return (
    <React.Fragment>
       <Header
        title="On-Demand"
        hasBurqOrderButton={true}
        handleBurqOrderClick={handleManageHeaderClick}
      />

     <div id="MainContent" className="main-content">
        <div className="container-fluid order-container">
          <Tabs value={tabId} onChange={handleTabChange} className="mb-4">
            <Tab key={0} id={0} label={`On-Demand Order`} />
            <Tab key={1} id={1} label={`Manual Order`} />
          </Tabs>

          <TabPanel tabId={tabId} index={0}>
            <BurqOrder />
          </TabPanel>

          <TabPanel tabId={tabId} index={1}>
            <BurqManualOrder />
          </TabPanel>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Burq);

function TabPanel(props) {
  const { children, tabId, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabId !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabId === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
