import OrderError from "Components/OrderError/OrderError";
import React, { useContext, useEffect } from "react";
import { useMemo } from "react";
import { useOrderAttributeProvider } from "../context/order";
import OrderErrorModel from "./OrderErrorModel";
import OverrideEditOrderModal from "./OverrideEditOrderModal";
import { useProcessingOrder } from "../hooks/useProcessingOrder";
import { AppContext } from "Context/AppContext";
import { useHistory } from "react-router";

const Validation = () => {
  const { orderDetail, modal, setModal } = useOrderAttributeProvider();
  const { lastOrderEditPath } = useContext(AppContext);
  const { overRideEditOrder } = useProcessingOrder();
  let history = useHistory();

  useEffect(() => {
    if (orderDetail) {
      if (!orderDetail?.editFlag) {
        setModal("override-order-edit");
      }
    }
  }, [orderDetail]);

  // console.log("orderDetail=>",orderDetail);
  let errors = useMemo(() => {
    let mainErrors =
      orderDetail?.order_validations?.map((o) => {
        return { error: o.validation_message };
      }) ?? [];
    let nolocalError =
      orderDetail?.is_local_order !== 1 && !orderDetail?.order_type
        ? [{ error: "Not a local order" }]
        : [];
    return { mainErrors, nolocalError };
  }, [orderDetail]);

  const cancleClickPopup = async () => {
    setModal("");
    history.push(lastOrderEditPath);
  };

  const proceedClickPopup = async () => {
    overRideEditOrder();
    setModal("");
    // window.location.reload();
  };

  return (
    <div>
      {(errors.mainErrors.length !== 0 || errors.nolocalError.length !== 0) && (
        <OrderError errors={[...errors.mainErrors, ...errors.nolocalError]} />
      )}
      <OrderErrorModel
        showModal={modal === "override"}
        pageTitle="Are you sure you want to override the below
          validation(s) and process this order?"
        handleCancelClick={(e) => {
          setModal("");
        }}
        handleSubmitClick={(e) => {
          setModal("approve-validation");
        }}
        errorData={errors.mainErrors}
      />

      <OverrideEditOrderModal
        showModal={modal === "override-order-edit"}
        handleCancelClick={() => cancleClickPopup()}
        handleSubmitClick={proceedClickPopup}
        pageTitle="Override Edit Order"
        message={orderDetail?.editOrderMessage}
      />
    </div>
  );
};

export default Validation;
