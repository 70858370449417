import { callGetApiv2, callPostApiv2, callPutApiv2 } from "./api";

export async function getFeaturedProduct(id, moduleName) {
    return callGetApiv2(`featured-product/${moduleName}/${id}/${false}`);
}

export async function uploadImage(data) {
    return callPostApiv2('utils/upload-image', data);
}

export async function updateStatus(postData) {
    return callPutApiv2("utils/update-status/", postData);
}