export const orderState = {
  editMode: false,
  activeRecipentIndex: 0,
  custom_order: 0, // 0 = normal, 1 = custom_price_order
  parent_order_id: null,
  order_type_identifier: 0, // 0 = normal, 1 = replace, 2 = duplicate
  recipents: [
    {
      form: {
        recipients_id: 0,
        whats_the_occasion: "",
        whats_the_occasion_id: null,
        first_name: "",
        last_name: "",
        greetings_on_card: "",
        card_signature: "",
        completed: false,
      },
      added_product: [],
      addedAddons: [],
    },
  ],
  isVerifiedCustomer: false,
  formInfo: {
    order_id: 0,
    address_type: "",
    address_type_id: null,
    attn: "",
    address_line_1: "",
    address_line_2: "",
    phone: "",
    alt_phone: "",
    city: "",
    state: "",
    zipcode: "",
    country: "usa",
    is_local: false,
    latitude: "",
    longitude: "",
    completed: false,
  },
  leadTime: 0,
  order: {
    frequency: 0,
    parent_order_id: 0,
    is_subscribe_order: false,
    csr_id: 0,
    order_type: 0,
    source: "website",
    designer_id: 0,
    actual_delivered_time: "",
    recipient_signature_or_picture: "",
    driver_instruction: "",
    delivery_date: "",
    delivery_type: "",
    delivered_time_id: null,
    delivered_time: "",
    custom_delivery_time: "",
    custom_delivery_time_before_after: "",
    custom_delivery_time_amount: 0,
    shopcode: "",
    external_shop_name: "",
    external_phone_number: "",
    external_person_name: "",
    external_order_number: "",
    designer_instruction: "",
    total_qty: "",
    sub_total: 0,
    promocode_type: "",
    promocode_id: 0,
    promocode: "",
    created_by: 1,
    discount_percentage: 0,
    discount_price: 0,
    upcharge_id: 0,
    upcharge_amount: 0,
    delivery_charges: 0,
    custom_delivery_time_ampm: "",
    custom_reason_for_discount: "",
    earned_petal_rewards_point: 0,
    flower_total_amount: 0,
    gift_card_amount: 0,
    gift_card_id: 0,
    gift_card_detail: "",
    internal_notes: "",
    is_fraud_order: 0,
    is_local_order: 0,
    order_status: "",
    petal_rewards_earn_amount: 0,
    petal_rewards_id: 0,
    petal_rewards_point_amount: 0,
    petal_rewards_point_applied: 0,
    tax: 0,
    total: 0,
    tax_id: "",
    total_commission_amount: 0,
    add_fund: 0,
    refund: 0,
    burq_quote_id:"",
    burq_quote_expires:""
  },

  order_transaction: {
    customer_address_id: 0,
    total_qty: 0,
    sub_total: 0,
    promocode: "",
    promocode_id: 0,
    discount_price: 0,
    delivery_charges: 0,
    upcharge_amount: 0,
    tax: 0,
    tax_id: "",
    flower_total_amount: 0,
    petal_rewards_id: 0,
    petal_rewards_point_amount: 0,
    petal_rewards_point_applied: 0,
    total: 0,
    total_commission_amount: 0,
    gift_card_amount: 0,
    gift_card_detail: "",
    gift_card_id: "",
    order_from: 0,
    customer_id: 0,
    promocode_type: "",
    earned_petal_rewards_point: 0,
  },

  customer_info: {
    customer_first_name: "",
    customer_last_name: "",
    customer_email: "",
    customer_phone: "",
    customer_alt_phone: "",
    customer_id: 0,
    is_house_account: 0,
    is_business_account: 0,
    completed: false,
  },
  billing_data: {
    customer_billing_first_name: "",
    customer_billing_last_name: "",
    customer_billing_address: "",
    customer_billing_city: "",
    customer_billing_state: "",
    customer_billing_country: "United States",
    customer_billing_zipcode: "",
    customer_id: 0,
    completed: false,
  },
  currentSelectedCard: null,
  delivery_type_id: 0,
  not_available_at_home_key: "",
  not_available_at_home_option: "",
  is_local_available: false,
  card_list: [],
  order_payment_transaction: [
    {
      transaction_type: "",
      type: "",
      card_id: 0,
      card_last_4_digits: "",
      paymentMethodNonce: "",
      total_amount: 0,
      description: "",
      is_subscription_paymeny: 0,
      card_type: "",
    },
  ],
  order_card_list: [],
  frequencyList: [],
  petalrewardlist: [],
  blockoutdate: [],
  upCharge: [],
  customererror: false,
  deliveryMatrixFilled: false,
  recipentscommanError: false,
  recipentsError: false,
  orderCardCompletionStatus: false,
  paymetTypeSelect: "",
  holidayCutOff: [],
  biilingAddressError: false,
};

export const newRec = {
  form: {
    whats_the_occasion: "",
    whats_the_occasion_id: null,
    first_name: "",
    last_name: "",
    greetings_on_card: "",
    completed: false,
  },
  // isVerifiedRecipient: 0,
  added_product: [],
  addedAddons: [],
  flower_total: 0,
  tax: 0,
  delivery_charge: 0,
  subtotal: 0,
  total: 0,
};
export const OccationList = {
  data: [],
};
