import CheckBox from "Components/FormControl/CheckBox/CheckBox";
import RadioBox from "Components/FormControl/RadioBox/RadioBox";
import { isBeforetwelveandsix } from "Helpers/date";
import { setAllStore } from "Helpers/setAllStore";
import { DateTime } from "luxon";
import moment from "moment";
import { PST_ZONE } from "Pages/Order/Order/utility/util";
import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router";
import { getAddressTypeList } from "../../../api/addressType";
import { getCancelDetails } from "../../../api/report";
import CalendarIcon from "../../../Assets/images/calendar.svg";
import CloseIcon from "../../../Assets/images/close.svg";
// import DefaultImage from "../../../Assets/images/default-signature.svg";
import ViewIcon from "../../../Assets/images/error-outline.svg";
import SearchIcon from "../../../Assets/images/search.svg";
import Date from "../../../Components/FormControl/Date/Date";
import Select from "../../../Components/FormControl/Select/Select";
import ReactTable from "../../../Components/Table/Table";
import { AppContext } from "../../../Context/AppContext";
import { MODULES } from "../../../Helpers/Constants";
// Context
import { getAccessByModuleName } from "../../../Routes/Routes";
import ExportIcon from "./../../../Assets/images/export.svg";
// Components
import Header from "./../../../Components/Header/Header";
import styles from "./index.module.scss";

const CancelOrders = (props) => {
  const { showToastMessage, modules } = useContext(AppContext);
  const [buildingList, setBuildingList] = React.useState([]);
  const [type, setType] = useState("delivery_date");
  let accessExport = getAccessByModuleName(
    modules,
    MODULES.CANCEL_ORDER_REPORT
  );

  // const [buildingList, setBuildingList] = useState([
  //   { id: "", title: "All" },
  //   { id: "house", title: "House" },
  //   { id: "apartment", title: "Apartment" },
  //   { id: "business", title: "Business" },
  //   { id: "funeral_home", title: "Funeral home" },
  //   { id: "church", title: "Church" },
  //   { id: "hospital", title: "Hospital" },
  //   { id: "restaurant", title: "Restaurant" },
  //   { id: "hotel", title: "Hotel" },
  //   { id: "university", title: "University" },
  //   { id: "other_school ", title: "Other school " },
  // ]);

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const reference = React.useRef(null);

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  const [fromDate, setFromDate] = useState(
    moment().startOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [toDate, setToDate] = useState(
    moment().endOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [selectedPeriod, setSelectedPeriod] = React.useState("weekly");
  const [selectedPeriodLabel, setSelectedPeriodLabel] = React.useState(
    "By Week"
  );

  const [firstTimePageLoad, setFirstTimePageLoad] = React.useState(false);

  const [exportData, setExportData] = React.useState([]);
  const [exportHeader, setExportHeader] = React.useState([]);

  const [checkAllStore,setCheckAllStore] = useState(true);

  const getAddressType = async () => {
    let response = await getAddressTypeList();
    if (response && response.status === 200) {
      let addressTypeArr = [];
      response.data.data.rows?.forEach((d, i) => {
        let tempArr = {
          id: d.name,
          title: d.name,
        };
        addressTypeArr.push(tempArr);
      });
      setBuildingList(addressTypeArr);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  useEffect(() => {
    getAddressType();
  }, []);

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
  }) => {

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);

    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    // if (accessExport && accessExport.permissions.write_access)
    //   handleExportCancelOrders(sortBy, filters, fromDateDefault, toDateDefault);

    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      date_type: type,
      filters,
      sortBy,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
    };

    if (!firstTimePageLoad) {
      const response = await getCancelDetails(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count.length / pageSize));
      } else if (response.status === 403) {
        showToastMessage(undefined, response.data.message, "error", false);
        props.history.push("/profile");
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    } else {
      setFirstTimePageLoad(false);
    }
    setLoading(false);

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  const handleExportCancelOrders = async (
    sortBy,
    filters,
    fromDateDefault,
    toDateDefault
  ) => {

/*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);

    let postData = {
      // currentPage: event.pageIndex,
      // itemsPerPage: event.pageSize,
      // globalSearch: event.globalFilter,
      isExport: true,
      filters,
      sortBy,
      date_type: type,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
    };

    const response = await getCancelDetails(postData);

    if (response && response.status === 200) {
      setExportHeader(response.data.data.header);
      setExportData(response.data.data.data);
      reference.current?.link?.click();
    } else if (response.status === 403) {
      showToastMessage(undefined, response.data.message, "error", false);
      props.history.push("/profile");
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  const handleClearSearch = async (event) => {
    event.preventDefault();

    setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
    setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
    setSelectedPeriod("weekly");
    fetchData({
      ...defaultFilter,
      fromDateDefault: moment().startOf("isoWeek").format("ddd MMM DD, YYYY"),
      toDateDefault: moment().endOf("isoWeek").format("ddd MMM DD, YYYY"),
    });
    // handleExportCancelOrders(event);
  };

  const handleSelectedPeriod = async (id) => {
    if (id === "weekly") {
      setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Week");
    } else if (id === "monthly") {
      setFromDate(moment().startOf("month").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("month").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Month");
    } else if (id === "yearly") {
      setFromDate(moment().startOf("year").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("year").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Year");
    }
  };

  const handleFromDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setToDate(moment(data).add(7, "days").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "monthly") {
      setToDate(moment(data).add(1, "months").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "yearly") {
      setToDate(moment(data).add(1, "years").utc().format("ddd MMM DD, YYYY"));
    }
  };

  const handleToDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setFromDate(
        moment(data).subtract(7, "days").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "monthly") {
      setFromDate(
        moment(data).subtract(1, "months").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "yearly") {
      setFromDate(
        moment(data).subtract(1, "years").utc().format("ddd MMM DD, YYYY")
      );
    }
  };

  let viewOrderAccess = getAccessByModuleName(modules, MODULES.VIEW_ORDER);
  const columns = React.useMemo(
    () => [
      {
        Header: "Order No",
        accessor: "order_number",
        isVisible: true,
        placeholder: "Order No",
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        isVisible: true,
        placeholder: "Customer Name",
        Cell: ({ value, row, column }) => {
          return `${row.original.order_transaction.customer_name}`;
        },
      },
      {
        Header: "Recipient Name",
        accessor: "recipient_name",
        isVisible: true,
        placeholder: "Recipient Name",
        Cell: ({ value, row, column }) => {
          return `${row.original.order_recipients[0].recipient_name}`;
        },
      },
      {
        Header: "Order Placed Date",
        accessor: "createdAt",
        isVisible: true,
        placeholder: "Search By Order Placed Date",
        Cell: ({ value, row, column }) => {
          return `${
            row.original.delivery_date && row.original.delivery_date !== null
              ? DateTime.fromISO(row.original.delivery_date)
                  .setZone(PST_ZONE)
                  .toFormat("ccc LLL dd, yyyy")
              : ""
          }`;
        },
      },
      {
        Header: "Delivery Date",
        accessor: "delivery_date_time",
        isVisible: true,
        placeholder: "Search By Delivery Date",
        Cell: ({ value, row, column }) => {
          return `${
            row.original.delivery_date_time &&
            row.original.delivery_date_time !== null
              ? DateTime.fromISO(row.original.delivery_date_time)
                  .setZone(PST_ZONE)
                  .toFormat("ccc LLL dd, yyyy")
              : ""
          }`;
        },
      },
      {
        Header: "Building Type",
        accessor: "building_type",
        isVisible: true,
        placeholder: "Status",
        isSelectFilter: true,
        selectFilterOption: buildingList,
        colWidth: 160,
        Cell: ({ value, row, column }) => {
          return `${row.original.order_recipients[0].building_type}`;
        },
      },
      {
        Header: "Price",
        accessor: "total",
        isVisible: true,
        placeholder: "Price",
        Cell: ({ value, row, column }) => {
          return `$${row.original.total}`;
        },
      },
      {
        Header: "Products",
        accessor: "products",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          let time = isBeforetwelveandsix(row.original.delivery_date_time);
          let threeMark = (
            <span
              className={` ${styles.ProducatBadge} ${styles.ProducatBadgeRed}`}
            >
              !!!
            </span>
          );

          let oneMark = (
            <span
              className={` ${styles.ProducatBadge} ${styles.ProducatBadgeGreen}`}
            >
              !
            </span>
          );
          let img = row.original.order_products.find((c) => !c.product_type);
          return (
            <div className={` ${styles.ProducatImageWapper}`}>
              <img
                src={
                  img?.variation_image === "" ||
                  img?.variation_image === "null" ||
                  img?.variation_image === null
                    ? ""
                    : `${img.variation_image}`
                }
                alt=""
              />
              {time.beforTwelve && threeMark}
              {!time.beforTwelve && time.beforSix && oneMark}
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {viewOrderAccess && viewOrderAccess.permissions.read_access ? (
                <img
                  className={`cursor-pointer mr-2 view-icon`}
                  src={ViewIcon}
                  alt="Cancel Order"
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push(`/admin/orders/view/${row.original.id}`);
                  }}
                />
              ) : (
                <span style={{ width: 34, height: 34, display: "block" }} />
              )}
            </div>
          );
        },
      },
    ],
    [buildingList]
  );

  return (
    <React.Fragment>
      <Header title="Cancelled Orders Report" />

      <div id="MainContent" className="main-content">
        <div style={{ float: "left", width: "100%" }}>
          <div className={"row "}>
            <div className="col-12 d-flex align-items-center">
              <span
                className="mb-0 mt-2 pb-0 d-inline-bock mr-3"
                style={{ lineHeight: 1 }}
              >
                Filter By
              </span>
              <RadioBox
                selectedValue={type}
                onChange={() => {
                  if (setType) {
                    setType("order_date");
                  }
                }}
                label="Order date"
                value="order_date"
              />
              <RadioBox
                selectedValue={type}
                onChange={() => {
                  if (setType) {
                    setType("delivery_date");
                  }
                }}
                label="Delivery date"
                value="delivery_date"
              />
            </div>

            <div className="col-sm-2 col-xs-12">
              <Select
                label={selectedPeriodLabel}
                value={selectedPeriod}
                onChange={(data) => {
                  setSelectedPeriod(data);
                  handleSelectedPeriod(data);
                }}
                options={[
                  { id: "weekly", title: "Weekly" },
                  { id: "monthly", title: "Monthly" },
                  { id: "yearly", title: "Yearly" },
                  { id: "custom", title: "Custom" },
                ]}
                disabled={false}
              />
            </div>
            <div className="col-sm-3 col-xs-12">
              <Date
                label="Filter From"
                value={fromDate}
                dateFormat="ddd MMM DD, YYYY"
                type="date"
                fieldIcon={CalendarIcon}
                onChange={(fromDate) => {
                  setFromDate(moment(fromDate).format("ddd MMM DD, YYYY"));
                  handleFromDate(fromDate);
                }}
              />
            </div>
            <div className="col-sm-3 col-xs-12">
              <Date
                label="Filter To "
                value={toDate}
                dateFormat="ddd MMM DD, YYYY"
                type="date"
                fieldIcon={CalendarIcon}
                onChange={(toDate) => {
                  setToDate(moment(toDate).format("ddd MMM DD, YYYY"));
                  handleToDate(toDate);
                }}
              />
            </div>
            <div className="col-sm-2 col-xs-12">
              <CheckBox
              value={checkAllStore}
              label="All Stores"
              onChange={(data)=>{setCheckAllStore(data)}}
              />
            </div>
            <div className="col-sm-2 col-xs-12">
              <div className="td-actions pt-4">
                <span
                  onClick={(e) => fetchData(defaultFilter)}
                  className={`link cursor-pointer ${styles.searchIconWrapper}`}
                >
                  <img className={styles.searchIcon} src={SearchIcon} alt="" />
                </span>
                <span
                  onClick={(e) => handleClearSearch(e)}
                  className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
                >
                  <img className={styles.clearIcon} src={CloseIcon} alt="" />
                </span>
                {exportData && exportData.length > 0 && (
                  <CSVLink
                    ref={reference}
                    style={{
                      display: "none",
                      float: "right",
                      marginTop: "25px",
                    }}
                    data={exportData}
                    headers={exportHeader}
                    filename={"CancelOrderReportLookup.csv"}
                    className={`link cursor-pointer ml-3 ${styles.searchIconWrapper}`}
                  >
                    DownLoad
                  </CSVLink>
                )}
                {accessExport && accessExport.permissions.write_access && (
                  <span
                    onClick={(e) => handleExportCancelOrders(defaultFilter)}
                    className={`link cursor-pointer ${styles.searchIconWrapper}`}
                    style={{ float: "right" }}
                  >
                    <img
                      className={styles.searchIcon}
                      src={ExportIcon}
                      alt=""
                    />
                  </span>
                )}
              </div>
            </div>
          </div>

          <ReactTable
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            noDataText={"There is no data to display"}
            defaultFilter={defaultFilter}
            displayGlobalSearch={false}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CancelOrders);
