import React, { useState } from "react";
import styles from "./index.module.scss";
import Text from "../FormControl/Text/TextInput";
// import AddIcon from "../../Assets/images/add.svg";
// import Arrow from "../../Assets/images/arrow.svg";
// import Products from "../Products/Products";
import CloseIcon from "../../Assets/images/close-icon.svg";
import SeoData from "../../Components/Modal/Location/SeoData";
import Select from "../../Components/FormControl/Select/Select";

import { useLocation } from "../../Context/Cms-Locations/useLocation";

const RelatedCategoryForm = (props) => {
  // const [showFeaturedProduct, setShowFeaturedProduct] = useState(false);
  // let handleSubmit = () => {
  //   console.log("handleSubmit");
  // };
  const [showAddModal, setShowAddModal] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const { locationState, setSeoDataIndex } = useLocation();

  const handleManageCategorySeo = async (data) => {
    // console.log(data);
    setSeoDataIndex(data, props.index);
    setShowAddModal(false);
  };

  const handleAddSeoClick = async () => {
    setShowAddModal(true);
  };

  const handleSetSelectedCategoryData = (SelectedData) => {
    // let duplicateData = [];
    let duplicateData = locationState.cms_location_categories?.filter(
      (data) => {
        if (data.category_hierarchy_id === SelectedData) {
          return data;
        }
      }
    );

    if (duplicateData && duplicateData.length > 0) {
      setErrorMessage("This Category is already added");
    } else {
      setErrorMessage("");
      props.setSelectedData(SelectedData, props.index);
    }
  };

  return (
    <React.Fragment>
      <form>
        <div className={`${styles.cardBody}`}>
          <div className={`card-body ${styles.featuredCard}`}>
            <div className={`row`}>
              <div className={`col-md-6 col-xs-12`}>
                <Select
                  label="Type"
                  parentClass="text-center"
                  value={props.selectedData.category_hierarchy_id}
                  required={false}
                  onChange={(data) => {
                    if (data !== "") handleSetSelectedCategoryData(data);
                  }}
                  options={props.categoryList}
                  disabled={false}
                  id="categorySearching"
                />
              </div>
              <div className={`col-md-2 col-xs-12`}>
                {/* <a
                  href="/"
                  className={`link cursor-pointer ml-2 mt-1 ${styles.searchIconWrapper}`}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <img className={styles.searchIcon} src={AddIcon} alt="Add" />
                </a> */}
              </div>

              <div className={`col-md-2 col-xs-12`}>
                <span
                  className={`cursor-pointer ml-2 ${styles.editSeo}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddSeoClick();
                  }}
                >
                  Edit SEO
                </span>
              </div>

              <div className={`col-md-1 col-xs-12`}>
                <span
                  className={`cursor-pointer ${styles.closeIconWrapper}`}
                  onClick={(e) => {
                    e.preventDefault();
                    props.deleteCategory(props.index);
                  }}
                >
                  <img className={styles.closeIcon} src={CloseIcon} alt="Add" />
                </span>
              </div>

              {/* <div className={`col-md-1 col-xs-12  ${styles.featuredItem}`}>
                <a
                  href="/"
                  className={`link cursor-pointer ml-2 mt-1`}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {showFeaturedProduct ? (
                    <img
                      className={styles.toggalIconDown}
                      src={Arrow}
                      alt="Add"
                    />
                  ) : (
                    <img
                      className={styles.toggalIconUp}
                      src={Arrow}
                      alt="Add"
                    />
                  )}
                </a>
              </div> */}
            </div>
            <div className={`row`}>
              <p className={styles.paragraphBackground}>{errorMessage}</p>
            </div>
            {/* <Products
              isVarianNeeded={false}
              productList={props.productList}
              removeProductList={(data) => {
                props.removeProductList(data);
              }}
              addProductList={(data) => {
                props.addProductList(data);
              }}
            /> */}
          </div>
        </div>
      </form>

      <SeoData
        showModal={showAddModal}
        pageTitle="Add FAQs"
        handleCancelClick={(e) => {
          setShowAddModal(false);
        }}
        submitStatus={submitStatus}
        handleSubmitClick={handleManageCategorySeo}
        seoFinalData={props.selectedData.seo}
        
      />
    </React.Fragment>
  );
};
export default RelatedCategoryForm;
