import React, { useReducer } from "react";
import CouponReducer from "./couponReducer";
import { couponState } from "./couponState";

export const CouponContext = React.createContext({
  couponState: couponState,
  dispatch: () => null,
});

const CouponProviderCurd = (props) => {
  const [state, dispatch] = useReducer(CouponReducer, couponState);
  return (
    <CouponContext.Provider value={{ couponState: state, dispatch }}>
      {props.children}
    </CouponContext.Provider>
  );
};

export default CouponProviderCurd;
