export const CategoryAction = {
  ADD_NEW_BANNER: "ADD_NEW_BANNER",
  REMOVE_BANNER: "REMOVE_BANNER",
  UPDATE_BANNER_DATA: "UPDATE_BANNER_DATA",
  CHECK_BANNER_DATA: "CHECK_BANNER_DATA",
  SET_SEO_DATA: "SET_SEO_DATA",
  CHECK_SEO_DATA: "CHECK_SEO_DATA",
  UPDATE_OG_INFO_DATA: "UPDATE_OG_INFO_DATA",
  CHECK_OG_INFO_DATA: "CHECK_OG_INFO_DATA",
  UPDATE_TWITTER_INFO_DATA: "UPDATE_TWITTER_INFO_DATA",
  CHECK_TWITTER_INFO_DATA: "CHECK_TWITTER_INFO_DATA",
  UPDATE_SCHEMA_DATA: "UPDATE_SCHEMA_DATA",
  CHECK_SCHEMA_DATA: "CHECK_SCHEMA_DATA",
  SET_CATEGORY_NAVIGATION_INFO: "SET_CATEGORY_NAVIGATION_INFO",
  CHECK_CATEGORY_NAVIGATION_INFO: "CHECK_CATEGORY_NAVIGATION_INFO",
  CLEAR_CATEGORY_DATA: "CLEAR_CATEGORY_DATA",
  SET_ALL_CATEGORY_DATA: "SET_ALL_CATEGORY_DATA",
  SET_CATEGORY_BANNER_DATA: "SET_CATEGORY_BANNER_DATA",
  SET_CATEGORY_HIERARCHY_ID: "SET_CATEGORY_HIERARCHY_ID",
  SET_CATEGORY_HIERARCHY_PARENT_ID: "SET_CATEGORY_HIERARCHY_PARENT_ID",
  SET_CATEGORY_LIST_DATA: "SET_CATEGORY_LIST_DATA",
  SET_CATEGORY_FAQ_DATA: "SET_CATEGORY_FAQ_DATA",
  REMOVE_FAQ:"REMOVE_FAQ",
  UPDATE_FAQ_DATA:"UPDATE_FAQ_DATA",
};
