export const couponState = {
      coupon_code: "",
      coupon_type: "Percentage",
      coupon_value: "",
      // coupon_apply_to: "Specific Collection",
      coupon_apply_to: "Specific Product",
      coupon_redeem_for_mobile: false,
      coupons_specific_collections: [],
      usage_limit_one_per_user: false,
      usage_limit_number_of_time:null,
      active_start_date: "",
      active_end_date: "",
      section_info: {
            category_section: false,
            product_section: false,
            order_section: false,
      },
};
