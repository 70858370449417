import React from "react";
import styles from "./index.module.scss";
import AddIcon from "../../Assets/images/add.svg";
import BannerForm from "./BannerForm";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
export default function Banner(props) {
  const bannersData = props.bannersData ? props.bannersData : [];
  const [open, setOpen] = React.useState(true);

  const handleAddBanner = async () => {
    if (props.addNewBanner) {
      await props.addNewBanner();
      await handleScrollFunction();
    }
  };
  const handleRemoveBanner = (event, remove_banner_id) => {
    event.stopPropagation();
    props.removeBanner(remove_banner_id);
  };

  const handleScrollFunction = async () => {
    if (bannersData.length > 0) {
      const titleElement = await document.getElementById(
        "home_banner_" + (bannersData.length + 1)
      );
      if (titleElement) {
        titleElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <div className={`mb-4`} id={`banner-section`}>
      <Accordion style={{ boxShadow: "none" }} expanded={open}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={() => setOpen(!open)} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <AccordionDetails>
            <Typography>
              <div className={styles.title}>Banner</div>
              {props.setPageForView === 1 ? (
                ""
              ) : (
                <a
                  href="/"
                  className={`link cursor-pointer ml-2 mt-1 ${styles.searchIconWrapper}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddBanner();
                  }}
                >
                  <img className={styles.searchIcon} src={AddIcon} alt="Add" />
                </a>
              )}
            </Typography>
          </AccordionDetails>
        </AccordionSummary>

        <Typography>
          {bannersData &&
            bannersData?.map((banner, index) => {
              let bannerCount = index + 1;
              return (
                <div
                  className={`md-2 ${styles.banners}`}
                  id={`home_banner_${bannerCount}`}
                  key={index}
                >
                  <div className={styles.circle}>{bannerCount}</div>
                  <div style={{ width: "100%" }}>
                    <BannerForm
                      bannerModule={
                        props.bannerModule ? props.bannerModule : ""
                      }
                      bannerData={banner}
                      removeBanner={handleRemoveBanner}
                      index={index}
                      setBannerData={props.setBannerData}
                      checkBannerData={props.checkBannerData}
                      uploadImageType={
                        props.uploadImageType ? props.uploadImageType : ""
                      }
                    />
                  </div>
                </div>
              );
            })}
        </Typography>
      </Accordion>
    </div>
  );
}
