export const categoryState = {
      seo: {
            id: null,
            parent_id: 0,
            module: "category-hierarchy",
            is_robot: false,
            is_no_follow: false,
            is_no_index: false,
            image_alt_text: "",
            page_title: "",
            meta_title: "",
            meta_description: "",
            canonical_url: "",
            og_title: "",
            og_description: "",
            og_image_url: "",
            og_url: "",
            og_image_alt: " ",
            og_image_type: "",
            og_image_width: 0,
            og_image_height: 0,
            og_site_name: "",
            twitter_title: "",
            twitter_description: "",
            twitter_image_url: "",
            twitter_site: "",
            twitter_creator: ""
      },
      banners: [
            // {
            //       id: 0,
            //       module: "category",
            //       position: "LEFT",
            //       banner_text: "",
            //       button_label: "",
            //       button_url: "",
            //       description: "",
            //       image: "",
            //       is_supported_image: 1,
            //       show_oval: 0
            // }
      ],
      setCategoryAllData: "",
      categoryPrimaryError: false,
      category_primary_info: {
            type: null,
            inventory_remaining: 0,
            title: "",
            sequence_number: 0,
            status: "",
            keywords: "",
            customer_note: "",
            description: "",
            show_certification: false,
            category_image: "",
            is_filter: false,
      },
      categoryNavigationError: false,
      category_navigation_info: {
            slug: "",
            blurb: "",
      },
      seoError: false,
      ogInfoError: false,
      twitterError: false,
      schemaError: false,
      categoryHierarchyId:null,
      categoryHierarchyParentId:null,
      categoryListData:[],
};
