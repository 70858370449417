import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import ReactTable from "../Table/Table";
import ManageZipCodeModal from "./../../Components/Modal/Zipcode";
import ManageGroupZipCodeModel from "./../../Components/Modal/GroupZipCode";
import EditIcon from "./../../Assets/images/edit.svg";
import DeleteIcon from "./../../Assets/images/delete.svg";
import AddIcon from "../../Assets/images/add.svg";
import ActivatedIcon from "./../../Assets/images/activated.svg";
import FolderIcon from "../../Assets/images/Folder.svg";
import styles from "./index.module.scss";
import DeleteConfirmation from "./../../Components/Modal/DeleteConfirmation/DeleteConfirmation";
import StatusConfirmation from "./../../Components/Modal/StatusConfirmation/StatusConfirmation";
// Context
import { AppContext } from "./../../Context/AppContext";
import { getAccessByModuleName } from "./../../Routes/Routes";
import { MODULES, STATUS_FILTER } from "./../../Helpers/Constants";
// Services
// import SettingsApi from "./../../Services/Settings/Settings";
// import UtilsApi from "./../../Services/Utils/Utils";
import { updateStatus } from "./../../api/utils";
import {
  getFolderGroups,
  groupZipcodeUpdate,
  groupZipcodeAdd,
  zipcodeListByGroup,
  zipcodeUpdate,
  zipcodeAdd,
  zipcodeDeleteById,
} from "./../../api/settings";

const ZipcodeSettings = () => {
  //Api Calling Response Data
  const [pageCount, setPageCount] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [currentModal, setCurrentModal] = React.useState("");
  const [submitStatus, setSubmitStatus] = useState(false);
  const [access, setAccess] = useState({});
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);
  const statusList = STATUS_FILTER;

  // const settingsService = new SettingsApi();
  // const utilsService = new UtilsApi();

  // zipcode Group
  const [data, setData] = React.useState([]);
  const [group_name, setZipcodeGroupName] = React.useState(false);
  const [status, setZipcodeGroupStatus] = React.useState(false);
  const [currentZipcodeGroupId, setCurrentZipcodeGroupId] = React.useState(
    false
  );
  const [zipcodeGroupTitle, setManageZipcodeGroupTitle] = React.useState(false);
  const [zipcodeGroupDetails, setZipcodeGroupDetails] = useState({});

  // Zipcode
  const [zipcodeList, setZipcodeList] = useState({});
  const [showAddModalZipcode, setShowAddModalZipcode] = React.useState(false);
  const [zipcodeTitle, setManageZipcodeTitle] = React.useState(false);
  const [zipcodeDetails, setZipcodeDetails] = useState({});
  const [currentZipcodeId, setCurrentZipcodeId] = React.useState(false);
  const [zipcodeId, setZipcodeId] = useState("");
  const [zipcodeDeleteId, setDeleteZipcodeId] = useState("");
  const [zip_code, setZipcodeName] = useState("");
  const [zipcode_status, setZipcodeStatus] = React.useState(false);
  const [is_deliverable, setZipcodeIsdeliverable] = useState(false);
  const [defaultFilter, setDefaultFilter] = React.useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  const [statusZipcodeId, setStatusZipcodeId] = useState(false);
  const [statusOfZipcode, setStatusOfZipcode] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);

  useEffect(() => {
    getZipcodeGroupList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.CONFIG_STORE_SETTING);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      window.location.href = "/dashboard";
    }
  }

  const handleAddGroupZipCode = async (
    event,
    type = "add",
    zipcodeGroupDetails = {}
  ) => {
    event.preventDefault();
    // setZipcodeGroupDetails(zipcodeGroupDetails)
    setShowAddModal(true);
    setManageZipcodeGroupTitle("Create New Group");
  };

  const getZipcodeGroupList = async () => {
    let storeSettingAccess = getAccessByModuleName(
      modules,
      MODULES.CONFIG_STORE_SETTING
    );

    if (storeSettingAccess && storeSettingAccess.permissions.read_access) {
      // let response = await settingsService.getFolderGroups();
      let response = await getFolderGroups();
      if (response && response.status === 200) {
        // setData(response.data.data.rows);
        setZipcodeGroupDetails(response.data.data);
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }
    setIsLoading(false);
  };

  const handleManageGroupZipCode = async (event) => {
    event.preventDefault();
    setSubmitStatus(true);

    if (group_name && zipcodeGroupDetails) {
      setIsLoading(true);
      let reqBody = {
        group_name,
      };

      let response;
      if (zipcodeGroupDetails.id) {
        reqBody.id = zipcodeGroupDetails.id;

        // response = await settingsService.groupZipcodeUpdate(reqBody);
        response = await groupZipcodeUpdate(reqBody);
      } else response = await groupZipcodeAdd(reqBody);

      if (response && response.status === 200) {
        showToastMessage(undefined, response.data.message, "success", false);
        setSubmitStatus(false);
        setShowAddModal(false);
        getZipcodeGroupList();
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
      // setIsLoading(false);
    }
  };

  const handleSelectZipcodeGroup = async (event, id) => {
    event.preventDefault();
    if (id) {
      setCurrentZipcodeGroupId(id);
      handleGetZipcodeByGroup(defaultFilter, id);
    }
  };

  const handleGetZipcodeByGroup = async (
    { pageIndex, pageSize, sortBy, globalFilter, filters },
    groupId = ""
  ) => {
    // console.log("currentZipcodeGroupIddsaf",currentZipcodeGroupId);
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    let zipcodeGroupId = groupId;
    if (!zipcodeGroupId) {
      zipcodeGroupId = zipcodeGroupDetails[0].id;
      setCurrentZipcodeGroupId(zipcodeGroupId);
    }

    // setLoading(true);
    const reqBody = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
    };

    // setIsLoading(true);
    let storeSettingAccess = getAccessByModuleName(
      modules,
      MODULES.CONFIG_STORE_SETTING
    );

    if (storeSettingAccess && storeSettingAccess.permissions.read_access) {
      // const response = await settingsService.zipcodeListByGroup(
      //   reqBody,
      //   zipcodeGroupId
      // );
      const response = await zipcodeListByGroup(reqBody, zipcodeGroupId);

      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else showToastMessage(undefined, response.data.message, "error", false);
    }
    // setIsLoading(false);
    // setLoading(false);
  };

  const handleGetZipcodeByGroupTable = async (
    { pageIndex, pageSize, sortBy, globalFilter, filters },
    groupId = ""
  ) => {
    // console.log("currentZipcodeGroupIddsaf",currentZipcodeGroupId);
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    let zipcodeGroupId = currentZipcodeGroupId;
    if (!zipcodeGroupId) {
      zipcodeGroupId = zipcodeGroupDetails[0].id;
      setCurrentZipcodeGroupId(zipcodeGroupId);
    }

    // setLoading(true);
    const reqBody = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
    };

    // setIsLoading(true);
    let storeSettingAccess = getAccessByModuleName(
      modules,
      MODULES.CONFIG_STORE_SETTING
    );

    if (storeSettingAccess && storeSettingAccess.permissions.read_access) {
      // const response = await settingsService.zipcodeListByGroup(
      //   reqBody,
      //   zipcodeGroupId
      // );
      const response = await zipcodeListByGroup(reqBody, zipcodeGroupId);

      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else showToastMessage(undefined, response.data.message, "error", false);
    }
    // setIsLoading(false);
    // setLoading(false);
  };

  const handleAddZipCode = async (event, type = "add", zipcodeDetails = {}) => {
    event.preventDefault();
    setZipcodeDetails(zipcodeDetails);
    setCurrentZipcodeId("");
    setZipcodeName("");
    setZipcodeStatus("");
    setZipcodeIsdeliverable("");
    setShowAddModalZipcode(true);
    setCurrentModal("zip-code");
    setManageZipcodeTitle("Create New Zipcode");
  };

  const handleManageZipcodeClick = async (
    event,
    type = "add",
    zipcodeObj = {}
  ) => {
    event.preventDefault();
    setZipcodeDetails(zipcodeObj);
    setShowAddModalZipcode(true);
    setManageZipcodeTitle("Create Zipcode");
    if (type === "edit") {
      setCurrentModal("zip-code");
      setManageZipcodeTitle("Edit Zipcode");
      setCurrentZipcodeId(zipcodeObj.id);
      // setZipcodeId(zipcodeObj.id);
      setZipcodeName(zipcodeObj.zip_code);
      setZipcodeStatus(zipcodeObj.status);
      setZipcodeIsdeliverable(zipcodeObj.is_deliverable);
    }
  };

  const handleManageZipCodeByGroup = async (event) => {
    let val = Number(zip_code);
    if (zip_code && zip_code <= 0) {
      showToastMessage(
        undefined,
        "Please enter valid positive values only in zip code",
        "error",
        false
      );
      event.preventDefault();
    } else if ((zip_code && zip_code.length < 5) || zip_code.length > 5) {
      showToastMessage(
        undefined,
        "Please enter valid zip code with 5 digit length.",
        "error",
        false
      );
      event.preventDefault();
    } else if (zip_code && _.isInteger(val) == false) {
      showToastMessage(
        undefined,
        "Please enter valid integer number",
        "error",
        false
      );
      event.preventDefault();
    } else {
      event.preventDefault();
      setSubmitStatus(true);
      if (
        zip_code &&
        currentZipcodeGroupId &&
        (status == true || status == false)
      ) {
        setIsLoading(true);
        let reqBody = {
          zip_code,
          folder_id: currentZipcodeGroupId,
        };
        let response;
        if (currentZipcodeId) {
          reqBody.id = currentZipcodeId;
          reqBody.is_deliverable = is_deliverable;
          reqBody.status = zipcode_status;
          // response = await settingsService.zipcodeUpdate(reqBody);
          response = await zipcodeUpdate(reqBody);
        } else response = await zipcodeAdd(reqBody);

        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          setSubmitStatus(false);
          setShowAddModalZipcode(false);
          handleGetZipcodeByGroup(defaultFilter, currentZipcodeGroupId);

          setZipcodeName(null);
          setZipcodeId(null);
          setZipcodeIsdeliverable(null);
          setZipcodeStatus(null);
        } else {
          showToastMessage(undefined, response.data.message, "error", false);
        }
        setIsLoading(false);
      }
    }
  };

  const handleDeleteZipcodeClick = async (event, id) => {
    event.preventDefault();
    setDeleteZipcodeId(id);
    setShowDeleteModal(true);
  };

  const handleManageDeleteZipcode = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // const response = await settingsService.zipcodeDeleteById(zipcodeDeleteId);
    const response = await zipcodeDeleteById(zipcodeDeleteId);

    if (response && response.status === 200) {
      setDeleteZipcodeId(null);
      setShowDeleteModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      handleGetZipcodeByGroup(defaultFilter, currentZipcodeGroupId);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const handleStatusZipCodeClick = async (event, id, status) => {
    event.preventDefault();
    setStatusZipcodeId(id);
    setStatusOfZipcode(status);
    setShowStatusModal(true);
  };

  const handleZipcodeStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfZipcode === 1 ? 0 : 1,
      id: statusZipcodeId,
      table: "store_settings_zipcodes",
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setShowStatusModal(false);
      setStatusZipcodeId(null);
      setStatusOfZipcode(null);
      showToastMessage(undefined, response.data.message, "success", false);
      handleGetZipcodeByGroup(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        isVisible: true,
        Cell: ({ value, row, column }) => {
          return `${row.original.id}`;
        },
      },
      {
        Header: "Zip Code",
        accessor: "zip_code",
        isVisible: true,
        Cell: ({ value, row, column }) => {
          return `${row.original.zip_code}`;
        },
      },
      {
        Header: "Is Deliverable",
        accessor: "is_deliverable",
        isVisible: true,
        Cell: ({ value, row, column }) => {
          return `${(row.original.is_deliverable) ? 'Yes' : 'No'}`;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        isVisible: true,
        placeholder: "Status",
        isSelectFilter: true,
        selectFilterOption: statusList,
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.status}>
              {row.original.status === 1 ? (
                <div className={styles.Active}>Active</div>
              ) : (
                <div className={styles.Inactive}>Inactive </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          let accessCol = getAccessByModuleName(
            modules,
            MODULES.CONFIG_STORE_SETTING
          );
          return (
            <div>
              {accessCol.permissions.write_access && (
                <img
                  onClick={(e) =>
                    handleManageZipcodeClick(e, "edit", row.original)
                  }
                  className={`${styles.searchIcon} mr-4`}
                  src={EditIcon}
                  alt=""
                />
              )}
              {accessCol.permissions.delete_access && (
                <img
                  onClick={(e) => handleDeleteZipcodeClick(e, row.original.id)}
                  className={`${styles.searchIcon} mr-4`}
                  src={DeleteIcon}
                  alt=""
                />
              )}
              {accessCol.permissions.write_access && (
                <img
                  className={`${styles.searchIcon}`}
                  src={ActivatedIcon}
                  alt=""
                  onClick={(e) =>
                    handleStatusZipCodeClick(
                      e,
                      row.original.id,
                      row.original.status
                    )
                  }
                />
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  // console.log("currentZipcodeGroupId",currentZipcodeGroupId);

  return (
    <React.Fragment>
      <div className="d-md-flex align-items-md-center mt-4 mb-2">
        <h3 className="bold mr-3">
          Add Zipcode
          {access.write_access && (
            <span
              className={`cursor-pointer ml-3 ${styles.searchIconWrapper}`}
              onClick={handleAddZipCode}
            >
              <img className={styles.searchIcon} src={AddIcon} alt="" />
            </span>
          )}
          {access.write_access && (
            <span
              className={`cursor-pointer ml-4`}
              onClick={handleAddGroupZipCode}
            >
              <img className={styles.floderIcon} src={FolderIcon} alt="" />
            </span>
          )}
        </h3>
      </div>
      <div className="d-md-flex align-items-md-center mt-2 mb-4">
        {zipcodeGroupDetails &&
          zipcodeGroupDetails.length > 0 &&
          zipcodeGroupDetails.map((group) => (
            <a
              href="#"
              className={`ml-2 ${styles.addZip} ${(group.id === currentZipcodeGroupId) ? styles.active : ''}`}
              onClick={(e) => handleSelectZipcodeGroup(e, group.id)}
            >
              <div>{group.group_name}</div>
            </a>
          ))}
      </div>
      <div>
        {zipcodeGroupDetails && zipcodeGroupDetails.length && (
          <ReactTable
            columns={columns}
            data={data}
            fetchData={handleGetZipcodeByGroupTable}
            loading={loading}
            pageCount={pageCount}
            noDataText={"There is no data to display"}
            defaultFilter={defaultFilter}
            displayGlobalSearch={false}
            displayColumnVisibility={false}
          />
        )}
      </div>
      <ManageZipCodeModal
        showModal={showAddModalZipcode}
        pageTitle={zipcodeTitle}
        submitStatus={submitStatus}
        handleCancelClick={(e) => {
          setShowAddModalZipcode(false);
          setSubmitStatus(null);
        }}
        zip_code={zip_code}
        status={zipcode_status}
        is_deliverable={is_deliverable}
        handleSubmitClick={handleManageZipCodeByGroup}
        onChange={(type, val) => {
          if (type === "zip_code") {
            if (val.length <= 5) {
              setZipcodeName(val);
            }
          }
          if (type === "status") setZipcodeStatus(val);
          if (type === "is_deliverable") setZipcodeIsdeliverable(val);
        }}
      />
      <DeleteConfirmation
        showModal={showDeleteModal}
        title="Delete Zipcode"
        message="Are you sure want to delete this Zipcode?"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteModal(false);
          setDeleteZipcodeId(null);
        }}
        handleSubmitClick={handleManageDeleteZipcode}
      />
      <ManageGroupZipCodeModel
        showModal={showAddModal}
        pageTitle={zipcodeGroupTitle}
        submitStatus={submitStatus}
        group_name={group_name ? group_name : ""}
        status={status}
        handleCancelClick={(e) => {
          setShowAddModal(false);
          setZipcodeGroupName(null);
          setSubmitStatus(null);
        }}
        handleSubmitClick={handleManageGroupZipCode}
        onChange={(type, val) => {
          if (type === "group_name") setZipcodeGroupName(val);
          // if (type === 'status') setZipcodeGroupStatus(val);
        }}
      />

      <StatusConfirmation
        showModal={showStatusModal}
        title="Zipcode"
        message={"zipcode?"}
        buttonLabel={statusOfZipcode ? "Inactivate" : "Activate"}
        statusFlag={statusOfZipcode}
        customFlag={false}
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowStatusModal(false);
          setStatusZipcodeId(null);
          setStatusOfZipcode(null);
        }}
        handleSubmitClick={handleZipcodeStatus}
      />
    </React.Fragment>
  );
};
// export default ZipcodeSettings;
export default React.memo(ZipcodeSettings);
