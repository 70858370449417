import { LocationActions } from "./locationActions";

const clearLocation = {
  primary_info: {
    name: "",
    type: "",
    parent_id: null,
    main_parent_id: null,
    service_offered: "",
    need_opening_hour: "",
    deliver_throughout_description: "",
    your_local_florist_description: "",
    sequence_number: 0,
    slug: "",
    blurb: "",
    description: "",
    status: 1
  },
  cms_location_categories: [],
  banners: [],
  seo: {
    id: 0,
    parent_id: 0,
    module: "locations",
    is_robot: false,
    is_no_follow: false,
    is_no_index: false,
    image_alt_text: "",
    page_title: "",
    meta_title: "",
    meta_description: "",
    canonical_url: "",
    og_title: "",
    og_description: "",
    og_image_url: "",
    og_image_alt: "",
    og_url: "",
    og_image_type: "",
    og_site_name: "",
    og_image_height: 0,
    og_image_width: 0,
    twitter_title: "",
    twitter_image_url: "",
    twitter_description: "",
    twitter_site: "",
    twitter_creator: "",
    schema: "",
  },
};

const newBanner = {
  id: 0,
  parent_id: 0,
  module: "locations",
  position: "LEFT",
  banner_text: "",
  button_label: "",
  button_url: "",
  description: "",
  image: "",
  is_supported_image: 1,
  show_oval: 0,
};
const newCategoryData = {
  category_hierarchy_id: null,
  seo: [],
}

function locationReducer(state, action) {
  switch (action.type) {

    case LocationActions.SET_BANNER_DATA:
      let setBannersData = state.banners ? [...state.banners] : [];
      setBannersData.push(action.payload);
      return {
        ...state,
        banners: setBannersData,
      };
    case LocationActions.ADD_NEW_BANNER:
      let aBanner = state.banners ? [...state.banners] : [];
      aBanner.push(newBanner);
      return {
        ...state,
        banners: aBanner,
      };
    case LocationActions.REMOVE_BANNER:
      let rBanner = [...state.banners];
      rBanner.splice(action.payload, 1);
      return {
        ...state,
        banners: rBanner,
      };
    case LocationActions.UPDATE_BANNER_DATA:
      let bannersData = [...state.banners];
      bannersData[action.payload.index] = {
        ...action.payload.values,
      };
      return {
        ...state,
        banners: bannersData,
      };
    case LocationActions.CHECK_BANNER_DATA:
      return {
        ...state,
        bannerError: action.payload,
      };
    case LocationActions.SET_LOCATION_PRIMARY_DATA:

      return {
        ...state,
        primary_info: action.payload,
      };
    case LocationActions.SET_RELATED_CATEGORY:

      let relatedCatData = state.cms_location_categories ? [...state.cms_location_categories] : [];
      // relatedCatData.push(newCategoryData);
      relatedCatData = [...relatedCatData, {
        category_hierarchy_id: null,
        seo: [],
      }]
      return {
        ...state,
        cms_location_categories: relatedCatData,
      };
    case LocationActions.DELETE_RELATED_CATEGORY:
      let deleteCatData = [...state.cms_location_categories];
      deleteCatData.splice(action.payload, 1);
      return {
        ...state,
        cms_location_categories: deleteCatData,
      };
    case LocationActions.UPDATE_RELATED_CATEGORY:

      let updatedCatData = state.cms_location_categories ? [...state.cms_location_categories] : [];
      updatedCatData[action.index].category_hierarchy_id = action.payload;

      return {
        ...state,
        cms_location_categories: updatedCatData,
      };
    case LocationActions.SET_EXISTING_RELATED_CATEGORY:
      let existingRelatedCategory = state.cms_location_categories ? [...state.cms_location_categories] : [];
      existingRelatedCategory.push(action.payload);
      return {
        ...state,
        cms_location_categories: existingRelatedCategory,
      };
    case LocationActions.SET_SEO_DATA:
      let updatedCatSeoData = state.cms_location_categories ? [...state.cms_location_categories] : [];
      let seoData = { ...updatedCatSeoData[action.index].seo, ...action.payload };
      updatedCatSeoData[action.index].seo = seoData;
      return {
        ...state,
        cms_location_categories: updatedCatSeoData,
      };
    case LocationActions.CLEAR_DATA_LOCATION:
      return clearLocation;

    case LocationActions.UPDATE_SEO_DATA:
      let seoDataNew = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: seoDataNew,
      };
    case LocationActions.CHECK_SEO_DATA:
      return {
        ...state,
        seoError: action.payload,
      };
    case LocationActions.UPDATE_OG_INFO_DATA:
      let ogInfoData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: ogInfoData,
      };
    case LocationActions.CHECK_OG_INFO_DATA:
      return {
        ...state,
        ogInfoError: action.payload,
      };
    case LocationActions.UPDATE_TWITTER_INFO_DATA:
      let twitterInfoData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: twitterInfoData,
      };
    case LocationActions.CHECK_TWITTER_INFO_DATA:
      return {
        ...state,
        twitterInfoError: action.payload,
      };
    case LocationActions.UPDATE_SCHEMA_DATA:
      let schemaData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: schemaData,
      };
    case LocationActions.CHECK_SCHEMA_DATA:
      return {
        ...state,
        schemaError: action.payload,
      };
    default:
      return state;
  }
}

export default locationReducer;