import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import React from "react";
// Component
// SCSS
import styles from "./CancelOrder.module.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CancelOrder = ({
  showModal,
  pageTitle,
  handleCancelClick,
  handleSubmitClick,
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center  ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="container">
              <p
                style={{ color: "#f30000", fontSize: "30px" }}
                className="mt-5 mb-3"
              >
                Cancel order means the order will be deleted from the system.
              </p>
              <p
                style={{ color: "#f30000", fontSize: "30px" }}
                className="mt-5 mb-3"
              >
                You cannot restore this action.
              </p>
              <p
                style={{ color: "#f30000", fontSize: "30px" }}
                className="mt-5 mb-3"
              >
                Are you sure you want to cancel the order?
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <span
                data-dismiss="modal"
                onClick={handleSubmitClick}
                className="btn gray-btn d-inline-block mt-5 mb-5 mr-2 mt-5 cursor-pointer"
              >
                {" "}
                Yes{" "}
              </span>
              <span
                data-dismiss="modal"
                onClick={handleCancelClick}
                className="secondary-btn d-inline-block mt-5 mb-5 mr-2 mt-5 cursor-pointer"
              >
                {" "}
                No{" "}
              </span>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default CancelOrder;
