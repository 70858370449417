import React, { useContext, useEffect, useState } from "react";
import CardContent from "@material-ui/core/CardContent";
import Seo from "../../../Components/Cms/Seo";
import OGInfo from "../../../Components/Cms/OgInfo";
import Twitterinfo from "../../../Components/Cms/TwitterInfo";
// import Editer from "../../../Components/Cms/Schema";
import Header from "../../../Components/Header/Header";
import Banner from "../../../Components/Cms/Banner";
import Goto from "../../../Components/Cms/Goto";
import PrimaryInformation from "../../../Components/Category/PrimaryInformation";
import Navigation from "../../../Components/Products/Navigation";
import classes from "./index.module.scss";
import Faq from "Components/Category/CategoryFaq";
// import CheckBox from "./../../../Components/FormControl/CheckBox/CheckBox";

import { useCategory } from "../../../Context/CategoryCurd/useCategory";
import { postReqCategory } from "../../../Hooks/category/categoryDataApiCall";

import { getAccessByModuleName } from "../../../Routes/Routes";
import { AppContext } from "./../../../Context/AppContext";
import { MODULES } from "../../../Helpers/Constants";

// Services
// import CategoryApi from "../../../Services/Category/Category";
import {
  getCategoryDetail,
  getCategoryNormalList,
  getCategoryHierarchyList,
  createCategory,
  updateCategory,
} from "api/category";

// import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";

const AddCategory = (props) => {
  // const history = useHistory();
  // const categoryService = new CategoryApi();

  const gotoList = [
    { id: "primary-information", title: "Primary Informations" },
    { id: "banner-section", title: "Banner Style" },
    { id: "category_faq", title:"Faqs"},
    { id: "seo-section", title: "SEO" },
    // { id: "tags", title: "Tags" },
    { id: "navigation", title: "Navigation" },
  ];

  const { showToastMessage, modules, setIsLoading } = useContext(AppContext);
  const [catHierarchyId, setCatHierarchyId] = useState(0);
  const [categoryUpdateId, setCategoryUpdateId] = useState("");
  const [categoryHierarchyName, setCategoryHierarchyName] = useState("");
  const [showCategorySearching, setShowCategorySearching] = useState(0);

  const [access, setAccess] = useState({});

  useEffect(() => {
    clearCategoryState();
  }, []);

  useEffect(() => {
    const { categoryHierarchyId, categoryId } = props.match.params;
    if (categoryId !== undefined && categoryId !== null) {
      //FOR CATEGORY EDIT PAGE
      setCategoryUpdateId(categoryId);
      setCatHierarchyId(categoryHierarchyId);
    } else if (
      categoryHierarchyId !== undefined &&
      categoryHierarchyId !== null
    ) {
      //FOR ADD HIERARCHY UNDER SELECTED CATEGORY
      setCategoryHierarchyParentId(categoryHierarchyId);
      handleGetCategoryListData();
      setCategoryHierarchyId(0);
      setShowCategorySearching(1);
    }
  }, []);

  //use in update case for get product detail
  useEffect(() => {
    if (categoryUpdateId) {
      handleGetCategoryDetailById(categoryUpdateId);
    }
  }, [categoryUpdateId]);

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  // Access Contorol functionm call
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.CATEGORY);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.write_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  const {
    categoryState,
    addNewBanner,
    removeBanner,
    setBannerData,
    checkBannerData,
    setSeoData,
    checkSeoData,
    setOgInfoData,
    checkOgInfoData,
    setTwitterInfoData,
    checkTwitterInfoData,
    setCategoryNavigationData,
    checkCategoryNavigationData,
    clearCategoryState,
    setCategoryAllData,
    setCategoryBannerData,
    setCategoryPrimaryData,
    setCategoryHierarchyId,
    setCategoryHierarchyParentId,
    setCategoryListData,
    setCategoryFaqData,
  } = useCategory();

  const handleGetCategoryDetailById = async (categoryId) => {
    setIsLoading(true);
    // const response = await categoryService.getCategoryDetail(categoryId);
    const response = await getCategoryDetail(categoryId);
    if (response && response.status === 200) {
      if (response.data.data) {
        let tempResponseData = { ...response.data.data };
        setCategoryAllData(tempResponseData);
        setCategoryHierarchyId(catHierarchyId);
        let filterHierarchyData = tempResponseData?.category_hierarchies?.filter(
          (hierarchyData) => {
            if (hierarchyData.id === +catHierarchyId) {
              return hierarchyData;
            }
          }
        );
        setCategoryNavigationData({
          slug: tempResponseData.slug,
          blurb: tempResponseData.blurb,
        });
        setSeoData(filterHierarchyData[0]?.seo);
        setOgInfoData(filterHierarchyData[0]?.seo);
        setTwitterInfoData(filterHierarchyData[0]?.seo);
        tempResponseData.banners?.forEach((d, i) => {
          setCategoryBannerData(tempResponseData.banners[i]);
        });
        // Set Category Faq data
        tempResponseData.cms_faqs?.forEach((val, index) => {
          setCategoryFaqData(tempResponseData.cms_faqs[index]);
        })
        setCategoryPrimaryData(tempResponseData);
        handleGetCategoryHierarchyData(tempResponseData.id, catHierarchyId);
      }
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handleGetCategoryListData = async () => {
    setIsLoading(true);

    let postData = {
      globalSearch: "",
      filters: [],
      sortBy: [
        {
          id: "title",
          asc: "asc",
        },
      ],
    };

    // const response = await categoryService.getCategoryNormalList(postData);
    const response = await getCategoryNormalList(postData);
    if (response && response.status === 200) {
      setCategoryListData(response.data.data.rows);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handleGetCategoryHierarchyData = async (id, catHierarchyId) => {
    setIsLoading(true);

    let postData = {
      globalSearch: "",
      filters: [
        {
          id: "id",
          value: id,
        },
      ],
      sortBy: [],
    };

    // const response = await categoryService.getCategoryHierarchyList(postData);
    const response = await getCategoryHierarchyList(postData);
    if (response && response.status === 200) {
      let tempResponseData = [...response.data.data];
      let filterHierarchyData = tempResponseData.filter((hierarchyData) => {
        if (hierarchyData.category_hierarchy_id === +catHierarchyId) {
          return hierarchyData;
        }
      });
      // console.log(filterHierarchyData);
      if (
        filterHierarchyData &&
        filterHierarchyData.length > 0 &&
        filterHierarchyData[0].title !== null
      ) {
        setCategoryHierarchyName(filterHierarchyData[0].title);
      }
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setIsLoading(false);
  };

  const handleSetSelectedCategoryData = async (categoryId) => {
    //GET SELECTED CATEGORY DATA & SET
    setCategoryUpdateId(categoryId);
  };

  const handleSubmitClick = async (event) => {
    event.preventDefault();

    // debugger;
    let categoryApiCall = true;
    let validationMessage = "please add required data";
    const primaryInfoForm = document.getElementById(
      "category_primary_info_submit_button"
    );
    if (primaryInfoForm) {
      primaryInfoForm.click();
    }

    const navigationInfoForm = document.getElementById(
      "navigation_info_submit_button"
    );
    if (navigationInfoForm) {
      navigationInfoForm.click();
    }

    // const seoSubmit = document.querySelector(".seo-submit-button");
    // if (seoSubmit) {
    //   seoSubmit.click();
    // }

    // const ogInfoSubmit = document.querySelector(".og-info-submit-button");
    // if (ogInfoSubmit) {
    //   ogInfoSubmit.click();
    // }

    // const twitterSubmit = document.querySelector(".twitter-submit-button");
    // if (twitterSubmit) {
    //   twitterSubmit.click();
    // }

    if(categoryState.categoryHierarchyParentId !== null){
      let checkData = categoryState.setCategoryAllData?.category_hierarchies?.findIndex((data)=> +data.parent_hierarchy_id === +categoryState.categoryHierarchyParentId);
      if(categoryState.setCategoryAllData?.category_hierarchies && +checkData !== -1){
        categoryApiCall = false;
        validationMessage = "Ths category is already assign in this hierarchy";
      }
    }
  
    if (categoryState.categoryPrimaryError) {
      categoryApiCall = false;
      validationMessage = "please add required data";
    } else if (!categoryState.category_primary_info.title) {
      categoryApiCall = false;
      validationMessage = "please add category title";
    } else if (categoryState.categoryNavigationError) {
      categoryApiCall = false;
      validationMessage = "please add slug data";
    }
    // else if (categoryState.seoError) {
    //   categoryApiCall = false;
    //   validationMessage = "please add seo data";
    // } else if (!categoryState.seo.image_alt_text) {
    //   categoryApiCall = false;
    //   validationMessage = "please add seo data";
    // } else if (categoryState.ogInfoError) {
    //   categoryApiCall = false;
    //   validationMessage = "please add seo og data";
    // } else if (!categoryState.seo.og_title) {
    //   categoryApiCall = false;
    //   validationMessage = "please add seo og data";
    // } else if (categoryState.twitterError) {
    //   categoryApiCall = false;
    //   validationMessage = "please add seo twitter data";
    // } else if (!categoryState.seo.twitter_title) {
    //   categoryApiCall = false;
    //   validationMessage = "please add seo twitter data";
    // }

    // console.log("categorystate =>", categoryState);
    const post_req = postReqCategory(categoryState);
    // console.log(post_req);
    // return false;

    if (categoryApiCall) {
      if (categoryUpdateId) {
        // let response = await categoryService.updateCategory(
        //   post_req,
        //   categoryUpdateId
        // );
        let response = await updateCategory(post_req, categoryUpdateId);
        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          clearCategoryState();
          setIsLoading(false);
          props.history.push("/product-catalog/category-management");
        } else {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "error", false);
        }
      } else {
        // let response = await categoryService.createCategory(post_req);
        let response = await createCategory(post_req);
        if (response && response.status === 200) {
          showToastMessage(undefined, response.data.message, "success", false);
          clearCategoryState();
          setIsLoading(false);
          props.history.push("/product-catalog/category-management");
        } else {
          setIsLoading(false);
          showToastMessage(undefined, response.data.message, "error", false);
        }
      }
    } else {
      showToastMessage(undefined, validationMessage, "error", false);
    }
  };

  return (
    <React.Fragment>
      <Header title="Category" />
      <div className="row">
        <div id="MainContent" className="col-8">
          <div className={`${classes.cardBody}`}>
            <CardContent>
              <PrimaryInformation
                handleSetSelectedCategoryData={handleSetSelectedCategoryData}
                showCategorySearching={showCategorySearching}
                categoryHierarchyName={categoryHierarchyName}
              />
              <Banner
                bannerModule="category"
                bannersData={categoryState.banners}
                addNewBanner={addNewBanner}
                removeBanner={removeBanner}
                setBannerData={setBannerData}
                checkBannerData={checkBannerData}
                uploadImageType="CATEGORY_BANNER"
              />
              <Faq />

              <Seo
                data={categoryState.seo}
                setSeoData={setSeoData}
                checkSeoData={checkSeoData}
              />
              <OGInfo
                data={categoryState.seo}
                setOgInfoData={setOgInfoData}
                checkOgInfoData={checkOgInfoData}
              />
              <Twitterinfo
                data={categoryState.seo}
                setTwitterInfoData={setTwitterInfoData}
                checkTwitterInfoData={checkTwitterInfoData}
              />
              {/* <Editer
                data={categoryState.seo}
                setSchemaData={setSchemaData}
                checkSchemaData={checkSchemaData}
              /> */}
            </CardContent>
          </div>
        </div>
        <div className="col-4">
          <Goto gotoList={gotoList} handleSubmitClick={handleSubmitClick} />
          <Navigation
            slugData={categoryState.setCategoryAllData.slug}
            blurbData={categoryState.setCategoryAllData.blurb}
            setNavigationData={setCategoryNavigationData}
            checkNavigationData={checkCategoryNavigationData}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(AddCategory);
