import TableContainer from 'Components/Table/TableContainer';
import React from 'react'

const NonRecipeOrderProducts = (props) => {
    const columns = React.useMemo(
        () => [
          {
            Header: "Product Name",
            accessor: "product_name",
            colWidth: 400,
          },
          {
            Header: "Varialtion",
            accessor: "title",
            colWidth: 200,
            
          },
          {
            Header: "Quantity",
            accessor: "total_qty",
          },
          {
            Header: "Unit Price",
            accessor: "unit_price",
          },
          
          
        ],
        []
      );
  return (
    <React.Fragment>
        <h3 className='bold'>Products without Recipe  </h3>
        <TableContainer
        columns={columns}
        data={props.data}
        noDataText={'There is no data to display'}
        loading={props.loading}
        />
    </React.Fragment>
  )
}

export default NonRecipeOrderProducts