import { HomePageActions } from "./homePageActions";

const newRec = {
  id: 0,
  parent_id: 1,
  module: "home-page",
  position: "LEFT",
  banner_text: "",
  button_label: "",
  button_url: "",
  description: "",
  image: "",
  mobile_image: "",
  is_supported_image: 1,
  show_oval: 0,
};

function homePageReducer(state, action) {
  // console.log('homePageReducer', action);
  switch (action.type) {
    case HomePageActions.GET_HOME_PAGE_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case HomePageActions.GET_HOME_PAGE_PRODUCT_CATEGRIES:
      return {
        ...state,
        product_categories: action.payload,
      };
    case HomePageActions.ADD_NEW_BANNER:
      let aBanner = state.banners ? [...state.banners] : [];
      aBanner.push(newRec);
      return {
        ...state,
        banners: aBanner,
      };
    case HomePageActions.REMOVE_BANNER:
      let rBanner = { ...state };
      rBanner.banners.splice(action.payload, 1);
      return {
        ...rBanner,
      };
    case HomePageActions.UPDATE_BANNER_DATA:
      let bannersData = [...state.banners];
      bannersData[action.payload.index] = {
        ...action.payload.values,
      };
      return {
        ...state,
        banners: bannersData,
      };
    case HomePageActions.CHECK_BANNER_DATA:
      return {
        ...state,
        bannerError: action.payload,
      };
    case HomePageActions.ADD_NEW_PARTNER:
      let aPartner = state.worked_with
        ? [...state.worked_with, ...action.payload]
        : [];
      return {
        ...state,
        worked_with: aPartner,
      };
    case HomePageActions.REMOVE_PARTNER:
      let rPartner = { ...state };
      rPartner.worked_with.splice(action.payload, 1);
      return {
        ...rPartner,
      };
    case HomePageActions.ADD_NEW_CERTIFICATION:
      let aCertification = state.certifications
        ? [...state.certifications, ...action.payload]
        : [];
      return {
        ...state,
        certifications: aCertification,
      };
    case HomePageActions.REMOVE_CERTIFICATION:
      let rCertification = { ...state };
      rCertification.certifications.splice(action.payload, 1);
      return {
        ...rCertification,
      };
    case HomePageActions.UPDATE_SEO_DATA:
      let seoData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: seoData,
      };
    case HomePageActions.CHECK_SEO_DATA:
      return {
        ...state,
        seoError: action.payload,
      };
    case HomePageActions.UPDATE_OG_INFO_DATA:
      let ogInfoData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: ogInfoData,
      };
    case HomePageActions.CHECK_OG_INFO_DATA:
      return {
        ...state,
        ogInfoError: action.payload,
      };
    case HomePageActions.UPDATE_TWITTER_INFO_DATA:
      let twitterInfoData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: twitterInfoData,
      };
    case HomePageActions.CHECK_TWITTER_INFO_DATA:
      return {
        ...state,
        twitterInfoError: action.payload,
      };
    case HomePageActions.UPDATE_SCHEMA_DATA:
      let schemaData = { ...state.seo, ...action.payload };
      return {
        ...state,
        seo: schemaData,
      };
    case HomePageActions.CHECK_SCHEMA_DATA:
      return {
        ...state,
        schemaError: action.payload,
      };
    case HomePageActions.UPDATE_FEATURED_PRODUCT_DATA:
      let fProductData = [...state.product_categories];
      fProductData[0].children[action.payload.index] = {
        ...action.payload.values,
      };
      return {
        ...state,
        product_categories: fProductData,
      };
    case HomePageActions.CHECK_FEATURED_PRODUCT_DATA:
      return {
        ...state,
        featuredProductError: action.payload,
      };
    case HomePageActions.UPDATE_FEATURED_CATEGORY_DATA:
      let fCategoryData = [...state.product_categories];
      fCategoryData[action.payload.index] = {
        ...action.payload.values,
      };
      return {
        ...state,
        product_categories: fCategoryData,
      };
    case HomePageActions.CHECK_FEATURED_CATEGORY_DATA:
      return {
        ...state,
        featuredCategoryError: action.payload,
      };
    case HomePageActions.ADD_FEATURED_PRODUCT_PRODUCT:
      let featuredProduct = [...state.product_categories];
      featuredProduct[0].children[action.payload.index].products = [
        ...action.payload.values,
      ];
      return {
        ...state,
        product_categories: featuredProduct,
      };
    case HomePageActions.DELETE_FEATURED_PRODUCT_PRODUCT:
      let rFeaturedProduct = [...state.product_categories];
      rFeaturedProduct[0].children[action.payload.index].products = [
        ...action.payload.values,
      ];
      return {
        ...state,
        product_categories: rFeaturedProduct,
      };
    case HomePageActions.SET_FEATURED_PRODUCT_SEQUENCE:
      let sFeaturedProduct = [...state.product_categories];
      sFeaturedProduct[0].children[action.payload.index].products[
        action.payload.product_index
      ].sequence_number = action.payload.values;
      return {
        ...state,
        product_categories: sFeaturedProduct,
      };
    case HomePageActions.ADD_FEATURED_PRODUCT_CATEGORY:
      let featuredCategory = [...state.product_categories];
      featuredCategory[action.payload.index].products = [
        ...action.payload.values,
      ];
      return {
        ...state,
        product_categories: featuredCategory,
      };
    case HomePageActions.DELETE_FEATURED_PRODUCT_CATEGORY:
      let rFeaturedCategory = [...state.product_categories];
      rFeaturedCategory[action.payload.index].products = [
        ...action.payload.values,
      ];
      return {
        ...state,
        product_categories: rFeaturedCategory,
      };
    case HomePageActions.SET_FEATURED_CATEGORY_SEQUENCE:
      let sFeaturedCategory = [...state.product_categories];
      sFeaturedCategory[action.payload.index].products[
        action.payload.product_index
      ].sequence_number = action.payload.values;
      return {
        ...state,
        product_categories: sFeaturedCategory,
      };
    default:
      return state;
  }
}

export default homePageReducer;
