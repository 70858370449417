import { callPostApi, callDeleteApi, callPutApi, callGetApi } from "./api";

export async function getCmsLocationsList(data) {
      // return this.interceptors.POST("page", data);
      try {
            const response = await callPostApi({ url: "locations/list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getCmsLocationsDetail(locationId) {
      // return this.interceptors.GET("page/" + pageId);
      try {
            const response = await callGetApi({ url: "locations/" + locationId });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function createCmsLocations(postData) {
      // return this.interceptors.POST("page/add", postData);
      try {
            const response = await callPostApi({ url: "locations", body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateCmsLocations(postData, locationId) {
      // return this.interceptors.PUT("page/" + pageId, postData);
      try {
            const response = await callPutApi({ url: "locations/" + locationId, body: postData });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function deleteCmsLocations(id) {
      // return this.interceptors.DELETE("page/" + id);
      try {
            const response = await callDeleteApi({ url: "locations/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getCmsLocationsRawDetail(locationId) {
      // return this.interceptors.GET("page/" + pageId);
      try {
            const response = await callGetApi({ url: "locations/locationDetail/" + locationId });
            return response;
      } catch (error) {
            throw error;
      }
}

