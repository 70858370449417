import { AppContext } from "Context/AppContext";
import { useContext, useEffect } from "react";

export function useLastOrderEditPath() {

      const { setLastOrderEditPath } = useContext(AppContext);

      useEffect(() => {
            setLastOrderEditPath(window.location.pathname);
      }, [])

}