import CardContent from "@material-ui/core/CardContent";
import React from "react";
import styles from "./index.module.scss";
const Listbox = ({ list }) => {
  return (
    <div className={styles.card} style={{ paddingLeft: '0px' }}>
      <CardContent>
        {list &&
          list.map((i, j) => (
            <div key={j} className={styles.cardItem}>
              <div className="d-flex justify-content-between align-items-center ">
                <div className={styles.digits}>{i.number}</div>
                <img className="mr-3" src={i.icon} alt={i.title} height='90px' width='90px' />
              </div>
              <div className="mt-1 d-flex align-items-start">
                <p>{i.title}</p>
              </div>
            </div>
          ))}
      </CardContent>
    </div>
  );
};

export default Listbox;
