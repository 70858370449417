export const BlogActions = {
    CLEAR_DATA_BLOG: "CLEAR_DATA_BLOG",
    SET_ALL_BLOG_DATA: "SET_ALL_BLOG_DATA",
    SET_PAGE_FOR_VIEW: "SET_PAGE_FOR_VIEW",
    SET_PRIMARY_INFO: "SET_PRIMARY_INFO",
    CHECK_PRIMARY_INFO: "CHECK_PRIMARY_INFO",
    SET_CATEGORY_DATA: "SET_CATEGORY_DATA",
    DELETE_CATEGORY_DATA: "DELETE_CATEGORY_DATA",
    SET_BANNER_DATA: "SET_BANNER_DATA",
    ADD_NEW_BANNER: "ADD_NEW_BANNER",
    REMOVE_BANNER: "REMOVE_BANNER",
    UPDATE_BANNER_DATA: "UPDATE_BANNER_DATA",
    CHECK_BANNER_DATA: "CHECK_BANNER_DATA",
    UPDATE_SEO_DATA: "UPDATE_SEO_DATA",
    CHECK_SEO_DATA: "CHECK_SEO_DATA",
    UPDATE_OG_INFO_DATA: "UPDATE_OG_INFO_DATA",
    CHECK_OG_INFO_DATA: "CHECK_OG_INFO_DATA",
    UPDATE_TWITTER_INFO_DATA: "UPDATE_TWITTER_INFO_DATA",
    CHECK_TWITTER_INFO_DATA: "CHECK_TWITTER_INFO_DATA",
    UPDATE_SCHEMA_DATA: "UPDATE_SCHEMA_DATA",
    CHECK_SCHEMA_DATA: "CHECK_SCHEMA_DATA",
    SET_SIMILAR_BLOG_DATA: "SET_SIMILAR_BLOG_DATA",
    DELETE_SIMILAR_BLOG_DATA: "DELETE_SIMILAR_BLOG_DATA",
}; 