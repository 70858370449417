import {
  deleteProduct,
  getProductsListAdmin,
  updatesequnceProduct,
} from "api/product";
import { updateStatus } from "api/utils";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
// import ViewIcon from "../../../Assets/images/error-outline.svg";
// import AddIcon from "../../../Assets/images/add.svg";
import { useHistory } from "react-router-dom";
import Header from "../../../Components/Header/Header";
// Components
import DeleteConfirmation from "../../../Components/Modal/DeleteConfirmation/DeleteConfirmation";
// import Text from "../../../Components/FormControl/Text/TextInput";
import ProductSequnceModel from "../../../Components/Modal/ProductSequnceModel/ProductSequnceModel";
import StatusConfirmation from "../../../Components/Modal/StatusConfirmation/StatusConfirmation";
import ReactTable from "../../../Components/Table/Table";
// import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
// Context
import { AppContext } from "../../../Context/AppContext";
import { MODULES, STATUS_FILTER } from "../../../Helpers/Constants";
import { getAccessByModuleName } from "../../../Routes/Routes";
// Services
// import ProductManagementApi from "../../../Services/Products/Products";
// import UtilsApi from "../../../Services/Utils/Utils";
import ActivatedIcon from "./../../../Assets/images/activated.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";
// Images
import EditIcon from "./../../../Assets/images/edit.svg";
//scss
import styles from "./index.module.scss";

const ProductManagement = (props) => {
  const history = useHistory();
  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [access, setAccess] = useState({});
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [statusChangeProductId, setStatusChangeProductId] = useState(false);
  const [statusOfProductId, setStatusOfProductId] = useState();
  const [allDataShow, setAllDataShow] = useState("");
  const MAX_ITEMS = 10;
  const [showSequenseNumber, setShowSequenseNumber] = useState(false);
  const [sequnceNumber, setSequnceNumber] = useState(0);
  const [sequnceIndex, setSequnceIndex] = useState(0);
  const [productId, setProductId] = useState(0);

  const typeList = [
    { id: "", title: "All" },
    { id: "NON_ADD_ONS", title: "Product" },
    { id: "ADD_ONS", title: "Add On" },
  ];

  const statusList = STATUS_FILTER;

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [
      {
        id: "id",
      },
    ],
    filters: [],
    globalFilter: "",
  });

  // const productService = new ProductManagementApi();
  // const utilsService = new UtilsApi();

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
    };
    let productAccess = getAccessByModuleName(modules, MODULES.PRODUCT_ADDONS);
    if (productAccess && productAccess.permissions.read_access) {
      // const response = await productService.getProducts(postData);
      const response = await getProductsListAdmin(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }
    setLoading(false);
  };

  const handleDeleteProductClick = async (event, id) => {
    event.preventDefault();
    setDeleteProductId(id);
    setShowDeleteModal(true);
  };

  const handleStatusProductClick = async (event, id, status) => {
    event.preventDefault();

    setStatusChangeProductId(id);
    setStatusOfProductId(status);
    setShowStatusModal(true);
  };

  const handleDeleteProduct = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // const response = await productService.deleteProduct(deleteProductId);
    const response = await deleteProduct(deleteProductId);

    if (response && response.status === 200) {
      setDeleteProductId(null);
      setShowDeleteModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const handleProductStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfProductId === 1 ? 0 : 1,
      id: statusChangeProductId,
      table: "products",
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);

    if (response && response.status === 200) {
      setStatusChangeProductId(null);
      setStatusOfProductId(null);
      setShowStatusModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const handleManageProductClick = async (
    event,
    type = "add",
    productData = {}
  ) => {
    event.preventDefault();
    if (type === "add") {
      history.push(`/product-catalog/product-management/add-product`);
    } else if (type === "view") {
      let productId = productData.id;
      props.history.push(
        `/product-catalog/product-management/view-product/${productId}`
      );
    } else {
      let productId = productData.id;
      props.history.push(
        `/product-catalog/product-management/edit-product/${productId}`
      );
    }
  };

  useEffect(getModuleAccess, [modules]);
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.PRODUCT_ADDONS);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  const renderCategory = (categoryData) => {
    if (allDataShow === "ALL") {
      return categoryData;
    }
    return categoryData.slice(0, MAX_ITEMS);
  };

  // const loadMoreData = (e) => {
  //   if (allDataShow === "ALL") {
  //     setAllDataShow("");
  //   } else {
  //     setAllDataShow("ALL");
  //   }
  // };
  const handleBuureSequece = async (index, value, indexId) => {
    let post_Data = {
      id: index,
      sequence_number: value,
    };
    changeSequenceData(indexId, value);
    // const response = await productService.updatesequnceProduct(post_Data);
    const response = await updatesequnceProduct(post_Data);
    if (response.status === 200) {
      // setSequnceNumber(0);
      // setSequnceIndex(0);
      showToastMessage(
        undefined,
        "Sequence update has been completed successfully.",
        "success",
        false
      );
    } else showToastMessage(undefined, response.data.message, "error", false);
    // fetchData(defaultFilter);
  };
  const changeSequenceData = (id, value, tableDtataList) => {
    let tableData = [...data];
    tableData[id].sequence_number = +value;
    setData(tableData);
  };
  // const onKeyPress = (e, index, value) => {
  //   if (e.keyCode === 13) {
  //     handleBuureSequece(index, value);
  //   }
  // };

  let productAccess = getAccessByModuleName(modules, MODULES.PRODUCT_ADDONS);
  const columns = React.useMemo(
    () => [
      {
        Header: "SKU",
        accessor: "sku_id",
        isVisible: true,
        placeholder: "Search by Code",
        disableFilters: true,
      },
      // {
      //   Header: "Order",
      //   accessor: "",
      //   isVisible: true,
      //   disableSortBy: true,
      //   disableFilters: true,
      //   Cell: ({ value, row, column }) => {
      //     return (
      //       <div>
      //         <h5>{row.index + 1}</h5>
      //       </div>
      //     );
      //   },
      // },
      {
        Header: "Name",
        accessor: "title",
        isVisible: true,
        placeholder: "Search by Name",
        Cell: ({ value, row, column }) => {
          let defaultIndex = 0;
          row.original.product_images.forEach((d, i) => {
            if (d.is_default_selected) defaultIndex = i;
          });
          return (
            <div className={` ${styles.ProducatImageWapper}`}>
              <img
                src={
                  row.original.product_images.length === 0 ||
                  row.original.product_images[defaultIndex].image === "" ||
                  row.original.product_images[defaultIndex].image === "null" ||
                  row.original.product_images[defaultIndex].image === null
                    ? ""
                    : `${row.original.product_images[defaultIndex].image}`
                }
                alt=""
              />

              <h4>{row.original.title}</h4>
              <p>{row.original.priceRange}</p>
            </div>
          );
        },
      },

      {
        Header: "Categories",
        accessor: "category",
        isVisible: true,
        disableSortBy: true,
        placeholder: "Search by Categories",
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {renderCategory(row.original.product_categories).map((d, i) => {
                return (
                  <div className={styles.addCategory}>
                    <span>{d.category_hierarchy.category.title}</span>
                  </div>
                );
              })}
              {/* <span
                className={`link cursor-pointer ml-2 ${styles.searchIconWrapper}`}
                onClick={(e) => {
                  e.preventDefault();
                  loadMoreData();
                }}
              >
                <img className={styles.searchIcon} src={AddIcon} alt="" />
              </span> */}
            </div>
          );

          // return <h5>{row.original.categories}</h5>;
        },
      },
      {
        Header: "Keywords",
        accessor: "keywords",
        isVisible: true,
        placeholder: "Search by Keywords",
      },
      {
        Header: "Type",
        accessor: "type",
        isVisible: true,
        isSelectFilter: true,
        selectFilterOption: typeList,
        Cell: ({ value, row, column }) => {
          return (
            <span>
              {row.original.type === "NON_ADD_ONS" ? "Product" : "Add On"}
            </span>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        isVisible: true,
        placeholder: "Status",
        isSelectFilter: true,
        selectFilterOption: statusList,
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.status}>
              {row.original.status === 1 ? (
                <div className={styles.Active}>Active</div>
              ) : (
                <div className={styles.Inactive}>Inactive </div>
              )}
            </div>
          );
        },
      },
      /* comment by darshan for not give this in release on 1-7-2021 */
      // {
      //   Header: "Sequence",
      //   accessor: "sequence_number",
      //   isVisible: true,
      //   // selectFilterOption: typeList,
      //   disableFilters: true,
      //   Cell: ({ value, row, column }) => {
      //     return <p className="text-center">{row.original.sequence_number}</p>;
      //   },
      // },
      {
        Header: "Action",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div className={`link cursor-pointer`}>
              {/* comment by darshan for not give this in release on 1-7-2021 */}
              {/* {access && access.read_access &&
                <img
                  onClick={(e) =>
                    handleManageProductClick(e, "view", row.original)
                  }
                  className={`${styles.searchIcon} mr-3`}
                  src={ViewIcon}
                  alt="View product"
                />
              } */}
              {productAccess && productAccess.permissions.write_access && (
                <img
                  onClick={(e) =>
                    handleManageProductClick(e, "edit", row.original)
                  }
                  className={`${styles.searchIcon} mr-3`}
                  src={EditIcon}
                  alt=""
                />
              )}
              {/* <div className="d-flex align-items-center">
                <Text
                  type="text"
                  // label="First Name"
                  value={row.original.sequence_number}
                  required={true}
                  onChange={(value) => {
                    changeSequenceData(row.index, value, data);
                  }}
                  onBlur={(e) => {
                    handleBuureSequece(row.original.id, e.target.value);
                  }}
                  onKeyDown={(e) => {
                    onKeyPress(e, row.original.id, e.target.value);
                  }}
                />
                <SubdirectoryArrowRightIcon
                  className={`ml-4n`}
                  onClick={() => {
                    handleBuureSequece(
                      row.original.id,
                      row.original.sequence_number
                    );
                  }}
                />
              </div> */}
              {productAccess &&
                productAccess.permissions.write_access &&
                row.original.slug !== "custom-product" &&
                row.original.slug !== "subscription-item" && (
                  <img
                    onClick={(e) =>
                      handleStatusProductClick(e, value, row.original.status)
                    }
                    className={`${styles.searchIcon} mr-3`}
                    src={ActivatedIcon}
                    alt=""
                  />
                )}
              {productAccess &&
                productAccess.permissions.delete_access &&
                row.original.slug !== "custom-product" &&
                row.original.slug !== "subscription-item" && (
                  <img
                    onClick={(e) => handleDeleteProductClick(e, value)}
                    className={`${styles.searchIcon} mr-3`}
                    src={DeleteIcon}
                    alt=""
                  />
                )}
            </div>
          );
        },
      },
    ],
    [
      // changeSequenceData,
      data,
      // handleBuureSequece,
      // loadMoreData,
      // renderCategory,
      // statusList,
      // typeList,
    ]
  );
  return (
    <React.Fragment>
      <Header
        title="Product Management"
        hasAddButton={access.write_access}
        handleAddClick={handleManageProductClick}
      />
      <div id="MainContent" className="main-content" />
      <div>
        <ReactTable
          columns={columns}
          data={data}
          expandedRows={true}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
        />
      </div>
      <DeleteConfirmation
        showModal={showDeleteModal}
        title="Delete Product"
        message="Are you sure want to delete this Product?"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteModal(false);
          setDeleteProductId(null);
        }}
        handleSubmitClick={handleDeleteProduct}
      />

      <StatusConfirmation
        showModal={showStatusModal}
        title={"Product"}
        message={"product?"}
        buttonLabel={statusOfProductId ? "Inactivate" : "Activate"}
        statusFlag={statusOfProductId}
        customFlag={false}
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowStatusModal(false);
          setStatusChangeProductId(null);
          setStatusOfProductId(null);
        }}
        handleSubmitClick={handleProductStatus}
      />
      <ProductSequnceModel
        showModal={showSequenseNumber}
        pageTitle="Set Sequence"
        handleCancelClick={(e) => {
          setShowSequenseNumber(false);
        }}
        sequnceNumber={sequnceNumber}
        setSequnceNumber={setSequnceNumber}
        index={sequnceIndex}
        setSequnceIndex={setSequnceIndex}
        submitCall={(index, value, indexId) => {
          handleBuureSequece(index, value, indexId);
        }}
        productId={productId}
      />
    </React.Fragment>
  );
};

export default withRouter(ProductManagement);
