import { useFormik } from "formik";

export function BannerForm(
  submit, 
  initialVal, 
  cb,
  checkBannerData
) {
  const validation = (values) => {
    // console.log('BannerForm validation');
    let errors = {};
    let bannerError = false;
    if (values.button_label) {
      if (!values.button_url) {
        errors.button_url = "Banner url required";
        bannerError = true;
      }
    }
    if (!values.image) {
      errors.image = "Image required";
      bannerError = true;
    }

    console.log('bannerError 111 ===>', errors, bannerError);
    checkBannerData(bannerError);
    if (cb) {
      cb(values);
    }
    return errors;
  };

  const initialValues = {
    id: 0,
    parent_id: 0,
    module: "",
    position: "LEFT",
    banner_text: "",
    button_label: "",
    button_url: "",
    description: "",
    image: "",
    mobile_image:"",
    is_supported_image: 0,
    show_oval: 0
  };

  const formik = useFormik({
    initialValues: initialVal || initialValues,
    onSubmit: submit,
    validate: validation,
    enableReinitialize: true,
  });
  return { formik };
}
