import React from "react";

// SCSS
import erroricon from "../../Assets/images/error-icon.svg";
import styles from "./OrderError.module.scss";

const OrderError = ({ errors }) => {
  if (!errors || errors?.length < 0) return <></>;
  return (
    <div className={`${styles.errorWrapper}`}>
      {errors?.length > 0 &&
        errors?.map((error, index) => {
          return (
            <div className={`${styles.errorItem}`} key={index}>
              <span className={`${styles.errorIcon}`}>
                {index === 0 && <img src={erroricon} alt="error" />}
              </span>
              <span
                className={`${styles.errorLabel} ${
                  index === 0 ? styles.ml15 : styles.ml40
                }`}
              >
                {error.error}
              </span>
            </div>
          );
        })}
    </div>
  );
};

export default OrderError;
