import Interceptors from "../Interceptors/Interceptors";

class ChangeToOndemandApi {
  constructor() {
    this.interceptors = new Interceptors();
  }

  async getChanageToOndemandDetails(data) {
    return this.interceptors.POST("delivery-matrix/list-by-date-burq", data);
  }

  async updateChangeToOndemand(data){
    return this.interceptors.POST("orders/transfer_order_onfleet_to_burq", data);
  }

}
export default ChangeToOndemandApi;