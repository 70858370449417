import { useEffect } from "react";
import styles from "./Payabli.module.css";
import PayabliComponent from "Helpers/PayabliLibraryComponent";

//PAYABLI
const PaymentEditCardComponent = (props) => {
  useEffect(() => {
    // Load Payabli Component script
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_PAYABLI_COMPOENT_URL;
    script.async = true;
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const token = process.env.REACT_APP_PAYABLI_TOKEN;
    const url = new URL(process.env.REACT_APP_API_URL);
    const domainPath = `${url.protocol}//${url.hostname}${url.port ? `:${url.port}` : ''}`; 
    const customCssUrl=`${domainPath}/Payabli/cardFormAdmin.css`;
    const timestamp = Date.now();
    const payabliConfigCard = {
      type: "methodEmbedded",
      rootContainer: "edit-component-card",
      defaultOpen: "card",
      token: token,
      temporaryToken: true, // Set the token to be temporary
      forceCustomerCreation: false,
      customCssUrl,
      entryPoint: props.entryPoint,
      customerData: {
        customerNumber: `${timestamp}` // Pass the timestamp as customerNumber
      },
      card: {
        enabled: true,
        inputs: {
          // cardHolderName: {
          //   label: "Card Holder",
          //   placeholder: "name",
          //   size: 6,
          //   className: styles.input,
          // },
          cardNumber: {
            label: "Card Number",
            placeholder: "1234 1234 1234 1234",
            size: 6,
            className: styles.input,
          },
          cardExpirationDate: {
            label: "Expiry Date",
            placeholder: "MM/YY",
            size: 6,
            className: styles.input,
          },
          cardCvv: {
            label: "CVC",
            placeholder: "CVV/CVC",
            size: 6,
            className: styles.input,
          },
        },
      },
      ach: {
        enabled: true,
        inputs: {
          achAccountHolderName: { label: "ACCOUNTHOLDER NAME", size: 6 },
          achRouting: { label: "ROUTING NUMBER", size: 6 },
          achAccount: { label: "ACCOUNT NUMBER", size: 6 },
        },
      },
      functionCallBackSuccess: function (response) {
        if (response.isSuccess) {
          const nonce = response.responseData.referenceId;
          props.handleSubmitPaymentCard(nonce);
        }
      },
      functionCallBackError: function (errors) {
        console.log("errors: ", errors);
      },
    };

    // Payabli component initialization
    const payabliInstance = new PayabliComponent(payabliConfigCard);
    window.payabliEditCardInstance = payabliInstance;
  }, []);

  return (
    <>
      <div
        id="embeddedComponentStyle"
        className={`${styles.embeddedComponentStyle} ${props.className}`}
      >
        <div className="payment-form tab-content">
          <div
            className="tab-pane fade show active"
            id="edit-component-card-cont"
            role="tabpanel"
          >
            <div id="edit-component-card"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentEditCardComponent;
