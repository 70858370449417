import { useContext } from "react";
import { DeliveryMatrixContext } from "./DeliveryMatrixContext";
import { DeliveryMatrixAction } from "./DeliveryMatrixAction";

export function useDeliveryMatrix() {
  const { DeliveryMatrixState, dispatch } = useContext(DeliveryMatrixContext);

  const setDeliveryMatrixName = (values) => {
    dispatch({ type: DeliveryMatrixAction.SET_DELIVERY_MATRIX_NAME, payload: values });
  };

  const setDeliveryMatrixZipcode = (values) => {
    dispatch({ type: DeliveryMatrixAction.SET_DELIVERY_MATRIX_ZIPCODE, payload: values });
  };

  const setDeliveryMatrixAddressType = (values) => {
    dispatch({ type: DeliveryMatrixAction.SET_DELIVERY_MATRIX_ADDRESS_TYPE, payload: values });
  };

  const setDeliveryMatrixDays = (values) => {
    dispatch({ type: DeliveryMatrixAction.SET_DELIVERY_MATRIX_DAYS, payload: values });
  };

  const setDeliveryMatrixStartDate = (values) => {
    dispatch({ type: DeliveryMatrixAction.SET_DELIVERY_MATRIX_START_DATE, payload: values });
  };

  const setDeliveryMatrixEndDate = (values) => {
    dispatch({ type: DeliveryMatrixAction.SET_DELIVERY_MATRIX_END_DATE, payload: values });
  };

  const addDeliveryTimeData = (values) => {
    dispatch({ type: DeliveryMatrixAction.SET_DELIVERY_TIME_DATA, payload: values });
  };

  const deleteDeliveryTimeData = (values) => {
    dispatch({ type: DeliveryMatrixAction.DELETE_DELIVERY_TIME_DATA, payload: values });
  };

  const editDeliveryTimeData = (values,index) => {
    dispatch({ type: DeliveryMatrixAction.EDIT_DELIVERY_TIME_DATA, payload: values,index:index });
  };

  const clearDeliveryState = (values) => {
    dispatch({ type: DeliveryMatrixAction.CLEAR_DELIVERY_MATRIX_DATA, payload: values});
  };

  const deleteAllDeliveryItemData = (values) => {
    dispatch({ type: DeliveryMatrixAction.DELETE_ALL_DELIVERY_TIME_DATA, payload: values});
  };

  return {
    DeliveryMatrixState,
    setDeliveryMatrixName,
    setDeliveryMatrixZipcode,
    setDeliveryMatrixAddressType,
    setDeliveryMatrixDays,
    setDeliveryMatrixStartDate,
    setDeliveryMatrixEndDate,
    addDeliveryTimeData,
    deleteDeliveryTimeData,
    editDeliveryTimeData,
    clearDeliveryState,
    deleteAllDeliveryItemData
  };
}
