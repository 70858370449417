import React, { useContext } from "react";
import InputType from "../FormControl/Text/Text";
import TextArea from "../FormControl/TextArea/TextArea";
import styles from "./index.module.scss";
import AddBlackIcon from "./../../Assets/images/add-black.svg";
import DeleteIcon from "../../Assets/images/delete.svg";

import { OgInfoForm } from "../../Hooks/Seo/ogInfoFormFormik";

import { AppContext } from "../../Context/AppContext";
import UtilsApi from "../../Services/Utils/Utils";

const Oginfo = (props) => {
  const utilsService = new UtilsApi();
  const { showToastMessage, setIsLoading } = useContext(AppContext);

  const ogInfoData = props.data ? props.data : {};
  const ogInfoInitalValues = {
    og_title: ogInfoData.og_title ? ogInfoData.og_title : "",
    og_description: ogInfoData.og_description ? ogInfoData.og_description : "",
    og_image_url: ogInfoData.og_image_url ? ogInfoData.og_image_url : "",
    og_url: ogInfoData.og_url ? ogInfoData.og_url : "",
    og_site_name: ogInfoData.og_site_name ? ogInfoData.og_site_name : "",
  };

  const submit = (values) => {};
  const cbSubmit = (values) => {
    props.setOgInfoData(values);
  };

  const { formik: ogInfoFormik } = OgInfoForm(
    submit,
    ogInfoInitalValues,
    cbSubmit,
    props.checkOgInfoData
  );

  const ogImageRef = React.useRef();

  const handleUploadImage = async (imageData, imageType = "OTHER") => {
    let imageFormdata = new FormData();
    imageFormdata.append("image", imageData);
    imageFormdata.append("type", imageType);
    setIsLoading(true);
    const response = await utilsService.uploadImage(imageFormdata);
    if (response && response.status === 200) {
      setIsLoading(false);
      return response.data.data;
    } else {
      setIsLoading(false);
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };


  const deleteOgImageData = () => {
    ogInfoFormik.setValues((v) => {
      return {
        ...v,
        og_image_url: "",
        og_image_type: "",
        og_image_height: 0,
        og_image_width: 0,
      };
    });
  };

  return (
    <React.Fragment>
      <form
        onSubmit={(e) => ogInfoFormik.handleSubmit(e)}
        id={`on-info-section`}
      >
        <div>
          <h3 className={styles.seotitle}>OG Info</h3>
          <InputType
            type="text"
            label="OG Title"
            value={ogInfoFormik.values.og_title}
            required={true}
            onChange={(data) => {
              ogInfoFormik.setValues((v) => {
                return {
                  ...v,
                  og_title: data,
                };
              });
            }}
            onBlur={ogInfoFormik.handleBlur}
            touched={ogInfoFormik.touched.og_title}
            error={ogInfoFormik.errors.og_title}
            id="og_title"
          />
          <TextArea
            label="OG Description"
            rows={3}
            value={ogInfoFormik.values.og_description}
            required={true}
            onChange={(data) => {
              ogInfoFormik.setValues((v) => {
                return {
                  ...v,
                  og_description: data,
                };
              });
            }}
            onBlur={ogInfoFormik.handleBlur}
            touched={ogInfoFormik.touched.og_description}
            error={ogInfoFormik.errors.og_description}
            id="og_description"
          />
          <div className="form-group bmd-form-group is-filled text-left">
            <label className="bmd-label-floating">OG Image URL</label>
            <div
              onClick={() => {
                ogImageRef.current.click();
              }}
              className={styles.uploadBox}
            >
              <input
                type="file"
                className="d-none"
                ref={ogImageRef}
                accept="image/*"
                
                onChange={async (e) => {
                  const file = e.target.files[0];
                  const uploadResponse = await handleUploadImage(file);
                  // console.log('uploadResponse', uploadResponse);
                  ogInfoFormik.setValues((v) => {
                    return {
                      ...v,
                      og_image_url: uploadResponse[0].url,
                      og_image_type: uploadResponse[0].mimeType,
                      og_image_height: uploadResponse[0].height,
                      og_image_width: uploadResponse[0].width,
                    };
                  });
                }}
              />

              {ogInfoFormik.values.og_image_url ? (
                <img
                  className="img-fluid"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={ogInfoFormik.values.og_image_url}
                  alt="OG_Image_URL"
                />
              ) : (
                <img src={AddBlackIcon} alt="OG_Image_URL" />
              )}
            </div>
            {ogInfoFormik.values.og_image_url ? (
              <img
                onClick={(e) => {
                  e.preventDefault();
                  deleteOgImageData();
                }}
                src={DeleteIcon}
                style={{
                  position: "absolute",
                  top: "30px",
                  left: "125px",
                  cursor: "pointer",
                }}
                width="14px"
                height="14px"
                alt="DELETE"
              />
            ):(
              ""
            )}
            {ogInfoFormik.errors.og_image_url ? (
              <p class="invalid-feedback d-block">
                {/* {ogInfoFormik.errors.og_image_url} */}
              </p>
            ) : (
              ""
            )}
          </div>
          <InputType
            type="text"
            label="OG URL"
            value={ogInfoFormik.values.og_url}
            required={true}
            onChange={(data) => {
              ogInfoFormik.setValues((v) => {
                return {
                  ...v,
                  og_url: data,
                };
              });
            }}
            onBlur={ogInfoFormik.handleBlur}
            touched={ogInfoFormik.touched.og_url}
            error={ogInfoFormik.errors.og_url}
            id="og_url"
          />
          <InputType
            type="text"
            label="OG Sitename"
            value={ogInfoFormik.values.og_site_name}
            required={true}
            onChange={(data) => {
              ogInfoFormik.setValues((v) => {
                return {
                  ...v,
                  og_site_name: data,
                };
              });
            }}
            onBlur={ogInfoFormik.handleBlur}
            touched={ogInfoFormik.touched.og_site_name}
            error={ogInfoFormik.errors.og_site_name}
            id="og_site_name"
          />
          <button
            className="d-none og-info-submit-button"
            type="submit"
          ></button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default Oginfo;
