import React, { useState } from "react";
import styles from "./index.module.scss";
import AddIcon from "../../Assets/images/add.svg";
import RelatedCategoryForm from "./RelatedCategoryForm";

import { useLocation } from "../../Context/Cms-Locations/useLocation";

export default function RelatedCategory(props) {
  const {
    locationState,
    setRelatedCategory,
    updateRelatedCategory,
    deleteRelatedCategory,
  } = useLocation();
 
  const setSelectedData = async (data,index) => {
    // console.log(data);
    // console.log(index);
    updateRelatedCategory(data,index);
  };

  return (
    <div className={`mt-2 mb-4`} id={`related-category`}>
      <div className={styles.title}>Related Category</div>
      <span
        className={`cursor-pointer ml-2 mt-1 ${styles.searchIconWrapper}`}
        onClick={(e) => {
          e.preventDefault();
          setRelatedCategory();
        }}
      >
        <img className={styles.searchIcon} src={AddIcon} alt="Add" />
      </span>

      <div>
        {locationState.cms_location_categories &&
          locationState.cms_location_categories.map((element, index) => (
            <RelatedCategoryForm
              key={index}
              index={index}
              deleteCategory={deleteRelatedCategory}
              categoryList={props.categoryList}
              setSelectedData={setSelectedData}
              selectedData={locationState.cms_location_categories[index]}
            />
          ))}
      </div>
    </div>
  );
}
