import { useFormik } from "formik";

export function CategoryPrimaryInfoForm(
      submit,
      initialVal,
      cb,
      checkCategoryPrimaryData
) {

      const validation = (values) => {

            let errors = {};
            let categoryPrimaryError = false;
           
            if (!values.status) {
                  errors.status = "Category Status is required";
                  categoryPrimaryError = true;
            } else {
                  categoryPrimaryError = false;
            }
            // if (!values.sequence_number) {
            //       errors.sequence_number = "Category Sequence number is required";
            //       categoryPrimaryError = true;
            // } else {
            //       categoryPrimaryError = false;
            // }
            if (!values.title) {
                  errors.title = "Category Title is required";
                  categoryPrimaryError = true;
            } else {
                  categoryPrimaryError = false;
            }

            if (cb) {
                  cb(values);
            }
            
            checkCategoryPrimaryData(categoryPrimaryError);
            return errors;
      };

      const initialValues = {
            title: "",
            type: "",
            category_image: "",
            keywords: "",
            customer_note: "",
            sequence_number: "",
            status: "",
            is_filter: "",
            description: "",
            show_certification: "",
      };

      const formik = useFormik({
            initialValues: initialVal || initialValues,
            onSubmit: submit,
            enableReinitialize: true,
            validate: validation,
      });

      return { formik };
}