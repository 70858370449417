import Header from "Components/Header/Header";
import ReactTable from "Components/Table/Table";
import { AppContext } from "Context/AppContext";
import { MODULES } from "Helpers/Constants";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
// Components
import { getAccessByModuleName } from "Routes/Routes";
import { getSubscriptionOrder } from "api/order";
import { FREQUENCY_LIST } from "Helpers/Constants";
// import UsersApi from "./../../../services/Users/Users";
import close from "Assets/images/close.svg";
import styles from "./index.module.scss";
import StatusConfirmation from "Components/Modal/StatusConfirmation/StatusConfirmation";
import { updateStatus } from "api/utils";
import ViewIcon from "Assets/images/error-outline.svg";

import LoadingScreen from "Components/Loading/LoadingScreen";

const SubscriptionList = (props) => {
  const { showToastMessage, modules, setIsLoading } = useContext(AppContext);
  // Add / Edit
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
  });

  const [subscriptionId, setSubscriptionId] = useState(null);
  const [firstRender, setFirstRender] = useState(true);

  // Set Async Data
  const setAsyncData = (data) => {
    let asyncDataValuesAccountLookup = JSON.stringify(data);
    localStorage.setItem(
      "asyncDataValuesSubscriptionOrder",
      asyncDataValuesAccountLookup
    );
  };

  // Get And Set Data Into State
  useEffect(() => {
    const filterData = JSON.parse(
      localStorage.getItem("asyncDataValuesSubscriptionOrder")
    );
    if (filterData) {
      const {
        currentPage,
        itemsPerPage,
        filters,
        sortBy,
      } = filterData;
      setDefaultFilter({
        filters: filters,
        pageIndex: currentPage,
        pageSize: itemsPerPage,
        sortBy: sortBy,
      });
    }
    setFirstRender(false);
  }, []);

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      filters,
      sortBy,
    };

    let accountAccess = getAccessByModuleName(
      modules,
      MODULES.SUBSCRIPTION_LIST
    );
    if (accountAccess && accountAccess.permissions.read_access) {
      const response = await getSubscriptionOrder(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }
    setAsyncData(postData);
    setLoading(false);
  };

  /* Check Module Access */

  let subscriptionOrderList = getAccessByModuleName(
    modules,
    MODULES.SUBSCRIPTION_LIST
  );
  const columns = React.useMemo(() => [
    {
      Header: "Order Number",
      accessor: "order_number",
      placeholder: "Order Number",
      isVisible: true,
      Cell: ({ value, row, column }) => {
        return `${row.original.order.order_number}`;
      },
    },
    {
      Header: "Order Total",
      accessor: "total",
      placeholder: "Order Total",
      isVisible: true,
      Cell: ({ value, row, column }) => {
        return `${row.original.order.total}`;
      },
    },
    {
      Header: "Customer Name",
      accessor: "customer_name",
      placeholder: "Customer Name",
      isVisible: true,
      Cell: ({ value, row, column }) => {
        return `${row.original.customer.customer_name}`;
      },
    },
    {
      Header: "Customer Email",
      accessor: "email",
      placeholder: "Customer Email",
      isVisible: true,
      Cell: ({ value, row, column }) => {
        return `${row.original.customer.email}`;
      },
    },
    {
      Header: "Subscription Type",
      accessor: "productType",
      placeholder: "Subscription Type",
      isVisible: true,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Subscription Status",
      accessor: "status",
      isVisible: true,
      Cell: ({ value, row, column }) => {
        return (
          <div className={styles.status}>
            {row.original.status === 1 ? (
              <div className={styles.Active}>Active</div>
            ) : (
              <div className={styles.Inactive}>Inactive </div>
            )}
          </div>
        );
      },
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Frequency",
      accessor: "frequency",
      isVisible: true,
      Cell: ({ value, row, column }) => {
        return `${FREQUENCY_LIST[row.original.frequency]}`;
      },
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: "Action",
      accessor: "cancel",
      isVisible: true,
      Cell: ({ value, row, column }) => {
        return (
          <>
            <img
              className={`cursor-pointer mr-2 mb-3 view-icon`}
              src={ViewIcon}
              alt="View Order"
              onClick={(e) => {
                e.preventDefault();
                props.history.push(
                  `/admin/subscriptions/list/${row.original.order.id}`
                );
              }}
            />
            {subscriptionOrderList && subscriptionOrderList.permissions.delete_access && (
              <div
                style={{
                  display: row.original.status ? "inline-flex" : "none",
                  backgroundColor: row.original.status ? "#CB998D" : "#ccc",
                  width: "30px",
                  height: "30px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (row.original.status) {
                    setSubscriptionId(row.original.id);
                  }
                }}
              >
                <img
                  style={{ maxWidth: "100%", height: "auto" }}
                  src={close}
                  alt="Close Icon"
                />
              </div>
            )}
          </>
        );
      },
      disableFilters: true,
      disableSortBy: true,
    },
  ]);

  if (firstRender) return <LoadingScreen />;
  return (
    <React.Fragment>
      <Header title={`Subscription Order List`} />

      <div id="MainContent" className="main-content" />
      <div>
        <ReactTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
          displayColumnVisibility={false}
        />
        <StatusConfirmation
          showModal={!!subscriptionId}
          title={"Subscription"}
          message={"subscription?"}
          buttonLabel={"Activate"}
          statusFlag={true}
          customFlag={false}
          handleCancelClick={(event) => {
            event.preventDefault();
            setSubscriptionId(null);
          }}
          handleSubmitClick={async (e) => {
            e.preventDefault();
            setIsLoading(true);
            //Disable Subscription
            let postData = {
              status: 0,
              id: subscriptionId,
              table: "subscription_orders",
            };

            // const response = await utilsService.updateStatus(postData);
            const response = await updateStatus(postData);
            if (response && response.status === 200) {
              setSubscriptionId(null);
              showToastMessage(
                undefined,
                response.data.message,
                "success",
                false
              );
              fetchData(defaultFilter);
            } else
              showToastMessage(
                undefined,
                response.data.message,
                "error",
                false
              );
            setIsLoading(false);
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(SubscriptionList);
