import * as PRODUCT_ACTIONS from "./ProductAction";

export function productReducer(state, action) {
  switch (action.type) {
    case PRODUCT_ACTIONS.SET_MENU_LIST:
      return {
        ...state,
        menuList: action.payload,
      };

    case PRODUCT_ACTIONS.SET_PRODUCT_LIST:
      return {
        ...state,
        productList: action.payload,
      };

    case PRODUCT_ACTIONS.SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload,
      };

    case PRODUCT_ACTIONS.CLEAR_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: null,
      };

    case PRODUCT_ACTIONS.SET_CURRENT_ADD:
      return {
        ...state,
        setCurrentProductToAdd: action.payload,
      };

    case PRODUCT_ACTIONS.CLEAR_CURRENT_ADD:
      let emptyArray = [];
      return {
        ...state,
        setCurrentProductToAdd: null,
        selectedProductArray: emptyArray,
      };
    case PRODUCT_ACTIONS.PRODUCT_ADD:
      let p = [...state.addedProducts];
      let selectedproduct = action.payload;
      selectedproduct["qty"] = 1;
      p.push(selectedproduct);
      return {
        ...state,
        addedProducts: p,
      };

    case PRODUCT_ACTIONS.PRODUCT_VARIANT_SELECTED:
      let variationProduct = [...state.selectedVariation, ...action.payload];
      return {
        ...state,
        selectedVariation: variationProduct,
      };
    case PRODUCT_ACTIONS.CLR_VARIANT_SELECTED:
      let emtyVariationArray = [];
      return {
        ...state,
        selectedVariation: emtyVariationArray,
      };
    case PRODUCT_ACTIONS.SELECTED_PRODUCT_DATA_CMS:
      return {
        ...state,
        selectedProductAddCMS: action.payload,
      };
    case PRODUCT_ACTIONS.DELETE_PRODUCT_SELECTED:
      let data = [...state.addedProducts];
      data.splice(action.payload, 1);
      return {
        ...state,
        addedProducts: data,
      };

    default:
      return state;
  }
}
