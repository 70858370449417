import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";

// Scss
import styles from "./index.module.scss";

// Context
import { AppContext } from "./../../../Context/AppContext";
import { getAccessByModuleName } from "./../../../Routes/Routes";
import { MODULES, STATUS_FILTER } from "./../../../Helpers/Constants";

// Components
import Header from "./../../../Components/Header/Header";
import ReactTable from "../../../Components/Table/Table";
import DeleteConfirmation from "./../../../Components/Modal/DeleteConfirmation/DeleteConfirmation";
import StatusConfirmation from "../../../Components/Modal/StatusConfirmation/StatusConfirmation";

// Services
import UtilsApi from "../../../Services/Utils/Utils";
import CmsPagesApi from "../../../Services/CmsPages/CmsPages";
import { updateStatus } from "../../../api/utils";
import { getCmsPagesList, deleteCmsPage } from "../../../api/cmsPages";

// Images
import EditIcon from "./../../../Assets/images/edit.svg";
import DeleteIcon from "./../../../Assets/images/delete.svg";
import ActivatedIcon from "./../../../Assets/images/activated.svg";

const CmsPages = (props) => {
  const history = useHistory();

  const utilsService = new UtilsApi();
  const CmsPagesService = new CmsPagesApi();

  const { showToastMessage, setIsLoading, modules } = useContext(AppContext);

  const [access, setAccess] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [defaultFilter, setDefaultFilter] = useState({
    currentPage: 0,
    itemsPerPage: 10,
    sortBy: [],
    filters: [],
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCmsPageId, setDeleteCmsPageId] = useState();
  const [statusCmsPageId, setStatusCmsPageId] = useState(false);
  const [statusOfCmsPage, setStatusOfCmsPage] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);

  const statusList = STATUS_FILTER;

  useEffect(getModuleAccess, [modules]);
  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.PAGES);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push("/users");
    }
  }

  const handelDeleteClick = async (event, id) => {
    event.preventDefault();

    setDeleteCmsPageId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteCmsPage = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // const response = await CmsPagesService.deleteCmsPage(deleteCmsPageId);
    const response = await deleteCmsPage(deleteCmsPageId);
    if (response && response.status === 200) {
      setDeleteCmsPageId(null);
      setShowDeleteModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  const handleCmsPageClick = async (event, id, status) => {
    event.preventDefault();

    setStatusCmsPageId(id);
    setStatusOfCmsPage(status);
    setShowStatusModal(true);
  };

  const handleCmsPageStatus = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let postData = {
      status: statusOfCmsPage === 1 ? 0 : 1,
      id: statusCmsPageId,
      table: "cms_pages",
    };

    // const response = await utilsService.updateStatus(postData);
    const response = await updateStatus(postData);
    if (response && response.status === 200) {
      setStatusCmsPageId(null);
      setStatusOfCmsPage(null);
      setShowStatusModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    setIsLoading(false);
  };

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
    };
    // const response = await CmsPagesService.getCmsPagesList(postData);
    const response = await getCmsPagesList(postData);

    if (response && response.status === 200) {
      setData(response.data.data.rows);
      setPageCount(Math.ceil(response.data.data.count / pageSize));
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
    setLoading(false);
  };

  const handleAddCmsPageClick = async (event, type = "add", id = "") => {
    event.preventDefault();
    if (type === "add") {
      props.history.push("/cms/cms-page/add");
    } else {
      props.history.push(`/cms/cms-page/edit/${id}`);
    }
  };

  let pageAccess = getAccessByModuleName(modules, MODULES.PAGES);
  const columns = React.useMemo(
    () => [
      {
        Header: "Page Name",
        accessor: "title",
        isVisible: true,
        placeholder: "Search by Category Name",
      },
      {
        Header: "Page Slug",
        accessor: "slug",
        isVisible: true,
        placeholder: "Search by Category Slug",
      },
      {
        Header: "Status",
        accessor: "status",
        isVisible: true,
        placeholder: "Status",
        isSelectFilter: true,
        selectFilterOption: statusList,
        Cell: ({ value, row, column }) => {
          return (
            <div className={styles.status}>
              {row.original.status === 1 ? (
                <div className={styles.Active}>Active</div>
              ) : (
                <div className={styles.Inactive}>Inactive </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {pageAccess && pageAccess.permissions.write_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={EditIcon}
                  alt="Edit"
                  onClick={(e) =>
                    handleAddCmsPageClick(e, value, row.original.id)
                  }
                />
              )}
              {pageAccess && pageAccess.permissions.write_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={ActivatedIcon}
                  alt="Active"
                  onClick={(e) =>
                    handleCmsPageClick(e, value, row.original.status)
                  }
                />
              )}

              {pageAccess && pageAccess.permissions.delete_access && (
                <img
                  className={`cursor-pointer ml-3`}
                  src={DeleteIcon}
                  alt="Delete"
                  onClick={(e) => handelDeleteClick(e, value)}
                />
              )}
            </div>
          );
        },
      },
    ],
    [data]
  );

  return (
    <React.Fragment>
      <Header
        title="CMS Page"
        hasAddButton={1}
        handleAddClick={handleAddCmsPageClick}
      />
      <div id="MainContent" className="main-content" />
      <div>
        <ReactTable
          columns={columns}
          data={data}
          expandedRows={true}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
        />
      </div>

      <DeleteConfirmation
        showModal={showDeleteModal}
        title="Delete Cms Page"
        message="Are you sure want to delete this cms page?"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteModal(false);
          setDeleteCmsPageId(null);
        }}
        handleSubmitClick={handleDeleteCmsPage}
      />

      <StatusConfirmation
        showModal={showStatusModal}
        title={"Cms Page"}
        message={"cms page?"}
        buttonLabel={statusOfCmsPage ? "Inactivate" : "Activate"}
        statusFlag={statusOfCmsPage}
        customFlag={false}
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowStatusModal(false);
          setStatusCmsPageId(null);
          setStatusOfCmsPage(null);
        }}
        handleSubmitClick={handleCmsPageStatus}
      />
    </React.Fragment>
  );
};

export default withRouter(CmsPages);
