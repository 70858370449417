export const homePageState = {
  scrollable_text: "",
  banners: [
    {
      id: 0,
      parent_id: 1,
      module: "home-page",
      position: "LEFT",
      banner_text: "",
      button_label: "",
      button_url: "",
      description: "",
      image: "",
      is_supported_image: 1,
      show_oval: 0,
    },
  ],
  seo: {
    id: 0,
    parent_id: 0,
    module: "home-page",
    is_robot: false,
    is_no_follow: false,
    is_no_index: false,
    image_alt_text: "",
    page_title: "",
    meta_title: "",
    meta_description: "",
    canonical_url: "",
    og_title: "",
    og_description: "",
    og_image_url: "",
    og_image_alt: "",
    og_url: "",
    og_image_type: "",
    og_site_name: "",
    og_image_height: 0,
    og_image_width: 0,
    twitter_title: "",
    twitter_image_url: "",
    twitter_description: "",
    twitter_site: "",
    twitter_creator: "",
    schema: "",
  },
  worked_with: [
    {
      id: 0,
      name: "",
      image: "",
    },
  ],
  certifications: [
    {
      id: 0,
      title: "",
      image: "",
    },
  ],
  product_categories: [
    {
      is_main_section: false,
      id: 0,
      parent_id: 0,
      module: "home-page",
      sequence_number: 1,
      name: "",
      button_label: "",
      button_url: "",
      theme_color: "",
      description: "",
      main_parent_id: 0,
      products: [
        {
          product_id: 0,
          sequence_number: 1,
          id: 0,
          title: "",
          product_images: [],
        }
      ],
      children: []
    }
  ],
  bannerError: false,
  seoError: false,
  ogInfoError: false,
  twitterError: false,
  schemaError: false,
  featuredProductError: false,
  featuredCategoryError: false,
};