import React, { useContext } from "react";
import Text from "../FormControl/Text/TextInput";
import classes from "./index.module.scss";
import RadioBoxLabel from "../FormControl/RadioBoxLabel/RadioBoxLabel";
import CheckBox from "../FormControl/CheckBox/CheckBox";
import styles from "./index.module.scss";
import TextArea from "../FormControl/TextArea/TextArea";
import CloseIcon from "../../Assets/images/close-icon.svg";
import { BannerForm } from "../../Hooks/bannerFormFormik";
import { AppContext } from "../../Context/AppContext";
import UtilsApi from "../../Services/Utils/Utils";
import DefaultImage from "../../Assets/images/default-signature.svg";
import DefaultWebImage from "../../Assets/images/default-signature-web.svg";
import DefaultMobImage from "../../Assets/images/default-signature-mob.svg";
const BannerInput = (props) => {
  const utilsService = new UtilsApi();
  const { showToastMessage, setIsLoading } = useContext(AppContext);

  const bannerData = props.bannerData ? props.bannerData : {};
  const bannerInitalValues = {
    id: bannerData.id ? bannerData.id : 0,
    parent_id: bannerData.parent_id ? bannerData.parent_id : 0,
    module: bannerData.module
      ? bannerData.module
      : props.bannerModule
      ? props.bannerModule
      : "home-page",
    position: bannerData.position ? bannerData.position : "LEFT",
    banner_text: bannerData.banner_text ? bannerData.banner_text : "",
    button_label: bannerData.button_label ? bannerData.button_label : "",
    button_url: bannerData.button_url ? bannerData.button_url : "",
    description: bannerData.description ? bannerData.description : "",
    image: bannerData.image ? bannerData.image : "",
    mobile_image: bannerData.mobile_image ? bannerData.mobile_image : "",
    is_supported_image: bannerData.is_supported_image
      ? bannerData.is_supported_image
      : 0,
    show_oval: bannerData.show_oval ? bannerData.show_oval : 0,
  };

  const submit = (values) => {};
  const cbSubmit = (values) => {
    let payloadData = {
      values,
      index: props.index,
    };
    props.setBannerData(payloadData);
  };

  const { formik: bannerFormik } = BannerForm(
    submit,
    bannerInitalValues,
    cbSubmit,
    props.checkBannerData
  );

  const bannerImageRef = React.useRef();
  const bannerMobImageRef = React.useRef();

  const handleUploadImage = async (imageData, imageType = "OTHER") => {
    let imageFormdata = new FormData();
    imageFormdata.append("image", imageData);
    imageFormdata.append("type", imageType);
    setIsLoading(true);
    const response = await utilsService.uploadImage(imageFormdata);

    // console.log('handleUploadImage', response);
    if (response && response.status === 200) {
      setIsLoading(false);
      return response.data.data;
    } else {
      setIsLoading(false);
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          if (!bannerFormik.isValid) {
            showToastMessage(
              undefined,
              `Please enter required banner details ${props.index + 1}`,
              "error",
              false
            );
          }
          bannerFormik.handleSubmit(e);
        }}
        id={`banner-form-${props.index}`}
      >
        <div className={`row mb-1`}>
          <div className={`col-md-7`}>
            <div className={`${styles.bannerFormRadio}`}>
              <RadioBoxLabel
                label="Left Image"
                value="LEFT"
                type="Radio"
                className={styles.redioButton}
                selectedValue={bannerFormik.values.position}
                onChange={(data) => {
                  bannerFormik.setValues((v) => {
                    return {
                      ...v,
                      position: data,
                    };
                  });
                }}
                aria-disabled="true"
                type={"Radio"}
              />
              <RadioBoxLabel
                label="Right Image"
                value="RIGHT"
                type="Radio"
                selectedValue={bannerFormik.values.position}
                onChange={(data) => {
                  bannerFormik.setValues((v) => {
                    return {
                      ...v,
                      position: data,
                    };
                  });
                }}
                type={"Radio"}
              />
              <RadioBoxLabel
                label="Full Image"
                value="CENTER"
                type="Radio"
                selectedValue={bannerFormik.values.position}
                onChange={(data) => {
                  bannerFormik.setValues((v) => {
                    return {
                      ...v,
                      position: data,
                    };
                  });
                }}
                type={"Radio"}
              />
            </div>

            <Text
              type="text"
              label="Banner Text"
              value={bannerFormik.values.banner_text}
              onChange={(data) =>
                bannerFormik.setValues((v) => {
                  return { ...v, banner_text: data };
                })
              }
              onBlur={bannerFormik.handleBlur}
              touched={bannerFormik.touched.banner_text}
              error={bannerFormik.errors.banner_text}
              id="banner_text"
            />
            <Text
              type="text"
              label="Button Label"
              value={bannerFormik.values.button_label}
              onChange={(data) =>
                bannerFormik.setValues((v) => {
                  return { ...v, button_label: data };
                })
              }
              onBlur={bannerFormik.handleBlur}
              touched={bannerFormik.touched.button_label}
              error={bannerFormik.errors.button_label}
              id="button_label"
            />
            <Text
              type="text"
              label="Button URL"
              value={bannerFormik.values.button_url}
              onChange={(data) =>
                bannerFormik.setValues((v) => {
                  return { ...v, button_url: data };
                })
              }
              onBlur={bannerFormik.handleBlur}
              touched={bannerFormik.touched.button_url}
              error={bannerFormik.errors.button_url}
              id="button_url"
            />
            <TextArea
              type="text"
              label="Description"
              rows={3}
              value={bannerFormik.values.description}
              onChange={(data) =>
                bannerFormik.setValues((v) => {
                  return { ...v, description: data };
                })
              }
              onBlur={bannerFormik.handleBlur}
              touched={bannerFormik.touched.description}
              error={bannerFormik.errors.description}
              id="description"
            />
            <div className={`row`}>
              {/* <div className={`col-md-6`}>
                <CheckBox
                  label="Supported Image"
                  value={bannerFormik.values.is_supported_image}
                  onChange={(data) =>
                    bannerFormik.setValues((v) => {
                      return { ...v, is_supported_image: data };
                    })
                  }
                />
              </div> */}
              <div className={`col-md-6`}>
                <CheckBox
                  label="show oval"
                  value={bannerFormik.values.show_oval}
                  onChange={(data) =>
                    bannerFormik.setValues((v) => {
                      return { ...v, show_oval: data };
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className={`col-md-5`}>
            <a
              href
              className={`${classes.closeIconWrapper} cursor-pointer`}
              onClick={(e) => {
                e.preventDefault();
                props.removeBanner(e, props.index);
              }}
            >
              <img className={classes.closeIcon} src={CloseIcon} alt="Add" />
            </a>
            <div
              onClick={() => {
                bannerImageRef.current.click();
              }}
              className={classes.uploadBox}
              style={{
                border: "1px solid #e4e0e5",
                background: "#e4e0e5",
                height: (bannerInitalValues.module==="home-page") ? "42%" : "85%",
                width: "100%",
              }}
            >
              <input
                type="file"
                className="d-none"
                ref={bannerImageRef}
                accept="image/*"
                // onChange={async (e) => {
                //   const file = e.target.files[0];
                //   const url = await readURL(file);
                // }}
                onChange={async (e) => {
                  const file = e.target.files[0];
                  const uploadResponse = await handleUploadImage(
                    file,
                    props.uploadImageType
                  );
                  // console.log('uploadResponse', uploadResponse);
                  bannerFormik.setValues((v) => {
                    return {
                      ...v,
                      image: uploadResponse[0].url,
                    };
                  });
                }}
              />
              {bannerFormik.values.image ? (
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={bannerFormik.values.image}
                  alt="Banner_Image"
                />
              ) : (
                <img
                  style={{ width: "80%", height: "40%", objectFit: "cover" }}
                  src={ bannerInitalValues.module==="home-page" ? DefaultWebImage : DefaultImage}
                  alt="BannerImage"
                />
              )}
            </div>
            {bannerFormik.errors.image ? (
              <p class="invalid-feedback d-block">
                {bannerFormik.errors.image}
              </p>
            ) : (
              ""
            )}
            {bannerInitalValues.module==="home-page" ? (
              <>
              <div
                onClick={() => {
                  bannerMobImageRef.current.click();
                }}
                className={classes.uploadBox}
                style={{
                  border: "1px solid #e4e0e5",
                  background: "#e4e0e5",
                  height: "42%",
                  width: "100%",
                }}
              >
                <input
                  type="file"
                  className="d-none"
                  ref={bannerMobImageRef}
                  accept="image/*"
                  // onChange={async (e) => {
                  //   const file = e.target.files[0];
                  //   const url = await readURL(file);
                  // }}
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    const uploadResponse = await handleUploadImage(
                      file,
                      props.uploadImageType
                    );
                    // console.log('uploadResponse', uploadResponse);
                    bannerFormik.setValues((v) => {
                      return {
                        ...v,
                        mobile_image: uploadResponse[0].url,
                      };
                    });
                  }}
                />
                {bannerFormik.values.mobile_image ? (
                  <img
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    src={bannerFormik.values.mobile_image}
                    alt="Banner_Image"
                  />
                ) : (
                  <img
                    style={{ width: "80%", height: "40%", objectFit: "cover" }}
                    src={DefaultMobImage}
                    alt="BannerImage"
                  />
                )}
              </div>
              </>
            ) : ""}
          </div>
        </div>
        <button
          className="d-none banner-submit-button"
          id={`banner-submit-button-${props.index}`}
          type="submit"
        ></button>
      </form>
    </React.Fragment>
  );
};
export default BannerInput;
