import React, { useState, useEffect } from 'react';
import classes from './index.module.scss';
import Date from './../../../Components/FormControl/Date/Date';
import CalendarIcon from './../../../Assets/images/calendar.svg';
import moment from 'moment';
const TimeSetting = ({
  setReminderToDoValuesTimeSettings,
  repeatType,
  monthlyDate,
  keyValuesVal,
}) => {
  const [weeklyDays, setWeeklyDays] = React.useState(keyValuesVal);
  const [monthlyDateValue, setMonthlyDate] = React.useState(monthlyDate);
  const [activeTab, setActiveTab] = React.useState(repeatType);
  const changeTab = (name) => {
    setActiveTab(name);
    setReminderToDoValuesTimeSettings('repeatType', name);
    if (weeklyDays.length > 0) {
      setWeeklyDays([]);
    }
    if (monthlyDateValue) {
      setMonthlyDate(null);
    }
    setDays(defaultDays);
  };
  const setDaysVal = async (index) => {
    keyValuesVal = keyValuesVal ? keyValuesVal : [];
    let value = {
      day: days[index].day,
      value: days[index].value ? false : true,
    };
    let indexValue = keyValuesVal.findIndex(weekValue => weekValue == index);
    if (indexValue > -1 && !value.value) {
      keyValuesVal.splice(indexValue, 1);
    }
    if (indexValue === -1 && value.value) {
      keyValuesVal.push(index);
    }
    setReminderToDoValuesTimeSettings('keyValuesVal', keyValuesVal);
    setWeeklyDays(keyValuesVal);
    setDays({ ...days, [index]: value });
  };
  let defaultDays = [
    { day: 'M', value: false },
    { day: 'T', value: false },
    { day: 'W', value: false },
    { day: 'T', value: false },
    { day: 'F', value: false },
    { day: 'S', value: false },
    { day: 'S', value: false },
  ];
  const [days, setDays] = useState(defaultDays);
  const setDaysValue = async () => {
    if (keyValuesVal && keyValuesVal.length > 0) {
      let defaultDays = [
        {
          day: 'M',
          value: keyValuesVal && keyValuesVal.includes('0') ? true : false,
        },
        {
          day: 'T',
          value: keyValuesVal && keyValuesVal.includes('1') ? true : false,
        },
        {
          day: 'W',
          value: keyValuesVal && keyValuesVal.includes('2') ? true : false,
        },
        {
          day: 'T',
          value: keyValuesVal && keyValuesVal.includes('3') ? true : false,
        },
        {
          day: 'F',
          value: keyValuesVal && keyValuesVal.includes('4') ? true : false,
        },
        {
          day: 'S',
          value: keyValuesVal && keyValuesVal.includes('5') ? true : false,
        },
        {
          day: 'S',
          value: keyValuesVal && keyValuesVal.includes('6') ? true : false,
        },
      ];
      setDays(defaultDays);
    }
  };
  useEffect(() => {
    setDaysValue();
  }, []);
  useEffect(() => {
    setReminderToDoValuesTimeSettings('monthlyDate', monthlyDateValue);
  }, [monthlyDateValue]);
  useEffect(() => {
    setReminderToDoValuesTimeSettings('keyValuesVal', weeklyDays);
  }, [weeklyDays]);
  return (
    <React.Fragment>
      <div className={`${classes.timerFormRadio}`}>
        <ul className={classes.tab}>
          <li
            onClick={() => changeTab('DAILY')}
            key={'DAILY'}
            className={activeTab && activeTab === 'DAILY' ? classes.active : ''}
          >
            Everyday
          </li>
          <li
            onClick={() => changeTab('WEEKLY')}
            key={'WEEKLY'}
            className={
              activeTab && activeTab === 'WEEKLY' ? classes.active : ''
            }
          >
            Weekly
          </li>
          <li
            onClick={() => changeTab('MONTHLY')}
            key={'MONTHLY'}
            className={
              activeTab && activeTab === 'MONTHLY' ? classes.active : ''
            }
          >
            Monthly
          </li>
        </ul>
      </div>
      <div>
        {activeTab === 'DAILY' ? <div></div> : <div></div>}
        {activeTab === 'WEEKLY' ? (
          <div className={`mt-1`}>
            <div
              onClick={() => setDaysVal(0)}
              className={days[0].value ? classes.Days : classes.SelectedDay}
              key="0"
            >
              <span>{days[0].day}</span>
            </div>
            <div
              onClick={() => setDaysVal(1)}
              className={days[1].value ? classes.Days : classes.SelectedDay}
              key="1"
            >
              <span>{days[1].day}</span>
            </div>
            <div
              onClick={() => setDaysVal(2)}
              className={days[2].value ? classes.Days : classes.SelectedDay}
              key="2"
            >
              <span>{days[2].day}</span>
            </div>
            <div
              onClick={() => setDaysVal(3)}
              className={days[3].value ? classes.Days : classes.SelectedDay}
              key="3"
            >
              <span>{days[3].day}</span>
            </div>
            <div
              onClick={() => setDaysVal(4)}
              className={days[4].value ? classes.Days : classes.SelectedDay}
              key="4"
            >
              <span>{days[4].day}</span>
            </div>
            <div
              onClick={() => setDaysVal(5)}
              className={days[5].value ? classes.Days : classes.SelectedDay}
              key="5"
            >
              <span>{days[5].day}</span>
            </div>
            <div
              onClick={() => setDaysVal(6)}
              className={days[6].value ? classes.Days : classes.SelectedDay}
              key="6"
            >
              <span>{days[6].day}</span>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {activeTab === 'MONTHLY' ? (
          <div style={{ marginTop: '-5px' }}>
            <Date
              label="Reminder Date"
              value={monthlyDate}
              dateFormat="ddd MMM DD, YYYY"
              type="date"
              fieldIcon={CalendarIcon}
              onChange={(monthlyDate) => {
                setReminderToDoValuesTimeSettings(
                  'monthlyDate',
                  moment(monthlyDate).format('ddd MMM DD, YYYY')
                );
                setMonthlyDate(moment(monthlyDate).format('ddd MMM DD, YYYY'));
              }}
              checkPassDate={true}
              disableToday={true}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className={`mt-2 ${classes.repeatText}`}></div>
    </React.Fragment>
  );
};
export default TimeSetting;
