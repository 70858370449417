import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import React from "react";
// SCSS
import styles from "./Add.module.scss";
// Images

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DesignerList = ({
  showModal,
  pageTitle,
  handleCancelClick,
  designersList,
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={(e) => {
          e.preventDefault();
          handleCancelClick();
        }}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div
              className="row"
              style={{
                textAlign: "left",
                marginLeft: "15px",
                marginTop: "30px",
              }}
            >
              <div className="col-md-12 col-xs-12">
                {designersList &&
                  designersList.length > 0 &&
                  designersList.map((designer, index) => (
                    <h4>{index+1}. {designer.title}</h4>
                  ))}
                {designersList.length === 0 && <h4>No Designer Found</h4>}
              </div>
            </div>
            <button
              data-dismiss="modal"
              onClick={(e) => {
                handleCancelClick();
              }}
              className="btn gray-btn d-inline-block mt-5 mb-3 mr-2"
            >
              Close
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default DesignerList;
