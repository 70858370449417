import React from "react";
import RadioBoxLabel from "../../../Components/FormControl/RadioBoxLabel/RadioBoxLabel";
import styles from "./index.module.scss";
import Select from "../../../Components/FormControl/Select/Select";
const SortButton = (props) => {
  return (
    <div className={`row mb-3 ${styles.DashboardFormRadio}`}>
      <div className="col-sm-12 col-xs-12">
        <Select
          label=""
          value={props.timeValue}
          onChange={(data) => {
            props.setTimeValue(data);
          }}
          options={[
            { id: "Today", title: "Today" },
            { id: "Yesterday", title: "Yesterday" },
            { id: "Weekly", title: "Weekly" },
            { id: "Monthly", title: "Monthly" },
          ]}
          disabled={false}
        />
      </div>

      {/* <div className="col-md-3">
        <RadioBoxLabel
          label="Today"
          value="Today"
          onChange={(data) => {
            props.setTimeValue(data);
          }}
          selectedValue={props.timeValue}
          className={styles.redioButton}
          type={"Radio"}
        />
      </div>
      <div className="col-md-3">
        <RadioBoxLabel
          label="Yesterday"
          value="Yesterday"
          onChange={(data) => {
            props.setTimeValue(data);
          }}
          selectedValue={props.timeValue}
          className={styles.redioButton}
          type={"Radio"}
        />
      </div>
      <div className="col-md-3">
        <RadioBoxLabel
          label="Past Week"
          value="PastWeek"
          onChange={(data) => {
            props.setTimeValue(data);
          }}
          selectedValue={props.timeValue}
          className={styles.redioButton}
          type={"Radio"}
        />
      </div>
      <div className="col-md-3">
        <RadioBoxLabel
          label="Past 30 Days"
          value="PastDay"
          onChange={(data) => {
            props.setTimeValue(data);
          }}
          selectedValue={props.timeValue}
          className={styles.redioButton}
          type={"Radio"}
        />
      </div> */}
      {/* <div className="col">
        <RadioBoxLabel label="All Time" value="All Time" className={styles.redioButton}/>
      </div> */}
    </div>
  );
};

export default SortButton;
