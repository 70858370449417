import { callPostApi, callDeleteApi ,callPutApi, callGetApi } from "./api";

export async function getReminderList(postData) {
    try {
        const response = await callPostApi({ url: "reminder/list", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function addReminder(postData) {
    try {
        const response = await callPostApi({ url: "reminder", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function updateReminder(postData, id) {
    try {
        const response = await callPutApi({ url: "reminder/" + id, body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function deleteReminder(id) {
    // return this.interceptors.DELETE('exception-master/' + id);
    try {
          const response = await callDeleteApi({ url: "reminder/" + id });
          return response;
    } catch (error) {
          throw error;
    }
}

export async function editReminderStatus(postData) {
    // return this.interceptors.DELETE('exception-master/' + id);
    try {
          const response = await callPostApi({ url: "reminder/update-reminder-status" , body: postData });
          return response;
    } catch (error) {
          throw error;
    }
}

export async function addReminderUsers(postData,id) {
    // return this.interceptors.DELETE('exception-master/' + id);
    try {
          const response = await callPostApi({ url: "reminder/update-reminder-users/" + id , body: postData });
          return response;
    } catch (error) {
          throw error;
    }
}


export async function getPersonalReminderList(postData) {
    try {
        const response = await callPostApi({ url: "daily-reminders/list", body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function deleteDailyReminder(id) {
    // return this.interceptors.DELETE('exception-master/' + id);
    try {
          const response = await callDeleteApi({ url: "daily-reminders/" + id });
          return response;
    } catch (error) {
          throw error;
    }
}

export async function updateDailyReminder(postData, id) {
    try {
        const response = await callPutApi({ url: "daily-reminders/" + id, body: postData });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getReminderDetails(id) {
    try {
        const response = await callGetApi({ url: "reminder/" + id});
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getDailyReminderDetails(id) {
    try {
        const response = await callGetApi({ url: "daily-reminders/" + id});
        return response;
    } catch (error) {
        throw error;
    }
}