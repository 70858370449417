import { useFormik } from "formik";

export function TwitterForm(
  submit,
  initialVal,
  cb,
  checkTwitterData
) {
  const validation = (values) => {
    // console.log('TwitterForm validation');
    let errors = {};
    let twitterError = false;
    if (!values.twitter_title) {
      errors.twitter_title = "Twitter title required";
      twitterError = true;
    }
    if (!values.twitter_image_url) {
      errors.twitter_image_url = "Twitter image url required";
      twitterError = true;
    }  
    if (!values.twitter_description) {
      errors.twitter_description = "Twitter description required";
      twitterError = true;
    }
    if (!values.twitter_site) {
      errors.twitter_site = "Twitter site required";
      twitterError = true;
    }
    if (!values.twitter_creator) {
      errors.twitter_creator = "Twitter creator required";
      twitterError = true;
    }
    // console.log('twitterError ===>', twitterError);
    checkTwitterData(twitterError);
    if (cb) {
      cb(values);
    }
    return errors;
  };

  const initialValues = {
    twitter_title: "",
    twitter_image_url: "",
    twitter_description: "",
    twitter_site: "",
    twitter_creator: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialVal || initialValues,
    onSubmit: submit,
    validate: validation,
  });
  return { formik };
}
