import CheckBox from "Components/FormControl/CheckBox/CheckBox";
import RadioBox from "Components/FormControl/RadioBox/RadioBox";
import { MODULES } from "Helpers/Constants";
import { setAllStore } from "Helpers/setAllStore";
import { DateTime } from "luxon";
import moment from "moment";
import React, { useContext, useState } from "react";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router";
import { getAccessByModuleName } from "Routes/Routes";
import { getCommissionsDetail } from "../../../../api/report";
import CalendarIcon from "../../../../Assets/images/calendar.svg";
import CloseIcon from "../../../../Assets/images/close.svg";
import ViewIcon from "../../../../Assets/images/error-outline.svg";
import SearchIcon from "../../../../Assets/images/search.svg";
import Date from "../../../../Components/FormControl/Date/Date";
import Select from "../../../../Components/FormControl/Select/Select";
import ReactTable from "../../../../Components/Table/Table";
import { AppContext } from "../../../../Context/AppContext";
import { formatUsPhone } from "../../../../Hooks/commanUtility";
import ExportIcon from "./../../../../Assets/images/export.svg";
// Components
import Header from "./../../../../Components/Header/Header";
import styles from "./index.module.scss";

const CommissionReportLookUp = (props) => {
  const { showToastMessage, modules } = useContext(AppContext);
  let accessExport = getAccessByModuleName(modules, MODULES.COMMISSION_REPORT);

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [productType, setProductType] = React.useState("");
  const [exportData, setExportData] = React.useState([]);
  const [exportHeader, setExportHeader] = React.useState([]);
  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });
  const [type, setType] = useState("delivery_date");

  const [fromDate, setFromDate] = useState(
    moment().startOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [toDate, setToDate] = useState(
    moment().endOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [selectedPeriod, setSelectedPeriod] = React.useState("weekly");
  const [selectedPeriodLabel, setSelectedPeriodLabel] = React.useState(
    "By Week"
  );

  const [firstTimePageLoad, setFirstTimePageLoad] = React.useState(false);
  
  const [checkAllStore,setCheckAllStore] = useState(true);

  const csr = props.match.params;
  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);

    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    if (accessExport && accessExport.permissions.write_access)
      handleExportProductOrders(fetchData);

    setLoading(true);
    const postData = {
      start_date: moment(fromDate).format("YYYY-MM-DD"),
      end_date: moment(toDate).format("YYYY-MM-DD"),
      csr_id: csr.id,
      isExport: false,
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      date_type: type,
      filters,
      sortBy,
    };

    if (!firstTimePageLoad) {
      const response = await getCommissionsDetail(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count.length / pageSize));
      } else if (response.status === 403) {
        showToastMessage(undefined, response.data.message, "error", false);
        props.history.push("/profile");
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    } else {
      setFirstTimePageLoad(false);
    }
    setLoading(false);

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  const handleExportProductOrders = async (event) => {
    if (!csr.id) return;

    let postData = {
      currentPage: event.pageIndex,
      itemsPerPage: event.pageSize,
      globalSearch: event.globalFilter,
      csr_id: csr.id,
      type: productType === "ALL" ? "" : productType,
      isExport: true,
      date_type: type,
      start_date: moment(fromDate).format("YYYY-MM-DD"),
      end_date: moment(toDate).format("YYYY-MM-DD"),
    };

    const response = await getCommissionsDetail(postData);

    if (response && response.status === 200) {
      setExportHeader(response.data.data.header);
      setExportData(response.data.data.data);
    } else if (response.status === 403) {
      showToastMessage(undefined, response.data.message, "error", false);
      props.history.push("/profile");
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const handleClearSearch = async (event) => {
    event.preventDefault();

    setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
    setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
    setSelectedPeriod("weekly");

    fetchData(defaultFilter);
    handleExportProductOrders(event);
  };

  const handleSelectedPeriod = async (id) => {
    if (id === "weekly") {
      setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Week");
    } else if (id === "monthly") {
      setFromDate(moment().startOf("month").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("month").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Month");
    } else if (id === "yearly") {
      setFromDate(moment().startOf("year").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("year").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Year");
    }
  };

  const handleFromDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setToDate(moment(data).add(7, "days").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "monthly") {
      setToDate(moment(data).add(1, "months").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "yearly") {
      setToDate(moment(data).add(1, "years").utc().format("ddd MMM DD, YYYY"));
    }
  };

  const handleToDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setFromDate(
        moment(data).subtract(7, "days").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "monthly") {
      setFromDate(
        moment(data).subtract(1, "months").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "yearly") {
      setFromDate(
        moment(data).subtract(1, "years").utc().format("ddd MMM DD, YYYY")
      );
    }
  };

  let viewOrderAccess = getAccessByModuleName(modules, MODULES.VIEW_ORDER);
  const columns = React.useMemo(
    () => [
      {
        Header: "Order N0",
        accessor: "order_number",
        isVisible: true,
        placeholder: "Search by Order N0",
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        isVisible: true,
        Cell: ({ value, row, column }) => {
          return <div>{row.original.order_transaction.customer_name}</div>;
        },
      },
      {
        Header: "Recipient Name",
        accessor: "recipient_name",
        isVisible: true,
        Cell: ({ value, row, column }) => {
          return <div>{row.original.order_recipients[0].recipient_name}</div>;
        },
      },
      {
        Header: "Customer Phone",
        accessor: "customer_phone",
        isVisible: true,
        Cell: ({ value, row, column }) => {
          return `${
            row.original.order_transaction.customer_phone
              ? formatUsPhone(row.original.order_transaction.customer_phone)
              : ""
          }`;
        },
      },
      {
        Header: "Recipient Phone",
        accessor: "recipient_phone",
        isVisible: true,
        Cell: ({ value, row, column }) => {
          return `${
            row.original.order_recipients[0].recipient_phone
              ? formatUsPhone(row.original.order_recipients[0].recipient_phone)
              : ""
          }`;
        },
      },
      {
        Header: "Delivery Date",
        accessor: "delivery_date",
        isVisible: true,
        Cell: ({ value, row, column }) => {
          return `${
            row.original.delivery_date && row.original.delivery_date !== null
              ? DateTime.fromISO(row.original.delivery_date)
                  .setZone("America/Los_Angeles")
                  .toFormat("ccc LLL dd, yyyy")
              : ""
          }`;
        },
      },
      {
        Header: "Action",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {viewOrderAccess && viewOrderAccess.permissions.read_access ? (
                <img
                  className={`cursor-pointer mr-2 view-icon`}
                  src={ViewIcon}
                  alt="Cancel Order"
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push(`/admin/orders/view/${row.original.id}`);
                  }}
                />
              ) : (
                <span style={{ width: 34, height: 34, display: "block" }} />
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Header title="Commission Report Lookup" />
      <div id="MainContent" className="main-content">
        <div style={{ float: "left", width: "100%" }}>
          <div className={"row "}>
            <div className="col-12 d-flex align-items-center">
              <span
                className="mb-0 mt-2 pb-0 d-inline-bock mr-3"
                style={{ lineHeight: 1 }}
              >
                Filter By
              </span>
              <RadioBox
                selectedValue={type}
                onChange={() => {
                  if (setType) {
                    setType("order_date");
                  }
                }}
                label="Order date"
                value="order_date"
              />
              <RadioBox
                selectedValue={type}
                onChange={() => {
                  if (setType) {
                    setType("delivery_date");
                  }
                }}
                label="Delivery date"
                value="delivery_date"
              />
            </div>
            <div className="col-sm-2 col-xs-12">
              <Select
                label={selectedPeriodLabel}
                value={selectedPeriod}
                onChange={(data) => {
                  setSelectedPeriod(data);
                  handleSelectedPeriod(data);
                }}
                options={[
                  { id: "weekly", title: "Weekly" },
                  { id: "monthly", title: "Monthly" },
                  { id: "yearly", title: "Yearly" },
                  { id: "custom", title: "Custom" },
                ]}
                disabled={false}
              />
            </div>
            <div className="col-sm-3 col-xs-12">
              <Date
                label="Order Start Date"
                value={fromDate}
                dateFormat="ddd MMM DD, YYYY"
                type="date"
                fieldIcon={CalendarIcon}
                onChange={(fromDate) => {
                  setFromDate(moment(fromDate).format("ddd MMM DD, YYYY"));
                  handleFromDate(fromDate);
                }}
              />
            </div>
            <div className="col-sm-3 col-xs-12">
              <Date
                label="Order End Date"
                value={toDate}
                dateFormat="ddd MMM DD, YYYY"
                type="date"
                fieldIcon={CalendarIcon}
                onChange={(toDate) => {
                  setToDate(moment(toDate).format("ddd MMM DD, YYYY"));
                  handleToDate(toDate);
                }}
              />
            </div>
            <div className="col-sm-2 col-xs-12">
              <CheckBox
              value={checkAllStore}
              label="All Stores"
              onChange={(data)=>{setCheckAllStore(data)}}
              />
            </div>
            <div className="col-sm-2 col-xs-12">
              <div className="td-actions pt-4">
                <span
                  onClick={(e) => fetchData(defaultFilter)}
                  className={`link cursor-pointer ${styles.searchIconWrapper}`}
                >
                  <img className={styles.searchIcon} src={SearchIcon} alt="" />
                </span>
                <span
                  onClick={(e) => handleClearSearch(e)}
                  className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
                >
                  <img className={styles.clearIcon} src={CloseIcon} alt="" />
                </span>
                {accessExport && accessExport.permissions.write_access && (
                  <span>
                    <CSVLink
                      data={exportData}
                      style={{ float: "right" }}
                      headers={exportHeader}
                      filename={"CommissionReportLookup.csv"}
                      className={`link cursor-pointer ml-3 ${styles.searchIconWrapper}`}
                    >
                      <img
                        className={styles.searchIcon}
                        src={ExportIcon}
                        alt=""
                      />
                    </CSVLink>
                  </span>
                )}
              </div>
            </div>
          </div>

          <ReactTable
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            noDataText={"There is no data to display"}
            defaultFilter={defaultFilter}
            displayGlobalSearch={false}
            displayColumnVisibility={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CommissionReportLookUp);
