import React, { useState } from "react";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// Scss
import styles from "./DeleteConfirmation.module.scss";

import Seo from "../../../Components/Cms/Seo";
import OGInfo from "../../../Components/Cms/OgInfo";
import Twitterinfo from "../../../Components/Cms/TwitterInfo";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SeoData = (props) => {
  const [seoDataTemp, setSeoDataTemp] = useState(props.seoFinalData);

  const checkSeoData = async (data) => {
    return true;
  };

  const saveButtonClick = async () => {
    props.handleSubmitClick(seoDataTemp);
  };

  const setSeoDataJson = async (data) => {
    // debugger
    let seoDataTempData = {
      ...seoDataTemp,
    };
    setSeoDataTemp({
      ...seoDataTempData,
      ...data,
    });
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.showModal}
        TransitionComponent={Transition}
        onClose={props.handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={styles.modalContent}>
          <div className={`text-center ${styles.modalBody}`}>
            <Seo
              data={seoDataTemp}
              setSeoData={setSeoDataJson}
              checkSeoData={checkSeoData}
            />
            <OGInfo
              data={seoDataTemp}
              setOgInfoData={setSeoDataJson}
              checkOgInfoData={checkSeoData}
            />
            <Twitterinfo
              data={seoDataTemp}
              setTwitterInfoData={setSeoDataJson}
              checkTwitterInfoData={checkSeoData}
            />

            <span
              data-dismiss="modal"
              onClick={saveButtonClick}
              className="btn gray-btn d-inline-block mb-3 mr-3"
            >
              {" "}
              Save{" "}
            </span>
            <span
              data-dismiss="modal"
              onClick={props.handleCancelClick}
              className="btn gray-btn d-inline-block mb-3"
            >
              {" "}
              Cancel{" "}
            </span>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SeoData;
