import { Checkbox, FormControlLabel, withStyles } from "@material-ui/core";
import TableMap from "Components/OrderComponents/TableSection/TableMap";
import TableSectionDrawer from "Components/OrderComponents/TableSection/TableSectionDrawer";
import { useFormikContext } from "formik";
import { useOrderAttributeProvider } from "Pages/Order/Order/context/order";
import React, { useState } from "react";
import { useProductManage } from "../hooks/useProductMange";
import AddonContent from "./AddonContainerNewContext";
import Custom from "./Custom";
import ProductContent from "./ProductContentNewContext";
const ProductSection = () => {
  const { active } = useOrderAttributeProvider();
  const formik = useFormikContext();
  const [open, setOpen] = useState(false);
  const [customOpen, setCustomOpen] = useState(false);
  const [currentDrawer, setCurrentDrawer] = useState(null);

  const {
    deleteProduct,
    increaseProductQty,
    decreaseProductQty,
    decreaseAddonQty,
    increaseAddonQty,
    deleteAddon,
    deleteAllProduct,
  } = useProductManage();

  let currentRecipient = formik.values.recipients[active];
  const GreenCheckbox = withStyles({
    root: {
      color: "#CB998D",
      "&$checked": {
        color: "#CB998D",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  return (
    <div className="row col-12">
      <div className="col-12">
        &nbsp;
        {/* <FormControlLabel
          control={
            <GreenCheckbox
              disabled={active > 0}
              checked={formik.values.custom_order === 1}
              onChange={() => {
                let newValue = 1;
                if (formik.values.custom_order === 1) {
                  newValue = 0;
                }
                // deleteAllProduct();
                // let recipients = [...formik.values.recipients];
                // recipients = recipients.map((r) => {
                //   r.products = [];
                //   return r;
                // });

                formik.setValues((v) => {
                  return {
                    ...v,
                    custom_order: newValue,
                    // recipients: recipients,
                  };
                });
              }}
            />
          }
          label="Custom Price Order"
        /> */}
      </div>
      <div className="col-md-6">
        <TableMap
          onDecrease={decreaseProductQty}
          onIncrease={increaseProductQty}
          onDelete={deleteProduct}
          OnAddClick={(e) => {
            // if (formik.values.custom_order === 1) {
            //   setCustomOpen(true);
            // } else {
            formik.setValues((v) => {
              return {
                ...v,
                custom_order: 0,
              };
            });
            setOpen(true);
            setCurrentDrawer("product");
            // }
          }}
          OnAddClickCustom={() => {
            formik.setValues((v) => {
              return {
                ...v,
                custom_order: 1,
              };
            });
            setCustomOpen(true);
          }}
          title="Products"
          list={currentRecipient.products ?? []}
        />
      </div>
      <div className="col-md-6">
        <TableMap
          onDecrease={decreaseAddonQty}
          onIncrease={increaseAddonQty}
          onDelete={deleteAddon}
          title="Addons"
          list={currentRecipient.addons ?? []}
          OnAddClick={() => {
            setOpen(true);
            setCurrentDrawer("addon");
          }}
        />
      </div>
      {customOpen && (
        <Custom
          addProduct={(product) => {
            let recipients = [...formik.values.recipients];
            product.total_price = +product.total_price;
            product.total_price = +product.selectedVariant.price;
            product.custom_product_flag = 1;
            //unique key for each product recipent wise
            product.product_key = product.product_key || `prod_${product.id}_${product.selectedVariant.id}_${new Date().getTime()}`;
            recipients[active].products.push(product);
            formik.setValues((v) => {
              return {
                ...v,
                recipients,
              };
            });
            setCustomOpen(false);
          }}
          open={customOpen}
          onClose={() => setCustomOpen(false)}
        />
      )}
      {open && currentDrawer && (
        <TableSectionDrawer
          open={open}
          onClose={() => {
            setCurrentDrawer(null);
            setOpen(false);
          }}
        >
          {currentDrawer === "product" && (
            <ProductContent
              onAdd={(products) => {
                let recipients = [...formik.values.recipients];
                recipients[active].products.push(...products);
                formik.setValues((v) => {
                  return {
                    ...v,
                    recipients,
                  };
                });
                setOpen(false);
                setCurrentDrawer(null);
              }}
              onClose={() => {
                setOpen(false);
                setCurrentDrawer(null);
              }}
            />
          )}
          {currentDrawer === "addon" && (
            <AddonContent
              onAdd={(addons) => {
                let recipients = [...formik.values.recipients];
                recipients[active].addons.push(...addons);
                formik.setValues((v) => {
                  return {
                    ...v,
                    recipients,
                  };
                });
                setOpen(false);
                setCurrentDrawer(null);
              }}
              onClose={() => {
                setOpen(false);
                setCurrentDrawer(null);
              }}
            />
          )}
        </TableSectionDrawer>
      )}
    </div>
  );
};

export default ProductSection;
