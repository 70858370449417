import React from 'react'
import styles from "./index.module.scss";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const productRecipeDetailsModel = ({showModal, handleCloseModal,productRecipe}) => {
  console.log('productRecipe: ', productRecipe);
  return (
    <React.Fragment>
            <Dialog
                open={showModal}
                TransitionComponent={Transition}
                onClose={handleCloseModal}
                classes={{
                    paper: styles.modalDialog
                }}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogContent
                    className={`${styles.modalContent} px-0 py-0`}
                >
                    <div className={`text-center ${styles.modalBody}`}>
                        <div className="d-flex justify-content-between">
                            <h5 className={`mt-0 mb-3 text-center ${styles.modalTitle}`}>
                              {productRecipe?.product?.title && productRecipe.product.title }
                              {productRecipe?.title && ` (${productRecipe?.title})`}
                            </h5>
                            {/* <span className={`current-date ${styles.date}`}></span> */}
                        </div>

                        <div className="">
                            <table className="table">
                                <thead>
                                  <tr>
                                    <th className="text-left">Row Material Type</th>
                                    <th className="text-left">Row Material Name </th>
                                    <th className="text-left">Unit</th>
                                    <th className="text-left">Quantity</th>
                                    <th className="text-left">Total Quentiry</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    productRecipe?.product_recipes && productRecipe?.product_recipes.length > 0 && (
                                      productRecipe?.product_recipes[0].product_recipe_ingredients.map((data)=>(
                                        <>
                                          <tr className="text-left">
                                            <td>{data.product_recipe_type.title}</td>
                                            <td>{data.product_recipe_raw_material.title}</td>
                                            <td>{data.product_recipe_unit.title}</td>
                                            <td>{data.quantity}</td>
                                            <td>{data.total_unit_qty}</td>
                                          </tr>

                                        </>
                                      ))
                                    )
                                  }
                                </tbody>
                            </table>
                        </div>
                        <span
                          data-dismiss="modal"
                          onClick={handleCloseModal}
                          className="btn gray-btn d-inline-block mb-3"
                          >
                          OK
                        </span>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
  )
}

export default productRecipeDetailsModel