import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import Header from "../../../Components/Header/Header";
import Text from "../../../Components/FormControl/Text/TextInput";
import styles from "./index.module.scss";
import Date1 from "../../../Components/FormControl/Date/Date";
import CalendarIcon from "./../../../Assets/images/calendar.svg";
import CheckBox from "../../../Components/FormControl/CheckBox/CheckBox";
//Material Ui
import MultiSelect from "../../../CommonComponents/MultiSelect";
import MultipleSelect from 'react-select';

import { BlockOutDatesForm } from "../../../Hooks/cms/blockOutDates";
//services
// import BlockOutDatesApi from "../../../Services/BlockOutDates/BlockOutDates";
// import AddressTypeApi from "../../../Services/AddressType/AddressType";
import { getAddressTypeList } from "../../../api/addressType";
import {
  getDetailsBlockoutDates,
  addBlockOutDate,
  updateBlockOutDates,
  getZipCodesList,
} from "../../../api/blockoutDates";
//context
import { AppContext } from "Context/AppContext";
import { useHistory, useLocation } from "react-router-dom";
import { convertUtcTimestampToPstDate } from "Hooks/commanUtility";
import { DateTime } from "luxon";
import {
  PST_ZONE,
  UTC_ZONE,
  START_TIME_OF_DATE_TWENTY_FOUR,
  END_TIME_OF_DATE_TWENTY_FOUR,
} from "Pages/Order/Order/utility/util";
import { getFolderGroups } from "api/settings";

// import Autocomplete from '@material-ui/lab/Autocomplete';
// import TextField from '@material-ui/core/TextField';

const Add = (props) => {
  const [zipCodeList, setZipCodeList] = useState([]);
  // const blockOutDatesService = new BlockOutDatesApi();
  // const addressTypeService = new AddressTypeApi();

  const location = useLocation();
  const history = useHistory();
  // const [deliveryAddress, setDeliveryAddress] = React.useState([
  //   {
  //     id: 1,
  //     title: "Residence",
  //     isSelected: false,
  //   },
  //   {
  //     id: 2,
  //     title: "Apartment",
  //     isSelected: false,
  //   },
  //   {
  //     id: 3,
  //     title: "Business",
  //     isSelected: false,
  //   },
  //   {
  //     id: 4,
  //     title: "Hospital",
  //     isSelected: false,
  //   },
  //   {
  //     id: 5,
  //     title: "Funeral",
  //     isSelected: false,
  //   },
  //   {
  //     id: 6,
  //     title: "Church",
  //     isSelected: false,
  //   },
  //   {
  //     id: 7,
  //     title: "School",
  //     isSelected: false,
  //   },
  //   {
  //     id: 8,
  //     title: "Other",
  //     isSelected: false,
  //   },
  //   {
  //     id: 9,
  //     title: "All",
  //     isSelected: false,
  //   },
  // ]);
  const [deliveryAddress, setDeliveryAddress] = React.useState([]);
  const formRef = useRef();
  const [edit, isEdit] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [autocomplete, setAutocomplete] = useState(false);
  const { showToastMessage, setIsLoading } = React.useContext(AppContext);
  
  const [zipcodeFolderList,setZipcodeFolderList] = useState([]);
  const [zipcodeFolder,setZipcodeFolder] = useState([])
  const [defaultSelectedZipFolder,setDefaultSelectedZipFolder] = useState([]);
  const [disableZipcode,setDisableZipcode] = useState(false);
  const [disableZipcodeFolder,setDisableZipcodeFolder] = useState(false);
  
  let [initialValues, setInitValues] = useState({
    title: "",
    start_blocking_on: moment().unix(),
    stop_repeating_on: moment().unix(),
    start_blocking_at: moment().unix(),
    services_block_local_delivery: true,
    services_block_non_local_delivery: true,
    services_block_pickup_order: true,
    services_block_delivery_order: true,
    services_block_on_demand_delivery: true,
    services_block_will_call: true,
    status: 1,
  });
  const [selectCode, setSelectedCodes] = useState([]);

  const changeBlock = (i, index) => {
    if (deliveryAddress[index]["isSelected"] === false) {
      deliveryAddress[index]["isSelected"] = true;
      let delievryAddressTemp = [...deliveryAddress];
      setDeliveryAddress(delievryAddressTemp);
    } else {
      deliveryAddress[index]["isSelected"] = false;
      let delievryAddressTemp = [...deliveryAddress];
      setDeliveryAddress(delievryAddressTemp);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getZipCodes();
      await getAddressType();
      await getZipCodeFolders();
      setLoaded(true);
      setIsLoading(false);
    })();
  }, []);

  const getZipCodes = async () => {
    let postData = {
      globalSearch: "",
      itemsPerPage: 500,
    };
    // let response = await blockOutDatesService.getZipCodes(postData);
    let response = await getZipCodesList(postData);
    if (response && response.status === 200) {
      setZipCodeList(response.data.data.rows);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const getAddressType = async () => {
    // let response = await addressTypeService.getAddressTypeList();
    let response = await getAddressTypeList();
    if (response && response.status === 200) {
      let addressTypeArr = [];
      response.data.data.rows?.forEach((d, i) => {
        let tempArr = {
          id: d.id,
          title: d.name,
          isSelected: false,
        };
        addressTypeArr.push(tempArr);
      });
      setDeliveryAddress(addressTypeArr);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  //get zipcode folder list
  const getZipCodeFolders = async ()=>{
    const response = await getFolderGroups();
    console.log('response: ', response.data.data);
    if (response && response.status === 200) {
      setZipcodeFolderList([...response.data.data.map((elem)=>{return {value:elem.id,label:elem.group_name,zipcode:elem.store_settings_zipcodes}})])
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

  }
  // const  =
  const serviesBlock = [
    {
      id: 1,
      title: "Local Delivery",
      key: "services_block_local_delivery",
    },
    {
      id: 2,
      title: "Non-Local Delivery",
      key: "services_block_non_local_delivery",
    },
    {
      id: 3,
      title: "Pickup Order",
      key: "services_block_pickup_order",
    },
    {
      id: 4,
      title: "Delivery Order",
      key: "services_block_delivery_order",
    },
    {
      id: 5,
      title: "On Demand Delivery",
      key: "services_block_on_demand_delivery",
    },
    // {
    //   id: 3,
    //   title: "Will Call",
    //   key: "services_block_will_call",
    // },
  ];

  const submit = async (values) => {
    let postData = {
      ...values,
    };

    // let blockingOnDate = moment
    //   .unix(postData.start_blocking_on)
    //   .format("YYYY-MM-DD");
    // let blockingOnDateTimestamp =
    //   new Date(blockingOnDate + " 12:00:00 AM").getTime() / 1000;

    // let repeatingOnDate = moment
    //   .unix(postData.stop_repeating_on)
    //   .format("YYYY-MM-DD");
    // let repeatingOnDateTimestamp =
    //   new Date(repeatingOnDate + " 11:59:59 PM").getTime() / 1000;

    // let startBlockingAt = moment
    //   .unix(postData.stop_repeating_on)
    //   .format("YYYY-MM-DD");
    // let startBlockingAtTimestamp =
    //   new Date(startBlockingAt + " 11:59:59 PM").getTime() / 1000;

    // postData.start_blocking_on = blockingOnDateTimestamp;
    // postData.stop_repeating_on = repeatingOnDateTimestamp;
    // postData.start_blocking_at = startBlockingAtTimestamp;
    // postData.start_blocking_on = postData.start_blocking_on;
    // postData.stop_repeating_on = postData.stop_repeating_on;
    // postData.start_blocking_at = postData.stop_repeating_on;

    postData.blockout_dates_zipcode_to_blocks = selectCode.map(
      (item) => item.id
    );

    postData.blockout_dates_delivery_address_types = deliveryAddress
      .filter((item) => item.isSelected)
      .map((item) => item.id);

    postData.start_blocking_at = postData.stop_repeating_on;
    //set selected zipcode folder id in postdata
    postData.blockout_dates_zipcode_folder_to_blocks = zipcodeFolder;
    let response = null;

    if (edit) {
      postData.id = location.state.id;
      // response = await blockOutDatesService.updateBlockOutDates(postData);
      response = await updateBlockOutDates(postData);
    } else {
      // response = await blockOutDatesService.addBlockOutDate(postData);
      response = await addBlockOutDate(postData);
    }

    if (response && response.status === 200) {
      showToastMessage(undefined, response.data.message, "success", false);
      history.push("/settings/blockout-dates");
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  const selectedCodes = (value) => {
    setSelectedCodes(value);
    //when csr can select zipcode at that time zipcode folder will become disabled
    if(value.length>0){
      setDisableZipcodeFolder(true)
    }else{
      setDisableZipcodeFolder(false)
    }
  };

  useEffect(() => {
    (async () => {
      if (loaded && location?.state?.id) {
        isEdit(true);
        setIsLoading(true);
        await getDetails();
        setIsLoading(false);
      } else {
        if (loaded) setAutocomplete(true);
      }
    })();
  }, [location, loaded]);

  const getDetails = async () => {
    // const response = await blockOutDatesService.getDetails(location.state.id);
    const response = await getDetailsBlockoutDates(location.state.id);
    if (response.status === 200) {
      setInitValues({
        title: response.data.data.title,
        start_blocking_on: response.data.data.start_blocking_on,
        stop_repeating_on: response.data.data.stop_repeating_on,
        start_blocking_at: response.data.data.start_blocking_at,
        services_block_local_delivery:
          response.data.data.services_block_local_delivery,
        services_block_non_local_delivery:
          response.data.data.services_block_non_local_delivery,
        services_block_pickup_order:response.data.data.services_block_pickup_order,
        services_block_delivery_order:response.data.data.services_block_delivery_order,
        services_block_on_demand_delivery:response.data.data.services_block_on_demand_delivery,
        services_block_will_call: response.data.data.services_block_will_call,
        status: response.data.data.status,
      });

      let zipCodes = [];
      response.data.data.blockout_dates_zipcode_to_blocks.forEach((item) => {
        zipCodes.push({
          id: item.zipcode_id,
          zip_code: item.store_settings_zipcode.zip_code,
          is_deliverable: true,
          status: 1,
        });
      });

      setSelectedCodes(zipCodes);
      
      //set zipcode folder when csr click on edit blockout date
      const tempZipFolderId = response.data.data.blockout_dates_zipcode_folder_to_blocks.split(",").map((elem)=>(Number(elem)));
      const tempDefaultZipFolder = zipcodeFolderList.filter(elem=>tempZipFolderId.includes(elem.value))

      // if zipcode folder is selected so zipcode will be disabled
      if(tempDefaultZipFolder.length>0){
        setDisableZipcode(true)
      }
      // if zipcode folder is not selected and zipcode is selected so zipcode folder will be disabled
      if(!tempDefaultZipFolder.length>0 && zipCodes.length>0){
        setDisableZipcodeFolder(true)
      }
      //if zipcode folder and zipcode oth are not selected at that time both are enable
      if(!tempDefaultZipFolder.length>0 && !zipCodes.length>0){
        setDisableZipcode(false)
        setDisableZipcodeFolder(false)
      }
      setZipcodeFolder([...tempZipFolderId]);
      setDefaultSelectedZipFolder(tempDefaultZipFolder)


      let newDeliveryAddress = [...deliveryAddress];
      newDeliveryAddress.forEach((ele) => {
        let isExist =
          response.data.data.blockout_dates_delivery_address_types.find(
            (item) => item.delivery_address_type_id === ele.id
          );

        if (isExist) return (ele.isSelected = true);
        ele.isSelected = false;
      });
      setDeliveryAddress(newDeliveryAddress);
      setAutocomplete(true);
    }
  };

  let tempFolderZip = [];
  let tempFolderZipId = [];
  const zipcodeFolderChange = (data)=>{
    setDefaultSelectedZipFolder(data);
    //when csr can select zipcode folder at that time zipcode will become disabled
    if(data.length>0){
      setDisableZipcode(true)
    }else{
      setDisableZipcode(false)
    }
    // whenever csr can select zipcode folder we set this perticular zipcode folder's zipcodes in zipcode dropdown 
    data.map((elem)=>{
      tempFolderZip=[...tempFolderZip, ...elem.zipcode];
      tempFolderZipId=[...tempFolderZipId, elem.value]
    })
    
    setSelectedCodes([...tempFolderZip]);
    setZipcodeFolder([...tempFolderZipId]);
  }
  const { formik: DatesFormik } = BlockOutDatesForm(submit, initialValues);

  return (
    <React.Fragment>
      <Header title="Add Blockout Date" />
      <div className="d-md-flex align-items-md-center mt-4 mb-3">
        <h3 className="bold mr-3">Primary information</h3>
      </div>
      <form ref={formRef} onSubmit={(e) => DatesFormik.handleSubmit(e)}>
        <div className={`row`}>
          <div className={`col-md-8`}>
            <Text
              type="text"
              label="Title"
              id="title"
              value={DatesFormik.values.title}
              required={true}
              onChange={(data) =>
                DatesFormik.setValues((v) => {
                  return { ...v, title: data };
                })
              }
              touched={DatesFormik.touched.title}
              error={DatesFormik.errors.title}
            />
          </div>
          <div className={`col-md-4 cursor-pointer`}>
            <label style={{ marginRight: "80%" }}>Status</label>
            <div className={styles.status}>
              <div
                className={
                  DatesFormik.values.status === 1
                    ? styles.Active
                    : styles.Inactive
                }
                // style={{ "font-weight": "bold" }}
                onClick={(data) =>
                  DatesFormik.setValues((v) => {
                    return { ...v, status: 1 };
                  })
                }
              >
                Active
              </div>
              <div
                className={
                  DatesFormik.values.status === 0
                    ? styles.Active
                    : styles.Inactive
                }
                onClick={(data) =>
                  DatesFormik.setValues((v) => {
                    return { ...v, status: 0 };
                  })
                }
              >
                Inactive
              </div>
            </div>
          </div>
          <div className={`col-md-4`}>
            <Date1
              label="Start Blocking On"
              // value={fromDate}
              dateFormat="MM-DD-YYYY"
              type="date"
              fieldIcon={CalendarIcon}
              onChange={(data) =>
                DatesFormik.setValues((v) => {
                  let pst = moment(data).format("MM-DD-YYYY");
                  let timestamp = DateTime.fromFormat(
                    `${pst} ${START_TIME_OF_DATE_TWENTY_FOUR}`,
                    "LL-dd-yyyy HH:mm",
                    {
                      zone: PST_ZONE,
                    }
                  )
                    .setZone(UTC_ZONE)
                    .toSeconds();
                  return {
                    ...v,
                    // start_blocking_on: moment(data, "DD-MM-YYYY").unix(),
                    start_blocking_on: timestamp,
                  };
                })
              }
              // value={moment.unix(DatesFormik.values.start_blocking_on)}
              value={
                DatesFormik.values.start_blocking_on !== ""
                  ? convertUtcTimestampToPstDate(
                      DatesFormik.values.start_blocking_on,
                      "LL-dd-yyyy"
                    )
                  : ""
              }
              id="start_blocking_on"
              touched={DatesFormik.touched.start_blocking_on}
              error={DatesFormik.errors.start_blocking_on}
            />
          </div>
          <div className={`col-md-4`}>
            <Date1
              label="Stop Repeating On"
              // value={fromDate}
              dateFormat="MM-DD-YYYY"
              type="date"
              fieldIcon={CalendarIcon}
              onChange={(data) =>
                DatesFormik.setValues((v) => {
                  let pst = moment(data).format("MM-DD-YYYY");
                  let timestamp = DateTime.fromFormat(
                    `${pst} ${END_TIME_OF_DATE_TWENTY_FOUR}`,
                    "LL-dd-yyyy HH:mm",
                    {
                      zone: PST_ZONE,
                    }
                  )
                    .setZone(UTC_ZONE)
                    .toSeconds();
                  return {
                    ...v,
                    stop_repeating_on: timestamp,
                  };
                })
              }
              // value={moment.unix(DatesFormik.values.stop_repeating_on)}
              value={
                DatesFormik.values.stop_repeating_on !== ""
                  ? convertUtcTimestampToPstDate(
                      DatesFormik.values.stop_repeating_on,
                      "LL-dd-yyyy"
                    )
                  : ""
              }
              id="stop_repeating_on"
              touched={DatesFormik.touched.stop_repeating_on}
              error={DatesFormik.errors.stop_repeating_on}
            />
          </div>
          <div className={`col-md-4`}>
            {/* <Date1
              label="Stop Blocking At"
              // value={fromDate}
              timeFormat="hh:mm A"
              type="time"
              fieldIcon={CalendarIcon}
              onChange={(data) =>
                DatesFormik.setValues((v) => {
                  return {
                    ...v,
                    start_blocking_at: moment(data, "hh:mm A").unix(),
                  };
                })
              }
              value={moment
                .unix(DatesFormik.values.start_blocking_at)}
              id="start_blocking_at"
              touched={DatesFormik.touched.start_blocking_at}
              error={DatesFormik.errors.start_blocking_at}
            /> */}
          </div>
        </div>
        <div className={`row`}>
          <div className={`col-md-8 `}>
            <div className="d-md-flex align-items-md-center mt-3 mb-3">
              <h3 className="bold mr-3">Delivery Address Types To Block</h3>
            </div>
            <div className={`${styles.deliveryAddress} cursor-pointer`}>
              {deliveryAddress.map((i, j) => (
                <div
                  className={
                    i.isSelected === true
                      ? `${styles.active}`
                      : `${styles.deliveryContent}`
                  }
                  onClick={() => changeBlock(i, j)}
                  key={j}
                >
                  {i.title}
                </div>
              ))}
            </div>
          </div>
          <div className={`col-md-4`}>
            <div className="d-md-flex align-items-md-center mt-3 mb-3">
              <h3 className="bold mr-3">Servies To Block</h3>
            </div>
            {serviesBlock.map((i, j) => (
              <div key={j}>
                <CheckBox
                  label={i.title}
                  // submitStatus={submitStatus}
                  value={DatesFormik.values[i.key]}
                  id={i.key}
                  required={false}
                  onChange={(data) => {
                    let key = DatesFormik.values[i.key];
                    DatesFormik.setValues((v) => {
                      return { ...v, [i.key]: !key };
                    });
                  }}
                />
              </div>
            ))}
          </div>
        </div>
          <div className="d-md-flex align-items-md-center mt-3 mb-3">
            <h3 className="bold mr-3">Zipcode Folder</h3>
          </div>
          <div className="col-md-4 col-xs-12 mb-4">
              <div className={`form-group bmd-form-group is-filled text-left`} >
                <MultipleSelect
                  // label={"Zip Codes To Block"}
                  
                  options={zipcodeFolderList}
                  value={defaultSelectedZipFolder}
                  isMulti={true}
                  isDisabled={disableZipcodeFolder}
                  placeholder={"Select Zipcode Folder"}
                  onChange={(data)=>{zipcodeFolderChange(data)}} 
                  styles={{ control: (styles, state) => ({
                    ...styles,
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    borderBottomWidth: state.isFocused ? 2 : 1,
                    borderRadius: 0,
                    background: "transparent",
                    boxShadow: "none",
                    fontSize: 20,
                  }),
                  singleValue: (styles) => ({ ...styles, color: "#212721" }),
                  valueContainer: (styles) => ({
                    ...styles,
                    paddingLeft: 0,
                    color: "#212721",
                  }
                  )
                }}
                  isClearable={false}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#CB998D",
                      primary25: "rgba(203,153,141,.15)",
                    }
                  })}
                />
              </div>
            </div>
          <div className="d-md-flex align-items-md-center mt-3 mb-3">
            <h3 className="bold mr-3">Zip Code</h3>
          </div>
        {autocomplete && (
          <MultiSelect
            // label={"Zip Codes To Block"}
            codes={zipCodeList}
            value={selectCode}
            selectedCodes={selectedCodes}
            isFolderSelect={disableZipcode}
          />
        )}

        <div className="d-block text-left mt-5 mb-4">
          <button type="submit" className="btn pink-btn bold">
            Save
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};
export default Add;
