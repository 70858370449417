import { callPostApi, callDeleteApi, callPutApi } from "./api";

export async function getRoles(data) {
      // return this.interceptors.POST('roles/list', data);
      try {
            const response = await callPostApi({ url: "roles/list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function addRole(data) {
      // return this.interceptors.POST('roles', data);
      try {
            const response = await callPostApi({ url: "roles", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateRole(roleId, data) {
      // return this.interceptors.PUT('roles/' + roleId, data);
      try {
            const response = await callPutApi({ url: "roles/" + roleId, body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function deleteRole(roleId) {
      // return this.interceptors.DELETE('roles/' + roleId);
      try {
            const response = await callDeleteApi({ url: "roles/" + roleId });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function getModulesData(data) {
      // return this.interceptors.POST('roles/modules/list', data);
      try {
            const response = await callPostApi({ url: "roles/modules/list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}
