import React from "react";
// SCSS
import styles from "./index.module.scss";

function OrderProducts(props) {
  return (
    <div className={`${styles.productsWrapper}`}>
      <div className="">
        <div className="">
          <div className={styles.title}>{props.title}</div>
          <div className="table-responsive my-4">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-left">Item</th>
                  <th className="text-left">Name</th>
                  <th className="text-center">Variation</th>
                  <th className="text-center">Qty</th>
                  <th className="text-center">Price</th>
                  <th className="text-center">Total</th>
                </tr>
              </thead>
              <tbody>
                {props.itemData.length === 0 ? (
                  <tr>
                    <td className={``}>
                      <div className={`${styles.emptyImage}`}>
                        <span>Image</span>
                      </div>
                    </td>
                    <td className={``}>{props.title.toLowerCase() === 'Products' ? "No Product" : "No Add Ons"}</td>
                    <td className={` text-center`}>-</td>
                    <td className={` text-center`}>-</td>
                    <td className={` text-center`}>-</td>
                    <td className={` text-center`}>-</td>
                  </tr>
                ) : (
                  props.itemData.map((item, index) => (
                    <tr className={`${styles.tableStyle}`} key={index}>
                      <td className={``}>
                        <div className={`${styles.tableimg}`}>
                          <img
                            src={item.variation_image}
                            alt=""
                            title="image"
                          />
                        </div>
                      </td>
                      <td className={``}>{item.product_name}</td>
                      <td className={` text-center`}>
                        {item.variation_name}
                      </td>
                      <td className={` text-center`}>{item.qty}</td>
                      <td className={` text-center`} >
                        {item.unit_price}
                      </td>
                      <td className={` text-center`}>
                        {item.total_price}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderProducts;
