// Packages
import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router";
import moment from "moment";
import "moment-timezone";

// Components
import Header from "./../../Components/Header/Header";
import DeleteConfirmation from "./../../Components/Modal/DeleteConfirmation/DeleteConfirmation";

// Services
import Notification from "./../../Services/Notification/Notification";
import { list, deleteNotification } from "./../../api/notification";

// Context
import { AppContext } from "./../../Context/AppContext";

// Images
import DeleteIcon from "./../../Assets/images/delete.svg";

const TimeClockHistory = (props) => {
  const [notificationList, setNotificationList] = useState([]);
  const {
    timezone,
    handleGetNotificationCount,
    setIsLoading,
    showToastMessage,
  } = useContext(AppContext);

  const [deleteNotificationId, setDeleteNotificationId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const notificationService = new Notification();

  useEffect(() => {
    handleGetNotificationsList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetNotificationsList = async () => {
    setIsLoading(true);
    // const response = await notificationService.list();
    const response = await list();

    if (response && response.status === 200) {
      setNotificationList(response.data.data);

      handleGetNotificationCount();
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  const handleDeleteNotificationsClick = async (event, id) => {
    event.preventDefault();

    setDeleteNotificationId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteNotification = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    // const response = await notificationService.delete(deleteNotificationId);
    const response = await deleteNotification(deleteNotificationId);

    if (response && response.status === 200) {
      setDeleteNotificationId(null);
      setShowDeleteModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      handleGetNotificationsList();
    } else showToastMessage(undefined, response.data.message, "error", false);

    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Header title="Notifications" />

      <div id="MainContent" className="main-content">
        {
          <div className="table-responsive my-5">
            <table className="table">
              <thead>
                <tr>
                  <th>Message</th>
                  <th>Date</th>
                  <th className="text-center"></th>
                </tr>
              </thead>
              <tbody>
                {notificationList.length > 0 &&
                  notificationList.map((notificationObj, index) => {
                    return (
                      <tr key={index}>
                        <td>{notificationObj.message}</td>
                        <td>
                          {notificationObj.createdAt
                            ? moment(notificationObj.createdAt)
                                .tz(timezone)
                                .format("DD-MMM-YYYY hh:mm A")
                            : "-"}
                        </td>
                        <td className="td-actions text-center">
                          <span className="link">
                            <img
                              onClick={(e) =>
                                handleDeleteNotificationsClick(
                                  e,
                                  notificationObj.id
                                )
                              }
                              src={DeleteIcon}
                              alt="Delete"
                            />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        }
      </div>

      <DeleteConfirmation
        showModal={showDeleteModal}
        title="Delete Notification"
        message="Are you sure want to delete this notification?"
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowDeleteModal(false);
          setDeleteNotificationId(null);
        }}
        handleSubmitClick={handleDeleteNotification}
      />
    </React.Fragment>
  );
};

export default withRouter(TimeClockHistory);
