import { useFormikContext } from "formik";
import React from "react";
import { useEffect } from "react";
import { newRecipient } from "../context/order";

const ResetRecipient = () => {
  const formik = useFormikContext();
  useEffect(() => {
    console.log("reset");
    newRecipient.addons = [];
    newRecipient.products = [];
    newRecipient.whats_the_occasion_id = null;
    newRecipient.whats_the_occasion = "";
    formik.setValues((v) => {
      return {
        ...v,
        recipients: [newRecipient],
      };
    });
  }, []);
  return <></>;
};

export default ResetRecipient;
