import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import classes from "./styles.module.scss";
const DropdownMenu = ({
  activeMenu,
  onMouseEnter,
  onMouseLeave,
  onFilterClick,
}) => {
  const [activeImage, setActiveImage] = useState("");

  useEffect(() => {
    setActiveImage(activeMenu?.category?.category_image);
  }, []);

  return (
    <div
      className={classes.DropdownMenu}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="row">
        {activeMenu?.children.map((c, i) => (
          <div className="col-2" key={i}>
            <p>{c.title}</p>
            <ul>
              {c.children.map((z) => (
                <li
                  onMouseEnter={() => {
                    setActiveImage(z?.category?.category_image);
                  }}
                  onMouseLeave={() => {
                    setActiveImage(activeMenu?.category?.category_image);
                  }}
                  onClick={() => onFilterClick(z)}
                  key={z.title}
                >
                  {z.title}
                </li>
              ))}
            </ul>
          </div>
        ))}
        <div className="col">
          {activeImage && (
            <img
              style={{ height: "300px", width: "300px", objectFit: "cover" }}
              src={activeImage}
              alt=""
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DropdownMenu;
