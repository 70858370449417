export const braintreeFieldsSettings = {
  number: {
    selector: "#cc-number",
    placeholder: "XXXX - XXXX - XXXX - XXXX",
  },
  cvv: {
    selector: "#cvv",
    placeholder: "CVV",
    type: "password",
  },
  expirationDate: {
    selector: "#expiration-date",
    placeholder: "MM/YY",
  },
  cardholderName: {
    selector: "#cc-name",
    placeholder: "Name On The Card",
  },
};

export const braintreeInputStyles = {
  input: {
    "font-size": "16px",
    "font-family": "'Kern', sans-serif",
    "font-weight": "normal",
    color: "black",
  },
  ":focus": {
    color: "black",
  },
  ".valid": {
    color: "#69ba78",
  },
  ".invalid": {
    color: "#e44a41",
  },
};
