import CheckBox from "Components/FormControl/CheckBox/CheckBox";
import { setAllStore } from "Helpers/setAllStore";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { getDeliveryTypes } from "../../../api/order";
import { getMostPopularProducts } from "../../../api/report";
import CalendarIcon from "../../../Assets/images/calendar.svg";
import CloseIcon from "../../../Assets/images/close.svg";
import SearchIcon from "../../../Assets/images/search.svg";
import Date from "../../../Components/FormControl/Date/Date";
import Select from "../../../Components/FormControl/Select/Select";
import Header from "../../../Components/Header/Header";
import ReactTable from "../../../Components/Table/Table";
import { AppContext } from "../../../Context/AppContext";
import { MODULES } from "./../../../Helpers/Constants";
import { getAccessByModuleName } from "./../../../Routes/Routes";
import styles from "./index.module.scss";
const MostPorpularProduct = () => {
  const { showToastMessage, modules } = useContext(AppContext);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [defaultFilter, setDefaultFilter] = React.useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });
  const [fromDate, setFromDate] = useState(
    moment().startOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [toDate, setToDate] = useState(
    moment().endOf("isoWeek").format("ddd MMM DD, YYYY")
  );

  const [searchBy, setSearchBy] = React.useState("orderDate");
  const [firstTimePageLoad, setFirstTimePageLoad] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  // const [deliveryType, setDeliveryType] = React.useState("");
  const [deliveryTypeVal, setDeliveryTypeVal] = React.useState(0);
  const [deliveryList, setDeliveryList] = useState([]);
  const product = [
    { id: "orderDate", title: "Order Date" },
    { id: "deliveryDate", title: "Delivery Date" },
  ];

  const [checkAllStore,setCheckAllStore] = useState(true);


  useEffect(() => {
    handleGetDeliveryTypes();
  }, []);

  const handleFromDate = async (data) => {
    setToDate(moment(data).add(7, "days").utc().format("ddd MMM DD, YYYY"));
  };

  const handleToDate = async (data) => {
    moment(data).subtract(7, "days").utc().format("ddd MMM DD, YYYY");
  };

  // get delivery type list
  const handleGetDeliveryTypes = async () => {
    let deliveryPostData = {
      sortBy: [
        {
          id: "id",
          desc: false,
        },
      ],
    };
    const response = await getDeliveryTypes(deliveryPostData);

    if (response && response.status === 200) {
      // setDeliveryType(response.data.data.rows);
      let finalData = response.data.data.rows;
      let tempArrAll = {
        id: 0,
        title: "All",
      };
      let tempArrData = [];
      tempArrData.push(tempArrAll);
      finalData.map((d, i) => {
        let tempArr = {
          id: d.id,
          title: d.name,
        };
        tempArrData.push(tempArr);
      });
      setDeliveryList(tempArrData);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  // let [deliveryList, setDeliveryList] = React.useState([]);
  // if (deliveryType.length > 0) {
  //   deliveryList = [];
  //   deliveryType.map((d, i) => {
  //     let tempArr = {
  //       id: deliveryType[i].id,
  //       title: deliveryType[i].name,
  //     };
  //     deliveryList.push(tempArr);
  //   });
  // }

  const handleClearSearch = async (event) => {
    event.preventDefault();
    setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
    setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
    setSearchBy("orderDate");
    setDeliveryTypeVal(0);
    fetchData({
      ...defaultFilter,
      fromDateDefault: moment().startOf("isoWeek").format("YYYY-MM-DD"),
      toDateDefault: moment().endOf("isoWeek").format("YYYY-MM-DD"),
    });
  };
  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
  }) => {

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      # 
    */
      setAllStore(checkAllStore);

    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      searchBy: searchBy,
      deliveryType: deliveryTypeVal,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
    };

    let accountAccess = getAccessByModuleName(modules, MODULES.ACCOUNT_LOOKUP);
    if (accountAccess && accountAccess.permissions.read_access) {
      const response = await getMostPopularProducts(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count / pageSize));
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    } else {
      setFirstTimePageLoad(false);
    }
    setLoading(false);
    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };
  const columns = React.useMemo(() => [
    {
      Header: "Products",
      accessor: "Products",
      isVisible: true,
      disableFilters: true,
      Cell: ({ value, row, column }) => {
        let defaultIndex = 0;
        row.original.product.product_images.forEach((d, i) => {
          if (d.is_default_selected) defaultIndex = i;
        });
        return (
          <div className="row">
            <div className={`col-md-4 ${styles.ProducatImageWapper}`}>
              <img
                height="70"
                width="60"
                src={
                  row.original.product.product_images.length === 0 ||
                  row.original.product.product_images[defaultIndex].image ===
                    "" ||
                  row.original.product.product_images[defaultIndex].image ===
                    "null" ||
                  row.original.product.product_images[defaultIndex].image ===
                    null
                    ? ""
                    : `${row.original.product.product_images[defaultIndex].image}`
                }
                alt=""
              />
            </div>
          </div>
        );
      },
    },
    {
      Header: "Products Name",
      accessor: "title",
      disableSortBy: true,
      isVisible: true,
      Cell: ({ value, row, column }) => {
        return `${row.original.product ? row.original.product.title : ""}`;
      },
    },
    {
      Header: "Variation",
      accessor: "variation_title",
      isVisible: true,
      disableSortBy: true,
      Cell: ({ value, row, column }) => {
        return row.original.title;
      },
    },
    {
      Header: "Quantity",
      accessor: "no_of_orders",
      isVisible: true,
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: "Price",
      accessor: "order_total",
      disableSortBy: true,
      isVisible: true,
      disableFilters: true,
      Cell: ({ value, row, column }) => {
        return `$${row.original.order_total}`;
      },
    },
  ]);

  return (
    <div>
      <Header title="Most Popular Products" />
      <div className="row mb-5">
        <div className="col-sm-2 col-xs-12">
          <Date
            label="Filter From"
            value={fromDate}
            dateFormat="ddd MMM DD, YYYY"
            type="date"
            fieldIcon={CalendarIcon}
            onChange={(fromDate) => {
              setFromDate(moment(fromDate).format("ddd MMM DD, YYYY"));
              // handleFromDate(fromDate);
            }}
          />
        </div>
        <div className="col-sm-2 col-xs-12">
          <Date
            label="Filter To"
            value={toDate}
            dateFormat="ddd MMM DD, YYYY"
            type="date"
            fieldIcon={CalendarIcon}
            onChange={(toDate) => {
              setToDate(moment(toDate).format("ddd MMM DD, YYYY"));
              // handleToDate(toDate);
            }}
          />
        </div>
        <div className="col-md-2">
          <Select
            label="Search By"
            value={searchBy}
            options={product}
            required={false}
            onChange={(data) => setSearchBy(data)}
            disabled={false}
          />
        </div>
        <div className="col-md-2">
          <Select
            label="Delivery Type"
            value={deliveryTypeVal}
            options={deliveryList}
            onChange={(data) => setDeliveryTypeVal(data)}
            disabled={false}
          />
        </div>
        <div  className="col-sm-2 col-xs-12">
          <CheckBox
            label="All Stores"
            onChange={(data)=>{setCheckAllStore(data)}}
            value={checkAllStore}
          />
        </div>
        <div className="col-sm-1 col-xs-12">
          <div className="td-actions pt-4">
            <span
              onClick={(e) => fetchData(defaultFilter)}
              className={`link cursor-pointer ${styles.searchIconWrapper}`}
            >
              <img className={styles.searchIcon} src={SearchIcon} alt="" />
            </span>
            <span
              onClick={(e) => handleClearSearch(e)}
              className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
            >
              <img className={styles.clearIcon} src={CloseIcon} alt="" />
            </span>
          </div>
        </div>
      </div>
      <div>
        <ReactTable
          columns={columns}
          data={data}
          loading={loading}
          fetchData={fetchData}
          pageCount={pageCount}
          defaultFilter={defaultFilter}
          noDataText={"There is no data to display"}
          displayGlobalSearch={false}
          displayColumnVisibility={false}
        />
      </div>
    </div>
  );
};
export default MostPorpularProduct;
