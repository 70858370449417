export const HomePageActions = {
  GET_HOME_PAGE_DETAILS: "GET_HOME_PAGE_DETAILS",
  GET_HOME_PAGE_PRODUCT_CATEGRIES: "GET_HOME_PAGE_PRODUCT_CATEGRIES",
  ADD_NEW_BANNER: "ADD_NEW_BANNER",
  REMOVE_BANNER: "REMOVE_BANNER",
  UPDATE_BANNER_DATA: "UPDATE_BANNER_DATA",
  CHECK_BANNER_DATA: "CHECK_BANNER_DATA",
  ADD_NEW_PARTNER: "ADD_NEW_PARTNER",
  REMOVE_PARTNER: "REMOVE_PARTNER",
  ADD_NEW_CERTIFICATION: "ADD_NEW_CERTIFICATION",
  REMOVE_CERTIFICATION: "REMOVE_CERTIFICATION",
  UPDATE_SEO_DATA: "UPDATE_SEO_DATA",
  CHECK_SEO_DATA: "CHECK_SEO_DATA",
  UPDATE_OG_INFO_DATA: "UPDATE_OG_INFO_DATA",
  CHECK_OG_INFO_DATA: "CHECK_OG_INFO_DATA",
  UPDATE_TWITTER_INFO_DATA: "UPDATE_TWITTER_INFO_DATA",
  CHECK_TWITTER_INFO_DATA: "CHECK_TWITTER_INFO_DATA",
  UPDATE_SCHEMA_DATA: "UPDATE_SCHEMA_DATA",
  CHECK_SCHEMA_DATA: "CHECK_SCHEMA_DATA",
  UPDATE_FEATURED_PRODUCT_DATA: "UPDATE_FEATURED_PRODUCT_DATA",
  CHECK_FEATURED_PRODUCT_DATA: "CHECK_FEATURED_PRODUCT_DATA",
  UPDATE_FEATURED_CATEGORY_DATA: "UPDATE_FEATURED_CATEGORY_DATA",
  CHECK_FEATURED_CATEGORY_DATA: "CHECK_FEATURED_CATEGORY_DATA",
  ADD_FEATURED_PRODUCT_PRODUCT: "ADD_FEATURED_PRODUCT_PRODUCT",
  DELETE_FEATURED_PRODUCT_PRODUCT: "DELETE_FEATURED_PRODUCT_PRODUCT",
  ADD_FEATURED_PRODUCT_CATEGORY: "ADD_FEATURED_PRODUCT_CATEGORY",
  DELETE_FEATURED_PRODUCT_CATEGORY: "DELETE_FEATURED_PRODUCT_CATEGORY",
  SET_FEATURED_PRODUCT_SEQUENCE: "SET_FEATURED_PRODUCT_SEQUENCE",
  SET_FEATURED_CATEGORY_SEQUENCE: "SET_FEATURED_CATEGORY_SEQUENCE",
};
