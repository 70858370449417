import { Button, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import React, { useEffect, useState } from "react";
import RadioBox from "Components/FormControl/RadioBox/RadioBox";
import TextArea from "Components/FormControl/TextArea/TextArea";
// SCSS
import styles from "./cancelNote.module.scss";
import CheckBox from "../../../../Components/FormControl/CheckBox/CheckBox";
import { REASON_LIST } from "Helpers/Constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalWithReasons = ({
  showModal,
  pageTitle,
  handleCancelClick,
  handleSubmitClick,
  placeholder,
  totalRefundAmount = 0,
}) => {
  const [addnote, setAddNote] = useState("");
  const [checkBox, setCheckBox] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0);
  const [refundAmountInitial, setRefundAmountInitial] = useState(0);
  const [error, setErrorMessage] = useState("");
  const [errorAmount, setErrorMessageAmount] = useState("");
  const [reasonOptionValue, setReasonOptionValue] = useState(null);
  const [reasonMessageValue, setReasonMessageValue] = useState("");

  useEffect(() => {
    if (totalRefundAmount) {
      // setRefundAmount(totalRefundAmount);
      setRefundAmountInitial(totalRefundAmount);
    }
  }, [totalRefundAmount]);

  useEffect(() => {
    setReasonMessageValue(REASON_LIST.DESIGN_STYLE_ISSUE.title)
    setAddNote("")
    setReasonOptionValue(REASON_LIST.DESIGN_STYLE_ISSUE.id)
  }, [showModal]);

  const submit = (e) => {
    if (reasonOptionValue === 1 && addnote === "") {
      setErrorMessage(`Please write why you are ${placeholder ? placeholder : "cancelling"} the order`);
    } else {
      setErrorMessage("");
      setErrorMessageAmount("");
      handleSubmitClick(reasonOptionValue, reasonOptionValue === 1 ? addnote : reasonMessageValue);
    }
  };
  const handleSelectedReason = (val, msg) => {
    setReasonOptionValue(parseInt(val));
    setReasonMessageValue(msg);
  }
  // const { orderState } = useOrder();
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-4 py-0 pb-3`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            {pageTitle === "Replace Order" && <h3 className="text-center px-5" style={{fontSize : "20px"}}>{`Replacing an order will allow you to send a replacement
without charging the customer again`}</h3>}
            <div className={`row flex-column px-2 ${styles.reasonCard_radio_button}`}>
              <h3 className="text-left">{`Please carefully select the reason you are ${placeholder ? placeholder : "cancelling"} the order`}</h3>
              {Object.keys(REASON_LIST).map((key) => (
                <RadioBox
                  selectedValue={reasonOptionValue}
                  onChange={(e) => {
                    handleSelectedReason(e, key === "OTHER" ? addnote : REASON_LIST[key].title);
                  }}
                  value={REASON_LIST[key].id}
                  label={key === "OTHER" ? `${REASON_LIST[key].title} (Please Write A Detailed Explanation)` : REASON_LIST[key].title}
                />
              ))}
              {reasonOptionValue === 1 &&
                <TextArea
                  label={`Please write why you are ${placeholder ? placeholder : "cancelling"} the order`}
                  rows={3}
                  className="w-100"
                  style={{ height: "130px" }}
                  onChangeWithEvent={(e) => {
                    setErrorMessage("");
                    setAddNote(e.target.value);
                  }}
                  id="other_reason"
                  error={(Boolean(error) && reasonOptionValue === 1) && error}
                  touched={(Boolean(error) && reasonOptionValue === 1) && error}
                  value={addnote}
                  disabled={false}
                />
              }
            </div>
            <div className="row">
              <div className="col-12 my-3">
                <Button
                  onClick={submit}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ModalWithReasons;
