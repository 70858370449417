import React from "react";
// images
import CalendarIcon from "../../Assets/images/calendar.svg";
import CloseIcon from "../../Assets/images/close.svg";
import SearchIcon from "../../Assets/images/search.svg";
import Date from "../../Components/FormControl/Date/Date";
import RadioBox from "Components/FormControl/RadioBox/RadioBox";
import styles from "./DefaultOrderFilter.module.scss";
import CheckBox from "Components/FormControl/CheckBox/CheckBox";
const DefaultOrderFilter = (props) => {
  return (
    <div className={"row " + props.wrapperClass}>
      {!props.notShowFilterBy && (
        <div className="col-12 d-flex align-items-center">
          <span
            className="mb-0 mt-2 pb-0 d-inline-bock mr-3"
            style={{ lineHeight: 1 }}
          >
            Filter By
          </span>
          <RadioBox
            selectedValue={props.type}
            onChange={() => {
              if (props.setType) {
                props.setType("order_date");
              }
            }}
            label="Order date"
            value="order_date"
          />
          <RadioBox
            selectedValue={props.type}
            onChange={() => {
              if (props.setType) {
                props.setType("delivery_date");
              }
            }}
            label="Delivery date"
            value="delivery_date"
          />
        </div>
      )}
      <div className="col-sm-3 col-xs-12">
        <Date
          label="Filter From"
          value={props.fromDate}
          dateFormat="ddd MMM DD, YYYY"
          type="date"
          fieldIcon={CalendarIcon}
          onChange={(fromDate) => {
            props.handleFromDate(fromDate);
          }}
        />
      </div>
      <div className="col-sm-3 col-xs-12">
        <Date
          label="Filter To"
          value={props.toDate}
          dateFormat="ddd MMM DD, YYYY"
          type="date"
          fieldIcon={CalendarIcon}
          onChange={(toDate) => {
            props.handleToDate(toDate);
          }}
        />
      </div>
      <div className="ml-3">
        <CheckBox
        value={props.defaultSetCheckAllStore}
        label="All Stores"
        onChange={(data)=>{props.checkAllStore(data)}}
        />
      </div>
      <div className="col-sm-3 col-xs-12">
        <div className="td-actions pt-4">
          <span
            onClick={(e) => props.fetchData(props.defaultFilter)}
            className={`link cursor-pointer ${styles.searchIconWrapper}`}
          >
            <img className={styles.searchIcon} src={SearchIcon} alt="" />
          </span>
          <span
            onClick={(e) => props.handleClearSearch(e)}
            className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
          >
            <img className={styles.clearIcon} src={CloseIcon} alt="" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default DefaultOrderFilter;
