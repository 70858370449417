import { callPutApi, callGetApi } from "./api";

export async function getHomePage() {
      // return this.interceptors.GET(`home-page`);
      try {
            const response = await callGetApi({ url: "home-page" });
            return response;
      } catch (error) {
            throw error;
      }
}

export async function updateHomePage(data) {
      // return this.interceptors.PUT(`home-page/update`, data);
      try {
            const response = await callPutApi({ url: "home-page/update", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}
