import { OrderConfigContext } from "Context/OrderConfigContext";
import { useFormikContext } from "formik";
import { DateTime } from "luxon";
import { useContext, useEffect, useMemo, useState } from "react";

export function useDate() {
  const config = useContext(OrderConfigContext);
  const [blockoutDates, setBlockOutDates] = useState([]);
  const [tooltipDays, setTooltipDays] = useState([]);
  const [upchargeDatesArray, setUpchargeDatesArray] = useState([]);
  const formik = useFormikContext();

  let dependency = useMemo(() => {
    return {
      zip_code: formik.values.delivery_information?.zipcode,
      address_type_id: formik.values.delivery_information?.address_type_id,
      is_local: formik.values.delivery_information?.is_local,
    };
  }, [
    formik.values.delivery_information?.zipcode,
    formik.values.delivery_information?.address_type_id,
    formik.values.delivery_information?.is_local,
  ]);

  useEffect(() => {
    let blocks = [];
    if (config.blockoutList && config.blockoutList.length > 0) {
      blocks = config.blockoutList
        ?.filter((a) => {
          let zip_code_block = a.blockout_dates_zipcode_to_blocks.findIndex(
            (c) => c.store_settings_zipcode.zip_code === dependency.zip_code
          );

          let address_type_block =
            a.blockout_dates_delivery_address_types.findIndex(
              (c) => c.delivery_address_type_id === dependency.address_type_id
            );

          if (a.services_block_local_delivery && dependency.is_local) {
            return true;
          }

          if (a.services_block_non_local_delivery && !dependency.is_local) {
            return true;
          }

          if (zip_code_block !== -1) {
            return true;
          }

          if (address_type_block !== -1) {
            return true;
          }
          return false;
        })
        .map((a) => {
          return {
            from: new Date(
              DateTime.fromSeconds(a.start_blocking_on)
                .setZone("America/Los_Angeles")
                .toJSDate()
                .toLocaleString("en-US", {
                  timeZone: "America/Los_Angeles",
                })
            ),
            to: new Date(
              DateTime.fromSeconds(a.stop_repeating_on)
                .setZone("America/Los_Angeles")
                .toJSDate()
                .toLocaleString("en-US", {
                  timeZone: "America/Los_Angeles",
                })
            ),
          };
        });
    }
    setBlockOutDates(blocks);
  }, [dependency, config.blockoutList]);

  //Upcharge dates
  useEffect(() => {
    let upchargeDate = [];
    config.upchargeList?.forEach((c) => {
      let start_date = new Date(
        DateTime.fromSeconds(+c.apllies_on)
          .toJSDate()
          .toLocaleString("en-US", {
            timeZone: "UTC",
          })
      );
      let end_date = new Date(
        DateTime.fromSeconds(+c.apllies_untill)
          .toJSDate()
          .toLocaleString("en-US", {
            timeZone: "UTC",
          })
      );
      for (let q = start_date; q <= end_date; q.setDate(q.getDate() + 1)) {
        let z;
        if (c.local && dependency.is_local) {
          z = {
            date: q.toISOString(),
            charge: c.amount,
            title: c.title,
            id: c.id,
          };
        } else if (c.non_local && !dependency.is_local) {
          z = {
            charge: c.amount,
            date: q.toISOString(),
            title: c.title,
            id: c.id,
          };
        }
        if (z) {
          upchargeDate.push(z);
        }
      }
    });
    let tooltipDays = upchargeDate?.map((a, i) => {
      return {
        id: i.toString(),
        date: DateTime.fromISO(a.date).toJSDate().getDate(),
        month: DateTime.fromISO(a.date).toJSDate().getMonth(),
        year: DateTime.fromISO(a.date).toJSDate().getFullYear(),
        message: `${a.title} +$${a.charge}`,
      };
    });
    setTooltipDays(tooltipDays);
    setUpchargeDatesArray(upchargeDate);
  }, [dependency, config.upchargeList]);

  return {
    tooltipDays,
    blockoutDates,
    upchargeDatesArray,
  };
}
