
import LoaderGif from "../../Assets/loader.gif";
const LoadingScreen = () => {
   return( <div className="loader-wrapper">
    <div
    style={{
        height: "100%",
        width: "100%",
        display: "grid",
        placeItems: "center",
    }}
    >
    {/* <Loader type="ThreeDots" color="#0069d9" height={50} width={50} /> */}
    <img style={{ width: "500px" }} src={LoaderGif} alt="Loader" />
    </div>
    </div>)
}
export default LoadingScreen;