/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import AddIcon from "../../Assets/images/add.svg";
import CheckBox from "./../FormControl/CheckBox/CheckBox";
// Context
import { AppContext } from "./../../Context/AppContext";
// SCSS
import styles from "./index.module.scss";
// COMPONENTS
import Select from "../../Components/FormControl/Select/Select";
import Text from "../../Components/FormControl/Text/Text";
import TextArea from "../FormControl/TextArea/TextArea";
import AddBlackIcon from "./../../Assets/images/add-black.svg";
import DeleteIcon from "../../Assets/images/delete.svg";
import AutocompleteInput from "../FormControl/Autocomplete/Autocomplete";

//helper
import { CategoryPrimaryInfoForm } from "../../Hooks/category/categoryPrimaryInfoFormFromik";
import { useCategory } from "../../Context/CategoryCurd/useCategory";
import { CATEGORY_TYPES } from "../../Helpers/Constants";

//SERVICES
import UtilsApi from "../../Services/Utils/Utils";
import { uploadImage } from "../../api/utils";
import WYSIWIGEditor from "Components/WYSIWIGEditor/WYSIWIGEditor";

function PrimaryInformation(props) {
  const utilsService = new UtilsApi();
  const { showToastMessage, setIsLoading } = useContext(AppContext);

  const uploadImageRef = React.useRef();

  const categoryList = CATEGORY_TYPES;

  const {
    categoryState,
    setCategoryPrimaryData,
    checkCategoryPrimaryData,
  } = useCategory();

  let categoryPrimaryInfoValue = {
    title: categoryState.setCategoryAllData.title ?? "",
    type: categoryState.setCategoryAllData.type ?? null,
    category_image: categoryState.setCategoryAllData.category_image ?? "",
    keywords: categoryState.setCategoryAllData.keywords ?? "",
    customer_note: categoryState.setCategoryAllData.customer_note ?? "",
    sequence_number: categoryState.setCategoryAllData.sequence_number ?? 0,
    status: categoryState.setCategoryAllData.status ?? 1,
    is_filter: categoryState.setCategoryAllData.is_filter ?? false,
    description: categoryState.setCategoryAllData.description ?? "",
    show_certification:
      categoryState.setCategoryAllData.show_certification ?? false,
  };

  const submit = (values) => {};
  const cbSubmit = (values) => {
    setCategoryPrimaryData(values);
  };

  const { formik: CategoryPrimaryInfoFormik } = CategoryPrimaryInfoForm(
    submit,
    categoryPrimaryInfoValue,
    cbSubmit,
    checkCategoryPrimaryData
  );

  const handleUploadImage = async (imageData, imageType = "CATEGORIES") => {
    setIsLoading(true);
    let imageFormdata = new FormData();
    imageFormdata.append("image", imageData);
    imageFormdata.append("type", imageType);
    // const response = await utilsService.uploadImage(imageFormdata);
    const response = await uploadImage(imageFormdata);

    // console.log('handleUploadImage', response);
    if (response && response.status === 200) {
      setIsLoading(false);
      return response.data.data;
    } else {
      setIsLoading(false);
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        CategoryPrimaryInfoFormik.handleSubmit(e);
      }}
      className={`row`}
    >
      <div className={`${styles.productsWrapper}`} id="primary-information">
        <div className="">
          <div className="">
            <div className={styles.title}>Primary Information </div>
            {/* <span
              className={`link cursor-pointer ml-2 ${styles.searchIconWrapper}`}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <img className={styles.searchIcon} src={AddIcon} alt="" />
            </span> */}

            <div className={`mt-2 md-4`}>
              {/* <div className={`row`}>
                <div className={`col-md-12 col-xs-12`}>
                  <div className={styles.title}>Current Hierarchy - {props.categoryHierarchyName}</div>
                </div>
              </div> */}
              <div className={`row`}>
                <div className={`col-md-6 col-xs-12`}>
                  <CheckBox
                    label="Filter Category"
                    value={CategoryPrimaryInfoFormik.values.is_filter}
                    required={false}
                    submitStatus={props.submitStatus}
                    onChange={(data) =>
                      CategoryPrimaryInfoFormik.setValues((v) => {
                        return { ...v, is_filter: data };
                      })
                    }
                    error={CategoryPrimaryInfoFormik.errors.is_filter}
                    touched={CategoryPrimaryInfoFormik.touched.is_filter}
                    id="is_filter"
                  />
                </div>
                {props.showCategorySearching === 1 ? (
                  <div className={`col-md-6 col-xs-12`}>
                    <AutocompleteInput
                      label="Select Category New / Existing"
                      required={true}
                      submitStatus={props.submitStatus}
                      onChangeParent={(data) => {
                        if (data.id) {
                          props.handleSetSelectedCategoryData(data.id);
                        }
                      }}
                      onChangeParentManual={(data) => {}}
                      disabled={false}
                      listData={categoryState.categoryListData}
                      id="categorySearching"
                    />
                  </div>
                ) : (
                  <div className={`col-md-6 col-xs-12`}></div>
                )}

                <div className={`col-md-6 col-xs-12`}>
                  <Text
                    type="text"
                    label="Title"
                    required={true}
                    submitStatus={props.submitStatus}
                    value={CategoryPrimaryInfoFormik.values.title}
                    onChange={(data) =>
                      CategoryPrimaryInfoFormik.setValues((v) => {
                        return { ...v, title: data };
                      })
                    }
                    error={CategoryPrimaryInfoFormik.errors.title}
                    touched={CategoryPrimaryInfoFormik.touched.title}
                    id="title"
                  />
                </div>

                <div className={`col-md-6 col-xs-12`}>
                  <Select
                    label="Category Type"
                    parentClass="text-left"
                    value={CategoryPrimaryInfoFormik.values.type}
                    required={false}
                    submitStatus={props.submitStatus}
                    onChange={(data) =>
                      CategoryPrimaryInfoFormik.setValues((v) => {
                        return { ...v, type: data };
                      })
                    }
                    error={CategoryPrimaryInfoFormik.errors.type}
                    touched={CategoryPrimaryInfoFormik.touched.type}
                    options={categoryList}
                    disabled={false}
                    id="type"
                  />
                </div>
                {/* hide by darshan on 12/07/2021  */}
                {/* <div className={`col-md-6 col-xs-12`}>
                  <Text
                    type="number"
                    label="Sequence Number"
                    required={true}
                    submitStatus={props.submitStatus}
                    value={CategoryPrimaryInfoFormik.values.sequence_number.toString()}
                    onChange={(data) =>
                      CategoryPrimaryInfoFormik.setValues((v) => {
                        return { ...v, sequence_number: +data };
                      })
                    }
                    error={CategoryPrimaryInfoFormik.errors.sequence_number}
                    touched={CategoryPrimaryInfoFormik.touched.sequence_number}
                    id="sequence_number"
                  />
                </div> */}
                <div className={`col-md-6 col-xs-12`}>
                  <CheckBox
                    label="Show Certification"
                    submitStatus={props.submitStatus}
                    value={CategoryPrimaryInfoFormik.values.show_certification}
                    required={false}
                    onChange={(data) =>
                      CategoryPrimaryInfoFormik.setValues((v) => {
                        return { ...v, show_certification: data };
                      })
                    }
                    error={CategoryPrimaryInfoFormik.errors.show_certification}
                    touched={
                      CategoryPrimaryInfoFormik.touched.show_certification
                    }
                    id="show_certification"
                  />
                </div>

                <div className="col-md-3 col-xs-12">
                  <div
                    onClick={() => {
                      uploadImageRef.current.click();
                    }}
                    className={styles.uploadBox}
                  >
                    <input
                      type="file"
                      className="d-none"
                      ref={uploadImageRef}
                      accept="image/*"
                      onChange={async (e) => {
                        e.preventDefault();
                        const file = e.target.files[0];
                        const uploadResponse = await handleUploadImage(file);
                        CategoryPrimaryInfoFormik.setValues((v) => {
                          return {
                            ...v,
                            category_image: uploadResponse[0].url,
                          };
                        });
                      }}
                    />
                    {CategoryPrimaryInfoFormik.values.category_image ? (
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={CategoryPrimaryInfoFormik.values.category_image}
                        alt="upload Image URL"
                      />
                    ) : (
                      <img src={AddBlackIcon} alt="upload_Image_URL" />
                    )}
                  </div>
                  {CategoryPrimaryInfoFormik.values.category_image ? (
                    <img
                      onClick={(e) => {
                        e.preventDefault();
                        CategoryPrimaryInfoFormik.setValues((v) => {
                          return {
                            ...v,
                            category_image: "",
                          };
                        });
                      }}
                      src={DeleteIcon}
                      style={{
                        position: "absolute",
                        top: "16px",
                        left: "138px",
                        cursor: "pointer",
                      }}
                      width="14px"
                      height="14px"
                      alt="DELETE"
                    />
                  ) : (
                    ""
                  )}
                </div>

                <div className={`col-md-3 col-xs-12`}>
                  <label className={`bmd-label-floating ${styles.imageLabel}`}>
                    Category Image
                  </label>
                </div>

                <div className={`link cursor-pointer col-md-6 col-xs-12`}>
                  <label>Status</label>

                  {CategoryPrimaryInfoFormik.values.status === 1 ? (
                    <div className={styles.status}>
                      <div
                        className={styles.Active}
                        style={{ "font-weight": "bold" }}
                      >
                        Active
                      </div>
                      <div
                        className={styles.Inactive}
                        onClick={(data) =>
                          CategoryPrimaryInfoFormik.setValues((v) => {
                            return { ...v, status: 0 };
                          })
                        }
                      >
                        Inactive
                      </div>
                    </div>
                  ) : (
                    <div className={styles.status}>
                      <div
                        className={styles.Active}
                        onClick={(data) =>
                          CategoryPrimaryInfoFormik.setValues((v) => {
                            return { ...v, status: 1 };
                          })
                        }
                      >
                        Active
                      </div>
                      <div
                        className={styles.Inactive}
                        style={{ "font-weight": "bold" }}
                      >
                        Inactive
                      </div>
                    </div>
                  )}
                </div>

                <div className={`col-md-6 col-xs-12`}>
                  <Text
                    type="text"
                    label="Keywords"
                    submitStatus={props.submitStatus}
                    value={CategoryPrimaryInfoFormik.values.keywords}
                    required={false}
                    onChange={(data) =>
                      CategoryPrimaryInfoFormik.setValues((v) => {
                        return { ...v, keywords: data };
                      })
                    }
                    error={CategoryPrimaryInfoFormik.errors.keywords}
                    touched={CategoryPrimaryInfoFormik.touched.keywords}
                    id="keywords"
                  />
                </div>
                {/* hide by darshan on 12/07/2021  */}
                {/* <div className={`col-md-6 col-xs-12`}>
                  <Text
                    type="text"
                    label="Customer Note"
                    submitStatus={props.submitStatus}
                    value={CategoryPrimaryInfoFormik.values.customer_note}
                    required={false}
                    onChange={(data) =>
                      CategoryPrimaryInfoFormik.setValues((v) => {
                        return { ...v, customer_note: data };
                      })
                    }
                    error={CategoryPrimaryInfoFormik.errors.customer_note}
                    touched={CategoryPrimaryInfoFormik.touched.customer_note}
                    id="customer_note"
                  />
                </div> */}
              </div>

              <div className={`row`}>
                <label
                  className="bmd-label-floating"
                  style={{ marginLeft: "15px" }}
                >
                  Description
                </label>
                <div className={`col-md-12 col-xs-12`}>
                  <WYSIWIGEditor 
                    data={CategoryPrimaryInfoFormik.values.description}
                    onChange={(data) => {
                      CategoryPrimaryInfoFormik.setValues((v) => {
                        return { ...v, description: data };
                      })
                    }}
                    error={CategoryPrimaryInfoFormik.errors.description}
                    touched={CategoryPrimaryInfoFormik.touched.description}
                  />
                  {/* <TextArea
                    label="Description"
                    rows={5}
                    submitStatus={props.submitStatus}
                    value={CategoryPrimaryInfoFormik.values.description}
                    required={false}
                    onChange={(data) =>
                      CategoryPrimaryInfoFormik.setValues((v) => {
                        return { ...v, description: data };
                      })
                    }
                    error={CategoryPrimaryInfoFormik.errors.description}
                    touched={CategoryPrimaryInfoFormik.touched.description}
                    disabled={false}
                    id="description"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="d-none"
        id="category_primary_info_submit_button"
        type="submit"
      ></button>
    </form>
  );
}

export default PrimaryInformation;
