import {
  addStockOrder,
  assignDesignerOrder,
  assignDesignerStockOrder,
  getDesignerStatusList,
  printGreetingMessage,
  orderDesignerStatusMarkAsCompleted,
  orderDesignerStatusBulkMarkAsCompleted,
  orderDesignerBulkPrintCard,
} from "api/order";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";

import { getProductsNormal } from "../../../api/product";
import { getDesigners } from "../../../api/user";
// Components
import Header from "../../../Components/Header/Header";
import ManageStockOrderModal from "../../../Components/Modal/StockOrder/Add/Add";
import DesignerList from "../../../Components/Modal/StockOrder/designerList/list";
import { AppContext } from "../../../Context/AppContext";
import { MODULES } from "../../../Helpers/Constants";
import { getAccessByModuleName } from "../../../Routes/Routes";
import DesignerStatusSummary from "./header-summary";
import DesignerStatusToday from "./today-order";
import DesignerStatusTomorrow from "./tomorrow-order";
import DesignerStatusCustom from "./custom-order";
import CommonButtonComponent from "./common-button";
import DesignerStatusFilter from "../../../Components/DesignerStatusFilter/DesignerStatusFilter";
import { DateTime } from "luxon";
import { PST_ZONE, UTC_ZONE } from "../Order/utility/util";

const DesignerStatus = (props) => {
  const { showToastMessage, modules, setIsLoading } = useContext(AppContext);
  const [designersList, setDesignersList] = useState([]);
  const [designersListClockIn, setDesignersListClockIn] = useState([]);
  const [productsList, setProductsList] = useState([]);

  // Date Range Filter Start
  let defaultFromDate = DateTime.fromFormat(
    moment().startOf("day").add(2, "days").format("ddd MMM DD, YYYY"),
    "ccc LLL dd, yyyy",
    {
      zone: PST_ZONE,
    }
  )
    .setZone(UTC_ZONE)
    .toFormat("ccc LLL dd, yyyy");

  let deafultToDate = moment()
    .endOf("day")
    .add(2, "days")
    .tz("America/Los_Angeles")
    .format("ddd MMM DD, YYYY");
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(deafultToDate);
  // Date Range Filter End

  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddModalDesigner, setShowAddModalDesigner] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [manageModalPageTitle, setManageModalPageTitle] = useState("");
  const [access, setAccess] = useState({});
  const [loadingToday, setLoadingToday] = React.useState(false);
  const [dataToday, setDataToday] = React.useState([]);
  // const [pageCountToday, setPageCountToday] = React.useState(1);
  const [defaultFilterToday, setDefaultFilterToday] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  const [loadingTomorrow, setLoadingTomorrow] = React.useState(false);
  const [dataTomorrow, setDataTomorrow] = React.useState([]);
  // const [pageCountTomorrow, setPageCountTomorrow] = React.useState(1);
  const [defaultFilterTomorrow, setDefaultFilterTomorrow] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  const [loadingCustom, setLoadingCustom] = React.useState(false);
  const [dataCustom, setDataCustom] = React.useState([]);
  // const [pageCountCustom, setPageCountCustom] = React.useState(1);
  const [defaultFilterCustom, setDefaultFilterCustom] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  const [headerCountInformation, setHeaderCountInformation] =
    React.useState(true);
  const [dataTodayHeader, setDataTodayHeader] = React.useState({});
  const [dataTomorrowHeader, setDataTomorrowHeader] = React.useState({});
  const [dataCustomHeader, setDataCustomHeader] = React.useState({});
  const [designersClockedIn, setDesignersClockedIn] = React.useState(0);

  const [todayDataFlag, setTodayDataFlag] = React.useState(false);
  const [tomorrowDataFlag, setTomorrowDataFlag] = React.useState(false);

  const [printAllAccess, setPrintAllAccess] = React.useState(false);
  const [selectedCheckBoxValueToday, setSelectedCheckBoxValueToday] = useState(
    []
  );
  const [selectedCheckBoxValueTomorrow, setSelectedCheckBoxValueTomorrow] =
    useState([]);
  const [selectedCheckBoxValueCustom, setSelectedCheckBoxValueCustom] =
    useState([]);
  // const ordersService = useMemo(() => new OrdersApi(), []);

  //API FOR GET TODAYS ORDER & STOCK ORDER
  const fetchDataToday = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {

    setIsLoading(true);
    setDefaultFilterToday({
      pageSize,
      pageIndex,
      filters,
      globalFilter,
      sortBy,
    });
    setLoadingToday(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      start_date: moment().startOf("day").format("YYYY-MM-DD"),
      end_date: moment().endOf("day").format("YYYY-MM-DD"),
      header_count_information: headerCountInformation,
    };
    // console.log("Today req", postData);
    let designerAccess = getAccessByModuleName(
      modules,
      MODULES.DESIGNER_STATUS
    );
    if (designerAccess && designerAccess.permissions.read_access) {
      const response = await getDesignerStatusList(postData);
      if (response && response.status === 200) {
        // console.log("Today re", response);
        //setHeaderCountInformation(false);
        setDataToday(response.data.data.orders_data.rows);
        // setPageCountToday(
        //   Math.ceil(response.data.data.orders_data.count / pageSize)
        // );
        if (headerCountInformation) {
          setDataTodayHeader(response.data.data.today_data);
          setDataTomorrowHeader(response.data.data.tomorrow_data);
          // setDataCustomHeader(response.data.data.custom_data);
          setDesignersClockedIn(
            response.data.data.today_data.designers_clocked_in
          );
        }
        setTodayDataFlag(true);
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }
    setLoadingToday(false);
    setIsLoading(false);
  };

  //API FOR GET TOMORROW ORDER & STOCK ORDER
  const fetchDataTomorrow = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
  }) => {
    setDefaultFilterTomorrow({
      pageSize,
      pageIndex,
      filters,
      globalFilter,
      sortBy,
    });
    setLoadingTomorrow(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      start_date: moment().startOf("day").add(1, "days").format("YYYY-MM-DD"),
      end_date: moment().endOf("day").add(1, "days").format("YYYY-MM-DD"),
      header_count_information: true,
    };
    // console.log("Req", postData);
    let designerAccess = getAccessByModuleName(
      modules,
      MODULES.DESIGNER_STATUS
    );
    if (designerAccess && designerAccess.permissions.read_access) {
      const response = await getDesignerStatusList(postData);
      if (response && response.status === 200) {
        // console.log("Sucess Data", response);
        setDataTomorrow(response.data.data.orders_data.rows);
        setDataTomorrowHeader(response.data.data.today_data);
        // setPageCountTomorrow(
        //   Math.ceil(response.data.data.orders_data.count / pageSize)
        // );
        setTomorrowDataFlag(true);
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }
    setLoadingTomorrow(false);
  };

  //API FOR GET CUSTOM DATE ORDER & STOCK ORDER
  const fetchDataCustom = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
  }) => {
    setDefaultFilterCustom({
      pageSize,
      pageIndex,
      filters,
      globalFilter,
      sortBy,
    });
    if (
      !dateValidation(
        fromDateDefault ? fromDateDefault : fromDate,
        toDateDefault ? toDateDefault : toDate
      )
    ) {
      return false;
    }
    setLoadingCustom(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      start_date: fromDateDefault
        ? fromDateDefault
        : moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault
        ? toDateDefault
        : moment(toDate).format("YYYY-MM-DD"),
      header_count_information: false,
      custom_date_range: true,
    };
    // console.log("Req", postData);
    let designerAccess = getAccessByModuleName(
      modules,
      MODULES.DESIGNER_STATUS
    );
    if (designerAccess && designerAccess.permissions.read_access) {
      const response = await getDesignerStatusList(postData);
      if (response && response.status === 200) {
        setDataCustom(response.data.data.orders_data.rows);
        if (response.data.data.custom_data) {
          setDataCustomHeader(response.data.data.custom_data);
        }
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }
    setLoadingCustom(false);
  };

  const fetchDataDummyFunction = async (type, data) => {
    if (todayDataFlag && type === "today") {
      fetchDataToday(data);
    } else if (tomorrowDataFlag && type === "tomorrow") {
      fetchDataTomorrow(data);
    } else if (todayDataFlag && tomorrowDataFlag && type === "custom") {
      fetchDataCustom(data);
    } else {
      return true;
    }
    // return true;
  };

  // Handle On Start Date Change Validation
  const handleFromDate = async (fromDate) => {
    setFromDate(fromDate);
  };

  // Handle On End Date Change Validation
  const handleToDate = async (toDate) => {
    setToDate(toDate);
  };

  // Date Validation Custom
  const dateValidation = (fromDate, toDate) => {
    let dateAfterTomorrow = moment(defaultFromDate).format("x");
    let startDate = moment(fromDate).format("x");
    let endDate = moment(toDate).format("x");
    if (startDate < dateAfterTomorrow) {
      showToastMessage(
        undefined,
        "Start date should be greater than today, tomorrow, and past date",
        "error",
        false
      );
      return false;
    }

    if (startDate > endDate) {
      showToastMessage(
        undefined,
        "End date should be greater than start date",
        "error",
        false
      );
      return false;
    }
    return true;
  };

  // Handle Clear Search
  const handleClearSearch = async (event) => {
    event.preventDefault();
    // Condition According To Order Type For Start Date And End Date
    setFromDate(defaultFromDate);
    setToDate(deafultToDate);
    fetchDataCustom({
      ...defaultFilterCustom,
      fromDateDefault: defaultFromDate,
      toDateDefault: deafultToDate,
    });
  };

  //API FOR ASSIGN OR UNASSIGN DESIGNER TO ORDER
  const handleSelectedAssginTo = async (
    designer_id,
    order_id,
    is_stock_order,
    component,
    index
  ) => {
    if (order_id) {
      setIsLoading(true);
      // assgin order to designer
      let reqBody = {
        designer_id: designer_id,
        order_ids: [order_id],
      };
      let response = {};
      if (is_stock_order) {
        response = await assignDesignerStockOrder(reqBody);
      } else {
        response = await assignDesignerOrder(reqBody);
      }

      if (response && response.status === 200) {
        if (
          response.data.data &&
          response.data.data.message &&
          response.data.data.status === false
        ) {
          showToastMessage(
            undefined,
            response.data.data.message,
            "error",
            false
          );
          setHeaderCountInformation(true);
          if (component === "today") {
            fetchDataToday(defaultFilterToday);
          } else if (component === "tomorrow") {
            fetchDataTomorrow(defaultFilterTomorrow);
          } else if (component === "custom") {
            fetchDataCustom(defaultFilterCustom);
          }
        } else {
          showToastMessage(undefined, response.data.message, "success", false);
          setHeaderCountInformation(true);
          if (component === "today") {
            // CHANGE IN HEADER COUNT FOR DESIGNER ASSIGN OR UNASSIGN
             fetchDataToday(defaultFilterToday);
            // dataToday[index].designer_id = designer_id;
            // setDataToday([...dataToday]);
            
            // if (designer_id) {
              
            //   //FOR DESIGNER ASSIGN
            //   setDataTodayHeader({
            //     ...dataTodayHeader,
            //     unassigned_orders:
            //       dataTodayHeader.unassigned_orders > 0
            //         ? dataTodayHeader.unassigned_orders - 1
            //         : dataTodayHeader.unassigned_orders,
            //     assigned_orders: dataTodayHeader.assigned_orders + 1,
            //   });
            // } else {
            //   //FOR DESIGNER UNASSIGN
            //   setDataTodayHeader({
            //     ...dataTodayHeader,
            //     unassigned_orders: dataTodayHeader.unassigned_orders + 1,
            //     assigned_orders:
            //       dataTodayHeader.assigned_orders > 0
            //         ? dataTodayHeader.assigned_orders - 1
            //         : dataTodayHeader.assigned_orders,
            //   });
            // }
          } else if (component === "tomorrow") {
            // CHANGE IN HEADER COUNT FOR DESIGNER ASSIGN OR UNASSIGN
             fetchDataTomorrow(defaultFilterTomorrow);
            // dataTomorrow[index].designer_id = designer_id;
            // setDataTomorrow([...dataTomorrow]);
            // if (designer_id) {
              

            //   //FOR DESIGNER ASSIGN
            //   setDataTomorrowHeader({
            //     ...dataTomorrowHeader,
            //     unassigned_orders:
            //       dataTomorrowHeader.unassigned_orders > 0
            //         ? dataTomorrowHeader.unassigned_orders - 1
            //         : dataTomorrowHeader.unassigned_orders,
            //     assigned_orders: dataTomorrowHeader.assigned_orders + 1,
            //   });
            // } else {
            //   //FOR DESIGNER UNASSIGN
            //   setDataTomorrowHeader({
            //     ...dataTomorrowHeader,
            //     unassigned_orders: dataTomorrowHeader.unassigned_orders + 1,
            //     assigned_orders:
            //       dataTomorrowHeader.assigned_orders > 0
            //         ? dataTomorrowHeader.assigned_orders - 1
            //         : dataTomorrowHeader.assigned_orders,
            //   });
            // }
          } else if (component === "custom") {
            // CHANGE IN HEADER COUNT FOR DESIGNER ASSIGN OR UNASSIGN
             fetchDataCustom(defaultFilterCustom);
            // dataCustom[index].designer_id = designer_id;
            // setDataCustom([...dataCustom]);
            // if (designer_id) {
            //   //FOR DESIGNER ASSIGN
            //   setDataCustomHeader({
            //     ...dataCustomHeader,
            //     unassigned_orders:
            //       dataCustomHeader.unassigned_orders > 0
            //         ? dataCustomHeader.unassigned_orders - 1
            //         : dataCustomHeader.unassigned_orders,
            //     assigned_orders: dataCustomHeader.assigned_orders + 1,
            //   });
            // } else {
            //   //FOR DESIGNER UNASSIGN
            //   setDataCustomHeader({
            //     ...dataCustomHeader,
            //     unassigned_orders: dataCustomHeader.unassigned_orders + 1,
            //     assigned_orders:
            //       dataCustomHeader.assigned_orders > 0
            //         ? dataCustomHeader.assigned_orders - 1
            //         : dataCustomHeader.assigned_orders,
            //   });
            // }
          }
        }
      } else {
        showToastMessage(undefined, response.data?.message, "error", false);
      }
      setIsLoading(false);
    }
  };

  // FIRST CALL DESIGNER LIST API & TODAYS ORDER
  useEffect(() => {
    let printAccess = getAccessByModuleName(modules, MODULES.PRINT_ALL_MARK_AS_COMPLETED);
    if (printAccess) setPrintAllAccess(printAccess.permissions.read_access);
    handleGetDesigners();
    fetchDataToday(defaultFilterToday);
  }, []);

  // SECOND CALL FOR TOMORROW ORDER
  useEffect(() => {
    //PASS DEPENDENCY FLAG FROM TODAY API CALL SO THIS CALL AFTER TODAY API CALLED
    if (todayDataFlag) {
      handleGetProducts();
      fetchDataTomorrow(defaultFilterTomorrow);
    }
  }, [todayDataFlag]);

  // THIRD CALL FOR TOMORROW ORDER
  useEffect(() => {
    //PASS DEPENDENCY FLAG FROM TOMORROW API CALL SO THIS CALL AFTER TOMORROW API CALLED
    if (tomorrowDataFlag) {
      fetchDataCustom(defaultFilterCustom);
    }
  }, [tomorrowDataFlag]);

  // FUNCTION FOR GET DESIGNER LISTING
  const handleGetDesigners = async () => {
    let reqBody = { id: 2 };
    // const response = await usersService.getDesigners(reqBody);
    const response = await getDesigners(reqBody);

    if (response && response.status === 200) {
      let tempDesignersListClockIn = [];
      // No need to display default designer in dropdown
      let tempDesignerData = response?.data?.data?.designer.filter(val=>!response?.data?.data?.selectedStoreDefaultDesigner.includes(val.id)).map((designer) => {
        let isClockInClockOutLabel =
          designer.clock_time_masters?.length > 0 ? "Clock In" : "Clock Out";
        designer.last_name =
          designer.last_name + " - " + isClockInClockOutLabel;

        if (designer?.clock_time_masters?.length > 0) {
          tempDesignersListClockIn.push({ title: designer.title });
        }
        return designer;
      });

      tempDesignerData.unshift({
        id: 0,
        first_name: "Unassigned",
        last_name: "",
        title: "Unassigned",
        email: "",
        role_id: 2,
        user_type: "designer",
      });

      setDesignersListClockIn(tempDesignersListClockIn);
      setDesignersList(tempDesignerData);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {

    let access = getAccessByModuleName(modules, MODULES.DESIGNER_STATUS);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  //GET PRODUCT LISTING
  const handleGetProducts = async () => {
    const response = await getProductsNormal();

    if (response && response.status === 200) {
      if (response.data.data && response.data.data.length) {
        setProductsList(response.data.data);
      }
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  // FUNCTION FOR OPEN POPUP OF STOCKORDER ADD
  const handleManageAddStocKClick = async (event) => {
    event.preventDefault();

    setShowAddModal(true);
    setManageModalPageTitle("Add a Stock Arrangement");
  };

  // FUNCTION FOR ADD STOCK ORDER IN SYSTEM
  const handleManageStock = async (event, data) => {
    event.preventDefault();
    setSubmitStatus(true);

    if (
      data.productId &&
      data.variationId &&
      data.stockQuantity &&
      data.stockDeliveryDate
    ) {
      setIsLoading(true);

      let stockUnitPrice = data?.varationDetail["price"] ?? 0;
      let stockTotalPrice = data.stockQuantity * stockUnitPrice;

      let stockOrderData = {
        product_id: data.productId,
        product_type: data.productDetail.type === "ADD_ONS" ? 1 : 0,
        product_name: data.productDetail.title,
        variation_id: data.variationId,
        variation_name: data.varationDetail["title"],
        variation_image: data.varationDetail["image"],
        qty: data.stockQuantity,
        unit_price: stockUnitPrice,
        total_price: stockTotalPrice,
        delivery_date: moment(data.stockDeliveryDate).format("YYYY-MM-DD"),
      };
      // console.log("stockOrderData=>", stockOrderData);
      let response = await addStockOrder(stockOrderData);

      if (response && response.status === 200) {
        showToastMessage(undefined, response.data.message, "success", false);
        setShowAddModal(false);
        setSubmitStatus(false);

        setHeaderCountInformation(true);
        if (
          moment().startOf("day").format("YYYY-MM-DD") ===
          stockOrderData.delivery_date
        ) {
          //GET ONLY TODAY DATA ID STOCK ORDER DATE IS TODAY DATE
          fetchDataToday(defaultFilterToday);
        } else if (
          moment().startOf("day").add(1, "days").format("YYYY-MM-DD") ===
          stockOrderData.delivery_date
        ) {
          //GET ONLY TOMORROW DATA ID STOCK ORDER DATE IS TOMORROW DATE
          fetchDataTomorrow(defaultFilterTomorrow);
        } else {
          //GET ONLY CUSTOM DATE DATA ID STOCK ORDER DATE IS NOT TODAY OR TOMORROW
          fetchDataCustom(defaultFilterCustom);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }
  };

  // FUNCTION FOR PRINT GREETING CARD OR NORMAL ORDER
  const greetingCardRePrint = async (event, orderId, recipientId) => {
    event.preventDefault();
    let PostData = {
      order_id: orderId,
      recipient_id: recipientId,
    };
    setIsLoading(true);
    try {
      const response = await printGreetingMessage(PostData);

      if (response && response.status === 200) {
        // console.log(response.data)
        window.open(response.data.data.message_url, "_blank");
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
      setIsLoading(false);
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
      showToastMessage(undefined, error.response.data.message, "error", false);
    }
  };

  // FUNCTION FOR MARK ORDER AS DESIGN COMPLETED
  const orderMarkAsCompleted = async (
    event,
    orderId,
    designerId,
    typeOfOrder,
    component,
    index
  ) => {
    event.preventDefault();
    let PostData = {
      order_id: orderId,
      designer_id: designerId,
      type: !typeOfOrder ? "normal" : "stock",
    };

    
    setIsLoading(true);
    try {
      const response = await orderDesignerStatusMarkAsCompleted(PostData);
      
      if (response && response.status === 200) {
        showToastMessage(undefined, response.data.message, "success", false);

        if (component === "today") {
               fetchDataToday(defaultFilterToday);
          //REMOVE COMPLETED ORDER BY IT'S INDEX & RE CALCULATE THE HEADER COUNT
          //console.log("dataToday",dataToday)
          // dataToday.splice(index, 1);
          // setDataToday([...dataToday]);
          // console.log("Today.assigned_orders",dataTodayHeader)
         
          // setDataTodayHeader({
          //   ...dataTodayHeader,
          //   unassigned_orders:
          //     dataTodayHeader.unassigned_orders && dataTodayHeader.unassigned_orders > 0 && !designerId
          //       ? dataTodayHeader.unassigned_orders - 1
          //       : dataTodayHeader.unassigned_orders,
          //   assigned_orders:
          //     dataTodayHeader.assigned_orders && dataTodayHeader.assigned_orders > 0 && designerId
          //       ? dataTodayHeader.assigned_orders - 1
          //       : dataTodayHeader.assigned_orders,
          //   total_unmade:
          //     dataTodayHeader.total_unmade && dataTodayHeader.total_unmade > 0
          //       ? dataTodayHeader.total_unmade - 1
          //       : dataTodayHeader.total_unmade,
          // });
          //console.log("Today.assigned_orders else",dataTodayHeader)
        } else if (component === "tomorrow") {
          //REMOVE COMPLETED ORDER BY IT'S INDEX & RE CALCULATE THE HEADER COUNT
               fetchDataTomorrow(defaultFilterTomorrow);
          // dataTomorrow.splice(index, 1);
          // setDataTomorrow([...dataTomorrow]);
         
          // setDataTomorrowHeader({
          //   ...dataTomorrowHeader,
          //   unassigned_orders:
          //     dataTomorrowHeader.unassigned_orders > 0 && !designerId
          //       ? dataTomorrowHeader.unassigned_orders - 1
          //       : dataTomorrowHeader.unassigned_orders,
          //   assigned_orders:
          //     dataTomorrowHeader.assigned_orders > 0 && designerId
          //       ? dataTomorrowHeader.assigned_orders - 1
          //       : dataTomorrowHeader.assigned_orders,
          //   total_unmade:
          //     dataTomorrowHeader.total_unmade > 0
          //       ? dataTomorrowHeader.total_unmade - 1
          //       : dataTomorrowHeader.total_unmade,
          // });
        } else if (component === "custom") {
          //REMOVE COMPLETED ORDER BY IT'S INDEX & RE CALCULATE THE HEADER COUNT
               fetchDataCustom(defaultFilterCustom);
          // dataCustom.splice(index, 1);
          // setDataCustom([...dataCustom]);

          // setDataCustomHeader({
          //   ...dataCustomHeader,
          //   unassigned_orders:
          //     dataCustomHeader.unassigned_orders > 0 && !designerId
          //       ? dataCustomHeader.unassigned_orders - 1
          //       : dataCustomHeader.unassigned_orders,
          //   assigned_orders:
          //     dataCustomHeader.assigned_orders > 0 && designerId
          //       ? dataCustomHeader.assigned_orders - 1
          //       : dataCustomHeader.assigned_orders,
          //   total_unmade:
          //     dataCustomHeader.total_unmade > 0
          //       ? dataCustomHeader.total_unmade - 1
          //       : dataCustomHeader.total_unmade,
          // });
        }
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
      setIsLoading(false);
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
      showToastMessage(undefined, error.response.data.message, "error", false);
    }
  };

  //FUNCTION FOR OPEN DESIGNER LIST POPUP
  const openDesignerPopup = async (event) => {
    event.preventDefault();
    setDesignersListClockIn([]);
    handleGetDesigners();
    setShowAddModalDesigner(true);
    setManageModalPageTitle("Clocked In Designer List");
  };

  //FUNCTION FOR BULK ORDER UPDATE
  const bulkOrderUpdate = async (component) => {
    let finalData;
    setIsLoading(true);

    //GET ORDER DATA BY ORDER ID , CHECKBOX WILL GIVE US ORDER ID
    if (component === "today") {
      finalData = dataToday.filter((order) =>
        selectedCheckBoxValueToday.includes(order.id)
      );
    } else if (component === "tomorrow") {
      finalData = dataTomorrow.filter((order) =>
        selectedCheckBoxValueTomorrow.includes(order.id)
      );
    } else if (component === "custom") {
      finalData = dataCustom.filter((order) =>
        selectedCheckBoxValueCustom.includes(order.id)
      );
    }

    if (finalData && finalData?.length > 0) {
      let postData = finalData.map((data) => {
        return {
          order_number: data.order_number,
          order_id: data.id,
          designer_id: data.designer_id,
          type: data.is_stock_order ? "stock" : "normal",
        };
      });
      // console.log("postData=>", postData);
      try {
        const response = await orderDesignerStatusBulkMarkAsCompleted(postData);

        if (response && response.status === 200) {
          if (response?.data?.data) {
            showToastMessage(
              undefined,
              response?.data?.message,
              "error",
              false
            );
          } else {
            showToastMessage(
              undefined,
              response.data.message,
              "success",
              false
            );
          }

          if (component === "today") {
            fetchDataToday(defaultFilterToday);
          } else if (component === "tomorrow") {
            fetchDataTomorrow(defaultFilterTomorrow);
          } else if (component === "custom") {
            fetchDataCustom(defaultFilterCustom);
          }
        } else {
          showToastMessage(undefined, response.data.message, "error", false);
        }
      } catch (error) {
        // console.log(error);
        setIsLoading(false);
        showToastMessage(
          undefined,
          error.response.data.message,
          "error",
          false
        );
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      showToastMessage(
        undefined,
        "Please select at least one order",
        "error",
        false
      );
    }
  };

  //FUNCTION FOR BULK PRINT ORDER
  const bulkPrintOrder = async (component) => {
    let finalData;
    setIsLoading(true);
    if (component === "today") {
      finalData = dataToday
        .filter((order) => selectedCheckBoxValueToday.includes(order.id))
        .filter((data) => !data.is_stock_order)
        .map((data) => data.id);
      // finalData = selectedCheckBoxValueToday;
    } else if (component === "tomorrow") {
      finalData = dataTomorrow
        .filter((order) => selectedCheckBoxValueTomorrow.includes(order.id))
        .filter((data) => !data.is_stock_order)
        .map((data) => data.id);
      // finalData = selectedCheckBoxValueTomorrow;
    } else if (component === "custom") {
      finalData = dataCustom
        .filter((order) => selectedCheckBoxValueCustom.includes(order.id))
        .filter((data) => !data.is_stock_order)
        .map((data) => data.id);
      // finalData = selectedCheckBoxValueCustom;
    }

    if (finalData && finalData?.length > 0) {
      let postData = { order_id: finalData };

      try {
        const response = await orderDesignerBulkPrintCard(postData);

        if (response && response.status === 200) {
          // console.log(response.data)
          window.open(response.data.data.message_url, "_blank");
        } else {
          showToastMessage(undefined, response.data.message, "error", false);
        }
      } catch (error) {
        // console.log(error);
        setIsLoading(false);
        showToastMessage(
          undefined,
          error.response.data.message,
          "error",
          false
        );
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      showToastMessage(
        undefined,
        "Please select at least one order ",
        "error",
        false
      );
    }
  };

  return (
    <React.Fragment>
      <Header title="Designer Status" />

      <div id="MainContent" className="main-content">
        <div className="container-fluid">
          <div className="d-md-flex align-items-md-center justify-content-md-between my-4">
            <h3 className="bold my-0">Today</h3>
            <button
              onClick={(e) => handleManageAddStocKClick(e)}
              className="btn pink-btn bold float-right mt-0 mb-0"
            >
              Add Stock Arrangement
            </button>
          </div>
          {/* HEADER SUMMARY OF TODAY ORDER */}
          <DesignerStatusSummary
            totalUnmade={dataTodayHeader.total_unmade}
            unassignedOrders={dataTodayHeader.unassigned_orders}
            assignedOrders={dataTodayHeader.assigned_orders}
            designersClockedIn={designersClockedIn}
            unprocessedOrders={dataTodayHeader.total_unprocessed}
            isToday={true}
            openDesignerPopup={openDesignerPopup}
          />
          {
            printAllAccess &&
            <CommonButtonComponent
              component={"today"}
              completedAllOrder={bulkOrderUpdate}
              printAllOrder={bulkPrintOrder}
            />
          }


          <DesignerStatusToday
            designersList={designersList}
            dataToday={dataToday}
            fetchDataToday={(filterData) =>
              fetchDataDummyFunction("today", filterData)
            }
            loadingToday={loadingToday}
            pageCountToday={1}
            defaultFilterToday={defaultFilterToday}
            handleSelectedAssginTo={handleSelectedAssginTo}
            greetingCardRePrintData={greetingCardRePrint}
            orderMarkAsComplete={orderMarkAsCompleted}
            selectedCheckBoxValue={setSelectedCheckBoxValueToday}
            printAllAccess={printAllAccess}
          />

          {/* HEADER SUMMARY OF TOMORROW ORDER */}
          <h3 className="bold mt-5 mb-4">Tomorrow</h3>
          <DesignerStatusSummary
            totalUnmade={dataTomorrowHeader.total_unmade}
            unassignedOrders={dataTomorrowHeader.unassigned_orders}
            assignedOrders={dataTomorrowHeader.assigned_orders}
            unprocessedOrders={dataTomorrowHeader.total_unprocessed}
            isToday={false}
          />
          {
            printAllAccess &&
            <CommonButtonComponent
              component={"tomorrow"}
              completedAllOrder={bulkOrderUpdate}
              printAllOrder={bulkPrintOrder}
            />
          }

          <DesignerStatusTomorrow
            designersList={designersList}
            dataTomorrow={dataTomorrow}
            fetchDataTomorrow={(filterData) =>
              fetchDataDummyFunction("tomorrow", filterData)
            }
            loadingTomorrow={loadingTomorrow}
            pageCountTomorrow={1}
            defaultFilterTomorrow={defaultFilterTomorrow}
            handleSelectedAssginTo={handleSelectedAssginTo}
            greetingCardRePrintData={greetingCardRePrint}
            orderMarkAsComplete={orderMarkAsCompleted}
            selectedCheckBoxValue={setSelectedCheckBoxValueTomorrow}
            printAllAccess={printAllAccess}
          />

          {/* Custom Date Range */}
          <h3 className="bold mt-5 mb-4">Custom</h3>
          <DesignerStatusFilter
            fromDate={fromDate}
            handleFromDate={handleFromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            handleToDate={handleToDate}
            fetchData={fetchDataCustom}
            handleClearSearch={handleClearSearch}
            defaultFilter={defaultFilterCustom}
          />

          <DesignerStatusSummary
            totalUnmade={dataCustomHeader.total_unmade}
            unassignedOrders={dataCustomHeader.unassigned_orders}
            assignedOrders={dataCustomHeader.assigned_orders}
            unprocessedOrders={dataCustomHeader.total_unprocessed}
            isToday={false}
          />
          {
            printAllAccess &&
            <CommonButtonComponent
              component={"custom"}
              completedAllOrder={bulkOrderUpdate}
              printAllOrder={bulkPrintOrder}
            />
          }
          <DesignerStatusCustom
            designersList={designersList}
            dataCustom={dataCustom}
            fetchDataCustom={(filterData) =>
              fetchDataDummyFunction("custom", filterData)
            }
            loadingCustom={loadingCustom}
            pageCountCustom={1}
            defaultFilterCustom={defaultFilterCustom}
            handleSelectedAssginTo={handleSelectedAssginTo}
            greetingCardRePrintData={greetingCardRePrint}
            orderMarkAsComplete={orderMarkAsCompleted}
            selectedCheckBoxValue={setSelectedCheckBoxValueCustom}
            printAllAccess={printAllAccess}
          />
        </div>
      </div>
      {productsList && productsList.length > 0 && (
        // STOCK ORDER ADD MODEL IF PRODUCT DATA AVILABLE
        <ManageStockOrderModal
          showModal={showAddModal}
          pageTitle={manageModalPageTitle}
          productsList={productsList}
          submitStatus={submitStatus}
          handleCancelClick={(e) => {
            setShowAddModal(false);
          }}
          handleSubmitClick={handleManageStock}
        />
      )}

      <DesignerList
        designersList={designersListClockIn}
        showModal={showAddModalDesigner}
        pageTitle={manageModalPageTitle}
        submitStatus={submitStatus}
        handleCancelClick={(e) => {
          setShowAddModalDesigner(false);
        }}
      />
    </React.Fragment>
  );
};

export default DesignerStatus;
