import React from "react";
import styles from "./index.module.scss";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { DateTime } from "luxon";
import moment from "moment";

function InternalNoteAccordion(props) {
  const [expanded, setExpanded] = React.useState(true);

  return (
    <div className={`${styles.InternalNotesAccordianStyle}`}>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        className={`${styles.MuiAccordion}`}
      >
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={`${styles.summary} Custom-Accordian`}
        >
          <div
            className={`${styles.iconArrow} ${
              props.panel === expanded && styles.iconRotation
            }`}
          >
            <KeyboardArrowUpIcon style={{ color: "#ffffff" }} />
          </div>
          <p className={`${styles.author}`}>{props.data.author}</p>
          <p className={`${styles.time}`}>
            {props.data.time && props.data.time !== null
              ? DateTime.fromISO(moment(props.data.time).toISOString())
                  .setZone("America/Los_Angeles")
                  .toFormat("ccc LLL dd, yyyy, hh:mm a")
              : ""}
          </p>
        </AccordionSummary>
        <AccordionDetails className={`${styles.desc}`}>
          {props.data.ans}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default InternalNoteAccordion;
