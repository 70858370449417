import { debounce, TextField } from "@material-ui/core";
import { getCustomerRecipients } from "api/customer";
import { getCheckZipcodeAvability } from "api/order";
import Select from "Components/FormControl/Select/NewSelect";
import { OrderConfigContext } from "Context/OrderConfigContext";
import { useFormikContext } from "formik";
import { formatUsPhone } from "Hooks/commanUtility";
import { getLabel } from "Hooks/getLabel";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Dropdown from "./Dropdown";
import DropdownLoqate from "./DropdownLoqate";
import { useLoqate } from "../hooks/useLoqate";
import _ from "lodash";
import PhoneInput from "react-phone-input-2";
import { PAYMENT_METHOD_DETAIL, DEFAULT_COUNTRY_CODE_NAME, PAYMENT_METHODS } from "Helpers/Constants";
import PhoneNumber from "Components/FormControl/PhoneNumber/PhoneNumber";
import { useOrderAttributeProvider } from "../context/order";
import { AppContext } from "Context/AppContext";

const Recipent = ({ index, ...props }) => {
  const formik = useFormikContext();
  const config = useContext(OrderConfigContext);
  const { orderDetail,module } = useOrderAttributeProvider();
  const { showToastMessage } = useContext(AppContext);
  const { fetchAddressList, items, onItemClick, show, setItems } = useLoqate({
    onResult: (item) => {
      let BuildingNumber = item.BuildingNumber ? item.BuildingNumber + " " : "";
      let BuildingName = item.BuildingName ? item.BuildingName + " " : "";
      let Street = item.Street ? item.Street + " " : "";
      let apt = item.SubBuilding ?? "";
      let delivery_information = { ...formik.values.delivery_information };
      delivery_information.address_line_1 =
        BuildingNumber + BuildingName + Street;
      delivery_information.address_line_2 = apt;
      delivery_information.city = item.City ?? "";
      delivery_information.attn = item.Line2;
      delivery_information.state = item.Province ?? "";
      delivery_information.zipcode =
        (item.PostalCode.includes("-")
          ? item.PostalCode.split("-")[0]
          : item.PostalCode) ?? "";
      formik.setValues((v) => {
        return {
          ...v,
          delivery_information,
        };
      });
    },
  });

  const [recipientList, setRecipientList] = useState([]);

  const [showDropDown, setShowDropDown] = useState(false);

  let touched = formik?.touched?.recipients
    ? formik?.touched.recipients[index] || {}
    : {};
  let errors = formik?.errors?.recipients
    ? formik?.errors?.recipients[index] || {}
    : {};

  let labels = useMemo(() => {
    return getLabel(formik.values.delivery_information.address_type);
  }, [formik.values.delivery_information.address_type_id]);

  //PAYABLI
  /**
   * @param {*} orderDetails 
   * @param {*} newStoreId 
   * @returns {boolean}
   * @description step1 : get prevPaymentMethod and newPaymentMethod
   * @description step2 : if prevPaymentMethod and newPaymentMethod is braintree store then return false
   * @description step3 : if prevPaymentMethod is braintree store and newPaymentMethod is payabli store then return true
   * @description step4 : if prevPaymentMethod is payabli store and newPaymentMethod is braintree store then return true
   * @description step5 : if prevPaymentMethod and newPaymentMethod is payabli store then check both store_id is same or not
   * @description step6 : if both store_id is same then return false
   * @description step7 : if both store_id is not same then return true
   */
    const validateEditOrderForStoreChange = (orderDetails, newStoreId=0) => {
      const prevStoreId = orderDetails?.store_id ?? 0;
      const prevPaymentMethod = PAYMENT_METHOD_DETAIL[prevStoreId];
      const newPaymentMethod = PAYMENT_METHOD_DETAIL[newStoreId];

      if(prevPaymentMethod === PAYMENT_METHODS.BRAINTREE && 
          newPaymentMethod === PAYMENT_METHODS.BRAINTREE){
        return false;
      }else if(prevPaymentMethod === PAYMENT_METHODS.BRAINTREE && 
          newPaymentMethod === PAYMENT_METHODS.PAYABLI){
        return true;
      }else if(prevPaymentMethod === PAYMENT_METHODS.PAYABLI && 
          newPaymentMethod === PAYMENT_METHODS.BRAINTREE){
        return true;
      }else{
        if(prevPaymentMethod === PAYMENT_METHODS.PAYABLI && 
          newPaymentMethod === PAYMENT_METHODS.PAYABLI){
          return prevStoreId === newStoreId ? false : true;
        }
      }
      return false;
    }

  const handleLocalCheck = React.useCallback(() => {
    if (formik.values.delivery_information.zipcode.length === 5 && 
        formik.values.delivery_information.order_type !== 1) {
      //PAYABLI
      let data = formik.values.delivery_information.zipcode;
      getCheckZipcodeAvability(data).then((c) => {
        let local = false;
        let payment_method = formik.values.billing_info.payment_method;
        let di = { ...formik.values.delivery_information };
        let billing_info = { ...formik.values.billing_info };
        if(c.data){
          local = c.data.is_locally_available ? 1 : 0;
          payment_method =  PAYMENT_METHOD_DETAIL[c.data?.store_id ?? 0]
          billing_info.zip_code_store_id = c?.data?.store_id;
          billing_info.payment_method = payment_method;
          di.is_local = local;
          //PAYABLI
          const isValidateEditOrderForStoreChange = module === "edit-order" && 
              validateEditOrderForStoreChange(orderDetail, c.data?.store_id);
          if(isValidateEditOrderForStoreChange){
            showToastMessage(
              undefined,
              "This service is not available in the entered zip code area. Please enter a valid zip code to proceed.", 
              "error", 
              false
            );
            di.zipcode = "";
          }
          formik.setValues((v) => {
            return {
              ...v,
              delivery_information: di,
              billing_info,
              //PAYABLI
              payment_type: module === "edit-order" || module === "replace-order"? v.payment_type : "",
              payabli_paypoint: payment_method === PAYMENT_METHODS.PAYABLI ? c?.data?.payabli_paypoint : null
            };
          });
        }else{
          di.is_local = local;
          formik.setValues((v) => {
            return {
              ...v,
              delivery_information: di,
            };
          });
        }
      });
    }
  }, [formik.values.delivery_information.zipcode, formik.values.delivery_information.delivery_type]);

  useEffect(() => {
    handleLocalCheck();
  }, [handleLocalCheck]);

  const checkDelivery = React.useCallback(() => {
    if (!formik.values.delivery_information.is_local) {
      let products = formik.values.recipients[index].products
        ?.filter((c) => c?.is_local_only)
        ?.map((z) => z.title);
      let addons = formik.values.recipients[index].addons
        ?.filter((c) => c?.is_local_only)
        ?.map((z) => z.title);
      if (products?.length !== 0 || addons?.length !== 0) {
        formik.setValues((v) => {
          return {
            ...v,
            delivery_information: {
              ...v.delivery_information,
              not_deliverable: [...products, ...addons],
            },
          };
        });
      } else {
        formik.setValues((v) => {
          return {
            ...v,
            delivery_information: {
              ...v.delivery_information,
              not_deliverable: [],
            },
          };
        });
      }
    } else {
      formik.setValues((v) => {
        return {
          ...v,
          delivery_information: {
            ...v.delivery_information,
            not_deliverable: [],
          },
        };
      });
    }
  }, [
    formik.values.delivery_information.is_local,
    formik.values.recipients[index].addons,
    formik.values.recipients[index].products,
  ]);

  useEffect(() => {
    checkDelivery();
  }, [checkDelivery]);
  //Create unique key for each recipient
  useEffect(() => {
    let recipients = [...formik.values.recipients];
    recipients[index].recipient_key = recipients[index].recipient_key || 
     `reci_0_${index}_${new Date().getTime()}`;  // {reci_{order_index}_{recipient_index}}
    formik.setValues((v) => {
      return {
        ...v,
        recipients,
      };
    });
  },[formik.values.delivery_information.zipcode]);

  const fetchRecipientList = React.useCallback(
    debounce(async (value) => {
      if (formik?.values?.customer_info?.customer_id !== 0) {
        const resp = await getCustomerRecipients({
          customer_id: formik.values.customer_info.customer_id,
          globalSearch: value,
        });
        setRecipientList(resp.data.data.rows);
      }
    }, 1000),
    [formik.values.customer_info.customer_id]
  );

  const setRecipient = (recipient) => {
    let recipients = [...formik.values.recipients];
    let delivery_information = { ...formik.values.delivery_information };
    recipients[index].recipient_id = recipient.id;
    recipients[index].first_name = recipient.first_name;
    recipients[index].last_name = recipient.last_name;

    delivery_information.address_line_1 = recipient.address_line_1;
    delivery_information.address_line_2 = recipient.address_line_2;
    delivery_information.city = recipient.city;
    delivery_information.state = recipient.state;
    delivery_information.zipcode = recipient.zipcode;
    delivery_information.phone = recipient.phone;
    delivery_information.alt_phone = recipient.alt_phone;
    delivery_information.address_type_id = recipient.address_type_id;
    delivery_information.address_type = recipient.address_type;
    formik.setValues((v) => {
      return {
        ...v,
        recipients,
        delivery_information,
      };
    });
    // setRecipientList([]);
  };

  return (
    <div className={`row recform ${props.className}`}>
      <div className="col-md-6 mr-5">
        <Select
          error={
            touched?.whats_the_occasion && Boolean(errors?.whats_the_occasion)
          }
          label="What's the occasion"
          value={formik.values.recipients[index].whats_the_occasion_id}
          required={true}
          isSearchable={false}
          //
          onChange={(data) => {
            let recipients = [...formik.values.recipients];
            recipients[index].whats_the_occasion_id = data.id;
            recipients[index].whats_the_occasion = data.name;
            formik.setValues((v) => {
              return {
                ...v,
                recipients,
              };
            });
          }}
          blur={formik.handleBlur}
          touched={formik.touched.whats_the_occasion_id}
          // error={formik.errors.whats_the_occasion_id}
          id={`recipients[${index}].whats_the_occasion_id`}
          options={config?.occasionsList}
        />
      </div>
      <div className="col-md-6 my-2">
        <TextField
          onFocus={() => {
            setShowDropDown(true);
          }}
          error={touched?.first_name && Boolean(errors?.first_name)}
          helperText={touched?.first_name && errors?.first_name}
          onChange={(e) => {
            formik.handleChange(e);
            fetchRecipientList(e.target.value);
          }}
          onBlur={(e) => {
            setTimeout(() => {
              setShowDropDown(false);
              setRecipientList([]);
            }, 100);
            formik.handleBlur(e);
          }}
          className="w-100"
          label="First Name"
          name={`recipients[${index}].first_name`}
          value={formik.values.recipients[index].first_name}
        />
        <Dropdown
          recipientList={recipientList}
          showDropDown={showDropDown}
          onItemClick={(recipient) => setRecipient(recipient)}
        />
      </div>
      <div className="col-md-6 my-2">
        <TextField
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={touched?.last_name && Boolean(errors?.last_name)}
          helperText={touched?.last_name && errors?.first_name}
          className="w-100"
          name={`recipients[${index}].last_name`}
          value={formik.values.recipients[index].last_name}
          label="Last Name"
        />
      </div>
      <div className="col-md-6 my-2">
        <Select
          isSearchable={false}
          label="Address Type"
          value={formik.values.delivery_information.address_type_id}
          required={true}
          disabled={
            formik.values.delivery_information.order_type === 1 || index > 0 || formik.values.is_burq_order === 1
          }
          onChange={(data) => {
            let di = { ...formik.values.delivery_information };
            di.address_type_id = data.id;
            di.address_type = data.name;
            di = {
              ...di,
              delivery_date: null,
              delivery_time_id: null,
              delivery_time: "",
              delivery_date_time: "",
              delivery_time_type: "",
              delivery_type: "",
              actual_delivered_time: "",
              charge: 0,
              upcharge_id: 0,
              upcharge_amount: 0,
            };
            formik.setValues((v) => {
              return {
                ...v,
                delivery_information: di,
              };
            });
          }}
          blur={formik.handleBlur}
          touched={formik?.touched?.delivery_information?.address_type_id}
          error={formik.errors?.delivery_information?.address_type_id}
          id="delivery_information.address_type_id"
          options={config.addressTypesList}

          // disabled={props.is_subscribe_order === false ? false : true}
        />
      </div>
      <div className="col-md-6  my-2">
        <TextField
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="w-100"
          value={formik.values.delivery_information.attn}
          // error={formik.errors.delivery_information.address_line_1}
          name={`delivery_information.attn`}
          disabled={
            formik.values.delivery_information.order_type === 1 || index > 0 || formik.values.is_burq_order === 1
          }
          label={labels.attnLabel}
        />
      </div>
      <div className="col-12 my-2">
        <TextField
          disabled={
            formik.values.delivery_information.order_type === 1 || index > 0 || formik.values.is_burq_order === 1
          }
          onKeyDown={(e) => {
            if (e.key === "Tab") {
              setItems([]);
            }
          }}
          onChange={(e) => {
            formik.handleChange(e);
            fetchAddressList(e.target.value);
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched?.delivery_information?.address_line_1 &&
            Boolean(formik.errors.delivery_information?.address_line_1)
          }
          helperText={
            formik.touched?.delivery_information?.address_line_1 &&
            formik.errors.delivery_information?.address_line_1
          }
          className="w-100"
          value={formik.values.delivery_information.address_line_1}
          // error={formik.errors.delivery_information.address_line_1}
          name={`delivery_information.address_line_1`}
          label="Address Line 1"
        />
        <DropdownLoqate
          addressList={items}
          showDropDown={show}
          onItemClick={onItemClick}
        />
      </div>
      <div className="col-12 my-2">
        <TextField
          disabled={
            formik.values.delivery_information.order_type === 1 || index > 0 || formik.values.is_burq_order === 1
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="w-100"
          label={labels.addressLine2Label}
          value={formik.values.delivery_information.address_line_2}
          // error={formik.errors.delivery_information.address_line_2}
          name={`delivery_information.address_line_2`}
        />
      </div>
      <div className="col-4 my-2">
        <TextField
          disabled={
            formik.values.delivery_information.order_type === 1 || index > 0 || formik.values.is_burq_order === 1
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched?.delivery_information?.city &&
            Boolean(formik.errors.delivery_information?.city)
          }
          helperText={
            formik.touched?.delivery_information?.city &&
            formik.errors.delivery_information?.city
          }
          className="w-100"
          label="City"
          value={formik.values.delivery_information.city}
          // error={formik.errors.delivery_information.city}
          name={`delivery_information.city`}
        />
      </div>
      <div className="col-4 my-2">
        <TextField
          disabled={
            formik.values.delivery_information.order_type === 1 || index > 0 || formik.values.is_burq_order === 1
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched?.delivery_information?.state &&
            Boolean(formik.errors.delivery_information?.state)
          }
          helperText={
            formik.touched?.delivery_information?.state &&
            formik.errors.delivery_information?.state
          }
          className="w-100"
          label="State"
          value={formik.values.delivery_information.state}
          // error={formik.errors.delivery_information.state}
          name={`delivery_information.state`}
        />
      </div>
      <div className="col-4 my-2">
        <TextField
          disabled={
            formik.values.delivery_information.order_type === 1 || index > 0 || formik.values.is_burq_order === 1
          }
          onChange={(e) => {
            let de = { ...formik.values.delivery_information };
            if (e.target.value.length > 5) return;
            de.zipcode = e.target.value;
            de = {
              ...de,
              delivery_date: null,
              delivery_time_id: null,
              delivery_time: "",
              delivery_date_time: "",
              delivery_time_type: "",
              delivery_type: "",
              actual_delivered_time: "",
              charge: 0,
              upcharge_id: 0,
              upcharge_amount: 0,
            };
            formik.setValues((v) => {
              return {
                ...v,
                delivery_information: de,
              };
            });
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched?.delivery_information?.zipcode &&
            Boolean(formik.errors.delivery_information?.zipcode)
          }
          helperText={
            formik.touched?.delivery_information?.zipcode &&
            formik.errors.delivery_information?.zipcode
          }
          className="w-100"
          label="Zipcode"
          value={formik.values.delivery_information.zipcode}
          // error={formik.errors.delivery_information.zipcode}
          name={`delivery_information.zipcode`}
        />
      </div>
      <div className="col-md-6 my-2">
        {/* <TextField
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={
            formik.values.delivery_information.order_type === 1 || index > 0
          }
          error={
            formik.touched?.delivery_information?.phone &&
            Boolean(formik.errors.delivery_information?.phone)
          }
          helperText={
            formik.touched?.delivery_information?.phone &&
            formik.errors.delivery_information?.phone
          }
          className="w-100"
          label="Phone"
          value={formatUsPhone(formik.values.delivery_information.phone)}
          // error={formik.errors.delivery_information.phone}
          name={`delivery_information.phone`}
        /> */}
        <PhoneNumber
          countryCode={DEFAULT_COUNTRY_CODE_NAME}
          initValue={
            formik.values.delivery_information?.phone_country_code +
            formik.values.delivery_information?.phone
          }
          specialLabel={"Phone"}
          fieldName={`delivery_information.phone`}
          onChange={(phone, countryData, event, formatedValue) => {
            let countryCode = "+" + countryData.dialCode;
            let finalMobileNum = formatedValue
              ? formatedValue?.split(countryCode)[1]?.trim()
              : "";
            let valueUpdate = {
              ...formik.values.delivery_information,
              phone: finalMobileNum,
              phone_country_code: countryCode,
            };
            formik.setValues((v) => {
              return {
                ...v,
                delivery_information: valueUpdate,
              };
            });
          }}
          disabled={
            index > 0 || formik.values.is_burq_order === 1
          }
        />
      </div>
      <div className="col-md-6 my-2">
        {/* <TextField
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="w-100"
          label="Alt Phone"
          disabled={
            formik.values.delivery_information.order_type === 1 || index > 0
          }
          value={formatUsPhone(formik.values.delivery_information.alt_phone)}
          // error={formik.errors.delivery_information.alt_phone}
          name={`delivery_information.alt_phone`}
        /> */}
        <PhoneNumber
          countryCode={DEFAULT_COUNTRY_CODE_NAME}
          initValue={
            formik.values.delivery_information?.alt_phone_country_code +
            formik.values.delivery_information?.alt_phone
          }
          specialLabel={"Alt Phone"}
          fieldName={`delivery_information.alt_phone`}
          onChange={(phone, countryData, event, formatedValue) => {
            let countryCode = "+" + countryData.dialCode;
            let finalMobileNum = formatedValue
              ? formatedValue?.split(countryCode)[1]?.trim()
              : "";
            let valueUpdate = {
              ...formik.values.delivery_information,
              alt_phone: finalMobileNum,
              alt_phone_country_code: countryCode,
            };
            formik.setValues((v) => {
              return {
                ...v,
                delivery_information: valueUpdate,
              };
            });
          }}
          disabled={
            index > 0 || formik.values.is_burq_order === 1
          }
        />
      </div>
    </div>
  );
};

export default Recipent;
