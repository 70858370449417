import React from "react";
// import moment from "moment";
import Text from "../../Components/FormControl/Text/Text";
import Date from "./../../Components/FormControl/Date/Date";
import CalendarIcon from "./../../Assets/images/calendar.svg";
// import DeleteIcon from "./../../Assets/images/DeleteBg.svg";
import Check from "./../../Assets/images/Check.svg";
import styles from "./index.module.scss";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import { useDeliveryMatrix } from "./../../Context/DeliveryMatrixCurd/useDeliveryMatrix";

const PrimaryInformation = (props) => {
  const {
    DeliveryMatrixState,
    setDeliveryMatrixName,
    setDeliveryMatrixZipcode,
    setDeliveryMatrixAddressType,
    setDeliveryMatrixDays,
    setDeliveryMatrixStartDate,
    setDeliveryMatrixEndDate,
  } = useDeliveryMatrix();

  // console.log("DeliveryMatrixState=>", DeliveryMatrixState);

  let finalDataZipcode = [];
  if (props.folderList.length > 0) {
    props.folderList?.map((d, i) => {
      let filterData = DeliveryMatrixState.delivery_matrix_zipcode_folders?.filter(
        (data) => {
          if (data.id == d.id) {
            return d;
          }
        }
      );
      if (filterData && filterData.length > 0) {
        finalDataZipcode.push(d);
      }
    });
  }

  let finalDataAddressType = [];
  if (props.deliveryAddress.length > 0) {
    props.deliveryAddress?.map((d, i) => {
      let filterData = DeliveryMatrixState.delivery_matrix_address_types?.filter(
        (data) => {
          if (data.id == d.id) {
            return d;
          }
        }
      );
      if (filterData && filterData.length > 0) {
        finalDataAddressType.push(d);
      }
    });
  }

  let finalDataDays = [];
  if (props.daysList.length > 0) {
    props.daysList?.map((d, i) => {
      let filterData = DeliveryMatrixState.delivery_matrix_days?.filter(
        (data) => {
          if (data.id == d.id) {
            return d;
          }
        }
      );
      if (filterData && filterData.length > 0) {
        finalDataDays.push(d);
      }
    });
  }

  return (
    <div className={`row`}>
      <div className={`col-md-4`}>
        <Text
          type="text"
          label="Matrix Name"
          value={DeliveryMatrixState.name}
          onChange={(data) => setDeliveryMatrixName(data)}
          id="name"
          required={true}
          disabled={false}
        />
      </div>
      <div className={`col-md-4`}>
        <Autocomplete
          multiple
          id="tags-standard"
          options={props.folderList}
          getOptionLabel={(option) => option.title}
          onChange={(e, value) => {
            setDeliveryMatrixZipcode(value);
          }}
          // defaultValue={finalData}
          value={finalDataZipcode}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="ZipCode Folder"
              placeholder="Select ZipCode Folder"
            />
          )}
        />
      </div>
      <div className={`col-md-4`}>
        <Autocomplete
          multiple
          id="tags-standard"
          options={props.deliveryAddress}
          getOptionLabel={(option) => option.title}
          // defaultValue={[]}
          value={finalDataAddressType}
          onChange={(e, value) => {
            setDeliveryMatrixAddressType(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Address Type"
              placeholder="Select Address Type"
            />
          )}
        />
      </div>
      <div className={`col-md-4`}>
        <Autocomplete
          multiple
          id="tags-standard"
          options={props.daysList}
          getOptionLabel={(option) => option.title}
          // defaultValue={[]}
          value={finalDataDays}
          onChange={(e, value) => {
            setDeliveryMatrixDays(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Days"
              placeholder="Select Days"
            />
          )}
        />
      </div>
      <div className={`col-md-2`}>
        <div class={`align-items-center bold ${styles.circle}`}>Or</div>
      </div>
      <div className={`col-md-2`}>
        <Date
          label="Start Date"
          value={DeliveryMatrixState.start_date}
          dateFormat="MM-DD-YYYY"
          type="date"
          fieldIcon={CalendarIcon}
          onChange={(startDate) => setDeliveryMatrixStartDate(startDate)}
          checkPassDate={true}
        />
      </div>
      <div className={`col-md-2`}>
        <Date
          label="End Date"
          value={DeliveryMatrixState.end_date}
          dateFormat="MM-DD-YYYY"
          type="date"
          fieldIcon={CalendarIcon}
          onChange={(toDate) => setDeliveryMatrixEndDate(toDate)}
          checkPassDate={true}
        />
      </div>
      <div className={`col-md-2`}>
        <img
          className={`cursor-pointer mt-4`}
          src={Check}
          alt="Save"
          onClick={(e) => props.handleSubmitButton()}
        />
        {/* <img
          className={`cursor-pointer mt-4 ml-4`}
          src={DeleteIcon}
          alt="Delete"
          onClick={(e) => console.log("Delete")}
        /> */}
      </div>
    </div>
  );
};
export default PrimaryInformation;
