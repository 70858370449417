import React, { useState } from "react";
import AddIcon from "../../Assets/images/add.svg";
import Arrow from "../../Assets/images/arrow.svg";
import { FeaturedProductForm } from "../../Hooks/featuredProductFormFormik";
import Text from "../FormControl/Text/TextInput";
import Products from "../Products/Products";
import styles from "./index.module.scss";

const FeaturedProductCard = (props) => {
  const productData = props.productData ? props.productData : {};
  const featuredProductInitalValues = {
    id: productData.id ? productData.id : 0,
    parent_id: productData.parent_id ? productData.parent_id : 0,
    module: "home-page",
    name: productData.name ? productData.name : "",
    sequence_number: productData.sequence_number
      ? productData.sequence_number
      : 1,
    products: productData.products ? productData.products : [],
  };

  const [showFeaturedProduct, setShowFeaturedProduct] = useState(true);

  const addFeaturedProduct = () => {
    openAddProductDrawer(true);
  };

  const [addProduct, openAddProductDrawer] = useState(false);
  const handleCloseProduct = () => {
    openAddProductDrawer(false);
  };

  const submit = (values) => {};
  const cbSubmit = (values) => {
    let payloadData = {
      values,
      index: props.index,
    };
    props.setFeaturedProductData(payloadData);
  };

  const { formik: featuredProductFormik } = FeaturedProductForm(
    submit,
    featuredProductInitalValues,
    cbSubmit,
    props.checkFeaturedProductData
  );

  return (
    <React.Fragment>
      <form onSubmit={(e) => featuredProductFormik.handleSubmit(e)}>
        <div className={`${styles.cardBody}`}>
          <div className={`card-body ${styles.featuredCard}`}>
            <div className={`row`}>
              <div className={`col-md-4 col-xs-12`}>
                <Text
                  type="text"
                  label="Category Text"
                  value={featuredProductFormik.values.name}
                  required={true}
                  onChange={(data) =>
                    featuredProductFormik.setValues((v) => {
                      return { ...v, name: data };
                    })
                  }
                  onBlur={featuredProductFormik.handleBlur}
                  touched={featuredProductFormik.touched.name}
                  error={featuredProductFormik.errors.name}
                  id="name"
                />
              </div>
              <div className={`col-md-4 col-xs-12`}>
                <Text
                  type="text"
                  label="Sequence Number"
                  value={featuredProductFormik.values.sequence_number}
                  required={true}
                  onChange={(data) => {
                    if (!/^[0-9]*$/.test(data)) {
                      return;
                    }
                    let val = (+data).toString() || "0";
                    featuredProductFormik.setValues((v) => ({
                      ...v,
                      sequence_number: val,
                    }));
                  }}
                  onBlur={featuredProductFormik.handleBlur}
                  touched={featuredProductFormik.touched.sequence_number}
                  error={featuredProductFormik.errors.sequence_number}
                  id="sequence_number"
                />
              </div>
              <div className={`col-md-3 col-xs-12`}>
                <a
                  href="/"
                  className={`link cursor-pointer ml-2 mt-1 ${styles.searchIconWrapper}`}
                  onClick={(e) => {
                    e.preventDefault();
                    addFeaturedProduct();
                  }}
                >
                  <img className={styles.searchIcon} src={AddIcon} alt="Add" />
                </a>
              </div>
              <div className={`col-md-1 ${styles.featuredItem}`}>
                <a
                  href="/"
                  className={`link cursor-pointer ml-2 mt-1`}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowFeaturedProduct(!showFeaturedProduct);
                  }}
                >
                  {showFeaturedProduct ? (
                    <img
                      className={styles.toggalIconDown}
                      src={Arrow}
                      alt="Add"
                    />
                  ) : (
                    <img
                      className={styles.toggalIconUp}
                      src={Arrow}
                      alt="Add"
                    />
                  )}
                </a>
              </div>
            </div>
            <Products
              isVarianNeeded={false}
              hideFeaturedProduct={showFeaturedProduct}
              showSequenceNumber={true}
              addProduct={addProduct}
              openAddProductDrawer={openAddProductDrawer}
              handleCloseProduct={handleCloseProduct}
              productList={
                props.productData.products ? props.productData.products : []
              }
              addProductList={(data) => {
                let payloadData = {
                  values: data,
                  index: props.index,
                };
                props.addProductList(payloadData);
              }}
              removeProductList={(data) => {
                let payloadData = {
                  values: data,
                  index: props.index,
                };
                props.removeProductList(payloadData);
              }}
              setProductSequence={(index, data) => {
                let payloadData = {
                  product_index: index,
                  index: props.index,
                  values: data,
                };
                props.setProductSequence(payloadData);
              }}
            />
          </div>
        </div>
        <button
          className="d-none featured-product-submit-button"
          id={`featured-product-submit-button-${props.index}`}
          type="submit"
        ></button>
      </form>
    </React.Fragment>
  );
};
export default FeaturedProductCard;
