import React from "react";
import Select from "../FormControl/Select/Select";
import styles from "./index.module.scss";
import CheckBox from "Components/FormControl/CheckBox/CheckBox";

const StoreSettingOpenCloseTime = (props) => {

  const handleManageOpenCloseTimeMer = async (id, mer, type) => {
    //console.log("1.0 mer => ", mer, id)
    let storeOpenCloseDataObj = await props.storeOpenCloseInfo.map((rdata) => {
      if (rdata.id === id) {
        rdata[type] = mer === "PM" ? "AM" : "PM";
      }
      return rdata;
    });
    props.setStoreOpenCloseInfo(storeOpenCloseDataObj);
  };

  const handleManageOpenCloseTimeHrs = async (id, hrs, type) => {
    //console.log("1.0 hrs => ", hrs, id)
    let storeOpenCloseDataObj = await props.storeOpenCloseInfo.map((rdata) => {
      if (rdata.id === id) {
        rdata[type] = hrs;
      }
      return rdata;
    });
    props.setStoreOpenCloseInfo(storeOpenCloseDataObj);
  };

  const handleManageOpenCloseTimeMin = async (id, min, type) => {
    let storeOpenCloseDataObj = await props.storeOpenCloseInfo.map((rdata) => {
      if (rdata.id === id) {
        rdata[type] = min;
      }
      return rdata;
    });
    props.setStoreOpenCloseInfo(storeOpenCloseDataObj);
  };

  const handleCheckbox = async (value, id) => {
    let storeOpenCloseDataObj = await props.storeOpenCloseInfo.map((rdata) => {
      if (rdata.id === id) {
        rdata.is_closed = value
      }
      return rdata;
    });
    props.setStoreOpenCloseInfo(storeOpenCloseDataObj);
  };



  return (
    <React.Fragment>
      <h3 className="bold mt-5 ">Store Hours</h3>
      <div className={`row ${styles.storeOpenClose}`}>
        <div className="col-2">
          <h3>Days</h3>
        </div>

        <div className="col-4">
          <h3>Start Time</h3>
        </div>

        <div className="col-4">
          <h3>End Time</h3>
        </div>

        <div className="col-2 d-flex align-items-center justify-content-center">
          <h3>Is Closed</h3>
        </div>
      </div>

      <div className={`row ${styles.storeOpenClose}`}>
        {props.storeOpenCloseInfo.map((r) => (
          <>
            <div className="col-2">
              <h3>{r.key}</h3>
            </div>

            {r?.open_value && (
              <>
                <div className="col-4 d-flex align-items-center">
                  <div>{r.hrs}</div>
                  <div className={styles.selectItme}>
                    <Select // parentClass="text-left" value={value}
                      required={true}
                      value={r.open_hrs}
                      onChange={(data) => handleManageOpenCloseTimeHrs(r.id, data, 'open_hrs')}
                      options={[
                        { id: "01", title: "01" },
                        { id: "02", title: "02" },
                        { id: "03", title: "03" },
                        { id: "04", title: "04" },
                        { id: "05", title: "05" },
                        { id: "06", title: "06" },
                        { id: "07", title: "07" },
                        { id: "08", title: "08" },
                        { id: "09", title: "09" },
                        { id: "10", title: "10" },
                        { id: "11", title: "11" },
                        { id: "12", title: "12" },
                      ]}
                      disabled={r.is_closed}
                    />
                  </div>

                  <div className={styles.selectItme2}>
                    <Select
                      parentClass="text-left"
                      required={true}
                      onChange={(data) => handleManageOpenCloseTimeMin(r.id, data, 'open_min')}
                      value={r.open_min}
                      options={[
                        { id: "00", title: "00" },
                        { id: "30", title: "30" }
                      ]}
                      disabled={r.is_closed}
                    />
                  </div>
                  <div className={`d-block text-left ${styles.changeButton}`}>
                    <button
                      disabled={r.is_closed}
                      onClick={(e) => handleManageOpenCloseTimeMer(r.id, r.open_meridiem, 'open_meridiem')}
                      className="btn pink-btn bold"
                    >
                      {r.open_meridiem}
                    </button>
                  </div>
                </div>
              </>
            )}

            {r?.close_value && (
              <>
                <div className="col-4 d-flex align-items-center">
                  <div className={`${styles.selectItme}`}>
                    <Select
                      required={true}
                      value={r.close_hrs}
                      onChange={(data) => handleManageOpenCloseTimeHrs(r.id, data, 'close_hrs')}
                      options={[
                        { id: "01", title: "01" },
                        { id: "02", title: "02" },
                        { id: "03", title: "03" },
                        { id: "04", title: "04" },
                        { id: "05", title: "05" },
                        { id: "06", title: "06" },
                        { id: "07", title: "07" },
                        { id: "08", title: "08" },
                        { id: "09", title: "09" },
                        { id: "10", title: "10" },
                        { id: "11", title: "11" },
                        { id: "12", title: "12" },
                      ]}
                      disabled={r.is_closed}
                    />
                  </div>
                  <div className={styles.selectItme2}>
                    <Select
                      parentClass="text-left"
                      required={true}
                      onChange={(data) => handleManageOpenCloseTimeMin(r.id, data, 'close_min')}
                      value={r.close_min}
                      options={[
                        { id: "00", title: "00" },
                        { id: "30", title: "30" }
                      ]}
                      disabled={r.is_closed}
                    />
                  </div>
                  <div className={`d-block text-left ${styles.changeButton}`}>
                    <button
                      disabled={r.is_closed}
                      onClick={(e) => handleManageOpenCloseTimeMer(r.id, r.close_meridiem, 'close_meridiem')}
                      className="btn pink-btn bold"
                    >
                      {r.close_meridiem}
                    </button>
                  </div>
                </div>
              </>
            )}
            {
              <div className="col-2 d-flex align-items-center justify-content-center">
                <div className={styles.isCloseCheckbox}>
                  <CheckBox
                    value={r.is_closed}
                    onChange={(e) => handleCheckbox(e, r.id)}
                  />
                </div>
              </div>
            }
          </>
        ))}
      </div>
    </React.Fragment>
  );
};
export default StoreSettingOpenCloseTime;
