import { getBurqOrderList, updateBurqOrder } from "api/order";
import { isBeforetwelveandsix } from "Helpers/date";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import ViewIcon from "../../../Assets/images/error-outline.svg";
import StatusConfirmation from "../../../Components/Modal/StatusConfirmation/StatusConfirmation";
import moment from "moment";
// Components
import ReactTable from "../../../Components/Table/Table";
import AcceptIcon from "./../../../Assets/images/accept.svg";
import { AppContext } from "./../../../Context/AppContext";
import {
  MODULES,
  BURQ_MANUAL_ORDER_STATUS_LIST,
} from "./../../../Helpers/Constants";
import OrderFilter from "../../../Components/OrderFilter/OrderFilter";
// Context
import { getAccessByModuleName } from "./../../../Routes/Routes";
//scss
import styles from "./index.module.scss";
import LoadingScreen from "Components/Loading/LoadingScreen";
import { setAllStore } from "Helpers/setAllStore";

const BurqManualOrder = (props) => {
  const { showToastMessage, modules } = useContext(AppContext);

  // const [manualOrderStatusList, setManualOrderStatusList] = useState([
  //   { id: "", title: "All" },
  //   { id: "in_design", title: "In Design" },
  //   { id: "design_completed", title: "Design Completed" },
  // ]);
  const manualOrderStatusList = BURQ_MANUAL_ORDER_STATUS_LIST;
  // Date Range Filter
  const [type, setType] = useState("delivery_date");
  const [fromDate, setFromDate] = useState(
    moment().startOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [toDate, setToDate] = useState(
    moment().endOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [selectedPeriod, setSelectedPeriod] = React.useState("weekly");
  const [selectedPeriodLabel, setSelectedPeriodLabel] = React.useState(
    "By Week"
  );
  // Add / Edit
  const [updateOrderId, setUpdateOrderId] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [access, setAccess] = useState({});

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [firstRender, setFirstRender] = useState(true);

  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
    // Date Range Filter
    fromDateDefault: "",
    toDateDefault: "",
    dateType: type,
  });

  const [checkAllStore,setCheckAllStore] = useState(true);

  // Set Async Data
  const setAsyncData = (data) => {
    let asyncDataValues = JSON.stringify(data);
    localStorage.setItem("asyncDataValuesManualOrder", asyncDataValues);
  };
  // Get And Set Data Into State
  useEffect(() => {
    const filterData = JSON.parse(
      localStorage.getItem("asyncDataValuesManualOrder")
    );

    if (filterData) {
      const {
        currentPage,
        itemsPerPage,
        globalSearch,
        filters,
        sortBy,
        dateType,
        start_date,
        end_date,
        selectedPeriodLabel,
        selectedPeriod,
      } = filterData;
      setDefaultFilter({
        filters: filters,
        pageIndex: currentPage,
        globalFilter: globalSearch,
        pageSize: itemsPerPage,
        sortBy: sortBy,
        fromDateDefault: moment(start_date).format("ddd MMM DD, YYYY"),
        toDateDefault: moment(end_date).format("ddd MMM DD, YYYY"),
      });
      setType(dateType);
      setFromDate(moment(start_date).format("ddd MMM DD, YYYY"));
      setToDate(moment(end_date).format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel(selectedPeriodLabel);
      setSelectedPeriod(selectedPeriod);
    }
    setFirstRender(false);
  }, []);

  // Date Range Handle Start
  const handleSelectedPeriod = async (id) => {
    if (id === "weekly") {
      setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Week");
    } else if (id === "monthly") {
      setFromDate(moment().startOf("month").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("month").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Month");
    } else if (id === "yearly") {
      setFromDate(moment().startOf("year").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("year").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Year");
    }
  };
  const handleFromDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setToDate(moment(data).add(7, "days").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "monthly") {
      setToDate(moment(data).add(1, "months").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "yearly") {
      setToDate(moment(data).add(1, "years").utc().format("ddd MMM DD, YYYY"));
    }
  };
  const handleToDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setFromDate(
        moment(data).subtract(7, "days").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "monthly") {
      setFromDate(
        moment(data).subtract(1, "months").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "yearly") {
      setFromDate(
        moment(data).subtract(1, "years").utc().format("ddd MMM DD, YYYY")
      );
    }
  };
  const handleClearSearch = async (event) => {
    event.preventDefault();
    setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
    setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
    setSelectedPeriod("weekly");
    setType("delivery_date");
    fetchData({
      ...defaultFilter,
      fromDateDefault: "",
      toDateDefault: "",
      dateType: "delivery_date",
    });
  };
  const fetchDataDateRange = async () => {
    fetchData({
      ...defaultFilter,
      fromDateDefault: moment(fromDate).format("YYYY-MM-DD"),
      toDateDefault: moment(toDate).format("YYYY-MM-DD"),
      dateType: type,
    });
  };
  // Date Range Handle End
  // const ordersService = useMemo(() => new OrdersApi(), []);

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
    dateType,
  }) => {

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);

    setDefaultFilter({
      pageSize,
      pageIndex,
      filters,
      globalFilter,
      sortBy,
      fromDateDefault,
      toDateDefault,
      dateType,
    });
    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      list_type: "manual",
      start_date: fromDateDefault
        ? fromDateDefault
        : moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault
        ? toDateDefault
        : moment(toDate).format("YYYY-MM-DD"),
      dateType: type,
    };

    let burqAccess = getAccessByModuleName(modules, MODULES.ONDEMAND);
    if (burqAccess && burqAccess.permissions.read_access) {
      const response = await getBurqOrderList(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setPageCount(Math.ceil(response.data.data.count.length / pageSize));
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    }

    setAsyncData({
      ...postData,
      selectedPeriod: selectedPeriod,
      selectedPeriodLabel: selectedPeriodLabel,
    });

    setLoading(false);
    
    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  /* Check Module Access */
  useEffect(getModuleAccess, [modules]);

  function getModuleAccess() {
    let access = getAccessByModuleName(modules, MODULES.ONDEMAND);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      showToastMessage(
        undefined,
        "You are not authorized to access.",
        "error",
        false
      );
      props.history.push(`/users`);
    }
  }

  const handleStatusOrderClick = async (event, id) => {
    event.preventDefault();

    setUpdateOrderId(id);
    setShowStatusModal(true);
  };

  const handleOrderStatus = async (event) => {
    event.preventDefault();

    // update order status
    let reqBody = {
      order_id: updateOrderId,
      order_status: "delivered",
    };
    let response = await updateBurqOrder(reqBody);

    if (response && response.status === 200) {
      setUpdateOrderId(null);
      setShowStatusModal(false);
      showToastMessage(undefined, response.data.message, "success", false);
      fetchData(defaultFilter);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  let accountAccess = getAccessByModuleName(modules, MODULES.ACCOUNT_LOOKUP);
  let viewOrderAccess = getAccessByModuleName(modules, MODULES.VIEW_ORDER);

  const columns = React.useMemo(
    () => [
      {
        Header: "Order No",
        accessor: "order_number",
        isVisible: true,
        placeholder: "Order No",
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        isVisible: true,
        placeholder: "Customer Name",
        Cell: ({ value, row, column }) => {
          return row.original.order_transaction.customer_id === 0 ||
            row.original.order_transaction.customer_id === undefined ? (
            <span>{`${row.original.order_transaction.customer_name}`}</span>
          ) : accountAccess && accountAccess.permissions.write_access ? (
            <Link
              to={`/admin/customers/${row.original.order_transaction.customer_id}`}
            >
              <span className={`cursor-pointer`}>
                {`${row.original.order_transaction.customer_name}`}
              </span>
            </Link>
          ) : (
            <span>{`${row.original.order_transaction.customer_name}`}</span>
          );
        },
      },
      {
        Header: "Recipient Name",
        accessor: "recipient_name",
        isVisible: true,
        placeholder: "Recipient Name",
        Cell: ({ value, row, column }) => {
          return `${row.original.order_recipients[0].recipient_name}`;
        },
      },
      {
        Header: "Order Placed Date",
        accessor: "createdAt",
        isVisible: true,
        placeholder: "Order Placed Date",
        Cell: ({ value, row, column }) => {
          return `${
            row.original.createdAt && row.original.createdAt !== null
              ? DateTime.fromISO(row.original.createdAt)
                  .setZone("America/Los_Angeles")
                  .toFormat("ccc LLL dd, yyyy, hh:mm a")
              : ""
          }`;
        },
        disableFilters: true,
      },
      {
        Header: "Delivery Date",
        accessor: "delivery_date_time",
        isVisible: true,
        placeholder: "Delivery Date",
        Cell: ({ value, row, column }) => {
          return `${
            row.original.delivery_date_time &&
            row.original.delivery_date_time !== null
              ? row.original.delivery_time_type === "Anytime"
                ? DateTime.fromISO(row.original.delivery_date_time)
                    .setZone("America/Los_Angeles")
                    .toFormat("ccc LLL dd, yyyy") +
                  " " +
                  row.original.delivery_time_type
                : DateTime.fromISO(row.original.delivery_date_time)
                    .setZone("America/Los_Angeles")
                    .toFormat("ccc LLL dd, yyyy, hh:mm a")
              : ""
          }`;
        },
        disableFilters: true,
      },
      {
        Header: "Building Type",
        accessor: "building_type",
        isVisible: true,
        placeholder: "Building Type",
        colWidth: 140,
        isSelectFilter: true,
        selectFilterOption: [
          { id: "", title: "All" },
          { id: "house", title: "House" },
          { id: "apartment", title: "Apartment" },
          { id: "business", title: "Business" },
          { id: "funeral_home", title: "Funeral home" },
          { id: "church", title: "Church" },
          { id: "hospital", title: "Hospital" },
          { id: "restaurant", title: "Restaurant" },
          { id: "hotel", title: "Hotel" },
          { id: "university", title: "University" },
          { id: "other_school ", title: "Other school " },
        ],
        Cell: ({ value, row, column }) => {
          return `${row.original.order_recipients[0].building_type}`;
        },
      },
      {
        Header: "Price",
        accessor: "total",
        isVisible: true,
        placeholder: "Price",
        Cell: ({ value, row, column }) => {
          return `$${row.original.total.toFixed(2)}`;
        },
      },
      {
        Header: "Products",
        accessor: "image",
        isVisible: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          let time = isBeforetwelveandsix(row.original.delivery_date_time);
          let threeMark = (
            <span
              className={` ${styles.ProducatBadge} ${styles.ProducatBadgeRed}`}
            >
              !!!
            </span>
          );

          let oneMark = (
            <span
              className={` ${styles.ProducatBadge} ${styles.ProducatBadgeGreen}`}
            >
              !
            </span>
          );
          let img = row.original.order_products.find((c) => !c.product_type);
          return (
            <div className={` ${styles.ProducatImageWapper}`}>
              <img
                src={
                  img?.variation_image === "" ||
                  img?.variation_image === "null" ||
                  img?.variation_image === null
                    ? ""
                    : `${img.variation_image}`
                }
                alt=""
              />
              {time.beforTwelve && threeMark}
              {!time.beforTwelve && time.beforSix && oneMark}
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "order_status",
        isVisible: true,
        placeholder: "Status",
        colWidth: 160,
        isSelectFilter: true,
        selectFilterOption: manualOrderStatusList,
        Cell: ({ value, row, column }) => {
          let orderStatusData = manualOrderStatusList.filter(
            (status) => status.id === row.original.order_status
          );
          return `${
            orderStatusData.length ? orderStatusData[0]["title"] : "-"
          }`;
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              {viewOrderAccess && viewOrderAccess.permissions.read_access ? (
                <img
                  className={`cursor-pointer mr-2 view-icon`}
                  src={ViewIcon}
                  alt="View Order"
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push(`/admin/orders/view/${row.original.id}`);
                  }}
                />
              ) : (
                <span style={{ width: 34, height: 34, display: "block" }} />
              )}
              <img
                className={`cursor-pointer`}
                src={AcceptIcon}
                alt="Delivered Order"
                onClick={(e) => handleStatusOrderClick(e, value)}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  if (firstRender) return <LoadingScreen />;
  return (
    <React.Fragment>
      <OrderFilter
        type={type}
        setType={setType}
        selectedPeriodLabel={selectedPeriodLabel}
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={setSelectedPeriod}
        handleSelectedPeriod={handleSelectedPeriod}
        fromDate={fromDate}
        setFromDate={setFromDate}
        handleFromDate={handleFromDate}
        toDate={toDate}
        setToDate={setToDate}
        handleToDate={handleToDate}
        fetchData={fetchDataDateRange}
        handleClearSearch={handleClearSearch}
        defaultFilter={defaultFilter}
        defaultSetCheckAllStore={checkAllStore}
        checkAllStore={(data)=>{setCheckAllStore(data)}}
      />
      <ReactTable
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        noDataText={"There is no data to display"}
        defaultFilter={defaultFilter}
        displayGlobalSearch={false}
        displayColumnVisibility={false}
      />

      <StatusConfirmation
        showModal={showStatusModal}
        title="Delivered Order"
        message="Are you sure want to delivered this order?"
        buttonLabel="Delivered"
        customFlag={true}
        handleCancelClick={(event) => {
          event.preventDefault();
          setShowStatusModal(false);
          setUpdateOrderId(null);
        }}
        handleSubmitClick={handleOrderStatus}
      />
    </React.Fragment>
  );
};

export default withRouter(BurqManualOrder);
