import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";

// SCSS
import styles from "./index.module.scss";

// Components
import Text from "./../../Components/FormControl/Text/Text";

// Context
import { AppContext } from "./../../Context/AppContext";

// Services
import Users from "./../../Services/Users/Users";
import { userLogin } from "api/user";

// Images
import Logo from "./../../Assets/images/admin-logo.svg";

import { MODULES } from "./../../Helpers/Constants";
import { getAccessByModuleName } from "./../../Routes/Routes";
import { callPostApiv2 } from "api/api";

const Login = (props) => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [submitStatus, setSubmitStatus] = useState(false);
  const usersService = new Users();

  const { login, setIsLoading, showToastMessage } = useContext(AppContext);
  const handleLogin = async (event) => {
    event.preventDefault();
    setSubmitStatus(true);

    let build_version = process.env.REACT_APP_BUILD_VERSION;
    let device_type = process.env.REACT_APP_DEVICE_TYPE;

    if (username && password) {
      setIsLoading(true);
      setSubmitStatus(false);
      //   const response = await usersService.login({
      //     username,
      //     password,
      //     build_version,
      //     device_type,
      //   });
      const response = await userLogin({
        username,
        password,
        build_version,
        device_type,
      });

      if (response && response.status === 200) {
        //user assigned store and default selected store save in local storage
        localStorage.setItem("assignStore", JSON.stringify(response.data.data.assign_stores));
        let storelist = localStorage.getItem("assignStore");
        const storeId = JSON.parse(storelist);
        localStorage.setItem("selectedStore",storeId[0].id)

        login(
          response.data.data.token,
          response.data.data.role_id,
          response.data.data.default_timezone,
          response.data.data.modules
        );

        let orderLookupAccess = getAccessByModuleName(
          response.data.data.modules,
          MODULES.ORDER_LOOKUP
        );
        let clockInAccess = getAccessByModuleName(
          response.data.data.modules,
          MODULES.CLOCK_IN_CLOCK_OUT
        );

        let postMenuData = {
          menuFor: "admin",
          position: "TOP",
        };

        await callPostApiv2("menus/menu-admin", postMenuData).then ((c) => {
          // setMenu(c.data.data);
          localStorage.removeItem("adminMenuData");
          localStorage.setItem("adminMenuData", JSON.stringify(c.data.data));
        })

        if (orderLookupAccess && orderLookupAccess.permissions.read_access) {
          props.history.replace("/admin/orders/list");
        } else if (clockInAccess && clockInAccess.permissions.read_access) {
          props.history.replace("/time-clock");
        } else {
          props.history.replace("/profile");
        }
      } else showToastMessage(undefined, response.data?.message, "error", false);

      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div
        className={`d-flex align-items-center justify-content-center h-100 ${styles.loginWrapper}`}
      >
        <div className="w-100">
          <div
            className="logo d-block mb-5 text-center"
            style={{ maxWidth: "500px", margin: "0 auto", padding: "0 10px" }}
          >
            <img style={{ maxWidth: "100%" }} src={Logo} alt="Logo" />
          </div>
          <div className={styles.loginBlock}>
            <div className="mt-2 h1">Welcome,</div>
            <form onSubmit={handleLogin}>
              <Text
                type="text"
                label="Username"
                value={username}
                id="username"
                required={true}
                submitStatus={submitStatus}
                onChange={(username) => setUserName(username)}
              />
              <Text
                type="password"
                label="Password"
                id="password"
                value={password}
                required={true}
                submitStatus={submitStatus}
                onChange={(password) => setPassword(password)}
              />
              <a
                className="link"
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  props.history.push("/forgot-password");
                }}
              >
                {" "}
                Forgot Password?
              </a>
              <div className="d-block mt-5 text-center">
                <button
                  id="login-submit"
                  type="submit"
                  className="btn gray-btn"
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
