import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import React from "react";
// SCSS
import styles from "./OrderError.module.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderErrorModel = ({
  showModal,
  pageTitle,
  handleCancelClick,
  handleSubmitClick,
  errorData,
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} px-0 py-0`}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center  ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="container">
              <ul className={`${styles.list}`}>
                {errorData?.map((data, i) => {
                  return <li key={i}>{data.error}</li>;
                })}
              </ul>
            </div>
            <div className="d-flex justify-content-center">
              <span
                data-dismiss="modal"
                onClick={handleSubmitClick}
                className="btn gray-btn d-inline-block mt-5 mb-5 mr-2 mt-5 cursor-pointer"
              >
                {" "}
                Yes{" "}
              </span>
              <span
                data-dismiss="modal"
                onClick={handleCancelClick}
                className="secondary-btn d-inline-block mt-5 mb-5 mr-2 mt-5 cursor-pointer"
              >
                {" "}
                No{" "}
              </span>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default OrderErrorModel;
