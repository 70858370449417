import React from "react";
import moment from "moment";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// SCSS
import styles from "./index.module.scss";

// Component
import Text from "../../FormControl/Text/Text";
import Select from "../../FormControl/Select/Select";
import { PAYABLI_DEFAULT_STORE } from "Helpers/Constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const daySelect = (day) => {
  return moment(day).format("MM/DD/YYYY");
};

const TransferToDifferentStore = ({
  showModal,
  pageTitle,
  submitStatus,
  points,
  reward_amount,
  status,
  handleCancelClick,
  handleSubmitClick,
  onChange,
  from,
  options,
  to
}) => {

  //PAYABLI
  // Filter out the default payabli store from transfer store options
  const filteredOptions = options.filter((option) => option.id !== PAYABLI_DEFAULT_STORE);
  return (
    <React.Fragment>
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        onClose={handleCancelClick}
        classes={{
          paper: styles.modalDialog,
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogContent className={`${styles.modalContent} `}>
          <div className={`text-center ${styles.modalBody}`}>
            <h5 className={`my-3 text-center ${styles.modalTitle}`}>
              {pageTitle}
            </h5>
            <div className="row px-5 py-4">
              <div className="col-md-6 col-xs-12">
                <Text
                  type="text"
                  label="From"
                  value={from}
                  required={true}
                  submitStatus={submitStatus}
                  onChange={(data) => onChange("points", data)}
                  id="points"
                  disabled={true}
                />
              </div>
			  
			  <div className="col-md-6 col-xs-12">
					<Select
						label="To"
						parentClass='text-left'
						value={to}
						required={true}
						submitStatus={submitStatus}
						onChange={(data) => onChange('store', data)}
						options={filteredOptions}
						disabled={false}
					/>
				</div>
            </div>
            <a
              href="/"
              data-dismiss="modal"
              onClick={handleSubmitClick}
              className="btn gray-btn d-inline-block mb-3 mr-2"
            >
              Save
            </a>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default TransferToDifferentStore;
