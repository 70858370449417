import React, { useReducer } from "react";
import DeliveryMatrixReducer from "./DeliveryMatrixReducer";
import { DeliveryMatrixState } from "./DeliveryMatrixState";

export const DeliveryMatrixContext = React.createContext({
  DeliveryMatrixState: DeliveryMatrixState,
  dispatch: () => null,
});

const CouponProviderCurd = (props) => {
  const [state, dispatch] = useReducer(DeliveryMatrixReducer, DeliveryMatrixState);
  return (
    <DeliveryMatrixContext.Provider value={{ DeliveryMatrixState: state, dispatch }}>
      {props.children}
    </DeliveryMatrixContext.Provider>
  );
};

export default CouponProviderCurd;
