import React from "react";
// images
import CalendarIcon from "../../Assets/images/calendar.svg";
import CloseIcon from "../../Assets/images/close.svg";
import SearchIcon from "../../Assets/images/search.svg";
import Date from "../../Components/FormControl/Date/Date";
import styles from "./DesignerStatusFilter.module.scss";
const DesignerStatusFilter = (props) => {
  return (
    <div className={"row " + props.wrapperClass}>
      <div className="col-sm-3 col-xs-12">
        <Date
          label="Filter From"
          value={props.fromDate}
          dateFormat="ddd MMM DD, YYYY"
          type="date"
          fieldIcon={CalendarIcon}
          onChange={(fromDate) => {
            props.handleFromDate(fromDate);
          }}
        />
      </div>
      <div className="col-sm-3 col-xs-12">
        <Date
          label="Filter To"
          value={props.toDate}
          dateFormat="ddd MMM DD, YYYY"
          type="date"
          fieldIcon={CalendarIcon}
          onChange={(toDate) => {
            props.handleToDate(toDate);
          }}
        />
      </div>
      <div className="col-sm-2 col-xs-12">
        <div className="td-actions pt-4">
          <span
            onClick={(e) => props.fetchData(props.defaultFilter)}
            className={`link cursor-pointer ${styles.searchIconWrapper}`}
          >
            <img className={styles.searchIcon} src={SearchIcon} alt="" />
          </span>
          <span
            onClick={(e) => props.handleClearSearch(e)}
            className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
          >
            <img className={styles.clearIcon} src={CloseIcon} alt="" />
          </span>
        </div>
      </div>
    </div>
  );
};
export default DesignerStatusFilter;