import { useFormik } from "formik";

export function OgInfoForm(
  submit,
  initialVal,
  cb,
  checkOgInfoData
) {
  const validation = (values) => {
    // console.log('OgInfoForm validation');
    let errors = {};
    let ogInfoError = false;
    if (!values.og_title) {
      errors.og_title = "Og title required";
      ogInfoError = true;
    }
    if (!values.og_description) {
      errors.og_description = "Og description required";
      ogInfoError = true;
    }
    if (!values.og_image_url) {
      errors.og_image_url = "Og image url required";
      ogInfoError = true;
    }
    if (!values.og_url) {
      errors.og_url = "Og url required";
      ogInfoError = true;
    }
    if (!values.og_site_name) {
      errors.og_site_name = "Og site name required";
      ogInfoError = true;
    }
    // console.log('ogInfoError ===>', ogInfoError);
    checkOgInfoData(ogInfoError);
    if (cb) {
      cb(values);
    }
    return errors;
  };

  const initialValues = {
    og_title: "",
    og_description: "",
    og_image_url: "",
    og_image_alt: "",
    og_url: "",
    og_image_type: "",
    og_site_name: "",
    og_image_height: 0,
    og_image_width: 0,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialVal || initialValues,
    onSubmit: submit,
    validate: validation,
  });
  return { formik };
}
