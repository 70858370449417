import { getProfile } from "api/user";
import { AppContext } from "Context/AppContext";
import { useContext } from "react";
import { UserContext } from "./userContext";
import * as User_Action from "./userAction";

export function useUser() {
  const { userState, dispatch } = useContext(UserContext);
  const { logout } = useContext(AppContext);
  const reLogin = () => {
    return getProfile()
      .then((data) => {
        dispatch({
          type: User_Action.GET_PROFILE_DATA,
          payload: data.data.data,
        });
        return data.data.data;
      })
      .catch((e) => {
        dispatch({
          type: User_Action.GET_PROFILE_DATA,
          payload: null,
        });
        logout();
      });
  };
  return {
    userState,
    reLogin,
  };
}
