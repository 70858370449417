import { callPostApi, callDeleteApi, callPutApi, callGetApi } from "./api";

export async function getBlockOutDatesList(data) {
      // return this.interceptors.POST("blockout-dates/list", data);
      try {
            const response = await callPostApi({ url: "blockout-dates/list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function deleteBlockOutDates(id) {
      // return this.interceptors.DELETE("blockout-dates/" + id);
      try {
            const response = await callDeleteApi({ url: "blockout-dates/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function getDetailsBlockoutDates(id) {
      // return this.interceptors.GET("blockout-dates/" + id);
      try {
            const response = await callGetApi({ url: "blockout-dates/" + id });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function addBlockOutDate(data) {
      // return this.interceptors.POST("blockout-dates/", data)
      try {
            const response = await callPostApi({ url: "blockout-dates/", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function updateBlockOutDates(data) {
      // return this.interceptors.PUT("blockout-dates/", data)
      try {
            const response = await callPutApi({ url: "blockout-dates/", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}
export async function getZipCodesList(data) {
      // return this.interceptors.POST("store-settings/zipcode/list", data)
      try {
            const response = await callPostApi({ url: "store-settings/zipcode/storewise-list", body: data });
            return response;
      } catch (error) {
            throw error;
      }
}

