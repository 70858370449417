import React, { useEffect } from "react";
import { useParams } from "react-router";
import OrderMain from "../Order";
import { removeUpdateOrderLastEditBy } from "api/order";

const DuplicateOrder = () => {
  const { id } = useParams();

  useEffect(() => {
    return () => {
      let postData = {
        orderId: id,
      };
      removeUpdateOrderLastEditBy(postData)
        .then((data) => {
          // console.log("data=>",data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  }, []);

  return <OrderMain module="edit-order" title="Edit Order" />;
};

export default DuplicateOrder;
