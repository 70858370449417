import React, { useContext } from "react";
import styles from "./index.module.scss";
import MultipalImage from "./MultipalImage";

import { AppContext } from "../../Context/AppContext";
import UtilsApi from "../../Services/Utils/Utils";
import CloseIcon from "../../Assets/images/close-icon.svg";

import { convertimgixurl } from "../../Hooks/commanUtility";

export default function PartnersLogo(props) {
  const utilsService = new UtilsApi();
  const { showToastMessage, setIsLoading } = useContext(AppContext);

  const partnersData = props.partnersData ? props.partnersData : [];
  const handleNewLogo = (data) => {
    let newLogoData = data.map((image) => {
      return {
        id: 0,
        name: "",
        image: image.url,
      };
    });
    // console.log('handleNewLogo', newLogoData);
    props.addNewLogo(newLogoData);
  };

  const handleRemoveLogo = (event, remove_partner_id) => {
    event.stopPropagation();
    props.removeLogo(remove_partner_id);
  };

  const handleUploadImage = async (imageData, imageType = "OTHER") => {
    let imageFormdata = new FormData();
    imageData.forEach((image) => {
      imageFormdata.append("image", image);
    });
    imageFormdata.append("type", imageType);
    setIsLoading(true);
    const response = await utilsService.uploadImage(imageFormdata);

    // console.log('handleUploadImage', response);
    if (response && response.status === 200) {
      handleNewLogo(response.data.data);
      setIsLoading(false);
      return response.data.data;
    } else {
      setIsLoading(false);
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };

  return (
    <div className={`mt-2 mb-4`}>
      <div className={styles.featuredTitle}>Partners Logo</div>
      <div className={`row align-items-center`}>
        {partnersData &&
          partnersData?.map((partner, index) => (
            <div
              className={`col-3 col-md-3 text-center ${styles.imageWrapper}`}
              key={index}
            >
              <img
                style={{ maxHeight: "100px" }}
                className={`img-fluid ${styles.image}`}
                src={convertimgixurl(partner.image, process.env.REACT_APP_IMGIX_FIND_URL, process.env.REACT_APP_IMGIX_REPLACE_URL, '', '100')}
                alt="Partners Logo"
              />
              <img
                className={styles.removeImage}
                src={CloseIcon}
                alt="Partners Logo"
                onClick={(e) => handleRemoveLogo(e, index)}
              />
            </div>
          ))}

        <div className="col-3 col-md-2 col-lg-2 col-xl-2 text-center ">
          <MultipalImage
            multiple
            accept="image/*"
            handleUploadImage={handleUploadImage}
          />
        </div>
      </div>
    </div>
  );
}
