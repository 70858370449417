export const LocationActions = {
    SET_BANNER_DATA: "SET_BANNER_DATA",
    ADD_NEW_BANNER: "ADD_NEW_BANNER",
    REMOVE_BANNER: "REMOVE_BANNER",
    UPDATE_BANNER_DATA: "UPDATE_BANNER_DATA",
    CHECK_BANNER_DATA: "CHECK_BANNER_DATA",
    SET_LOCATION_PRIMARY_DATA: "SET_LOCATION_PRIMARY_DATA",
    SET_RELATED_CATEGORY: "SET_RELATED_CATEGORY",
    UPDATE_RELATED_CATEGORY: "UPDATE_RELATED_CATEGORY",
    DELETE_RELATED_CATEGORY: "DELETE_RELATED_CATEGORY",
    SET_EXISTING_RELATED_CATEGORY: "SET_EXISTING_RELATED_CATEGORY",
    SET_SEO_DATA: "SET_SEO_DATA",
    CLEAR_DATA_LOCATION: "CLEAR_DATA_LOCATION",
}; 