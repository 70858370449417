export const CouponAction = {
  SET_COUPON_CODE_NAME: "SET_COUPON_CODE_NAME",
  SET_COUPON_CODE_TYPE: "SET_COUPON_CODE_TYPE",
  SET_COUPON_CODE_VALUE: "SET_COUPON_CODE_VALUE",
  SET_COUPON_APPLY_TO: "SET_COUPON_APPLY_TO",
  SET_COUPON_REDEEM_FOR_MOBILE: "SET_COUPON_REDEEM_FOR_MOBILE",
  SET_USAGE_LIMIT_PER_USER: "SET_USAGE_LIMIT_PER_USER",
  SET_START_DATE: "SET_START_DATE",
  SET_END_DATE: "SET_END_DATE",
  SET_SELECTED_DATA: "SET_SELECTED_DATA",
  DISABLE_SECTION_ON_SELECT: "DISABLE_SECTION_ON_SELECT",
  EMPTY_SELECTED_DATA: "EMPTY_SELECTED_DATA",
  CLEAR_COUPON_DATA: "CLEAR_COUPON_DATA",
  SET_SUMMARY_LIST: "SET_SUMMARY_LIST",
  SET_COUPON_USE_COUNT: "SET_COUPON_USE_COUNT",
};
