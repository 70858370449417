export function postReqCategory(categoryState) {

      let seoPredefaultData = {
            id: null,
            module: "category-hierarchy",
            is_robot: false,
            is_no_follow: false,
            is_no_index: false,
            image_alt_text: "",
            page_title: "",
            meta_title: "",
            meta_description: "",
            canonical_url: "",
            og_title: "",
            og_description: "",
            og_image_url: "",
            og_url: "",
            og_image_alt: " ",
            og_image_type: "",
            og_image_width: 0,
            og_image_height: 0,
            og_site_name: "",
            twitter_title: "",
            twitter_description: "",
            twitter_image_url: "",
            twitter_site: "",
            twitter_creator: ""
      };

      if (categoryState.banners !== undefined && categoryState.banners.length > 0) {
            // eslint-disable-next-line array-callback-return
            categoryState.banners?.map((d, i) => {
                  if (categoryState.banners[i]['id'] == null || categoryState.banners[i]['id'] === undefined || categoryState.banners[i]['id'] === 0) {
                        delete categoryState.banners[i]['id'];
                        delete categoryState.banners[i]['parent_id'];
                  }
            });
      }
      let category_banner_data = (categoryState.banners !== undefined && categoryState.banners.length > 0) ? categoryState.banners : [];

      // Prepare faq data
      let category_faqs = []
      if(categoryState.faqs && categoryState.faqs.length > 0) {
            categoryState.faqs.forEach((val, i) => {
                  if(!categoryState.faqs[i]["id"]) {
                        delete categoryState.faqs[i]["id"];
                  }
            })
            category_faqs = categoryState.faqs;
      }
      if(categoryState.seo['id'] === null)
            delete categoryState.seo['id'];
      let seo = categoryState.seo;

      let filterHierarchyData = categoryState.setCategoryAllData?.category_hierarchies?.filter(
            (hierarchyData) => {
                  if (hierarchyData.id === +categoryState.categoryHierarchyId) {
                        return hierarchyData;
                  }
            }
      );

      let parent_hierarchy_id = null
      if (categoryState.categoryHierarchyParentId !== null) {
            parent_hierarchy_id = categoryState.categoryHierarchyParentId;
      } else {
            parent_hierarchy_id = (filterHierarchyData !== undefined) ? filterHierarchyData[0]?.parent_hierarchy_id ?? null : null;
      }


      let categoryData = [
            {
                  seo: seo,
                  parent_hierarchy_id: parent_hierarchy_id,
            }
      ];

      let otherCategoryHierarchyData = categoryState.setCategoryAllData?.category_hierarchies?.filter(
            (hierarchyData) => {
                  if (hierarchyData.id !== +categoryState.categoryHierarchyId) {
                        return hierarchyData;
                  }
            }
      );

      if (otherCategoryHierarchyData?.length > 0) {
            otherCategoryHierarchyData.forEach(data => {
                  if (data.seo === null)
                        data.seo = seoPredefaultData;
                  categoryData.push(data);
            });
      }

      let post_Req = {

            type: categoryState.category_primary_info.type,
            title: categoryState.category_primary_info.title,
            sequence_number: categoryState.category_primary_info.sequence_number,
            keywords: categoryState.category_primary_info.keywords,
            customer_note: categoryState.category_primary_info.customer_note,
            description: categoryState.category_primary_info.description,
            category_image: categoryState.category_primary_info.category_image,
            is_filter: categoryState.category_primary_info.is_filter,
            show_certification: categoryState.category_primary_info.show_certification,
            status : categoryState.category_primary_info.status,
            slug: categoryState.category_navigation_info.slug,
            blurb: categoryState.category_navigation_info.blurb,
            faq_title: categoryState.category_primary_info.faq_title,
            faq_description: categoryState.category_primary_info.faq_description,
            banners: category_banner_data,
            category_hierarchies: categoryData,
            faqs: category_faqs,
      };

      return { ...post_Req };
}