import { TextField } from "@material-ui/core";
import CheckBox from "Components/FormControl/CheckBox/CheckBox";
import RadioBox from "Components/FormControl/RadioBox/RadioBox";
import { setAllStore } from "Helpers/setAllStore";
import { DateTime } from "luxon";
import moment from "moment";
import React, { useContext, useState } from "react";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router";
import { getShopCodeData } from "../../../api/report";
import CalendarIcon from "../../../Assets/images/calendar.svg";
import CloseIcon from "../../../Assets/images/close.svg";
import ViewIcon from "../../../Assets/images/error-outline.svg";
import ExportIcon from "../../../Assets/images/export.svg";
import SearchIcon from "../../../Assets/images/search.svg";
import Date from "../../../Components/FormControl/Date/Date";
import Select from "../../../Components/FormControl/Select/Select";
import ReactTable from "../../../Components/Table/Table";
import { AppContext } from "../../../Context/AppContext";
import { MODULES } from "../../../Helpers/Constants";
import { formatUsPhone } from "../../../Hooks/commanUtility";
// Context
import { getAccessByModuleName } from "../../../Routes/Routes";
// Components
import Header from "./../../../Components/Header/Header";
import styles from "./index.module.scss";

const ShopCode = (props) => {
  const { showToastMessage, modules } = useContext(AppContext);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(1);
  const [exportData, setExportData] = React.useState([]);
  const [exportHeader, setExportHeader] = React.useState([]);
  const [shopCode, setShopCode] = React.useState();
  const [type, setType] = useState("delivery_date");
  const reference = React.useRef(null);

  let accessExport = getAccessByModuleName(modules, MODULES.SHOP_CODE_REPORT);
  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: [],
    filters: [],
    globalFilter: "",
  });

  const [fromDate, setFromDate] = useState(
    moment().startOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [toDate, setToDate] = useState(
    moment().endOf("isoWeek").format("ddd MMM DD, YYYY")
  );
  const [selectedPeriod, setSelectedPeriod] = React.useState("weekly");
  const [selectedPeriodLabel, setSelectedPeriodLabel] = React.useState(
    "By Week"
  );

  const [ordersCount, setOrdersCount] = React.useState(0);
  const [totalOrders, setTotalOrders] = React.useState(0);
  const [firstTimePageLoad, setFirstTimePageLoad] = React.useState(false);

  const [checkAllStore,setCheckAllStore] = useState(true);

  const fetchData = async ({
    pageIndex,
    pageSize,
    sortBy,
    globalFilter,
    filters,
    fromDateDefault,
    toDateDefault,
  }) => {

    /*
      # when all store checkbox is checked at that time we set page_wise_all_store_checkbox=0 in local storage & this logic write in setAllStore function
      
    */
      setAllStore(checkAllStore);

    setDefaultFilter({ pageSize, pageIndex, filters, globalFilter, sortBy });

    setLoading(true);
    const postData = {
      currentPage: pageIndex,
      itemsPerPage: pageSize,
      globalSearch: globalFilter,
      filters,
      sortBy,
      date_type: type,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
      shopcode: shopCode,
    };

    if (firstTimePageLoad) {
      // if (accessExport && accessExport.permissions.write_access)
      //   handleExport(sortBy, filters, fromDateDefault, toDateDefault);

      const response = await getShopCodeData(postData);
      if (response && response.status === 200) {
        setData(response.data.data.rows);
        setTotalOrders(response.data.data.shopcode.total_orders);
        setOrdersCount(response.data.data.shopcode.no_of_orders);
        setPageCount(Math.ceil(response.data.data.count.length / pageSize));
      } else if (response.status === 403) {
        showToastMessage(undefined, response.data.message, "error", false);
        props.history.push("/profile");
      } else {
        showToastMessage(undefined, response.data.message, "error", false);
      }
    } else {
      setFirstTimePageLoad(true);
    }
    setLoading(false);

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  const handleClearSearch = async (event) => {
    event.preventDefault();

    setFromDate(moment().startOf("isoWeek").format("YYYY-MM-DD"));
    setToDate(moment().endOf("isoWeek").format("YYYY-MM-DD"));
    setSelectedPeriod("weekly");

    fetchData({
      ...defaultFilter,
      fromDateDefault: moment().startOf("isoWeek").format("YYYY-MM-DD"),
      toDateDefault: moment().endOf("isoWeek").format("YYYY-MM-DD"),
    });
  };

  const handleExport = async (
    sortBy,
    filters,
    fromDateDefault,
    toDateDefault
  ) => {

    /*
      # first, we get the selected store id in the local storage
      # when all store checkbox is checked at that time we set selected store id=0 in local storage & this logic write in setAllStore function
      # 
    */
    const selectedStore = localStorage.getItem("selectedStore");
    setAllStore(checkAllStore);

    let postData = {
      isExport: true,
      shopcode: shopCode,
      filters,
      sortBy,
      date_type: type,
      start_date: fromDateDefault ?? moment(fromDate).format("YYYY-MM-DD"),
      end_date: toDateDefault ?? moment(toDate).format("YYYY-MM-DD"),
    };

    const response = await getShopCodeData(postData);

    if (response && response.status === 200) {
      setExportHeader(response.data.data.header);
      setExportData(response.data.data.data);
      reference.current?.link?.click();
    } else if (response.status === 403) {
      showToastMessage(undefined, response.data.message, "error", false);
      props.history.push("/profile");
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }

    //when the all store checkbox is checked and we get all store data(after the API call) then we set the selected store id=dropdown selected store in local storage and this logic writes in setAllStore  function
    if(checkAllStore){ setAllStore(false) }
  };

  const handleSelectedPeriod = async (id) => {
    if (id === "weekly") {
      setFromDate(moment().startOf("isoWeek").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("isoWeek").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Week");
    } else if (id === "monthly") {
      setFromDate(moment().startOf("month").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("month").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Month");
    } else if (id === "yearly") {
      setFromDate(moment().startOf("year").format("ddd MMM DD, YYYY"));
      setToDate(moment().endOf("year").format("ddd MMM DD, YYYY"));
      setSelectedPeriodLabel("By Year");
    }
  };

  const handleFromDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setToDate(moment(data).add(7, "days").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "monthly") {
      setToDate(moment(data).add(1, "months").utc().format("ddd MMM DD, YYYY"));
    } else if (selectedPeriod === "yearly") {
      setToDate(moment(data).add(1, "years").utc().format("ddd MMM DD, YYYY"));
    }
  };

  const handleToDate = async (data) => {
    if (selectedPeriod === "weekly") {
      setFromDate(
        moment(data).subtract(7, "days").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "monthly") {
      setFromDate(
        moment(data).subtract(1, "months").utc().format("ddd MMM DD, YYYY")
      );
    } else if (selectedPeriod === "yearly") {
      setFromDate(
        moment(data).subtract(1, "years").utc().format("ddd MMM DD, YYYY")
      );
    }
  };

  // let accountAccess = getAccessByModuleName(modules, MODULES.ACCOUNT_LOOKUP);
  const columns = React.useMemo(
    () => [
      {
        Header: "Order No",
        accessor: "order_number",
        isVisible: true,
        placeholder: "Order No",
      },
      {
        Header: "Customer Name",
        accessor: "customer_name",
        isVisible: true,
        placeholder: "Customer Name",
        Cell: ({ value, row, column }) => {
          return row.original.order_transaction?.customer_id === 0 ||
            row.original.order_transaction?.customer_id === undefined ? (
            <span>{`${row.original.order_transaction.customer_name}`}</span>
          ) : (
            <span>{`${row.original.order_transaction.customer_name}`}</span>
          );
        },
      },
      {
        Header: "Recipient Name",
        accessor: "recipient_name",
        isVisible: true,
        placeholder: "Recipient Name",
        Cell: ({ value, row, column }) => {
          return `${row.original.order_recipients[0].recipient_name}`;
        },
      },
      {
        Header: "Customer Phone",
        accessor: "customer_phone",
        isVisible: true,
        placeholder: "Customer Phone",
        Cell: ({ value, row, column }) => {
          return `${
            row.original.order_transaction.customer_phone
              ? row.original.order_transaction.customer_phone_country_code +
                " " +
                formatUsPhone(row.original.order_transaction.customer_phone)
              : ""
          }`;
        },
      },
      {
        Header: "Recipient Phone",
        accessor: "recipient_phone",
        isVisible: true,
        placeholder: "Recipient Phone",
        Cell: ({ value, row, column }) => {
          return `${
            row.original.order_recipients[0].recipient_phone
              ? row.original.order_recipients[0].phone_country_code +
                " " +
                formatUsPhone(row.original.order_recipients[0].recipient_phone)
              : ""
          }`;
        },
      },
      {
        Header: "Delivery Date",
        accessor: "delivery_date_time",
        isVisible: true,
        placeholder: "Delivery Date",
        Cell: ({ value, row, column }) => {
          return `${
            row.original.delivery_date_time &&
            row.original.delivery_date_time !== null
              ? DateTime.fromISO(row.original.delivery_date_time)
                  .setZone("America/Los_Angeles")
                  .toFormat("ccc LLL dd, yyyy")
              : ""
          }`;
        },
      },
      {
        Header: "Price",
        accessor: "total",
        placeholder: "Price",
        Cell: ({ value, row, column }) => {
          return `$${row.original.total.toFixed(2)}`;
        },
      },
      {
        Header: "Customer Email",
        accessor: "customer_email",
        placeholder: "Customer Email",
        Cell: ({ value, row, column }) => {
          return `${row.original.order_transaction.customer_email}`;
        },
      },
      {
        Header: "Customer Address",
        accessor: "customer_address",
        placeholder: "Customer Address",
        Cell: ({ value, row, column }) => {
          return `${row.original.order_transaction.customer_address}`;
        },
      },
      {
        Header: "Recipient Address",
        accessor: "recipient_address",
        placeholder: "Recipient Address",
        Cell: ({ value, row, column }) => {
          return `${row.original.order_recipients[0].recipient_address}`;
        },
      },
      {
        Header: "Type",
        accessor: "source",
        placeholder: "Type",
      },
      {
        Header: "External Order Number",
        accessor: "external_order_number",
        placeholder: "External Order Number",
      },
      {
        Header: "Shop Code",
        accessor: "shopcode",
        placeholder: "Shop Code",
      },
      {
        Header: "Action",
        accessor: "id",
        isVisible: true,
        disableSortBy: true,
        Cell: ({ value, row, column }) => {
          return (
            <div>
              <img
                className={`mr-2 view-icon`}
                src={ViewIcon}
                alt="View Order"
              />
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Header title="Shop Code" />

      <div id="MainContent" className="main-content">
        <div className={"row "}>
          <div className="col-12 d-flex align-items-center">
            <span
              className="mb-0 mt-2 pb-0 d-inline-bock mr-3"
              style={{ lineHeight: 1 }}
            >
              Filter By
            </span>
            <RadioBox
              selectedValue={type}
              onChange={() => {
                if (setType) {
                  setType("order_date");
                }
              }}
              label="Order date"
              value="order_date"
            />
            <RadioBox
              selectedValue={type}
              onChange={() => {
                if (setType) {
                  setType("delivery_date");
                }
              }}
              label="Delivery date"
              value="delivery_date"
            />
          </div>

          <div className="col-sm-2 col-xs-12">
            <Select
              label={selectedPeriodLabel}
              value={selectedPeriod}
              onChange={(data) => {
                setSelectedPeriod(data);
                handleSelectedPeriod(data);
              }}
              options={[
                { id: "weekly", title: "Weekly" },
                { id: "monthly", title: "Monthly" },
                { id: "yearly", title: "Yearly" },
                { id: "custom", title: "Custom" },
              ]}
              disabled={false}
            />
          </div>
          <div className="col-sm-3 col-xs-12">
            <Date
              label="Filter From"
              value={fromDate}
              dateFormat="ddd MMM DD, YYYY"
              type="date"
              fieldIcon={CalendarIcon}
              onChange={(fromDate) => {
                setFromDate(moment(fromDate).format("ddd MMM DD, YYYY"));
                handleFromDate(fromDate);
              }}
            />
          </div>
          <div className="col-sm-3 col-xs-12">
            <Date
              label="Filter To"
              value={toDate}
              dateFormat="ddd MMM DD, YYYY"
              type="date"
              fieldIcon={CalendarIcon}
              onChange={(toDate) => {
                setToDate(moment(toDate).format("ddd MMM DD, YYYY"));
                handleToDate(toDate);
              }}
            />
          </div>
          <div className="col-sm-2 col-xs-12">
            <CheckBox
            value={checkAllStore}
            label="All Stores"
            onChange={(data)=>{setCheckAllStore(data)}}
            />
          </div>
          <div className="col-sm-2 col-xs-12">
            <div className="td-actions pt-4">
              <span
                onClick={(e) => fetchData(defaultFilter)}
                className={`link cursor-pointer ${styles.searchIconWrapper}`}
              >
                <img className={styles.searchIcon} src={SearchIcon} alt="" />
              </span>
              <span
                onClick={(e) => handleClearSearch(e)}
                className={`link cursor-pointer ml-3 ${styles.clearIconWrapper}`}
              >
                <img className={styles.clearIcon} src={CloseIcon} alt="" />
              </span>
              {exportData && exportData.length > 0 && (
                <CSVLink
                  ref={reference}
                  style={{
                    display: "none",
                    float: "right",
                    marginTop: "25px",
                  }}
                  data={exportData}
                  headers={exportHeader}
                  filename={"ShopCodeReport.csv"}
                  className={`link cursor-pointer ml-3 ${styles.searchIconWrapper}`}
                >
                  DownLoad
                </CSVLink>
              )}
              {accessExport && accessExport.permissions.write_access && (
                <span
                  onClick={(e) => handleExport(defaultFilter)}
                  className={`link cursor-pointer ${styles.searchIconWrapper}`}
                  style={{ float: "right" }}
                >
                  <img className={styles.searchIcon} src={ExportIcon} alt="" />
                </span>
              )}
            </div>
          </div>
        </div>
        <br />
        <div className={"row "}>
          <div className="col-sm-3 col-xs-12">
            <TextField
              id="standard-read-only-input"
              label="Shop Code"
              value={shopCode}
              onChange={(e) => setShopCode(e.target.value)}
            />
          </div>
          <div
            style={{ color: "#9B9B9B", paddingTop: "35px" }}
            className="col-sm-3 col-xs-12"
          >
            {" "}
            No of Orders: <span style={{ color: "black" }}>{ordersCount}</span>
          </div>
          <div
            style={{ color: "#9B9B9B", paddingTop: "35px" }}
            className="col-sm-3 col-xs-12"
          >
            {" "}
            Orders Total: <span style={{ color: "black" }}>{totalOrders}</span>
          </div>
        </div>
        <br />
        <ReactTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          noDataText={"There is no data to display"}
          defaultFilter={defaultFilter}
          displayGlobalSearch={false}
          displayColumnVisibility={true}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(ShopCode);
