import { useContext } from "react";
import { LocationContext } from "./locationContext";
import { LocationActions } from "./locationActions";

export function useLocation() {
  const { locationState, dispatch } = useContext(LocationContext);

  const setLocationBannerData = (payload) => {
    dispatch({ type: LocationActions.SET_BANNER_DATA, payload });
  };
  const addNewBanner = () => {
    dispatch({ type: LocationActions.ADD_NEW_BANNER });
  };
  const removeBanner = (index) => {
    dispatch({ type: LocationActions.REMOVE_BANNER, payload: index });
  };
  const setBannerData = (values) => {
    dispatch({ type: LocationActions.UPDATE_BANNER_DATA, payload: values });
  };
  const checkBannerData = (payload) => {
    dispatch({ type: LocationActions.CHECK_BANNER_DATA, payload });
  };
  const setLocationPrimaryData = (values) => {
    dispatch({ type: LocationActions.SET_LOCATION_PRIMARY_DATA, payload: values });
  };
  const setRelatedCategory = (values) => {
    dispatch({ type: LocationActions.SET_RELATED_CATEGORY, payload: values });
  };
  const deleteRelatedCategory = (values) => {
    dispatch({ type: LocationActions.DELETE_RELATED_CATEGORY, payload: values });
  };
  const updateRelatedCategory = (values, index) => {
    dispatch({ type: LocationActions.UPDATE_RELATED_CATEGORY, payload: values, index: index });
  };
  const setExistingRelatedCategoryData = (values) => {
    dispatch({ type: LocationActions.SET_EXISTING_RELATED_CATEGORY, payload: values });
  };
  const setSeoDataIndex = (values, index) => {
    dispatch({ type: LocationActions.SET_SEO_DATA, payload: values, index: index });
  };
  const clearLocationState = () => {
    dispatch({ type: LocationActions.CLEAR_DATA_LOCATION });
  };
  const setSeoData = (values) => {
    dispatch({ type: LocationActions.UPDATE_SEO_DATA, payload: values });
  };
  const checkSeoData = (payload) => {
    dispatch({ type: LocationActions.CHECK_SEO_DATA, payload });
  };
  const setOgInfoData = (values) => {
    dispatch({ type: LocationActions.UPDATE_OG_INFO_DATA, payload: values });
  };
  const checkOgInfoData = (payload) => {
    dispatch({ type: LocationActions.CHECK_OG_INFO_DATA, payload });
  };
  const setTwitterInfoData = (values) => {
    dispatch({ type: LocationActions.UPDATE_TWITTER_INFO_DATA, payload: values });
  };
  const checkTwitterInfoData = (payload) => {
    dispatch({ type: LocationActions.CHECK_TWITTER_INFO_DATA, payload });
  };
  const setSchemaData = (values) => {
    dispatch({ type: LocationActions.UPDATE_SCHEMA_DATA, payload: values });
  };
  const checkSchemaData = (payload) => {
    dispatch({ type: LocationActions.CHECK_SCHEMA_DATA, payload });
  }

  return {
    locationState,
    setLocationBannerData,
    addNewBanner,
    removeBanner,
    setBannerData,
    checkBannerData,
    setLocationPrimaryData,
    setRelatedCategory,
    deleteRelatedCategory,
    updateRelatedCategory,
    setExistingRelatedCategoryData,
    setSeoDataIndex,
    clearLocationState,
    setSeoData,
    checkSeoData,
    setOgInfoData,
    checkOgInfoData,
    setTwitterInfoData,
    checkTwitterInfoData,
    setSchemaData,
    checkSchemaData,
  };
}