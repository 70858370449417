import React from 'react';

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

// Scss
import styles from './Notification.module.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Notification = ({ showModal, handleCancelClick, handleSubmitClick, message }) => {
    return (
        <React.Fragment>
            <Dialog
                open={showModal}
                TransitionComponent={Transition}
                onClose={handleSubmitClick}
                classes={{
                    paper: styles.modalDialog
                }}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogContent
                    className={styles.modalContent}
                >
                    <div className={`text-center ${styles.modalBody}`}>
                        <h5 className={`my-3 text-center ${styles.modalTitle}`}>Notification</h5>
                        <p className="popup-info-text"> {message} </p>
                        <a href="/" data-dismiss="modal" onClick={handleSubmitClick} className="btn gray-btn d-inline-block mb-3 mr-2"> Continue on Lunch Break </a>
                        {/* <a href="/" data-dismiss="modal" onClick={handleCancelClick} className="btn gray-btn d-inline-block mb-3"> Override and Clock Back In </a> */}
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default Notification;
