import { useFormik } from "formik";

export function SeoForm(
  submit,
  initialVal,
  cb,
  checkSeoData
) {
  const validation = (values) => {
    // console.log('SeoForm validation');
    let errors = {};
    let seoError = false;
    if (!values.image_alt_text) {
      errors.image_alt_text = "Image alt text required";
      seoError = true;
    }
    if (!values.page_title) {
      errors.page_title = "Page title required";
      seoError = true;
    }
    if (!values.meta_title) {
      errors.meta_title = "Meta title required";
      seoError = true;
    }
    if (!values.meta_description) {
      errors.meta_description = "Meta description required";
      seoError = true;
    }
    if (!values.canonical_url) {
      errors.canonical_url = "Canonical url required";
      seoError = true;
    }
    // console.log('seoError ===>', seoError);
    checkSeoData(seoError);
    if (cb) {
      cb(values);
    }
    return errors;
  };

  const initialValues = {
    is_robot: false,
    is_no_follow: false,
    is_no_index: false,
    image_alt_text: "",
    page_title: "",
    meta_title: "",
    meta_description: "",
    canonical_url: "",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialVal || initialValues,
    onSubmit: submit,
    validate: validation,
  });
  return { formik };
}
