import { useContext } from "react";
import { BlogContext } from "./blogContext";
import { BlogActions } from "./blogActions";

export function useBlog() {
  const { blogState, dispatch } = useContext(BlogContext);
  const clearBlogState = () => {
    dispatch({ type: BlogActions.CLEAR_DATA_BLOG });
  };
  const setBlogAllData = (index) => {
    dispatch({ type: BlogActions.SET_ALL_BLOG_DATA, payload: index });
  };
  const setPageForView = (values) => {
    dispatch({ type: BlogActions.SET_PAGE_FOR_VIEW, payload: values });
  };  
  const setBlogPrimaryData = (values) => {
    dispatch({ type: BlogActions.SET_PRIMARY_INFO, payload: values });
  };
  const checkBlogPrimaryData = (values) => {
    dispatch({ type: BlogActions.CHECK_PRIMARY_INFO, payload: values });
  };
  const setCategoryData = (values) => {
    dispatch({type: BlogActions.SET_CATEGORY_DATA, payload: values });
  };
  const deleteCategoryData = (values) => {
    dispatch({ type: BlogActions.DELETE_CATEGORY_DATA, payload: values });
  };
  const setBlogBannerData = (payload) => {
    dispatch({ type: BlogActions.SET_BANNER_DATA, payload });
  };
  const addNewBanner = () => {
    dispatch({ type: BlogActions.ADD_NEW_BANNER });
  };
  const removeBanner = (index) => {
    dispatch({ type: BlogActions.REMOVE_BANNER, payload: index });
  };
  const setBannerData = (values) => {
    dispatch({ type: BlogActions.UPDATE_BANNER_DATA, payload: values });
  };
  const checkBannerData = (payload) => {
    dispatch({ type: BlogActions.CHECK_BANNER_DATA, payload });
  };  
  const setSeoData = (values) => {
    dispatch({ type: BlogActions.UPDATE_SEO_DATA, payload: values });
  };
  const checkSeoData = (payload) => {
    dispatch({ type: BlogActions.CHECK_SEO_DATA, payload });
  };
  const setOgInfoData = (values) => {
    dispatch({ type: BlogActions.UPDATE_OG_INFO_DATA, payload: values });
  };
  const checkOgInfoData = (payload) => {
    dispatch({ type: BlogActions.CHECK_OG_INFO_DATA, payload });
  };
  const setTwitterInfoData = (values) => {
    dispatch({ type: BlogActions.UPDATE_TWITTER_INFO_DATA, payload: values});
  };
  const checkTwitterInfoData = (payload) => {
    dispatch({ type: BlogActions.CHECK_TWITTER_INFO_DATA, payload });
  };
  const setSchemaData = (values) => {
    dispatch({ type: BlogActions.UPDATE_SCHEMA_DATA, payload: values });
  };
  const checkSchemaData = (payload) => {
    dispatch({ type: BlogActions.CHECK_SCHEMA_DATA, payload });
  };
  const setSimilarBlogData = (payload) => {
    dispatch({ type: BlogActions.SET_SIMILAR_BLOG_DATA, payload });
  };
  const deleteSimilarBlogData = (values) => {
    dispatch({ type: BlogActions.DELETE_SIMILAR_BLOG_DATA, payload: values });
  };

  return {
    blogState,
    setPageForView,
    setBlogAllData,
    clearBlogState,
    setBlogPrimaryData,
    checkBlogPrimaryData,
    setCategoryData,
    deleteCategoryData,
    setBlogBannerData,
    addNewBanner,
    removeBanner,
    setBannerData,
    checkBannerData,
    setSeoData,
    checkSeoData,
    setOgInfoData,
    checkOgInfoData,
    setTwitterInfoData,
    checkTwitterInfoData,
    setSchemaData,
    checkSchemaData,
    setSimilarBlogData,
    deleteSimilarBlogData
  };
}