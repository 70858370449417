import { IconButton, Input, InputAdornment } from "@material-ui/core";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import { addInternalNotes } from "api/order";
import { AppContext } from "Context/AppContext";
import { useUser } from "Context/UserContext/useUser";
import { useFormikContext } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useOrderAttributeProvider } from "../../context/order";
import InternalNoteAccordion from "./InternalNoteAccordion";
function InternalNote(props) {
  //Requires order id here
  const [comments, setComments] = useState("");
  const [emptyComment, setEmptyComment] = useState(false);
  const { userState } = useUser();
  const { showToastMessage } = useContext(AppContext);
  const { orderDetail, module, active } = useOrderAttributeProvider();
  const formik = useFormikContext();

  const enterIconClick = async () => {
    if (comments === "") {
      setEmptyComment(true);
      return;
    }
    let commentData = {
      author:
        userState.userProfile?.first_name +
        " " +
        userState.userProfile?.last_name,
      ans: comments,
      time: moment().toISOString(),
    };
    let postData = {
      order_id: orderDetail.id,
      created_by: userState.userProfile?.id,
      notes: comments,
    };
    const response = await addInternalNotes(postData);
    if (response && response.status === 200) {
      //New internal note
      let intenalNote = [...formik.values.internal_notes];
      intenalNote.push(commentData);
      formik.setValues((v) => {
        return {
          ...v,
          internal_notes: intenalNote,
        };
      });
      setComments("");
      setEmptyComment(false);
    } else {
      showToastMessage(undefined, response.data.message, "error", false);
    }
  };
  const handleData = async (e) => {
    if (comments !== "") {
      if (e.keyCode === 13) {
        await enterIconClick();
      }
    }
  };

  if (module !== "edit-order") {
    return (
      <div>
        <div className={`mb-3`}>
          <Input
            id="standard-adornment-password"
            type={"text"}
            className="form-control enterInput"
            value={formik.values.internal_note}
            onChange={(e) => {
              formik.setValues((v) => {
                return {
                  ...v,
                  internal_note: e.target.value,
                };
              });
            }}
            placeholder="Write additional comments..."
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={`mb-3`}>
        <Input
          id="standard-adornment-password"
          type={"text"}
          className="form-control enterInput"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          onKeyDown={handleData}
          placeholder="Write additional comments..."
          disabled={active === 0 ? false : true}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                style={{ border: "none" }}
              >
                <SubdirectoryArrowRightIcon onClick={() => enterIconClick()} />
              </IconButton>
            </InputAdornment>
          }
        />
        {emptyComment && (
          <p className="invalid-feedback">please enter comment</p>
        )}
      </div>
      {formik.values.internal_notes?.map((d, i) => {
        return <InternalNoteAccordion data={d} key={i} panel={i.toString()} />;
      })}
    </div>
  );
}

export default InternalNote;
